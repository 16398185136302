import React, { Component } from 'react';
// import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import EventBus from 'eventing-bus';
import moment from 'moment';
import { ACTION_TYPES } from 'src/constants';
import { Loader, SignInSignUpModal, SavvySupport } from 'src/components';
import { Convert } from 'src/helpers';
import _ from 'lodash';
import cx from 'classnames';
import styles from './pay.module.scss';
import OfferBox from './offer-box';
import PayError from '../pay-error';
import PaySuccess from '../pay-success';
// import ExtraInfo from '../extra-info';

const PAYMENT_METHODS = {
  book_save_buy: 'Book. Save. Buy',
  pay_in_instalments: 'Pay in Installments',
};

const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;

const SAVE_NOW_BUY_LATER = 'snbl';
const PRODUCT_PROVIDER = { NAME: 'icici_prudential', ID: 1565 };
const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class Pay extends Component {
  state = {
    loading: false,
    // isShowSavvyPayModal: true,
    // payment: {},
    showLoginModal: false,
    showSuccessModal: false,
    showErrorModal: false,
  };

  componentDidMount() {
    this.createSavingGoalSubscription = EventBus.on(ACTION_TYPES.CREATE_SAVING_GOAL, () =>
      this.setState({ loading: true }),
    );
    this.createSavingGoalSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_SAVING_GOAL_SUCCESS,
      this._createSavingGoalCreationSuccess,
    );
    this.createSavingGoalFailureSubscription = EventBus.on(
      ACTION_TYPES.CREATE_SAVING_GOAL_FAILED,
      () => this.setState({ loading: false }),
    );

    let {
      partnerAccessKey,
      userIdentityToken,
      productUuid,
      productOfferUuid,
      status,
      selectedPaymentMethod,
    } = queryString.parse(this.props.location.search);

    const { savingPlan } = this.props;

    this.props.updateSavingPlan({
      ...savingPlan,
      paymentMethod: selectedPaymentMethod || '',
    });

    this.requestProductSubscription = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.productSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_DETAIL_SUCCEEDED,
      () => this.setState({ loading: false }),
    );
    this.productSubscriptionFailure = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestOfferSubscription = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_OFFERS, () =>
      this.setState({ loading: true }),
    );
    this.offerSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED,
      this._requestOfferSuccess,
    );
    this.offerSubscriptionFailure = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_OFFERS_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestPaymentSubscription = EventBus.on(ACTION_TYPES.REQUEST_GENERATE_PAYMENT, () =>
      this.setState({ loading: true }),
    );
    this.paymentSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.GENERATE_PAYMENT_SUCCESS,
      this._onPaymentGenerated,
    );
    this.paymentSubscriptionFailure = EventBus.on(ACTION_TYPES.GENERATE_PAYMENT_FAILED, () =>
      this.setState({ loading: false }),
    );

    const credentialParams = {};
    const productParams = {};

    if (partnerAccessKey) credentialParams.partnerAccessKey = partnerAccessKey;
    if (userIdentityToken) credentialParams.userIdentityToken = userIdentityToken;

    if (productUuid) productParams.productUuid = productUuid;
    if (productOfferUuid) productParams.productOfferUuid = productOfferUuid;

    if (Object.keys(credentialParams).length > 0) this.props.updateCredentials(credentialParams);
    if (Object.keys(productParams).length > 0) this.props.updateProduct(productParams);

    productUuid = productUuid || this.props.product.productUuid;
    productOfferUuid = productOfferUuid || this.props.product.productOfferUuid;

    if (!this.props.product.uuid && !productUuid && !productOfferUuid) {
      return this.props.history.push('/dashboard');
    }

    if (productUuid) {
      this.props.requestProductDetail({ uuid: productUuid });
    }
    if (productOfferUuid) {
      this.props.requestProductOffers({ uuid: productOfferUuid });
    }

    if (status === 'success' && !this.props.savingPlan.paymentCompleted) {
      this._createSavingGoal();
    }

    if (status === 'success' && this.props.savingPlan.paymentCompleted) {
      this.setState({ showSuccessModal: true });
    } else if (status === 'failure') {
      this.setState({ loading: false, showErrorModal: true });
    }

    if (status === 'success') {
      this.requestVerifyUser = EventBus.on(ACTION_TYPES.REQUEST_VERIFY_USER_TOKEN, () =>
        this.setState({ loading: true }),
      );
      this.verifyUserSuccess = EventBus.on(ACTION_TYPES.VERIFY_USER_TOKEN_SUCCESS, () =>
        this.setState({ loading: false }),
      );
      this.verifyUserFailure = EventBus.on(ACTION_TYPES.VERIFY_USER_TOKEN_FAILED, () =>
        this.setState({ loading: false }),
      );

      if (this.props?.user?.uuid) this.props.requestVerifyUser({ uuid: this.props?.user?.uuid });
    }
  }

  componentWillUnmount() {
    this.requestProductSubscription();
    this.productSubscriptionSuccess();
    this.productSubscriptionFailure();

    this.requestOfferSubscription();
    this.offerSubscriptionSuccess();
    this.offerSubscriptionFailure();

    this.requestPaymentSubscription();
    this.paymentSubscriptionSuccess();
    this.paymentSubscriptionFailure();

    this.createSavingGoalSubscription();
    this.createSavingGoalSuccessSubscription();
    this.createSavingGoalFailureSubscription();

    this.requestVerifyUser && this.requestVerifyUser();
    this.verifyUserSuccess && this.verifyUserSuccess();
    this.verifyUserFailure && this.verifyUserFailure();
  }

  _requestOfferSuccess = (offer) => {
    const { savingPlan } = this.props;
    const { paymentMethodsAvailable = [] } = offer || {};
    const paymentMethod =
      paymentMethodsAvailable.length === 1 ? paymentMethodsAvailable[0] : savingPlan?.paymentMethod;

    this.setState({ loading: false }, () => {
      this.props.updateSavingPlan({
        ...offer,
        ...savingPlan,
        paymentMethod,
      });
    });
  };

  _createSavingGoal = () => {
    const { product, savingPlan } = this.props;
    const { paymentCalendar = {}, paymentId, paymentMethod } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );

    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const goalAbsoluteAmount = _.sumBy(sortedPaymentCalendarDates, (key) => {
      if (key !== firstPaymentKey) {
        return Number(paymentCalendar[key]);
      }
    });

    let finalEndDate = '';
    if (sortedPaymentCalendarDates && sortedPaymentCalendarDates.length > 0) {
      const lastInstallmentDate = sortedPaymentCalendarDates[sortedPaymentCalendarDates.length - 1];
      finalEndDate = moment(lastInstallmentDate, 'DDMMYYYY').add(31, 'days').format('YYYY-MM-DD');
    }

    const imageLink =
      product?.imageLink ||
      (product?.imageUrls && product?.imageUrls.length > 0 && product?.imageUrls[0]?.url);

    this.props.createSavingGoal({
      saving_goal: {
        first_payment_id: paymentId,
        description: savingPlan.productOfferName,
        saving_goal_category: SAVE_NOW_BUY_LATER,
        goal_absolute_amount: goalAbsoluteAmount,
        end_date: finalEndDate,
        preferred_product_provider: PRODUCT_PROVIDER.NAME,
        preferred_product_id: PRODUCT_PROVIDER.ID,
        product_offer_uuid: savingPlan.uuid,
        unsplash_image: {
          link: imageLink || '',
        },
        routine: {
          time_interval: 'scheduled',
          automated: true,
        },
        multiplier: 1,
        payment_method: paymentMethod,
      },
    });
  };

  _onProceedPayment = () => {
    if (!this.props.user.uuid) {
      this.setState({ showLoginModal: true });
    } else {
      this._onProceedBooking();
    }
  };

  _onProceedBooking = () => {
    const { product = {}, savingPlan } = this.props;
    const productOffer =
      product.productOffers && product.productOffers.length > 0 ? product.productOffers[0] : {};
    const { productOfferName, paymentCalendar } = productOffer;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const firstPaymentAmount = paymentCalendar[firstPaymentKey];
    const { origin } = document.location;

    this.props.updateSavingPlan({
      ...productOffer,
      productOffer,
      paymentMethod: savingPlan?.paymentMethod,
    });

    this.props.requestGeneratePayment({
      payment: {
        amount: firstPaymentAmount,
        info: productOfferName,
        redirect_url: `${origin}/checkout`,
      },
    });
  };

  _onPaymentGenerated = ({ payment, orderId }) => {
    const { user, partner, savingPlan } = this.props;
    const amount = Number(payment.amount) * 100; // send it as cents ex: 100Rs as 10000 cents

    const options = {
      key: RAZORPAY_KEY,
      amount,
      currency: 'INR',
      name: partner.name,
      description: savingPlan.productOfferName,
      image: partner.logoImageLink,
      order_id: orderId,
      callback_url: `${API_ENDPOINT_URL}/api/partners/razorpay/payments/redirect`,
      prefill: {
        name: user.firstName,
        email: user.email,
        contact: user.phoneNumber,
      },
      theme: {
        color: '#ffd401',
      },
    };

    this.setState({ loading: false }, () => {
      this.props.updateSavingPlan({
        ...savingPlan,
        paymentId: payment.id,
        paymentRequestId: payment.requestId,
      });

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  };

  _createSavingGoalCreationSuccess = () => {
    const { savingPlan } = this.props;
    savingPlan.paymentCompleted = true;

    this.props.updateSavingPlan(savingPlan);
    this.setState({ loading: false, showSuccessModal: true });
  };

  _onCloseSignInModal = () => {
    this.setState({ showLoginModal: false });
  };

  _onSignInComplete = () => {
    this.setState({ showLoginModal: false }, () => {
      this._onProceedPayment();
    });
  };

  _onChangePaymentMethod = (paymentMethod) => {
    const { savingPlan } = this.props;
    this.props.updateSavingPlan({
      ...savingPlan,
      paymentMethod,
    });
  };

  render() {
    const { loading, showSuccessModal, showErrorModal } = this.state;
    const { partner, product, user, savingPlan } = this.props;
    const { productOffers } = product || {};
    const offer = productOffers && productOffers.length > 0 ? productOffers[0] : {};
    const { paymentMethodsAvailable = [] } = offer || {};

    if (showSuccessModal) {
      return <PaySuccess />;
    }

    if (showErrorModal) {
      return <PayError />;
    }

    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box-outer']}>
            <div className={styles['left-box']}>
              <div className={styles['pay-header']}>
                <h4 className={styles['pay-heading']}>Please choose the way you want to pay</h4>
                <div className={styles['options-container']}>
                  {paymentMethodsAvailable &&
                    paymentMethodsAvailable.length > 0 &&
                    paymentMethodsAvailable.map((paymentMethod, index) => {
                      if (!PAYMENT_METHODS[paymentMethod]) return null;
                      return (
                        <button
                          onClick={() => this._onChangePaymentMethod(paymentMethod)}
                          className={cx(styles['option-button'], {
                            [styles['active']]: paymentMethod === savingPlan?.paymentMethod,
                          })}
                          key={`option-${index}`}
                        >
                          {paymentMethodsAvailable.length > 1 ? (
                            <span className={styles['option-circle']} />
                          ) : null}
                          <img
                            src={`/img/${paymentMethod}.svg`}
                            alt={PAYMENT_METHODS[paymentMethod]}
                          />
                          <h4>{PAYMENT_METHODS[paymentMethod]}</h4>
                        </button>
                      );
                    })}
                </div>
                <div className={styles['pay-content']}>
                  <div className={styles['pay-title']}>
                    Payment request from &#34;{partner.name}&#34;
                  </div>
                  <div className={styles['pay-sub-title']}>PAYMENT FOR</div>
                  <div className={styles['pay-desc']}>{offer.productOfferName || '-'}</div>
                  <div className={styles['pay-amount']}>
                    <div className={styles['pay-total']}>
                      <div className={styles['pay-sub-title']}>PAYABLE TODAY</div>
                      <div className={styles['pay-value']}>
                        {Convert.currency(offer?.upfrontPaymentAmount || 0)}
                      </div>
                    </div>
                    <div>
                      <div className={styles['pay-sub-title']}>COST OF OFFER</div>
                      <div className={styles['pay-value']}>
                        {Convert.currency(offer?.discountedAmount)}
                      </div>
                    </div>
                  </div>
                  <div className={styles['pay-line']} />
                </div>
              </div>
              <div
                className={cx(styles['footer'], {
                  [styles['disabled-footer']]: !savingPlan?.paymentMethod,
                })}
                disabled={!savingPlan?.paymentMethod}
                onClick={() => savingPlan?.paymentMethod && this._onProceedPayment()}
              >
                PROCEED
              </div>
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox
              partner={partner}
              product={product}
              savingPlan={savingPlan}
              isPayScreen
              user={user}
              onProceedPayment={this._onProceedPayment}
            />
          </div>
        </div>
        <SignInSignUpModal
          isShow={this.state.showLoginModal}
          closeModal={this._onCloseSignInModal}
          onComplete={this._onSignInComplete}
        />
        {/* <ExtraInfo /> */}
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(Pay);

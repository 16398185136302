import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  requestAdminTransactionDetailSuccess,
  requestAdminTransactionDetailFailure,
  requestAdminTransactionDepositListSuccess,
  requestAdminTransactionDepositListFailed,
  requestAdminTransactionWithdrawalListSuccess,
  requestAdminTransactionWithdrawalListFailed,
  requestAdminTransactionListSuccess,
  requestAdminTransactionListFailed,
} from '../actions';
import {
  adminTransactionList,
  adminTransactionDetail,
  adminTransactionDepositList,
  adminTransactionWithdrawalList,
} from '../api';

export function* watchAdminTransactionRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DETAIL, requestAdminTransactionDetail);
  yield takeEvery(
    ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST,
    requestAdminTransactionDepositList,
  );
  yield takeEvery(
    ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST,
    requestAdminTransactionWithdrawalList,
  );
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST, requestAdminTransactionList);
}

function* requestAdminTransactionList(action) {
  try {
    const transactions = yield call(adminTransactionList, action.payload);
    yield put(requestAdminTransactionListSuccess(transactions));
  } catch (e) {
    yield put(requestAdminTransactionListFailed(e));
  }
}

function* requestAdminTransactionDepositList(action) {
  try {
    const transactions = yield call(adminTransactionDepositList, action.payload);
    yield put(requestAdminTransactionDepositListSuccess(transactions));
  } catch (e) {
    yield put(requestAdminTransactionDepositListFailed(e));
  }
}

function* requestAdminTransactionWithdrawalList(action) {
  try {
    const transactions = yield call(adminTransactionWithdrawalList, action.payload);
    yield put(requestAdminTransactionWithdrawalListSuccess(transactions));
  } catch (e) {
    yield put(requestAdminTransactionWithdrawalListFailed(e));
  }
}

function* requestAdminTransactionDetail(action) {
  try {
    const transaction = yield call(adminTransactionDetail, action.payload);
    yield put(requestAdminTransactionDetailSuccess(transaction));
  } catch (e) {
    yield put(requestAdminTransactionDetailFailure(e));
  }
}

import { takeEvery, put, call } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';

import { createSavingGoal, getGoalList, getGoalDetail, cancelPurchase } from 'src/redux/api';
import { 
  createSavingGoalSuccess, 
  createSavingGoalFailure, 
  requestGoalListSuccess, 
  requestGoalListFailed,
  requestGoalDetailSuccess,
  requestGoalDetailFailed,
  cancelPurchaseSuccess,
  cancelPurchaseFailure
} from 'src/redux/actions';

export function* watchSavingGoalRequests() {
  yield takeEvery(ACTION_TYPES.CREATE_SAVING_GOAL, requestCreateSavingGoal);
  yield takeEvery(ACTION_TYPES.REQUEST_GOAL_LIST, requestGoalList);
  yield takeEvery(ACTION_TYPES.REQUEST_GOAL_DETAIL, requestGoalDetail);
  yield takeEvery(ACTION_TYPES.CANCEL_PURCHASE_REQUEST, requestCancelPurchase);
}

function* requestCreateSavingGoal(action) {
  try {
    const savingGoal = yield call(createSavingGoal, action.payload);
    yield put(createSavingGoalSuccess(savingGoal));
  } catch (e) {
    yield put(createSavingGoalFailure(e));
  }
}

function* requestGoalList(action) {
  try {
    const goalList = yield call(getGoalList, action.payload);
    yield put(requestGoalListSuccess(goalList));
  } catch (e) {
    yield put(requestGoalListFailed(e));
  }
}

function* requestGoalDetail(action) {
  try {
    const goalDetail = yield call(getGoalDetail, action.payload);
    yield put(requestGoalDetailSuccess(goalDetail));
  } catch (e) {
    yield put(requestGoalDetailFailed(e));
  }
}

function* requestCancelPurchase(action) {
  try {
    const canceledGoal = yield call(cancelPurchase, action.payload);
    yield put(cancelPurchaseSuccess(canceledGoal));
  } catch (e) {
    yield put(cancelPurchaseFailure(e));
  }
}

import { connect } from 'react-redux';
import { requestVerifyOTP, requestResendOtp } from 'src/redux/actions';
import OtpLoginVerify from './OtpLoginVerify';

function mapStateToProps(state) {
  return {
    user: state.user,
    product: state.product,
  };
}

export default connect(mapStateToProps, { requestVerifyOTP, requestResendOtp })(OtpLoginVerify);
import { connect } from 'react-redux';
import AdminProtectedRoute from './admin-protected-route';

function mapStateToProps({ adminCredentials }) {
  const { partnerAccessKey, userIdentityToken } = adminCredentials;

  return {
    partnerAccessKey,
    userIdentityToken,
  };
}

export default connect(mapStateToProps, {})(AdminProtectedRoute);

import React from 'react';
import styles from './_securedpartners.module.scss';

export default function SecuredPartners(props) {
  return (
    <div className={styles['partner-box']} style={{ ...props.styles }}>
      <div className={styles['message']}>100% secured by our partners</div>
      <div className={styles['partner-images']}>
        <img src="/img/axis.png" alt="axis" />
        <img src="/img/sebi3.png" alt="sebi" />
        <img src="/img/amfi1.png" alt="amfi" />
        <img src="/img/icici2.png" alt="icici" />
      </div>
    </div>
  );
}

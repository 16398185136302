import React from 'react'
import cx from 'classnames'
import styles from './_button-one.module.scss'

export const ButtonOne = (props) => {
  const {
    variant="one",
    type="button",
    className,
    ...otherprops
  } = props

  return (
    <button className={cx(styles[variant], className)} type={type} {...otherprops}>
      {props?.text}
    </button>
  )
}

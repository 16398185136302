import React, { Component } from 'react'
import { Screen, JumbotronOne, CustomCardThree, CtaTwo, BootstrapModal, Loader } from 'src/components'
import { Convert } from 'src/helpers'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { TextInputThree } from 'src/components/form-inputs'
import { ACTION_TYPES } from 'src/constants'
import EventBus from 'eventing-bus';
import styles from './_finalpayment.module.scss'
// import modalStyles from './modal.styles.scss';

export class FinalPayment extends Component {
  state={
    loading: false,
    transactionId: null,
    otpId: null,
    isSubmitting: false,
    showOtpModal: false
  }

  componentDidMount() {    
    this.savingGoalRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL, () => this.setState({ loading: true }));
    this.savingGoalSuccessSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS, () => this.setState({ loading: false }));
    this.savingGoalFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED, () => this.setState({ loading: false }));

    this.folioWithdrawRequestSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_REQUEST, () => this.setState({ loading: true }));
    this.folioWithdrawSuccessSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_SUCCESS, this._onfolioWithdrawSuccess);
    this.folioWithdrawFailureSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_FAILED, () => this.setState({ loading: false }));

    this.folioWithdrawOTPRequestSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_OTP_REQUEST, () => this.setState({ isSubmitting: true }));
    this.folioWithdrawOTPSuccessSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_OTP_SUCCESS, this._onFolioWithdrawOTPSuccess);
    this.folioWithdrawOTPFailureSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_OTP_FAILED, () => this.setState({ isSubmitting: false }));
    
    this.folioWithdrawResendOTPRequestSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_REQUEST, () => this.setState({ isSubmitting: true }));
    this.folioWithdrawResendOTPSuccessSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_SUCCESS, () => this.setState({ isSubmitting: false }));
    this.folioWithdrawResendOTPFailureSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_FAILED, () => this.setState({ isSubmitting: false }));
    
    this.validateFolioWithdrawRequestSubscription = EventBus.on(ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_REQUEST, () => this.setState({ isSubmitting: true }));
    this.validateFolioWithdrawSuccessSubscription = EventBus.on(ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_SUCCESS, this._onvalidateFolioWithdrawSuccess);
    this.validateFolioWithdrawFailureSubscription = EventBus.on(ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_FAILED, () => this.setState({ isSubmitting: false }));
    
    this.props.updateCredentials({ partnerAccessKey: this.props.match.params.key });

    if (!this.props.credentials.userIdentityToken) {
      return this.props.history.push({ 
        pathname: '/sign-in',
        state: {
          redirectTo: this.props.history.location.pathname
        }
      });
    }

    
    this.props.requestGoalDetail({ id: this.props.match.params.goalId });    
  }

  componentWillUnmount() {
    this.savingGoalRequestSubscription();
    this.savingGoalSuccessSubscription();
    this.savingGoalFailureSubscription();

    this.folioWithdrawRequestSubscription();
    this.folioWithdrawSuccessSubscription();
    this.folioWithdrawFailureSubscription();

    this.folioWithdrawOTPRequestSubscription();
    this.folioWithdrawOTPSuccessSubscription();
    this.folioWithdrawOTPFailureSubscription();

    this.folioWithdrawResendOTPRequestSubscription();
    this.folioWithdrawResendOTPSuccessSubscription();
    this.folioWithdrawResendOTPFailureSubscription();

    this.validateFolioWithdrawRequestSubscription();
    this.validateFolioWithdrawSuccessSubscription();
    this.validateFolioWithdrawFailureSubscription();
  }

  _validationSchema = yup.object().shape({
    otp: yup
    .string()
    .required('Please enter the OTP.')
    .matches(/^\d{4}$/, { message: 'Please enter a 4 digit OTP' }),
  })

  _onWithdrawFolio = () => {    
    this.props.folioWithdrawRequest({
      amount: this.props.currentBalance,
      saving_goal_id: this.props.savingGoalId,
      pay_after_reconciliation: true
    })
  }

  _onfolioWithdrawSuccess = ({ transactionId }) => {    
    this.setState({ loading: false, showOtpModal: true, transactionId }, () => {
      this.props.folioWithdrawOTPRequest({ transaction_id: transactionId });
    })    
  }

  _onFolioWithdrawOTPSuccess = (response) => {
    this.setState({ otpId: response.otpId })
  }

  _onValidateOTP = ({ otp }) => {
    this.props.validateFolioWithdrawRequest({
      transaction_id: this.state.transactionId,
      otp_id: this.state.otpId,
      otp,
      withdrawal: {
        saving_goal_id: this.props.savingGoalId,
        account_id: this.props.savingAccount.id,
        amount: this.props.currentBalance,
      }
    })
  }

  _resendOTP = () => {
    this.props.folioWithdrawResendOTPRequest({ transaction_id: this.state.transactionId });
  }

  _onvalidateFolioWithdrawSuccess = () => {
    this.setState({ showOtpModal: false }, () => {
      // withdrawal success message
    });
  }

  render() {
    const { totalGoalAmount, totalAmountPaid, balanceAmount } = this.props;
    const { showOtpModal } = this.state
    return (
      <>
      <div className={styles.container}>
        <Screen contentClassName={styles.contentClassName}>
          <JumbotronOne
            className={styles.jumbotron}            
            imgSrc="https://images.unsplash.com/photo-1582675002716-5f953d174ecb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
            title={Convert.currency(balanceAmount)}
            subTitle="Remaining Cost of Holiday"
            leading="World of Vacations"
          />
          <div className={styles.main} role="main">
            <div className={styles.contentHeader}>
              <div className={styles.heading}>
                Make your final payment and book your holiday!
              </div>
            </div>            
            <CustomCardThree
              label="Amount to be saved"
              labelstyles={styles.labelstyles}
              rightContent={Convert.currency(totalGoalAmount)}
              rightContentStyles={styles.rightContentStyleThree}
              containerstyles={styles.customCardThree}
            />
            <CustomCardThree
              label="You have already paid"
              labelstyles={styles.labelstyles}
              rightContent={Convert.currency(totalAmountPaid)}
              rightContentStyles={styles.rightContentStyleOne}
              containerstyles={styles.customCardThree}
            />
            <CustomCardThree
              label="Amount left to pay"
              labelstyles={styles.labelstyles}
              rightContent={Convert.currency(balanceAmount)}
              rightContentStyles={styles.rightContentStyleTwo}
              containerstyles={styles.customCardThree}
            />
            
          </div>
          <div className={styles.ctaContainer}>
            <div className={styles.text}>You can redeem your investment anytime you need to by logging into your account</div>
            <CtaTwo
              text="SEND OTP & PAY"
              className={styles.ctaTwo}
              onClick={this._onWithdrawFolio}
            />
          </div>          
        </Screen>
        <Loader loading={this.state.loading}/>
      </div>
      <BootstrapModal show={showOtpModal} className={styles.otpModal} onClose={() => this.setState({ showOtpModal: false })}>
        <div className={styles.otpModalContent}>
          <div className={styles.header}>
            <div className={styles.heading}>
              Pay Last Installment
            </div>
            <div className={styles.sub}>
              Please enter the 4 digit OTP send to your phone to complete the transaction
            </div>
          </div>
          <Formik
            initialValues={{
              otp: ''
            }}
            validationSchema={this._validationSchema}
            onSubmit={this._onValidateOTP}
          >            
            <Form>
              <TextInputThree
                name="otp"
                id="otp"
                label=""
                type="number"                             
              />
              <div className="row">
                <button 
                  className={styles.resendOTP} 
                  onClick={this._resendOTP}
                >
                  Resend OTP
                </button>
              </div>                
              <CtaTwo
                type="submit"
                text="Verify OTP"
                className={styles.ctaTwo}
              />
              <Loader loading={this.state.isSubmitting}/>
            </Form>            
          </Formik>
        </div>
      </BootstrapModal>
      </>
    )
  }
}

export default FinalPayment

// import { useField } from 'formik';
import React from 'react';
import cx from 'classnames';
import { useField } from 'formik';
import { MandatoryMark } from 'src/components';
import styles from './_inputselectthree.module.scss';

function InputSelectThree({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <div style={{ ...props.containerstyles }}>
        <div className={cx(styles.inputLabelContainer, props.labelstyles)}>
          <label htmlFor={props.id || props.name} className={styles.inputLabel}>
            {label}
            {props.mandatory && <MandatoryMark />}
          </label>
        </div>
        <div className={styles.selectContainer}>
          <select className={`${styles.select} form-select`} {...field} {...props} />
          {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
        </div>
      </div>
    </>
  );
}

export default InputSelectThree;

import React, { useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import SolidHeader from 'src/components/headings/solid-header';
import { Convert } from 'src/helpers';
import styles from './_productdetailsread.module.scss';

export default function ProductDetailsRead(props) {
  const history = useHistory();
  const { product, handleEditState } = props;
  const [activeKey, setActiveKey] = useState(1);

  const prepareBody = (body) => (body && body !== 'null' ? Convert.toRawHtml(body) : '');

  const imageUrl =
    (product?.imageUrls && product?.imageUrls.length > 0 && product?.imageUrls[0].url) || '';
  return (
    <>
      <Card className={styles.card}>
        <Card.Header className={styles.cardHeader}>
          <span className={styles.cardHeading}>Product Details</span>
          <span
            className={`${styles.anchorHand} ${styles.toggleButton}`}
            onClick={() => handleEditState()}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </span>
        </Card.Header>
        <Card.Body>
          <Row className={styles.cardRow}>
            <Col md={3}>
              <Row className={styles.cardRow}>
                <Col>
                  <div className={styles.mainImage}>
                    {imageUrl || product?.imageLink ? (
                      <img src={imageUrl || product?.imageLink} alt="img" />
                    ) : (
                      <div className={styles.innerImageBox}>No Image</div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={9}>
              <div className={styles.list}>
                <label className={styles.inputLabel}>Product Name</label>
                <span>{product && product.productName}</span>
              </div>
              <div className={styles.list}>
                <label className={styles.inputLabel}>Product Amount</label>
                <span>{product && Convert.toCurrencyValue(product.amount)}</span>
              </div>
              {product?.textFields &&
                product.textFields.map((textField, index) => (
                  <div className={`${styles.list} ${styles.description}`} key={index}>
                    <label className={styles.inputLabel}>{textField.heading}</label>
                    <div className={styles.editor}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: prepareBody(textField.body),
                        }}
                      />
                    </div>
                  </div>
                ))}
            </Col>
            <Col className={styles.buttonContainer}>
              <div className={styles['custom-btn-group']}>
                <button
                  className={styles['custom-btn']}
                  onClick={() =>
                    history.push({
                      pathname: '/admin/products/offers/new',
                      state: {
                        productUuid: product.uuid,
                      },
                    })
                  }
                >
                  Create product Offer
                </button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* Card: Product Details */}
      <Accordion defaultActiveKey={1} activeKey={activeKey}>
        {product?.productOffers &&
          product?.productOffers.length > 0 &&
          product.productOffers.map((productOffer, index) => {
            const accordionIndex = index + 1;
            const productOfferDescriptionData =
              (productOffer &&
                productOffer?.description &&
                productOffer?.description !== 'null' &&
                Convert.toRawHtml(productOffer?.description)) ||
              '';
            return (
              <Card className={styles.card} key={productOffer?.uuid}>
                <Card.Header className={styles.cardHeader}>
                  <span className={styles.cardHeading}>Offer #{accordionIndex} Details</span>
                  <span
                    className={`${styles.anchorHand} ${styles.toggleButton}`}
                    onClick={() => {
                      if (activeKey === accordionIndex) {
                        setActiveKey(null);
                      } else {
                        setActiveKey(accordionIndex);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={activeKey == accordionIndex ? faMinus : faPlus} />
                  </span>
                </Card.Header>
                <Accordion.Collapse eventKey={accordionIndex}>
                  <>
                    <Card.Body className={styles.cardBody}>
                      <div className={styles.cardSection}>
                        <Row className={styles.cardRow}>
                          <Col md={6} className={styles.cardCol}>
                            <Row className={styles.cardRow}>
                              <Col className={styles.inputLabelContainer}>
                                <label className={styles.inputLabel}>Offer Name</label>
                              </Col>
                              <Col>
                                <span>{productOffer?.productOfferName}</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row className={styles.cardRow}>
                              <Col className={styles.inputLabelContainer}>
                                <label className={styles.inputLabel}>Offer Description</label>
                              </Col>
                              <Col>
                                <span>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: productOfferDescriptionData,
                                    }}
                                  />
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <SolidHeader
                        heading="Define Pricing"
                        styles={{
                          fontFamily: 'Avenir-DemiBold',
                        }}
                        headerStyles={{
                          paddingLeft: '1rem',
                          marginBottom: '1rem',
                        }}
                      />
                      <div className={styles.cardSection}>
                        <Row>
                          <Col sm={6}>
                            <Row className={styles.cardRow}>
                              <Col className={styles.inputLabelContainer}>
                                <label className={styles.inputLabel}>Discount Percentage</label>
                              </Col>
                              <Col>
                                <span>{productOffer?.discountPercentage}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <SolidHeader
                        heading="Define Savings Time Period"
                        styles={{
                          fontFamily: 'Avenir-DemiBold',
                        }}
                        headerStyles={{
                          paddingLeft: '1rem',
                          marginBottom: '1rem',
                        }}
                      />
                      <div className={styles.cardSection}>
                        {productOffer?.isFixedMonths ? (
                          <Row>
                            <Col md={6}>
                              <Row className={styles.cardRow}>
                                <Col className={styles.inputLabelContainer}>
                                  <label className={styles.inputLabel}>Total Months</label>
                                </Col>
                                <Col>
                                  <span>{productOffer?.months}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col md={6}>
                              <Row className={styles.cardRow}>
                                <Col className={styles.inputLabelContainer}>
                                  <label className={styles.inputLabel}>Min Months</label>
                                </Col>
                                <Col>
                                  <span>{productOffer?.minMonths}</span>
                                </Col>
                              </Row>
                              <Row className={styles.cardRow}>
                                <Col className={styles.inputLabelContainer}>
                                  <label className={styles.inputLabel}>Max Months</label>
                                </Col>
                                <Col>
                                  <span>{productOffer?.maxMonths}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </div>
                      {productOffer.isUpfrontPaymentRequired && (
                        <>
                          <SolidHeader
                            heading="Define Payment"
                            styles={{
                              fontFamily: 'Avenir-DemiBold',
                            }}
                            headerStyles={{
                              paddingLeft: '1rem',
                              marginBottom: '1rem',
                            }}
                          />
                          <div className={styles.cardSection}>
                            <Row>
                              <Col md={6}>
                                <Row className={styles.cardRow}>
                                  <Col className={styles.inputLabelContainer}>
                                    <label className={styles.inputLabel}>
                                      Upfront payment amount
                                    </label>
                                  </Col>
                                  <Col>
                                    <span>{productOffer?.upfrontPaymentAmount}</span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </>
                </Accordion.Collapse>
              </Card>
            );
          })}
      </Accordion>
    </>
  );
}

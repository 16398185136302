import { connect } from 'react-redux';
import EventBus from 'eventing-bus';
import { Modal } from 'react-bootstrap';
import { requestAdminOffersList, createAdminOffer, requestShortenUrl } from 'src/redux/actions';
import { ButtonOne } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import React, { Component } from 'react';
import styles from './_create-another-offer-modal.module.scss';
import './_modal.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class CreateAnotherOfferModal extends Component {
  shortenedUrl = null;

  state = {
    loading: false,
    shortenedUrl: '',
    linkCopied: false,
  };

  componentDidMount() {
    this._onGeneratePaymentLink();

    this.generateUrlSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL, () =>
      this.setState({ loading: true }),
    );
    this.generateUrlSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SHORTEN_URL_SUCCESS,
      this._onUrlGenerated,
    );
    this.generateUrlFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  _onUrlGenerated = (response) => {
    const { referenceId = '' } = response || {};
    const shortenedUrl = `${API_ENDPOINT_URL}?id=${referenceId}`;
    this.setState({ loading: false, shortenedUrl });
  };

  _onGeneratePaymentLink = () => {
    const { origin } = document.location;
    const { adminCredentials, offers, requestShortenUrl } = this.props;
    const productOffer = offers?.productOffer;
    let link = `${origin}?partnerAccessKey=${adminCredentials.partnerAccessKey}`;
    link += productOffer?.uuid
      ? `&productOfferUuid=${productOffer?.uuid}`
      : `&productUuid=${productOffer?.product?.uuid}`;

    const linkParams = {
      payment_link: {
        link,
      },
    };

    if (productOffer?.uuid) linkParams.payment_link.product_offer_uuid = productOffer?.uuid;
    if (productOffer?.product?.uuid)
      linkParams.payment_link.product_uuid = productOffer?.product?.uuid;

    requestShortenUrl(linkParams);
  };

  _copyClipboard = (e) => {
    this.shortenedUrl.select();
    this.shortenedUrl.setSelectionRange(0, 99999);

    document.execCommand('copy');
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 2000);
    e.preventDefault();
  };

  render() {
    const { showCreateAnotherModal, closeCreateAnotherModal, handleCreateAnotherOffer, product } =
      this.props;
    const { loading } = this.state;

    return (
      <div>
        <Modal show={showCreateAnotherModal} size="lg">
          <div className={styles['close-btn']}>
            <span onClick={closeCreateAnotherModal}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </span>
          </div>
          <div className={styles['alert-icon']}>
            <img src="/img/new-offer-ok.svg" alt="alert-icon" />
          </div>
          <div className={styles['title']}>
            Your offer ‘{product?.productName}’ has been created
          </div>
          <div>
            {loading ? (
              <div>Generating payment link...</div>
            ) : (
              <div className={styles.urlContainer}>
                <input
                  className={styles.urlInput}
                  ref={(ref) => (this.shortenedUrl = ref)}
                  value={this.state.shortenedUrl}
                  readOnly
                />
                {!this.state.linkCopied && (
                  <div
                    className={styles.copied}
                    style={{ cursor: 'pointer' }}
                    onClick={this._copyClipboard}
                  >
                    Click to copy payment link
                  </div>
                )}
                {this.state.linkCopied && (
                  <div className={styles.copied}>Link Copied to clipboard</div>
                )}
              </div>
            )}
          </div>
          <div className={styles['group-btns']}>
            <div className={styles['inner-btn']}>
              <ButtonOne text="Ok" type="submit" onClick={handleCreateAnotherOffer} />
            </div>
            {/* <div className={styles['inner-btn']}>
              <ButtonOne text="Send offer" type="submit" onClick={closeCreateAnotherModal} />
            </div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    adminCredentials: state.adminCredentials,
    offers: state.adminOffers,
  };
}

export default connect(mapStateToProps, {
  requestAdminOffersList,
  createAdminOffer,
  requestShortenUrl,
})(CreateAnotherOfferModal);

import React, { Component } from 'react';
import * as yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { TextInputOne } from 'src/components/form-inputs';
import { Loader } from 'src/components';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import { Container, Section, ButtonOne } from '../../components';
import styles from './_profile.module.scss';

export class Profile extends Component {
  state = {
    loading: false,
  };

  _validationSchema = () =>
    yup.object().shape({
      // logo_image_link: yup.mixed()
      //   .test(
      //     'imaegSize',
      //     'Image size is large',
      //     (val) => {
      //       console.log(val.size, 'test 1')
      //       return val?.size < (5 * 1000 * 1000)
      //     }
      //   )
      //   .test(
      //     'imageType',
      //     'Image type is not valid',
      //     (val) =>  {
      //       console.log(val.type, 'test 2');
      //       return ['image/jpg', 'image/jpeg', 'image/png'].includes(val?.type)
      //     }
      //   ),
      logo_image_link: yup
        .string()
        .required('Please enter a url.')
        .url('Please enter a valid url.'),
      legal_name: yup.string().required('Please enter legal name'),
      email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Please enter email address.'),
      phone_number: yup
        .string()
        .required('Please enter phone number.')
        .matches(/^\+[1-9]{2}[6-9]\d{9}$/, {
          message: 'Phone number not valid',
        }),
      address: yup.string().required('Please enter address line one'),
      pincode: yup
        .string()
        .required('Please enter pin code')
        .matches(/^[1-9][0-9]{5}$/, {
          message: 'Please enter a valid pin code',
        }),
      pan_number: yup
        .string()
        .required('Please enter Pan number')
        .matches(/^[A-Z]{5}\d{4}[A-Z]{1}$/, {
          message: 'Please enter a valid pan number.',
        }),
      gst_number: yup
        .string()
        .required('Please enter gst number.')
        .matches(/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, {
          message: 'PLease enter a valid gst number.',
        }),
    });

  componentDidMount() {
    this.requestAdminProfile = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PROFILE, () =>
      this.setState({ loading: true }),
    );
    this.adminProfileSuccess = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminProfileFailed = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminProfileUpdate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PROFILE_UPDATE, () =>
      this.setState({ loading: true }),
    );
    this.adminProfileUpdateSuccess = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_UPDATE_SUCCESS, () => {
      this.setState({ loading: false });
      notify.show('Profile updated successfully', 'success');
    });
    this.adminProfileUpdateFailed = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_UPDATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (!this.props.admin.isAdmin) {
      return this.props.history.push('/admin');
    }

    this.props.requestAdminProfile();
  }

  componentWillUnmount() {
    this.requestAdminProfile();
    this.adminProfileSuccess();
    this.adminProfileFailed();

    this.requestAdminProfileUpdate();
    this.adminProfileUpdateSuccess();
    this.adminProfileUpdateFailed();
  }

  render() {
    const { partner = {} } = this.props;

    return (
      <div className={styles['profile']}>
        <Container title="Your Profile">
          <Formik
            initialValues={{
              legal_name: partner?.legalName || '',
              email: partner?.email || '',
              phone_number: partner?.phoneNumber || '',
              address: partner?.address || '',
              pincode: partner?.pincode || '',
              pan_number: partner?.panNumber || '',
              gst_number: partner?.gstNumber || '',
              logo_image_link: partner?.logoImageLink || '',
            }}
            validationSchema={this._validationSchema}
            onSubmit={(values) => {
              // let formData = new FormData()
              // const formValues = {...values}

              // Object.keys(formValues).map(key => formData.append(`partner[${key}]`, formValues[key]))

              // for (let val of formData.entries()) {
              //   console.log(val[0], val[1]);
              // }
              this.props.requestAdminProfileUpdate({ partner: { ...values } });
            }}
          >
            <Form className={styles.formContainer}>
              <Section title="">
                <Row>
                  <Col sm={4}>
                    {/* <InputFileFour
                label="Logo Image"
                name="logo_image_link"
                id="logo_image_link"
                type="file"
                containerstyles={styles.imageContainer}
                labelContainerStyles={styles.labelContainer}
                labelstyles={styles.label}
                onChange={e => setFieldValue('logo_image_link', e.target.files[0])}
              /> */}
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="Logo Image"
                        name="logo_image_link"
                        id="logo_image_link"
                        containerstyles={styles.inputContainer}
                        labelcontainerstyles={styles.labelContainer}
                        fieldcontainerstyles={styles.fieldContainer}
                        inputstyles={styles.inputStyles}
                        placeholder="Please Enter an Image url"
                      />
                    </div>
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="Legal"
                        name="legal_name"
                        id="legal_name"
                        containerstyles={styles.inputContainer}
                        labelcontainerstyles={styles.labelContainer}
                        fieldcontainerstyles={styles.fieldContainer}
                        inputstyles={styles.inputStyles}
                        placeholder="Legal Name"
                      />
                    </div>
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="Email Id"
                        name="email"
                        id="email"
                        containerstyles={styles.inputContainer}
                        labelcontainerstyles={styles.labelContainer}
                        fieldcontainerstyles={styles.fieldContainer}
                        inputstyles={styles.inputStyles}
                        placeholder="Enter email address"
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="Phone Number"
                        name="phone_number"
                        id="phone_number"
                        containerstyles={styles.inputContainer}
                        labelcontainerstyles={styles.labelContainer}
                        fieldcontainerstyles={styles.fieldContainer}
                        inputstyles={styles.inputStyles}
                        placeholder="9999999999"
                      />
                    </div>
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="Address"
                        name="address"
                        id="address"
                        containerstyles={styles.inputContainer}
                        labelcontainerstyles={styles.labelContainer}
                        fieldcontainerstyles={styles.fieldContainer}
                        inputstyles={styles.inputStyles}
                        placeholder="Address line 1"
                      />
                    </div>
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="Picode"
                        name="pincode"
                        id="pincode"
                        inputstyles={styles.inputStyles}
                        placeholder="111111"
                      />
                    </div>
                  </Col>

                  <Col sm={4}>
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="Pan Number"
                        name="pan_number"
                        id="pan_number"
                        inputstyles={styles.inputStyles}
                        placeholder="Enter Pan Number"
                      />
                    </div>
                    <div className={styles['input-box']}>
                      <TextInputOne
                        label="GST Number"
                        name="gst_number"
                        id="gst_number"
                        inputstyles={styles.inputStyles}
                        placeholder="Enter GST Number"
                      />
                    </div>
                  </Col>
                </Row>
                <br />

                <div className={styles['btn-group']}>
                  <ButtonOne text="Save Details" type="submit" />
                </div>
              </Section>
            </Form>
          </Formik>
        </Container>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default withRouter(Profile);

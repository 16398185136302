import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import { Convert } from 'src/helpers';
import cx from 'classnames';
import './_modal.scss';

const STEPS = {
  WITHDRAW_SAVING: 'WITHDRAW_SAVING',
  CONFIRM_SAVING: 'CONFIRM_SAVING',
  DONE: 'DONE',
};

const ICICI_PRU_SCHEME_CODE = 1565;

class WithDrawnMoney extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: STEPS.WITHDRAW_SAVING,
      amount: this.props.isWithdrawAll
        ? Convert.roundDownAmount(props.savingGoal.actualShadowCurrentInvestmentBalance)
        : null,
      isInstantWithdrawAllowed: false,
      amountForInstantWithdrawal: 0,
      regularWithdrawAmount: 0,
      transactionId: null,
      otpId: null,
      shouldResendPin: false,
      otp: null,
      loading: false,
      errors: {
        e_amount: '',
        e_otp: '',
      },
    };
  }

  componentDidMount() {
    this.checkInstantWithdrawalRequestSubscription = EventBus.on(
      ACTION_TYPES.CHECK_INSTANT_WITHDRAWAL_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.checkInstantWithdrawalSuccessSubscription = EventBus.on(
      ACTION_TYPES.CHECK_INSTANT_WITHDRAWAL_SUCCESS,
      this._oncheckInstantWithdrawalSuccess,
    );
    this.checkInstantWithdrawalFailureSubscription = EventBus.on(
      ACTION_TYPES.CHECK_INSTANT_WITHDRAWAL_FAILED,
      () => this.setState({ loading: false }),
    );

    this.folioWithdrawRequestSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.folioWithdrawSuccessSubscription = EventBus.on(
      ACTION_TYPES.FOLIO_WITHDRAW_SUCCESS,
      this._onfolioWithdrawSuccess,
    );
    this.folioWithdrawFailureSubscription = EventBus.on(ACTION_TYPES.FOLIO_WITHDRAW_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.folioWithdrawOTPRequestSubscription = EventBus.on(
      ACTION_TYPES.FOLIO_WITHDRAW_OTP_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.folioWithdrawOTPSuccessSubscription = EventBus.on(
      ACTION_TYPES.FOLIO_WITHDRAW_OTP_SUCCESS,
      this._onfolioWithdrawOTPSuccess,
    );
    this.folioWithdrawOTPFailureSubscription = EventBus.on(
      ACTION_TYPES.FOLIO_WITHDRAW_OTP_FAILED,
      () => this.setState({ loading: false }),
    );

    this.folioWithdrawResendOTPRequestSubscription = EventBus.on(
      ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.folioWithdrawResendOTPSuccessSubscription = EventBus.on(
      ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_SUCCESS,
      this._onfolioWithdrawResendOTPSuccess,
    );
    this.folioWithdrawResendOTPFailureSubscription = EventBus.on(
      ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_FAILED,
      () => this.setState({ loading: false }),
    );

    this.validateFolioWithdrawalRequestSubscription = EventBus.on(
      ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.validateFolioWithdrawalSuccessSubscription = EventBus.on(
      ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_SUCCESS,
      this._onvalidateFolioWithdrawalSuccess,
    );
    this.validateFolioWithdrawalFailureSubscription = EventBus.on(
      ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_FAILED,
      () => this.setState({ loading: false }),
    );

    this.props.checkInstantWithdrawalRequest({
      scheme_code: ICICI_PRU_SCHEME_CODE,
    });
  }

  componentWillUnmount() {
    this.checkInstantWithdrawalRequestSubscription();
    this.checkInstantWithdrawalSuccessSubscription();
    this.checkInstantWithdrawalFailureSubscription();

    this.folioWithdrawRequestSubscription();
    this.folioWithdrawSuccessSubscription();
    this.folioWithdrawFailureSubscription();

    this.folioWithdrawOTPRequestSubscription();
    this.folioWithdrawOTPSuccessSubscription();
    this.folioWithdrawOTPFailureSubscription();

    this.folioWithdrawResendOTPRequestSubscription();
    this.folioWithdrawResendOTPSuccessSubscription();
    this.folioWithdrawResendOTPFailureSubscription();

    this.validateFolioWithdrawalRequestSubscription();
    this.validateFolioWithdrawalSuccessSubscription();
    this.validateFolioWithdrawalFailureSubscription();
  }

  _oncheckInstantWithdrawalSuccess = (data) => {
    if (data !== undefined || data !== null) {
      const isInstantWithdrawAllowed = data.instantWithdrawalAllowed === 'Y';
      const amountForInstantWithdrawal = data.amountForInstantWithdrawal;

      this.setState({ loading: false, isInstantWithdrawAllowed, amountForInstantWithdrawal });
    } else {
      this.setState({
        loading: false,
        isInstantWithdrawAllowed: false,
        amountForInstantWithdrawal: 0,
      });
    }
  };

  _onFolioWithdraw = () => {
    const { isInstantWithdrawAllowed, amount, amountForInstantWithdrawal } = this.state;
    let withdrawalParams = { saving_goal_id: this.props.savingGoal.id };

    if (isInstantWithdrawAllowed && amount > amountForInstantWithdrawal) {
      const differenceAmount = amount - amountForInstantWithdrawal;
      const immediateAmount = amount - differenceAmount;

      this.setState({ regularWithdrawAmount: differenceAmount });

      withdrawalParams = {
        ...withdrawalParams,
        instant_withdrawal_amount: immediateAmount,
        regular_withdrawal_amount: differenceAmount,
        instant_withdrawal: true,
      };
    } else if (isInstantWithdrawAllowed && amount <= amountForInstantWithdrawal) {
      withdrawalParams = {
        ...withdrawalParams,
        instant_withdrawal_amount: amount,
        instant_withdrawal: true,
      };
    } else if (!isInstantWithdrawAllowed || amountForInstantWithdrawal < 1) {
      withdrawalParams = {
        ...withdrawalParams,
        amount,
      };
    }

    this.props.folioWithdrawRequest(withdrawalParams);
  };

  _onfolioWithdrawSuccess = (response) => {
    const transactionId = response.transactionId;
    const { regularWithdrawAmount } = this.state;

    this.setState({ loading: false, transactionId });

    if (transactionId && regularWithdrawAmount && regularWithdrawAmount > 0) {
      this.props.folioWithdrawOTPRequest({ transaction_id: transactionId });
    } else {
      this.handleStep({ stepName: STEPS.DONE });
    }
  };

  _onfolioWithdrawOTPSuccess = () => {
    this.setState({ loading: false }, () => {
      this.handleStep({ stepName: STEPS.CONFIRM_SAVING });
    });
  };

  _onResendOTP = () => {
    this.setState({ shouldResendPin: false }, () => {
      this.props.folioWithdrawResendOTPRequest({ transaction_id: this.state.transactionId });
    });
  };

  _onfolioWithdrawResendOTPSuccess = () => {
    setTimeout(() => {
      this.setState({ loading: false, shouldResendPin: true });
    }, 15000);
  };

  _onVerifyOTP = () => {
    const { transactionId, otpId, otp } = this.state;
    this.props.validateFolioWithdrawRequest({
      transaction_id: transactionId,
      otp_id: otpId,
      otp,
      withdrawal: {
        saving_goal_id: this.props.savingGoal.id,
        account_id: this.props.savingAccount.id,
        amount: this.state.amount,
      },
    });
  };

  _onvalidateFolioWithdrawalSuccess = () => {
    this.setState({ loading: false }, () => {
      this.handleStep({ stepName: STEPS.DONE });
    });
  };

  handleStep = ({ stepName }) => {
    this.setState({ activeStep: stepName });
  };

  handleAmount = (e) => {
    this.setState({ amount: e.target.value }, () => {
      const { amount } = this.state;
      if (!amount) {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_amount: 'Required amount' },
        });
      } else if (amount && parseInt(amount) < 1) {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_amount: 'Amount must be greater than zero' },
        });
      } else {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_amount: '' },
        });
      }
    });
  };

  handleOtp = (e) => {
    this.setState({ otp: e.target.value }, () => {
      const { otp } = this.state;
      if (!otp) {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_otp: 'Required otp' },
        });
      } else if (otp && otp.length !== 5) {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_otp: 'Invalid OTP' },
        });
      } else {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_otp: '' },
        });
      }
    });
  };

  handleDone = () => {
    if (this.props.isWithdrawAll) {
      this.props.showCancelBooking();
    } else {
      this.props.closeModal();
    }
  };

  render() {
    const { isModalOpen, closeModal, bankAccount, savingGoal = {} } = this.props;
    const { activeStep, amount, shouldResendPin, errors, otp } = this.state;
    const isDisabled = !amount || (amount && parseInt(amount) < 1);
    const isDisabledOtp = !otp || otp.length !== 5;

    return (
      <div>
        <Modal show={isModalOpen} size="lg">
          <div className="withdrawn-money-modal">
            <div className="cross-icon">
              <span onClick={closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
            {activeStep === STEPS.WITHDRAW_SAVING && (
              <div className="withdraw-saving">
                <div className="title">How much would you like to withdraw?</div>
                <div className="amount">
                  Total Saved: {Convert.currency(savingGoal.actualShadowCurrentInvestmentBalance)}
                </div>
                <div className="input-box">
                  <input
                    value={this.state.amount || ''}
                    type="number"
                    className="text-input"
                    placeholder="Please enter the amount you wish to withdraw"
                    onChange={this.handleAmount}                    
                  />
                  {errors && errors?.e_amount && <div className="error">{errors?.e_amount}</div>}
                </div>
                <div className="horizontal-line-box">
                  <div className="horizontal-line" />
                </div>
                <div className="transfer">
                  <div className="transfer-box">
                    <div className="label">Transfer from</div>
                    <div className="text">ICICI Prudential Liquid Fund</div>
                  </div>
                  <img src="/img/arrow-right-1.png" alt="arrow-right" />
                  <div className="transfer-box">
                    <div className="label">Transfer to</div>
                    <div className="text text-hdfc">{bankAccount?.bankName}</div>
                  </div>
                </div>
                <div className="withdraw-btn">
                  <button
                    className={cx('custom-btn', {
                      'disabled-btn': isDisabled,
                    })}
                    disabled={isDisabled}
                    onClick={this._onFolioWithdraw}
                  >
                    Withdraw Savings
                  </button>
                </div>
              </div>
            )}
            {activeStep === STEPS.CONFIRM_SAVING && (
              <div className="confirm-withdraw">
                <div className="title">Please enter the OTP to complete your withdrawal of</div>
                <div className="amount">{Convert.currency(amount)}</div>
                <div className="input-box">
                  <input
                    value={this.state.otp || ''}
                    type="number"
                    className="text-input"
                    placeholder="XXXXXX"
                    onChange={this.handleOtp}
                  />
                  {errors && errors?.e_otp && <div className="error">{errors?.e_otp}</div>}
                </div>
                <div className="text-center">
                  {!shouldResendPin ? (
                    <button className="resend-otp" onClick={this._onResendOTP}>
                      Resend OTP
                    </button>
                  ) : null}
                </div>
                <div className="confirm-btn">
                  <button
                    className={cx('custom-btn', {
                      'disabled-btn': isDisabledOtp,
                    })}
                    disabled={isDisabledOtp}
                    onClick={this._onVerifyOTP}
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            )}
            {activeStep === STEPS.DONE && (
              <div className="withdrawal-successfull">
                <div className="tick">
                  <img src="/img/tick.png" alt="tick" />
                </div>
                <div className="title">Withdrawal Successful</div>
                <div className="amount">{Convert.currency(this.state.amount)}</div>
                <div className="description">
                  The amount will be transfered to your account in 2 - 3 business days
                </div>

                <div className="done-btn">
                  <button className="custom-btn" onClick={this.handleDone}>
                    Done
                  </button>
                </div>
              </div>
            )}
          </div>
          <Loader loading={this.state.loading} />
        </Modal>
      </div>
    );
  }
}

export default WithDrawnMoney;

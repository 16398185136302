import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
// import cx from 'classnames';
import { Convert } from 'src/helpers';
import _ from 'lodash';
import './_modal.scss';

// const API_ENDPOINT_URL =
//   process.env.REACT_APP_ENV === 'production'
//     ? 'https://api.savvyapp.in'
//     : 'https://www.thesavvyapp.in';

const STEPS = {
  COLLECT_MONEY_SCREEN: 'COLLECT_MONEY_SCREEN',
  SUCCESS_SCREEN: 'SUCCESS_SCREEN',
};

class CollectMoneyRequest extends Component {
  state = {
    activeStep: '',
    amount: '',
    loading: false,
    isSubmit: false,
    // shortenedUrl: '',
    // linkCopied: false,
  };

  componentDidMount() {
    this.setState({ activeStep: STEPS.COLLECT_MONEY_SCREEN });

    this.requestCreateCollectionList = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST,
      () => this.setState({ loading: true }),
    );
    this.requestCreateCollectionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST_SUCCESS,
      this.onCreateSuccess,
    );
    this.requestCreateCollectionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST_FAILED,
      this.onCreateFailed,
    );
  }

  componentWillUnmount() {
    this.requestCreateCollectionList();
    this.requestCreateCollectionListSuccess();
    this.requestCreateCollectionListFailed();
  }

  handleStep = ({ stepName }) => {
    this.setState({ activeStep: stepName });
  };

  onCreateSuccess = () => {
    // const url = `${API_ENDPOINT_URL}/admin/collect-requests/${response?.uuid}`;
    // this.setState({ shortenedUrl: url });
    this.handleStep({ stepName: STEPS.SUCCESS_SCREEN });
    this.setState({ loading: false });
  };

  onCreateFailed = () => {
    this.setState({ loading: false });
  };

  handleGenerateRequest = () => {
    const { amount } = this.state;
    const { goal } = this.props;
    
    this.setState({ isSubmit: true }, () => {
      if (!amount) return;
      this.props.requestCreateAdminCollections({
        collect_request: {
          user_uuid: goal?.userUuid,
          saving_goal_id: goal?.id,
          requested_amount: amount,
        },
      });
    });
  };

  // _copyClipboard = (e) => {
  //   this.shortenedUrl.select();
  //   this.shortenedUrl.setSelectionRange(0, 99999);

  //   document.execCommand('copy');
  //   this.setState({ linkCopied: true });
  //   setTimeout(() => {
  //     this.setState({ linkCopied: false });
  //   }, 2000);
  //   e.preventDefault();
  // };

  handleAmount = (e) => {
    this.setState({ amount: e.target.value });
  };

  render() {
    const { isModalOpen, closeModal, goal = {} } = this.props;
    const { activeStep, loading, amount, isSubmit } = this.state;
    const { paymentCalendar = {} } = goal;
    const paymentCalendarKeys = Object.keys(paymentCalendar || {});
    const pendingPaymentCalendar = paymentCalendarKeys.filter((calendar) => {
      const date = calendar.substr(0, 2);
      const month = calendar.substr(2, 2);
      const year = calendar.substr(4, 4);
      return moment(`${year}/${month}/${date}`).diff(moment(), 'days') > 0;
    });
    const totalPendingAmount = _.sumBy(pendingPaymentCalendar, (calendar) =>
      Number(paymentCalendar[calendar]),
    );
    return (
      <div>
        <Modal show={isModalOpen} size="lg">
          <div className="redeem-modal">
            <div className="cross-icon">
              <span onClick={closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
            {activeStep === STEPS.COLLECT_MONEY_SCREEN && (
              <div className="redeem-screen">
                <div className="title">Collect Money Request</div>
                <div className="upper-section">
                  <div>
                    <div className="heading">
                      Package: {goal?.description}
                      <br /> Pending balance on this offer: {Convert.currency(totalPendingAmount)}
                      <br />
                      <br /> How much would like to request today?{' '}
                    </div>
                  </div>
                </div>
                <div className="input-box">
                  <input
                    value={this.state.amount}
                    type="number"
                    className="text-input"
                    placeholder="RS. 5000"
                    onChange={this.handleAmount}
                  />
                  {!amount && isSubmit && <div className="error">Required Amount</div>}
                  <div className="note">
                    *Please note you can collect a max of Rs.50,000/day per user
                  </div>
                </div>
                <div className="common-btn">
                  <button
                    className="custom-btn"
                    disabled={loading}
                    onClick={this.handleGenerateRequest}
                  >
                    {loading ? 'LOADING' : 'GENERATE REQUEST'}
                  </button>
                </div>
              </div>
            )}

            {activeStep === STEPS.SUCCESS_SCREEN && (
              <div className="redeem-success-screen">
                <div className="upper-section">
                  <div className="okay-icon">
                    <img src="/img/okay.svg" alt="okay-icon" />
                  </div>
                  <div>
                    <div className="sub-title">
                      Your collect money request for {Convert.currency(amount)}
                      <br />
                      has been generated.
                    </div>
                  </div>
                </div>
                <div className="urlContainer">
                  {/* <>
                    <input
                      className="urlInput"
                      ref={(ref) => (this.shortenedUrl = ref)}
                      value={this.state.shortenedUrl}
                      readOnly
                    />
                    {!this.state.linkCopied && (
                      <div
                        className="sub-title"
                        style={{ cursor: 'pointer' }}
                        onClick={this._copyClipboard}
                      >
                        Click to copy payment link
                      </div>
                    )}
                    {this.state.linkCopied && (
                      <div className="sub-title">Link Copied to clipboard</div>
                    )}
                  </> */}
                </div>
                <div className="common-btn">
                  <button className="custom-btn" onClick={closeModal}>
                    CLOSE
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default CollectMoneyRequest;

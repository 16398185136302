import React from 'react';
import AsyncSelect from 'react-select/async';
import cx from 'classnames';
import './_asyncSelectInput.scss';

export default function AsyncSelectInput(props) {
  return (
    <div className={cx('async-select', props.customClassnames)}>
      <AsyncSelect
        {...props}
        // className={cx(styles['main-container'], props.className)}
        className="main-container"
        classNamePrefix="main-container"
      />
    </div>
  );
}

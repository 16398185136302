import { ACTION_TYPES } from 'src/constants';

/**
 *
 * Admin collection
 *
 */
export function requestAdminCollections(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_LIST,
    payload,
  };
}
export function adminCollectionsSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_LIST_SUCCESS,
    payload,
  };
}
export function adminCollectionsFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_LIST_FAILED,
    payload,
  };
}

export function requestCreateAdminCollections(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST,
    payload,
  };
}
export function createAdminCollectionsSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST_SUCCESS,
    payload,
  };
}
export function createAdminCollectionsFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST_FAILED,
    payload,
  };
}

export function requestAdminCollectionsDetail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_DETAIL,
    payload,
  };
}
export function adminCollectionsDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_DETAIL_SUCCESS,
    payload,
  };
}
export function adminCollectionsDetailFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_DETAIL_FAILED,
    payload,
  };
}

export function requestUserCollectionsDetail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL,
    payload,
  };
}
export function userCollectionsDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL_SUCCESS,
    payload,
  };
}
export function userCollectionsDetailFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL_FAILED,
    payload,
  };
}

export function requestAdminCollectionsAccept(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_ACCEPT,
    payload,
  };
}
export function adminCollectionsAcceptSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_ACCEPT_SUCCESS,
    payload,
  };
}
export function adminCollectionsAcceptFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_ACCEPT_FAILED,
    payload,
  };
}

export function requestAdminCollectionsConfirm(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_CONFIRM,
    payload,
  };
}
export function adminCollectionsConfirmSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_CONFIRM_SUCCESS,
    payload,
  };
}
export function adminCollectionsConfirmFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_COLLECTION_CONFIRM_FAILED,
    payload,
  };
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';
import { BsExclamationOctagonFill } from 'react-icons/bs';
import { HiBadgeCheck } from 'react-icons/hi';
// import { useHistory } from 'react-router-dom';
import { TextOne } from '../text-one';
import styles from './_modal-payment-link.module.scss';
import './_modal-payment-link.scss';
import { ButtonOne } from '..';

export default function ModalPaymentLinkDelete(props) {
  // const history = useHistory();
  
  // const reload = () => history.go(0);

  return (
    <div className="modal-link-payment">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.container}
      >
        <Modal.Header className={cx(styles.header, 'd-flex justify-content-center')}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.deleted ? (
              <HiBadgeCheck size="70" className={cx(styles.icon, styles.success)} />
            ) : (
              <BsExclamationOctagonFill size="70" className={cx(styles.icon, styles.error)} />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={cx(styles.body, 'd-flex justify-content-center')}>
          {props.deleted ? (
            <TextOne semiBold className={styles.text}>
              Offer has been deleted successfully!
            </TextOne>
          ) : (
            <TextOne semiBold className={styles.text}>
              Do you want to delete the payment link?
            </TextOne>
          )}
        </Modal.Body>
        <Modal.Footer className={cx(styles.footer, 'd-flex justify-content-center')}>
          {!props.deleted && (
            <>
              <ButtonOne text="Cancel" onClick={() => props.handleModal()} variant="two"/>
              <ButtonOne text="Yes" onClick={() => props.handleDelete()}/>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { Container } from 'react-bootstrap';
import { ACTION_TYPES } from 'src/constants';
import { Loader } from 'src/components';
import { ProductDetailsRead } from 'src/components/admin-details';
import { ProductDetailsEdit } from 'src/components/admin';
import styles from './_productdetails.module.scss';

class ProductDetails extends Component {
  state = {
    loading: false,
    edit: false,
  };

  _handleEditState = () => {
    this.setState({
      edit: !this.state.edit,
    });
  };

  componentDidMount() {
    const uuid = this.props.match.params?.uuid;

    this.requestAdminProductDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.adminProductDetailSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminProductDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (uuid) {
      this.props.requestAdminProductDetail({ uuid });
    }
  }

  componentWillUnmount() {
    this.requestAdminProductDetail();
    this.adminProductDetailSuccess();
    this.adminProductDetailFailure();
  }

  render() {
    const { product } = this.props;
    return (
      <>
        <Container className={styles.container}>
          {/* <h1 className={styles.pageHeading}>
                    Add a New Product
                </h1> */}
          {this.state.edit ? (
            <ProductDetailsEdit product={product} handleEditState={this._handleEditState} />
          ) : (
            <ProductDetailsRead product={product} handleEditState={this._handleEditState} />
          )}
        </Container>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default ProductDetails;

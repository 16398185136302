import { connect } from 'react-redux'
import { 
  requestGoalDetail, 
  updateCredentials, 
  folioWithdrawRequest,
  folioWithdrawOTPRequest,
  folioWithdrawResendOTPRequest,
  validateFolioWithdrawRequest
} from 'src/redux/actions';
import FinalPayment from './FinalPayment'

function mapStateToProps(state){
  const savingAccount = state.user.accounts.find(account => account.accountType === 'savings');
  const { id, goalAbsoluteAmount, investmentPlusPendingBalance, currentInvestmentBalance } = state.savingPlan;
  const currentBalance = Number(currentInvestmentBalance);
  const totalGoalAmount = Number(goalAbsoluteAmount);
  const totalAmountPaid = Number(investmentPlusPendingBalance);
  const balanceAmount = totalGoalAmount - totalAmountPaid;

  return {
    savingAccount,
    credentials: state.credentials,
    savingGoalId: id,
    currentBalance,
    totalGoalAmount,
    totalAmountPaid,
    balanceAmount
  }
}

export default connect(mapStateToProps, {
  requestGoalDetail, 
  updateCredentials, 
  folioWithdrawRequest,
  folioWithdrawOTPRequest,
  folioWithdrawResendOTPRequest,
  validateFolioWithdrawRequest
})(FinalPayment)

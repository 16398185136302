import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import moment from 'moment';
import { Convert } from 'src/helpers';
import { notify } from 'react-notify-toast';
import cx from 'classnames';
import { Loader, SavvySupport, SignInSignUpModal } from 'src/components';
import styles from './missed-payment.module.scss';
import OfferBox from '../pay/offer-box';

const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class MissedPayment extends Component {
  state = {
    loading: false,
    showLoginModal: false,
  };

  componentDidMount() {
    if (!this.props.user.uuid) {
      this.setState({ showLoginModal: true });
    }

    this.requestPaymentSubscription = EventBus.on(ACTION_TYPES.REQUEST_GENERATE_PAYMENT, () =>
      this.setState({ loading: true }),
    );
    this.paymentSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.GENERATE_PAYMENT_SUCCESS,
      this._onPaymentGenerated,
    );
    this.paymentSubscriptionFailure = EventBus.on(ACTION_TYPES.GENERATE_PAYMENT_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestPaymentSubscription();
    this.paymentSubscriptionSuccess();
    this.paymentSubscriptionFailure();
  }

  _onPaymentGenerated = ({ payment, orderId }) => {
    const { user, partner, savingPlan, savingGoal } = this.props;
    const amount = Number(payment.amount) * 100; // send it as cents ex: 100Rs as 10000 cents

    const options = {
      key: RAZORPAY_KEY,
      amount,
      currency: 'INR',
      name: partner.name,
      description: savingGoal?.productOffer?.productOfferName,
      image: partner.logoImageLink,
      order_id: orderId,
      callback_url: `${API_ENDPOINT_URL}/api/partners/razorpay/payments/redirect`,
      prefill: {
        name: user.firstName,
        email: user.email,
        contact: user.phoneNumber,
      },
      theme: {
        color: '#ffd401',
      },
    };

    this.setState({ loading: false }, () => {
      this.props.updateSavingPlan({
        ...savingPlan,
        paymentId: payment.id,
        paymentRequestId: payment.requestId,
      });

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  };

  _onProceedPayment = () => {
    if (!this.props.user.uuid) {
      this.setState({ showLoginModal: true });
    } else {
      const missedPayment = this.getMissedPaymentData();
      if (missedPayment?.amount <= 0) {
        notify.show(`Amount must be greater than ${Convert.toCurrencyValue(0)}`, 'error');
        return;
      }
      this._onProceedBooking();
    }
  };

  _onProceedBooking = () => {
    const { savingGoal } = this.props;
    const missedPayment = this.getMissedPaymentData();
    this.props.requestGeneratePayment({
      payment: {
        amount: missedPayment?.amount || 0,
        info: savingGoal?.productOffer?.productOfferName,
        redirect_url: `${origin}/checkout`,
      },
    });
  };

  _onCloseSignInModal = () => {
    this.setState({ showLoginModal: false });
  };

  _onSignInComplete = () => {
    this.setState({ showLoginModal: false }, () => {
      this._onProceedPayment();
    });
  };

  getMissedPaymentData = () => {
    let missedPaymentStructure = {
      amount: 0,
      date: '',
      paymentNumber: '',
    };
    const { savingGoal } = this.props;
    const savingGoalContributions = savingGoal?.savingGoalContributions || [];
    const paymentCalendar = savingGoal?.paymentCalendar || [];
    let formattedPaymentCalendarDates = [];

    if (savingGoalContributions && savingGoalContributions.length > 0) {
      savingGoalContributions.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }
        return 0;
      });
    }
    const paymentCalendarKeys = Object.keys(paymentCalendar || {});
    formattedPaymentCalendarDates = paymentCalendarKeys.map((calendar) => ({
      date: moment(calendar, 'DDMMYYYY').format('YYYY/MM/DD'),
      amount: paymentCalendar[calendar],
    }));

    if (formattedPaymentCalendarDates && formattedPaymentCalendarDates.length > 0) {
      formattedPaymentCalendarDates.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
    }
    formattedPaymentCalendarDates.shift();
    formattedPaymentCalendarDates = formattedPaymentCalendarDates.filter(
      (data) => data.date <= moment().format('YYYY/MM/DD'),
    );

    const formattedSavingGoalContributionsDates = savingGoalContributions.map((goal) => ({
      ...goal,
      createdAt: moment(goal?.createdAt).format('YYYY/MM/DD'),
    }));

    if (formattedPaymentCalendarDates && formattedPaymentCalendarDates.length > 0) {
      formattedPaymentCalendarDates.some((data, index) => {
        const contribution = formattedSavingGoalContributionsDates.find(
          (contribution) => contribution?.createdAt == data.date,
        );
        if (!contribution) {
          missedPaymentStructure = {
            ...missedPaymentStructure,
            amount: data.amount,
            date: data.date,
            paymentNumber: index + 1,
          };
          return true;
        }
        return false;
      });
    }

    return missedPaymentStructure;
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user, savingGoal } = this.props;
    const missedPayment = this.getMissedPaymentData();
    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>
                You have missed a payment{' '}
                <span className={styles['mobile']}>
                  of {Convert.currency(missedPayment?.amount || 0)}
                </span>
              </div>
              <div className={cx(styles['note'], styles['mobile'])}>
                for {savingGoal?.productOffer?.productOfferName}
              </div>
              <div className={cx(styles['progress-bar'], styles['mobile'])}>
                <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
              </div>
              <div className={cx(styles['pay-in-installment'], styles['mobile'])}>
                PAY IN INSTALLMENTS{' '}
              </div>

              <div className={cx(styles['sub-title'], styles['desktop'])}>
                Complete the transaction to avoid delays in availing of{' '}
                {savingGoal?.productOffer?.productOfferName}
              </div>
              <div className={styles['tick']}>
                <img src="/img/block.svg" alt="tick" />
              </div>
              <div className={cx(styles['sub-title-mob'], styles['mobile'])}>
                Complete the transaction to avoid delays in availing{' '}
              </div>

              <div className={styles['message']}>
                Amount {Convert.currency(missedPayment?.amount || 0)}
                <br /> Due Date:{' '}
                {missedPayment?.date &&
                  moment(missedPayment?.date, 'YYYY/MM/DD').format('DD/MM/YYYY')}
              </div>
            </div>
            <div className={styles['footer']} onClick={this._onProceedPayment}>
              Pay Now
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox partner={partner} product={product} user={user} />
          </div>
        </div>
        <SignInSignUpModal
          isShow={this.state.showLoginModal}
          closeModal={this._onCloseSignInModal}
          onComplete={this._onSignInComplete}
        />
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(MissedPayment);

import React, { Component } from 'react';
import moment from 'moment';
import EventBus from 'eventing-bus';
import cx from 'classnames';
import { Loader, CollectMoneyRequest } from 'src/components';
import { Convert } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import styles from './_goal-details.module.scss';

class GoalDetails extends Component {
  state = {
    loading: false,
    isCollectModalOpen: false,
  };

  componentDidMount() {
    const id = this.props?.match?.params?.id;

    this.requestAdminGoalDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_GOAL_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.adminGoalDetailSuccess = EventBus.on(ACTION_TYPES.ADMIN_GOAL_DETAIL_SUCCESS, () => {
      const { goal } = this.props;
      if (goal?.productOffer?.uuid) {
        this.props.requestAdminUserPurchases({ uuid: goal?.productOffer?.uuid });
      }
    });
    this.adminGoalDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_GOAL_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminUserPurchases = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_USER_PURCHASES, () =>
      this.setState({ loading: true }),
    );
    this.adminUserPurchasesSuccess = EventBus.on(ACTION_TYPES.ADMIN_USER_PURCHASES_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminUserPurchasesFailure = EventBus.on(ACTION_TYPES.ADMIN_USER_PURCHASES_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (id) {
      this.props.requestAdminGoalDetail({ id });
    }
  }

  componentWillUnmount() {
    this.requestAdminGoalDetail();
    this.adminGoalDetailSuccess();
    this.adminGoalDetailFailure();

    this.requestAdminUserPurchases();
    this.adminUserPurchasesSuccess();
    this.adminUserPurchasesFailure();
  }

  closeCollectModal = () => {
    this.setState({ isCollectModalOpen: false });
  };

  openCollectModal = () => {
    this.setState({ isCollectModalOpen: true });
  };

  render() {
    const { goal = {} } = this.props;
    let {
      savingGoalContributions = [],
      savingGoalWithdrawals = [],
      paymentCalendar = {},
      payments = [],
    } = goal;

    const completedPayment = _.map(
      payments.filter((payment) => payment.status === 'completed'),
      (payment) => ({ amount: payment.amount, createdAt: payment.createdAt, type: 'Deposit' }),
    );
    const { isCollectModalOpen } = this.state;

    savingGoalContributions = savingGoalContributions.map((saveGoal) => ({
      ...saveGoal,
      createdAt: moment(saveGoal.createdAt, 'YYYY/MM/DD').format('DD/MM/YYYY'),
    }));
    savingGoalWithdrawals = savingGoalWithdrawals.map((saveGoalWithDrawal) => ({
      ...saveGoalWithDrawal,
      createdAt: moment(saveGoalWithDrawal.createdAt, 'YYYY/MM/DD').format('DD/MM/YYYY'),
    }));

    const totalContributions = completedPayment.concat(savingGoalContributions);
    const contributions = totalContributions.map((contribution) => ({
      ...contribution,
      type: 'Deposit',
    }));
    const withdrawals = savingGoalWithdrawals.map((withdrawal) => ({
      ...withdrawal,
      type: 'Withdrawal',
    }));

    const totalDeposited =
      _.sumBy(
        contributions,
        (contribution) =>
          (contribution.status === 'completed' || contribution.status === 'pending_investment') &&
          Number(contribution.amount),
      ) || 0;
    const totalWithdrawn =
      _.sumBy(
        withdrawals,
        (withdrawal) =>
          (withdrawal.status === 'completed' ||
            withdrawal.status === 'pending' ||
            withdrawal.transfer) &&
          Number(withdrawal.amount),
      ) || 0;
    const sortedTransactions = _.sortBy(contributions.concat(withdrawals), (obj) =>
      moment(obj.createdAt, 'DD/MM/YYYY hh:mm:ss'),
    ).reverse();
    const paymentCalendarKeys = Object.keys(paymentCalendar || {});
    const pendingPaymentCalendar = paymentCalendarKeys.filter((calendar) => {
      const date = calendar.substr(0, 2);
      const month = calendar.substr(2, 2);
      const year = calendar.substr(4, 4);
      return moment(`${year}/${month}/${date}`).diff(moment(), 'days') > 0;
    });
    const totalPendingAmount = _.sumBy(pendingPaymentCalendar, (calendar) =>
      Number(paymentCalendar[calendar]),
    );

    return (
      <div className={styles['goal-details']}>
        {/* <div className={styles['main-heading']}>          
          <div className={styles['content']}>
            <Row>
              <Col xs={12} sm={6}>
                <div className={styles['content-box']}>
                  <div className={styles['label']}>0</div>
                  <div className={styles['text']}>TOTAL VALUE OF PURCHAGES </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className={styles['content-box']}>
                  <div className={styles['label']}>0</div>
                  <div className={styles['text']}>NO. OF TIMES OFFER WAS PURCHASED </div>
                </div>
              </Col>
            </Row>
          </div>
        </div> */}
        <div className={styles['main-content']}>
          <div className={styles['heading']}>
            <div className={styles['offer-detail-heading']}>Purchase Details</div>
          </div>
          <div className={styles['offer-detail']}>
            <div className={styles['offer-detail-content']}>
              <div className={styles['left-side']}>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>Product: </div>
                  <div className={styles['text']}>{goal?.description}</div>
                </div>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>Price: </div>
                  <div className={styles['text']}>
                    {goal?.goalAbsoluteAmount && Convert.toCurrencyValue(goal?.goalAbsoluteAmount)}
                  </div>
                </div>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>Offer created on: </div>
                  <div className={styles['text']}>
                    {goal?.startDate &&
                      moment(goal?.startDate, 'DD/MM/YYYY').format('MMMM Do YYYY')}
                  </div>
                </div>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>Tentative end date: </div>
                  <div className={styles['text']}>
                    {' '}
                    {goal?.endDate &&
                      moment(
                        `${goal?.endDate.split('/')[1]}-
                              ${goal?.endDate.split('/')[0]}-
                              ${goal?.endDate.split('/')[2]}
                            `,
                      ).format('MMMM Do YYYY')}
                  </div>
                </div>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>User Details: </div>
                  <div className={styles['text']}>
                    <Link to={`/admin/user/${goal?.userUuid}`}>{goal?.userName}</Link>
                  </div>
                </div>
              </div>
              <div className={styles['center']} />
              <div className={styles['right-side']}>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>Amount invested so far: </div>
                  <div className={cx(styles['text'], styles['text-green'])}>
                    {Convert.currency(totalDeposited)}
                  </div>
                </div>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>Amount withdrawn so far: </div>
                  <div className={cx(styles['text'], styles['text-danger'])}>
                    {Convert.currency(totalWithdrawn)}
                  </div>
                </div>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>Pending savings: </div>
                  <div className={styles['text']}>{Convert.currency(totalPendingAmount)}</div>
                </div>
                <div className={styles['lt-content']}>
                  <div className={styles['label']}>No. of pending installments: </div>
                  <div className={styles['text']}>{pendingPaymentCalendar.length}</div>
                </div>
              </div>
            </div>
            {/* <div className={styles['agent-id']}>
              <div className={styles['lt-content']}>
                <div className={styles['label']}>Agent ID: </div>
                <div className={styles['text']}>-</div>
              </div>
            </div> */}
          </div>
          <div className={styles['heading']}>
            <div className={styles['offer-detail-heading']}>Transactions</div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Date</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {sortedTransactions.length === 0 ? (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center' }}>
                    No transitions
                  </td>
                </tr>
              ) : null}
              {sortedTransactions.map((transaction, index) => {
                const { createdAt, amount, type } = transaction || {};

                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{Convert.currency(amount)}</td>
                      <td>{moment(createdAt, 'DD/MM/YYYY').format('MMMM Do YYYY')}</td>
                      <td className={type === 'Deposit' ? styles['success'] : styles['error']}>
                        {type}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <div className={styles['custom-btn-group']}>
            <button
              className={cx(styles['custom-btn'], {
                [styles['disabled-btn']]: false,
              })}
              disabled={false}
              onClick={this.openCollectModal}
            >
              COLLECT MONEY
            </button>
          </div>
          {isCollectModalOpen && (
            <CollectMoneyRequest
              isModalOpen={isCollectModalOpen}
              closeModal={this.closeCollectModal}
            />
          )}
          <Loader loading={this.state.loading} />
        </div>
      </div>
    );
  }
}

export default GoalDetails;

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import cx from 'classnames';
import { Form, Formik, FieldArray } from 'formik';
import { Convert } from 'src/helpers';
import { Loader } from 'src/components';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { ACTION_TYPES } from 'src/constants';
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { InputFileSix } from 'src/components/form-inputs';
import { stateToHTML } from 'draft-js-export-html';
import * as yup from 'yup';
import {
  // InputRadio,
  TextInputOne,
} from '../../../components/form-inputs';
import { Container, Section, ButtonOne } from '../../../components';
import PreviewImages from './preview-images';
import styles from './_addEditProduct.module.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ProductCreateModal from './product-create-modal';

const UPLOAD_IMAGES_CONFIG = {
  MAX_IMAGES: 5, // Should be greater than 0
  IMAGE_SIZE: 1, // In Mb
};

class AddEditProduct extends Component {
  state = {
    loading: false,
    showCreateAnotherModal: false,
    isImageUploading: false,
    createdProductResponse: {},
    isReRendered: false,
  };

  componentDidMount() {
    const { match } = this.props;
    const uuid = match?.params?.uuid;

    this.requestAdminProductDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_DETAIL, () =>
      this.setState({ loading: true, isReRendered: true }),
    );
    this.adminProductDetailSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_DETAIL_SUCCESS, () =>
      this.setState({ loading: false, isReRendered: false }),
    );
    this.adminProductDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_DETAIL_FAILED, () =>
      this.setState({ loading: false, isReRendered: false }),
    );

    if (uuid) {
      this.props.requestAdminProductDetail({ uuid });
    }

    this.requestImageUploading = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD, () =>
      this.setState({ isImageUploading: true }),
    );
    this.imageUploadingSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD_SUCCESS,
      (data) => {
        console.log('data', data);
        this.formik.setFieldValue('image_urls', [
          ...this.formik.values?.image_urls,
          { ...data, isTempImage: true },
        ]);
        this.setState({ isImageUploading: false });
      },
    );

    this.imageUploadingFailed = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD_FAILED, () =>
      this.setState({ isImageUploading: false }),
    );

    this.requestAdminProductUpdate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_UPDATE, () =>
      this.setState({ loading: true }),
    );
    this.adminProductUpdateSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_UPDATE_SUCCESS, () => {
      this.setState({ loading: false });
      notify.show('Product updated successfully', 'success');
    });
    this.adminProductUpdateFailed = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_UPDATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminProductCreate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_CREATE, () =>
      this.setState({ loading: true }),
    );

    this.adminProductCreateSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_PRODUCT_CREATE_SUCCESS,
      (response) => {
        this.showCreateAnotherModal();
        this.setState({ loading: false, createdProductResponse: response?.product || {} });
      },
    );

    this.adminProductCreateFailed = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_CREATE_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestImageUploading();
    this.imageUploadingSuccess();
    this.imageUploadingFailed();

    this.requestAdminProductUpdate();
    this.adminProductUpdateSuccess();
    this.adminProductUpdateFailed();

    this.requestAdminProductCreate();
    this.adminProductCreateSuccess();
    this.adminProductCreateFailed();

    this.requestAdminProductDetail();
    this.adminProductDetailSuccess();
    this.adminProductDetailFailure();
  }

  _validationSchema = yup.object().shape(
    {
      product_name: yup.string().required('Please enter name of the product'),
      amount: yup.string().required('Please enter the product amount.'),
      images: yup.mixed().test('imageValidation', 'Image size/format not valid', (val) => {
        const imageResults = val.map(
          ({ file }) =>
            file.size < 5 * 1000 * 1000 &&
            ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type),
        );
        return imageResults.indexOf('false') == -1 ? 1 : 0;
      }),
      final_date: yup.date().required('Please select date'),
      text_fields: yup.array().of(
        yup.object().shape({
          heading: yup.string().required('Please enter the heading'),
          body: yup.mixed().test('body', 'Please enter some text in the body.', (val) => {
            const value =
              (val &&
                val.getCurrentContent().hasText() &&
                JSON.stringify(convertToRaw(val.getCurrentContent()))) ||
              null;
            return value != null ? 1 : 0;
          }),
        }),
      ),
    },
    ['image', 'image_urls'],
  );

  _getEditorState = () => {
    let { product = {}, match } = this.props;
    const uuid = match?.params?.uuid;
    if (!uuid) {
      product = {};
    }

    const textFields =
      product?.textFields &&
      product?.textFields.length > 0 &&
      product?.textFields.map((textField) => {
        const textFieldBody =
          textField.body && textField.body != 'null'
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(stateToHTML(convertFromRaw(JSON.parse(textField.body)))),
                ),
              )
            : EditorState.createEmpty();
        return {
          ...textField,
          body: textFieldBody,
        };
      });

    return textFields;
  };

  _prepareImageDataUri = (images) => {
    let promises = Object.values(images).map((image) =>
      Convert.toDataUri(image).then((dataUri) => ({ src: dataUri, file: image })),
    );

    Promise.all(promises)
      .then((results) => {
        if (
          results.length > UPLOAD_IMAGES_CONFIG.MAX_IMAGES ||
          this.formik.values.image_urls.length + results.length > UPLOAD_IMAGES_CONFIG.MAX_IMAGES
        ) {
          notify.show(
            `You can upload a maximum of ${UPLOAD_IMAGES_CONFIG.MAX_IMAGES} images`,
            'error',
          );
          this.formik.setFieldValue('images', [...this.formik.values.images]);
        } else {
          results.forEach((imageFile) => {
            if (imageFile && imageFile.file.size > UPLOAD_IMAGES_CONFIG.IMAGE_SIZE * 1024 * 1024) {
              notify.show(
                `Product image file cannot exceed ${UPLOAD_IMAGES_CONFIG.IMAGE_SIZE}mb`,
                'error',
              );
              document.getElementById('images').value = '';
            } else {
              let formData = new FormData();
              formData.append(`attachment[image]`, imageFile.file);
              this.props.adminProductImageUpload(formData);
              this.formik.setFieldValue('images', [...this.formik.values.images, ...results]);
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  _onActionClick = () => {
    const { history } = this.props;
    history.push('/admin/marketing/products/new');
    window.location.reload();
  };

  showCreateAnotherModal = () => {
    this.setState({ showCreateAnotherModal: true });
  };

  _handleSubmit = ({ uuid, formData }) => {
    if (uuid) {
      this.props.requestAdminProductUpdate({ uuid, data: formData });
    } else {
      this.props.requestAdminProductCreate(formData);
    }
  };

  render() {
    const {
      loading,
      isReRendered,
      showCreateAnotherModal,
      isImageUploading,
      createdProductResponse,
    } = this.state;
    let { product = {}, match } = this.props;
    const uuid = match?.params?.uuid;
    if (!uuid) {
      product = {};
    }
    let finalDate = product?.finalDate;
    if (finalDate) {
      finalDate = finalDate.split('T');
      if (finalDate && finalDate.length > 0) {
        finalDate = finalDate[0];
      }
    }

    return (
      <div className={styles['add-edit-product']}>
        <Container
          title={`${uuid ? 'Edit a product' : 'Create a new product'}`}
          buttonText="create a product"
          onButtonClick={this._onActionClick}
        >
          <Formik
            enableReinitialize={isReRendered}
            innerRef={(ref) => (this.formik = ref)}
            initialValues={{
              product_name: product?.productName || '',
              amount: product?.amount || '',
              final_date: finalDate || '',
              images: [],
              image_urls: product?.imageUrls || [],
              text_fields: this._getEditorState() || [{ heading: '', body: '' }],
            }}
            validationSchema={this._validationSchema}
            onSubmit={(values) => {
              let formValues = { ...values };

              const textFields = formValues.text_fields || [];
              let amount = formValues.amount.replace(/,/g, '');

              delete formValues.images;
              delete formValues.text_fields;
              delete formValues.amount;

              let formData = new FormData();

              if (this.formik.values?.image_urls && this.formik.values?.image_urls.length > 0) {
                Object.values(this.formik.values?.image_urls).forEach((image) => {
                  if (image?.isTempImage) {
                    formData.append(`product[attachment_ids][]`, image.id);
                  }
                });
              }

              Object.keys(formValues).map((key) => formData.append(`product[${key}]`, values[key]));

              const textFieldsData = textFields.map(
                (info) =>
                  info.body &&
                  info.body.getCurrentContent().hasText() && {
                    ...info,
                    body: JSON.stringify(convertToRaw(info.body.getCurrentContent())),
                  },
              );

              formData.append('product[amount]', amount);

              formData.append('product[text_fields]', JSON.stringify(textFieldsData));
              this._handleSubmit({ uuid: product?.uuid, formData });
            }}
          >
            {(formikProps) => {
              const { values, setFieldValue, errors, submitCount } = formikProps;
              return (
                <Form>
                  <Section title="Product Details">
                    <Row>
                      <Col md={6}>
                        <TextInputOne
                          label="Name of Product"
                          name="product_name"
                          id="product_name"
                          type="text"
                        />
                        <br />
                      </Col>
                      <Col md={3}>
                        <TextInputOne
                          label="Date of final payment"
                          name="final_date"
                          id="final_date"
                          placeholder=""
                          type="date"
                        />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <TextInputOne
                          label="Base price of product"
                          name="amount"
                          id="amount"
                          type="text"
                          value={Convert.toCurrencyValue(values.amount, {
                            currencyDisplay: 'none',
                            allowCurrencyOnly: false,
                          })}
                          onChange={(e) => {
                            let currencyValue = Convert.toCurrencyValue(e.target.value, {
                              currencyDisplay: 'none',
                              allowCurrencyValue: false,
                            });
                            if (currencyValue === '₹0') {
                              currencyValue = '';
                            }
                            setFieldValue('amount', currencyValue || '');
                          }}
                        />
                      </Col>

                      {/* <Col md={3}>
                        <InputRadio
                          label="Is there a discount offered?"
                          name="is_discount_percentage"
                          id="is_discount_percentage"
                          radioOptions={[
                            { value: 'Y', label: 'Yes' },
                            { value: 'N', label: 'No' },
                          ]}
                        />
                      </Col>

                      <Col md={4}>
                        <TextInputOne
                          label="Enter Discount Percentage"
                          name="discount_percentage"
                          id="discount_percentage"
                          inline
                          suffix="%"
                        />
                      </Col> */}
                    </Row>
                  </Section>
                  <Section title="Upload Images">
                    <Row>
                      <Col sm={12}>
                        <div className={styles['image-note']}>
                          (You can upload upto 5 images in .jpg or .png format)
                        </div>
                      </Col>
                      <Col sm={12}>
                        <InputFileSix
                          label="Find & Upload"
                          name="images"
                          id="images"
                          type="file"
                          multiple="multiple"
                          onChange={(e) => {
                            const files = e.target.files;
                            this._prepareImageDataUri(files);
                          }}
                        />
                      </Col>
                      <Col sm={12}>
                        <PreviewImages
                          imageUrls={values.image_urls}
                          formikRef={this.formik}
                          isImageUploading={isImageUploading}
                        />
                      </Col>
                    </Row>
                  </Section>
                  <Section title="Describe the product">
                    <FieldArray
                      name="text_fields"
                      render={(arrayHelpers) => (
                        <>
                          <Row>
                            <Col md={12} className="pt-3">
                              {values.text_fields.map((infoField, index) => (
                                <div key={index} className={styles.contentWrapper}>
                                  <div className={styles.titleContainer}>
                                    <div className={styles.titleInput}>
                                      <TextInputOne
                                        label="Title"
                                        name={`text_fields[${index}].heading`}
                                        id={`text_fields[${index}].heading`}
                                        placeholder="Enter a title for this section"
                                        type="text"
                                      />
                                    </div>
                                    <div className={styles.titleAction}>
                                      <span
                                        className={styles.delete}
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        Delete
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles['label']}>Content</div>
                                  <Editor
                                    editorState={values.text_fields[index].body}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName={styles['editorClassName']}
                                    onEditorStateChange={(val) =>
                                      setFieldValue(`text_fields[${index}].body`, val)
                                    }
                                  />
                                  {errors?.text_fields &&
                                    errors?.text_fields.length > 0 &&
                                    errors?.text_fields[index]?.body &&
                                    submitCount > 0 && (
                                      <div className={cx(styles['error'], styles['content-error'])}>
                                        Required content
                                      </div>
                                    )}
                                </div>
                              ))}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className={styles.addMoreContainer}>
                                <span
                                  className={styles.addMore}
                                  onClick={() => arrayHelpers.push({ heading: '', body: '' })}
                                >
                                  Add another section
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    />
                  </Section>
                  <Section title="">
                    <div className={styles['save-btn']}>
                      <ButtonOne
                        type="submit"
                        disabled={loading}
                        text={loading ? 'Loading...' : 'Save Product'}
                      />
                    </div>
                  </Section>
                </Form>
              );
            }}
          </Formik>
        </Container>
        {showCreateAnotherModal && (
          <ProductCreateModal createdProductResponse={createdProductResponse} />
        )}
        <Loader loading={loading} />
      </div>
    );
  }
}

export default withRouter(AddEditProduct);

import React, { Component } from 'react'
import { ACTION_TYPES } from 'src/constants'
import EventBus from 'eventing-bus'
import { Loader } from 'src/components'

class TransactionDetails extends Component {

  state={
    loading: false
  }

  componentDidMount(){
      this.requestAdminTransactionDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DETAIL,
          () => this.setState({loading: true})
      )

      this.requestAdminTransactionDetailSuccess = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DETAIL_SUCCESS,
          () => this.setState({loading: false})
      )

      this.requestAdminTransactionDetailFailed = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DETAIL_FAILED,
          () => this.setState({loading: false})
      )

      this.props.requestAdminTransactionDetail({
        type: 'Deposits',
        id: 1
      })

  }

  componentWillUnmount(){
      this.requestAdminTransactionDetail()
      this.requestAdminTransactionDetailSuccess()
      this.requestAdminTransactionDetailFailed()
  }

  render() {
    return (
      <>
      <div/>
      <Loader loading={this.state.loading}/>
      </>
    )
  }
}

export default TransactionDetails

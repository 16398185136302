import { ACTION_TYPES } from 'src/constants';
import moment from 'moment';
import _ from 'lodash';

export function assignCustomProperties(action) {   
    if (ACTION_TYPES.REQUEST_SIGN_IN_USER === action.type) {
        return {...action.payload};
    }
    
    if (ACTION_TYPES.SIGN_IN_USER_SUCCESS === action.type) {
        const { user } = action.payload;

        return {
            id: user.uuid,
            email: user.email,
            phoneNumber: user.phoneNumber,
            firstName: user.firstName || '',
            lastName: user.lastName || ''
        } 
    }

    if (ACTION_TYPES.CREATE_SAVING_GOAL_SUCCESS === action.type) {
        const { savingGoal } = action.payload;
        
        const goalStartDate = moment(savingGoal.startDate).format('YYYY-MM-DDThh:mm:ss');
        const goalEndDate = moment(savingGoal.endDate).format('YYYY-MM-DDThh:mm:ss');
        const offer = savingGoal.productOffer || {};
        const product = offer.product || {};

        return {
            id: savingGoal.id,
            description: savingGoal.description,
            goalAbsoluteAmount: Number(savingGoal.goalAbsoluteAmount),
            goalStartDate,
            goalEndDate,
            productUuid: product.uuid,
            productName: product.productName,
            offerUuid: offer.uuid,
            offerName: offer.productOfferName,
            months: offer.months,
            isUpfrontPaymentRequired: offer.isUpfrontPaymentRequired,
            isDiscountPercentage: offer.isDiscountPercentage,
            discountPercentage: Number(offer.discountPercentage || 0),
            upfrontPaymentAmount: Number(offer.upfrontPaymentAmount || 0)
        } 
    }

    if (ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION === action.type) {
        return action.payload;
    }
    
    if (_.isString(action.payload)) {
        return action.payload;
    }
  }
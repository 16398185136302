import { connect } from 'react-redux';
import { requestAdminProductList } from 'src/redux/actions';
import Products from './products';

function mapStateToProps(state) {
  return {
    adminProducts: state.adminProducts,
  };
}

export default connect(mapStateToProps, {
  requestAdminProductList,
})(Products);

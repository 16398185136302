import { connect } from 'react-redux';
import {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,  
  requestGeneratePayment,
  updateCredentials,
  updateProduct,
} from 'src/redux/actions';

import PlanGoal from './plan-goal';

function mapStateToProps(state) {
  return {
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
    totalPax: state.savingPlan.totalPax || 1
  };
}

export default connect(mapStateToProps, {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,  
  requestGeneratePayment,
  updateCredentials,
  updateProduct
})(PlanGoal);

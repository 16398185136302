import React, { Component } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import queryString from 'query-string';
import moment from 'moment';
import { Screen, Loader } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import styles from './_home.module.scss';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      loading: true,
    };
  }

  componentDidMount() {
    const { user, credentials } = this.props;
    let {
      partnerAccessKey,
      userIdentityToken,
      productUuid,
      productOfferUuid,
      uuid,
      firstName,
      lastName,
      email,
      phoneNumber,
      totalPax,
      agentId,
      screenType,
      purchaseId,
    } = queryString.parse(this.props.location.search);
    const credentialParams = {};
    const productParams = {};
    const userParams = {};

    if (partnerAccessKey) credentialParams.partnerAccessKey = partnerAccessKey;
    if (userIdentityToken) credentialParams.userIdentityToken = userIdentityToken;

    if (productUuid) productParams.productUuid = productUuid;
    if (productOfferUuid) productParams.productOfferUuid = productOfferUuid;

    if (uuid) userParams.uuid = uuid;
    if (firstName) userParams.firstName = firstName;
    if (lastName) userParams.lastName = lastName;
    if (email) userParams.email = email;
    if (phoneNumber) userParams.phoneNumber = phoneNumber;
    if (agentId) userParams.agentId = agentId;

    if (totalPax) this.props.updateSavingPlan({ totalPax: Number(totalPax) });
    if (Object.keys(credentialParams).length > 0) this.props.updateCredentials(credentialParams);
    if (Object.keys(productParams).length > 0) this.props.updateProduct(productParams);
    if (Object.keys(userParams).length > 0) this.props.updateUser(userParams);

    partnerAccessKey = partnerAccessKey || credentials.partnerAccessKey;
    userIdentityToken = userIdentityToken || credentials.userIdentityToken;
    uuid = uuid || user.uuid;

    if (purchaseId) {
      if (uuid) {
        this.goalDetailProcess({ purchaseId });
      } else {
        this.props.history.push({
          pathname: `/sign-in`,
          search: `${this.props.location.search}&redirectToPath=/`,
        });
      }

      return;
    }

    if (!partnerAccessKey) return this.props.history.push('/sign-in');

    if (screenType === 'missedPayment') {
      return this.props.history.push({
        pathname: `/checkout/missed-payment`,
      });
    }
    if (screenType === 'makePayment') {
      return this.props.history.push({
        pathname: `/checkout/make-payment`,
      });
    }

    if (screenType === 'receivedPayment') {
      return this.props.history.push({
        pathname: `/checkout/received-payment`,
      });
    }

    if (screenType === 'receivedPaymentSetup') {
      return this.props.history.push({
        pathname: `/checkout/received-payment-setup`,
      });
    }

    if (productUuid) {
      return this.props.history.push({
        pathname: `/plan`,
        search: this.props.location.search,
        // state: {
        //   showPromo: productOfferUuid || productUuid
        // }
      });
    }
    if (partnerAccessKey && (!userIdentityToken || !uuid))
      return this.props.history.push({
        pathname: `/checkout`,
        search: this.props.location.search,
        // state: {
        //   showPromo: productOfferUuid || productUuid
        // }
      });
    if (partnerAccessKey && userIdentityToken && uuid && !productUuid && !productOfferUuid)
      return this.props.history.push('/dashboard');

    this.requestVerifyUser = EventBus.on(ACTION_TYPES.REQUEST_VERIFY_USER_TOKEN, () =>
      this.setState({ loading: true }),
    );
    this.verifyUserSuccess = EventBus.on(
      ACTION_TYPES.VERIFY_USER_TOKEN_SUCCESS,
      this._verifyUserTokenSuccess,
    );
    this.verifyUserFailure = EventBus.on(
      ACTION_TYPES.VERIFY_USER_TOKEN_FAILED,
      this._verifyUserTokenFailure,
    );

    if (uuid) this.props.requestVerifyUser({ uuid });
  }

  goalDetailProcess = ({ purchaseId }) => {
    this.goalRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.goalSuccessSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS, () => {
      this.setState({ loading: false });
      this.onSavingGoalFetched();
    });
    this.goalFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );
    if (purchaseId) {
      this.props.requestGoalDetail({ id: purchaseId });
    }
  };

  componentWillUnmount() {
    this.requestVerifyUser && this.requestVerifyUser();
    this.verifyUserSuccess && this.verifyUserSuccess();
    this.verifyUserFailure && this.verifyUserFailure();

    this.goalRequestSubscription && this.goalRequestSubscription();
    this.goalSuccessSubscription && this.goalSuccessSubscription();
    this.goalFailureSubscription && this.goalFailureSubscription();
  }

  getMissedPaymentData = () => {
    let missedPaymentStructure = {
      amount: 0,
      date: '',
      paymentNumber: '',
    };
    const { savingGoal } = this.props;
    const savingGoalContributions = savingGoal?.savingGoalContributions || [];
    const paymentCalendar = savingGoal?.paymentCalendar || [];
    let formattedPaymentCalendarDates = [];

    if (savingGoalContributions && savingGoalContributions.length > 0) {
      savingGoalContributions.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }
        return 0;
      });
    }
    const paymentCalendarKeys = Object.keys(paymentCalendar || {});
    formattedPaymentCalendarDates = paymentCalendarKeys.map((calendar) => ({
      date: moment(calendar, 'DDMMYYYY').format('YYYY/MM/DD'),
      amount: paymentCalendar[calendar],
    }));

    if (formattedPaymentCalendarDates && formattedPaymentCalendarDates.length > 0) {
      formattedPaymentCalendarDates.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
    }
    formattedPaymentCalendarDates.shift();
    formattedPaymentCalendarDates = formattedPaymentCalendarDates.filter(
      (data) => data.date <= moment().format('YYYY/MM/DD'),
    );

    const formattedSavingGoalContributionsDates = savingGoalContributions.map((goal) => ({
      ...goal,
      createdAt: moment(goal?.createdAt).format('YYYY/MM/DD'),
    }));

    if (formattedPaymentCalendarDates && formattedPaymentCalendarDates.length > 0) {
      formattedPaymentCalendarDates.some((data, index) => {
        const contribution = formattedSavingGoalContributionsDates.find(
          (contribution) => contribution?.createdAt == data.date,
        );
        if (!contribution) {
          missedPaymentStructure = {
            ...missedPaymentStructure,
            amount: data.amount,
            date: data.date,
            paymentNumber: index + 1,
          };
          return true;
        }
        return false;
      });
    }

    return missedPaymentStructure;
  };

  onSavingGoalFetched = () => {
    const missedPayment = this.getMissedPaymentData();
    const { savingGoal } = this.props;
    if (
      !savingGoal?.mandate ||
      (savingGoal?.mandate && Object.keys(savingGoal?.mandate).length === 0)
    ) {
      return this.props.history.push('/setup-sip');
    }
    if (!savingGoal?.kycd && savingGoal?.paymentMethod === 'book_save_buy') {
      return this.props.history.push('/checkout/kyc');
    }
    if (missedPayment?.amount > 0) {
      return this.props.history.push('/checkout/missed-payment');
    }

    this.props.history.push({
      pathname: `/checkout/received-payment`,
    });
  };

  _verifyUserTokenSuccess = () => {
    this.setState({ loading: false }, () => {
      const { user, product } = this.props;
      const { savingGoals } = user;
      const { productOfferUuid } = product;
      const existingGoal = savingGoals.find((goal) => goal.productOffer?.uuid === productOfferUuid);

      if (productOfferUuid && existingGoal) {
        this.props.history.push(`/dashboard/progress/${existingGoal.id}`);
      } else {
        this.props.history.push('/checkout');
      }
    });
  };

  _verifyUserTokenFailure = () => {
    this.setState({ loading: false, showError: true });
  };

  render() {
    return (
      <Screen>
        <div className={styles['home-page-container']}>
          <Loader loading={this.state.loading} title="Please wait, verifing request.." />
          {this.state.showError ? (
            <div>
              <Player
                autoplay
                keepLastFrame
                src="https://assets3.lottiefiles.com/packages/lf20_n1yefofl.json"
                style={{ height: 400, width: 400 }}
              />
              <div className={styles['description']}>
                <h1>Oops!</h1>
                <p>Your request is not authorised or invalid</p>
              </div>
            </div>
          ) : null}
        </div>
      </Screen>
    );
  }
}

export default withRouter(Home);

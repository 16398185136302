import { connect } from 'react-redux';
import KycVerified from './kyc-verified';

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, {})(KycVerified);

import _ from 'lodash'
// import moment from 'moment'

class Helpers{
  
  // get amount from a percentage of value.
  static percentToAmount(percent, amount){
    return amount ? Number(((percent * amount) / 100)) : 0
    // return amount ? this.round((percent * amount) / 100, precision) : 0
  }

  // find the rounded of number, accepts precision(decimal places)
  static round(value, precision = 2){
    const multiplier = 10**(precision || 0)
    return Math.round((Number(value) + Number.EPSILON) * multiplier) / multiplier
  }

  // find the rounded of number, accepts precision(decimal places)
  static roundDown(value, precision = 2){
    const multiplier = 10**(precision || 0)
    return Math.floor((Number(value) + Number.EPSILON) * multiplier) / multiplier
  }
  
  // add/subtracts weekdays only
  static addWeekdays(date, days){
    // let d = date ? moment(date) : moment()
    let inc = days/Math.abs(days)

    while(days != 0){
      date.add(inc, 'days')
      if(date.isoWeekday() != 6 && date.isoWeekday() != 7){// saturday(6) & sunday(7)
        days -= inc
      }
    }
    return date
  }

  // Find duplicate entries in an array
  static findDuplicatesInArray(arr){
    const copactArr = _.compact(arr)
    const items = {}
    const result = []

    copactArr.forEach(item => {
      if(items[item]){
        items[item] += 1
      }else{
        items[item] = 1
      }
    })

    for(let i in items){
      if(items[i] >= 2){
        result.push(i)
      }
    }

    return result
  }
}

export {Helpers}
import React, { Component } from 'react';
import { Row, Col, Form as BsForm } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import { InputFileTwo } from 'src/components/form-inputs';
import InputSelectTwo from 'src/components/form-inputs/input-select-two';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { ApiRequest } from 'src/helpers';
import { CtaTwo, SecuredPartners } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import styles from './_addressverification.module.scss';

class AddressVerification extends Component {
  state = {
    loading: false,
    identityType: '',
    frontImage: '',
    backImage: '',
    frontProofLoading: false,
    backProofLoading: false,
    idNoBackSide: false,

    fullName: '',
    dob: '',
    licenseNumber: '',
    issueDate: '',
    expiryDate: '',
    address: '',
    city: '',
    state: '',
    district: '',
    pincode: '',
    aadhaarUid: '',
    passportNumber: '',
    voterIdNumber: '',
  };

  componentDidMount() {
    this.extractAddressProofRequestSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_ADDRESS_PROOF_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.extractAddressProofRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_ADDRESS_PROOF_SUCCESS,
      this._onExtractedAddressProof,
    );
    this.extractAddressProofRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_ADDRESS_PROOF_FAILED,
      () => this.setState({ loading: false }),
    );

    this.uploadAddressProofRequestSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_ADDRESS_PROOF_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.uploadAddressProofSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_ADDRESS_PROOF_SUCCESS,
      this._onAddressProofUploaded,
    );
    this.uploadAddressProofFailureSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_ADDRESS_PROOF_FAILED,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.extractAddressProofRequestSubscription();
    this.extractAddressProofRequestSuccessSubscription();
    this.extractAddressProofRequestFailureSubscription();

    this.uploadAddressProofRequestSubscription();
    this.uploadAddressProofSuccessSubscription();
    this.uploadAddressProofFailureSubscription();
  };

  _validationSchema = yup.object().shape({
    identity_proof: yup.string().required('Please select an identity proof.'),
  });

  _onProofFrontImageUpdated = (event) => {
    this.setState({ frontProofLoading: true });
    const [file] = event.target.files;

    ApiRequest.uploadToSignzy(file).then((res) => {
      this.setState({ frontProofLoading: false, frontImage: res.data.file.directUrl });
    });
  };

  _onProofBackImageUpdated = (event) => {
    this.setState({ backProofLoading: true });
    const [file] = event.target.files;

    ApiRequest.uploadToSignzy(file).then((res) => {
      this.setState({ backProofLoading: false, backImage: res.data.file.directUrl });
    });
  };

  _onSubmitAddressProof = ({ identity_proof }) => {
    const { frontImage, backImage } = this.state;
    this.setState({ identityType: identity_proof }, () => {
      const imageUrls = [frontImage];
      if (identity_proof !== 'license') imageUrls.push(backImage);

      this.props.extractAddressProofRequest({
        address_proof_type: identity_proof,
        image_urls: imageUrls,
      });
    });
  };

  present(param) {
    return param != null && param != '';
  }

  _uploadAddressProof = () => {
    const addressProof = {
      name: this.state.fullName,
      expiry_date: this.state.expiryDate,
      license_number: this.state.licenseNumber,
      aadhaar_uid: this.state.aadhaarUid,
      passport_number: this.state.passportNumber,
      voter_id_number: this.state.voterIdNumber,
      date_of_birth: this.state.dob,
      issue_date: this.state.issueDate,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      district: this.state.district,
      pincode: this.state.pincode,
      image_url: this.state.frontImage,
      address_proof_type: this.state.identityType,
    };

    this.props.updateKycParams({ addressProof });
    this.props.uploadAddressProofRequest(addressProof);
  };

  _showExtractionError = (type) => {
    notify.show(`Document extraction failed, make sure the ${type} document is valid, readable and doesn't have blur, glare, or dim lighting.`, 'error', 5000);
  }

  _checkCompletion = () => {
    switch (this.state.identityType) {
      case 'license':
        if (this._isLicenseExtractedCorrectly()) {
          this._uploadAddressProof();
        } else {
          this._showExtractionError('license');
        }
        break;
      case 'aadhaar':
        if (this._isAadhaarExtractedCorrectly()) {
          this._uploadAddressProof();
        } else {
          this._showExtractionError('aadhaar');
        }
        break;
      case 'voter_id':
        if (this._isVoterIDExtractedCorrectly()) {
          this._uploadAddressProof();
        } else {
          this._showExtractionError('voter id');
        }
        break;
      case 'passport':
        if (this._isPassportExtractedCorrectly()) {
          this._uploadAddressProof();
        } else {
          this._showExtractionError('passport');
        }
        break;
      default:
        break;
    }
  };

  _setLicenseParams = (res) => {
    this.setState(
      {
        fullName: res.name,
        dob: res.dateOfBirth,
        licenseNumber: res.licenseNumber,
        issueDate: res.issueDate,
        expiryDate: res.expiryDate,
        address: res.address,
        city: res.city,
        state: res.state,
        district: res.district,
        pincode: res.pincode,
      },
      this._checkCompletion,
    );
  };

  _setAadhaarParams = (res) => {
    const isValidDate = moment(res.dateOfBirth).isValid();
    const dateOfBirth = isValidDate ? res.dateOfBirth : this.props.dateOfBirth;
    this.setState(
      {
        fullName: res.name,
        dob: dateOfBirth,
        aadhaarUid: res.aadhaarUid,
        address: res.address,
        city: res.city,
        state: res.state,
        district: res.district,
        pincode: res.pincode,
      },
      this._checkCompletion,
    );
  };

  _setVoterIDParams = (res) => {
    this.setState(
      {
        fullName: res.name,
        dob: res.dateOfBirth,
        voterIdNumber: res.voterIdNumber,
        address: res.address,
        city: res.city,
        state: res.state,
        district: res.district,
        pincode: res.pincode,
      },
      this._checkCompletion,
    );
  };

  _setPassportParams = (res) => {
    this.setState(
      {
        fullName: res.name,
        dob: res.dateOfBirth,
        passportNumber: res.passportNumber,
        address: res.address,
        city: res.city,
        state: res.state,
        district: res.district,
        pincode: res.pincode,
        expiryDate: res.expiryDate,
        issueDate: res.issueDate,
      },
      this._checkCompletion,
    );
  };

  _isLicenseExtractedCorrectly = () =>
    this.present(this.state.issueDate) &&
    this.present(this.state.dob) &&
    this.present(this.state.expiryDate) &&
    this.present(this.state.fullName) &&
    this.present(this.state.licenseNumber) &&
    this.present(this.state.address) &&
    this.present(this.state.district) &&
    this.present(this.state.city) &&
    this.present(this.state.state) &&
    this.present(this.state.pincode);

  _isAadhaarExtractedCorrectly = () =>
    this.present(this.state.dob) &&
    this.present(this.state.fullName) &&
    this.present(this.state.aadhaarUid) &&
    this.present(this.state.address) &&
    this.present(this.state.district) &&
    this.present(this.state.city) &&
    this.present(this.state.state) &&
    this.present(this.state.pincode);

  _isVoterIDExtractedCorrectly = () =>
    this.present(this.state.dob) &&
    this.present(this.state.fullName) &&
    this.present(this.state.voterIdNumber) &&
    this.present(this.state.address) &&
    this.present(this.state.district) &&
    this.present(this.state.city) &&
    this.present(this.state.state) &&
    this.present(this.state.pincode);

  _isPassportExtractedCorrectly = () =>
    this.present(this.state.dob) &&
    this.present(this.state.fullName) &&
    this.present(this.state.passportNumber) &&
    this.present(this.state.address) &&
    this.present(this.state.district) &&
    this.present(this.state.city) &&
    this.present(this.state.state) &&
    this.present(this.state.pincode) &&
    this.present(this.state.expiryDate) &&
    this.present(this.state.issueDate);

  _onExtractedAddressProof = (res) => {
    switch (this.state.identityType) {
      case 'license':
        this._setLicenseParams(res);
        break;
      case 'aadhaar':
        this._setAadhaarParams(res);
        break;
      case 'voter_id':
        this._setVoterIDParams(res);
        break;
      case 'passport':
        this._setPassportParams(res);
        break;
      default:
        break;
    }
  };

  _onAddressProofUploaded = () => {
    this.setState({ loading: false }, () => {
      this.props.onComplete && this.props.onComplete();
    });
  };

  _shouldDisableNext = () => {
    if (!this.state.idNoBackSide) return !this.state.frontImage || !this.state.backImage;
    if (this.state.idNoBackSide) return !this.state.frontImage;
  }

  _getIdentityName = (type) => {
     if (type === "license") return "driving license";     
     if (type === "aadhaar") return "adhaar";     
     if (type === "passport") return "passport";  
     if (type === "voter_id") return "voter Id";
     return "national identity";     
  }

  render() {    
    return (
      <div className={styles['pan-verification']}>
        <div className={styles['pv-group-input']}>
          <Formik
            initialValues={{
              identity_proof: '',
            }}
            validationSchema={this._validationSchema}
            onSubmit={this._onSubmitAddressProof}
          >
            {({ values, setFieldValue }) => (
              <FormikForm>
                <Row>
                  <Col lg={6} className={styles.formItem}>
                    <InputSelectTwo
                      mandatory
                      label="Pick one of the national Identities"
                      name="identity_proof"
                      id="identity_proof"
                      labelstyles={styles.labelContainerStyles}
                      onChange={(e) => {
                        setFieldValue('identity_proof', e.target.value);
                        this.setState({ idNoBackSide: false });
                      }}
                    >
                      <option value="">Select Id Proof</option>
                      <option value="license">Driving License</option>
                      <option value="aadhaar">Adhaar</option>
                      <option value="passport">Passport</option>
                      <option value="voter_id">Voter Id</option>
                    </InputSelectTwo>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className={styles.formItem}>
                    <InputFileTwo
                      mandatory
                      heading={`Upload photo of ${this._getIdentityName(values.identity_proof)}`}
                      label={`Front of your ${this._getIdentityName(values.identity_proof)}`}
                      name="identity_proof_front"
                      id="identity_proof_front"
                      headingstyles={styles.inputFileTwoHeading}
                      container={styles.inputFileTwoContainer}
                      accept="image/png, image/jpeg"
                      loading={this.state.frontProofLoading}
                      onChange={this._onProofFrontImageUpdated}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className={styles.formItem}>
                    {
                      !this.state.idNoBackSide
                      ?
                      <InputFileTwo
                        mandatory
                        label={`Back of your ${this._getIdentityName(values.identity_proof)}`}
                        name="identity_proof_back"
                        id="identity_proof_back"
                        container={styles.inputFileTwoContainer}
                        accept="image/png, image/jpeg"
                        loading={this.state.backProofLoading}
                        onChange={this._onProofBackImageUpdated}
                      />
                      : null
                    }   
                    {
                      values.identity_proof === 'license'
                      ?
                      <BsForm.Check
                        className={styles['no-back-side-checkbox']}
                        type="checkbox"
                        label="My ID doesn't have a back section to upload"
                        id="id_no_back_side"
                        name="id_no_back_side"  
                        checked={this.state.idNoBackSide}     
                        onChange={(e) => this.setState({ idNoBackSide: e.target.checked })}               
                      />
                      :
                      null
                    }    
                  </Col>
                </Row>
                <CtaTwo
                  type="submit"
                  text="Next"
                  loading={this.state.loading}
                  disabled={this._shouldDisableNext()}
                  styles={{
                    minWidth: '140px',
                    // width: '100%',
                    height: '44px',
                    fontFamily: 'Avenir-DemiBold',
                    fontSize: '18px',
                    marginTop: '2rem',
                  }}
                />
                <div className={styles['secure-partners']}>
                  <SecuredPartners />
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default AddressVerification;

import React from 'react';
import { useField } from 'formik';
import { MandatoryMark } from 'src/components';
import globalStyles from '../_styles.module.scss';
import styles from './_inputradio.module.scss';

export default function InputRadio({ label, ...props }) {
  const [field, meta] = useField(props);
  
  return (
    <>
      <div className={styles.inputLabelContainer}>
        <label htmlFor={props.id || props.name} className={styles.inputLabel}>
          {label}
          {props.mandatory && <MandatoryMark />}
        </label>

        <ul className={props.radioButtonsContainer}>
          {props?.radioOptions &&
            props.radioOptions.length > 0 &&
            props.radioOptions.map(({ value, label }, index) => (
              <li key={index}>
                <input
                  type="radio"
                  id={value}
                  disabled={props.disabled}
                  {...field}
                  value={value}
                  checked={value === (field.value || props.initialValue)}
                />
                <label htmlFor={value}>{label}</label>
              </li>
            ))}
        </ul>
      </div>
      <div>
        {meta.touched && meta.error ? <div className={globalStyles.error}>{meta.error}</div> : null}
      </div>
    </>
  );
}

import { connect } from 'react-redux';
import { requestCreateMandate, updateSavingPlan, requestGoalDetail  } from 'src/redux/actions';
import SetupAutoPay from './setup-auto-pay';

function mapStateToProps(state) {
  return {
    user: state.user,
    partner: state.partner,
    savingPlan: state.savingPlan,
  };
}

export default connect(mapStateToProps, { requestCreateMandate, updateSavingPlan, requestGoalDetail  })(SetupAutoPay);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Loader, SavvySupport } from 'src/components';
import styles from './sip-failure.module.scss';
import OfferBox from '../pay/offer-box';
// import ExtraInfo from '../extra-info';

class SipFailure extends Component {
  state = {
    loading: false,
    // isShowSavvyPayModal: true,
    // payment: {},
  };

  _onRetrySIP = () => {
    this.props.history.push('/checkout/sip');
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user } = this.props;

    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>We were unable to setup your mandate</div>
              <div className={styles['tick']}>
                <img src="/img/block.svg" alt="tick" />
              </div>
              <div className={styles['message']}>
                Please try again <br />
                or reach out to us hello@savvyapp.in for help
              </div>
            </div>
            <div className={styles['footer']} onClick={this._onRetrySIP}>
              Re-try setting up AutoPay
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox
              partner={partner}
              product={product}
              user={user}
              onProceedPayment={this._onProceedPayment}
            />
          </div>
        </div>
        {/* <ExtraInfo /> */}
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(SipFailure);

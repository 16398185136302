import { connect } from 'react-redux'
import { requestAdminTransactionDetail } from 'src/redux/actions'
import TransactionDetails from './TransactionDetails'

function mapStateToProps(){
  return{
    // transaction: state.adminTransactions
  }
}

export default connect(mapStateToProps,{requestAdminTransactionDetail})(TransactionDetails)

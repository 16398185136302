import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Convert } from 'src/helpers';
import styles from './_customcardone.module.scss';

function CustomCardOne(props) {
  return (
    <Row className={styles.container}>
      <Col sm={4} className={styles.cardCol}>
        <Image src={props.imgSrc} fluid className={styles.offerImage} />
      </Col>
      <Col sm={8}>
        <div>
          <span className={styles.title}>{props.title}</span>
        </div>
        <div className={styles.section}>
          <span className={styles.textOne}>
            {(props?.price && Convert.toCurrencyValue(props?.price)) || 0}
          </span>
        </div>
        <div>
          <div className={styles.label}>{props.cardHeading}</div>
          <div className={styles.textTwo}>{props.children}</div>
        </div>
      </Col>
    </Row>
  );
}

export default CustomCardOne;

const API_URL = process.env.REACT_APP_API_URL || '';

export const API_END_POINTS = {
    USERS                            : `${API_URL}/api/partners/users`,
    USER                             : `${API_URL}/api/partners/user`,
    SIGN_IN                          : `${API_URL}/api/partners/users/sign_up_or_sign_in`,
    VERIFY_OTP                       : `${API_URL}/api/partners/users/verify_sms`,
    RESEND_OTP                       : `${API_URL}/api/partners/users/resend_sms`,
    SAVING_GOALS                     : `${API_URL}/api/partners/saving_goals`,
    KYC_GET_TAX_STATUS               : `${API_URL}/api/partners/icici_pru/kyc/tax_status`,    
    KYC_CHECK_PAN                    : `${API_URL}/api/partners/icici_pru/kyc/check_pan`,
    KYC_SUBMIT_PAN                   : `${API_URL}/api/partners/icici_pru/kyc/submit_pan`,
    KYC_SUBMIT_INVESTOR_DETAILS      : `${API_URL}/api/partners/icici_pru/kyc/submit_investor_details`,
    KYC_FULL                         : `${API_URL}/api/partners/icici_pru/kyc/full_kyc`,
    KYC_LOGIN                        : `${API_URL}/api/partners/icici_pru/kyc/full_kyc_login`,
    KYC_GET_OTM_BANKS                : `${API_URL}/api/partners/icici_pru/kyc/otm_banks`,
    KYC_GET_BANK_DETAILS             : `${API_URL}/api/partners/icici_pru/kyc/bank_details`,
    KYC_PENNY_DROP                   : `${API_URL}/api/partners/icici_pru/kyc/penny_drop`,
    KYC_SUBMIT_INVESTOR_BANK_ACCOUNT : `${API_URL}/api/partners/icici_pru/kyc/submit_bank_details`,
    KYC_GET_OCCUPATION_LIST          : `${API_URL}/api/partners/icici_pru/kyc/occupation_list`,
    VALIDATE_EMAIL                   : `${API_URL}/api/partners/user/validate_email`,
    KYC_SUBMIT_INVESTOR_KYC          : `${API_URL}/api/partners/icici_pru/kyc/submit_investor_kyc`,
    KYC_CREATE_CONTRACT              : `${API_URL}/api/partners/icici_pru/kyc/generate_contract`,
    KYC_EXECUTE_VERIFICATION         : `${API_URL}/api/partners/icici_pru/kyc/execute_verification`,
    CREATE_FOLIO                     : `${API_URL}/api/partners/icici_pru/folios`, 
    FOLIO_CURRENT_BALANCE            : `${API_URL}/api/partners/icici_pru/folios/current_balance`,   
    FOLIO_WITHDRAW                   : `${API_URL}/api/partners/icici_pru/folios/withdraw`,    
    FOLIO_WITHDRAW_SEND_OTP          : `${API_URL}/api/partners/icici_pru/folios/send_withdraw_otp`,
    FOLIO_WITHDRAW_RESEND_OTP        : `${API_URL}/api/partners/icici_pru/folios/resend_withdraw_otp`,
    FOLIO_WITHDRAW_VALIDATE_OTP      : `${API_URL}/api/partners/icici_pru/folios/validate_withdraw_otp`,
    CHECK_INSTANT_WITHDRAW           : `${API_URL}/api/partners/icici_pru/folios/check_instant_withdraw_allowed`,
    CREATE_MANDATE                   : `${API_URL}/api/partners/digio/mandates/api`,
    PRODUCTS                         : `${API_URL}/api/partners/products`,
    PRODUCTS_OFFERS                  : `${API_URL}/api/partners/product_offers`,
    PAYMENT_SCHEDULES                : `${API_URL}/api/partners/admin/payment_schedules/new`,
    GENERATE_PAYMENT                 : `${API_URL}/api/partners/razorpay/payments`,
    TRANSACTION_LIST                 : `${API_URL}/api/partners/transactions`,
    DEPOSITS                         : `${API_URL}/api/partners/deposits`,
    WITHDRAWAL_DETAIL                : `${API_URL}/api/partners/withdrawals`,
    PURCHASE_BANKS                   : `${API_URL}/api/partners/icici_pru/kyc/purchase_banks`,
    UPDATE_BANK_ACCOUNT              : `${API_URL}/api/partners/bank_accounts`,    

    ADMIN_LOGIN                      : `${API_URL}/api/partners/admin/partner_admin_users/sign_in`,
    ADMIN_PRODUCT_LIST               : `${API_URL}/api/partners/admin/products`,
    ADMIN_OFFERS_LIST                : `${API_URL}/api/partners/admin/product_offers`,
    ADMIN_OFFERS_LIST_V2             : `${API_URL}/api/partners/admin/v2/product_offers`,
    ADMIN_PRODUCTS_OFFERS_DELETE     : `${API_URL}/api/partners/admin/product_offers`,
    ADMIN_USERS_LIST                 : `${API_URL}/api/partners/admin/users`,
    ADMIN_USER_PURCHASES             : `${API_URL}/api/partners/admin/users`,
    ADMIN_AGENTS                     : `${API_URL}/api/partners/admin/partner_admin_users`,
    ADMIN_PARTNERS                   : `${API_URL}/api/partners/admin/partners`,
    ADMIN_GOAL_LIST                  : `${API_URL}/api/partners/admin/saving_goals`,
    URL_SHORTENERS                   : `${API_URL}/api/partners/admin/payment_links`,
    ADMIN_TRANSACTION_LIST           : `${API_URL}/api/partners/admin/transactions`,
    ADMIN_TRANSACTION_DEPOSIT_LIST   : `${API_URL}/api/partners/admin/deposits`,
    ADMIN_TRANSACTION_WITHDRAWAL_LIST: `${API_URL}/api/partners/admin/withdrawals`,   
    ADMIN_PAYMENT_LINKS              : `${API_URL}/api/partners/admin/payment_links`,   
    KYC_EXTRACT_PAN_CARD             : `${API_URL}/api/partners/icici_pru/kyc/extract_pan_card`,
    KYC_UPLOAD_PAN_CARD              : `${API_URL}/api/partners/icici_pru/kyc/upload_pan_card`,
    KYC_UPLOAD_FORM                  : `${API_URL}/api/partners/icici_pru/kyc/upload_form`,
    KYC_EXTRACT_ADDRESS_PROOF        : `${API_URL}/api/partners/icici_pru/kyc/extract_address_proof`,
    KYC_UPLOAD_ADDRESS_PROOF         : `${API_URL}/api/partners/icici_pru/kyc/upload_address_proof`,
    KYC_UPLOAD_SIGNATURE             : `${API_URL}/api/partners/icici_pru/kyc/upload_signature`,
    KYC_START_VIDEO_VERIFICATION     : `${API_URL}/api/partners/icici_pru/kyc/start_video_verification`,
    KYC_UPLOAD_VIDEO_VERIFICATION    : `${API_URL}/api/partners/icici_pru/kyc/upload_video_verification`,
    KYC_UPLOAD_PHOTO                 : `${API_URL}/api/partners/icici_pru/kyc/upload_photo`,
    FATCHA                           : `${API_URL}/api/partners/icici_pru/kyc/submit_investor_fatca`,
    KYC_COUNTRIES                    : `${API_URL}/api/partners/icici_pru/kyc/countries`,
    KYC_IDENTIFICATION_TYPES         : `${API_URL}/api/partners/icici_pru/kyc/identification_types`,
    KYC_SOURCES_OF_WEALTH            : `${API_URL}/api/partners/icici_pru/kyc/sources_of_wealth`,
    ADMIN_ATTACHMENTS                : `${API_URL}/api/partners/admin/attachments`,
    COLLECTION_REQUESTS              : `${API_URL}/api/partners/admin/collect_requests`,
    CANCEL_REQUESTS                  : `${API_URL}/api/partners/admin/saving_goals`,
    CONFIRM_CANCEL_REQUESTS          : `${API_URL}/api/partners/admin/saving_goals/confirm_cancellation/{GOAL_ID}`,
    USER_COLLECTION_REQUESTS         : `${API_URL}/api/partners/collect_requests`,
    COLLECTION_REQUESTS_ACCEPT       : `${API_URL}/api/partners/collect_requests/{ID}/accept`,
    COLLECTION_REQUESTS_CONFIRM      : `${API_URL}/api/partners/collect_requests/{ID}/confirm`,
    EMAIL_TEMPLATES                  : `${API_URL}/api/partners/admin/marketing/email_templates`,
    PRODUCT_LINK                     : `${API_URL}/api/partners/admin/product_links`,
};
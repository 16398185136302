import { ACTION_TYPES } from 'src/constants';
import _ from 'lodash';
import { INITIAL_STATE } from '../store';

export default function SavingGoalsReducer(state = INITIAL_STATE.savingGoals, action) {
  switch (action.type) {

    case ACTION_TYPES.REQUEST_GOAL_LIST_SUCCESS: {
      return [...action.payload.savingGoals];
    }

    case ACTION_TYPES.CREATE_SAVING_GOAL: {
      return state;
    }

    case ACTION_TYPES.CREATE_SAVING_GOAL_SUCCESS: {
      return [...state, action.payload.savingGoal];
    }

    case ACTION_TYPES.CREATE_SAVING_GOAL_FAILED: {
      return state;
    }

    case ACTION_TYPES.CREATE_DEPOSIT_SUCCESS: {
      const updateIndex = _.findIndex(state, (goal) => goal.id == action.payload.savingGoal.id)
      const updatedGoals = state
      updatedGoals[updateIndex] = action.payload.savingGoal

      return [...updatedGoals];
    }

    case ACTION_TYPES.FETCH_DEPOSIT_SUCCESS: {
      return state.map(goal => {
        const { savingGoal } = action.payload;

        if (savingGoal && savingGoal.id && savingGoal.id === goal.id) {
          return {
            ...savingGoal
          }
        }

        return {
          ...goal
        };
      });
    }

    case ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_SUCCESS: {
      const updateIndex = _.findIndex(state, (goal) => goal.id == action.payload.savingGoal.id)
      const updatedGoals = state
      updatedGoals[updateIndex] = action.payload.savingGoal

      return [...updatedGoals];
    }

    case ACTION_TYPES.FOLIO_WITHDRAW_SUCCESS: {
      if (action.payload.savingGoal && action.payload.savingGoal.id) {
        const updateIndex = _.findIndex(state, (goal) => goal.id == action.payload.savingGoal.id)
        const updatedGoals = state
        updatedGoals[updateIndex] = action.payload.savingGoal

        return [...updatedGoals];
      } 
      
      return [...state];      
    }

    case ACTION_TYPES.CANCEL_PURCHASE_SUCCESS: {
      const updateIndex = _.findIndex(state, (goal) => goal.id == action.payload.savingGoal.id)
      const updatedGoals = state
      updatedGoals[updateIndex] = action.payload.savingGoal

      return [...updatedGoals];
    }

    case ACTION_TYPES.REQUEST_COLLECTION_CONFIRM_SUCCESS: {
      if (action.payload.savingGoal && action.payload.savingGoal.id) {
        const updateIndex = _.findIndex(state, (goal) => goal.id == action.payload.savingGoal.id)
        const updatedGoals = state
        updatedGoals[updateIndex] = action.payload.savingGoal

        return [...updatedGoals];
      }
      return [...state];      
    }

    case ACTION_TYPES.VERIFY_USER_TOKEN_SUCCESS: {
      return action.payload.savingGoals;
    }

    case ACTION_TYPES.SIGN_IN_USER_SUCCESS: {
      return action.payload.user.savingGoals;
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.savingGoals;
    }

    default:
      return state;
  }
}

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminOffersReducer(state = INITIAL_STATE.adminOffers, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_OFFER_CREATE_SUCCESS:
      return {
        ...action.payload,
      };

    case ACTION_TYPES.ADMIN_OFFER_DELETE_SUCCESS:
      return {
        ...action.payload,
      };

    case ACTION_TYPES.REQUEST_ADMIN_OFFER_COPY:
      return {
        ...state,
        copyOfferId: action.payload,
      };

    case ACTION_TYPES.ADMIN_OFFER_UPDATE_SUCCESS:
      return {
        ...action.payload,
      };

    case ACTION_TYPES.ADMIN_OFFER_DETAIL_SUCCESS:
      return {
        ...action.payload,
      };

    case ACTION_TYPES.ADMIN_OFFERS_LIST_SUCCESS:
      return {
        ...action.payload,
      };

    case ACTION_TYPES.ADMIN_OFFERS_LIST_BY_PRODUCT_ID_SUCCESS:
      return {
        ...action.payload,
      };

    case ACTION_TYPES.CREATE_ADMIN_OFFER_SUCCESS:
      return state;

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminOffers;

    default:
      return state;
  }
}

import { connect } from 'react-redux';
import { requestCreateMandate, updateSavingPlan, requestGoalDetail } from 'src/redux/actions';
import CompletePurchase from './complete-purchase';

function mapStateToProps(state) {
  return {
    savingPlan: state.savingPlan,
  };
}

export default connect(mapStateToProps, {
  requestCreateMandate,
  updateSavingPlan,
  requestGoalDetail,
})(CompletePurchase);

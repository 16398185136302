import { ACTION_TYPES } from 'src/constants';

export function updateUser(payload) {
  return {
    type: ACTION_TYPES.UPDATE_USER,
    payload,
  };
}

// user
export function requestVerifyUser(payload) {
  return {
    type: ACTION_TYPES.REQUEST_VERIFY_USER_TOKEN,
    payload,
  };
}

export function verifyUserSuccess(payload) {
  return {
    type: ACTION_TYPES.VERIFY_USER_TOKEN_SUCCESS,
    payload,
  };
}

export function verifyUserFailure(payload) {
  return {
    type: ACTION_TYPES.VERIFY_USER_TOKEN_FAILED,
    payload,
  };
}

export function requestResendOtp(payload) {
  return {
    type: ACTION_TYPES.REQUEST_RESEND_OTP,
    payload,
  };
}

export function resendOtpSuccess(payload) {
  return {
    type: ACTION_TYPES.RESEND_OTP_SUCCESS,
    payload,
  };
}

export function resendOtpFailure(payload) {
  return {
    type: ACTION_TYPES.RESEND_OTP_FAILED,
    payload,
  };
}

export function requestSignInUser(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SIGN_IN_USER,
    payload,
  };
}

export function signInUserSuccess(payload) {
  return {
    type: ACTION_TYPES.SIGN_IN_USER_SUCCESS,
    payload,
  };
}

export function signInUserFailure(payload) {
  return {
    type: ACTION_TYPES.SIGN_IN_USER_FAILED,
    payload,
  };
}

export function requestVerifyOTP(payload) {
  return {
    type: ACTION_TYPES.REQUEST_VERIFY_OTP,
    payload,
  };
}

export function verifyOTPSuccess(payload) {
  return {
    type: ACTION_TYPES.VERIFY_OTP_SUCCESS,
    payload,
  };
}

export function verifyOTPFailure(payload) {
  return {
    type: ACTION_TYPES.VERIFY_OTP_FAILED,
    payload,
  };
}

export function requestValidateEmail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_EMAIL_VALIDATION,
    payload,
  };
}

export function validateEmailSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_EMAIL_VALIDATION_SUCCEEDED,
    payload,
  };
}

export function validateEmailFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_EMAIL_VALIDATION_FAILED,
    payload,
  };
}

export function requestLogout(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LOGOUT,
    payload,
  };
}

export function requestLogoutSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED,
    payload,
  };
}

export function requestLogoutFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LOGOUT_FAILED,
    payload,
  };
}

export function updateUserRequest(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_REQUEST,
    payload,
  };
}

export function updateUserSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_SUCCESS,
    payload,
  };
}

export function updateUserFailure(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_FAILED,
    payload,
  };
}

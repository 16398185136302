import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import InputMask from 'react-input-mask';
import { Screen, Wizard, Loader, SavvySupport, ButtonOne } from 'src/components';
import styles from './_link-bank-account.module.scss';

class LinkBankAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,    
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      bankAccountNumber: '',
      ifscCode: '',
    };
  }

  componentDidMount() {
    this.userUpdateRequestSubscription = EventBus.on(ACTION_TYPES.USER_UPDATE_REQUEST, () => this.setState({ loading: true }));
    this.userUpdateSuccessSubscription = EventBus.on(ACTION_TYPES.USER_UPDATE_SUCCESS, this._onUserUpdatedSuccess);
    this.userUpdateFailureSubscription = EventBus.on(ACTION_TYPES.USER_UPDATE_FAILED, () => this.setState({ loading: false }));

    this.requestBankDetailsRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_BANK_DETAILS, () => this.setState({ loading: true }));    
    this.requestBankDetailsSuccessSubscription = EventBus.on(ACTION_TYPES.REQUEST_BANK_DETAILS_SUCCEEDED, this._onBankDetailsFetched);
    this.requestBankDetailsFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_BANK_DETAILS_FAILED, () => this.setState({ loading: false }));

    this.updateBankAccountRequestSubscription = EventBus.on(ACTION_TYPES.UPDATE_BANK_ACCOUNT_REQUEST, () => this.setState({ loading: true }));
    this.updateBankAccountSuccessSubscription = EventBus.on(ACTION_TYPES.UPDATE_BANK_ACCOUNT_SUCCESS, this._onupdateBankAccountSuccess);
    this.updateBankAccountFailureSubscription = EventBus.on(ACTION_TYPES.UPDATE_BANK_ACCOUNT_FAILED, () => this.setState({ loading: false }));    
  }

  componentWillUnmount() {
    this.userUpdateRequestSubscription();
    this.userUpdateSuccessSubscription();
    this.userUpdateFailureSubscription();

    this.requestBankDetailsRequestSubscription();
    this.requestBankDetailsSuccessSubscription();
    this.requestBankDetailsFailureSubscription();

    this.updateBankAccountRequestSubscription();
    this.updateBankAccountSuccessSubscription();
    this.updateBankAccountFailureSubscription();
  }

  _onChangeIFSCCode = (e) => {
    const ifscCode = (e.target.value || '').toUpperCase();
    this.setState({ ifscCode });
  };

  _onupdateBankAccountSuccess = () => {
    this.setState({ loading: false }, () => {
      this.props.history.push('/auto-pay/setup');
    });   
  }

  _isValidForm = () => {    
    const { firstName, lastName, bankAccountNumber, ifscCode } = this.state;    
    return firstName && lastName && bankAccountNumber && ifscCode;       
  }

  _onUpdateUserProfile = () => {        
    this.props.updateUserRequest({      
      first_name: this.state.firstName,
      last_name: this.state.lastName
    });
  }

  _onUserUpdatedSuccess = () => {
    this._onLinkBankAccount();
  }

  _onLinkBankAccount = () => {
    this.props.requestBankDetails({
      ifsc_code: this.state.ifscCode,
    });    
  }

  _onBankDetailsFetched = (response) => {    
    this.props.updateBankAccountRequest({
      bank_account: {        
        bank_account_type: 'SB', 
        category: 'deposit',       
        account_number: this.state.bankAccountNumber,        
        ifsc_code: response?.ifscCode,
        bank_name: response?.bankName,
        bank_code: response?.bankCode,       
        city: response?.city,
        branch: response?.branchName,
        address: 'address'
      }
    })
  }

  render() {
    return (
      <Screen>
        <div className={styles['link-bank']}>
          <Row className="justify-content-md-center">
            <Col lg="9">
              <Wizard progress={33} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={9}>
              <h4 className={styles['title']}>
                Please link your bank account to setup for the remaining payments
              </h4>
              <div className={styles['sub-title']}>
                Please enter details of the account you would like to link
              </div>
            </Col>
          </Row>
          <div className={styles['bank-account-input']}>            
            <Row className="justify-content-center">
              <Col xs={12} lg={5}>
                <Row>
                  <Col xs={12} lg={6}>
                    <div className={styles['ba-group-input']}>
                      <div>
                        <label>First Name</label>
                      </div>
                      <input
                        className={styles['ba-input']}
                        placeholder="John"
                        value={this.state.firstName}
                        onChange={(e) => this.setState({ firstName: e.target.value })}                        
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className={styles['ba-group-input']}>
                      <div>
                        <label>Last Name</label>
                      </div>
                      <input
                        className={styles['ba-input']}
                        placeholder="Doe"
                        value={this.state.lastName}
                        onChange={(e) => this.setState({ lastName: e.target.value })}                        
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>        
            <Row className="justify-content-center">
              <Col xs={12} lg={5}>
                <div className={styles['ba-group-input']}>
                  <div>
                    <label>Account Number</label>
                  </div>
                  <InputMask
                    className={styles['ba-input']}
                    placeholder="099XXXXXXXXX90"
                    value={this.state.bankAccountNumber}
                    onChange={(e) => this.setState({ bankAccountNumber: e.target.value })}
                    mask="99999999999999999999"
                    maskChar={null}
                  />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} lg={5}>
                <div className={styles['ba-group-input']}>
                  <div>
                    <label>IFSC Code</label>
                  </div>
                  <InputMask
                    className={styles['ba-input']}
                    placeholder="HDFC0000001"
                    value={this.state.ifscCode}
                    onChange={this._onChangeIFSCCode}
                    mask="aaaa*******"
                    maskChar={null}                    
                  />
                </div>
              </Col>
            </Row>
          </div>
          <Row className="justify-content-center">
            <Col lg={8} className="text-center">
              <div className={styles['custom-btn']}>
                <ButtonOne 
                  disabled={!this._isValidForm()}
                  text="SETUP PAYMENT PLAN" 
                  onClick={this._onUpdateUserProfile} 
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className={styles['encrypt']}>
                <div className={styles['encrypt-inner']}>
                  <div className={styles['encrypt-text']}>
                    Your data is encrypted by 256-bit security
                  </div>
                  <div className={styles['encrypt-images']}>
                    <img src="/img/sebi-icici.svg" alt="img" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Loader loading={this.state.loading} />
        </div>
        <SavvySupport />
      </Screen>
    );
  }
}

export default withRouter(LinkBankAccount);

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Loader } from 'src/components';
import { withRouter } from 'react-router-dom';
import { ACTION_TYPES } from 'src/constants';
import { Form, Formik } from 'formik';
import moment from 'moment';
import EventBus from 'eventing-bus';
import { InputSelectOne } from 'src/components/form-inputs';
import { Container, Section, InputText } from '../../components';
import TransactionsTable from './transactions-table';
import styles from './_transactions.module.scss';

class Transactions extends Component {
  state = {
    loading: false,
    pageNumber: 1,
    filteredData: [],
    searchStatus: '',
    searchAmount: '',
    searchType: '',
    startDate: '',
    endDate: '',

    totalCount: 0,
    searchDate: '',
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminTransactionList({
          page: pageNumber,
        }),
    );
  };

  componentDidMount() {
    this.requestAdminTransactionList = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST,
      () => this.setState({ loading: true }),
    );

    this.requestAdminTransactionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST_SUCCESS,
      () => {
        let { transactions } = this.props;
        const { depositsTotal, paymentsTotal, withdrawalsTotal } = this.props.transactions;
        const totalItems = Number(depositsTotal + paymentsTotal + withdrawalsTotal);
        transactions = this._organizeTransactionsData(transactions);

        this.setState({ loading: false, totalCount: totalItems, filteredData: transactions });
      },
    );

    this.requestAdminTransactionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST_FAILED,
      () => this.setState({ loading: false }),
    );

    this.props.requestAdminTransactionList({
      page: this.state.pageNumber,
    });
  }

  componentWillUnmount() {
    this.requestAdminTransactionList();
    this.requestAdminTransactionListSuccess();
    this.requestAdminTransactionListFailed();
  }

  /**
   * add a label to the transactions array
   * example: 'Deposited' for savingsDeposit data, 'withdrwal' for withdrawals data
   * @param {array} data
   * @param {string} labelName
   * @returns {array}
   */
  _addTransactionLabel = (data, labelName) => {
    const labeledData = data.map((val) => {
      val['label'] = labelName;
      return val;
    });

    return labeledData;
  };

  /**
   * Raw data in the form of object of arrays is received which needs to be merged
   * and sorted according to date
   *
   * @param {object} transactionsData
   * @returns {array}
   */
  _organizeTransactionsData = (transactionsData) => {
    let { deposits = [], withdrawals = [], payments = [] } = transactionsData;
    deposits = this._addTransactionLabel(deposits, 'Deposited');
    withdrawals = this._addTransactionLabel(withdrawals, 'Withdrawn');
    payments = this._addTransactionLabel(payments, 'Payments');

    let organizedData = [...deposits, ...withdrawals, ...payments];

    return organizedData.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
    );
  };

  _onActionClick = () => {
    // console.log('onButtonClick')
    this.props.history.push('/admin/paymentlink/new');
  };

  handleChange = (e) => {
    let { transactions } = this.props;
    transactions = this._organizeTransactionsData(transactions);
    const { searchStatus, searchAmount, searchType, searchDate, startDate, endDate } = this.state;
    let filteredData = transactions || [];
    if (e.target.name === 'searchStatus' || (searchStatus && e.target.name !== 'searchStatus')) {
      let regex = new RegExp(
        searchStatus && e.target.name !== 'searchStatus'
          ? searchStatus
          : e.target.value
          ? e.target.value.toLowerCase().trim()
          : '',
        'i',
      );
      filteredData = filteredData.filter((item) =>
        regex.test(item?.status ? item?.status.toLowerCase() : ''),
      );
    }

    if (e.target.name === 'searchAmount' || (searchAmount && e.target.name !== 'searchAmount')) {
      let regex = new RegExp(
        searchAmount && e.target.name !== 'searchAmount' ? searchAmount : e.target.value,
        'i',
      );
      filteredData = filteredData.filter((item) => regex.test(item?.amount));
    }

    if (e.target.name === 'searchType' || (searchType && e.target.name !== 'searchType')) {
      let regex = new RegExp(
        searchType && e.target.name !== 'searchType'
          ? searchType
          : e.target.value
          ? e.target.value.toLowerCase().trim()
          : '',
        'i',
      );
      filteredData = filteredData.filter((item) =>
        regex.test(item?.label ? item?.label.toLowerCase() : ''),
      );
    }

    if (e.target.name === 'searchDate' || (searchDate && e.target.name !== 'searchDate')) {
      const dateValue = searchDate && e.target.name !== 'searchDate' ? searchDate : e.target.value;
      if (dateValue) {
        filteredData = filteredData.filter(
          (item) => moment(item?.createdAt, 'DDMMYYYY').format('YYYY-MM-DD') === dateValue,
        );
      }
    }

    let searchStartDate = '';
    let searchEndDate = '';

    if (e.target.name === 'startDate' || (startDate && e.target.name !== 'startDate')) {
      searchStartDate = startDate && e.target.name !== 'startDate' ? startDate : e.target.value;
    }

    if (e.target.name === 'endDate' || (endDate && e.target.name !== 'endDate')) {
      searchEndDate = endDate && e.target.name !== 'endDate' ? endDate : e.target.value;
    }

    if (searchStartDate && searchEndDate) {
      filteredData = filteredData.filter(
        (item) =>
          moment(item?.createdAt, 'DDMMYYYY').format('YYYY-MM-DD') >= searchStartDate &&
          moment(item?.createdAt, 'DDMMYYYY').format('YYYY-MM-DD') <= searchEndDate,
      );
    }

    this.setState({
      [e.target.name]: (e.target.value && e.target.value.trim()) || '',
      filteredData,
      totalCount: filteredData.length || 0,
    });
  };

  // handleRangeSearch = (e) => {
  //   let { transactions } = this.props;
  //   transactions = this._organizeTransactionsData(transactions);
  //   const { startDate, endDate } = this.state;
  //   let filteredData = transactions || [];

  //   let searchStartDate = '';
  //   let searchEndDate = '';

  //   if (e.target.name === 'startDate' || (startDate && e.target.name !== 'startDate')) {
  //     searchStartDate = startDate && e.target.name !== 'startDate' ? startDate : e.target.value;
  //   }

  //   if (e.target.name === 'endDate' || (endDate && e.target.name !== 'endDate')) {
  //     searchEndDate = endDate && e.target.name !== 'endDate' ? endDate : e.target.value;
  //   }

  //   if (searchStartDate && searchEndDate) {
  //     filteredData = filteredData.filter(
  //       (item) =>
  //         moment(item?.createdAt, 'DDMMYYYY').format('YYYY-MM-DD') >= searchStartDate &&
  //         moment(item?.createdAt, 'DDMMYYYY').format('YYYY-MM-DD') <= searchEndDate,
  //     );
  //   }

  //   this.setState({
  //     [e.target.name]: (e.target.value && e.target.value.trim()) || '',
  //     filteredData,
  //     totalCount: filteredData.length || 0,
  //   });
  // };

  render() {
    const {
      loading,
      filteredData,
      totalCount,
      // searchAmount,
      // searchStatus,
      // searchType,
      // searchDate,
      startDate,
      endDate,
    } = this.state;

    return (
      <div className={styles['payment-links']}>
        <Container
          title="Transactions"
          buttonText="create a new link"
          onButtonClick={this._onActionClick}
        >
          <Formik
            initialValues={{
              searchAmount: '',
              searchDate: '',
              searchType: '',
              searchStatus: '',
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Section title="Filter By">
                  <Row>
                    {/* <Col> */}
                    {/* <InputText
                        label="Amount"
                        value={searchAmount}
                        name="searchAmount"
                        onChange={this.handleChange}
                        type="number"
                      /> */}
                    {/* </Col> */}
                    <Col>
                      <InputText
                        label="Start Date"
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <InputText
                        label="End Date"
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={this.handleChange}
                      />
                    </Col>
                    {/* <Col>
                      <InputText
                        label="Date"
                        type="date"
                        value={searchDate}
                        name="searchDate"
                        onChange={this.handleChange}
                      />
                    </Col> */}
                    <Col>
                      <InputSelectOne
                        label="Type"
                        name="searchType"
                        labelStyle={styles['label']}
                        onChange={(e) => {
                          this.handleChange(e);
                          setFieldValue('searchType', e.target.value);
                        }}
                        className={styles['select']}
                      >
                        <option>&nbsp;</option>
                        <option value="Deposits">Deposits</option>
                        <option value="Payments">Payments</option>
                      </InputSelectOne>
                    </Col>
                    <Col>
                      {/* <InputText
                        label="Status"
                        value={searchStatus}
                        name="searchStatus"
                        onChange={this.handleChange}
                      /> */}
                      <InputSelectOne
                        label="Status"
                        name="searchStatus"
                        labelStyle={styles['label']}
                        onChange={(e) => {
                          this.handleChange(e);
                          setFieldValue('searchStatus', e.target.value);
                        }}
                        className={styles['select']}
                      >
                        <option>&nbsp;</option>
                        <option value="Pending">Pending</option>
                        <option value="Failed">Failed</option>
                        <option value="Completed">Completed</option>
                      </InputSelectOne>
                    </Col>
                  </Row>
                </Section>
              </Form>
            )}
          </Formik>
          <TransactionsTable
            data={filteredData}
            totalItems={totalCount}
            handlePaginationChange={this._handlePaginationChange}
          />
        </Container>
        <Loader loading={loading} />
      </div>
    );
  }
}

export default withRouter(Transactions);

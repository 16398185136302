import React, { Component } from 'react';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { Convert } from 'src/helpers';
import moment from 'moment';
import Loader from '../loader';
// import Strike from '../strike';
import JumbotronTwo from '../jumbotrons/jumbotron-two';
import styles from './_loginbanner.module.scss';

const DEFAULT_IMAGE =
  '/img/bag.png';

export class LoginBanner extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.requestProductSubscription = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.productSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_DETAIL_SUCCEEDED,
      () => this.setState({ loading: false }),
    );
    this.productSubscriptionFailure = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestProductOffersSubscription = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_OFFERS, () =>
      this.setState({ loading: true }),
    );
    this.productOffersSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED,
      () => this.setState({ loading: false }),
    );
    this.productOffersSubscriptionFailure = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_OFFERS_FAILED,
      () => this.setState({ loading: false }),
    );

    if (this.props.productUuid) {
      this.props.requestProductDetail({ uuid: this.props.productUuid });
    } else if (this.props.productOfferUuid) {
      this.props.requestProductOffers({ uuid: this.props.productOfferUuid });
    }
  }

  componentWillUnmount() {
    this.requestProductSubscription();
    this.productSubscriptionSuccess();
    this.productSubscriptionFailure();

    this.requestProductOffersSubscription();
    this.productOffersSubscriptionSuccess();
    this.productOffersSubscriptionFailure();
  }

  _prepareProductData = () => {
    const {
      product: { productName = '', imageUrls = [], productOffers = [], amount = '' } = {},
      productOfferUuid,
    } = this.props;
    let productOffer = '';
    let productAmount = Convert.toAbbreviatedNumber(amount);
    let returnAmount = '';

    if (productOfferUuid) {
      productOffer = productOffers.find((productOffer) => productOffer.uuid == productOfferUuid);
    } else {
      productOffers.sort((a, b) => b.months - a.months);
      productOffer = productOffers.length && productOffers[0];
    }

    if (productOffer) {
      if (productOffer?.paymentCalendar && Object.keys(productOffer?.paymentCalendar).length > 0) {
        const sortedPaymentCalendarDates = Object.keys(productOffer.paymentCalendar).sort(
          (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
        );
        productAmount = Convert.toAbbreviatedNumber(
          productOffer.paymentCalendar[sortedPaymentCalendarDates[1]],
        );
      }

      const offerAmount = amount - (productOffer.discountPercentage / 100) * amount;
      returnAmount = (offerAmount / 100) * productOffer.savingReturn;
      returnAmount = Convert.toAbbreviatedNumber(returnAmount);
    }

    let productData = {
      productName,
      imageUrls,
      productAmount,
      productOffer,
      returnAmount,
    };

    // if(productOffer){
    //   let discountedAmount = amount - Number(((amount*productOffer.discountPercentage)/100).toFixed(1))
    //   discountedAmount = (discountedAmount/productOffer.months).toFixed()
    //   const productAmount = (amount/productOffer.months).toFixed()
    //   const savingReturn = Number(productOffer.savingReturn || 1);
    //   return {
    //     ...productData,
    //     savingReturn,
    //     discountedAmount: Convert.toAbbreviatedNumber(discountedAmount),
    //     productAmount: Convert.toAbbreviatedNumber(productAmount)
    //   }
    // }

    return productData;
  };

  render() {
    const productData = this._prepareProductData();

    return (
      <>
        <JumbotronTwo
          imgSrc={(productData?.imageUrls && productData?.imageUrls[0]?.url) || DEFAULT_IMAGE}
          content={
            <div className={styles.title}>
              {/* {productData?.productName ? (
                <>
                  <span>Book {productData?.productName} for only </span>
                  <span>
                    {productData?.discountedAmount ? productData?.discountedAmount : null}
                  </span>
                  <span>{!productData?.discountedAmount ? productData.productAmount : null}</span>
                  <span>{productData?.productOffer ? '/ month' : ''}</span>
                  <>
                    {productData?.returnAmount && (
                      <div className={styles.slogan}>
                        Earn brand rewards and upto {productData?.returnAmount} return on savings
                      </div>
                    )}
                  </>
                </>
              ) : (
                <>
                  <div className={styles['generic-title']}>
                    Shop with <span>SavvyPay</span>
                  </div>
                  <div className={styles['generic-desc']}>
                    Sign in to get exclusive discounts, check your
                    <br /> progress and shop debt-free
                  </div>
                </>
              )} */}
            </div>
          }
          className={styles.jumbotron}
          bodyContainerStyles={styles.body}
        />
        <div className={styles.footer}>
          <div className={styles.title}>
            {productData?.productName ? (
              <>
                <span>Book {productData?.productName} for only </span>
                <span>{productData?.discountedAmount ? productData?.discountedAmount : null}</span>
                <span>{!productData?.discountedAmount ? productData.productAmount : null}</span>
                <span>{productData?.productOffer ? '/ month' : ''}</span>
                <>
                  {productData?.returnAmount && (
                    <div className={styles.slogan}>
                      Earn brand rewards and upto {productData?.returnAmount} return on savings
                    </div>
                  )}
                </>
              </>
            ) : (
              <>
                <span>
                  Shop with <span>SavvyPay</span>
                </span>
                <div className={styles['slogan']}>
                  Sign in to get exclusive discounts, check your progress and shop debt-free
                </div>
              </>
            )}
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default LoginBanner;

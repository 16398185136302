import React, { Component } from 'react';
import { InputSelectOne } from 'src/components/form-inputs';
import { Form, Formik, FieldArray } from 'formik';
import { withRouter } from 'react-router-dom';
import { ACTION_TYPES } from 'src/constants';
import * as yup from 'yup';
// import moment from 'moment';
import { Loader } from 'src/components';
import EventBus from 'eventing-bus';
import { AsyncSelectInput } from 'src/components/theme';
import { Container, Section, ButtonOne } from '../../components';
import styles from './_email-templates.module.scss';
import EmailTemplateModal from './email-template-modal';

class EmailTemplates extends Component {
  codeInput = null;

  state = {
    loading: false,
    templates: [],
    showGenerateCodeModal: false,
    code: '',
    codeCopied: false,
    selectedProductId: '',
  };

  _validationSchema = yup.object().shape({
    template: yup.string().required('Please select a template type.'),
    product_list: yup.array().of(
      yup.object().shape({
        productUuid: yup.string().required('Please select a Product'),
      }),
    ),
  });

  componentDidMount() {
    this.fetchEmailTemplateRequestSubscription = EventBus.on(
      ACTION_TYPES.FETCH_EMAIL_TEMPLATE_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.fetchEmailTemplateSuccessSubscription = EventBus.on(
      ACTION_TYPES.FETCH_EMAIL_TEMPLATE_SUCCESS,
      this._onfetchEmailTemplateSuccess,
    );
    this.fetchEmailTemplateFailureSubscription = EventBus.on(
      ACTION_TYPES.FETCH_EMAIL_TEMPLATE_FAILED,
      () => this.setState({ loading: false }),
    );

    this.requestAdminProductList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminProductListSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminProductListFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.generateEmailCodeRequestSubscription = EventBus.on(
      ACTION_TYPES.GENERATE_EMAIL_CODE_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.generateEmailCodeSuccessSubscription = EventBus.on(
      ACTION_TYPES.GENERATE_EMAIL_CODE_SUCCESS,
      this._onGenerateEmailCodeSuccess,
    );
    this.generateEmailCodeFailureSubscription = EventBus.on(
      ACTION_TYPES.GENERATE_EMAIL_CODE_FAILED,
      () => this.setState({ loading: false }),
    );

    this.props.fetchEmailTemplatesRequest();
    this.props.requestAdminProductList({ page: 1 });
  }

  componentWillUnmount() {
    this.fetchEmailTemplateRequestSubscription();
    this.fetchEmailTemplateSuccessSubscription();
    this.fetchEmailTemplateFailureSubscription();

    this.requestAdminProductList();
    this.adminProductListSuccess();
    this.adminProductListFailure();

    this.generateEmailCodeRequestSubscription();
    this.generateEmailCodeSuccessSubscription();
    this.generateEmailCodeFailureSubscription();
  }

  _onfetchEmailTemplateSuccess = ({ templates }) => {
    this.setState({ loading: false, templates });
  };

  _onGenerateEmailCode = (values, { setSubmitting }) => {
    const { template } = values;
    const { selectedProductId } = this.state;

    this.props.generateEmailCodeRequest({
      type: template,
      product_uuid: selectedProductId,
    });

    setSubmitting(false);
  };

  _onGenerateEmailCodeSuccess = (code) => {
    this.setState({ loading: false, showGenerateCodeModal: true, code });
  };

  loadOptions = (inputValue, callback) => {
    this.props.requestAdminProductListByName({ name: inputValue, callback });
  };

  _decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  _copyCodeToClipboard = (e) => {
    this.codeInput.select();
    this.codeInput.setSelectionRange(0, 99999);

    document.execCommand('copy');
    this.setState({ codeCopied: true });
    setTimeout(() => {
      this.setState({ codeCopied: false });
    }, 2000);
    e.preventDefault();
  };

  closeModal = () => {
    this.setState({ showGenerateCodeModal: false });
  };

  render() {
    const { products = [] } = this.props;
    const { templates, showGenerateCodeModal, code } = this.state;
    let productsData = [];
    if (products && products.length > 0) {
      productsData = products.map((data) => ({
        ...data,
        value: data?.productName,
        label: data?.productName,
      }));
    }

    return (
      <div className={styles['payment-links']}>
        <Container title="Email Templates">
          <Formik
            initialValues={{
              template: '',
              product_list: [{ productUuid: '' }],
            }}
            validationSchema={this._validationSchema}
            onSubmit={this._onGenerateEmailCode}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <Section title="Choose a Template">
                  <InputSelectOne
                    label="Select Template"
                    name="template"
                    id="template"
                    onChange={(e) => {
                      setFieldValue('template', e.target.value);
                    }}
                  >
                    {templates && templates.length === 0 && <option>Loading...</option>}
                    <option value="0"> </option>
                    {templates &&
                      templates.length > 0 &&
                      templates.map((template) => (
                        <option key={template.type} value={template.type}>
                          {template.title}
                        </option>
                      ))}
                  </InputSelectOne>
                  <br />
                  <FieldArray
                    name="product_list"
                    render={() => (
                      <>
                        {values?.product_list &&
                          values?.product_list.length > 0 &&
                          values?.product_list.map((offerList, index) => (
                            <div key={`row-${index}`}>
                              <div className={styles['label']}>Select Product</div>
                              <AsyncSelectInput
                                cacheOptions
                                placeholder=""
                                customClassnames="custom-template-product-select"
                                loadOptions={this.loadOptions}
                                defaultOptions={productsData}
                                labelsize="large"
                                name={`product_list[${index}].productUuid`}
                                id={`product_list[${index}].productUuid`}
                                onChange={(e) => {
                                  this.setState({ selectedProductId: e?.uuid || '' });
                                  setFieldValue(`product_list[${index}].productUuid`, e?.uuid);
                                }}
                              />
                              {errors?.product_list &&
                                errors?.product_list.length > 0 &&
                                errors?.product_list[index]?.productUuid && (
                                  <div className={styles['error-box']}>
                                    {errors?.product_list[index].productUuid}
                                  </div>
                                )}
                            </div>
                          ))}
                      </>
                    )}
                  />
                </Section>
                <Section title="">
                  <div className={styles['save-btn']}>
                    <ButtonOne text="Generate Email Code" type="submit" />
                  </div>
                </Section>
              </Form>
            )}
          </Formik>
        </Container>
        {showGenerateCodeModal && (
          <EmailTemplateModal
            code={code || ''}
            copyCodeToClipboard={this._copyCodeToClipboard}
            decodeHtml={this._decodeHtml}
            codeInput={(ref) => (this.codeInput = ref)}
            codeCopied={this.state.codeCopied}
            closeModal={this.closeModal}
          />
        )}

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default withRouter(EmailTemplates);

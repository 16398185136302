import React from 'react'
import cx from 'classnames'
import styles from './_customcardthree.module.scss'

export default function CustomCardThree(props) {
  return (
    <div className={cx(styles.container, props.containerstyles)}>
      <div className={cx(styles.label, props.labelstyles)}>
        {props.label}
      </div>
      <div className={cx(styles.rightContent, props.rightContentStyles)}>
        {props.rightContent}
      </div>
    </div>
  )
}

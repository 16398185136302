import { ACTION_TYPES } from 'src/constants';

/**
 *
 * Product Create actions
 *
 */
export function requestAdminProductCreate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PRODUCT_CREATE,
    payload,
  };
}

export function adminProductCreateSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_CREATE_SUCCESS,
    payload,
  };
}

export function adminProductCreateFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_CREATE_FAILED,
    payload,
  };
}

/**
 *
 * Product Update actions
 *
 */
export function requestAdminProductUpdate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PRODUCT_UPDATE,
    payload,
  };
}

export function adminProductUpdateSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_UPDATE_SUCCESS,
    payload,
  };
}

export function adminProductUpdateFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_UPDATE_FAILED,
    payload,
  };
}

/**
 *
 * product detail actions
 *
 */

export function requestAdminProductDetail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PRODUCT_DETAIL,
    payload,
  };
}

export function adminProductDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_DETAIL_SUCCESS,
    payload,
  };
}

export function adminProductDetailFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_DETAIL_FAILED,
    payload,
  };
}

/**
 *
 * Product list actions
 *
 */

export function requestAdminProductList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST,
    payload,
  };
}

export function adminProductListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS,
    payload,
  };
}

export function adminProductListFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_LIST_FAILED,
    payload,
  };
}

export function requestAdminProductListByName(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST_BY_NAME,
    payload,
  };
}

export function adminProductListByNameSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_LIST_BY_NAME_SUCCESS,
    payload,
  };
}

export function adminProductListByNameFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_LIST_BY_NAME_FAILED,
    payload,
  };
}

export function createAdminProduct(payload) {
  return {
    type: ACTION_TYPES.CREATE_ADMIN_PRODUCT,
    payload,
  };
}

export function createAdminProductSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_ADMIN_PRODUCT_SUCCESS,
    payload,
  };
}

export function createAdminProductFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_ADMIN_PRODUCT_FAILED,
    payload,
  };
}

export function adminProductImageDelete(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE,
    payload,
  };
}

export function adminProductImageDeleteSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE_SUCCESS,
    payload,
  };
}

export function adminProductImageDeleteFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE_FAILED,
    payload,
  };
}

export function adminProductImageUpload(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD,
    payload,
  };
}

export function adminProductImageUploadSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD_SUCCESS,
    payload,
  };
}

export function adminProductImageUploadFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD_FAILED,
    payload,
  };
}

export function adminProductLink(payload) {
  return {
    type: ACTION_TYPES.PRODUCT_LINK_REQUEST,
    payload,
  };
}

export function adminProductLinkSuccess(payload) {
  return {
    type: ACTION_TYPES.PRODUCT_LINK_SUCCESS,
    payload,
  };
}

export function adminProductLinkFailed(payload) {
  return {
    type: ACTION_TYPES.PRODUCT_LINK_FAILED,
    payload,
  };
}

import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { 
  adminPartnerListSuccess, 
  adminPartnerListFailed, 
  adminProfileSuccess,
  adminProfileFailed,
  adminProfileUpdateSuccess,
  adminProfileUpdateFailed
} from 'src/redux/actions';
import { adminPartnerList, adminProfile, adminProfileUpdate } from '../api';

export function* watchAdminPartnersRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PARTNER_LIST, requestAdminPartnerList);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PROFILE, requestAdminProfile);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PROFILE_UPDATE, requestAdminProfileUpdate);
}

function* requestAdminPartnerList(action) {
  try {
    const partners = yield call(adminPartnerList, action.payload);
    yield put(adminPartnerListSuccess(partners));
  } catch (e) {
    yield put(adminPartnerListFailed(e));
  }
}

function* requestAdminProfile(action) {
  try {
    const profile = yield call(adminProfile, action.payload);
    yield put(adminProfileSuccess(profile));
  } catch (e) {
    yield put(adminProfileFailed(e));
  }
}

function* requestAdminProfileUpdate(action) {
  try {
    const profile = yield call(adminProfileUpdate, action.payload);
    yield put(adminProfileUpdateSuccess(profile));
  } catch (e) {
    yield put(adminProfileUpdateFailed(e));
  }
}


import React, { Component } from 'react';
import cx from 'classnames';
import styles from './kyc-verified.module.scss';

class KycVerified extends Component {
  render() {
    const { onNext, user } = this.props;

    return (
      <div className={styles['kyc-verified']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Your KYC has been verified!</div>
              <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['pay-content']}>
          <div className={styles['title']}>Your KYC has been verified!</div>
          <div className={styles['tick']}>
            <img src="/img/tick-2.svg" alt="tick" />
          </div>
          <div className={styles['message']}>
            You may now start saving for your holiday <br />{' '}
            <span>Your Folio Number is {user?.folio?.folioNumber}</span>
          </div>
        </div>
        <div className={styles['spacer']} />
        <div className={styles['footer']} onClick={onNext}>
          SETUP SIP
        </div>
      </div>
    );
  }
}

export default KycVerified;

import React, { Component } from 'react';
import styles from './_link-error.module.scss';

class LinkError extends Component {
    render() {
        const getUrl = new URL(document.location.href);
        const errorMessage = getUrl.searchParams.get('error_message');
        return (
            <div className={styles['error-fallback']}>
                <img className={styles["logo"]} src="/img/s-logo.svg" alt="Savvy" />
                <img className={styles["oops"]} src="/img/oops.png" alt="Oops!!!" />
                <h3>The link you are trying to open has expired</h3>
                <p>
                    {errorMessage || "Please contact merchant to issue a new link"}
                </p>
            </div>

        )
    }
}

export default LinkError;

import { ACTION_TYPES } from 'src/constants';

export function requestTaxStatus(payload) {
  return {
    type: ACTION_TYPES.REQUEST_TAX_STATUS,
    payload,
  };
}

export function requestTaxStatusSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_TAX_STATUS_SUCCEEDED,
    payload,
  };
}

export function requestTaxStatusFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_TAX_STATUS_FAILED,
    payload,
  };
}

export function requestCheckPan(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CHECK_PAN,
    payload,
  };
}

export function requestCheckPanSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CHECK_PAN_SUCCEEDED,
    payload,
  };
}

export function requestCheckPanFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CHECK_PAN_FAILED,
    payload,
  };
}

export function requestSubmitPan(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_PAN,
    payload,
  };
}

export function requestSubmitPanSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_PAN_SUCCEEDED,
    payload,
  };
}

export function requestSubmitPanFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_PAN_FAILED,
    payload,
  };
}

export function requestFullKycRegistration(payload) {
  return {
    type: ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION,
    payload,
  };
}

export function requestFullKycRegistrationSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_SUCCEEDED,
    payload,
  };
}

export function requestFullKycRegistrationFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_FAILED,
    payload,
  };
}

export function requestKycLogin(payload) {
  return {
    type: ACTION_TYPES.REQUEST_KYC_LOGIN,
    payload,
  };
}

export function requestKycLoginSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_KYC_LOGIN_SUCCEEDED,
    payload,
  };
}

export function requestKycLoginFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_KYC_LOGIN_FAILED,
    payload,
  };
}

export function requestOTMBanks(payload) {
  return {
    type: ACTION_TYPES.REQUEST_OTM_BANKS,
    payload,
  };
}

export function requestOTMBanksSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_OTM_BANKS_SUCCEEDED,
    payload,
  };
}

export function requestOTMBanksFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_OTM_BANKS_FAILED,
    payload,
  };
}

export function requestBankDetails(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BANK_DETAILS,
    payload,
  };
}

export function requestBankDetailsSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BANK_DETAILS_SUCCEEDED,
    payload,
  };
}

export function requestBankDetailsFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BANK_DETAILS_FAILED,
    payload,
  };
}

export function requestSendPennyDrop(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SEND_PENNY_DROP,
    payload,
  };
}

export function requestSendPennyDropSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SEND_PENNY_DROP_SUCCEEDED,
    payload,
  };
}

export function requestSendPennyDropFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SEND_PENNY_DROP_FAILED,
    payload,
  };
}

export function requestSubmitBankAccount(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT,
    payload,
  };
}

export function requestSubmitBankAccountSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT_SUCCEEDED,
    payload,
  };
}

export function requestSubmitBankAccountFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT_FAILED,
    payload,
  };
}

export function requestOccupationList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_OCCUPATION_LIST,
    payload,
  };
}

export function requestOccupationListSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_OCCUPATION_LIST_SUCCEEDED,
    payload,
  };
}

export function requestOccupationListFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_OCCUPATION_LIST_FAILED,
    payload,
  };
}

export function requestSubmitInvestorDetails(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS,
    payload,
  };
}

export function requestSubmitInvestorDetailsSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_SUCCEEDED,
    payload,
  };
}

export function requestSubmitInvestorDetailsFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_FAILED,
    payload,
  };
}

export function requestSubmitInvestorKYC(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC,
    payload,
  };
}

export function requestSubmitInvestorKYCSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC_SUCCEEDED,
    payload,
  };
}

export function requestSubmitInvestorKYCFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC_FAILED,
    payload,
  };
}

export function updateKycParams(payload) {
  return {
    type: ACTION_TYPES.UPDATE_KYC_PARAMS,
    payload,
  };
}

export function extractPanCardRequest(payload) {
  return {
    type: ACTION_TYPES.EXTRACT_PAN_CARD_REQUEST,
    payload,
  };
}

export function extractPanCardSuccess(payload) {
  return {
    type: ACTION_TYPES.EXTRACT_PAN_CARD_SUCCESS,
    payload,
  };
}

export function extractPanCardFailure(payload) {
  return {
    type: ACTION_TYPES.EXTRACT_PAN_CARD_FAILED,
    payload,
  };
}

export function uploadPanCardRequest(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_PAN_CARD_REQUEST,
    payload,
  };
}

export function uploadPanCardSuccess(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_PAN_CARD_SUCCESS,
    payload,
  };
}

export function uploadPanCardFailure(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_PAN_CARD_FAILED,
    payload,
  };
}

export function uploadFormRequest(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_FORM_REQUEST,
    payload,
  };
}

export function uploadFormSuccess(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_FORM_SUCCESS,
    payload,
  };
}

export function uploadFormFailure(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_FORM_FAILED,
    payload,
  };
}

export function extractAddressProofRequest(payload) {
  return {
    type: ACTION_TYPES.EXTRACT_ADDRESS_PROOF_REQUEST,
    payload,
  };
}

export function extractAddressProofSuccess(payload) {
  return {
    type: ACTION_TYPES.EXTRACT_ADDRESS_PROOF_SUCCESS,
    payload,
  };
}

export function extractAddressProofFailure(payload) {
  return {
    type: ACTION_TYPES.EXTRACT_ADDRESS_PROOF_FAILED,
    payload,
  };
}

export function uploadAddressProofRequest(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_ADDRESS_PROOF_REQUEST,
    payload,
  };
}

export function uploadAddressProofSuccess(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_ADDRESS_PROOF_SUCCESS,
    payload,
  };
}

export function uploadAddressProofFailure(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_ADDRESS_PROOF_FAILED,
    payload,
  };
}

export function uploadSignatureRequest(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_SIGNATURE_REQUEST,
    payload,
  };
}

export function uploadSignatureSuccess(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_SIGNATURE_SUCCESS,
    payload,
  };
}

export function uploadSignatureFailure(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_SIGNATURE_FAILED,
    payload,
  };
}

export function startVideoVerificationRequest(payload) {
  return {
    type: ACTION_TYPES.START_VIDEO_VERIFICATION_REQUEST,
    payload,
  };
}

export function startVideoVerificationSuccess(payload) {
  return {
    type: ACTION_TYPES.START_VIDEO_VERIFICATION_SUCCESS,
    payload,
  };
}

export function startVideoVerificationFailure(payload) {
  return {
    type: ACTION_TYPES.START_VIDEO_VERIFICATION_FAILED,
    payload,
  };
}

export function uploadVideoVerificationRequest(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_REQUEST,
    payload,
  };
}

export function uploadVideoVerificationSuccess(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_SUCCESS,
    payload,
  };
}

export function uploadVideoVerificationFailure(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_FAILED,
    payload,
  };
}

export function uploadSelfieRequest(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_SELFIE_REQUEST,
    payload,
  };
}

export function uploadSelfieSuccess(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_SELFIE_SUCCESS,
    payload,
  };
}

export function uploadSelfieFailure(payload) {
  return {
    type: ACTION_TYPES.UPLOAD_SELFIE_FAILED,
    payload,
  };
}

export function createContractRequest(payload) {
  return {
    type: ACTION_TYPES.CREATE_CONTRACT_REQUEST,
    payload,
  };
}

export function createContractSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_CONTRACT_SUCCESS,
    payload,
  };
}

export function createContractFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_CONTRACT_FAILED,
    payload,
  };
}

export function longKycVerificationRequest(payload) {
  return {
    type: ACTION_TYPES.LONG_KYC_VERIFICATION_REQUEST,
    payload,
  };
}

export function longKycVerificationSuccess(payload) {
  return {
    type: ACTION_TYPES.LONG_KYC_VERIFICATION_SUCCESS,
    payload,
  };
}

export function longKycVerificationFailure(payload) {
  return {
    type: ACTION_TYPES.LONG_KYC_VERIFICATION_FAILED,
    payload,
  };
}

export function fatchaRequest(payload) {
  return {
    type: ACTION_TYPES.FATCHA_REQUEST,
    payload,
  };
}

export function fatchaSuccess(payload) {
  return {
    type: ACTION_TYPES.FATCHA_SUCCESS,
    payload,
  };
}

export function fatchaFailure(payload) {
  return {
    type: ACTION_TYPES.FATCHA_FAILED,
    payload,
  };
}

export function fatchaFormRequest(payload) {
  return {
    type: ACTION_TYPES.FATCHA_FORM_REQUEST,
    payload,
  };
}

export function fatchaFormSuccess(payload) {
  return {
    type: ACTION_TYPES.FATCHA_FORM_SUCCESS,
    payload,
  };
}

export function fatchaFormFailure(payload) {
  return {
    type: ACTION_TYPES.FATCHA_FORM_FAILED,
    payload,
  };
}

export function countriesRequest(payload) {
  return {
    type: ACTION_TYPES.KYC_COUNTRIES_REQUEST,
    payload,
  };
}

export function countriesSuccess(payload) {
  return {
    type: ACTION_TYPES.KYC_COUNTRIES_SUCCEEDED,
    payload,
  };
}

export function countriesFailure(payload) {
  return {
    type: ACTION_TYPES.KYC_COUNTRIES_FAILED,
    payload,
  };
}

export function identificationTypeRequest(payload) {
  return {
    type: ACTION_TYPES.KYC_IDENTIFICATION_TYPES_REQUEST,
    payload,
  };
}

export function identificationTypeSuccess(payload) {
  return {
    type: ACTION_TYPES.KYC_IDENTIFICATION_TYPES_SUCCEEDED,
    payload,
  };
}

export function identificationTypeFailure(payload) {
  return {
    type: ACTION_TYPES.KYC_IDENTIFICATION_TYPES_FAILED,
    payload,
  };
}

export function sourcesOfWealthRequest(payload) {
  return {
    type: ACTION_TYPES.KYC_SOURCES_OF_WEALTH_REQUEST,
    payload,
  };
}

export function sourcesOfWealthSuccess(payload) {
  return {
    type: ACTION_TYPES.KYC_SOURCES_OF_WEALTH_SUCCEEDED,
    payload,
  };
}

export function sourcesOfWealthFailure(payload) {
  return {
    type: ACTION_TYPES.KYC_SOURCES_OF_WEALTH_FAILED,
    payload,
  };
}

export function purchaseBanksRequest(payload) {
  return {
    type: ACTION_TYPES.PURCHASE_BANKS_REQUEST,
    payload
  }
}

export function purchaseBanksSuccess(payload) {
  return {
    type: ACTION_TYPES.PURCHASE_BANKS_SUCCESS,
    payload
  }
}

export function purchaseBanksFailure(payload) {
  return {
    type: ACTION_TYPES.PURCHASE_BANKS_FAILED,
    payload
  }
}

export function updateBankAccountRequest(payload) {
  return {
    type: ACTION_TYPES.UPDATE_BANK_ACCOUNT_REQUEST,
    payload
  }
}

export function updateBankAccountSuccess(payload) {
  return {
    type: ACTION_TYPES.UPDATE_BANK_ACCOUNT_SUCCESS,
    payload
  }
}

export function updateBankAccountFailure(payload) {
  return {
    type: ACTION_TYPES.UPDATE_BANK_ACCOUNT_FAILED,
    payload
  }
}

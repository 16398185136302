import React, { Component } from 'react';
import styles from './_error-fallback.module.scss';

class ErrorFallback extends Component {
  render() {
    return (
      <div className={styles['error-fallback']}>
        <img className={styles["logo"]} src="/img/s-logo.svg" alt="Savvy" />
        <img className={styles["oops"]} src="/img/oops.png" alt="Oops!!!" />
        <h3>Uh-oh! Something went wrong</h3>
        <p>
          Sit tight, we are working to resolve the issue asap. Please try to refresh the page asap or try again later.
          If its urgent please reach out to us on <a href="mailto:hello@savvyapp.in">hello@savvyapp.in</a></p>
      </div>
    );
  }
}

export default ErrorFallback;

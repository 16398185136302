import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { ACTION_TYPES } from 'src/constants';
import { Convert } from 'src/helpers';
import EventBus from 'eventing-bus';
import cx from 'classnames';
import './_modal.scss';

const STEPS = {
  REDEEM_OTP_SCREEN: 'REDEEM_OTP_SCREEN',
  REDEEM_SUCCESS_SCREEN: 'REDEEM_SUCCESS_SCREEN',
};

class CollectMoney extends Component {
  state = {
    activeStep: '',
    otp: ['', '', '', '', ''],
    isSubmitOtp: false,
  };

  componentDidMount() {
    this.setState({ activeStep: STEPS.REDEEM_OTP_SCREEN });

    this.requestConfirmCollectionList = EventBus.on(ACTION_TYPES.REQUEST_COLLECTION_CONFIRM, () =>
      this.setState({ isLoading: true }),
    );
    this.requestConfirmCollectionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_CONFIRM_SUCCESS,
      this.onCollectionConfirmSuccess,
    );
    this.requestConfirmCollectionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_CONFIRM_FAILED,
      this.onCollectionConfirmFailed,
    );
  }

  componentWillUnmount() {
    this.requestConfirmCollectionList();
    this.requestConfirmCollectionListSuccess();
    this.requestConfirmCollectionListFailed();
  }

  handleStep = ({ stepName }) => {
    this.setState({ activeStep: stepName });
  };

  onCollectionConfirmSuccess = () => {
    this.handleStep({ stepName: STEPS.REDEEM_SUCCESS_SCREEN });
    this.setState({ isLoading: false });
  };

  onCollectionConfirmFailed = () => {
    this.setState({ isLoading: false });
  };

  handleOtpScreen = () => {
    const { match, requestAdminCollectionsConfirm, collectionAccept } = this.props;
    const { otp } = this.state;
    const { otpId, transactionId } = collectionAccept || {};
    this.setState({ isSubmitOtp: true }, () => {
      const { e_otp } = this.handleValidation();
      if (e_otp) return;
      requestAdminCollectionsConfirm({
        id: match?.params?.uuid,
        data: {
          otp_id: otpId || '',
          transaction_id: transactionId || '',
          otp: otp.toString().replaceAll(',', ''),
        },
      });
    });
  };

  handleValidation = () => {
    const { isSubmitOtp, otp } = this.state;
    const errorStructure = {
      e_otp: '',
    };
    if (!isSubmitOtp) {
      return errorStructure;
    }
    if (otp.includes('')) {
      errorStructure.e_otp = 'Required Otp';
    } else {
      errorStructure.e_otp = '';
    }
    return errorStructure;
  };

  handleInputs = ({ index, value }) => {
    const { otp } = this.state;
    const cloneOtp = [...otp];
    if (index < otp.length && value) {
      const inputId = document.getElementById(`input-${index + 1}`);
      if (inputId) {
        inputId.focus();
      }
    }
    cloneOtp[index] = value;
    this.setState({ otp: cloneOtp });
  };

  handleKeyDown = ({ e, index }) => {
    setTimeout(() => {
      if (e.key === 'Backspace') {
        const inputId = document.getElementById(`input-${index - 1}`);
        if (inputId) {
          inputId.focus();
        }
      }
    }, 200);
  };

  _onDone = () => {
    this.props.closeModal && this.props.closeModal(true); // success
  }

  render() {
    const { isModalOpen, closeModal, collectionDetail, collectionAccept } = this.props;
    const { activeStep, otp, isLoading } = this.state;
    const { e_otp } = this.handleValidation();
    const { transactionId } = collectionAccept || {};

    return (
      <div>
        <Modal show={isModalOpen} size="lg">
          <div className="redeem-modal">
            <div className="cross-icon">
              <span onClick={closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>

            {activeStep === STEPS.REDEEM_OTP_SCREEN && (
              <div className="redeem-otp-screen">
                <div className="title">Redeem Savings for &#34;{collectionDetail?.savingGoal?.productOffer?.productOfferName}&#34;</div>
                <div className="heading">
                  Redeem {Convert.toCurrencyValue(collectionDetail?.requestedAmount)}
                </div>
                <div className="input-group-box">
                  {otp &&
                    otp.length > 0 &&
                    otp.map((data, index) => (
                      <input
                        type="text"
                        id={`input-${index}`}
                        key={index}
                        value={data}
                        className={cx('input-box', {
                          'error-input-box': !data && e_otp,
                        })}
                        maxLength="1"
                        onChange={(e) => this.handleInputs({ index, value: e.target.value })}
                        onKeyDown={(e) => this.handleKeyDown({ e, index })}
                      />
                    ))}
                </div>
                {e_otp && <div className="error">{e_otp}</div>}
                <div className="common-btn">
                  <button
                    disabled={isLoading}
                    onClick={this.handleOtpScreen}
                    className={cx('custom-btn', {
                      'disabled-btn': isLoading,
                    })}
                  >
                    {isLoading ? 'Loading...' : 'ENTER OTP TO REDEEM SAVINGS'}
                  </button>
                </div>
              </div>
            )}
            {activeStep === STEPS.REDEEM_SUCCESS_SCREEN && (
              <div className="redeem-success-screen">
                <div className="upper-section">
                  <div className="okay-icon">
                    <img src="/img/okay.svg" alt="okay-icon" />
                  </div>
                  <div>
                    <div className="sub-title">
                      You have redeemed &#34;{Convert.toCurrencyValue(collectionDetail?.requestedAmount)}&#34;{' '}
                      from {collectionDetail?.savingGoal?.productOffer?.productOfferName}
                    </div>
                    <div className="heading">Transaction ID: {transactionId}</div>
                  </div>
                </div>
                <div className="money-transferred">
                  <div className="heading">What happens next?</div>
                  <div className="line" />

                  <div className="transfer">
                    <div className="content-box">
                      <div className="number">1</div>
                      <div className="sub-heading">
                        {Convert.toCurrencyValue(collectionDetail?.requestedAmount)} will be
                        credited to your account
                      </div>
                    </div>
                    <div className="icon">
                      <img src="/img/arrow-right-2.svg" alt="arrow-i" />
                    </div>
                    <div className="content-box">
                      <div className="number">2</div>
                      <div className="sub-heading">
                        {Convert.toCurrencyValue(collectionDetail?.requestedAmount)} will
                        transferred to  &#34;{collectionDetail?.savingGoal?.productOffer?.partner?.name} &#34; within 2-3 business days.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-btn">
                  <button className="custom-btn" onClick={this._onDone}>
                    DONE
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CollectMoney);

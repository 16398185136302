import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function SavingPlanReducer(state = INITIAL_STATE.savingPlan, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_SAVING_PLAN: {
      return {        
        ...action.payload
      };
    }

    case ACTION_TYPES.CREATE_SAVING_GOAL_SUCCESS: {
      return {
        ...action.payload.savingGoal,
        amount: state.amount,
        paymentCalendar: state.paymentCalendar,
        totalPax: state.totalPax,
        paymentId: state.paymentId,
        paymentRequestId: state.paymentRequestId
      };
    }

    case ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS: {
      return {
        ...action.payload.savingGoal,
        paymentCalendar: action.payload.savingGoal?.productOffer?.paymentCalendar,
        totalPax: action.payload.savingGoal?.multiplier || 1
      };
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.savingPlan;
    }

    default:
      return state;
  }
}

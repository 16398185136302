import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import styles from './_listitem.module.scss';

function ListItem({ item }) {
  const { pathname } = useLocation();
  const [childToggleKey, setChildToggleKey] = useState(pathname);

  const handleChildToggle = (e) => {
    e.preventDefault();
    const updateChildToggleKey = childToggleKey.includes(item.toggleKey) ? '' : item.toggleKey;
    setChildToggleKey(updateChildToggleKey);
    return false;
  };

  let children = null;
  if (item.values && item.values.length) {
    children = (
      <Collapse className={styles.childMenu} in={!!childToggleKey.includes(item.toggleKey)}>
        <ul>
          {item.values.map((i) => (
            <ListItem item={i} key={i.id} />
          ))}
        </ul>
      </Collapse>
    );
  }

  return (
    <li>
      {item?.nav ? (
        <Link
          to={item.nav}
          className={`${styles.linkItem} ${pathname == item.nav && styles.active}`}
        >
          {item.name}
        </Link>
      ) : (
        <a
          href=""
          className={`${styles.listItemContainer} ${
            pathname.includes(item.toggleKey) && styles.active
          }`}
          onClick={(e) => handleChildToggle(e)}
        >
          <span className={styles.listItem}>{item.name}</span>
          {childToggleKey.includes(item.toggleKey) ? (
            <FontAwesomeIcon icon={faChevronUp} className={styles.icon} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} className={styles.icon} />
          )}
        </a>
      )}
      {children}
    </li>
  );
}

export default ListItem;

import { connect } from 'react-redux'
import { extractAddressProofRequest, uploadAddressProofRequest, updateKycParams } from 'src/redux/actions';
import AddressVerification from './AddressVerification'

function mapStateToProps(){
  return {

  }
}

export default connect(mapStateToProps,{
  extractAddressProofRequest, 
  uploadAddressProofRequest,
  updateKycParams
})(AddressVerification)


import React, { Component } from 'react';
import { Selfie } from 'src/components';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import styles from './selfie-verification.module.scss';

class SelfieVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      imageUri: null,
    };

    this._stream = null;
    this._video = null;
    this._canvas = null;
  }

  componentDidMount() {
    this.uploadPhotoRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SELFIE_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.uploadPhotoRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SELFIE_SUCCESS,
      this._onUploadedPhoto,
    );
    this.uploadPhotoRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SELFIE_FAILED,
      () => this.setState({ loading: false }),
    );

    this.setState({ imageUri: this.props?.kyc?.selfie?.image_url });
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.uploadPhotoRequestSuccessSubscription();
    this.uploadPhotoRequestFailureSubscription();
  };

  _onSelfieChange = (imageUri) => {
    this.setState({ imageUri }, () => {
      this.props.updateKycParams({ selfie: { image_url: imageUri } });
    });
  };

  _onSelfieVerificationSubmit = () => {
    this.props.uploadSelfieRequest({ image_url: this.state.imageUri });
  };

  _onUploadedPhoto = () => {
    this.setState({ loading: false }, () => {
      this.props.navigateToReview();
    });
  };

  render() {
    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['another-account']}>
          <div className={styles['pay-content']}>
            <div className={styles['title']}>Verify your KYC Start Saving</div>
            <div className={styles['line']} />
            <div className={styles['sub-title']}>SELFIE VERIFICATION</div>
            <div className={cx(styles['mobile-space'], styles['mobile'])}>
              <div className={cx(styles['line-mob'])} />
            </div>
            <div className={cx('pb-3 pt-4', styles['form-box'], styles['mobile-space'])}>
              <Selfie
                onSelfieChange={this._onSelfieChange}
                imageSrc={this.props?.kyc?.selfie?.image_url}
              />
            </div>
          </div>
          <button
            className={styles['footer']}
            type="submit"
            disabled={!this.state.imageUri || this.state.loading}
            onClick={this._onSelfieVerificationSubmit}
          >
            {this.state.loading ? 'LOADING...' : 'NEXT'}
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(SelfieVerification);

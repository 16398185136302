import { connect } from 'react-redux';
import { requestPaymentLinksDetail, requestPaymentExpireLink } from 'src/redux/actions';
import UserPaymentDetail from './user-payment-detail';

const mapStateToProps = (state) => ({
  adminPaymentDetail: state.adminPayments.adminPaymentDetail,
});

export default connect(mapStateToProps, { requestPaymentLinksDetail, requestPaymentExpireLink })(
  UserPaymentDetail,
);

import React from 'react'
import cx from 'classnames'
import Text from '../text-main'
import styles from './_logo.module.scss'

export default function Logo(props) {
  return (
    <>
      <Text className={cx(styles.logoHeading, props.logoHeadingStyles)} style={{color: props?.logoHeadingColor}}>SavvyPay</Text>
      <Text className={cx(styles.logoLeading, props.logoLeadingStyles)} style={{color: props?.logoLeadingColor}}>Book. Save. Buy.</Text>
    </>
  )
}

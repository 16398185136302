import { connect } from 'react-redux';
import { createAdminProduct } from 'src/redux/actions';
import NewProduct from './NewProduct';

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}

export default connect(mapStateToProps, { createAdminProduct })(NewProduct);

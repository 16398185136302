import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Convert } from 'src/helpers';
import { Loader, SavvySupport } from 'src/components';
import styles from './pay-error.module.scss';
import OfferBox from '../pay/offer-box';
// import ExtraInfo from '../extra-info';

class PayError extends Component {
  state = {
    loading: false,
    // isShowSavvyPayModal: true,
    // payment: {},
  };

  redirectToPayPage = () => {
    const { history } = this.props;
    history.push('pay');
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user, savingPlan } = this.props;
    const { paymentCalendar = {}, totalPax } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const firstPaymentAmount = totalPax
      ? paymentCalendar[firstPaymentKey] * totalPax
      : paymentCalendar[firstPaymentKey];

    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>Payment Failed!</div>
              <div className={styles['tick']}>
                <img src="/img/block.svg" alt="block" />
              </div>
              <div className={styles['message']}>
                Your payment of {Convert.currency(firstPaymentAmount)} failed for some reason.
                <br />
                <span>
                  If the amount is deducted from your bank account, please get in touch with us at
                  hello@savvyapp.in
                </span>
              </div>
            </div>
            <div className={styles['footer']} onClick={this.redirectToPayPage}>
              Retry Payment
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox
              partner={partner}
              product={product}
              user={user}
              onProceedPayment={this._onProceedPayment}
            />
          </div>
        </div>
        {/* <ExtraInfo /> */}
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(PayError);

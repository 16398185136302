import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { withRouter } from 'react-router-dom';
import { ACTION_TYPES } from 'src/constants';
import StatsContainer from 'src/components/stats-container';
import { Loader } from 'src/components';
import { ProductOffersTable } from 'src/components/tables';

class ProductOffers extends Component {
  state = {
    loading: false,
    pageNumber: 1
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState({
      pageNumber
    }, () => this.props.requestAdminOffersList({
      page: pageNumber
    }))
  }

  componentDidMount() {
    this.requestAdminOffersList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminOffersListSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminOffersListFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminOffersList({
      page: this.state.pageNumber
    });
  }

  componentWillUnmount() {
    this.requestAdminOffersList();
    this.adminOffersListSuccess();
    this.adminOffersListFailure();
  }

  render() {
    const { productOffers, productOffersTotal } = this.props.productOffers;
    return (
      <>
        <StatsContainer
          title="Products Offer Summary"
          data={[
            {
              title: 'Total Number of offers',
              field: 'totalProducts',
              value: productOffersTotal && productOffersTotal,
            },
          ]}
          button={{ name: 'create a new offer', link: '/admin/products/offers/new' }}
        />
        {productOffers && productOffers.length > 0 && 
        <ProductOffersTable 
          data={productOffers} 
          totalItems={productOffersTotal}
          handlePaginationChange={this._handlePaginationChange}
        />}
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withRouter(ProductOffers);

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminAgentsReducer(state = INITIAL_STATE.adminAgents, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_AGENT_CREATE_SUCCESS:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
}

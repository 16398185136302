import { connect } from 'react-redux';
import AuthRoute from './auth-route';

function mapStateToProps({ credentials, user }) {
  const { partnerAccessKey, userIdentityToken } = credentials;
  const { uuid, isPhoneVerified } = user;

  return {
    partnerAccessKey,
    userIdentityToken,
    uuid,
    isPhoneVerified,
  };
}

export default connect(mapStateToProps, {})(AuthRoute);

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminPaymentLinks(data) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_PAYMENT_LINKS}?page=${data.page}`,
  }).then((response) => response.data);
}

export function adminPaymentLinksDetail({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_PAYMENT_LINKS}/${uuid}`,
  }).then((response) => response.data);
}

export function adminPaymentExpireLink({ payment_link_id }) {
  return ApiRequest.fetchAdmin({
    method: 'put',
    url: `${API_END_POINTS.ADMIN_PAYMENT_LINKS}/${payment_link_id}/cancel`,
  }).then((response) => response.data);
}

import React, { Component } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { TextInputOne } from 'src/components/form-inputs';
import { Loader } from 'src/components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPen, faEye } from '@fortawesome/free-solid-svg-icons'
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';
import { Container, Section, ButtonOne } from '../../components';
import styles from './_agent.module.scss';

export class Agent extends Component {
  state = {
    loading: false,
  };

  _validationSchema = () =>
    yup.object().shape({
      // first_name: yup.string()
      //   .required('Please enter First Name'),
      // last_name: yup.string()
      //   .required('Please enter Last Name'),
      agentEmail: yup
        .string()
        .email('Please enter a valid email address')
        .required('Please enter email address.'),
      agentPassword: yup
        .string()
        .min(8, 'Password shall be atleast 8 characters long.')
        .max(20, 'Password shall be max 20 characters long.')
        .required('Please enter password'),
    });

  componentDidMount() {
    this.requestAdminAgentCreate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_AGENT_CREATE, () =>
      this.setState({ loading: true }),
    );
    this.adminAgentCreateSuccess = EventBus.on(ACTION_TYPES.ADMIN_AGENT_CREATE_SUCCESS, () =>
      this._handleCreateSuccess(),
    );
    this.adminAgentCreateFailed = EventBus.on(ACTION_TYPES.ADMIN_AGENT_CREATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    // non partner admin/super admin shalln't be able to create a sales agent
    if (!this.props.admin.isAdmin) {
      return this.props.history.push('/admin');
    }
  }

  componentWillUnmount() {
    this.requestAdminAgentCreate();
    this.adminAgentCreateSuccess();
    this.adminAgentCreateFailed();
  }

  _handleCreateSuccess = () => {
    this.setState(
      {
        loading: false,
      },
      () => notify.show('Agent Created successfully', 'success'),
    );
    setTimeout(() => {
      this.formik.resetForm();
    }, 2000);
  };

  render() {
    return (
      <div className={styles['agent']}>
        <Container title="Sales Agent Details">
          <Formik
            initialValues={{
              // first_name: '',
              // last_name: '',
              agentEmail: '',
              agentPassword: '',
            }}
            innerRef={(ref) => (this.formik = ref)}
            validationSchema={this._validationSchema}
            onSubmit={(values) => {
              const params = {
                email: values?.agentEmail,
                password: values?.agentPassword,
              };
              this.props.requestAdminAgentCreate({ partner_admin_user: { ...params } });
            }}
          >
            <Form autoComplete="off">
              <div className={styles['hidden-inputs']}>
                <TextInputOne name="email" id="email" type="email" />
                <TextInputOne name="password" id="password" type="password" />
              </div>
              <Section title="">
                <Row>
                  <Col sm={4}>
                    <div className={styles['email-input']}>
                      <TextInputOne
                        label="Email Id"
                        name="agentEmail"
                        id="agentEmail"
                        placeholder="Enter agent email address"
                      />
                    </div>

                    <TextInputOne
                      label="Password"
                      name="agentPassword"
                      id="agentPassword"
                      placeholder="Enter agent password"
                      type="password"
                    />
                    <br />
                    <div className={styles['buttons-group']}>
                      <ButtonOne text="Save Details" type="submit" />
                    </div>
                  </Col>
                </Row>
              </Section>
            </Form>
          </Formik>
        </Container>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default withRouter(Agent);

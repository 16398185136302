/* eslint-disable func-names */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik, FieldArray } from 'formik';
import { Loader } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import cx from 'classnames';
import * as yup from 'yup';

import {
  // InputSelectOne,
  InputSwitch,
  TextInputOne,
  TextInputTwo,
  // TextInputFour,
} from 'src/components/form-inputs';
import { notify } from 'react-notify-toast';
import { Convert } from 'src/helpers';
import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
// import { Editor } from 'react-draft-wysiwyg';
// import DatePicker from 'react-datepicker';
import _ from 'lodash';
import EventBus from 'eventing-bus';
import moment from 'moment';
import { ButtonMain, Heading, AsyncSelectInput } from 'src/components/theme';
import CreateAnotherOfferModal from './create-another-offer-modal';
import { TextInputOne as NewTextInputOne } from '../../../screens/v2/admin/components/form-inputs';

import OfferUpdateConfirmationModal from './offer-update-confirmation-modal';
import styles from './_offerdetailsedit.module.scss';

class OfferDetailsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      remainingAmount: 0,
      discountPercentage: 0,
      isDiscountPercentageToggled: false,
      description: EditorState.createEmpty(),
      scheduleError: '',
      // expiry_date: '',
      showCreateAnotherModal: false,
      showUpdateConfirmationModal: false,
      selectedProduct: '',
      gst: 0,
      tcs: 0,
      finalProductPrice: 0,
    };

    this.formik = null;
  }

  componentDidMount() {
    // const propsData = this.props;
    const getUrl = new URL(document.location.href);
    const selectedProductUuid = getUrl.searchParams.get('selectedProduct');
    const productUuid =
      this.props.history?.location?.state?.productUuid || selectedProductUuid || null;
    // if (propsData?.productOffer?.expiryDate) {
    //   this._handleExpiryDate(new Date(propsData?.productOffer.expiryDate));
    // }

    this.requestAdminOfferUpdate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_UPDATE, () => {
      this.setState({ loading: true });
    });

    this.adminOfferUpdateSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFER_UPDATE_SUCCESS, () => {
      this.setState({ loading: false });
      notify.show('Product offer updated successfully', 'success');
    });

    this.adminOfferUpdateFailed = EventBus.on(ACTION_TYPES.ADMIN_OFFER_UPDATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminOfferCreate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_CREATE, () =>
      this.setState({ loading: true }),
    );

    this.adminOfferCreateSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFER_CREATE_SUCCESS, () => {
      this.setState({
        showCreateAnotherModal: true,
        loading: false,
      });
    });

    this.adminOfferCreateFailed = EventBus.on(ACTION_TYPES.ADMIN_OFFER_CREATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminProductList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, () =>
      this.setState({ loading: true }),
    );

    this.adminProductListSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS, () => {
      this.setState({ loading: false }, () => {
        if (productUuid) {
          const product =
            this.props.products &&
            this.props.products.find((product) => product.uuid === productUuid);
          this.formik.setFieldValue('product_uuid', product ? product?.uuid : '');

          this.setState(
            {
              selectedProduct: product || '',
            },
            this.handleGstTcsAndDiscount,
          );
        }
      });
    });

    this.adminProductListFailed = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (this.props.isNew) {
      this.props.requestAdminProductList({ page: 1 });
    }

    const { productOffer } = this.props || {};
    if (this.props && this.props.productOffer && this.props.productOffer.product) {
      this.setState(
        {
          selectedProduct: this.props.productOffer.product,
          discountPercentage: productOffer?.discountPercentage,
        },
        () => this.handleGstTcsAndDiscount(true),
      );
    }
    const description =
      (productOffer?.description &&
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(stateToHTML(convertFromRaw(JSON.parse(productOffer?.description)))),
          ),
        )) ||
      '';
    this.setState({
      description,
    });
  }

  componentWillUnmount() {
    this.requestAdminOfferUpdate();
    this.adminOfferUpdateSuccess();
    this.adminOfferUpdateFailed();

    this.requestAdminOfferCreate();
    this.adminOfferCreateSuccess();
    this.adminOfferCreateFailed();
  }

  prepareBody = (body) => (body && body !== 'null' ? Convert.toRawHtml(body) : '');

  _handleDescription = (description) => {
    this.setState({ description });
  };

  _onChangeDiscount = (e) => {
    let discount_percentage = e.target.value;
    if (discount_percentage) {
      discount_percentage = Math.round((Number(discount_percentage) + Number.EPSILON) * 100) / 100;
    }
    this.setState({ discountPercentage: Number(discount_percentage), scheduleError: '' }, () => {
      this.handleGstTcsAndDiscount();
      if (this.formik.values?.payment_events && this.formik.values?.payment_events.length > 0) {
        this.formik.values?.payment_events.forEach((data, index) => {
          if (index > 0) {
            this.formik.setFieldValue(`payment_events[${index}].amount_required`, 0);
            this.formik.setFieldValue(`payment_events[${index}].date`, '');
          }
        });
      }
    });
  };

  _handlePaymentDependenciesChange = () => {
    this.handleGstTcsAndDiscount();
  };

  _firstPaymentChange = () => {
    const { finalProductPrice } = this.state;
    let { upfront_payment_amount } = this.formik.values;
    if (upfront_payment_amount) {
      upfront_payment_amount =
        Math.round((Number(upfront_payment_amount) + Number.EPSILON) * 100) / 100;
    }
    let remainingAmount = finalProductPrice - Number(upfront_payment_amount);
    if (remainingAmount) {
      remainingAmount = Math.round((Number(remainingAmount) + Number.EPSILON) * 100) / 100;
    }

    let date = 0;
    if (this.formik?.values?.payment_events && this.formik?.values?.payment_events.length > 0) {
      date = this.formik?.values?.payment_events[0].date;
    }
    this.formik.setFieldValue('payment_events', []);
    this.formik.setFieldValue('payment_events[0].amount_required', remainingAmount);
    this.formik.setFieldValue('payment_events[0].date', date);
    this.setState({ remainingAmount });
  };

  handleFirstPaymentChange = (e) => {
    let value = e.target.value;
    if (value) {
      value = Math.round((Number(value) + Number.EPSILON) * 100) / 100;
    }
    this.formik.setFieldValue(`upfront_payment_amount`, value);
  };

  handleAmount = (e, index, paymentEvents) => {
    let value = parseInt(e.target.value);
    if (value) {
      value = Math.round((Number(value) + Number.EPSILON) * 100) / 100;
    }
    this.formik.setFieldValue(`payment_events[${index}].amount_required`, value);
    this.formik.setFieldValue(`payment_events[${index}].date`, paymentEvents?.date || 0);
  };

  _paymentScheduleChange = () => {
    const { remainingAmount } = this.state;
    const { payment_events } = this.formik.values;
    const totalScheduleAmount = _.sumBy(payment_events, (payment) =>
      Number(payment.amount_required),
    );
    // const duplicateDays = _.groupBy(payment_events, (payment) => payment.date);
    // console.log('duplicateDays', duplicateDays);

    if (Number(totalScheduleAmount) !== Number(remainingAmount)) {
      return this.setState({
        scheduleError: `Total schedule amount should be ${Convert.currency(
          remainingAmount || 0,
        )}/-, plan for remaining ${Convert.currency(
          (remainingAmount || 0) - (totalScheduleAmount || 0),
        )}/-`,
      });
    }

    this.setState({ scheduleError: '' });
  };

  // _handleExpiryDate = (expiry_date) => {
  //   this.setState({ expiry_date });
  // };

  _handleSubmit = (values) => {
    if (this.props.isNew) {
      this.props.requestAdminOfferCreate({
        product_offer: {
          ...values,
        },
      });
    } else {
      this.props.requestAdminOfferUpdate({
        uuid: values?.uuid,
        product_offer: {
          ...values,
        },
      });
    }
  };

  closeCreateAnotherModal = () => {
    this.setState({ showCreateAnotherModal: false }, () => {
      this.props.history.push('/admin/products/offers');
    });
  };

  handleCreateAnotherOffer = () => {
    this.closeCreateAnotherModal();
  };

  closeUpdateConfirmationModal = () => {
    this.setState({ showUpdateConfirmationModal: false });
  };

  handleUpdateConfirmationModal = (callback) => {
    this.setState({ showUpdateConfirmationModal: false }, callback);
  };

  loadOptions = (inputValue, callback) => {
    this.props.requestAdminProductListByName({ name: inputValue, callback });
  };

  handleGstTcsAndDiscount = (isOnLoad) => {
    let { selectedProduct, discountPercentage } = this.state;
    const selectedProductCopy = { ...selectedProduct };
    if (this.props?.productOffer?.canEdit) {
      selectedProductCopy.amount = this.props?.productOffer?.product?.amount || 0;
    }

    let gstTcsStructure = {
      gst: 0,
      tcs: 0,
      finalProductPrice: Number(selectedProductCopy?.amount) || 0,
    };
    let finalProductPrice = Number(selectedProductCopy?.amount) || 0;
    let productAmount = Number(selectedProductCopy?.amount) || 0;
    const isDiscountActive =
      this.formik && this.formik?.values && this.formik?.values?.is_discount_percentage;
    if (isDiscountActive && discountPercentage && discountPercentage > 0) {
      productAmount -= (Number(discountPercentage) / 100) * productAmount;
      finalProductPrice = productAmount;
    }

    if (
      selectedProductCopy &&
      selectedProductCopy?.taxes &&
      selectedProductCopy?.taxes.length > 0
    ) {
      const getGst =
        selectedProductCopy?.taxes &&
        selectedProductCopy?.taxes.length > 0 &&
        selectedProductCopy?.taxes.find(({ taxFieldName }) => taxFieldName === 'gst');
      const getTcs =
        selectedProductCopy?.taxes &&
        selectedProductCopy?.taxes.length > 0 &&
        selectedProductCopy?.taxes.find(({ taxFieldName }) => taxFieldName === 'tcs');
      if (getGst?.percentage && getGst?.percentage > 0) {
        const gstAmount = (Number(getGst?.percentage) / 100) * selectedProductCopy.amount;
        finalProductPrice += gstAmount;
        gstTcsStructure.gst = gstAmount;
      }
      if (getTcs?.percentage && getTcs?.percentage > 0) {
        const cstAmount = (Number(getTcs?.percentage) / 100) * selectedProductCopy.amount;
        finalProductPrice += cstAmount;
        gstTcsStructure.tcs = cstAmount;
      }
    }
    gstTcsStructure.finalProductPrice = finalProductPrice;

    let firstInstallment = gstTcsStructure?.finalProductPrice;
    firstInstallment = (25 / 100) * (gstTcsStructure?.finalProductPrice || 0);
    let remainingAmount = gstTcsStructure?.finalProductPrice - firstInstallment;
    if (remainingAmount % 1 != 0) {
      const [beforeDecimal, afterDecimal] = remainingAmount.toString().split('.');
      remainingAmount = Number(beforeDecimal);
      firstInstallment += Number(`0.${afterDecimal}`);
    }

    if (discountPercentage) {
      discountPercentage = Math.round((Number(discountPercentage) + Number.EPSILON) * 100) / 100;
    }
    if (firstInstallment) {
      firstInstallment = Math.round((Number(firstInstallment) + Number.EPSILON) * 100) / 100;
    }
    if (remainingAmount) {
      remainingAmount = Math.round((Number(remainingAmount) + Number.EPSILON) * 100) / 100;
    }
    this.formik.setFieldValue('discount_percentage', discountPercentage);
    this.formik.setFieldValue('upfront_payment_amount', firstInstallment.toFixed(1));

    if (!isOnLoad) {
      this.formik.setFieldValue(
        'payment_events[0].amount_required',
        parseInt(remainingAmount || 0),
      );
    }

    this.setState({ remainingAmount, ...gstTcsStructure });
  };

  resetPaymentScheduleDates = () => {
    if (this.formik.values?.payment_events && this.formik.values?.payment_events.length > 0) {
      this.formik.values?.payment_events.forEach((data, index) => {
        this.formik.setFieldValue(
          `payment_events[${index}].amount_required`,
          data?.amount_required || 0,
        );
        this.formik.setFieldValue(`payment_events[${index}].date`, '');
      });
    }
  };

  addWeekdays = (date, days) => {
    date = moment(date); // use a clone
    while (days > 0) {
      date = date.add(1, 'days');
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }
    return date;
  }

  subtractWeekdays = (date, days) => {
    date = moment(date); // use a clone
    while (days > 0) {
      date = date.subtract(1, 'days');
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }
    return date;
  }

  render() {
    const { productOffer = {}, handleEditState } = this.props;
    const {
      remainingAmount,
      // description,
      finalProductPrice,
      scheduleError,
      // expiry_date,
      showCreateAnotherModal,
      showUpdateConfirmationModal,
      selectedProduct,
      gst,
      tcs,
      isDiscountPercentageToggled,
    } = this.state;

    let paymentEventsStructure = productOffer?.paymentSchedule?.paymentEvents.map((data) => ({
      amount_required: data?.amountRequired || '',
      date: moment(data?.date, 'DD/MM/YYYY').format('YYYY-MM-DD') || '',
    }));

    if (!paymentEventsStructure) {
      paymentEventsStructure = [{ amount_required: 0, date: '' }];
    }

    let productsData = [];
    if (this.props?.products && this.props?.products.length > 0) {
      productsData = this.props?.products.map((data) => ({
        ...data,
        value: data?.productName,
        label: data?.productName,
      }));
    }

    yup.addMethod(yup.array, 'unique', function (message) {
      return this.test('unique', message, function (list) {
        const mapper = (x) => x?.date?.toString();
        const set = [...new Set(list.map(mapper))];

        const isUnique = list.length === set.length;
        if (isUnique) {
          return true;
        }

        const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
        return this.createError({
          path: `payment_events[${idx}].date`,
          message,
        });
      });
    });

    return (
      <>
        <Formik
          innerRef={(ref) => (this.formik = ref)}
          initialValues={{
            product_uuid: productOffer?.product?.uuid || '',
            product_offer_name: productOffer?.productOfferName || '',
            // is_expiry_date: Boolean(productOffer?.expiryDate),
            // expiry_date: productOffer?.expiryDate,
            is_discount_percentage: productOffer?.isDiscountPercentage || false,
            discount_percentage: productOffer?.discountPercentage || '',
            final_date: productOffer?.finalDate || '',
            book_save_buy: productOffer?.paymentMethodsAvailable?.indexOf('book_save_buy') > -1,
            pay_in_instalments:
              productOffer?.paymentMethodsAvailable?.indexOf('pay_in_instalments') > -1,
            upfront_payment_amount: productOffer?.upfrontPaymentAmount || 0,
            payment_events: paymentEventsStructure,
            hidden: productOffer?.hidden || false,
          }}
          validationSchema={yup.object().shape({
            product_offer_name: yup.string().required('Please enter the offer name.'),
            product_uuid: yup.string().required('Please select product.'),
            is_discount_percentage: yup.bool().required(),
            discount_percentage: this.formik?.values?.is_discount_percentage
              ? yup.number().when('is_discount_percentage', {
                is: true,
                then: yup.number().required('Please enter discount percentage').min(1).max(100),
              })
              : null,
            upfront_payment_amount: yup.number().required('Please enter booking amount').min(1),
            final_date: yup.date().required('Please select date'),
            payment_events: yup
              .array()
              .of(
                yup.object().shape({
                  amount_required: yup.number('Must be number').min(0),
                  date: yup.date().required('Please select date'),
                  // .test(
                  //   'Month Validation',
                  //   'Final date should be 7 days lesser than the purchase date',
                  //   (value) => {
                  //     const finalDate = moment(this.formik?.values?.final_date, ['YYYY-MM-DD'])
                  //       .subtract(7, 'd')
                  //       .toDate();

                  //     const isGreater = moment(value).diff(moment(finalDate)) < 0;
                  //     return isGreater;
                  //   },
                  // )
                  // .test(
                  //   'Month Validation',
                  //   "First instalment date should be 8 days min from today's date",
                  //   (value) => {
                  //     console.log('value', value);
                  //     const isGreater = moment(value).diff(moment(), 'days') > 7;
                  //     return isGreater;
                  //   },
                  // ),
                }),
              )
              .unique('Duplicate dates should be avoided'),
            book_save_buy: yup.bool().when('pay_in_instalments', {
              is: false,
              then: yup.bool().oneOf([true], 'Please choose atleast one payment method'),
            }),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const { description, selectedProduct, scheduleError } = this.state;
            if (scheduleError) return;
            // let paymentEvents = values?.payment_events;
            let paymentEvents = [];
            if (values?.payment_events && values.payment_events.length > 0) {
              values?.payment_events.forEach((data) => {
                const paymentStructure = {
                  amount_required: data?.amount_required || 0,
                  date: moment(data?.date, 'YYYY-MM-DD').format('DD/MM/YYYY') || 0,
                };
                paymentEvents.push(paymentStructure);
              });
            }

            const remainingValues = { ...values };
            const payment_methods_available = [];
            delete remainingValues.payment_events;
            if (this.props.isNew) {
              delete remainingValues.hidden;
            }

            if (values?.book_save_buy) payment_methods_available.push('book_save_buy');
            if (values?.pay_in_instalments) payment_methods_available.push('pay_in_instalments');

            const taxes = [];
            if (selectedProduct?.taxes && selectedProduct?.taxes.length > 0) {
              selectedProduct?.taxes.forEach((data) => {
                const taxStructure = {
                  tax_field_name: data?.taxFieldName,
                  percentage: data?.percentage,
                };
                taxes.push(taxStructure);
              });
            }
            this._handleSubmit({
              ...remainingValues,
              uuid: productOffer.uuid || '',
              amount: Number(selectedProduct?.amount),
              is_fixed_months: true,
              discount_percentage: values?.is_discount_percentage
                ? values?.discount_percentage
                : '',
              is_upfront_payment_required: true,
              upfront_payment_amount: values?.upfront_payment_amount,
              payment_schedule: {
                final_date: values?.final_date || 1,
                payment_events: paymentEvents,
              },
              description:
                (description &&
                  description.getCurrentContent().hasText() &&
                  JSON.stringify(convertToRaw(description.getCurrentContent()))) ||
                null,
              // expiry_date: values?.is_expiry_date ? expiry_date || '' : '',
              payment_methods_available,
              taxes,
            });
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => {
            // let getNextTwoMonthDate = new Date();
            // getNextTwoMonthDate.setMonth(getNextTwoMonthDate.getMonth() + 3);
            // getNextTwoMonthDate = `${getNextTwoMonthDate.getFullYear()}-${`0${getNextTwoMonthDate.getMonth()}`.slice(
            //   -2,
            // )}-${`0${getNextTwoMonthDate.getDate()}`.slice(-2)}`;

            let finalStartDate = new Date();
            const eightWeekDayFromNow = this.addWeekdays(finalStartDate, 8);

            finalStartDate.setDate(eightWeekDayFromNow.format('D'));
            finalStartDate.setMonth(Number(eightWeekDayFromNow.format('M')) - 1);
            finalStartDate.setFullYear(eightWeekDayFromNow.format('YYYY'));

            let installmentStartDate = new Date();

            installmentStartDate.setDate(eightWeekDayFromNow.format('D'));
            installmentStartDate.setMonth(Number(eightWeekDayFromNow.format('M')) - 1);
            installmentStartDate.setFullYear(eightWeekDayFromNow.format('YYYY'));

            let installmentLastDate = '';
            if (values?.final_date) {
              installmentLastDate = new Date(values?.final_date);
            }

            let errorBookingSevenDayBefore = '';
            if (values?.book_save_buy && installmentLastDate) {
              const seventhWeekDayBeforeToday = this.subtractWeekdays(installmentLastDate, 7);
              installmentLastDate.setDate(seventhWeekDayBeforeToday.format('D'));
              installmentLastDate.setMonth(Number(seventhWeekDayBeforeToday.format('M')) - 1);
              installmentLastDate.setFullYear(seventhWeekDayBeforeToday.format('YYYY'));
              if (moment(installmentLastDate).diff(moment(installmentStartDate), 'days') < 0) {
                errorBookingSevenDayBefore =
                  'Payment schedule should be 7 days before the purchase date';
              }
            }

            let errorSameDate = '';
            if (values?.payment_events && values?.payment_events.length > 0 && values?.final_date) {
              const isFoundSameDate = values?.payment_events.find(
                (data) => data?.date === values?.final_date,
              );
              if (isFoundSameDate) {
                errorSameDate = 'Purchase date and schedule date should not be same';
              }
            }

            let errorRemainingAmountDecimal = '';
            if (values?.payment_events && values?.payment_events.length > 0) {
              const isFoundDecimal = values?.payment_events.find(
                (data) => data?.amount_required % 1 !== 0,
              );
              if (isFoundDecimal) {
                errorRemainingAmountDecimal =
                  'Remaining amount should not be in decimal, Decimal amount can adjust in booking amount';
              }
            }

            const isDisabledSubmitBtn = errorSameDate || errorRemainingAmountDecimal;

            return (
              <Form>
                {this.props.isNew && <Heading variant="section">Generate New Offer</Heading>}
                <Card className={styles.card}>
                  <Card.Header className={styles.cardHeader}>
                    <Heading variant="sub-heading">
                      {this.props.isNew ? 'New' : 'Edit'} Offer Details
                    </Heading>
                    {!this.props.isNew && (
                      <span
                        className={`${styles.anchorHand} ${styles.toggleButton}`}
                        onClick={handleEditState}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </span>
                    )}
                  </Card.Header>
                  <Card.Body className={styles.cardBody}>
                    <div className={styles.cardSection}>
                      {this.props?.isNew && (
                        <Row className={styles.cardRow}>
                          <Col>
                            {/* <InputSelectOne
                            label="Select the product you would like the generate an offer for"
                            name="product_uuid"
                            id="product_uuid"
                            onChange={(e) => {
                              setFieldValue('product_uuid', e.target.value);
                              const getProduct =
                                this.props?.products &&
                                this.props?.products.find((data) => data?.uuid === e.target.value);
                              this.setState({ productAmount: Number(getProduct?.amount) });
                            }}
                          >
                            <option value="">Select a product</option>
                            {this.props.products &&
                              this.props.products.length > 0 &&
                              this.props.products.map((product, index) => (
                                <option value={product.uuid} key={index}>
                                  {product.productName}
                                </option>
                              ))}
                          </InputSelectOne> */}
                            <div className={styles['label']} style={{ marginBottom: 5 }}>
                              Select the product you would like to generate an offer for
                            </div>

                            <AsyncSelectInput
                              cacheOptions
                              loadOptions={this.loadOptions}
                              defaultOptions={productsData}
                              placeholder=""
                              name="product_uuid"
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  borderRadius: 8,
                                }),
                              }}
                              value={{
                                value: selectedProduct?.productName || '',
                                label: selectedProduct?.productName || '',
                              }}
                              onChange={(e) => {
                                const product =
                                  this.props.products &&
                                  this.props.products.find((product) => product.uuid === e?.uuid);
                                setFieldValue('product_uuid', product ? product?.uuid : '');
                                this.setState(
                                  {
                                    selectedProduct: product || '',
                                  },
                                  this.handleGstTcsAndDiscount,
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      {selectedProduct && (
                        <Row className={styles.cardRow}>
                          <Col md={3}>
                            <div className={styles.mainImage}>
                              {(selectedProduct?.imageUrls &&
                                selectedProduct?.imageUrls.length > 0) ||
                                selectedProduct?.imageLink ? (
                                <img
                                  src={
                                    (selectedProduct?.imageUrls &&
                                      selectedProduct?.imageUrls.length > 0 &&
                                      selectedProduct?.imageUrls[0].url) ||
                                    selectedProduct?.imageLink
                                  }
                                  alt="img"
                                />
                              ) : (
                                <div className={styles.innerImageBox}>
                                  Thumbnail <br /> Image
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col md={9}>
                            <div className={styles.rightSideBox}>
                              <div className={styles.upperBox}>
                                <div className={styles.list}>
                                  <span className={styles.inputLabel}>Price</span>
                                  <span className={styles.price}>
                                    {Convert.toCurrencyValue(selectedProduct?.amount)}
                                  </span>
                                </div>
                                <div className={styles.list}>
                                  <span>{selectedProduct && selectedProduct.productName}</span>
                                </div>
                              </div>
                              <div className={styles.lowerBox}>
                                {selectedProduct?.textFields &&
                                  selectedProduct.textFields.map((textField, index) => (
                                    <div className={`  ${styles.description}`} key={index}>
                                      <div className={styles.editor}>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.prepareBody(textField.body),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}{' '}
                      <Row className={styles.cardRow}>
                        <Col md={12} className={styles.cardCol}>
                          <TextInputOne
                            label="Offer Name"
                            name="product_offer_name"
                            id="product_offer_name"
                            type="text"
                            labelsize="large"
                          />
                        </Col>
                        <Row className={styles['description']}>
                          {/* <Col md={12} className="pt-3">
                          <div className={styles['label']}>Description</div>
                          <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName={styles['editorClassName']}
                            onEditorStateChange={this._handleDescription}
                          />
                        </Col> */}
                        </Row>
                      </Row>
                      {!this.props.isNew && (
                        <div className={styles['status']}>
                          <Row>
                            <Col sm={4} className={styles['label']}>
                              Status
                            </Col>
                            <Col sm={1} className={styles['is-status']}>
                              <InputSwitch
                                label={`${values.hidden ? 'Hidden' : 'Visible'}`}
                                name="hidden"
                                id="hidden"
                                containerstyles={{
                                  marginBottom: '1rem',
                                }}
                                checked={values.hidden}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      <div className={styles['discount']}>
                        <Row>
                          <Col sm={4} className={styles['label']}>
                            Discount available
                          </Col>
                          <Col sm={1} className={styles['is-discount-available']}>
                            <InputSwitch
                              label={`${values.is_discount_percentage ? 'YES' : 'NO'}`}
                              name="is_discount_percentage"
                              id="is_discount_percentage"
                              containerstyles={{
                                marginBottom: '1rem',
                              }}
                              checked={values.is_discount_percentage}
                              onClick={() =>
                                this.setState(
                                  {
                                    isDiscountPercentageToggled: !isDiscountPercentageToggled,
                                  },
                                  this.handleGstTcsAndDiscount,
                                )
                              }
                            />
                          </Col>
                          <Col sm={1} />
                          {values.is_discount_percentage && (
                            <Col sm={6}>
                              <TextInputTwo
                                label="Discount Percentage"
                                name="discount_percentage"
                                id="discount_percentage"
                                type="number"
                                max={90}
                                min={0}
                                step=".01"
                                addonPosition="right"
                                disabled={!values.is_discount_percentage}
                                onChange={this._onChangeDiscount}
                                style={{ width: '100%' }}
                                addonValue={{
                                  type: 'icon',
                                  value: 'faPercent',
                                }}
                              />
                            </Col>
                          )}
                        </Row>
                        {selectedProduct && (
                          <div className={styles['gst-tcs']}>
                            <Row>
                              <Col sm={6} className={styles['label']}>
                                GST : {Convert.currency(gst)}
                              </Col>
                              <Col sm={6} className={styles['label']}>
                                TCS : {Convert.currency(tcs)}
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                      <div className={styles['discounted-price']}>
                        Offer price after discount and tax: {Convert.currency(finalProductPrice)}
                      </div>
                      <Row className={styles['months']}>
                        <Col md={2} className={styles['label']}>
                          Purchase Date
                        </Col>
                        <Col md={4}>
                          <NewTextInputOne
                            name="final_date"
                            id="final_date"
                            type="date"
                            min={new Date(finalStartDate).toISOString().split('T')[0]}
                            onChange={(e) => {
                              setFieldValue('final_date', e.target.value);
                              this.resetPaymentScheduleDates();
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                          />

                          {/* <TextInputTwo
                          label="Define the saving time period "
                          name="final_date"
                          id="final_date"
                          type="number"
                          addonPosition="right"
                          addonValue={{
                            type: 'text',
                            value: 'Months',
                          }}
                          onChange={(e) => {
                            setFieldValue('final_date', e.target.value).then(() =>
                              this._handlePaymentDependenciesChange(),
                            );
                          }}
                        /> */}
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>

                <Card className={styles.card}>
                  <Card.Header className={styles.cardHeader}>
                    <Heading variant="sub-heading">
                      What type of payment methods would you like to offer?
                    </Heading>
                  </Card.Header>
                  <Card.Body className={styles.cardBody}>
                    <Row className={`${styles.cardRow} ${styles['payment-methods']}`}>
                      <div className={styles.paymentMethodContainer}>
                        <div
                          className={styles.checkBoxContainer}
                          onClick={() => {
                            if (!values.book_save_buy) this.resetPaymentScheduleDates();
                            setFieldValue('book_save_buy', !values.book_save_buy);
                          }}
                        >
                          <div className={styles['tick-box']}>
                            {values.book_save_buy && <img src="/img/checkbox.png" alt="checkox" />}
                          </div>
                          <span>Book. Save. Buy</span>
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                Charge your customers a minimum booking amount, allow them to save
                                for the rest of the payment in ICICI Prudential Liquid Fund and pay
                                you within the schedule you set up below.
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} className={styles.tooltip} />
                          </OverlayTrigger>
                        </div>
                        <div
                          className={styles.checkBoxContainer}
                          onClick={() =>
                            setFieldValue('pay_in_instalments', !values.pay_in_instalments)
                          }
                        >
                          <div className={styles['tick-box']}>
                            {values.pay_in_instalments && (
                              <img src="/img/checkbox.png" alt="checkox" />
                            )}
                          </div>
                          <span>Pay in Installments</span>
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                Charge your customers a minimum booking amount, the remaining
                                balance will be paid to you as per the schedule you set below.
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} className={styles.tooltip} />
                          </OverlayTrigger>
                        </div>
                      </div>
                      {errors?.book_save_buy && (
                        <div className={styles.paymentMethodError}>{errors?.book_save_buy}</div>
                      )}
                      <div>
                        BSB (Book Save Buy) works best for plans beyond 2 months, and PII works best
                        of plans less than 2 months{' '}
                      </div>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className={styles.card}>
                  <Card.Header
                    className={`${styles.cardHeader} ${styles['define-payment-schedule']}`}
                  >
                    <Heading variant="sub-heading">
                      Define Payment Schedule for {Convert.currency(finalProductPrice)}
                    </Heading>
                  </Card.Header>
                  <Card.Body className={styles.cardBody}>
                    <Row className={`${styles.cardRow} ${styles['schedule-payments']}`}>
                      <div className={styles['payment-schedule']}>
                        <div className={styles['input-box']}>
                          <TextInputOne
                            label="Amount"
                            labelsize="large"
                            name="upfront_payment_amount"
                            id="upfront_payment_amount"
                            type="number"
                            disabled={!this.props.isNew && !this.props?.productOffer?.canEdit}
                            onBlur={this._firstPaymentChange}
                            onChange={this.handleFirstPaymentChange}
                          />
                        </div>
                        <div className={styles['arrow']}>
                          <img src="/img/long-arrow.svg" alt="arrow" />
                        </div>
                        <div className={styles['first-payment']}>Booking amount</div>
                      </div>
                      <div className={styles['remaining-payment']}>
                        Remaining Payment Schedule for {Convert.currency(remainingAmount)}
                      </div>
                      {scheduleError ? (
                        <div className={styles['schedule-error']}>{scheduleError}</div>
                      ) : null}
                      {errorBookingSevenDayBefore ? (
                        <div className={styles['schedule-error']}>{errorBookingSevenDayBefore}</div>
                      ) : null}
                      {errorSameDate ? (
                        <div className={styles['schedule-error']}>{errorSameDate}</div>
                      ) : null}
                      {errorRemainingAmountDecimal ? (
                        <div className={styles['schedule-error']}>
                          {errorRemainingAmountDecimal}
                        </div>
                      ) : null}
                      <FieldArray
                        name="payment_events"
                        render={(arrayHelpers) => (
                          <>
                            {values?.payment_events &&
                              values?.payment_events.length > 0 &&
                              values?.payment_events.map((paymentEvents, index) => (
                                <div key={index} className={styles['payment-schedule']}>
                                  <div className={styles['input-box']}>
                                    <TextInputOne
                                      label="Remaining amount"
                                      labelsize="large"
                                      labelstyles={
                                        index > 0
                                          ? cx(styles.paymentInputLabel, styles.variantOne)
                                          : ''
                                      }
                                      name={`payment_events[${index}].amount_required`}
                                      id={`payment_events[${index}].amount_required`}
                                      type="number"
                                      disabled={
                                        !this.props.isNew && !this.props?.productOffer?.canEdit
                                      }
                                      onBlur={this._paymentScheduleChange}
                                      onChange={(e) => this.handleAmount(e, index, paymentEvents)}
                                    />
                                  </div>
                                  <div className={styles['arrow']}>
                                    <img src="/img/long-arrow.svg" alt="arrow" />
                                  </div>
                                  <div className={styles['advance']}>
                                    <div className={styles['advance-input']}>
                                      <div className={styles['label']}>
                                        {index === 0 ? 'Choose date' : <>&nbsp;</>}
                                      </div>

                                      <NewTextInputOne
                                        name={`payment_events[${index}].date`}
                                        id={`payment_events[${index}].date`}
                                        type="date"
                                        disabled={!values?.final_date}
                                        min={
                                          new Date(installmentStartDate).toISOString().split('T')[0]
                                        }
                                        max={
                                          (installmentLastDate &&
                                            new Date(installmentLastDate)
                                              .toISOString()
                                              .split('T')[0]) ||
                                          ''
                                        }
                                        onKeyDown={(e) => e.preventDefault()}
                                      />
                                      {/* <TextInputFour
                                      label={`${
                                        index === 0 ? 'Days in advance to recieve amount' : ' '
                                      }`}
                                      labelsize="large"
                                      labelstyles={index > 0 ? styles['no-label'] : ''}
                                      name={`payment_events[${index}].date`}
                                      id={`payment_events[${index}].date`}
                                      type="number"
                                      disabled={
                                        !this.props.isNew && !this.props?.productOffer?.canEdit
                                      }
                                      inputgrouptextposition="right"
                                      inputgrouptextcomponent="days"
                                      onBlur={this._paymentScheduleChange}
                                    /> */}
                                    </div>
                                    {index > 0 && (
                                      <div
                                        className={styles['delete']}
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            <div className={styles['add-row']}>
                              {(this.props.isNew || this.props?.productOffer?.canEdit) && (
                                <span
                                  onClick={() =>
                                    arrayHelpers.push({ amount_required: 0, date: null })
                                  }
                                >
                                  Add Row
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      />
                      <div className={styles['expire-offer']}>
                        This offer will expire in 48 hours
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
                {/* <Card className={styles.card}>
                <Card.Header className={`${styles.cardHeader} ${styles['user-payment-schedule']}`}>
                  <span className={styles.cardHeading}>Users Saving Schedule</span>
                </Card.Header>
                <Card.Body className={styles.cardBody}>
                  <Row className={`${styles.cardRow} ${styles['saving-schedule']}`}>
                    <div className={styles['saving-total']}>Total amount: ₹2,00,000 </div>
                    <div className={styles['saving-payment-schedule']}>
                      <div className={styles['first-payment']}> ₹20,000</div>
                      <div className={styles['arrow']}>
                        <img src="/img/long-arrow.svg" alt="arrow" />
                      </div>
                      <div className={styles['first-payment']}>Paid today</div>
                    </div>
                    <div className={styles['remaining-payment']}>
                      Pending amount: ₹1,80,000 (Paid over 6 installments of ₹30,000)
                    </div>
                    <div className={styles['remaining-payment-schedule-box']}>
                      <div className={styles['remaining-payment-schedule']}>
                        <div className={styles['first-payment']}> ₹30,000</div>
                        <div className={styles['arrow']}>
                          <img src="/img/long-arrow.svg" alt="arrow" />
                        </div>
                        <div className={styles['first-payment']}>1st of feburary</div>
                      </div>
                      <div className={styles['remaining-payment-schedule']}>
                        <div className={styles['first-payment']}> ₹30,000</div>
                        <div className={styles['arrow']}>
                          <img src="/img/long-arrow.svg" alt="arrow" />
                        </div>
                        <div className={styles['first-payment']}>1st of March</div>
                      </div>
                      <div className={styles['remaining-payment-schedule']}>
                        <div className={styles['first-payment']}> ₹30,000</div>
                        <div className={styles['arrow']}>
                          <img src="/img/long-arrow.svg" alt="arrow" />
                        </div>
                        <div className={styles['first-payment']}>1st of April</div>
                      </div>
                      <div className={styles['remaining-payment-schedule']}>
                        <div className={styles['first-payment']}> ₹30,000</div>
                        <div className={styles['arrow']}>
                          <img src="/img/long-arrow.svg" alt="arrow" />
                        </div>
                        <div className={styles['first-payment']}>1st of May</div>
                      </div>
                      <div className={styles['remaining-payment-schedule']}>
                        <div className={styles['first-payment']}> ₹30,000</div>
                        <div className={styles['arrow']}>
                          <img src="/img/long-arrow.svg" alt="arrow" />
                        </div>
                        <div className={styles['first-payment']}>1st of June</div>
                      </div>
                      <div className={styles['remaining-payment-schedule']}>
                        <div className={styles['first-payment']}> ₹30,000</div>
                        <div className={styles['arrow']}>
                          <img src="/img/long-arrow.svg" alt="arrow" />
                        </div>
                        <div className={styles['first-payment']}>1st of Juy</div>
                      </div>
                    </div>
                  </Row>
                </Card.Body>
              </Card> */}

                {/* <Card className={styles.card}>
                  <Card.Header className={`${styles.cardHeader} ${styles['expiry-card-header']}`}>
                    <div className={styles['expiry-date']}>
                      <div className={styles['expiry-date-left']}>
                        <span>Is there an expiry date on this offer</span>
                        <div className={styles['is-expiry']}>
                          <InputSwitch
                            label={`${values.is_expiry_date ? 'YES' : 'NO'}`}
                            name="is_expiry_date"
                            id="is_expiry_date"
                            checked={values.is_expiry_date}
                          />
                        </div>
                      </div>
                      <div className={styles['end-offer']}>
                        {values?.is_expiry_date && (
                          <>
                            <span>End offer on</span>
                            <DatePicker
                              dateFormat="dd/MM/yyyy" // don't change to uppercase
                              placeholder="DD/MM/YYYY"
                              ref={this.datepickerRef}
                              className={styles['date-picker']}
                              name="expiry_date"
                              id="expiry_date"
                              selected={expiry_date}
                              onChange={this._handleExpiryDate}
                            />
                            <FontAwesomeIcon icon={faCalendarDay} />
                          </>
                        )}
                      </div>
                    </div>
                  </Card.Header>
                </Card> */}
                <ButtonMain type="submit" align="center" disabled={Boolean(isDisabledSubmitBtn)}>
                  {this.props?.isNew ? 'Generate New Offer' : 'Save Details'}
                </ButtonMain>
                {showCreateAnotherModal && (
                  <CreateAnotherOfferModal
                    product={this.props?.products.find(
                      (data) => data?.uuid === values?.product_uuid,
                    )}
                    showCreateAnotherModal={showCreateAnotherModal}
                    closeCreateAnotherModal={this.closeCreateAnotherModal}
                    handleCreateAnotherOffer={this.handleCreateAnotherOffer}
                  />
                )}
                {showUpdateConfirmationModal && (
                  <OfferUpdateConfirmationModal
                    product={this.props?.products.find(
                      (data) => data?.uuid === values?.product_uuid,
                    )}
                    showUpdateConfirmationModal={showUpdateConfirmationModal}
                    closeUpdateConfirmationModal={this.closeUpdateConfirmationModal}
                    handleUpdateConfirmationModal={() =>
                      this.handleUpdateConfirmationModal(handleSubmit)
                    }
                  />
                )}
              </Form>
            );
          }}
        </Formik>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withRouter(OfferDetailsEdit);

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function BankDetailsReducer(state = INITIAL_STATE.bankDetails, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_OTM_BANKS_SUCCEEDED: {
      if (action.payload.length > 0)
        return {
          ...state,
          ...action.payload[0],
        };
      return {
        ...state,
      };
    }

    case ACTION_TYPES.REQUEST_BANK_DETAILS_SUCCEEDED: {
      return action.payload;
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.bankDetails;
    }

    default:
      return state;
  }
}

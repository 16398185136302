import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './_modal.scss';

class SavvyPayWorksModal extends Component {
  render() {
    const { isShow, closeModal } = this.props;
    return (
      <div className="savvy-pay-works">
        <Modal className="savvy-pay-works-modal" show={isShow}>
          <div className="title">
            How <span>SavvyPay</span> Works
          </div>
          <div className="close-btn">
            <span onClick={closeModal}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </span>
          </div>

          <div className="cards-group">
            <div className="card">
              <div className="number active-number">1</div>
              <div className="name">Book</div>
              <div className="icon">
                <img src="/img/cal-1.png" alt="icon" />
              </div>
              <hr />
              <div className="description">
                Pay a minimal booking amount to secure your purchase today
              </div>
            </div>
            <div className="card">
              <div className="number">2</div>
              <div className="name">Save</div>
              <div className="icon">
                <img src="/img/piggy-1.png" alt="icon" />
              </div>
              <hr />
              <div className="description">
                Save for the rest of the amount in montly investments and earn returns as you go!
              </div>
            </div>
            <div className="card">
              <div className="number">3</div>
              <div className="name">Buy</div>
              <div className="icon">
                <img src="/img/cart-1.png" alt="icon" />
              </div>
              <hr />
              <div className="description">
                Buy the product with money saved and get the best value deal.
              </div>
            </div>
          </div>
          <button className="custom-btn" onClick={closeModal}>
            START BOOKING
          </button>
        </Modal>
      </div>
    );
  }
}

export default SavvyPayWorksModal;

import { connect } from 'react-redux';
import {
  requestFullKycRegistration,
  requestKycLogin,
  requestOTMBanks,
  requestBankDetails,
  requestSendPennyDrop,
  requestSubmitBankAccount,
} from 'src/redux/actions';
import LinkAccount from './link-account';

function mapStateToProps(state) {
  return {
    user: state.user,
    bankDetails: state.bankDetails,
  };
}

export default connect(mapStateToProps, {
  requestFullKycRegistration,
  requestKycLogin,
  requestOTMBanks,
  requestBankDetails,
  requestSendPennyDrop,
  requestSubmitBankAccount,
})(LinkAccount);

import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { MandatoryMark } from 'src/components';
import styles from './_inputfiletwo.module.scss';

class InputFileTwo extends Component {
  state = {
    src: '',
  };

  _onChange = (event) => {
    const [file] = event.target.files;

    if (file && this.props.accept.includes('image')) {
      const src = URL.createObjectURL(file);
      this.setState({ src });
    }

    this.props.onChange && this.props.onChange(event);
  };

  render() {
    return (
      <>
        <Row>
          <Col md={12}>
            {this.props.heading && (
              <div className={styles.header}>
                <span className={cx(styles.heading, this.props.headingstyles)}>
                  {this.props.heading}
                </span>
                <MandatoryMark />
              </div>
            )}
            <div className={cx(styles.imageInputContainer, this.props.container)}>
              {(this.state.src &&
                !this.props.loading &&
                this.props.accept &&
                this.props.accept.includes('image')) ||
              this.props.imageSrc ? (
                <div className={styles.preview}>
                  <img
                    className={styles.image}
                    src={this.state.src || this.props.imageSrc}
                    alt={this.props.label}
                  />
                </div>
              ) : this.props.loading ? (
                <div className={styles.preview}>
                  <img className={styles.uploading} src="/img/uploading.gif" alt="Uploading" />
                  Uploading...
                </div>
              ) : (
                <div className={styles.text}>{this.props.label}</div>
              )}
              <div>
                <div>
                  <label
                    htmlFor={this.props.id || this.props.name}
                    className={cx('btn', styles.label, this.props.labelStyle)}
                  >
                    Upload
                  </label>
                </div>
                <div>
                  {this.props.isPanUploadFailed && (
                    <label
                      htmlFor={this.props.id || this.props.name}
                      className={cx(styles['sync-btn'])}
                    >
                      <FontAwesomeIcon icon={faSyncAlt} />
                      Retry Upload
                    </label>
                  )}
                </div>
              </div>
              <Form.Group style={{ position: 'fixed', top: '-1000px' }}>
                <Form.Control
                  type="file"
                  // {...field}
                  {...this.props}
                  onChange={this._onChange}
                />
              </Form.Group>
            </div>
            {/* {meta.touched && meta.error ? (
              <div className={globalStyles.error}>{meta.error}</div>
            ) : null} */}
          </Col>
        </Row>
      </>
    );
  }
}

export default InputFileTwo;

import React, { Component } from 'react'
import { Heading, Text, ButtonMain, TextInput, Label, Logo } from 'src/components/theme'
import styles from './_themedemo.module.scss'

export class ThemeDemo extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div>
          <Logo/>
          <Logo logoHeadingColor="red"/>
          <Logo logoLeadingColor="green"/>
        </div>
        <div>
          <Heading>
            This is the Main heading
          </Heading>
        </div>
        <div>
          <Heading variant="section">
            This is the Section heading 2
          </Heading>
        </div>
        <div>
          <Text variant="section">
            Section text - Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus placeat, blanditiis, labore amet ipsam odio dicta suscipit praesentium asperiores fuga cumque consequatur accusantium officiis numquam debitis quisquam libero veniam?
          </Text>
        </div>
        <div>
          <Text variant="p1">
            paragraph one - Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus placeat, blanditiis, labore amet ipsam odio dicta suscipit praesentium asperiores fuga cumque consequatur accusantium officiis numquam debitis quisquam libero veniam?
          </Text>
        </div>
        <div>
          <Text variant="p2">
          paragraph two - Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus placeat, blanditiis, labore amet ipsam odio dicta suscipit praesentium asperiores fuga cumque consequatur accusantium officiis numquam debitis quisquam libero veniam?
          </Text>
        </div>
        <div>
          <Text variant="finePrint">
            finr print - Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus placeat, blanditiis, labore amet ipsam odio dicta suscipit praesentium asperiores fuga cumque consequatur accusantium officiis numquam debitis quisquam libero veniam?
          </Text>
        </div>
        <div style={{marginBottom: '10px'}}>
          <ButtonMain
            variant="primary"
          >
            Primary Button
          </ButtonMain>
        </div>
        <div style={{marginBottom: '10px'}}>
          <ButtonMain
            variant="secondary"
          >
            Secondary Button
          </ButtonMain>
        </div>
        <div style={{marginBottom: '10px'}}>
          <ButtonMain
            variant="compact"
          >
            Compact Button
          </ButtonMain>
        </div>
        <div>
          <Label>Label</Label>
          <TextInput
            placeholder="Text input"
          />
        </div>
        {/* <div>
          <ModalLayout>
            Modal
          </ModalLayout>
        </div> */}
      </div>
    )
  }
}

export default ThemeDemo

import React, { Component } from 'react';
import cx from 'classnames';
import styles from './confirm-account.module.scss';

class ConfirmAccount extends Component {
  _onLinkAccount = () => {
    this.props.onLinkAccount && this.props.onLinkAccount();
  };

  render() {
    const { bankAccountNumber, bankName, branchName, city } = this.props;

    return (
      <div className={styles['link-account']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>
        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>

        <div className={styles['pay-content']}>
          <div className={styles['title']}>Verify your KYC Start Saving</div>
          <div className={styles['line']} />
          <div className={styles['sub-title']}>LINK BANK ACCOUNT</div>
          <div className={styles['sub-title-light']}>
            This is the bank account linked to your PAN Card
          </div>
          <div className={cx(styles['mobile-space'], styles['mobile'])}>
            <div className={cx(styles['line-mob'])} />
          </div>

          <div className={styles['mobile-space']}>
            <div className={styles['card']}>
              <div className={styles['card-list']}>
                <span className={styles['header-bold']}>Bank account no:</span>{' '}
                <span title={bankAccountNumber}>{bankAccountNumber}</span>
              </div>
              <div className={styles['card-list']}>
                <span className={styles['header-bold']}>Bank name:</span>
                <span title={bankName}>{bankName}</span>
              </div>
              <div className={styles['card-list']}>
                <span className={styles['header-bold']}>Bank branch:</span>
                <span title={branchName}> {branchName}</span>
              </div>
              <div className={styles['card-list']}>
                <span className={styles['header-bold']}>Bank city:</span>
                <span title={city}> {city} </span>
              </div>
            </div>
          </div>
          <div className={styles['link']} onClick={this._onLinkAccount}>
            <div>I want to link another bank account</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmAccount;

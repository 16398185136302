import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import styles from './_link-one.module.scss'

export const LinkOne = (props) => {
  const {
    variant = 'one'
  } = props

  return(
    <Link className={cx(styles[variant], props?.className)} {...props}>
      {props?.children}
    </Link>
  )
}

import React, { Component } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextInputOne from 'src/components/form-inputs/textinput-one';
import { ButtonOne } from 'src/components';
import styles from './_userdetailsedit.module.scss';

class UserDetailsEdit extends Component {
  _validationSchema = yup.object().shape({
    firstName: yup.string().required('Please enter first name'),
    lastName: yup.string().required('Please enter last name'),
    email: yup.string().required('Please enter email'),
    uid: yup.string().required('Please enter uid'),
  });

  render() {
    const { user } = this.props;
    return (
      <>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            uid: user.uid,
          }}
        >
          {() => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Card className={styles.card}>
                <Card.Header className={styles.cardHeader}>
                  <span className={styles.cardHeading}>Edit User Details</span>
                </Card.Header>
                <>
                  <Card.Body className={styles.cardBody}>
                    <div className={styles.cardSection}>
                      <Row className={styles.cardRow}>
                        <Col md={6} className={styles.cardCol}>
                          <TextInputOne
                            label="FirstName"
                            name="firstName"
                            id="firstName"
                            type="text"
                          />
                        </Col>
                        <Col md={6} className={styles.cardCol}>
                          <TextInputOne
                            label="LastName"
                            name="lastName"
                            id="lastName"
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row className={styles.cardRow}>
                        <Col md={6} className={styles.cardCol}>
                          <TextInputOne
                            label="Email"
                            name="email"
                            id="email"
                            type="text"
                            disabled
                          />
                        </Col>
                        <Col md={6} className={styles.cardCol}>
                          <TextInputOne label="UID" name="uid" id="uid" type="text" disabled />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                  <ButtonOne
                    text="Save Details"
                    type="submit"
                    parentstyles={{
                      height: '5rem',
                      backgroundColor: '#ECECEC',
                      borderBottomLeftRadius: '5px',
                      borderBottomRightRadius: '5px',
                    }}
                  />
                </>
              </Card>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default UserDetailsEdit;

import React, { Component } from 'react';
import { Input, Modal, Button } from '..';
import styles from './_dropdown.module.scss';

class DropDownItem extends Component {
  _onClick = () => {
    this.props.onSelect({
      value: this.props.value,
      text: this.props.text,
    });
  };

  render() {
    return (
      <button className={styles['app-drop-down-item']} onClick={this._onClick}>
        {this.props.text}
        {this.props.selected && this.props.selected.value === this.props.value ? (
          <img
            src="https://savvy-web.s3-ap-southeast-1.amazonaws.com/partner/right.png"
            className={styles['selected-icon']}
            alt="selected"
          />
        ) : null}
      </button>
    );
  }
}

class DropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false,
      valueText: '',
      selected: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const selected = this.props.data.find((item) => item.value === this.props.value);
      this.setState({ selected, valueText: selected.text });
    }
  }

  _onToggle = () => {
    this.setState({ showPicker: true });
  };

  _onSelect = (selected) => {
    this.setState({ selected });
  };

  _onFocus = () => {
    this.setState({ showPicker: true });
  };

  _onConfirm = () => {
    this.props.onChange && this.props.onChange(this.state.selected);
    this.setState({ showPicker: false, selected: null });
  };

  _onCancel = () => {
    this.setState({ showPicker: false, selected: null });
  };

  render() {
    return (
      <div className={styles['app-drop-down']}>
        <div className={styles['input-container']}>
          <Input
            label={this.props.label}
            placeholder={this.props.placeholder}
            value={this.state.valueText}
            disabled={this.props.disabled}
            onFocus={this._onFocus}
          />
          <button className={styles['app-drop-down-toggle-button']} onClick={this._onToggle}>
            <img
              src="https://savvy-web.s3-ap-southeast-1.amazonaws.com/partner/chevron-down.svg"
              alt="chevron-down"
            />
          </button>
        </div>
        <Modal show={this.state.showPicker}>
          <div className={styles['app-drop-down-overlay']}>
            <div className={styles['app-drop-down-body']}>
              {this.props.data &&
                this.props.data.length > 0 &&
                this.props.data.map((item, index) => (
                  <DropDownItem
                    {...item}
                    key={index}
                    selected={this.state.selected}
                    onSelect={this._onSelect}
                  />
                ))}
            </div>
            <div className={styles['app-drop-down-footer']}>
              <Button
                title="CONFIRM"
                className={styles['app-drop-down-confirm-button']}
                onClick={this._onConfirm}
              />
              <Button title="CANCEL" outline onClick={this._onCancel} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DropDown;

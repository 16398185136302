import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Loader } from 'src/components';
import { TextInputThree } from 'src/components/form-inputs';
import { ButtonMain } from 'src/components/theme';
import * as yup from 'yup';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import './_modal.scss';

const timerProps = {
  isPlaying: true,
  size: 22,
  strokeWidth: 0.5,
};

class SignInSignUpModal extends Component {
  state = {
    loading: false,
    codeSent: false,
    isResending: false,
    timer: '',
  };

  _validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required('Please enter phone number.')
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits')
      .matches(/^[6-9]\d{9}$/, {
        message: 'Phone number not valid',
      }),
  });

  componentDidMount() {
    this.requestSignInSubscription = EventBus.on(ACTION_TYPES.REQUEST_SIGN_IN_USER, () =>
      this.setState({ loading: true }),
    );
    this.signInSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.SIGN_IN_USER_SUCCESS,
      this._onLoginSuccess,
    );
    this.signInSubscriptionFailure = EventBus.on(ACTION_TYPES.SIGN_IN_USER_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestVerifyOTPSubscription = EventBus.on(ACTION_TYPES.REQUEST_VERIFY_OTP, () =>
      this.setState({ loading: true }),
    );
    this.verifyOTPSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.VERIFY_OTP_SUCCESS,
      this._onVerifyCodeSuccess,
    );
    this.verifyOTPSubscriptionFailure = EventBus.on(ACTION_TYPES.VERIFY_OTP_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestResendOtp = EventBus.on(ACTION_TYPES.REQUEST_RESEND_OTP, () =>
      this.setState({ isResending: true }),
    );
    this.resendOtpSuccess = EventBus.on(ACTION_TYPES.RESEND_OTP_SUCCESS, () => {
      this.setState({ isResending: false });
      this.handleStartTimer();
    });
    this.resendOtpFailure = EventBus.on(ACTION_TYPES.RESEND_OTP_FAILED, () =>
      this.setState({ isResending: false }),
    );
  }

  componentWillUnmount() {
    this.requestSignInSubscription();
    this.signInSubscriptionSuccess();
    this.signInSubscriptionFailure();

    this.requestVerifyOTPSubscription();
    this.verifyOTPSubscriptionSuccess();
    this.verifyOTPSubscriptionFailure();

    this.requestResendOtp();
    this.resendOtpSuccess();
    this.resendOtpFailure();
  }

  _onLogin = (values) => {
    const { user } = this.props;
    const { phoneNumber } = values;
    const parsedPhoneNumber = phoneNumber.toString().replace('-', '');

    this.props.requestSignInUser({
      phone_number: parsedPhoneNumber,
      email: user.email || null,
      first_name: user.firstName || null,
      last_name: user.lastName || null,
    });
  };

  _onVerifyCode = (values) => {
    this.props.requestVerifyOTP({
      sms_verification_code: values.code,
    });
  };

  handleResendOtp = () => {
    if (this.state.timer) return;
    this.props.requestResendOtp();
  };

  _onLoginSuccess = () => {
    this.setState({ codeSent: true, loading: false });
  };

  _onVerifyCodeSuccess = () => {
    this.props.onComplete();
  };

  handleStartTimer = () => {
    const timer = setTimeout(() => {
      this.handleClearTimer();
    }, 30000);
    this.setState({ timer });
  };

  handleClearTimer = () => {
    this.setState({ timer: '' });
  };

  render() {
    const { isShow, closeModal, user } = this.props;

    return (
      <Modal className="sign-in-sign-up-modal" show={isShow}>
        <div className="loginContainer">
          <div className="loginBox">
            <div className="crossIcon">
              <span onClick={closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
            <div className="loginTitle">Sign in to book</div>
            <Formik
              initialValues={{
                phoneNumber: user.phoneNumber ? user.phoneNumber.substr(3) : '',
              }}
              validationSchema={this._validationSchema}
              onSubmit={(values) => {
                if (!this.state.codeSent) {
                  this._onLogin(values);
                } else {
                  this._onVerifyCode(values);
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  {!this.state.codeSent ? (
                    <TextInputThree
                      labelstyles="label"
                      inputstyles="input"
                      label="Please enter your mobile number to begin."
                      name="phoneNumber"
                      id="phoneNumber"
                      type="number"
                      placeholder="+91 _ _ _ _ _ _ _ _ _ _"
                      onChange={(e) => {
                        if ((e.target.value && e.target.value.length <= 10) || !e.target.value) {
                          setFieldValue('phoneNumber', e.target.value);
                        }
                      }}
                      value={values?.phoneNumber}
                    />
                  ) : (
                    <div>
                      <TextInputThree
                        labelstyles="label"
                        inputstyles="input"
                        label="Please enter the 6 digit OTP"
                        name="code"
                        id="code"
                        type="number"
                      />
                      <div className="resend-otp-box">
                        {this.state.isResending ? (
                          <span className="link">Loading...</span>
                        ) : (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleResendOtp();
                            }}
                            className="link"
                          >
                            Resend OTP
                          </a>
                        )}
                        {this.state.timer && (
                          <div
                            className={`d-flex center ${
                              this.state.timer ? 'cursor-not-allowed' : ''
                            }`}
                          >
                            <span> &nbsp;in&nbsp;</span>
                            <CountdownCircleTimer
                              isPlaying
                              duration={30}
                              {...timerProps}
                              colors={[['#eda12c', 0.33]]}
                            >
                              {({ remainingTime }) => (
                                <div className="remaining-time">{remainingTime}</div>
                              )}
                            </CountdownCircleTimer>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <ButtonMain type="submit" className="submitBtn">
                    {!this.state.codeSent ? 'GET OTP' : 'VERIFY OTP'}
                  </ButtonMain>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </Modal>
    );
  }
}

export default SignInSignUpModal;

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import { InputFileTwo } from 'src/components/form-inputs';
import { ApiRequest } from 'src/helpers';
import { CtaTwo, SecuredPartners } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import styles from './_personalverification.module.scss';

class PersonalVerification extends Component {
  state = {
    loading: false,
    signatureImage: '',
    signatureLoading: false,
  };

  componentDidMount() {
    this.uploadSignatureRequestSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SIGNATURE_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.uploadSignatureRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SIGNATURE_SUCCESS,
      this._onUploadedSignature,
    );
    this.uploadSignatureRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SIGNATURE_FAILED,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.uploadSignatureRequestSubscription();
    this.uploadSignatureRequestSuccessSubscription();
    this.uploadSignatureRequestFailureSubscription();
  };

  _onSignatureImageUpdated = (event) => {
    this.setState({ signatureLoading: true });
    const [file] = event.target.files;

    ApiRequest.uploadToSignzy(file).then((res) => {
      this.setState({ signatureLoading: false, signatureImage: res.data.file.directUrl });
    });
  };

  _onSubmitSignature = () => {
    this.props.updateKycParams({ signature: { image_url: this.state.signatureImage } });
    this.props.uploadSignatureRequest({
      image_url: this.state.signatureImage,
    });
  };

  _onUploadedSignature = () => {
    this.setState({ loading: false }, () => {
      this.props.onComplete && this.props.onComplete();
    });
  };

  render() {
    return (
      <div className={styles['pan-verification']}>
        <div className={styles['pv-group-input']}>
          <Row>
            <Col lg={6} className={styles.formItem}>
              <InputFileTwo
                mandatory
                heading="Upload your signature"
                label="Upload a copy of your signature in a white background"
                name="signature"
                id="signature"
                headingstyles={styles.inputFileTwoHeading}
                container={styles.inputFileTwoContainer}
                accept="image/png, image/jpeg"
                loading={this.state.signatureLoading}
                onChange={this._onSignatureImageUpdated}
              />
            </Col>
          </Row>
          <CtaTwo
            type="submit"
            text="Next"
            loading={this.state.loading}
            styles={{
              minWidth: '140px',
              // width: '100%',
              height: '44px',
              fontFamily: 'Avenir-DemiBold',
              fontSize: '18px',
              marginTop: '2rem',
            }}
            onClick={this._onSubmitSignature}
          />
          <SecuredPartners
            styles={{
              marginTop: '1rem',
            }}
          />
        </div>
      </div>
    );
  }
}

export default PersonalVerification;

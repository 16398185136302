import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Dropdown } from 'react-bootstrap';
import { PaymentLinksTable } from 'src/components/tables';
import { Loader } from 'src/components';
import { DropdownToggle } from 'src/components/theme';
import styles from './_payment-links.module.scss';

class PaymentLinks extends Component {
  state = {
    loading: false,
    pageNumber: 1
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState({
      pageNumber
    }, () => this.props.requestPaymentLinks({
      page: pageNumber
    }))
  }

  componentDidMount() {
    this.requestPaymentLinksList = EventBus.on(ACTION_TYPES.PAYMENT_LINKS_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.paymentLinksListSuccess = EventBus.on(ACTION_TYPES.PAYMENT_LINKS_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.paymentLinksListFailure = EventBus.on(ACTION_TYPES.PAYMENT_LINKS_FAILED, () =>
      this.setState({ loading: false }),
    );

    const { requestPaymentLinks } = this.props;
    requestPaymentLinks({
      page: this.state.pageNumber
    });
  }

  componentWillUnmount() {
    this.requestPaymentLinksList();
    this.paymentLinksListSuccess();
    this.paymentLinksListFailure();
  }

  render() {
    const { adminPayments:{paymentLinks = [], linksCount = 0} = {} } = this.props;
    const { loading } = this.state;
    return (
      <div className={styles['payment-links']}>
        <div className={styles['heading']}>
          <h2 className="p-3">Payment Links</h2>
          <Dropdown className={styles['dropdown']}>
            <DropdownToggle>
              Export Data
            </DropdownToggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">CSV</Dropdown.Item>
              <Dropdown.Item href="#/action-2">PDF</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <PaymentLinksTable 
          data={paymentLinks} 
          handlePaginationChange={this._handlePaginationChange}
          totalItems={linksCount}
          requestPaymentExpireLink={this.props.requestPaymentExpireLink}
        />
        <Loader loading={loading} />
      </div>
    );
  }
}

export default PaymentLinks;

import React, { useMemo } from 'react';
// import { Link } from 'react-router-dom';
// import { faEdit, faExternalLinkAlt, faCopy, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'src/components/theme';
import { Convert } from 'src/helpers';
import { DataTable, TextOne, LinkOne } from '../../../components';
import './_users-table.style.scss';

const UsersTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#ffffff',
      borderBottomColor: '#f3f3f3',
    },
    tableHeader: {
      backgroundColor: '#f3f3f3',
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <TextOne variant="head">Name</TextOne>,
        accessor: 'firstName',
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <TextOne>
            <LinkOne to={`/admin/analytics/users/${original?.uuid}`}>
              {value} {original?.lastName}
              {!value && !original?.lastName && <>&nbsp;</>}
            </LinkOne>
          </TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Email</TextOne>,
        accessor: 'email',
        disableSortBy: true,
        Cell: ({ value }) => <TextOne>{value}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Phone number</TextOne>,
        accessor: 'phoneNumber',
        disableSortBy: true,
        Cell: ({ value }) => <TextOne>{value}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Total Paid</TextOne>,
        accessor: 'amount',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
          let contribution = 0;
          if (original?.savingGoals && original?.savingGoals.length > 0) {
            original?.savingGoals.forEach((savingGoal) => {
              if (Number(savingGoal?.productOffer?.upfrontPaymentAmount)) {
                contribution += parseFloat(savingGoal?.productOffer?.upfrontPaymentAmount);
              }
              if (
                savingGoal?.savingGoalContributions &&
                savingGoal?.savingGoalContributions.length > 0
              ) {
                savingGoal?.savingGoalContributions.forEach((data) => {
                  if (data?.status === 'completed' || data?.status === 'pending_investments') {
                    contribution += parseFloat(data?.amount) || 0;
                  }
                });
              }
            });
          }
          return <TextOne>{Convert.toCurrencyValue(contribution || 0)}</TextOne>;
        },
      },
      {
        Header: <TextOne variant="head">Ongoing Offers</TextOne>,
        accessor: 'ongoingOffers',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          let ongoingOffers = 0;
          if (original?.savingGoals && original?.savingGoals.length > 0) {
            original?.savingGoals.forEach((savingGoal) => {
              if (savingGoal?.status !== 'completed' && savingGoal?.status !== 'pending_delete') {
                ongoingOffers += 1;
              }
            });
          }
          return <TextOne>{ongoingOffers}</TextOne>;
        },
      },
      {
        Header: <TextOne variant="head">Status</TextOne>,
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <div className="badge-box">
            <Badge
              variant={
                original?.folio?.identificationConfirmed && original?.savingGoals.length > 0
                  ? `completed`
                  : original?.savingGoals.length > 0 && !original?.folio?.identificationConfirmed
                  ? `created`
                  : 'expired'
              }
              type={
                original?.folio?.identificationConfirmed && original?.savingGoals.length > 0
                  ? 'success'
                  : original?.savingGoals.length > 0 && !original?.folio?.identificationConfirmed
                  ? 'info'
                  : 'error'
              }
            >
              {original?.folio?.identificationConfirmed && original?.savingGoals.length > 0
                ? `${original?.savingGoals.length} Purchases | KYC Completed`
                : original?.savingGoals.length > 0 && !original?.folio?.identificationConfirmed
                ? `${original?.savingGoals.length} Purchases | KYC Pending`
                : '0 Purchases | KYC Pending'}
            </Badge>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <DataTable
      columns={columns}
      data={props.data || []}
      tableStyles={tableStyles}
      paginationConfig={paginationConfig}
      handlePaginationChange={props.handlePaginationChange}
    />
  );
};

export default UsersTable;

import React from 'react';
import { Button } from 'react-bootstrap';

const CtaTwo = (props) => (
  <Button 
    variant="" 
    style={{
      backgroundColor: props.backgroundColor || '#EDA12C',
      ...props.styles
    }}
    {...props}
    className={props.className}
  >{props.loading
    ? 'Loading...'
    : (props.text || 'Next')
  }
  </Button>
);

export default CtaTwo;

import { connect } from 'react-redux';
import _ from 'lodash';
import { requestGoalList } from 'src/redux/actions';
import Overview from './overview';

function mapStateToProps(state) {
  const { savingGoals } = state;
  const noOfGoals = savingGoals.length;

  let unsplashImage =
    savingGoals.length > 0 ? savingGoals.filter(({ unsplashImage }) => unsplashImage?.link) : [];
  unsplashImage =
    (unsplashImage && unsplashImage.length > 0 && unsplashImage[0].unsplashImage) || {};
  const totalGoalAmount = _.sumBy(savingGoals, (goal) => Number(goal.goalAbsoluteAmount));

  let imageUrl =
    savingGoals.length > 0
      ? savingGoals.filter(
          ({ productOffer }) =>
            productOffer?.product?.imageUrls &&
            productOffer?.product?.imageUrls.length > 0 &&
            productOffer?.product?.imageUrls[0],
        )
      : [];
  imageUrl =
    (imageUrl && imageUrl.length > 0 && imageUrl[0].productOffer?.product?.imageUrls[0].url) || {};

  let contribution = 0;
  if (savingGoals && savingGoals.length > 0) {
    savingGoals.forEach((saving) => {
      if (saving?.savingGoalContributions && saving?.savingGoalContributions.length > 0) {
        saving?.savingGoalContributions.forEach((data) => {
          if (data?.status === 'completed' || data?.status === 'pending_investments') {
            contribution += parseFloat(data?.amount) || 0;
          }
        });
      }
    });
  }

  const returns = _.sumBy(savingGoals, (goal) => {
    const returnAmount = Number(goal?.currentInvestmentBalance) - Number(goal?.currentBalance);
    return returnAmount > 0 ? returnAmount : 0;
  });

  let payments = [];

  _.forEach(savingGoals, (goal) => {
    payments = payments.concat(goal.payments);
  });

  const price = _.sumBy(savingGoals, (goal) => Number(goal?.productOffer?.discountedAmount || 0));

  return {
    noOfGoals,
    totalGoalAmount,
    totalAmountSaved: contribution,
    payments,
    unsplashImage,
    returns,
    imageUrl,
    price,
  };
}

export default connect(mapStateToProps, { requestGoalList })(Overview);

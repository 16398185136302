import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function UserReducer(state = INITIAL_STATE.user, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_TYPES.REQUEST_VERIFY_USER_TOKEN:
      return state;

    case ACTION_TYPES.VERIFY_USER_TOKEN_SUCCESS:
      return {
        ...action.payload,
      };

    case ACTION_TYPES.VERIFY_USER_TOKEN_FAILED:
      return state;

    case ACTION_TYPES.REQUEST_SIGN_IN_USER:
      return state;

    case ACTION_TYPES.SIGN_IN_USER_SUCCESS:
      return {
        ...action.payload.user,
        isPhoneVerified: false,
      };

    case ACTION_TYPES.SIGN_IN_USER_FAILED:
      return state;

    case ACTION_TYPES.REQUEST_VERIFY_OTP:
      return state;

    case ACTION_TYPES.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isPhoneVerified: true,
      };

    case ACTION_TYPES.VERIFY_OTP_FAILED:
      return state;

    case ACTION_TYPES.CREATE_FOLIO_SUCCEEDED:
      return {
        ...state,
        ...action.payload.user,
        folio: action.payload.folio
      }

    case ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_SUCCEEDED:
    case ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC_SUCCEEDED:
    case ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_TYPES.UPLOAD_PAN_CARD_SUCCESS:
    case ACTION_TYPES.UPLOAD_FORM_REQUEST:
    case ACTION_TYPES.UPLOAD_ADDRESS_PROOF_SUCCESS:
    case ACTION_TYPES.UPLOAD_SIGNATURE_SUCCESS:
    case ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_SUCCESS:
    case ACTION_TYPES.UPLOAD_SELFIE_SUCCESS:
    case ACTION_TYPES.LONG_KYC_VERIFICATION_SUCCESS:    
      return {
        ...state,
        folio: action.payload.folio
      };    
    
    case ACTION_TYPES.CREATE_DEPOSIT_SUCCESS:
    case ACTION_TYPES.FETCH_DEPOSIT_SUCCESS:
    case ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_SUCCESS:
    case ACTION_TYPES.FOLIO_WITHDRAW_SUCCESS:
      const accounts = action.payload.accounts && action.payload.accounts.length > 0 ? action.payload.accounts : state.accounts;            
      const folio = action.payload.folio || state.folio;
      return {
        ...state,
        folio: {
          ...folio
        },
        accounts: [
          ...accounts
        ]
      };

    case ACTION_TYPES.USER_UPDATE_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.user;
    }

    default:
      return state;
  }
}

import React from 'react';
import cx from 'classnames';
import styles from './_custombadge.module.scss';

function CustomBadge(props) {
  return (
    <span
      className={cx(styles.container, props.className, {
        [styles['success']]: props?.type === 'success',
        [styles['info']]: props?.type === 'info',
        [styles['error']]: props?.type === 'error',
      })}
    >
      {props.value}
    </span>
  );
}

export default CustomBadge;

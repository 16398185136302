import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  adminOfferCreate,
  adminOfferCreateV2,
  adminOfferDelete,
  adminOfferDetail,
  adminOffersList,
  adminOffersListByProductId,
  adminOfferUpdate,
  createAdminOffer,
  requestShortenUrl,
  adminPaymentSchedule
} from 'src/redux/api';
import {
  adminOffersListSuccess,
  adminOffersListFailure,
  adminOfferDetailSuccess,
  adminOfferDetailFailure,
  adminOfferDeleteSuccess,
  adminOfferDeleteFailure,
  adminOfferUpdateSuccess,
  adminOfferUpdateFailure,
  adminOfferCreateSuccess,
  adminOfferCreateFailure,
  createAdminOfferSuccess,
  createAdminOfferFailure,
  urlShortenSuccess,
  urlShortenFailure,
  adminOffersListByProductIdSuccess,
  adminOffersListByProductIdFailure,
  adminPaymentScheduleSuccess,
  adminPaymentScheduleFailure
} from 'src/redux/actions';

export function* watchAdminOffersRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST, requestAdminOffersList);
  yield takeEvery(
    ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST_BY_PRODUCT_ID,
    requestAdminOffersListByProductId,
  );
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_OFFER_DETAIL, requestAdminOfferDetail);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_OFFER_UPDATE, requestAdminOfferUpdate);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_OFFER_CREATE, requestAdminOfferCreate);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_OFFER_DELETE, requestAdminOfferDelete);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_OFFER_CREATE_V2, requestAdminOfferCreateV2);
  yield takeEvery(ACTION_TYPES.CREATE_ADMIN_OFFER, requestCreateAdminOffer);
  yield takeEvery(ACTION_TYPES.REQUEST_SHORTEN_URL, requestUrlShortener);
  yield takeEvery(ACTION_TYPES.ADMIN_PAYMENT_SCHEDULE_REQUEST, requestAdminPaymentSchedule);
}

function* requestAdminOfferCreate(action) {
  try {
    const offer = yield call(adminOfferCreate, action.payload);
    yield put(adminOfferCreateSuccess(offer));
  } catch (e) {
    yield put(adminOfferCreateFailure(e));
  }
}

function* requestAdminOfferDelete(action) {
  try {
    const offer = yield call(adminOfferDelete, action.payload);
    yield put(adminOfferDeleteSuccess(offer));
  } catch (e) {
    yield put(adminOfferDeleteFailure(e));
  }
}

function* requestAdminOfferCreateV2(action) {
  try {
    const offer = yield call(adminOfferCreateV2, action.payload);
    yield put(adminOfferCreateSuccess(offer));
  } catch (e) {
    yield put(adminOfferCreateFailure(e));
  }
}

function* requestAdminOfferUpdate(action) {
  try {
    const offer = yield call(adminOfferUpdate, action.payload);
    yield put(adminOfferUpdateSuccess(offer));
  } catch (e) {
    yield put(adminOfferUpdateFailure(e));
  }
}

function* requestAdminOfferDetail(action) {
  try {
    const offer = yield call(adminOfferDetail, action.payload);
    yield put(adminOfferDetailSuccess(offer));
  } catch (e) {
    yield put(adminOfferDetailFailure(e));
  }
}

function* requestAdminOffersList(action) {
  try {
    const offers = yield call(adminOffersList, action.payload);
    yield put(adminOffersListSuccess(offers));
  } catch (e) {
    yield put(adminOffersListFailure(e));
  }
}

function* requestAdminOffersListByProductId(action) {
  try {
    const offers = yield call(adminOffersListByProductId, action.payload);
    yield put(adminOffersListByProductIdSuccess(offers));
  } catch (e) {
    yield put(adminOffersListByProductIdFailure(e));
  }
}

function* requestCreateAdminOffer(action) {
  try {
    const offer = yield call(createAdminOffer, action.payload);
    yield put(createAdminOfferSuccess(offer));
  } catch (e) {
    yield put(createAdminOfferFailure(e));
  }
}

function* requestUrlShortener(action) {
  try {
    const offer = yield call(requestShortenUrl, action.payload);
    yield put(urlShortenSuccess(offer.paymentLink));
  } catch (e) {
    yield put(urlShortenFailure(e));
  }
}

function* requestAdminPaymentSchedule(action) {
  try {
    const schedule = yield call(adminPaymentSchedule, action.payload);
    yield put(adminPaymentScheduleSuccess(schedule));
  } catch (e) {
    yield put(adminPaymentScheduleFailure(e));
  }
}

import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { adminLogin } from 'src/redux/api';
import {
  adminLoginSuccess,
  adminLoginFailure,
  adminLogoutSuccess,
  adminLogoutFailure,
} from 'src/redux/actions';

export function* watchAdminRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_LOGIN, requestAdminLogin);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_LOGOUT, requestAdminLogout);
}

function* requestAdminLogin(action) {
  try {
    const admin = yield call(adminLogin, action.payload);
    yield put(adminLoginSuccess(admin));
  } catch (e) {
    yield put(adminLoginFailure(e));
  }
}

function* requestAdminLogout() {
  try {
    yield put(adminLogoutSuccess());
  } catch (e) {
    yield put(adminLogoutFailure(e));
  }
}

import { connect } from 'react-redux';
import { 
  requestTaxStatus, 
  requestCheckPan, 
  requestSubmitPan, 
  requestGoalDetail 
} from 'src/redux/actions';

import Kyc from './kyc';

function mapStateToProps(state) {
  return {
    showKyc: !state.user.folio || !state.user.folio.folioNumber,    
    user: state.user,
    taxStatuses: state.taxStatuses,
    savingPlan: state.savingPlan,
  };
}

export default connect(mapStateToProps, {
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  requestGoalDetail
})(Kyc);

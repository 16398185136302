import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Screen, Wizard, Accordion, Loader, SavvySupport } from 'src/components';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import PanVerification from './pan-verification';
import LinkBankAccount from './link-bank-account';
import DemographicInfo from './demographic-info';
import CompleteMyKyc from './complete-my-kyc';
import Fatca from './fatca';
import styles from './_kyc.module.scss';

class Kyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeKey: 0,
      isKycVerificationPage: true,
      isFatcaVisible: false,
      isFatca: false,
    };
  }

  componentDidMount() {
    const savingGoalId = this.props.history?.location?.state?.savingGoalId;

    this.goalRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.goalSuccessSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.goalFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (!this.props.showKyc) {
      this.props.history.push('/');
    }

    if (savingGoalId) {
      this.props.requestGoalDetail({ id: savingGoalId });
    }
  }

  componentWillUnmount() {
    this.goalRequestSubscription();
    this.goalSuccessSubscription();
    this.goalFailureSubscription();
  }

  _onChange = (activeKey) => {
    console.log('activeKeyState', this.state.activeKey);
    if (this.state.activeKey === activeKey) {
      this.setState({ activeKey: '' });
    } else {
      this.setState({ activeKey });
    }
  };

  _onPanVerificationComplete = ({ isFatcaVisible, isFatca } = {}) => {
    if (isFatcaVisible) {
      this.setState({ activeKey: 1, isFatcaVisible: true, isFatca });
    } else {
      this.setState({ activeKey: 2 });
    }
  };

  _onFatchaComplete = () => {
    this.setState({ activeKey: 2 });
  };

  _onLinkingBankComplete = () => {
    this.setState({ activeKey: 3 });
  };

  _handleIsFatcaVisible = () => {
    this.setState({ isFatcaVisible: true });
  };

  closeIsKycVerificationPage = () => {
    this.setState({ isKycVerificationPage: false });
  };

  render() {    
    const { isKycVerificationPage, isFatcaVisible, isFatca } = this.state;    

    return (
      <Screen>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <Wizard progress={50} />
          </Col>
        </Row>
        <Row>
          {isKycVerificationPage ? (
            <CompleteMyKyc closeIsKycVerificationPage={this.closeIsKycVerificationPage} />
          ) : (
            <Col xs={12}>
              <Accordion
                activeKey={this.state.activeKey}
                onChange={this._onChange}
                list={[
                  {
                    isVisible: true,
                    title: 'Pan Verification',
                    className: styles['pan-verification'],
                    component: (
                      <PanVerification
                        history={this.props.history}
                        onComplete={this._onPanVerificationComplete}
                      />
                    ),
                  },
                  {
                    isVisible: isFatcaVisible,
                    title: 'FATCA (Foreign Account Tax Compliance Act)',
                    className: styles['fatcha'],
                    component:
                      this.state.activeKey === 1 ? (
                        <Fatca isFatca={isFatca} onComplete={this._onFatchaComplete} />
                      ) : null,
                  },
                  {
                    isVisible: true,
                    title: 'Link Bank Account',
                    className: styles['bank-account'],
                    component:
                      this.state.activeKey === 2 ? (
                        <LinkBankAccount onComplete={this._onLinkingBankComplete} />
                      ) : null,
                  },
                  {
                    isVisible: true,
                    title: 'Demographic Information',
                    className: styles['demographic-info'],
                    component: this.state.activeKey === 3 ? <DemographicInfo /> : null,
                  },
                ]}
              />
            </Col>
          )}
        </Row>
        <Loader loading={this.state.loading} />
        <SavvySupport />
      </Screen>
    );
  }
}

export default withRouter(Kyc);

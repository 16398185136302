import { useField } from 'formik';
import React from 'react';
import cx from 'classnames';
import { Label } from 'src/components/theme';
import { TextOne } from '../../text-one';
import TextInput from '../../text-input';
import styles from './_textinputone.module.scss';
import globalStyles from '../_styles.module.scss';

const TextInputOne = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { labelsize } = props || {};
  const isAutoCompleteOff = props?.isAutoComplete ? 'on' : 'off';
  return (
    <>
      <div style={{ ...props.containerstyles }}>
        {label && label != '' && (
          <div className={styles.inputLabelContainer}>
            <Label
              htmlFor={props.id || props.name}
              className={cx(styles.inputLabel, props.labelstyles, {
                [styles.largeLabel]: labelsize === 'large',
              })}
            >
              <TextOne>{label}</TextOne>
            </Label>
          </div>
        )}
        <div className={cx(styles.textInputContainer, props?.textInputContainerStyles)}>
          <div className={styles.textFieldContainer}>
            {props?.prefix && <span className={styles.prefix}>{props.prefix}</span>}
            <TextInput {...field} {...props} autoComplete={isAutoCompleteOff}/>
            {props?.suffix && <span className={styles.suffix}>{props.suffix}</span>}
          </div>
          {meta.touched && meta.error ? (
            <div className={globalStyles.error}>{meta.error}</div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TextInputOne;

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function fetchEmailTemplates() {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: API_END_POINTS.EMAIL_TEMPLATES,
  }).then((response) => response.data);
}

export function generateEmailCode(params) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.EMAIL_TEMPLATES,
    data: params,
  }).then((response) => response.data);
}
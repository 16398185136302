import { faChevronLeft, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import CircleIcon from 'src/components/circle-icon';
import ListItem from 'src/components/list-item';
import styles from './_sidenavigation.module.scss';

const SideNavigation = (props) => {
  const admin = useSelector((state) => state.admin);
  const menuItems = [
    {
      id: 1,
      name: 'Products',
      toggleKey: '/admin/products',
      values: [
        {
          id: 11,
          name: 'Product Catalogue',
          nav: '/admin/products',
        },
        {
          id: 12,
          name: 'Product Offers',
          nav: '/admin/products/offers',
        },
      ],
    },
    {
      id: 2,
      name: 'User Data',
      toggleKey: '/admin/users',
      nav: '/admin/users'
    },
    {
      id: 4,
      name: 'Transactions',
      toggleKey: '/admin/transactions',
      nav: '/admin/transactions',
    },
    {
      id: 5,
      name: 'Purchases',
      toggleKey: '/admin/purchases',
      nav: '/admin/purchases'
    },
    {
      id: 6,
      name: 'Payment links',
      toggleKey: '/admin/payment_links',
      nav: '/admin/payment_links',
    },
    {
      id: 4,
      name: 'Collect Requests',
      toggleKey: '/admin/collect-requests',
      nav: '/admin/collect-requests',
    },
    {
      id: 5,
      name: 'Marketing',
      toggleKey: '/admin/marketing',
      values: [
        {
          id: 13,
          name: 'Email Templates',
          nav: '/admin/marketing/email-templates',
        }
      ],
    },
  ];

  return (
    <>
      <div className={styles.titleContainer}>
        <button
          className={`${styles.menuCollapseButton}`}
          onClick={() => props.handleSidebarToggle()}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span className={styles.title} title={admin.partner?.name}>
          {admin.partner?.name}{' '}
        </span>
      </div>
      <ul className={styles.menu}>
        {menuItems &&
          menuItems.length > 0 &&
          menuItems.map((i, index) => <ListItem item={i} key={index} />)}
      </ul>
      <div className={styles.footerContainer}>
        {/* <NavDropdown
          className={styles.userMenu}
          drop="up"
          title={
            <>
              <CircleIcon styles={{ backgroundColor: '#fff', marginRight: '1rem' }}>
                <FontAwesomeIcon icon={faUser} className={styles.icon} />
              </CircleIcon>
              <span>{admin.email ? admin.email : null}</span>
            </>
          }
          style={{
            position: 'absolute',
            inset: 'auto auto 0px 0px',
            margin: '0px',
            transform: 'translate3d(0px, -45.2188px, 0px)',
            width: '100%',
            borderRadius: '0',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            padding: '0 0.5rem'}
          }
        >
          {admin?.isAdmin && 
            <>
              <NavDropdown.Item as={Link} to="/admin/profile">
                Edit Profile
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/admin/agent">
                Add Sales Agent
              </NavDropdown.Item>
            </>
          }
          <NavDropdown.Item as={Link} to="/admin/logout">
            Logout
          </NavDropdown.Item>
        </NavDropdown> */}
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-button-dark-example1"
            variant=""
            className={styles.footerMenuToggle}
          >
            <div className={styles.circleBox}>
              <FontAwesomeIcon icon={faUser} className={styles.icon} />
            </div>
            <div className={styles.displayEmail}>
              {admin?.email ? admin?.email.substr(0, admin.email.indexOf('@')) : null}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu variant="" className={styles.footerMenu}>
            {admin?.isAdmin && (
              <>
                <Dropdown.Item as={Link} to="/admin/profile">
                  Edit Business Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/agent">
                  Add Sales Agent
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item as={Link} to="/admin/logout">
              Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default SideNavigation;

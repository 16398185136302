import React, { Component } from 'react';
import { ButtonOne, Loader } from 'src/components';
import EventBus from 'eventing-bus';
import moment from 'moment';
import { Convert } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import { Badge } from 'src/components/theme';
import { Container, ModalPaymentLinkDelete } from '../../components';
import { InfoBlock } from '../../components/info-block';
import styles from './_payment-link-details.module.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

export class PaymentLinknDetails extends Component {
  state = {
    loading: false,
    showModal: false,
    deleted: false,
  };

  componentDidMount() {
    const { requestAdminOfferDetail, match } = this.props;

    this.requestAdminOfferDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.adminOfferDetailSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_OFFER_DETAIL_SUCCESS,
      this._computePaymentsData,
    );
    this.adminOfferDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFER_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );
    this.requestAdminOfferDelete = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_DELETE, () =>
      this.setState({ loading: true }),
    );
    this.adminOfferDeleteSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_OFFER_DELETE_SUCCESS,
      this._handleDeleteSuccess,
    );
    this.adminOfferDeleteFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFER_DELETE_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (match?.params?.uuid) requestAdminOfferDetail({ uuid: match?.params?.uuid });
  }

  componentWillUnmount() {
    this.requestAdminOfferDetail();
    this.adminOfferDetailSuccess();
    this.adminOfferDetailFailure();

    this.requestAdminOfferDelete();
    this.adminOfferDeleteSuccess();
    this.adminOfferDeleteFailure();
  }

  // check if payment has been made for respective date
  _upfrontPaymentStatus = () => {
    const { savingGoals = [] } = this.props?.productOffer;

    if (!savingGoals.length) {
      return false;
    }

    const { payments = [] } = savingGoals[0];

    const payment = payments.find((p) => p.status == 'completed');

    return payment;
  };

  _computePaymentsData = () => {
    const {
      paymentLinks,
      paymentCalendar,
      productOfferName,
      discountedAmount,
      upfrontPaymentAmount,
    } = this.props?.productOffer;

    // organize paymentlinks & user data
    let paymentLinksData = {};
    let userData = {};

    if (paymentLinks.length) {
      const paymentLink = paymentLinks[0];
      const { createdAt, expiry, shortLink, sentToEmail, sentToPhoneNumber, sentToName } =
        paymentLink;

      paymentLinksData = {
        createdAt: createdAt ? moment(createdAt, 'DDMMYYYY').format('Do MMMM, YYYY') : '',
        expiry: expiry ? moment(expiry).format('Do MMMM, YYYY') : '',
        shortLink: API_ENDPOINT_URL + shortLink,
      };

      if (sentToEmail || sentToPhoneNumber || sentToName) {
        userData = {
          sentToEmail,
          sentToPhoneNumber,
          sentToName,
        };
      }
    }

    // get total value of offer
    // let totalValue = Object.values(paymentCalendar).reduce((acc, val) => acc + Number(val), 0)

    // booking payment data
    const paymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY') - moment(b, 'DDMMYYYY'),
    );
    const bookingPaymentStatus = this._upfrontPaymentStatus();
    const paymentCalendarDate = bookingPaymentStatus
      ? moment(bookingPaymentStatus.createdAt, 'DD/MM/YYYY').format('Do MMMM, YYYY')
      : moment(paymentCalendarDates[0], 'DDMMYYYY').format('Do MMMM, YYYY');

    const bookingPaymentData = {
      ...(bookingPaymentStatus
        ? { 'Paid on': paymentCalendarDate }
        : { 'Payment Date': paymentCalendarDate }),
      Amount: Convert.toCurrencyFormat(upfrontPaymentAmount),
    };

    // upcoming payment data
    const upcomingDate = paymentCalendarDates.find(
      (date) => moment(date, 'DDMMYYYY').diff(moment(), 'days') > 1,
    );
    let upcomingPaymentData = {};
    if (upcomingDate) {
      upcomingPaymentData = {
        date: moment(upcomingDate, 'DDMMYYYY').format('Do MMMM, YYYY'),
        value: Convert.toCurrencyValue(paymentCalendar[upcomingDate]),
      };
    }

    // payment link status
    let status = this._getStatus();

    this.setState({
      loading: false,
      data: {
        ...this.state.data,
        productOfferName,
        paymentLinksData,
        userData,
        totalValue: discountedAmount,
        bookingPaymentData,
        upcomingPaymentData,
        status,
      },
    });
  };

  _getStatus = () => {
    const { paymentLinks, savingGoals } = this.props?.productOffer;

    let status = '';

    if (paymentLinks.length) {
      const paymentLink = paymentLinks[0];
      const { expiry } = paymentLink;

      status = 'created';

      // check if link has expired
      const isExpired = moment().diff(moment(expiry), 'milliseconds') > 0;

      if (isExpired) {
        status = 'expired';
        return status;
      }

      if (savingGoals.length) {
        const { isPaymentMade, kycd, mandate, status: statusFromData } = savingGoals[0];

        if (statusFromData == 'completed') {
          return 'completed';
        }

        if (isPaymentMade) {
          status = kycd && mandate ? 'ongoing' : 'booked';
        }
      }
    }

    return status;
  };

  _handleDelete = () => {
    const { requestAdminOfferDelete, match } = this.props;
    requestAdminOfferDelete({ uuid: match?.params?.uuid });
  };

  _handleDeleteSuccess = () => {
    const { history } = this.props;
    this.setState(
      {
        loading: false,
        deleted: true,
      },
      () => {
        setTimeout(() => history.push('/admin'), 1000);
      },
    );
  };

  _handleEdit = async () => {
    const { match, history } = this.props;
    // requestAdminOfferCopy({uuid: match?.params?.uuid})
    // await Promise.resolve()
    history.push(`/admin/paymentlink/edit/${match?.params?.uuid}`);
  };

  _handleDone = () => {
    this.props.history.push('/admin');
  };

  _handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const { loading, data = {} } = this.state;

    return (
      <Container
        title="Payment link details"
        buttonText="create a new link"
        className={styles.container}
        rightContent={
          <>
            <ButtonOne
              text="Edit"
              styles={{ marginRight: '1rem', minWidth: '7rem' }}
              onClick={this._handleEdit}
            />
            <ButtonOne text="Delete" styles={{ minWidth: '7rem' }} onClick={this._handleModal} />
          </>
        }
      >
        <InfoBlock
          data={{
            'Offer Name': data?.productOfferName || '',
            'Payment Link': data?.paymentLinksData?.shortLink,
          }}
          buttonComponent={
            data?.status && (
              <Badge variant={data?.status} className="badge-style">
                {data?.status}
              </Badge>
            )
          }
        />
        {data?.paymentLinksData && Object.keys(data?.paymentLinksData).length > 0 && (
          <InfoBlock
            title="Overview"
            data={{
              Price: data?.totalValue && Convert.toCurrencyValue(data?.totalValue),
              'Type of Link': 'Specific',
              'Created on': data?.paymentLinksData?.createdAt,
              'Expires on': data?.paymentLinksData?.expiry,
            }}
          />
        )}
        {data?.bookingPaymentData?.Amount && (
          <InfoBlock
            title="Payment Details"
            data={{
              'Type of Payment': 'Booking',
              ...data?.bookingPaymentData,
            }}
          />
        )}
        {data?.upcomingPaymentData?.date && (
          <InfoBlock
            title="Upcoming"
            data={{
              'Next Payment on': data?.upcomingPaymentData?.date,
              // 'Type of Payment': 'Upcoming',
              Amount: data?.upcomingPaymentData?.value,
            }}
          />
        )}
        {data?.userData && Object.keys(data?.userData).length > 0 && (
          <InfoBlock
            title="User Information"
            data={{
              Name: data?.userData?.sentToName || '',
              Email: data?.userData?.sentToEmail || '',
              'Phone Number': data?.userData?.sentToPhoneNumber || '',
            }}
          />
        )}
        <div className={styles.buttonContainer}>
          <ButtonOne text="Done" onClick={this._handleDone} />
        </div>
        <Loader loading={loading} />
        {!this.state.loading && (
          <ModalPaymentLinkDelete
            show={this.state.showModal}
            offerName={this.props?.productOffer?.productOfferName}
            handleModal={this._handleModal}
            handleDelete={this._handleDelete}
            deleted={this.state?.deleted}
            loading={this.state.loading}
          />
        )}
      </Container>
    );
  }
}

export default PaymentLinknDetails;

import { requestAdminOfferDetail, requestAdminOfferDelete } from "src/redux/actions";
import { connect } from "react-redux";
import PaymentLinknDetails from "./PaymentLinknDetails";

const mapStateToProps = (state) => ({
  productOffer: state.adminOffers.productOffer,
})

export default connect(mapStateToProps, {
  requestAdminOfferDetail,
  requestAdminOfferDelete,
})(PaymentLinknDetails)


import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import moment from 'moment';
import { Convert } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import { Screen, Wizard, Loader, SavvySupport } from 'src/components';
import styles from './_setup-sip.module.scss';

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
const DIGIO_ENDPOINT_URL = IS_PRODUCTION ? 'https://app.digio.in' : 'https://ext.digio.in';

class SetupSip extends Component {
  state = {
    transactionId: 0,
    loading: false,
    choosenDate: 5,
  };

  componentDidMount() {
    const savingGoalId = this.props.history?.location?.state?.savingGoalId;

    this.requestCreateMandate = EventBus.on(ACTION_TYPES.REQUEST_CREATE_MANDATE, () =>
      this.setState({ loading: true }),
    );
    this.createMandateSuccess = EventBus.on(
      ACTION_TYPES.CREATE_MANDATE_SUCCESS,
      this._createMandateSuccess,
    );
    this.createMandateFailure = EventBus.on(ACTION_TYPES.CREATE_MANDATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.goalRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.goalSuccessSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.goalFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (savingGoalId) {
      this.props.requestGoalDetail({ id: savingGoalId });
    }
  }

  componentWillUnmount() {
    this.requestCreateMandate();
    this.createMandateSuccess();
    this.createMandateFailure();

    this.goalRequestSubscription();
    this.goalSuccessSubscription();
    this.goalFailureSubscription();
  }

  _createMandateSuccess = (mandate) => {
    const { transactionId } = this.state;
    const { origin } = document.location;
    const redirectUrl = `${origin}/sip-redirect`;
    const mandateUrl = `${DIGIO_ENDPOINT_URL}/#/enach-mandate-direct/${mandate.mandateId}/${transactionId}/${mandate.userId}?token_id=${mandate.accessTokenId}&redirect_url=${redirectUrl}`;
    window.location.href = mandateUrl;
  };

  _setUpMandate = () => {
    const { savingPlan } = this.props;
    const { choosenDate } = this.state;
    const { id, goalAbsoluteAmount, startDate: start_date, paymentCalendar = {} } = savingPlan;
    const goalAmount = Number(goalAbsoluteAmount);
    const amount = goalAmount.toFixed(2);
    const transactionId = `${id}${new Date().valueOf()}`;

    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );

    let finalEndDate = '';
    if (sortedPaymentCalendarDates && sortedPaymentCalendarDates.length > 0) {
      const lastInstallmentDate = sortedPaymentCalendarDates[sortedPaymentCalendarDates.length - 1];
      finalEndDate = moment(lastInstallmentDate, 'DDMMYYYY').add(31, 'days').format('YYYY-MM-DD');
    }

    const mandate = {
      mandate_day: choosenDate,
      amount: goalAbsoluteAmount,
      start_date,
      end_date: finalEndDate,
      frequency: 'DAILY',
      partner_transaction_id: transactionId,
      saving_goal_id: id,
    };

    this.props.updateSavingPlan({ ...savingPlan, mandate });
    this.setState({ transactionId }, () => {
      if (IS_PRODUCTION) {
        this.props.requestCreateMandate({
          ...mandate,
          amount,
          end_date: finalEndDate,
        });
      } else {
        this.props.history.push('/sip-redirect?status=success');
      }
    });
  };

  _getOrdinal(n) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  handleChoosenDate = (choosenDate) => {
    this.setState({ choosenDate });
  };

  render() {
    const { savingPlan } = this.props;
    // const { choosenDate } = this.state;
    const { paymentCalendar = {}, unsplashImage, productOffer } = savingPlan;
    const paymentCalendarKey = Object.keys(paymentCalendar);
    const sortedPaymentCalendarDates = paymentCalendarKey.sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const monthlyPaymentKey = sortedPaymentCalendarDates[1];
    const monthlyPaymentAmount = paymentCalendar[monthlyPaymentKey];
    const totalMonths = paymentCalendarKey.length > 0 ? paymentCalendarKey.length - 1 : 0;

    return (
      <Screen>
        <div className={styles['setup-sip']}>
          <Row className="justify-content-md-center">
            <Col lg="9">
              <Wizard progress={67} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className={styles['product-image-wrapper']}>
                <div
                  className={styles['product-image']}
                  style={{ backgroundImage: `url(${unsplashImage?.link})` }}
                />
                <div className={styles['product-info']}>
                  <h2>{Convert.currency(savingPlan?.goalAbsoluteAmount || 0)}</h2>
                  <h4>Remaining amount to be saved</h4>
                  <p>{productOffer?.partner?.name}</p>
                </div>
              </div>
              <div className="text-center">
                <h4 className={styles['sip-title']}>Set up your Savings plan</h4>
                <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Monthly Investment: </div>
                  <div className={styles['right']}>
                    {Convert.currency(monthlyPaymentAmount || 0)}
                  </div>
                </div>
                <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Length of SIP: </div>
                  <div className={styles['right']}>{totalMonths} Months</div>
                </div>
                {/* <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Choose a date</div>
                  <div className={styles['right']}>
                    <Dropdown className={styles['main-dropdown']}>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        {this._getOrdinal(choosenDate)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={styles['dropdown-menu']}>
                        {new Array(31).fill(0).map((d, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => this.handleChoosenDate(index + 1)}
                          >
                            {this._getOrdinal(index + 1)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div> */}
                {/* <p className={styles['manage-account']}> */}
                {/* Your can redeem your investment anytime you need to by logging into your account */}
                {/* </p> */}
                <p className={styles['invested-in']}>
                  I agree that YSTPL will transfer money, using a nodal mandate,
                  <br />
                  (a) from my bank account as per the schedule above towards my investments and
                  <br /> (b) to the merchant for my purchases
                  {/*                   
                  The money you are saving will be invested in{' '}
                  <a
                    href="https://individuals.savvyapp.in/fund-performance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ICICI Prudential Liquid Fund
                  </a> */}
                </p>
                <Button onClick={this._setUpMandate} className={styles['setup-auto-debit']}>
                  SETUP AUTO SAVE
                </Button>
              </div>
            </Col>
          </Row>
          <Loader loading={this.state.loading} />
        </div>
        <SavvySupport />
      </Screen>
    );
  }
}

export default withRouter(SetupSip);

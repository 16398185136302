import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';

import styles from './_bank-account-input.module.scss';

class BankAccountInput extends Component {
  _onChangeAccountNumber = (e) => {
    this.props.onAccountNumberChange(e.target.value);
  };

  _onChangeIFSCCode = (e) => {
    const ifscCode = (e.target.value || '').toUpperCase();
    this.props.onIFSCCodeChange(ifscCode);
  };

  _onClick = () => {
    this.props.onFetchOTM && this.props.onFetchOTM();
  };

  render() {
    return (
      <div className={styles['bank-account-input']}>
        <Row>
          <Col xs={12} lg={5}>
            <div className={styles['ba-group-input']}>
              <div>
                <label>Enter you bank account number</label>
              </div>
              <InputMask
                className={styles['ba-input']}
                placeholder="099XXXXXXXXX90"
                value={this.props.accountNumber}
                onChange={this._onChangeAccountNumber}
                mask="99999999999999999999"
                maskChar={null}
                disabled={this.props.disabled}
              />
            </div>
          </Col>
          <Col xs={12} lg={5}>
            <div className={styles['ba-group-input']}>
              <div>
                <label>IFSC Code</label>
              </div>
              <InputMask
                className={styles['ba-input']}
                placeholder="HDFC0000001"
                value={this.props.ifscCode}
                onChange={this._onChangeIFSCCode}
                mask="aaaa*******"
                maskChar={null}
                disabled={this.props.disabled}
              />
            </div>
          </Col>
        </Row>

        <button className={styles['link-account']} onClick={this._onClick}>
          Try getting PAN-linked bank account
        </button>
      </div>
    );
  }
}

export default BankAccountInput;

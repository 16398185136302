import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import moment from 'moment';
import { Loader, SavvySupport } from 'src/components';
// import { Convert } from 'src/helpers';
import styles from './sip-success.module.scss';
import OfferBox from '../pay/offer-box';
// import ExtraInfo from '../extra-info';

class SipSuccess extends Component {
  state = {
    loading: false,
    // isShowSavvyPayModal: true,
    // payment: {},
  };

  _onDashboard = () => {
    this.props.history.push('/dashboard');
  };

  _onGoal = () => {
    const { savingPlan } = this.props;
    this.props.history.push(`/dashboard/progress/${savingPlan?.id}`);
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user, savingPlan } = this.props;
    // const { paymentCalendar, mandate } = savingPlan || {};
    // const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
    //   (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    // );
    // const monthlyPaymentKey = sortedPaymentCalendarDates[1];
    // const monthlyPaymentAmount = paymentCalendar[monthlyPaymentKey];

    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={styles['pay-content']}>
              {/* <div className={styles['title']}>Your SIP has been setup</div> */}
              <div className={styles['title']}>Your mandate has been setup!</div>
              <div className={styles['tick']}>
                <img src="/img/tick-2.svg" alt="tick" />
              </div>

              {/* <div className={styles['message']}>
                Amount : {Convert.currency(monthlyPaymentAmount)} /month
                <br /> Starting on: {mandate?.start_date}
                <br /> Ending on: {mandate?.end_date}
                <br />{' '}
                <div className={styles['transaction']}>
                  Transaction ID: {mandate?.partner_transaction_id}
                </div>
              </div> */}
              {/* <div className={styles['message']}>
                {Convert.currency(monthlyPaymentAmount)} will be saved on the {mandate?.start_date}{' '}
                of each month <br />
                until {mandate?.end_date}
              </div> */}
            </div>
            {savingPlan?.id ? (
              <div className={styles['footer']} onClick={this._onGoal}>
                GO TO DASHBOARD
              </div>
            ) : (
              <div className={styles['footer']} onClick={this._onDashboard}>
                GO TO DASHBOARD
              </div>
            )}
          </div>
          <div className={styles['right-box']}>
            <OfferBox
              partner={partner}
              product={product}
              user={user}
              onProceedPayment={this._onProceedPayment}
            />
          </div>
        </div>
        {/* <ExtraInfo /> */}
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(SipSuccess);

import React from 'react'
import cx from 'classnames'
import styles from './_buttonmain.module.scss'

export default function ButtonMain(props) {
  const {variant = 'primary',node = 'button'} = props

  let element = <></>

  switch (node) {
    case 'button':{
      element = (
      <button
        {...props}
        className={cx(styles[variant], props?.className)}
      >
        {props.children}
      </button>
    )
    break;
    }
    
    case 'span':{
      element = (<span
          {...props}
          className={cx(styles[variant], props?.className)}
          style={{cursor: 'pointer'}}
        >
          {props.children}
        </span>
        )
      break;
      }

    case 'label':{
      element = (<label
          {...props}
          className={cx(styles[variant], props?.className)}
        >
          {props.children}
        </label>)
        break;
      }
  
    default:
      break;
  }

  return (
    <div 
      className={cx(styles.container, props?.containerstyles)}
      style={{
        justifyContent: props?.align || 'flex-start',
        display: props?.align && 'flex',
        ...props?.containerstyles
      }}
    >
      {element}
    </div>
  )
}

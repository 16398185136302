import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import CustomCardOne from 'src/components/cards/custom-card-one';
import InputSelectOne from 'src/components/form-inputs/input-select-one';
import TextInputOne from 'src/components/form-inputs/textinput-one';
import BorderedHeader from 'src/components/headings/bordered-header';
import { ACTION_TYPES } from 'src/constants';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { ButtonMain, Heading } from 'src/components/theme';
import styles from './_generatepaymentlink.module.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class GeneratePaymentLink extends Component {
  shortenedUrl = null;

  state = {
    shortenedUrl: '',
    linkCopied: false,
    loading: false,
  };

  componentDidMount() {
    this.requestAdminOffersList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminOffersListSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminOffersListFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.generateUrlSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL, () =>
      this.setState({ loading: true }),
    );
    this.generateUrlSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SHORTEN_URL_SUCCESS,
      this._onUrlGenerated,
    );
    this.generateUrlFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminOffersList();
  }

  componentWillUnmount() {
    this.requestAdminOffersList();
    this.adminOffersListSuccess();
    this.adminOffersListFailure();

    this.generateUrlSubscription();
    this.generateUrlSuccessSubscription();
    this.generateUrlFailureSubscription();
  }

  _onGeneratePaymentLink = (values, { setSubmitting }) => {
    const { origin } = document.location;
    const { adminCredentials } = this.props;
    const nameSplit = values.nameOfUser.split(' ');
    const firstName = nameSplit[0];
    const lastName = nameSplit[1] || '';
    const url = `${origin}?partnerAccessKey=${adminCredentials.partnerAccessKey}&productOfferId=${values.offerUuid}&firstName=${firstName}&lastName=${lastName}&email=${values.sendLinkEmail}&phoneNumber=+91${values.userPhoneNumber}`;
    this.props.requestShortenUrl({ url });
    setSubmitting(false);
  };

  _onUrlGenerated = (response) => {
    const { alias = '' } = response || {};
    const urlId = alias.substr(alias.indexOf('?id=') + 4);
    const shortenedUrl = `${API_ENDPOINT_URL}?id=${urlId}`;
    this.setState({ loading: false, shortenedUrl });
  };

  _copyClipboard = (e) => {
    this.shortenedUrl.select();
    this.shortenedUrl.setSelectionRange(0, 99999);

    document.execCommand('copy');
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 2000);
    e.preventDefault();
  };

  _validationSchema = yup.object().shape({
    offerUuid: yup.string().required('Please choose an offer'),
    nameOfUser: yup.string().required('Please provide name of the user.'),
    sendLinkEmail: yup.string().email().required(),
    userPhoneNumber: yup
      .string()
      .required()
      .matches(/^[6-9]\d{9}$/, {
        message: 'Phone number not valid',
      }),
  });

  render() {
    return (
      <Container className={styles.container}>
        <Heading variant="sub-heading">Generate New Payment Link</Heading>
        <Formik
          initialValues={{
            offerUuid: '',
            offer: {},
            nameOfUser: '',
            sendLinkEmail: '',
            userPhoneNumber: '',
          }}
          validationSchema={this._validationSchema}
          onSubmit={this._onGeneratePaymentLink}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Card className={styles.card}>
                <Card.Header className={styles.cardHeader}>
                  <span className={styles.cardHeading}>Payment Details</span>
                </Card.Header>
                <Card.Body className={styles.cardBody}>
                  <Row className={styles.cardSection} style={{ padding: '0 1rem' }}>
                    <Col>
                      <InputSelectOne
                        label="Select the offer you would like to generate a payment link"
                        name="offerUuid"
                        id="offerUuid"
                        onChange={(e) => {
                          const offerItem = this.props.offers.find(
                            (offer) => offer.uuid === e.target.value,
                          );
                          setFieldValue('offer', offerItem || '');
                          setFieldValue('offerUuid', offerItem ? offerItem?.uuid : '');
                        }}
                      >
                        <option value="">Choose a offer</option>
                        {this.props?.offers &&
                          this.props.offers.length > 0 &&
                          this.props.offers.map((offer) => (
                            <option key={offer.uuid} value={offer.uuid}>
                              {offer.productOfferName}
                            </option>
                          ))}
                      </InputSelectOne>
                    </Col>
                  </Row>
                  {values.offer.uuid && (
                    <div className={styles.cardSection}>
                      <BorderedHeader
                        heading="Offer Details"
                        fontSize="1rem"
                        borderColor="#a4a4a4"
                        styles={{
                          fontWeight: 600,
                          paddingLeft: '1.5rem',
                        }}
                      />
                      <CustomCardOne
                        cardHeading="Offer description"
                        title={values.offer.productOfferName}
                        imgSrc={values.offer.product.imageLink}
                        price={values.offer.product.amount}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              (values?.offer?.description &&
                                values?.offer?.description &&
                                stateToHTML(
                                  convertFromRaw(JSON.parse(values?.offer?.description)),
                                )) ||
                              '',
                          }}
                        />
                      </CustomCardOne>
                    </div>
                  )}
                </Card.Body>
              </Card>
              <Card className={styles.card}>
                <Card.Header className={styles.cardHeader}>
                  <span className={styles.cardHeading}>User Details</span>
                </Card.Header>
                <Card.Body>
                  <Row className={styles.cardRow}>
                    <Col md={6} className={styles.cardCol}>
                      <TextInputOne label="Name of User" name="nameOfUser" type="text" />
                    </Col>
                    <Col md={6}>
                      <TextInputOne
                        label="Email ID to send link to"
                        name="sendLinkEmail"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className={styles.cardCol}>
                      <TextInputOne
                        label="Use Phone Number"
                        name="userPhoneNumber"
                        type="number"
                        maxlength={10}
                      />
                    </Col>
                    {/* <Col md={6} className={styles.cardCol}>
                      <InputSelectOne label="Agent ID" name="agentId">
                        <option>Open this select menu</option>
                        <option value="1">John Doe</option>
                        <option value="2">Raymond</option>
                        <option value="3">Jenifer</option>
                      </InputSelectOne>
                    </Col> */}
                  </Row>
                  <div className={styles.urlContainer}>
                    {this.state.shortenedUrl ? (
                      <>
                        <input
                          className={styles.urlInput}
                          ref={(ref) => (this.shortenedUrl = ref)}
                          value={this.state.shortenedUrl}
                          readOnly
                        />
                        <button className={styles.clipboard} onClick={this._copyClipboard}>
                          <FontAwesomeIcon icon={faClipboard} />
                        </button>
                        {this.state.linkCopied && (
                          <div className={styles.copied}>Link Copied to clipboard</div>
                        )}
                      </>
                    ) : null}
                  </div>
                  <ButtonMain type="submit">Generate Payment Link</ButtonMain>
                </Card.Body>
              </Card>
            </Form>
          )}
        </Formik>
        <Loader loading={this.state.loading} />
      </Container>
    );
  }
}

export default GeneratePaymentLink;

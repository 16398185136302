import { connect } from 'react-redux';
import {
  requestAdminCollectionsAccept,
  requestUserCollectionsDetail,
  requestAdminCollectionsConfirm,
} from 'src/redux/actions';
import CollectMoney from './collect-money';

function mapStateToProps(state) {
  return {
    user: state.user,
    collectionDetail: state?.adminCollections?.userCollectionDetail,
    collectionAccept: state?.adminCollections?.collectionAccept,
  };
}

export default connect(mapStateToProps, {
  requestUserCollectionsDetail,
  requestAdminCollectionsAccept,
  requestAdminCollectionsConfirm,
})(CollectMoney);

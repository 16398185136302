import { connect } from 'react-redux';
import {
  updateFolio,
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  fatchaRequest,
  countriesRequest,
  identificationTypeRequest,
  requestOccupationList,
  sourcesOfWealthRequest,
  fatchaFormSuccess,
} from 'src/redux/actions';

import Fatca from './fatca';

function mapStateToProps(state) {
  return {
    user: state.user,
    folio: state.folio,
    taxStatuses: state.taxStatuses,
    form: state?.kyc?.form || {},
  };
}

const mapDispatchToProps = (dispatch) => ({
  fatchaFormSuccess: (data) => dispatch(fatchaFormSuccess(data)),
  updateFolio: (data) => dispatch(updateFolio(data)),
  requestTaxStatus: (data) => dispatch(requestTaxStatus(data)),
  requestCheckPan: (data) => dispatch(requestCheckPan(data)),
  requestSubmitPan: (data) => dispatch(requestSubmitPan(data)),
  fatchaRequest: (data) => dispatch(fatchaRequest(data)),
  countriesRequest: (data) => dispatch(countriesRequest(data)),
  identificationTypeRequest: (data) => dispatch(identificationTypeRequest(data)),
  requestOccupationList: (data) => dispatch(requestOccupationList(data)),
  sourcesOfWealthRequest: (data) => dispatch(sourcesOfWealthRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fatca);

import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  requestPaymentLinksSuccess,
  requestPaymentLinksFailed,
  requestPaymentLinksDetailSuccess,
  requestPaymentLinksDetailFailed,
  requestPaymentExpireLinkSuccess,
  requestPaymentExpireLinkFailed,
} from '../actions';
import { adminPaymentLinks, adminPaymentLinksDetail, adminPaymentExpireLink } from '../api';

export function* watchAdminPaymentsRequests() {
  yield takeEvery(ACTION_TYPES.PAYMENT_LINKS_REQUEST, requestPaymentLinks);
  yield takeEvery(ACTION_TYPES.PAYMENT_LINKS_DETAIL_REQUEST, requestPaymentLinksDetail);
  yield takeEvery(ACTION_TYPES.PAYMENT_EXPIRE_LINK_REQUEST, requestPaymentExpireLink);
}

function* requestPaymentLinks(action) {
  try {
    const payments = yield call(adminPaymentLinks, action.payload);
    yield put(requestPaymentLinksSuccess(payments));
  } catch (e) {
    yield put(requestPaymentLinksFailed(e));
  }
}

function* requestPaymentLinksDetail(action) {
  try {
    const payments = yield call(adminPaymentLinksDetail, action.payload);
    yield put(requestPaymentLinksDetailSuccess(payments?.paymentLink));
  } catch (e) {
    yield put(requestPaymentLinksDetailFailed(e));
  }
}

function* requestPaymentExpireLink(action) {
  try {
    const payment = yield call(adminPaymentExpireLink, action.payload);
    yield put(requestPaymentExpireLinkSuccess(payment));
  } catch (e) {
    yield put(requestPaymentExpireLinkFailed(e));
  }
}

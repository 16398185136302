import React from 'react';
import { useAccordionToggle } from 'react-bootstrap';

function CustomAccordionToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => () => {});

  return (
    <button type="button" style={{ backgroundColor: 'pink' }} onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

export default CustomAccordionToggle;

import { connect } from 'react-redux';
import {
  requestAdminOfferCreate,
  requestAdminOfferUpdate,
  requestAdminProductList,
  requestAdminProductListByName,
} from 'src/redux/actions';
import OfferDetailsEdit from './OfferDetailsEdit';

function mapStateToProps(state) {
  return {
    products: state.adminProducts.products,
  };
}
export default connect(mapStateToProps, {
  requestAdminOfferUpdate,
  requestAdminOfferCreate,
  requestAdminProductList,
  requestAdminProductListByName,
})(OfferDetailsEdit);

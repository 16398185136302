import { connect } from 'react-redux';
import Admin from './admin';

function mapStateToProps(state) {
  return {
    user: state.user,
    credentials: state.credentials,
  };
}

export default connect(mapStateToProps, {})(Admin);

import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

/*
* Gets list of transactions for user dashboard
*/
export function transactionList() {
    return ApiRequest.fetch({
        method: 'get',
        url: API_END_POINTS.TRANSACTION_LIST,
    })
    .then(response => response.data);
}

/*
* Gets detail of transaction
*/
export function transactionDetail({type,id}) {

    let url = ''

    switch (type) {
        case 'deposits':
            url = `${API_END_POINTS.DEPOSITS}/${id}`
            break;

        case 'withdrawals':
            url = `${API_END_POINTS.WITHDRAWAL_DETAIL}/${id}`
            break;
    
        default:
            url = `${API_END_POINTS.DEPOSITS}/${id}`
            break;
    }

    return ApiRequest.fetchAdmin({
        method: 'get',
        url,
    })
    .then(response => response.data);
}
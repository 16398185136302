import { requestAdminProfile, requestAdminProfileUpdate } from 'src/redux/actions';
import { connect } from 'react-redux';
import Profile from './Profile';

function mapStateToProps(state) {
  return {
    partner: state.adminPartners.partner,
    admin: state.admin,
  };
}

export default connect(mapStateToProps, {
  requestAdminProfile,
  requestAdminProfileUpdate,
})(Profile);

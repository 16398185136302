import React, { Component } from 'react';
import { Loader } from 'src/components';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import ConfirmAccount from './confirm-account';
import AnotherAccount from './another-account';
import PennyCheck from '../penny-check';
import styles from './link-account.module.scss';

class LinkAccount extends Component {
  state = {
    accountNumber: this.props.bankDetails.bankAccountNumber || '',
    ifscCode: this.props.bankDetails.ifscCode || '',
    showPennyCheck: false,
    loadingText: '',
    requestedOTMBanks: false,
    noOTMBanks: false,
    loading: false,
  };

  componentDidMount() {
    this.fullKycRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION, () =>
      this.setState({
        loading: true,
        loadingText: 'Registering details...',
      }),
    );
    this.fullKycSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_SUCCEEDED,
      this._onFullKycReceive,
    );
    this.fullKycFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_FAILED,
      () => this.setState({ loading: false }),
    );

    this.kycLoginSubscription = EventBus.on(ACTION_TYPES.REQUEST_KYC_LOGIN, () =>
      this.setState({
        loading: true,
        loadingText: 'Requesting access...',
      }),
    );
    this.kycLoginSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_KYC_LOGIN_SUCCEEDED,
      this._onKycLogin,
    );
    this.kycLoginFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_KYC_LOGIN_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.pullOTMBanksRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_OTM_BANKS, () =>
      this.setState({ loading: true }),
    );
    this.pullOTMBanksSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_OTM_BANKS_SUCCEEDED,
      this._onReceivedOTMBanks,
    );
    this.pullOTMBanksFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_OTM_BANKS_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestBankDetailsRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BANK_DETAILS,
      () =>
        this.setState({
          loading: true,
          loadingText: 'Requesting bank details...',
        }),
    );
    this.requestBankDetailsSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BANK_DETAILS_SUCCEEDED,
      this._onBankDetailsFetched,
    );
    this.requestBankDetailsFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BANK_DETAILS_FAILED,
      () => this.setState({ loading: false }),
    );

    this.sendPennyDropRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_SEND_PENNY_DROP, () =>
      this.setState({ loading: false, showPennyCheck: true }),
    );
    this.sendPennyDropSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_PENNY_DROP_SUCCEEDED,
      this._onPennyDropSuccess,
    );
    this.sendPennyDropFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_PENNY_DROP_FAILED,
      this._onPennyDropFailed,
    );

    this.submitBankAccountRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT,
      () =>
        this.setState({
          loading: true,
          loadingText: 'Saving bank details...',
        }),
    );
    this.submitBankAccountSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT_SUCCEEDED,
      this._onBankAccountSubmissionSuccess,
    );
    this.submitBankAccountFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT_FAILED,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.fullKycRequestSubscription();
    this.fullKycSuccessSubscription();
    this.fullKycFailureSubscription();

    this.kycLoginSubscription();
    this.kycLoginSuccessSubscription();
    this.kycLoginFailureSubscription();

    this.pullOTMBanksRequestSubscription();
    this.pullOTMBanksSuccessSubscription();
    this.pullOTMBanksFailureSubscription();

    this.requestBankDetailsRequestSubscription();
    this.requestBankDetailsSuccessSubscription();
    this.requestBankDetailsFailureSubscription();

    this.sendPennyDropRequestSubscription();
    this.sendPennyDropSuccessSubscription();
    this.sendPennyDropFailureSubscription();

    this.submitBankAccountRequestSubscription();
    this.submitBankAccountSuccessSubscription();
    this.submitBankAccountFailureSubscription();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isActive &&
      this.props.isActive !== prevProps.isActive &&
      !this.state.requestedOTMBanks &&
      (!this.props.bankDetails || !this.props.bankDetails.bankAccountNumber)
    ) {
      this.props.requestOTMBanks();
    }
  }

  _onFullKycReceive = () => {
    this.props.requestKycLogin();
  };

  _onKycLogin = () => {
    this.props.requestBankDetails({
      ifsc_code: this.state.ifscCode,
    });
  };

  _onReceivedOTMBanks = (response) => {
    this.setState({ loading: false, requestedOTMBanks: true }, () => {
      if (response && response.length > 0) {
        const bank = response[0] || {};
        this.setState({
          accountNumber: bank.bankAccountNumber,
          ifscCode: bank.ifscCode,
          noOTMBanks: false,
        });
      } else {
        this.setState({ noOTMBanks: true });
      }
    });
  };

  _onBankDetailsFetched = () => {
    this.setState({ showPennyCheck: true }, () => {
      this.props.requestSendPennyDrop({
        bank_account_number: this.state.accountNumber,
        ifsc_code: this.state.ifscCode,
      });
    });
  };

  _onPennyDropSuccess = () => {
    this.setState({ showPennyCheck: false }, () => {
      this.props.requestSubmitBankAccount({
        payment_mode: 'NIIB',
        bank_account_type: 'SB',
        bank_account_number: this.state.accountNumber,
        bank_name: this.props.bankDetails.bankName,
        bank_code: this.props.bankDetails.depositBankCode || this.props.bankDetails.bankCode,
        ifsc_code: this.state.ifscCode,
        city: this.props.bankDetails.city,
        branch: this.props.bankDetails.branchName,
        otm_bank_code: this.props.bankDetails.otmBankCode,
        otm_pay_mech: this.props.bankDetails.payMech,
        otm_om_umrn: this.props.bankDetails.omUmrn,
        otm_micr: this.props.bankDetails.micr,
        address: 'address',
        otm_allowed: this.props.bankDetails.otmAllowed,
      });
    });
  };

  _onPennyDropFailed = () => {
    this.setState({ showPennyCheck: false });
  };

  _onBankAccountSubmissionSuccess = () => {
    this.setState({ loading: false }, () => {
      this.props.onNext && this.props.onNext();
    });
  };

  _onAccountNumberChange = (accountNumber) => {
    this.setState({ accountNumber });
  };

  _onIFSCCodeChange = (ifscCode) => {
    this.setState({ ifscCode });
  };

  _onFetchOTMBanks = () => {
    this.props.requestOTMBanks();
  };

  _isValidForm = () => this.state.accountNumber.length > 5 && this.state.ifscCode.length === 11;

  _onSubmit = () => {
    const { email, firstName, lastName } = this.props.user;

    this.props.requestFullKycRegistration({
      email,
      full_name: `${firstName} ${lastName}`,
    });
  };

  _onLinkNewAccount = () => {
    this.setState({ noOTMBanks: true, accountNumber: '', ifscCode: '' });
  };

  _onLinkPreviousAccount = () => {
    this.setState({ noOTMBanks: false });
  };

  render() {
    const { bankDetails } = this.props;
    const { showPennyCheck } = this.state;

    return (
      <div className={styles['link-account']}>
        {this.state.noOTMBanks ? (
          <AnotherAccount
            accountNumber={this.state.accountNumber}
            ifscCode={this.state.ifscCode}
            onAccountNumberChange={this._onAccountNumberChange}
            onIFSCCodeChange={this._onIFSCCodeChange}
            onFetchOTM={this._onFetchOTMBanks}
            disabled={this.state.loading}
            previousAccount={this._onLinkPreviousAccount}
          />
        ) : (
          <ConfirmAccount {...bankDetails} onLinkAccount={this._onLinkNewAccount} />
        )}

        <button
          disabled={(!this._isValidForm() && this.state.noOTMBanks) || this.state.loading}
          className={styles['footer-button']}
          onClick={this._onSubmit}
        >
          NEXT
        </button>
        <Loader loading={this.state.loading} title={this.state.loadingText} />
        {showPennyCheck && <PennyCheck />}
      </div>
    );
  }
}

export default LinkAccount;

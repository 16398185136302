import React, { Component } from 'react';
import cx from 'classnames';
import EventBus from 'eventing-bus';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { ACTION_TYPES } from 'src/constants';
import { Convert } from 'src/helpers';
// import _ from 'lodash';
// import { Heading } from 'src/components/theme';
import ProgressProvider from './progress-provider';
import styles from './_goal-progress.module.scss';

class GoalProgress extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.requestGoalList = EventBus.on(ACTION_TYPES.REQUEST_GOAL_LIST, () =>
      this.setState({ loading: true }),
    );
    this.requestGoalListSuccess = EventBus.on(ACTION_TYPES.REQUEST_GOAL_LIST_SUCCESS, () => {
      this.setState({ loading: false });
    });
    this.requestGoalListFailure = EventBus.on(ACTION_TYPES.REQUEST_GOAL_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestGoalDetail = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.requestGoalDetailSuccess = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.requestGoalDetailFailed = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestGoalList();
    this.requestGoalListSuccess();
    this.requestGoalListFailure();

    this.requestGoalDetail();
    this.requestGoalDetailSuccess();
    this.requestGoalDetailFailed();
  }

  circleProgressBar = ({ valueEnd }) => {
    const { data = {} } = this.props;
    let imageUrl = '';

    if (data.savingGoalCategory === 'snbl') {
      imageUrl =
        (data?.productOffer?.product?.imageUrls.length > 0 &&
          data?.productOffer?.product?.imageUrls[0]?.url) ||
        '/img/image.png';
    } else {
      imageUrl = data?.unsplashImage?.link || '/img/image.png';
    }

    return (
      <ProgressProvider valueStart={0} valueEnd={valueEnd}>
        {(value) => (
          <CircularProgressbarWithChildren
            value={value}
            styles={buildStyles({
              pathColor: '#F9C301',
              trailColor: '#DDDDDD',
              pathTransitionDuration: 1.5,
            })}
          >
            <div
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
              className={styles['goal-image']}
            />
          </CircularProgressbarWithChildren>
        )}
      </ProgressProvider>
    );
  };

  render() {
    const { loading } = this.state;
    const { data = {}, title } = this.props;
    // const amountPaid = _.sumBy(data.payments, (payment) => Number(payment?.amount || 0));
    const valueEnd =
      (parseFloat(data?.investmentPlusPendingBalance) / data?.goalAbsoluteAmount) * 100;
    return (
      <div className={cx(styles['goal-progress'], { [styles['overview']]: this.props.overview })}>
        <div className={cx({ [styles['overview-inner-box']]: this.props.overview })}>
          {this.props.overview && <h4 className={styles['title']}>Total Purchase Progress</h4>}
          <div className={styles['goal-image-container']}>
            {loading
              ? this.circleProgressBar({ valueEnd: 0 })
              : this.circleProgressBar({ valueEnd })}
          </div>
          {!this.props.overview && (
            <>
              <h4 className={styles['no-of-offer']}> {data?.description}</h4>
              <h4 className={styles['goal-name']}> {title}</h4>
            </>
          )}
          <div
            className={cx({
              [styles['payment-box']]: !this.props.overview,
            })}
          >
            <p
              className={cx(styles['total-goal-amount'], {
                [styles['total-goal-amount-yellow']]: !this.props.overview,
              })}
            >{`Price :${Convert.currency(data?.price)}`}</p>
            {this.props.overview && (
              <p className={styles['amount-saved']}>{`Amount Saved :${Convert.currency(
                data?.investmentPlusPendingBalance,
              )}`}</p>
            )}
            {/* <div
              className={cx({
                [styles['vertical-line']]: !this.props.overview,
              })}
            /> */}
            {/* {!this.props.overview && (
              <p className={styles['pending-amount']}>{`Pending Amount :${Convert.currency(
                data?.pendingAmount,
              )}`}</p>
            )} */}
          </div>
          {this.props.overview && (
            <div className={styles['your-return']}>
              Your Returns :{Convert.currency(data?.returns)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GoalProgress;

import React, { Component } from 'react';
import moment from 'moment';
import { Loader, WithDrawnMoney, SaveMore, CancelBooking } from 'src/components';
import { Convert } from 'src/helpers';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './_goal-details.module.scss';
import Transactions from '../transactions';

class GoalDetails extends Component {
  state = {
    isWithdrawalModalOpen: false,
    isSaveMoreModalOpen: false,
    isCancelModalOpen: false,
    isWithdrawAll: false
  };

  componentDidMount() {
    // this.requestGoalDetail = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL, () =>
    //   this.setState({ loading: true }),
    // );
    // this.requestGoalDetailSuccess = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS, () =>
    //   this.setState({ loading: false }),
    // );
    // this.requestGoalDetailFailed = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED, () =>
    //   this.setState({ loading: false }),
    // );
    // this.props.requestGoalDetail();
  }

  componentWillUnmount() {
    // this.requestGoalDetail();
    // this.requestGoalDetailSuccess();
    // this.requestGoalDetailFailed();
  }

  handleWithdrawModal = (e) => {
    e.preventDefault();
    this.setState({ isWithdrawalModalOpen: true, isWithdrawAll: false });
  };

  handleCloseWithdrawModal = () => {
    this.setState({ isWithdrawalModalOpen: false, isWithdrawAll: false });
  };

  handleShowCancelBooking = () => {
    this.setState({ isWithdrawalModalOpen: false, isWithdrawAll: false, isCancelModalOpen: true });
  }

  handleCancelModal = (e) => {
    e.preventDefault();
    this.setState({ isCancelModalOpen: true });
  };

  handleCloseCancelModal = () => {
    this.setState({ isCancelModalOpen: false });
  };

  handleWithdrawAllMoney = () => {
    this.setState({ isCancelModalOpen: false, isWithdrawalModalOpen: true, isWithdrawAll: true });
  }

  handleSaveMoreModal = (e) => {
    e.preventDefault();
    this.setState({ isSaveMoreModalOpen: true });
  };

  handleCloseSaveMoreModal = () => {
    this.setState({ isSaveMoreModalOpen: false });
  };

  render() {
    const {
      loading,
      // goalDetail
      data = {},
      savingGoal = {},
    } = this.props;
    const { isWithdrawalModalOpen, isSaveMoreModalOpen, isCancelModalOpen } = this.state;
    const discount =
      (Number(savingGoal?.productOffer?.product?.amount || 0) / 100) *
        Number(savingGoal?.productOffer?.discountPercentage) || '';
    const paymentCalendar = savingGoal?.paymentCalendar || {};
    const paymentCalendarKeys = Object.keys(paymentCalendar);
    const pendingPaymentCalendar = paymentCalendarKeys.filter((calendar) => {
      const date = calendar.substr(0, 2);
      const month = calendar.substr(2, 2);
      const year = calendar.substr(4, 4);
      return moment(`${year}/${month}/${date}`).diff(moment(), 'days') > 0;
    });
    let nextDate;
    if (pendingPaymentCalendar && pendingPaymentCalendar.length > 0) {
      const date = pendingPaymentCalendar[0].substr(0, 2);
      const month = pendingPaymentCalendar[0].substr(2, 2);
      const year = pendingPaymentCalendar[0].substr(4, 4);
      nextDate = moment(`${date}/${month}/${year}`, 'DD/MM/YYYY').format('Do MMMM YYYY');
    }

    // const savedMonths =
    //   ((paymentCalendarKeys && paymentCalendarKeys.length) || 0) -
    //   ((pendingPaymentCalendar && pendingPaymentCalendar.length) || 0);

    // let monthlyInvested =
    //   (paymentCalendarKeys &&
    //     paymentCalendarKeys.length > 0 &&
    //     paymentCalendar[paymentCalendarKeys[0]]) ||
    //   0;
    // if (
    //   paymentCalendarKeys &&
    //   paymentCalendarKeys.length > 1 &&
    //   paymentCalendar[paymentCalendarKeys[1]]
    // ) {
    //   monthlyInvested = paymentCalendar[paymentCalendarKeys[1]];
    // }
    
    return (
      <div className={cx(styles['goal-details'], { [styles['overview']]: this.props.overview })}>
        <div className={styles['complete-steps']}>
          { savingGoal.paymentMethod === 'book_save_buy' && !savingGoal.kycd ? 
            <Link
              to={{ pathname: '/kyc', state: { savingGoalId: savingGoal.id } }}
            >
              COMPLETE KYC
            </Link> 
            : 
            savingGoal.mandate?.status !== 'completed' && savingGoal.mandate?.status !== 'accepted_by_user' ? <Link
              to={{ pathname: savingGoal.paymentMethod === 'book_save_buy' ? '/setup-sip' : '/auto-pay', state: { savingGoalId: savingGoal.id } }}
            >
              { savingGoal.paymentMethod === 'book_save_buy' ? 'COMPLETE SIP SETUP' : 'SETUP AUTO-PAY'}
            </Link> : null 
          }
        </div>
        <div className={styles['banner']}>
          {Boolean(discount) && discount !== 'NaN' && (
            <div>
              <div className={styles['label']}>Discount Received </div>
              <div className={styles['text']}>{Convert.currency(discount)} </div>
            </div>
          )}
          <div>
            <div className={styles['label']}>Amount Saved</div>
            <div className={styles['text']}>
              {Convert.currency(data?.investmentPlusPendingBalance)}
            </div>
          </div>
          <div>
            <div className={styles['label']}>Returns Earned</div>
            <div className={styles['text']}>{Convert.currency(data?.returns)} </div>
          </div>
        </div>
        <Transactions savingGoal={savingGoal} />
        <div className={styles['next-date']}>Next Save Date: {nextDate}</div>
        <div className={styles['custom-btns']}>
          <a
            href="#"
            className={cx('btn btn-primary', styles['custom-btn'])}
            target="_blank"
            rel="noreferrer"
            onClick={this.handleSaveMoreModal}
          >
            SAVE MORE
          </a>
          <a
            href="#"
            className={cx('btn btn-primary', styles['custom-btn'])}
            target="_blank"
            rel="noreferrer"
            onClick={this.handleWithdrawModal}
          >
            WITHDRAW SAVINGS
          </a>
          <a
            href="#"
            className={cx('btn btn-primary', styles['custom-btn'])}
            target="_blank"
            rel="noreferrer"
            onClick={this.handleCancelModal}
          >
            CANCEL BOOKING
          </a>
        </div>
        <Loader loading={loading} />
        {isSaveMoreModalOpen && (
          <SaveMore
            isModalOpen={isSaveMoreModalOpen}
            closeModal={this.handleCloseSaveMoreModal}
            savingGoal={this.props.goalDetail}
          />
        )}
        {isWithdrawalModalOpen && (
          <WithDrawnMoney
            isModalOpen={isWithdrawalModalOpen}
            closeModal={this.handleCloseWithdrawModal}
            savingGoal={this.props.goalDetail}
            isWithdrawAll={this.state.isWithdrawAll}
            showCancelBooking={this.handleShowCancelBooking}
          />
        )}

        {isCancelModalOpen && (
          <CancelBooking 
            isModalOpen={isCancelModalOpen} 
            closeModal={this.handleCloseCancelModal} 
            withdrawAllMoney={this.handleWithdrawAllMoney}
            savingGoal={this.props.goalDetail}
          />
        )}
      </div>
    );
  }
}

export default GoalDetails;

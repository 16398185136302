import React, { Component } from 'react';
// import { Dropdown } from 'react-bootstrap';
import { Convert } from 'src/helpers';
import { Heading, Text } from 'src/components/theme';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './_product-details.module.scss';

class ProductDetails extends Component {
  state = {};

  handleTaxPay = ({ totalPax }) => {
    if (totalPax) this.props.updateSavingPlan({ totalPax: Number(totalPax) });
  };

  render() {
    const {
      productName,
      productOffers = [],
      totalPax = 1,
      isLockedQuantity,
      quantityText,
    } = this.props || {};

    const offer = productOffers && productOffers.length > 0 ? productOffers[0] : {};
    const discountPercentage = Number(offer?.discountPercentage || 0);

    return (
      <div className={styles['product-detail-container']}>
        <div className={styles['product-detail']}>
          <div className={styles['left']}>
            <Heading className={styles['product-name']}>{productName}</Heading>
            <div className={styles['passengers']}>
              {isLockedQuantity ? (
                <>
                  <FontAwesomeIcon icon={faUser} style={{ marginRight: '0.5rem' }} />
                  <Text variant="p1">{quantityText}</Text>
                </>
              ) : (
                <>
                  {/* <Text variant="p1">No. of passengers: </Text>
                  <Dropdown className={styles['main-dropdown']}>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      {totalPax || 0}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles['dropdown-menu']}>
                      {new Array(10).fill(0).map((d, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => this.handleTaxPay({ totalPax: index + 1 })}
                        >
                          {index + 1}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown> */}
                </>
              )}
            </div>
          </div>
          <div className={styles['center']}>
            {discountPercentage > 0 ? (
              <div className={styles['discount-container']}>
                <span>{discountPercentage}%</span>
                <span>Off</span>
              </div>
            ) : null}
          </div>
          <div className={styles['right']}>
            <Heading>{Convert.currency(offer?.discountedAmount * totalPax)}</Heading>
            <Text variant="p2">
              {discountPercentage > 0 ? Convert.currency(offer?.taxedAmount * totalPax) : ''}
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductDetails;

import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function TransactionsReducer(state=INITIAL_STATE.transactions, action){
    switch(action.type){

        case ACTION_TYPES.REQUEST_TRANSACTION_LIST_SUCCESS:{
            return{
                ...action.payload
            }
        }

        case ACTION_TYPES.REQUEST_TRANSACTION_DETAIL_SUCCESS:{
            return{
                ...action.payload
            }
        }

        case ACTION_TYPES.FOLIO_WITHDRAW_SUCCESS:
        case ACTION_TYPES.CREATE_DEPOSIT_SUCCESS:
        case ACTION_TYPES.FETCH_DEPOSIT_SUCCESS:
        case ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_SUCCESS:
            const payload = action.payload.transactions && action.payload.transactions.transactions ? action.payload.transactions.transactions : state;
            return {
                ...payload
            };
            
        case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED:
            return INITIAL_STATE.transactions;

        default:
            return state;

    }
}
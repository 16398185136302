import {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,
  requestGeneratePayment,
  updateCredentials,
  updateProduct,
} from 'src/redux/actions';
import { connect } from 'react-redux';

import PayError from './pay-error';

function mapStateToProps(state) {
  return {
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
  };
}

export default connect(mapStateToProps, {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,
  requestGeneratePayment,
  updateCredentials,
  updateProduct,
})(PayError);

import { ACTION_TYPES } from 'src/constants';

// blacklist actions
const BLACKLIST = [
  ACTION_TYPES.REDUX_PERSIST,
  ACTION_TYPES.REDUX_PERSIST_FLUSH,
  ACTION_TYPES.REDUX_PERSIST_REHYDRATE,
  ACTION_TYPES.REDUX_PERSIST_PAUSE,
  ACTION_TYPES.REDUX_PERSIST_PURGE,
  ACTION_TYPES.REDUX_PERSIST_REGISTER
];

export default BLACKLIST;

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function KycReducer(state = INITIAL_STATE.kyc, action) {  
  switch (action.type) {
    case ACTION_TYPES.UPDATE_KYC_PARAMS:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.kyc;
    }

    case ACTION_TYPES.FATCHA_FORM_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function createSavingGoal(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.SAVING_GOALS,
    data: params,
  }).then((response) => response.data);
}

export function cancelPurchase({ saving_goal_id }) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.SAVING_GOALS}/${saving_goal_id}/cancel`,    
  }).then((response) => response.data);
}

/**
 * gets goals list
 * @param {object} params 
 */
export function getGoalList() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.SAVING_GOALS,
  }).then((response) => response.data);
}

/** Get goal detail */
export function getGoalDetail({id}) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.SAVING_GOALS}/${id}`,
  }).then((response) => response.data);
}

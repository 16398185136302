import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { InputFileFive } from 'src/components/form-inputs';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

import styles from './review-info.module.scss';

class ReviewInfo extends Component {
  render() {
    const { onNext } = this.props;

    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['another-account']}>
          <div className={styles['pay-content']}>
            <div className={cx(styles['title'], styles['title-heading'])}>
              <div>Review your information (5/7)</div>
              <div>
                <FontAwesomeIcon icon={faAngleLeft} onClick={this.props.onPrev} /> &nbsp;&nbsp;
                <FontAwesomeIcon icon={faAngleRight} onClick={this.props.onNext} />
              </div>
            </div>
            <div className={styles['line']} />
            <div className={styles['sub-title']}>VIDEO REVIEW (5/7)</div>
            <div>
              <Row>
                <Col sm={12}>
                  {this.props.video?.video_url ? (
                    <div className={styles['video-box']}>
                      <InputFileFive
                        name="video"
                        id="video"
                        ctaTitle="Re-take"
                        source={this.props.video?.video_url}
                        onClick={() => this.props.history.push('video')}
                      />
                    </div>
                  ) : (
                    <div className={styles['placeholder-outer']}>
                      <div className={styles['placeholder']}>
                        <div className={styles['placeholder-inner']}>
                          <div>
                            <img src="/img/play.svg" alt="play" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles['spacer']} />

          <div className={styles['footer']} onClick={onNext}>
            NEXT
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReviewInfo);

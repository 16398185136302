import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminCollectionReducer(state = INITIAL_STATE.collections, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_COLLECTION_LIST_SUCCESS:
      return {
        ...state,
        collectionsList: action.payload,
      };

    case ACTION_TYPES.REQUEST_COLLECTION_DETAIL_SUCCESS:
      return {
        ...state,
        collectionDetail: action.payload,
      };

    case ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST_SUCCESS:
      return {
        ...state,
        collectionDetail: action.payload,
      };

    case ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL_SUCCESS:
      return {
        ...state,
        userCollectionDetail: action.payload,
      };

    case ACTION_TYPES.REQUEST_COLLECTION_ACCEPT_SUCCESS:
      return {
        ...state,
        collectionAccept: action.payload,
      };

    default:
      return state;
  }
}

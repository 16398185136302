import { connect } from 'react-redux';
import {
  extractPanCardRequest,
  uploadPanCardRequest,
  uploadFormRequest,
  updateKycParams,
} from 'src/redux/actions';
import UploadPan from './upload-pan';

function mapStateToProps(state) {
  return {
    kyc: state.kyc,
  };
}

export default connect(mapStateToProps, {
  extractPanCardRequest,
  uploadPanCardRequest,
  uploadFormRequest,
  updateKycParams,
})(UploadPan);

import React from 'react';
import cx from 'classnames'
import { Button } from 'react-bootstrap';
import styles from './_buttonone.module.scss';

function ButtonOne(props) {
  return (
    <div className={styles.container} style={{ ...props.parentstyles }}>
      <Button
        variant=""
        {...props}
        style={{
          ...props?.styles,
        }}
        className={cx(styles.button, props?.className)}
      >
        {props.text}
      </Button>
    </div>
  );
}

export default ButtonOne;

import { useField } from 'formik';
import React from 'react';
import cx from 'classnames';
import styles from './_inputselectone.module.scss';
import globalStyles from '../_styles.module.scss';

function InputSelectOne({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <div className={styles.inputLabelContainer}>
        <label
          htmlFor={props.id || props.name}
          className={cx(styles.inputLabel, props.labelStyle, {
            [styles.largeLabel]: props?.labelsize === 'large',
          })}
        >
          {label}
        </label>
      </div>
      <div>
        <select {...field} {...props} className={cx('form-select', props.className)} />
        {meta.touched && meta.error ? <div className={globalStyles.error}>{meta.error}</div> : null}
      </div>
    </>
  );
}

export default InputSelectOne;

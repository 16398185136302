import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CustomBadge from 'src/components/custom-badge';
import DataTable from 'src/components/data-table';
import styles from '../_styles.module.scss';
import './_productsOffersTable.scss';


const ProductOffersTable = (props) => {
  let history = useHistory();
  /**
   *
   * Handles click to uuid to take user to product detail screen
   *
   */
  const _handleDetailLink = (e, uuid) => {
    e.preventDefault();
    history.push(`/admin/product/offer/${uuid}`);
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'productOfferName', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <div className={styles.multilineColumn}>
            <span className={styles.childOne}>{value}</span>
            <a href="#" onClick={(e) => _handleDetailLink(e, original?.uuid)}>
              <span className={styles.childTwo}>{original.uuid}</span>
            </a>
          </div>
        ),
      },
      {
        Header: 'Months',
        accessor: 'months',
        styles: {
          textAlign: 'center',
        },
      },
      {
        Header: 'Discount (%)',
        accessor: 'discountPercentage',
        styles: {
          textAlign: 'center',
        },
      },
      {
        Header: 'Status',
        accessor: 'hidden',
        Cell: ({ value }) => (
          <CustomBadge type={value ? 'info' : 'success'} value={value ? 'Hidden' : 'Visible'} />
        ),
      },
    ],
    [],
  );
  return (
    <div className="products-offer-data-table">
      <DataTable
        columns={columns}
        data={props.data}
        paginationConfig={paginationConfig}
        handlePaginationChange={props.handlePaginationChange}
      />
    </div>
  );
};

export default ProductOffersTable;

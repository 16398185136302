import { ACTION_TYPES } from 'src/constants';

export function fetchEmailTemplatesRequest(payload) {
  return {
    type: ACTION_TYPES.FETCH_EMAIL_TEMPLATE_REQUEST,
    payload
  }
}

export function fetchEmailTemplatesSuccess(payload) {
  return {
    type: ACTION_TYPES.FETCH_EMAIL_TEMPLATE_SUCCESS,
    payload
  }
}

export function fetchEmailTemplatesFailure(payload) {
  return {
    type: ACTION_TYPES.FETCH_EMAIL_TEMPLATE_FAILED,
    payload
  }
}

export function generateEmailCodeRequest(payload) {
  return {
    type: ACTION_TYPES.GENERATE_EMAIL_CODE_REQUEST,
    payload
  }
}

export function generateEmailCodeSuccess(payload) {
  return {
    type: ACTION_TYPES.GENERATE_EMAIL_CODE_SUCCESS,
    payload
  }
}

export function generateEmailCodeFailure(payload) {
  return {
    type: ACTION_TYPES.GENERATE_EMAIL_CODE_FAILED,
    payload
  }
}


import { connect } from 'react-redux'
import { requestAdminTransactionList } from 'src/redux/actions'
import AdminTransactions from './AdminTransactions'

function mapStateToProps(state){
    return{
        transactions: state.adminTransactions
    }
}

export default connect(mapStateToProps, {
    requestAdminTransactionList
})(AdminTransactions)
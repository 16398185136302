import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonOne } from '../../../../components';

import styles from './_product-create-modal.module.scss';

class ProductCreateModal extends Component {
  redirectToProductOfferLink = () => {
    const { history, createdProductResponse } = this.props;
    history.push(`/admin/marketing/products/${createdProductResponse?.uuid}/link`);
  };

  redirectToProducts = () => {
    const { history } = this.props;
    history.push('/admin/marketing/products');
  };

  render() {
    const { createdProductResponse } = this.props;
    return (
      <div className={styles['product-create-modal']}>
        <div className={styles['product-box']}>
          <div className={styles['product-img']}>
            <img src="/img/ok-3.png" alt="ok" />
          </div>
          <div className={styles['title']}>
            Your product {createdProductResponse?.productName} has been created successfully!
          </div>
          <div className={styles['confirmation']}>
            Would you like to an share offer related to this product?
          </div>
          <div className={styles['custom-btns-group']}>
            <div className={styles['custom-btns-first']}>
              <ButtonOne text="Yes, I would" onClick={this.redirectToProductOfferLink} />
            </div>
            <ButtonOne text="No, not right now" onClick={this.redirectToProducts} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProductCreateModal);

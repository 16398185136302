import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CustomBadge from 'src/components/custom-badge';
import DataTable from 'src/components/data-table';
import styles from '../_styles.module.scss';
import './_userTable.scss';

const UsersTable = (props) => {
  const { data, handlePaginationChange } = props || {};
  const history = useHistory();

  const _handleUserDetails = (e, uuid) => {
    e.preventDefault();
    history.push({
      pathname: `user/${uuid}`,
    });
  };

  const pageSize = 50;

  const paginationConfig = {
    totalItems: data?.usersTotal || 0,
    pageSize,
    totalPages: Math.ceil(data?.usersTotal / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <div className={styles.multilineColumn}>
            <a href="" onClick={(e) => _handleUserDetails(e, original.uuid)}>
              <span className={styles.childOne}>{value}</span>
            </a>
            {/* <span className={styles.childTwo}>{original.uuid}</span> */}
          </div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Status',
        Cell: ({ row: { original } }) => (
          <CustomBadge
            value={
              original?.folio?.identificationConfirmed && original?.savingGoals.length > 0
                ? `${original?.savingGoals.length} Purchases | KYC done`
                : original?.savingGoals.length > 0 && !original?.folio?.identificationConfirmed
                ? `${original?.savingGoals.length} Purchases | KYC Pending`
                : '0 Purchases | KYC Pending'
            }
            type={
              original?.folio?.identificationConfirmed && original?.savingGoals.length > 0
                ? 'success'
                : original?.savingGoals.length > 0 && !original?.folio?.identificationConfirmed
                ? 'info'
                : 'error'
            }
          />
        ),
      },
    ],
    [],
  );

  return (
    <div className="user-data-table">
      <DataTable
        columns={columns}
        data={data?.users}
        paginationConfig={paginationConfig}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  );
};

export default UsersTable;

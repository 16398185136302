import { connect } from 'react-redux'
import { adminProductImageDelete } from 'src/redux/actions';
import PreviewImages from './PreviewImages'

function mapStateToProps(state){
  return{
    delete: state.delete
  }
}

export default connect(mapStateToProps, {
  adminProductImageDelete
})(PreviewImages)


import { connect } from 'react-redux'
import { requestSendPennyDrop, createContractRequest } from 'src/redux/actions';
import ReviewSummary from './ReviewSummary'

function mapStateToProps(state){  
  return{
    user: state.user,
    kyc: state.kyc
  }
}

export default connect(mapStateToProps,{ requestSendPennyDrop, createContractRequest })(ReviewSummary)



import React, { Component } from 'react'
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus'
import { Loader } from 'src/components';

export class AdminPartners extends Component {
  state={
    loading: false,
    // pageNumber: 1
  }

  componentDidMount() {
    this.requestAdminPartnerList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminPartnerListSuccess = EventBus.on(ACTION_TYPES.ADMIN_PARTNER_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminPartnerListFailed = EventBus.on(ACTION_TYPES.ADMIN_PARTNER_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    
    // if(!this.props.admin.isAdmin){
    //   return this.props.history.push('/admin')
    // }
    
    this.props.requestAdminPartnerList();
  }

  componentWillUnmount() {
    this.requestAdminPartnerList();
    this.adminPartnerListSuccess();
    this.adminPartnerListFailed();
  }

  render() {
    const {admin} = this.props
    console.log(admin);
    return (
      <div>
        Admin Partners
        <Loader loading={this.state.loading}/>
      </div>
    )
  }
}

export default AdminPartners

import { takeEvery, put, call } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { generatePayment, createDeposit, fetchDeposit } from 'src/redux/api';
import {
  generatePaymentSuccess,
  generatePaymentFailure,
  createDepositSuccess,
  createDepositFailure,
  fetchDepositSuccess,
  fetchDepositFailure,
  requestGoalDetailSuccess,
} from 'src/redux/actions';

export function* watchPaymentRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_GENERATE_PAYMENT, requestGeneratePayment);
  yield takeEvery(ACTION_TYPES.CREATE_DEPOSIT_REQUEST, requestCreateDeposit);
  yield takeEvery(ACTION_TYPES.FETCH_DEPOSIT_REQUEST, requestFetchDeposit);
}

function* requestGeneratePayment(action) {
  try {
    const payment = yield call(generatePayment, action.payload);
    yield put(generatePaymentSuccess(payment));
  } catch (e) {
    yield put(generatePaymentFailure(e));
  }
}

function* requestCreateDeposit(action) {
  try {
    const deposit = yield call(createDeposit, action.payload);
    yield put(createDepositSuccess(deposit));
    if (deposit?.savingGoal) {
      yield put(requestGoalDetailSuccess({ savingGoal: deposit?.savingGoal }));
    }
  } catch (e) {
    yield put(createDepositFailure(e));
  }
}

function* requestFetchDeposit(action) {
  try {
    const deposit = yield call(fetchDeposit, action.payload);
    yield put(fetchDepositSuccess(deposit));
  } catch (e) {
    yield put(fetchDepositFailure(e));
  }
}

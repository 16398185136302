import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Navbar, Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import CircleIcon from 'src/components/circle-icon';
// import SearchBar from 'src/components/searchbar';
import styles from './_topnavigation.module.scss';

const TopNavigation = (props) => {
  const { pathname } = useLocation();
  // const [searchbarVisible, setSearchbarVisible] = useState(false);
  return (
    <div className={styles.navbarContainer}>
      <Navbar expand="lg" className={styles['navbar']} style={{ paddingTop: 0, paddingBottom: 0 }}>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <CircleIcon styles={{ marginLeft: '0.8rem' }} onClick={() => props.handleSidebarToggle()}>
          <FontAwesomeIcon icon={faBars} />
        </CircleIcon>
        <div className={styles.navRight}>
          {/* {searchbarVisible 
                        ? (
                            <Form className={styles['searchbar']}>
                                <Form.Control 
                                    type="text" 
                                    className={`${styles.input} ${searchbarVisible && styles.visible}`}
                                    placeholder="Enter search text here..."
                                />
                            </Form>
                        )
                        : <FontAwesomeIcon icon={faSearch} onClick={()=>setSearchbarVisible(!searchbarVisible)}/>
                    } */}
          {/* <FontAwesomeIcon icon={faSearch} onClick={() => setSearchbarVisible(!searchbarVisible)} /> */}
          <div className={styles.verticalLine} />
          <CircleIcon styles={{ margin: '0 1rem' }}>
            <FontAwesomeIcon icon={faBell} className={styles.icon} />
          </CircleIcon>
          {/* <button variant="" className={styles['cta']}>
                        <FontAwesomeIcon icon={faPlus}/>
                        <span className={styles.text}>New</span>
                    </button> */}
          <Dropdown className={styles.dropdown}>
            <Dropdown.Toggle variant="" id="dropdown-basic" className={styles.cta}>
              <img src="/img/plus.svg" alt="plus" />
              <span className={styles.text}>NEW</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdownMenu}>
              <Dropdown.Item
                className={cx(styles.item, {
                  [styles.active]: pathname === '/admin/products/new',
                })}
                href="/admin/products/new"
              >
                {/* <Link
                  className={`${styles.linkItem} ${
                    pathname === '/admin/products/new' && styles.active
                  }`}
                  to="/admin/products/new"
                > */}
                Product
                {/* </Link> */}
              </Dropdown.Item>
              <Dropdown.Item
                className={cx(styles.item, {
                  [styles.active]: pathname === '/admin/products/offers/new',
                })}
                href="/admin/products/offers/new"
              >
                {/* <Link
                  className={`${styles.linkItem} ${
                    pathname === '/admin/products/offers/new' && styles.active
                  }`}
                  to="/admin/products/offers/new"
                > */}
                Product Offer
                {/* </Link> */}
              </Dropdown.Item>
              <Dropdown.Item
                className={cx(styles.item, {
                  [styles.active]: pathname === '/admin/product/payment',
                })}
                href="/admin/product/payment"
              >
                {/* <Link
                  className={`${styles.linkItem} ${
                    pathname === '/admin/product/payment' && styles.active
                  }`}
                  to="/admin/product/payment"
                > */}
                Payment Link
                {/* </Link> */}
              </Dropdown.Item>
              {/* <Dropdown.Item>
                <Link
                  className={`${styles.linkItem} ${
                    pathname === '/admin/offer/payment' && styles.active
                  }`}
                  to="/admin/offer/payment"
                >
                  Payment Link (offer)
                </Link>
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      {/* <SearchBar searchbarVisible={searchbarVisible} /> */}
    </div>
  );
};

export default TopNavigation;

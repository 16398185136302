import { connect } from 'react-redux';
import { requestSignInUser } from 'src/redux/actions';
import OtpLogin from './OtpLogin';


function mapStateToProps(state) {
  return {
    user: state.user,
    product: state.product
  };
}

export default connect(mapStateToProps, { requestSignInUser })(OtpLogin);
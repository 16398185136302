import { connect } from 'react-redux';
import {
  requestAdminOffersList,
  requestAdminProductDetail,
  adminProductLink,
} from 'src/redux/actions';
import ProductOfferLink from './product-offer-link';

function mapStateToProps(state) {
  return {
    offers: state.adminOffers.productOffers,
    product: state.adminProducts.product,
    admin: state.admin,
    adminCredentials: state.adminCredentials,
  };
}

export default connect(mapStateToProps, {
  requestAdminOffersList,
  requestAdminProductDetail,
  adminProductLink,
})(ProductOfferLink);

import { ACTION_TYPES } from 'src/constants';

export function requestCreateMandate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_MANDATE,
    payload,
  };
}

export function createMandateSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_MANDATE_SUCCESS,
    payload,
  };
}

export function createMandateFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_MANDATE_FAILED,
    payload,
  };
}

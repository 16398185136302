import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function CredentialReducer(state = INITIAL_STATE.credentials, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_CREDENTIALS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_TYPES.SIGN_IN_USER_SUCCESS:
      return {
        ...state,
        userIdentityToken: action.payload.authCredentials
          ? action.payload.authCredentials.xUserIdentityToken
          : '',
      };

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.credentials;
    }

    default:
      return state;
  }
}

import React, { Component } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { TextInputThree } from 'src/components/form-inputs'
import { Loader, CtaTwo, Header } from 'src/components'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPen, faEye } from '@fortawesome/free-solid-svg-icons'
import { ACTION_TYPES } from 'src/constants'
import EventBus from 'eventing-bus'
import { notify } from 'react-notify-toast'
import { withRouter } from 'react-router-dom'
import styles from './_adminpartneruser.module.scss'

export class AdminPartnerUser extends Component {
  state={
    loading:false
  }

  _validationSchema = () => yup.object().shape({
    // first_name: yup.string()
    //   .required('Please enter First Name'),
    // last_name: yup.string()
    //   .required('Please enter Last Name'),
    email: yup.string()
      .email('Please enter a valid email address')
      .required('Please enter email address.'),
    password: yup.string()
      .min(6,'Password shall be atleast 6 characters long.')
      .max(20, 'Password shall be max 20 characters long.')
      .required('Please enter password'),
  })

  componentDidMount() {
    this.requestAdminAgentCreate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_AGENT_CREATE, () =>
      this.setState({ loading: true }),
    );
    this.adminAgentCreateSuccess = EventBus.on(ACTION_TYPES.ADMIN_AGENT_CREATE_SUCCESS, () => this._handleCreateSuccess());
    this.adminAgentCreateFailed = EventBus.on(ACTION_TYPES.ADMIN_AGENT_CREATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    // non partner admin/super admin shalln't be able to create a sales agent 
    if(!this.props.admin.isAdmin){
      return this.props.history.push('/admin')
    }
  }

  componentWillUnmount() {
    this.requestAdminAgentCreate();
    this.adminAgentCreateSuccess();
    this.adminAgentCreateFailed();
  }

  _handleCreateSuccess = () => {
    this.formik.resetForm()

    this.setState({
      loading: false
    }, 
    () => notify.show('Agent Created successfully', 'success'))
  }

  render() {
    return (
      <div className={styles.container}>
        <Header
          heading="Sales Agent Details"
          headerStyles={styles.headerStyles}
          headingStyles={styles.headingStyles}
        />
        <Formik
          initialValues={{
            // first_name: '',
            // last_name: '',
            email: '',
            password: '',
          }}
          innerRef={ref => this.formik = ref}
          validationSchema={this._validationSchema}
          onSubmit={(values)=>{
            this.props.requestAdminAgentCreate({partner_admin_user: {...values}})
          }}
        >
            <Form
              className={styles.formContainer}
            >
              {/* <TextInputThree
                label="First Name"
                name="first_name"
                id="first_name"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Enter Agent First Name."
              />
              <TextInputThree
                label="Last Name"
                name="last_name"
                id="last_name"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Enter agent Last Name."
              /> */}
              <TextInputThree
                label="Email Id"
                name="email"
                id="email"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Enter agent email address."
              />
              <TextInputThree
                label="Password"
                name="password"
                id="password"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Enter agent password"
                type="password"
              />
              {!this.state.readMode &&
                <div className={styles.buttonContainer}>
                  <CtaTwo
                    text="Save Details"
                    className={styles.ctaTwoStyles}
                    type="submit"
                  />
                </div>
              }
            </Form>
        </Formik>
        <Loader loading={this.state.loading}/>
      </div>
    )
  }
}

export default withRouter(AdminPartnerUser)

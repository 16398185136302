import React, { Component } from 'react';
// import { Dropdown } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import { Loader, SavvySupport } from 'src/components';
import { Convert } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import cx from 'classnames';
import moment from 'moment';
import OfferBox from '../pay/offer-box';
import styles from './sip.module.scss';
import OfferTabItem from './offer-tab-item/offer-tab-item';

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
const DIGIO_ENDPOINT_URL = IS_PRODUCTION ? 'https://app.digio.in' : 'https://ext.digio.in';

class Sip extends Component {
  state = {
    loading: false,
    choosenDate: 5,
    transactionId: null,
  };

  componentDidMount() {
    this.requestCreateMandate = EventBus.on(ACTION_TYPES.REQUEST_CREATE_MANDATE, () =>
      this.setState({ loading: true }),
    );
    this.createMandateSuccess = EventBus.on(
      ACTION_TYPES.CREATE_MANDATE_SUCCESS,
      this._createMandateSuccess,
    );
    this.createMandateFailure = EventBus.on(ACTION_TYPES.CREATE_MANDATE_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestCreateMandate();
    this.createMandateSuccess();
    this.createMandateFailure();
  }

  _getOrdinal(n) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  handleChoosenDate = (choosenDate) => {
    this.setState({ choosenDate });
  };

  _onCreateMandate = () => {
    const { savingPlan } = this.props;
    const { choosenDate } = this.state;
    const { id, goalAbsoluteAmount, startDate: start_date, paymentCalendar = {} } = savingPlan;
    const goalAmount = Number(goalAbsoluteAmount);
    const amount = goalAmount.toFixed(2);
    const transactionId = `${id}${new Date().valueOf()}`;

    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );

    let finalEndDate = '';
    if (sortedPaymentCalendarDates && sortedPaymentCalendarDates.length > 0) {
      const lastInstallmentDate = sortedPaymentCalendarDates[sortedPaymentCalendarDates.length - 1];
      finalEndDate = moment(lastInstallmentDate, 'DDMMYYYY').add(31, 'days').format('YYYY-MM-DD');
    }

    const mandate = {
      mandate_day: choosenDate,
      amount: goalAbsoluteAmount,
      start_date,
      end_date: finalEndDate,
      frequency: 'DAILY',
      partner_transaction_id: transactionId,
      saving_goal_id: id,
    };

    this.props.updateSavingPlan({ ...savingPlan, mandate });
    this.setState({ transactionId }, () => {
      if (IS_PRODUCTION) {
        this.props.requestCreateMandate({
          ...mandate,
          amount,
          end_date: finalEndDate,
        });
      } else {
        this.props.history.push('/checkout/sip/redirect?status=success');
      }
    });
  };

  _createMandateSuccess = (mandate) => {
    const { transactionId } = this.state;
    const { origin } = document.location;
    const redirectUrl = `${origin}/checkout/sip/redirect`;
    const mandateUrl = `${DIGIO_ENDPOINT_URL}/#/enach-mandate-direct/${mandate.mandateId}/${transactionId}/${mandate.userId}?token_id=${mandate.accessTokenId}&redirect_url=${redirectUrl}`;
    window.location.href = mandateUrl;
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user, savingPlan } = this.props;
    const { productOffer, paymentCalendar, goalAbsoluteAmount, paymentMethod } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const bookingAmountKey = sortedPaymentCalendarDates[0];
    // const monthlyPaymentKey = sortedPaymentCalendarDates[1];
    const bookingAmount = paymentCalendar[bookingAmountKey];
    // const monthlyPaymentAmount = paymentCalendar[monthlyPaymentKey];

    return (
      <div className={styles['sip']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={cx(styles['header'], styles['mobile'])}>
              <div className={styles['inner-header']}>
                <div className={styles['sub-inner-header']}>
                  <div className={styles['title']}>{productOffer?.productOfferName}</div>
                  <div className={styles['price']}>{Convert.currency(goalAbsoluteAmount)}</div>
                  <div className={styles['note']}>Total amount to be saved</div>
                  <div className={styles['amount']}>
                    <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid :{' '}
                    {Convert.currency(bookingAmount)}
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(styles['progress-bar'], styles['mobile'])}>
              <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
            </div>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>
                {/* {paymentMethod === 'book_save_buy'
                  ? 'Setup your savings plan'
                  : 'Setup your payment plan'} */}
                Your payment plan
              </div>
              <div className={styles['line']} />

              <div className={styles['content-wrapper-box']}>
                {/* <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Monthly Investment </div>
                  <div className={styles['right']}>{Convert.currency(monthlyPaymentAmount)}</div>
                </div>
                <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Length of SIP </div>
                  <div className={styles['right']}>{sortedPaymentCalendarDates.length - 1} Months</div>
                </div> */}
                <OfferTabItem
                  {...savingPlan}
                  type="pay_in_instalments"
                  // onProceedAutoPay={this._onProceed}
                  autopay
                />
                {/* <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Choose a date</div>
                  <div className={styles['right']}>
                    <Dropdown className={styles['main-dropdown']}>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        {this._getOrdinal(choosenDate)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={styles['dropdown-menu']}>
                        {new Array(31).fill(0).map((d, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => this.handleChoosenDate(index + 1)}
                          >
                            {this._getOrdinal(index + 1)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div> */}
              </div>
              <div className={styles['divider']} />
              {/* {
                paymentMethod === 'book_save_buy'
                ?
                <div className={styles['icici-note']}>
                  The money you are saving will be invested in{' '}
                  <a href="#"> ICICI Prudential Liquid Fund.</a> <br /> You can redeem your
                  investment anytime you need to by logging into your account.
                </div>
                :
                null
              }               */}
              <div className={styles['secure']}>
                <img src="/img/secure-lock.svg" alt="secure" className={styles['secure-lock']} />
                <span>This transaction is secured by</span>
                <img src="/img/logo-savvy.svg" alt="logo-savvy" />
              </div>
            </div>
            <div className={styles['footer']} onClick={this._onCreateMandate}>
              {paymentMethod === 'book_save_buy' ? 'SETUP AUTO-SAVE' : 'SETUP AUTO-PAY'}
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox
              partner={partner}
              product={product}
              user={user}
              onProceedPayment={this._onProceedPayment}
            />
          </div>
        </div>
        {/* <div className={styles['pay-box']}>
          <div className={styles['left-box']}> */}
        {/* <div className={styles['header']}>
              <div className={styles['inner-header']}>
                <div className={styles['sub-inner-header']}>
                  <div className={styles['title']}>[Offer Name]</div>
                  <div className={styles['price']}>Rs. 10,000</div>
                  <div className={styles['note']}>Total amount to be saved</div>
                </div>
              </div>
            </div>

            <div className={styles['progress-bar']}>
              <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
            </div>
            <div className={styles['note-title']}>setup your savings plan</div>
            <div className={styles['content-wrapper-box']}>
              <div className={styles['content-wrapper']}>
                <div className={styles['left']}>Monthly Investment </div>
                <div className={styles['right']}>Rs. 2000</div>
              </div>
              <div className={styles['content-wrapper']}>
                <div className={styles['left']}>Length of SIP </div>
                <div className={styles['right']}>5 Months</div>
              </div>
              <div className={styles['content-wrapper']}>
                <div className={styles['left']}>Choose a date</div>
                <div className={styles['right']}>
                  <Dropdown className={styles['main-dropdown']}>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      {this._getOrdinal(choosenDate)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles['dropdown-menu']}>
                      {new Array(31).fill(0).map((d, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => this.handleChoosenDate(index + 1)}
                        >
                          {this._getOrdinal(index + 1)}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div> */}
        {/* <div className={styles['divider']} />
            <div className={styles['icici-note']}>
              The money you are saving will be invested in{' '}
              <a href="#"> ICICI Prudential Liquid Fund.</a> You can redeem your investment anytime
              you need to by logging into your account.
            </div>
            <div className={styles['secure']}>
              <img src="/img/secure-lock.svg" alt="secure" className={styles['secure-lock']} />
              <span>This transaction is secured by</span>
              <img src="/img/logo-savvy.svg" alt="logo-savvy" />
            </div>
            <div className={styles['proceed-btn']}>START SAVING</div> */}
        {/* </div>
        </div> */}
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default Sip;

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminGoalsReducer(state = INITIAL_STATE.adminGoals, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_GOAL_DETAIL_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.ADMIN_GOALS_LIST_SUCCESS:
      return {...action.payload};

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminGoals;

    default:
      return state;
  }
}

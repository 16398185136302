import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Screen, Wizard, Accordion, SavvySupport } from '../../components';
// import PanVerification from './pan-verification';
import DemographicInfo from './demographic-info';
import PanVerification from './pan-verification';
import AddressVerification from './address-verification';
import PersonalVerification from './personal-verification';
import VideoVerification from './video-verification';
import SelfieVerification from './selfie-verification';
import ReviewSummary from './review-summary';
import styles from './_kyc.module.scss';

class LongKyc extends Component {
  constructor(props) {
    super(props);

    let activeKey = 0;
    let completedStep = 0;

    if (
      !props.user?.folio?.gender ||
      !props.user?.folio?.maritalStatus ||
      !props.user?.folio?.occupationCode ||
      !props.user?.folio?.annualIncome
    ) {
      activeKey = 0;
    } else if (!props.user.folio || !props.user.folio.panNumber) {
      activeKey = 1;
      completedStep = 0;
    } else if (!props.user.folio.addressProofImageUrl) {
      activeKey = 2;
      completedStep = 1;
    } else if (!props.user.folio.signatureImageUrl) {
      activeKey = 3;
      completedStep = 2;
    } else if (!props.user.folio.videoUrl) {
      activeKey = 4;
      completedStep = 3;
    } else if (!props.user.folio.selfieImageUrl) {
      activeKey = 5;
      completedStep = 4;
    } else {
      activeKey = 6;
      completedStep = 5;
    }
    
    this.state = {
      activeKey,
      completedStep
    };
  }

  _onChange = (activeKey) => {
    this.setState({ activeKey });
  };

  _onUserInfoComplete = () => {    
    this.setState({ activeKey: 1 });
  };

  _onPanVerificationComplete = () => {
    this.setState({ activeKey: 2 });
  };

  _onAddressVerificationComplete = () => {
    this.setState({ activeKey: 3 });
  };

  _onPersonalVerificationComplete = () => {
    this.setState({ activeKey: 4 });
  };

  _onVideoVerificationComplete = () => {
    this.setState({ activeKey: 5 });
  };

  _onSelfieVerificationComplete = () => {
    this.setState({ activeKey: 6 });
  };

  _onReviewedSummary = (activeKey) => {
    this.setState({ activeKey });
  };

  render() {    
    return (
      <Screen>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <Wizard progress={75} />            
          </Col>
        </Row>
        <Row>
          <Col xs={12}>            
            <Accordion
              activeKey={this.state.activeKey}
              onChange={this._onChange}
              completedStep={this.state.completedStep}
              list={[
                {
                  isVisible: true,
                  title: 'Demographic Information',
                  className: styles['demographic-info'],
                  component: <DemographicInfo onComplete={this._onUserInfoComplete} />,
                },
                {
                  isVisible: true,
                  title: 'Pan Verification',
                  className: styles['pan-verification'],
                  component:
                    this.state.activeKey === 1 ? (
                      <PanVerification onComplete={this._onPanVerificationComplete} />
                    ) : null,
                },
                {
                  isVisible: true,
                  title: 'Address Verification',
                  className: styles['pan-verification'],
                  component:
                    this.state.activeKey === 2 ? (
                      <AddressVerification onComplete={this._onAddressVerificationComplete} />
                    ) : null,
                },
                {
                  isVisible: true,
                  title: 'Signature Verification',
                  className: styles['pan-verification'],
                  component:
                    this.state.activeKey === 3 ? (
                      <PersonalVerification onComplete={this._onPersonalVerificationComplete} />
                    ) : null,
                },
                {
                  isVisible: true,
                  title: 'Video Verification',
                  className: styles['pan-verification'],
                  component:
                    this.state.activeKey === 4 ? (
                      <VideoVerification onComplete={this._onVideoVerificationComplete} />
                    ) : null,
                },
                {
                  isVisible: true,
                  title: 'Selfie Verification',
                  className: styles['pan-verification'],
                  component:
                    this.state.activeKey === 5 ? (
                      <SelfieVerification onComplete={this._onSelfieVerificationComplete} />
                    ) : null,
                },
                {
                  isVisible: true,
                  title: 'Review Summary',
                  className: styles['bank-account'],
                  component:
                    this.state.activeKey === 6 ? (
                      <ReviewSummary onComplete={this._onReviewedSummary} />
                    ) : null,
                },
              ]}
            />
          </Col>
        </Row>
        <SavvySupport />
      </Screen>
    );
  }
}

export default withRouter(LongKyc);

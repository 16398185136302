import {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,
  requestGeneratePayment,
  updateCredentials,
  updateProduct,
} from 'src/redux/actions';
import { connect } from 'react-redux';

import ReceivedPaymentSetup from './received-payment-setup';

function mapStateToProps(state) {
  return {
    showKyc: !state.user.folio || !state.user.folio.folioNumber,
    user: state.user,
    savingGoal: state.savingGoal,
    product: state.product,
    partner: state.partner,
  };
}

export default connect(mapStateToProps, {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,
  requestGeneratePayment,
  updateCredentials,
  updateProduct,
})(ReceivedPaymentSetup);

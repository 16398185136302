import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Convert } from 'src/helpers';
import _ from 'lodash';
import styles from './_userDetailsRead.module.scss';

export default function UserDetailsRead(props) {
  const { user } = props;
  const savingGoals = user?.savingGoals;
  const uniqueProducts = _.uniq(_.map(savingGoals, 'description'));
  const contributions = [];
  const withdrawals = [];

  _.map(savingGoals, (goal) => contributions.concat(goal.savingGoalContributions));
  _.map(savingGoals, (goal) => withdrawals.concat(goal.savingGoalWithdrawals));

  const totalDeposited =
    _.sumBy(
      contributions,
      (contribution) =>
        (contribution.status === 'completed' || contribution.status === 'pending_investment') &&
        Number(contribution.amount),
    ) || 0;
  const totalWithdrawn =
    _.sumBy(
      withdrawals,
      (withdrawal) =>
        (withdrawal.status === 'completed' ||
          withdrawal.status === 'pending' ||
          withdrawal.transfer) &&
        Number(withdrawal.amount),
    ) || 0;

  return (
    <div className={styles['user-details-read']}>
      <div>
        <div className={styles['main-heading']}>
          <div className={styles['title']}>
            {user?.firstName} {user?.lastName} has :
          </div>
          <div className={styles['content']}>
            <Row>
              <Col xs={12} sm={4}>
                <div className={styles['content-box']}>
                  <div className={styles['label']}>{totalDeposited}</div>
                  <div className={styles['text']}>DEPOSITED</div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className={styles['content-box']}>
                  <div className={styles['label']}>{totalWithdrawn}</div>
                  <div className={styles['text']}>WITHDRAWN </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className={styles['content-box']}>
                  <div className={styles['label']}>{uniqueProducts.length}</div>
                  <div className={styles['text']}>NO.OF PRODUCTS INVESTED IN</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles['profile']}>
          <div className={styles['profile-container']}>
            <Row>
              {/* <Col xs={12} sm={3}>
                <div className={styles['profile-img']}>
                  <img src="/img/main-profile.png" alt="profile" />
                </div>
              </Col> */}
              <Col xs={12} sm={12}>
                <div className={styles['profile-content']}>
                  <div className={styles['profile-title']}>Demographic Details</div>
                  <div className={styles['profile-list']}>
                    <div className={styles['profile-data']}>
                      <div className={styles['profile-label']}>Name :&nbsp; </div>
                      <div className={styles['profile-text']}>
                        {' '}
                        {user?.firstName} {user?.lastName}
                      </div>
                    </div>
                    <div className={styles['profile-data']}>
                      <div className={styles['profile-label']}>Phone Number :&nbsp; </div>
                      <div className={styles['profile-text']}>{user?.phoneNumber}</div>
                    </div>
                    <div className={styles['profile-data']}>
                      <div className={styles['profile-label']}>Email ID :&nbsp; </div>
                      <div className={styles['profile-text']}>{user?.email}</div>
                    </div>
                    {/* <div className={styles['profile-data']}>
                      <div className={styles['profile-label']}>PAN Card :&nbsp; </div>
                      <div className={styles['profile-text']}>{user?.folio?.panNumber || 'N/A'}</div>
                    </div>
                    <div className={styles['profile-data']}>
                      <div className={styles['profile-label']}>Bank Account Number :&nbsp; </div>
                      <div className={styles['profile-text']}>
                        {user?.withdrawalBankAccount?.accountNumber || 'N/A'}
                      </div>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={styles['offer-table']}>
        <div className={styles['offer-table-heading']}>Purchases</div>
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Started Date</th>
              <th>Ending Date</th>
              <th>Cost of Offer</th>
            </tr>
          </thead>
          <tbody>
            {savingGoals &&
              savingGoals.length > 0 &&
              savingGoals.map((data, index) => {
                const { description, startDate, endDate, goalAbsoluteAmount, productOffer } =
                  data || {};

                const totalOfferCost =
                  Number(goalAbsoluteAmount || 0) + Number(productOffer?.upfrontPaymentAmount || 0);
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{description}</td>
                      <td>{startDate && moment(startDate, 'DD/MM/YYYY').format('MMMM Do YYYY')}</td>
                      <td>{endDate && moment(endDate, 'DD/MM/YYYY').format('MMMM Do YYYY')}</td>
                      <td> {Convert.toCurrencyValue(totalOfferCost || 0)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

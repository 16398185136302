import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import { Link, withRouter } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import queryString from 'query-string';
import { ACTION_TYPES } from 'src/constants';
import { Screen, Wizard, Loader, BootstrapModal, SavvySupport } from 'src/components';
import moment from 'moment';
import { Convert } from 'src/helpers';
import './_custom-modal.scss';

const SAVE_NOW_BUY_LATER = 'snbl';

const PRODUCT_PROVIDER = { NAME: 'icici_prudential', ID: 1565 };
const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';
const PAYU_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://secure.payu.in/_payment'
    : 'https://test.payu.in/_payment';

class Payment extends Component {
  paymentForm = null;

  state = {
    showSuccessModal: false,
    showErrorModal: false,
    errorReason: '',
    payment: {},
    loading: true,
    retrying: false,
  };

  componentDidMount() {
    this.createSavingGoalSubscription = EventBus.on(ACTION_TYPES.CREATE_SAVING_GOAL, () =>
      this.setState({ loading: true }),
    );
    this.createSavingGoalSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_SAVING_GOAL_SUCCESS,
      this._createSavingGoalCreationSuccess,
    );
    this.createSavingGoalFailureSubscription = EventBus.on(
      ACTION_TYPES.CREATE_SAVING_GOAL_FAILED,
      () => this.setState({ loading: false }),
    );

    this.requestPaymentSubscription = EventBus.on(ACTION_TYPES.REQUEST_GENERATE_PAYMENT, () =>
      this.setState({ retrying: true }),
    );
    this.paymentSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.GENERATE_PAYMENT_SUCCESS,
      this._onPaymentGenerated,
    );
    this.paymentSubscriptionFailure = EventBus.on(ACTION_TYPES.GENERATE_PAYMENT_FAILED, () =>
      this.setState({ retrying: false }),
    );

    const { status, reason = '' } = queryString.parse(this.props.location.search);

    if (status === 'success' && !this.props.savingPlan.paymentCompleted) {
      this._createSavingGoal();
    }
    if (status === 'success' && this.props.savingPlan.paymentCompleted) {
      this.setState({ loading: false, showSuccessModal: true });
    } else if (status === 'failure') {
      this.setState({ loading: false, showErrorModal: true, errorReason: reason });
    }
  }

  componentWillUnmount() {
    this.createSavingGoalSubscription();
    this.createSavingGoalSuccessSubscription();
    this.createSavingGoalFailureSubscription();

    this.requestPaymentSubscription();
    this.paymentSubscriptionSuccess();
    this.paymentSubscriptionFailure();
  }

  _createSavingGoal = () => {
    const { product, savingPlan } = this.props;
    const { paymentCalendar = {}, paymentId, totalPax, discountedPrice, paymentMethod, finalDate } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const firstPaymentAmount = paymentCalendar[firstPaymentKey] * totalPax;
    const goalAbsoluteAmount = discountedPrice - firstPaymentAmount;       
    const imageLink =
      product?.imageLink ||
      (product?.imageUrls && product?.imageUrls.length > 0 && product?.imageUrls[0]?.url);
    
    this.props.createSavingGoal({
      saving_goal: {
        payment_method: paymentMethod,
        first_payment_id: paymentId,
        description: product.productName,
        saving_goal_category: SAVE_NOW_BUY_LATER,
        goal_absolute_amount: goalAbsoluteAmount,
        end_date: finalDate,
        preferred_product_provider: PRODUCT_PROVIDER.NAME,
        preferred_product_id: PRODUCT_PROVIDER.ID,
        product_offer_uuid: savingPlan.uuid,
        unsplash_image: {
          link: imageLink || '',
        },
        routine: {
          time_interval: 'scheduled',
          automated: true,
        },
        multiplier: totalPax,
      },
    });
  };

  _createSavingGoalCreationSuccess = () => {
    const { savingPlan } = this.props;
    savingPlan.paymentCompleted = true;

    this.props.updateSavingPlan(savingPlan);
    this.setState({ loading: false, showSuccessModal: true });
  };

  _verifyKyc = () => {
    this.setState({ showSuccessModal: false }, () => {
      this.props.history.push('/kyc');
    });
  };

  _setupSIP = () => {
    this.setState({ showSuccessModal: false }, () => {
      this.props.history.push('/setup-sip');
    });
  };

  _setupAutoPay = () => {
    this.setState({ showSuccessModal: false }, () => {
      this.props.history.push('/auto-pay');
    });
  }

  _retryPayment = () => {
    const { user, savingPlan } = this.props;
    const { paymentCalendar, totalPax = 1 } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const firstPaymentAmount = paymentCalendar[firstPaymentKey] * totalPax;
    const { origin } = document.location;

    this.props.requestGeneratePayment({
      payment: {
        amount: firstPaymentAmount,
        info: savingPlan.description,
        user_email: user.email,
        user_first_name: user.firstName,
        success_url: `${origin}/payment?status=success`,
        failure_url: `${origin}/payment?status=failure`,
      },
    });
  };

  _onPaymentGenerated = (response) => {
    const payment = {
      ...response.payment,
      hash: response.hash,
    };

    this.setState({ payment }, () => {
      const { savingPlan } = this.props;
      this.props.updateSavingPlan({ ...savingPlan, paymentId: response.payment.id });
      this.paymentForm.submit();
    });
  };

  render() {
    const { showSuccessModal, showErrorModal, payment, errorReason } = this.state;
    const { savingPlan } = this.props;
    const { paymentCalendar = {}, totalPax, paymentMethod } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const firstPaymentAmount = paymentCalendar[firstPaymentKey] * totalPax;
    const totalOfferPrice = Number(firstPaymentAmount) + Number(savingPlan.goalAbsoluteAmount);    
    const offerName = this.props?.savingPlan?.productOffer?.productOfferName;

    return (
      <Screen>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <Wizard progress={50} />
          </Col>
        </Row>
        <BootstrapModal show={showSuccessModal} className="custom-modal">
          <div className="lock-fare-deposite-box">
            <Player
              autoplay
              keepLastFrame
              src="https://assets7.lottiefiles.com/packages/lf20_te6kna0d.json"
              className="lock-fare-ok-icon"
            />
            <div className="payment-ref">Payment ID: {savingPlan?.paymentRequestId}</div>
            <div className="payment-ref">Offer Name: {offerName}</div>
            <div className="lock-fare-thank-you">
              Thank you for your payment of {Convert.currency(firstPaymentAmount)}
              <br />
              you have claimed the offer of {Convert.currency(totalOfferPrice)}!
            </div>
            <div className="payment-receipt">
              The receipt will be sent to you via email and whatsapp as well
            </div>
            <div className="lock-fare-start-saving">
              Start saving for the remaining {Convert.currency(savingPlan.goalAbsoluteAmount)}!
            </div>
            {this.props.showKyc && paymentMethod === 'book_save_buy' ? (
              <div className="kyc-download-btns">
                <button className="verify-kyc-btn" onClick={this._verifyKyc}>
                  VERIFY KYC
                </button>
                {/* <button className="download-btn">DOWNLOAD RECEIPT</button> */}
              </div>
            ) : (
              <>
                { 
                  paymentMethod === 'book_save_buy' 
                  ?
                  <button className="verify-kyc-btn" onClick={this._setupSIP}>                
                    SETUP SIP
                  </button>
                  :
                  <button className="verify-kyc-btn" onClick={this._setupAutoPay}>                
                    SETUP PAYMENT PLAN
                  </button>
                }
              </>              
            )}
          </div>
        </BootstrapModal>
        <BootstrapModal show={showErrorModal} className="custom-modal">
          <div className="lock-fare-deposite-box">
            <Player
              autoplay
              keepLastFrame
              src="https://assets8.lottiefiles.com/temp/lf20_yYJhpG.json"
              className="lock-fare-error-icon"
            />
            <div className="lock-fare-start-saving">Payment failed!</div>
            <div className="lock-fare-failed">
              Your payment of {Convert.currency(firstPaymentAmount)} failed.              
            </div>
            { errorReason && <div className="lock-fare-failed-reason"><b>Reason:</b> {errorReason}</div>}
            <div className="lock-fare-failed-support">
              If the amount is deducted from your bank account, please get in touch with us at
              hello@savvyapp.in
            </div>
            <button className="verify-kyc-btn" onClick={this._retryPayment}>
              RETRY PAYMENT
            </button>
            <div className="go-back">
              <Link to="/checkout">Go Back</Link>
            </div>
          </div>
        </BootstrapModal>
        <form action={PAYU_ENDPOINT_URL} method="post" ref={(ref) => (this.paymentForm = ref)}>
          <input type="hidden" name="key" value={payment.merchantKey} />
          <input type="hidden" name="txnid" value={payment.requestId} />
          <input type="hidden" name="productinfo" value={payment.info} />
          <input type="hidden" name="amount" value={payment.amount} />
          <input type="hidden" name="email" value={payment.userEmail} />
          <input type="hidden" name="firstname" value={payment.userFirstName} />
          <input type="hidden" name="phone" value={payment.phoneNumber} />
          <input type="hidden" name="hash" value={payment.hash} />
          <input
            type="hidden"
            name="surl"
            value={`${API_ENDPOINT_URL}/api/partners/payu/payments/success`}
          />
          <input
            type="hidden"
            name="furl"
            value={`${API_ENDPOINT_URL}/api/partners/payu/payments/failure`}
          />
        </form>
        <Loader loading={this.state.loading} title="Verifying payment, please wait..." />
        <Loader loading={this.state.retrying} title="Retrying payment, please wait..." />
        <SavvySupport />
      </Screen>
    );
  }
}

export default withRouter(Payment);

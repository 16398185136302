import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { Loader } from 'src/components';
import styles from './review-pay-long-kyc.module.scss';
import OfferBox from '../pay/offer-box';
// import VerifyKyc from './verify-kyc';
import Demographic from './demographic';
import PanCard from './pan-card';
import AddressProof from './address-proof';
import LinkAccount from './link-account';
import ReviewInfo from './review-info';
import ReviewSignature from './review-signature';
import ReviewSelfie from './review-selfie';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class ReviewPayLongKyc extends Component {
  state = {
    loading: false,
    oldIndex: 0,
    // currentIndex: 0,
    // showBack: false,
  };

  settings = {
    dots: false,
    swipe: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  componentDidMount() {}

  componentWillUnmount() {}

  _onNext = () => {
    this.slider.slickNext();
  };

  _onPrev = () => {
    this.slider.slickPrev();
  };

  _onGoto = (index) => {
    this.slider.slickGoTo(index);
  };

  _onGoBack = () => {
    this._onGoto(this.state.oldIndex);
  };

  _navigateToSIP = () => {
    this.props.history.push('/checkout/sip');
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user, kyc } = this.props;

    return (
      <>
        <div className={styles['pay-kyc']}>
          <div className={styles['pay-box']}>
            <div className={styles['left-box']}>
              <Slider ref={(slider) => (this.slider = slider)} {...this.settings}>
                {/* <div>
                  <VerifyKyc onNext={this._onNext} onPrev={this._onPrev} onGoto={this._onGoto} />
                </div> */}

                <div>
                  <Demographic
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    {...user}
                    {...kyc}
                  />
                </div>
                <div>
                  <PanCard
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    {...user}
                    {...kyc}
                  />
                </div>
                <div>
                  <AddressProof
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    {...user}
                    {...kyc}
                  />
                </div>
                <div>
                  <ReviewSignature
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    {...user}
                    {...kyc}
                  />
                </div>

                <div>
                  <ReviewInfo
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    {...user}
                    {...kyc}
                  />
                </div>
                <div>
                  <ReviewSelfie
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    {...user}
                    {...kyc}
                  />
                </div>
                <div>
                  <LinkAccount
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    {...user}
                    {...kyc}
                  />
                </div>
              </Slider>
            </div>

            <div className={styles['right-box']}>
              <OfferBox partner={partner} product={product} user={user} />
            </div>
          </div>
          {/* <ExtraInfo /> */}

          <Loader loading={loading} fixed />
        </div>
      </>
    );
  }
}

export default withRouter(ReviewPayLongKyc);

import { connect } from 'react-redux'
import { requestAdminPartnerList } from 'src/redux/actions'
import AdminPartners from './AdminPartners'

function mapStateToProps(state){
  return{
    adminPartners: state.adminPartners,
    admin: state.admin
  }
}

export default connect(mapStateToProps, {
  requestAdminPartnerList
})(AdminPartners)
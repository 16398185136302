import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function MandateReducer(state = INITIAL_STATE.mandates, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_MANDATE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.mandates;
    }

    default:
      return state;
  }
}

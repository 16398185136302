import React, { Component } from 'react';
import { Modal, Row, Col, Dropdown } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { TextInputFive, InputTextarea } from 'src/components/form-inputs';
import * as yup from 'yup';
import cx from 'classnames';
import { notify } from 'react-notify-toast';
import './_modal.scss';
import axios from 'axios';

const API_ENDPOINT_URL = process.env.REACT_APP_ENV === 'production' ? '' : 'http://localhost:5000';

const QUERIES = {
  REQUEST: 'Request a callback',
  LEARN: 'Learn more about SavvyPay',
  CUSTOMIZE: 'Customise the Offer',
  OTHER: 'Other',
};

class ContactUsModal extends Component {
  state = {
    selectedQuery: QUERIES.REQUEST,
    isLoading: false,
  };

  handleSelectedQuery = (value) => {
    this.setState({ selectedQuery: value });
  };

  _validationSchema = yup.object().shape({
    phone_number: yup
      .string()
      .required('Please enter phone number.')
      .matches(/^[6-9]\d{9}$/, {
        message: 'Phone number not valid',
      }),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter email address.'),
    name: yup.string().required('Please enter name.'),
    content: yup.string().required('Please enter message.'),
  });

  sendMessage = async (params) => {
    const { selectedQuery } = this.state;
    const { closeModal, callback } = this.props;
    this.setState({ isLoading: true });
    axios({
      method: 'POST',
      url: `${API_ENDPOINT_URL}/services/contact`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        name: params?.name,
        email: params?.email,
        phoneNumber: params?.phone_number,
        subject: selectedQuery,
        message: params?.content,
        merchantEmail: this.props.partner?.email,
      },
    })
      .then((res) => {
        if (res?.status === 200) {
          notify.show('Message sent successfully', 'success');
          closeModal();
          if (callback) callback();
        } else {
          notify.show(res?.error || 'Something went wrong', 'error');
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        notify.show('Something went wrong', 'error');
      });
  };

  render() {
    const { isShow, closeModal } = this.props;
    const { selectedQuery, isLoading } = this.state;

    return (
      <div>
        <Modal className="contact-us-works-modal" show={isShow}>
          <div className="title pb-5">Contact Us</div>
          <div className="close-btn">
            <span onClick={closeModal}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </span>
          </div>
          <Formik
            initialValues={{
              name: '',
              phone_number: '',
              email: '',
              content: '',
            }}
            validationSchema={this._validationSchema}
            onSubmit={(values) => {
              this.sendMessage(values);
            }}
          >
            {() => (
              <Form>
                <div className="pb-3 mb-2">
                  <TextInputFive
                    label="Name"
                    name="name"
                    id="name"
                    placeholder="Please enter name"
                  />
                </div>
                <div className="pb-3 mb-2">
                  <Row>
                    <Col sm={6}>
                      <TextInputFive
                        label="Phone Number"
                        name="phone_number"
                        id="phone_number"
                        placeholder="+91 98210 09873"
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInputFive
                        label="Email ID"
                        name="email"
                        id="email"
                        placeholder="jdoe@gmail.com"
                        type="email"
                      />
                    </Col>
                  </Row>
                </div>

                <div className="text-dropdown pb-5">
                  <div className="text-label">Type of Query</div>
                  <Dropdown className="custom-dropdown">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basics"
                      className="custom-dropdown-btn"
                    >
                      {selectedQuery}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="custom-dropdown-item">
                      {Object.entries(QUERIES).map(([, value]) => (
                        <Dropdown.Item onClick={() => this.handleSelectedQuery(value)} key={value}>
                          {value}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="text-content">
                  <InputTextarea
                    label="Please let us know how we can help?"
                    name="content"
                    id="content"
                    rows={8}
                    textarea="text-content-area"
                  />
                </div>
                <div className="custom-btn-group">
                  <button
                    className={cx('custom-btn', {
                      'disabled-btn': isLoading,
                    })}
                    disabled={isLoading}
                  >
                    {isLoading ? 'LOADING...' : 'SUBMIT QUERY'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}

export default ContactUsModal;

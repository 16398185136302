import { ACTION_TYPES } from 'src/constants';

export function createSavingGoal(payload) {
  return {
    type: ACTION_TYPES.CREATE_SAVING_GOAL,
    payload,
  };
}

export function createSavingGoalSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_SAVING_GOAL_SUCCESS,
    payload,
  };
}

export function createSavingGoalFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_SAVING_GOAL_FAILED,
    payload,
  };
}

export function cancelPurchaseRequest(payload) {
  return {
    type: ACTION_TYPES.CANCEL_PURCHASE_REQUEST,
    payload
  }
}

export function cancelPurchaseSuccess(payload) {
  return {
    type: ACTION_TYPES.CANCEL_PURCHASE_SUCCESS,
    payload
  }
}

export function cancelPurchaseFailure(payload) {
  return {
    type: ACTION_TYPES.CANCEL_PURCHASE_FAILED,
    payload
  }
}


/**
 * Get goal progress list
 */
export function requestGoalList(payload){
  return {
    type: ACTION_TYPES.REQUEST_GOAL_LIST,
    payload
  }
}

export function requestGoalListSuccess(payload){
  return {
    type: ACTION_TYPES.REQUEST_GOAL_LIST_SUCCESS,
    payload
  }
}

export function requestGoalListFailed(payload){
  return {
    type: ACTION_TYPES.REQUEST_GOAL_LIST_FAILED,
    payload
  }
}

/**
 * Get goal progress detail
 */
export function requestGoalDetail(payload){
  return {
    type: ACTION_TYPES.REQUEST_GOAL_DETAIL,
    payload
  }
}

export function requestGoalDetailSuccess(payload){
  return {
    type: ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS,
    payload
  }
}

export function requestGoalDetailFailed(payload){
  return {
    type: ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED,
    payload
  }
}

import React, { Component } from 'react';
import { Accordion as BoostrapAccordion, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import styles from './_accordion.module.scss';

class Accordion extends Component {
  render() {
    const { completedStep = 100 } = this.props;

    return (
      <BoostrapAccordion
        className={styles['accordion']}
        activeKey={`item-${this.props.activeKey}`}
        defaultActiveKey={this.props.defaultActiveKey}
      >
        <div>
          {this.props.list &&
            this.props.list.length > 0 &&
            this.props.list.map((item, index) => {
              if (!item?.isVisible) return null;
              return (
                <div
                  className={cx(styles['accordion-wrapper'], item.className)}
                  key={`accordion-item-${index}`}
                >
                  <BoostrapAccordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={`item-${index}`}
                    className={cx(styles['button'], { [styles['disabled']]: completedStep < index - 1 })}
                    onClick={() => this.props.onChange && (completedStep + 1 >= index) && this.props.onChange(index)}
                  >
                    <div className={styles['title']}>{item.title}</div>
                    <div className={styles['icon']}>
                      <FontAwesomeIcon
                        icon={this.props.activeKey === index ? faChevronUp : faChevronDown}
                      />
                    </div>
                  </BoostrapAccordion.Toggle>
                  <BoostrapAccordion.Collapse eventKey={`item-${index}`}>
                    <div>{item.component}</div>
                  </BoostrapAccordion.Collapse>
                </div>
              );
            })}
        </div>
      </BoostrapAccordion>
    );
  }
}

export default Accordion;

import React from 'react'
import cx from 'classnames';
import styles from './_review-demographic-info.module.scss';

export default function ReviewDemoGraphicInfo(props) {  
  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <span className={styles.heading}>
          {props?.heading}
        </span>
      </div>      
      <div className={styles.contentContainer}>
        <div>
          <label>Full name:</label>
          <span>{props.firstName} {props.lastName}</span>
        </div>
        <div>
          <label>Email:</label>
          <span>{props.email}</span>
        </div>
        <div>
          <label>Gender:</label>
          <span>
            { props.userInfo.gender === 'M' ? 'Male' : props.gender === 'F' ? 'Female' : 'Others' }
          </span>
        </div>
        <div>
          <label>Marital Status:</label>
          <span>{props.userInfo.maritalStatus}</span>
        </div>
        <div>
          <label>Occupation:</label>
          <span>{props.userInfo.occupationDescription}</span>
        </div>
        <div>
          <label>Salary:</label>
          <span>{props.userInfo.salaryDescription}</span>
        </div>
      </div>      
    </div>
  )
}

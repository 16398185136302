import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { faEdit, faExternalLinkAlt, faCopy, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';
import { Badge } from 'src/components/theme';
import { ACTION_TYPES } from 'src/constants';
import EventingBus from 'eventing-bus';
import DataTable from '../../data-table';
import './_payment-links-table.style.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

const PaymentLinksTable = (props) => {  
  const [selectedItem, setSelectedItem] = useState({});
  const [showExpireConfirm, setShowExpireConfirm] = useState(false);
  const [isExpiring, setIsExpiring] = useState(false);

  useEffect(() => {
    const requestExpireLinkSubscribe = EventingBus.on(ACTION_TYPES.PAYMENT_EXPIRE_LINK_REQUEST, () => setIsExpiring(true));
    const requestExpireLinkSuccessSubscribe = EventingBus.on(ACTION_TYPES.PAYMENT_EXPIRE_LINK_SUCCESS, () => {
      setIsExpiring(false);
      setShowExpireConfirm(false);
      window.location.reload();
    });
    const requestExpireLinkFailedSubscribe = EventingBus.on(ACTION_TYPES.PAYMENT_EXPIRE_LINK_FAILED, () => setIsExpiring(false));

    return () => {
      requestExpireLinkSubscribe()
      requestExpireLinkSuccessSubscribe()
      requestExpireLinkFailedSubscribe()
    }
  }, [])

  const _onCopyLink = (e, referenceId) => {  
    const link = `${API_ENDPOINT_URL}?id=${referenceId}`; 
    const inputLink = document.getElementById("input-link");
    
    inputLink.value = link;
    inputLink.select();
    inputLink.setSelectionRange(0, 99999);

    document.execCommand('copy');
    
    inputLink.value = '';    
    notify.show('Link copied to clipboard', 'success');
    
    e.preventDefault();
  };

  const _expireLink = (item) => {    
    setSelectedItem(item)
    setShowExpireConfirm(true);
  }

  const onCloseExpireModal = () => setShowExpireConfirm(false);

  const onExpireSelectedLink = () => {        
    props.requestPaymentExpireLink({
      payment_link_id: selectedItem?.uuid,
    });
  }

  const tableStyles = {
    bodyRow: {
      backgroundColor: '#f3f3f3',
      borderBottom: '12px solid white',
      borderBottomColor: '#fff',
    },
  };

  const {totalItems} = props
  
  const pageSize = 50

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems/pageSize)
  }

  const columns = useMemo(
    () => [    
      {
        Header: 'Product/Offer',
        accessor: 'productId',
        disableSortBy: true,
        Cell: (props) => (
          <span>
            {
              props.row.original.productOfferUuid
              ?
              <a href={`product/offer/${props.row.original.productOfferUuid}`} target="_blank" rel="noreferrer">              
                Offer
              </a> 
              :
              null
            }           
          </span>
        ),
      },              
      {
        Header: 'Offer Name',
        accessor: 'productOfferName',
        disableSortBy: true,
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: 'Phone',
        accessor: 'sentToPhoneNumber',
        disableSortBy: true,
        Cell: ({ value }) => <span>{value}</span>,
      },      
      {
        Header: 'Purchases',
        accessor: 'savingGoalCount',
        disableSortBy: true,
        Cell: ({ value }) => <span>{value}</span>,
      },      
      {
        Header: 'Created At',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: 'Status',
        accessor: 'expiry',
        disableSortBy: false,
        Cell: ({ value }) => {
          let isActive = true;          
          if (value) isActive = moment().diff(moment(value)) < 0;

          return (
            <div className="badge-box">
              {isActive ? (
                <Badge variant="success" className='badge-style'>Valid</Badge>
              ) : (
                <Badge variant="error" className='badge-style'>Expired</Badge>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'uuid',
        disableSortBy: true,
        Cell: (props) => (
          <>
            <span>
              <Link title="Edit Link" to={`payment_links/${props.value}`}>
                <FontAwesomeIcon icon={faEdit} className="action-icon" />
              </Link>
            </span>
            <span>
              <a title="Open link in new tab" href={`${API_ENDPOINT_URL}?id=${props.row.original.referenceId}`} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faExternalLinkAlt} className="action-icon" />
              </a>
            </span>
            <span>
            <button title="Copy Link" onClick={(e) => _onCopyLink(e, props.row.original.referenceId)} className="copy-clipboard">              
              <FontAwesomeIcon icon={faCopy} className="action-icon" />              
            </button>
            <button title="Expire Link" onClick={() => _expireLink(props.row.original)} className="expire-link">              
              <FontAwesomeIcon icon={faCalendarTimes} className="action-icon" />              
            </button>
          </span>
        </>
        ),
      },
    ],
    [],
  );
  return (
    <div className="payment-links-table">      
      <DataTable 
        columns={columns} 
        data={props.data} 
        tableStyles={tableStyles} 
        paginationConfig={paginationConfig}
        handlePaginationChange={props.handlePaginationChange}
      />      
      <input id="input-link" className="link-input" />
      <Modal show={showExpireConfirm} size="lg">
        <div className="custom-modal-title">
          Are you Sure you want to <br />
          expire the payment link?
        </div>
        <div className="custom-modal-footer">
          <Button
            className="modal-btn"
            variant="primary"
            disabled={isExpiring}
            onClick={onExpireSelectedLink}
          >
            {isExpiring ? 'Expiring...' : 'Yes, I am sure'}
          </Button>
          <Button
            className="modal-btn"
            variant="primary"
            disabled={isExpiring}
            onClick={onCloseExpireModal}
          >
            No, don&apos;t expire the link
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentLinksTable;
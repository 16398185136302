import React, { Component } from 'react';
// import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { Screen, Input, Loader } from 'src/components';
import cx from 'classnames';
import { ACTION_TYPES } from 'src/constants';
import { ButtonMain } from 'src/components/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styles from './_admin-login.module.scss';

class AdminLogin extends Component {
  state = {
    loading: false,
    email: '',
    password: '',
    isVisiblePassword: false,
    isAcceptTermAndConditions: false,
  };

  componentDidMount() {
    this.requestSignInSubscription = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_LOGIN, () =>
      this.setState({ loading: true }),
    );
    this.signInSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_LOGIN_SUCCESS,
      this._onLoginSuccess,
    );
    this.signInSubscriptionFailure = EventBus.on(ACTION_TYPES.ADMIN_LOGIN_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestSignInSubscription();
    this.signInSubscriptionSuccess();
    this.signInSubscriptionFailure();
  }

  _onChangeEmail = (e) => {
    const email = e.target.value;
    this.setState({ email: (email && email.trim()) || '' });
  };

  _onChangePassword = (e) => {
    const password = e.target.value;
    this.setState({ password });
  };

  _onLoginSuccess = () => {
    this.setState({ loading: false }, () => {
      this.props.history.push('/admin');
    });
  };

  _onLogin = () => {
    const { email, password } = this.state;

    this.props.requestAdminLogin({
      email,
      password,
    });
  };

  _onSubmit = () => {
    if (this.state.isAcceptTermAndConditions) this._onLogin();
  };

  _onPressEnter = () => {
    if (this.state.isAcceptTermAndConditions) this._onLogin();
  };

  handlePasswordToggle = () => {
    this.setState({ isVisiblePassword: !this.state.isVisiblePassword });
  };

  _onAcceptTerms = () => {
    this.setState({ isAcceptTermAndConditions: !this.state.isAcceptTermAndConditions });
  };

  render() {
    const { isVisiblePassword, isAcceptTermAndConditions } = this.state;
    return (
      <Screen hideHeaderNav>
        <div className={styles['sign-in-container']}>
          <div className={styles['sign-in-form']}>
            <div>
              <h1 className={styles['title']}>Sign in to partner dashboard</h1>
              <p className={styles['description']}>Please enter your email and password</p>
            </div>
            <Input
              className={styles['email-address']}
              placeholder="Enter your email address"
              value={this.state.email}
              onChange={this._onChangeEmail}
              disabled={this.state.loading}
            />
            <div className={styles['password-box']}>
              <Input
                className={styles['password']}
                placeholder="Enter your password"
                value={this.state.password}
                onChange={this._onChangePassword}
                onPressEnter={this._onPressEnter}
                disabled={this.state.loading}
                type={isVisiblePassword ? 'text' : 'password'}
              />
              <div className={styles['password-icon']}>
                {isVisiblePassword ? (
                  <FontAwesomeIcon icon={faEye} onClick={this.handlePasswordToggle} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} onClick={this.handlePasswordToggle} />
                )}
              </div>
            </div>
            <div className={styles['admin-merchant-container']}>
              <input
                type="checkbox"
                className={styles['admin-merchant-checkbox']}
                onChange={this._onAcceptTerms}
              />
              <div className={styles['admin-merchant-text']}>
                Accept savvy&apos;s&nbsp;
                <a
                  href="https://www.savvyapp.in/merchant-agreement"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
              </div>
            </div>
            <ButtonMain
              variant="compact"
              align="center"
              className={cx(styles['button'], {
                [styles['disabled-btn']]:
                  !this.state.email || !this.state.password || !isAcceptTermAndConditions,
              })}
              disabled={!this.state.email || !this.state.password || !isAcceptTermAndConditions}
              onClick={this._onSubmit}
            >
              Sign In
            </ButtonMain>
            <Loader loading={this.state.loading} />
          </div>
        </div>
      </Screen>
    );
  }
}

export default withRouter(AdminLogin);

import { ACTION_TYPES } from 'src/constants';

export function requestAdminGoalDetail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_GOAL_DETAIL,
    payload
  };
}

export function adminGoalDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_GOAL_DETAIL_SUCCESS,
    payload
  };
}

export function adminGoalDetailFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_GOAL_DETAIL_FAILED,
    payload
  };
}

export function requestAdminGoalsList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_GOALS_LIST,
    payload,
  };
}

export function adminGoalsListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_GOALS_LIST_SUCCESS,
    payload,
  };
}

export function adminGoalsListFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_GOALS_LIST_FAILED,
    payload,
  };
}

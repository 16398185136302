import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function createFolio(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.CREATE_FOLIO,
    data: params,
  }).then((response) => response.data);
}

export function folioWithdraw(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.FOLIO_WITHDRAW,
    data: params,
  }).then((response) => response.data);
}

export function requestSendWithdrawOtp(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.FOLIO_WITHDRAW_SEND_OTP,
    data: params,
  })
  .then(response => response.data);
}

export function requestResendWithdrawOtp(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.FOLIO_WITHDRAW_RESEND_OTP,
    data: params,
  })
  .then(response => response.data);
}

export function requestValidateWithdrawOtp(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.FOLIO_WITHDRAW_VALIDATE_OTP,
    data: params,
  })
  .then(response => response.data);
}

export function checkInstantWithdraw({ scheme_code }) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.CHECK_INSTANT_WITHDRAW}?scheme_code=${scheme_code}`,
  })
  .then(response => response.data);
}
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BootstrapModal, DrawerModal } from 'src/components';
import { Formik, Form as FormikForm } from 'formik';
import { TextInputThree } from 'src/components/form-inputs';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import * as yup from 'yup';
import cx from 'classnames';
import Countdown from 'react-countdown';
import { Player } from '@lottiefiles/react-lottie-player';

import styles from './link-account.module.scss';

class LinkAccount extends Component {
  state = {
    showPennyCheck: false,
    loading: false,
  };

  _validationSchema = yup.object().shape({
    account_number: yup.string().required('Please enter your account number'),
    ifsc_code: yup.string().required('Please enter your IFSC Code'),
  });

  componentDidMount() {
    this.sendPennyDropRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_SEND_PENNY_DROP, () =>
      this.setState({ showPennyCheck: true }),
    );
    this.sendPennyDropSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_PENNY_DROP_SUCCEEDED,
      this._onsendPennyDropSuccess,
    );
    this.sendPennyDropFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_PENNY_DROP_FAILED,
      () => this.setState({ showPennyCheck: false }),
    );

    this.createContractRequestSubscription = EventBus.on(ACTION_TYPES.CREATE_CONTRACT_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.createContractSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_CONTRACT_SUCCESS,
      this._onCreateContractSuccess,
    );
    this.createContractFailureSubscription = EventBus.on(ACTION_TYPES.CREATE_CONTRACT_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.sendPennyDropRequestSubscription();
    this.sendPennyDropSuccessSubscription();
    this.sendPennyDropFailureSubscription();

    this.createContractRequestSubscription();
    this.createContractSuccessSubscription();
    this.createContractFailureSubscription();
  }

  _onPennyCheck = ({ account_number, ifsc_code }) => {
    this.props.requestSendPennyDrop({
      bank_account_number: account_number,
      ifsc_code,
    });
  };

  _onsendPennyDropSuccess = () => {
    this.setState({ showPennyCheck: false }, () => {
      this.props.createContractRequest();
    });
  };

  _onCreateContractSuccess = ({ url }) => {
    window.location.href = url;
  };

  render() {
    const { onNext } = this.props;

    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            account_number: '',
            ifsc_code: '',
          }}
          validationSchema={this._validationSchema}
          onSubmit={this._onPennyCheck}
        >
          <FormikForm>
            <>
              <div className={cx(styles['progress-bar'], styles['mobile'])}>
                <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
              </div>
              <div className={styles['another-account']}>
                <div className={styles['pay-content']}>
                  <div className={cx(styles['title'], styles['title-heading'])}>
                    <div>Review your information (7/7)</div>
                    <div>
                      <FontAwesomeIcon icon={faAngleLeft} onClick={this.props.onPrev} />{' '}
                      &nbsp;&nbsp;
                    </div>
                  </div>
                  <div className={styles['line']} />
                  <div className={styles['sub-title']}>BANK INFORMATION</div>
                  <div className={styles['sub-title-light']}>
                    To start saving for the rest of your trip please enter your pan card
                  </div>
                  <div>
                    <div className={cx('pb-3 pt-4', styles['form-box'], styles['mobile-space'])}>
                      <div>
                        <TextInputThree
                          mandatory
                          label="Enter your bank account number"
                          name="account_number"
                          id="account_number"
                          className={styles['input-box']}
                          inputstyles={styles['input-box']}
                          placeholder="Enter account number"
                        />
                      </div>
                    </div>
                    <div className={cx(styles['form-box'], styles['mobile-space'])}>
                      <div>
                        <TextInputThree
                          mandatory
                          label="Enter IFSC Code"
                          name="ifsc_code"
                          id="ifsc_code"
                          className={`${styles['input-box']} ${styles['ifsc-input-box']}`}
                          inputstyles={`${styles['input-box']} ${styles['ifsc-input-box']}`}
                          placeholder="Enter IFSC code"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['spacer']} />

                <button
                  className={styles['footer']}
                  type="submit"
                  disabled={this.state.loading}
                  onClick={onNext}
                >
                  {this.state.loading ? 'LOADING...' : 'NEXT'}
                </button>
              </div>
            </>
          </FormikForm>
        </Formik>
        {window.innerWidth > 768 ? (
          <BootstrapModal show={this.state.showPennyCheck} className="custom-modal">
            <div className="penny-check-box">
              <div className="title">PENNY CHECK</div>
              <div className="description">
                We are depositing a small amount into your <br />
                account to verify it
              </div>
              <Player
                autoplay
                loop
                keepLastFrame
                src="https://assets6.lottiefiles.com/packages/lf20_5xjxnhli.json"
                className="sand-clock"
              />
              <div className="timer">
                This may take upto{' '}
                <Countdown date={Date.now() + 10000} renderer={({ seconds }) => seconds} /> seconds
              </div>
            </div>
          </BootstrapModal>
        ) : (
          <DrawerModal show={this.state.showPennyCheck} className={styles['mobile-penny-check']}>
            <div className={styles['title']}>PENNY CHECK</div>
            <div className={styles['description']}>
              We are depositing a small amount <br />
              into your account to verify it
            </div>
            <Player
              autoplay
              loop
              keepLastFrame
              src="https://assets6.lottiefiles.com/packages/lf20_5xjxnhli.json"
              className={styles['sand-clock-mobile']}
            />
            <div className={styles['timer']}>
              This may take upto{' '}
              <Countdown date={Date.now() + 10000} renderer={({ seconds }) => seconds} /> seconds
            </div>
          </DrawerModal>
        )}
      </div>
    );
  }
}

export default LinkAccount;

import React from 'react';
import cx from 'classnames';
import styles from './_badge.module.scss';

function Badge(props) {
  const {variant = 'success'} = props

  return (
    <span className={cx(styles[variant], props?.className)}>
      {props.children}
    </span>
  );
}

export default Badge;

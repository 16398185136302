import { connect } from 'react-redux';
import { uploadSelfieRequest, updateKycParams } from 'src/redux/actions';
import SelfieVerification from './selfie-verification';

function mapStateToProps(state) {
  return { kyc: state.kyc };
}

export default connect(mapStateToProps, {
  uploadSelfieRequest,
  updateKycParams,
})(SelfieVerification);

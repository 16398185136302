import { connect } from 'react-redux';
import { requestSignInUser, requestVerifyOTP, requestResendOtp } from 'src/redux/actions';
import SignInSignUpModal from './sign-in-sign-up-modal';

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, { requestSignInUser, requestVerifyOTP, requestResendOtp })(SignInSignUpModal);

import React from 'react';
import styles from './_solidheader.module.scss';

function SolidHeader(props) {
  return (
    <div
      className={styles.container}
      style={{ borderColor: props.borderColor, ...props?.headerStyles }}
    >
      <span
        style={{
          fontSize: props.fontSize,
          ...props?.styles,
        }}
      >
        {props.heading}
      </span>
    </div>
  );
}

export default SolidHeader;

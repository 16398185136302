import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Dropdown } from 'react-bootstrap';
import { PaymentLinksRequestTable } from 'src/components/tables';
import { Loader } from 'src/components';
import { DropdownToggle } from 'src/components/theme';
import styles from './_payment-links.module.scss';

class CollectRequest extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.props.requestAdminCollections();

    this.requestAdminCollections = EventBus.on(ACTION_TYPES.REQUEST_COLLECTION_LIST, () =>
      this.setState({ loading: true }),
    );
    this.requestAdminCollectionsSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_LIST_SUCCESS,
      () => this.setState({ loading: false }),
    );
    this.requestAdminCollectionsFailure = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_LIST_FAILED,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestAdminCollections();
    this.requestAdminCollectionsSuccess();
    this.requestAdminCollectionsFailure();
  }

  render() {
    const { collections = [] } = this.props;
    const { loading } = this.state;
    return (
      <div className={styles['payment-links']}>
        <div className={styles['heading']}>
          <h2 className="p-3">Collect Requests</h2>
          <Dropdown className={styles['dropdown']}>
            <DropdownToggle>Filter By</DropdownToggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">CSV</Dropdown.Item>
              <Dropdown.Item href="#/action-2">PDF</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <PaymentLinksRequestTable
          data={collections}
          handlePaginationChange={() => {}}
          totalItems={collections.length}
        />
        <Loader loading={loading} />
      </div>
    );
  }
}

export default CollectRequest;

import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestProductOffersDelete } from 'src/redux/actions';
import EventBus from 'eventing-bus';
import { ButtonOne } from 'src/components';
import React, { Component } from 'react';
import { ACTION_TYPES } from 'src/constants';
import styles from './_delete-product-offer-modal.module.scss';
import './_modal.scss';

class DeleteProductOfferModal extends Component {
  state = {
    isDeleting: false,
  };

  componentDidMount() {
    const { history } = this.props;
    this.requestProductOfferDelete = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_OFFERS_DELETE, () =>
      this.setState({ isDeleting: true }),
    );
    this.productOfferDeleteSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED_DELETE,
      () => {
        this.setState({ isDeleting: false });
        history.push(`/admin/products/offers`);
      },
    );
    this.productOfferDeleteFailure = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_OFFERS_FAILED_DELETE,
      () => {
        this.setState({ isDeleting: false });
      },
    );
  }

  componentWillUnmount() {
    this.requestProductOfferDelete();
    this.productOfferDeleteSuccess();
    this.productOfferDeleteFailure();
  }

  deleteProductOffer = () => {
    const { requestProductOffersDelete, productOffer } = this.props;
    const params = {
      uuid: productOffer?.uuid || '',
    };
    requestProductOffersDelete(params);
  };

  render() {
    const { showDeleteModal, closeDeleteModal, productOffer } = this.props;
    const { isDeleting } = this.state;

    return (
      <div>
        <Modal show={showDeleteModal} size="lg">
          <div className={styles['close-btn']}>
            <span onClick={closeDeleteModal}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </span>
          </div>
          <div className={styles['alert-icon']}>
            <img src="/img/alert-red-icon.svg" alt="alert-icon" />
          </div>
          <div className={styles['title']}>
            Are you sure you would like to delete
            <br /> {productOffer?.product?.productName}?
          </div>
          <div className={styles['group-btns']}>
            <div className={styles['inner-btn']}>
              <ButtonOne
                text={`${isDeleting ? 'Deleting...' : 'Yes, delete it'}`}
                type="submit"
                disabled={isDeleting}
                onClick={this.deleteProductOffer}
              />
            </div>
            <div className={styles['inner-btn']}>
              <ButtonOne text="No, do not delete it" type="submit" onClick={closeDeleteModal} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { requestProductOffersDelete })(
  withRouter(DeleteProductOfferModal),
);

import { Analytics } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import moment from 'moment';
import BLACKLIST from './blacklist.middleware';
import { groupActionTypes } from './utils.middleware';
import { assignCustomProperties } from './event-properties.middleware';

const analyticsMiddleware = Analytics({
  // add ignore action filter
  ignoreAction: (action) => BLACKLIST.indexOf(action.type) > -1,

  // derive Mixpanel event name from action and/or state
  selectEventName: (action) => groupActionTypes(action),

  // assign logged in user to the mixpanel profile
  selectLoggedInUser: (action) => {
    if (ACTION_TYPES.SIGN_IN_USER_SUCCESS === action.type) {           
      return action.payload.user.uuid;
    }
  },

  // reset user on logout
  selectLogoutUser: (action) => ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED === action.type,

  // Per-action selector: Mixpanel Engage user profile data
  selectUserProfileData: (action) => {
    // Only update user profile data on SIGN_IN_VERIFICATION_SUCCEEDED action type
    if (ACTION_TYPES.SIGN_IN_USER_SUCCESS === action.type ) {                
      const { user } = action.payload;
      
      const createdAt = moment(user.createdAt).format('YYYY-MM-DDThh:mm:ss');      

      const userProfile = {
        '$phone': user.phoneNumber,          
        'User Since': createdAt      
      };

      if (user.firstName) {
        userProfile['$first_name'] = user.firstName;
      }

      if (user.lastName) {
        userProfile['$last_name'] = user.lastName;
      }

      if (user.email) {
        userProfile['$email'] = user.email;
      }
      
      // User data to `$set` via Mixpanel Engage request
      return userProfile;
    }
  },

  selectProperties: (action, state) => assignCustomProperties(action, state)
});

export { analyticsMiddleware };

import React, { Component } from 'react';
import { connect } from 'react-redux';
import OfferAccordion from 'src/screens/plan-goal/offer-detail-item/offer-accordion';
import styles from './_complete-my-kyc.module.scss';

class CompleteMyKyc extends Component {
  render() {
    const { product, closeIsKycVerificationPage } = this.props;
    return (
      <div className={styles['complete-my-kyc']}>
        <h2 className={styles['title']}>To start saving, we need to do a KYC Verification</h2>
        <h4 className={styles['product-name']}>{product?.productName || ''}</h4>
        <div className={styles['accordion']}>
          <OfferAccordion
            {...this.props.product}
            productOffers={[{ ...this.props.savingPlan, ...this.props.savingPlan.productOffer }]}
            totalPax={this.props.totalPax}
            onProceedFirstInstallment={this.props.onProceedFirstInstallment}
            fromKycScreen
            closeIsKycVerificationPage={closeIsKycVerificationPage}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    totalPax: state.savingPlan.totalPax || 1,
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
  };
}

export default connect(mapStateToProps, {})(CompleteMyKyc);

import { connect } from 'react-redux'
import { cancelPurchaseRequest } from 'src/redux/actions';
import CancelBooking from './cancel-booking'

function mapStateToProps(state, props){  
  return {
    productName: props.savingGoal?.description,
    partnerName: props.savingGoal?.productOffer?.partner.name || 'merchant'
  }
}

export default connect(mapStateToProps, { cancelPurchaseRequest })(CancelBooking)
import { connect } from 'react-redux';
import { updateSavingPlan } from 'src/redux/actions';
import ProductDetails from './product-details';

function mapStateToProps(state) {
  return {
    totalPax: state.savingPlan.totalPax || 1,
  };
}

export default connect(mapStateToProps, { updateSavingPlan })(ProductDetails);

import React, { Component } from 'react';
import styles from './_checkbox.module.scss';

class Checkbox extends Component {
  _onTogglePepCheck = () => {
    this.props.onChange && this.props.onChange(!this.props.checked);
  };

  render() {
    return (
      <button className={styles['app-checkbox']} onClick={this._onTogglePepCheck}>
        <div
          className={`${styles['app-checkbox-icon']} ${
            this.props.checked ? styles['checked'] : ''
          }`}
        />
        <div className={styles['app-checkbox-description']}>
          I am an Indian citizen and not a PEP (Politically Exposed Person) or related to a PEP
        </div>
      </button>
    );
  }
}

export default Checkbox;

import React, { useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import CustomBadge from 'src/components/custom-badge';
import DataTable from 'src/components/data-table';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../_styles.module.scss';
import './_goalsTable.scss';

const GoalsTable = (props) => {
  let history = useHistory();

  /**
   *
   * Handles click to uuid to take user to product detail screen
   *
   */
  const _handleDetailLink = (e, id) => {
    e.preventDefault();
    history.push(`/admin/goal/${id}`);
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <div className={styles.multilineColumn}>
            <a href="#" onClick={(e) => _handleDetailLink(e, original?.id)}>
              <span className={styles.childTwo}>{original.id}</span>
            </a>
          </div>
        ),
      },
      {
        Header: 'Product Name',
        accessor: 'description',
        disableSortBy: true,
        // Cell: ({value}) => <CustomBadge value={value}/>
      },
      {
        Header: 'Amount',
        accessor: 'goalAbsoluteAmount',
        Cell: ({ value }) => (
          <span>
            {value &&
              `${parseInt(value).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}`}
          </span>
        ),
      },      
      {
        Header: 'Start Date',
        accessor: 'startDate',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <CustomBadge
            value={
              !original.kycd
                ? 'Pending KYC'
                : !original.isPaymentMade
                ? 'Pending Payment'
                : original.mandate?.status !== 'completed' && original.mandate?.status !== 'accepted_by_user'
                ? 'Pending SIP'
                : 'Fulfilled'
            }
            type={original.kycd && original.isPaymentMade && (original.mandate?.status === 'completed' || original.mandate?.status === 'accepted_by_user') ? 'success' : 'error'}
          />
        ),
      },
      {
        Header: 'Details',
        accessor: 'userUuid',
        disableSortBy: true,
        Cell: ({ value }) => (
          <Link to={`/admin/user/${value}`} title="View user">
            <FontAwesomeIcon icon={faUser} />
          </Link>
        ),
      },
      // {
      //     Header: 'Offers Available',
      //     accessor: 'productOffers',
      //     Cell: ({value}) => <span>{value && value.length}</span>
      // },
    ],
    [],
  );
  return (
    <div className="goals-data-table">
      <DataTable
        columns={columns}
        data={props.data}
        paginationConfig={paginationConfig}
        handlePaginationChange={props.handlePaginationChange}
      />
    </div>
  );
};

export default GoalsTable;

import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { adminGoalDetail, adminGoalsList } from 'src/redux/api';
import {
  adminGoalsListSuccess,
  adminGoalsListFailure,
  adminGoalDetailSuccess,
  adminGoalDetailFailure,
} from 'src/redux/actions';

export function* watchAdminGoalsRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_GOALS_LIST, requestAdminGoalsList);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_GOAL_DETAIL, requestAdminGoalDetail);
}

function* requestAdminGoalDetail(action) {
  try {
    const offers = yield call(adminGoalDetail, action.payload);
    yield put(adminGoalDetailSuccess(offers));
  } catch (e) {
    yield put(adminGoalDetailFailure(e));
  }
}

function* requestAdminGoalsList(action) {
  try {
    const offers = yield call(adminGoalsList, action.payload);
    yield put(adminGoalsListSuccess(offers));
  } catch (e) {
    yield put(adminGoalsListFailure(e));
  }
}

import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import _ from 'lodash';
import { ACTION_TYPES } from 'src/constants';
import { Loader } from 'src/components';
import styles from './verify-kyc.module.scss';
import Fatcha from '../fatca';

const TAX_STATUS_MAP = { P: 'Individual', H: 'Hindu Undivided Family' };

class VerifyKyc extends Component {
  state = {
    panNumber: this.props.folio.panNumber || '',
    taxStatusCode: this.props.folio.taxStatusCode || '',
    loading: false,
    isPanMessageModal: false,
    isFactaModal: false,
    panData: {},
  };

  componentDidMount() {
    this.taxStatusRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_TAX_STATUS, () =>
      this.setState({ loading: true }),
    );
    this.taxStatusSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_TAX_STATUS_SUCCEEDED,
      this._onFetchedTaxStatuses,
    );
    this.taxStatusFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_TAX_STATUS_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.checkPanRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_CHECK_PAN, () =>
      this.setState({ loading: true }),
    );
    this.checkPanSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CHECK_PAN_SUCCEEDED,
      this._onPanChecked,
    );
    this.checkPanFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_CHECK_PAN_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.submitPanRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_SUBMIT_PAN, () =>
      this.setState({ loading: true }),
    );
    this.submitPanSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_PAN_SUCCEEDED,
      this._onPanSubmitted,
    );
    this.submitPanFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_SUBMIT_PAN_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (this.props.taxStatuses.length === 0) {
      this.props.requestTaxStatus();
    }
  }

  componentWillUnmount() {
    this.taxStatusRequestSubscription();
    this.taxStatusSuccessSubscription();
    this.taxStatusFailureSubscription();

    this.checkPanRequestSubscription();
    this.checkPanSuccessSubscription();
    this.checkPanFailureSubscription();

    this.submitPanRequestSubscription();
    this.submitPanSuccessSubscription();
    this.submitPanFailureSubscription();
  }

  _taxStatusFromPanNumber(panNumber) {
    const { taxStatuses = [] } = this.props;

    if (!panNumber) {
      return null;
    }

    const fourthAlphabet = panNumber.substring(3, 4);
    const taxStatusName = TAX_STATUS_MAP[fourthAlphabet];

    const taxStatus = _.find(taxStatuses, (status) => status.taxDesc === taxStatusName);

    if (taxStatus) {
      return taxStatus.taxCode;
    }
    return taxStatuses && taxStatuses[0] ? taxStatuses[0].taxCode : '';
  }

  _onChangePanNumber = (e) => {
    const panNumber = (e.target.value || '').toUpperCase();
    const taxStatusCode = this._taxStatusFromPanNumber(panNumber);

    this.setState({ panNumber, taxStatusCode }, () => {
      if (panNumber.length === 10) {
        this.props.updateFolio({ panNumber, taxStatusCode });
      }
    });
  };

  _onSubmit = () => {
    const { panNumber, taxStatusCode } = this.state;

    this.props.requestCheckPan({
      pan_number: panNumber,
      tax_status_code: taxStatusCode,
    });
  };

  _onPanChecked = (response) => {
    this.setState({ loading: false, panData: response }, () => {
      if (response.status === '1') {
        this.props.requestSubmitPan();
      } else {
        this.setState({ loading: false }, () => {
          this.props.navigateToLongKyc();
        });        
      }
    });
  };

  _onPanSubmitted = () => {
    const { panData } = this.state;
    if (panData?.fatca?.popupRequired) {
      this.setState({ loading: false, isPanMessageModal: true });
    } else {
      this.setState({ loading: false }, () => {
        this.props.onNext && this.props.onNext();
      });
    }
  };

  handleFactaComplete = () => {
    this.setState({ loading: false, isPanMessageModal: false, isFactaModal: false }, () => {
      this.props.onNext && this.props.onNext();
    });
  };

  _closePanMessageModal = () => {
    this.setState({ isPanMessageModal: false });
  };

  _handleYes = () => {
    this.setState({ isPanMessageModal: false, isFactaModal: true, isFatca: true });
  };

  _handleNo = () => {
    this.setState({ isPanMessageModal: false, isFactaModal: true, isFatca: false });
  };

  render() {
    const { panNumber, loading, isPanMessageModal, panData, isFactaModal, isFatca } = this.state;

    return (
      <div className={styles['verify-kyc']}>
        <div className={styles['test']}>
          <div className={cx(styles['header'], styles['mobile'])}>
            <div className={styles['inner-header']}>
              <div className={styles['sub-inner-header']}>
                <div className={styles['title']}>Complete your KYC to start saving</div>
                {/* <div className={styles['price']}>Rs. 10,000</div>
                <div className={styles['note']}>Total amount to be saved</div>
                <div className={styles['amount']}>
                  <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
                </div> */}
              </div>
            </div>
          </div>

          <div className={cx(styles['progress-bar'], styles['mobile'])}>
            <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
          </div>

          <div className={styles['pay-content']}>
            <div className={styles['title']}>Verify your KYC Start Saving</div>
            <div className={styles['line']} />
            <div className={styles['sub-title']}>PAN VERIFICATION</div>
            <div className={styles['sub-title-light']}>
              To start saving for the rest of your trip please enter your pan card{' '}
            </div>
            <div className={cx(styles['mobile-space'], styles['mobile'])}>
              <div className={cx(styles['line-mob'])} />
            </div>

            <div className={cx(styles['form-box'], styles['mobile-space'])}>
              <div>
                <label>Enter your PAN Card</label>
              </div>
              <div>
                <InputMask
                  className={styles['input-box']}
                  placeholder="Enter PAN number"
                  value={panNumber}
                  onChange={this._onChangePanNumber}
                  mask="aaaaa9999a"
                  maskChar={null}
                  disabled={this.state.loading}
                />
              </div>
            </div>
            <div className={cx(styles['note'], styles['mobile-space'])}>
              To invest in India, a PAN Card is mandatory as per SEBI regulations
            </div>
          </div>
        </div>
        <div className={styles['footer']} onClick={this._onSubmit}>
          VERIFY PAN
        </div>
        <Modal show={isPanMessageModal} size="lg">
          <div className="pan-message-modal">
            <div className="cross-icon">
              <span onClick={this._closePanMessageModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>

            <div className="message-successfull">
              <div className="description">{panData?.fatca?.popupMessage || ''}</div>
              <div className="done-btn">
                <button className="custom-btn" onClick={this._handleYes}>
                  Yes
                </button>
                <button className="cancel-btn" onClick={this._handleNo}>
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={isFactaModal} size="lg">
          <Fatcha onComplete={this.handleFactaComplete} isFatca={isFatca} />
        </Modal>
        <Loader loading={loading} />
      </div>
    );
  }
}

export default VerifyKyc;

import { connect } from 'react-redux';
import Wizard from './wizard';

function mapStateToProps(state) {
  return {
    showKyc: !state.user.folio || !state.user.folio.folioNumber,
    paymentMethod: state.savingPlan.paymentMethod
  };
}

export default connect(mapStateToProps, {})(Wizard);

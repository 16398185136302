export const customAreaChartData = [
  {
    day: '1',
    unit: 10,
  },
  {
    day: '2',
    unit: 15,
  },
  {
    day: '3',
    unit: 12,
  },
  {
    day: '4',
    unit: 12,
  },
  {
    day: '5',
    unit: 16,
  },
  {
    day: '6',
    unit: 16,
  },
  {
    day: '7',
    unit: 12,
  },
  {
    day: '8',
    unit: 8,
  },
  {
    day: '7',
    unit: 5,
  },
];

export const customPieChartData = [
  {
    name: 'P1',
    value: 400,
    fill: '#855CF8',
  },
  {
    name: 'P2',
    value: 300,
    fill: '#E289F2',
  },
  {
    name: 'P3',
    value: 300,
    fill: '#503795',
  },
  {
    name: 'P4',
    value: 200,
    fill: '#B085FF',
  },
];

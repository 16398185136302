import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
// import CustomBadge from 'src/components/custom-badge';
import CustomBadge from 'src/components/custom-badge';
import DataTable from 'src/components/data-table';
import { Convert } from 'src/helpers';
import styles from './_goalprogresstable.module.scss';

function GoalProgressTable(props) {
  // let history = useHistory();

  /**
   *
   * Handles click to uuid to take user to product detail screen
   *
   */
  // const _handleDetailLink = (e, id) => {
  //   e.preventDefault();
  //   history.push(`/admin/goal/${id}`);
  // };

  // const setGoalId = (e, val) => {
  //   e.preventDefault();
  //   props.setGoalId(val);
  // };

  const columns = useMemo(
    () => [
      {
        Header: 'Offer Name',
        accessor: 'id', // accessor is the "key" in the data
        disableSortBy: true,

        Cell: ({ value, row: { original } }) => (
          <div className={styles.multilineColumn}>
            <Link to={`/dashboard/progress/${value}`}>
              <span className={styles.childTwo}>{original.description}</span>
            </Link>
          </div>
        ),
      },
      {
        Header: 'Cost of offer',
        accessor: 'goalAbsoluteAmount',
        Cell: ({ value }) => <span>{value ? Convert.currency(value) : 'N/A'}</span>,
      },
      {
        Header: 'Saved so far',
        accessor: 'investmentPlusPendingBalance',
        Cell: ({ value }) => <span>{Convert.currency(value)}</span>,
      },
      {
        Header: 'Started At',
        accessor: 'startDate',
      },
      {
        Header: 'Ending At',
        accessor: 'endDate',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }) => (
          <span>
            {!original.kycd ? (
              <Link to={{ pathname: '/kyc', state: { savingGoalId: original.id } }}>
                Complete KYC
              </Link>
            ) : !original.mandate ? (
              <Link to={{ pathname: '/setup-sip', state: { savingGoalId: original.id } }}>
                Setup SIP
              </Link>
            ) : (
              <CustomBadge
                value={
                  Number(original.investmentPlusPendingBalance) >=
                  Number(original.goalAbsoluteAmount)
                    ? 'Completed'
                    : 'In Progress'
                }
                type={
                  Number(original.investmentPlusPendingBalance) >=
                  Number(original.goalAbsoluteAmount)
                    ? 'success'
                    : 'info'
                }
              />
            )}
          </span>
        ),
      },
    ],
    [],
  );
  return (
    <DataTable
      columns={columns}
      data={props.data}
      theadStyles={styles.thead}
      bodyStyles={styles.tbody}
      bodyRowStyles={styles.row}
    />
  );
}

export default GoalProgressTable;

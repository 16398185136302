import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function createMandate(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.CREATE_MANDATE,
    data: params,
  }).then((response) => response.data);
}

import React, { Component } from 'react';
import cx from 'classnames';
import { WithDrawnMoney, SaveMore } from 'src/components';
import AccountDetails from './account-details';
import Transactions from './transactions';
import styles from './_transaction-account-details.module.scss';

class TransactionAccountDetails extends Component {
  state = { isWithdrawalModalOpen: false, isSaveMoreModalOpen: false };

  componentDidMount() {
    this.props.requestGoalList();
  }

  handleWithdrawModal = (e) => {
    e.preventDefault();
    this.setState({ isWithdrawalModalOpen: true });
  };

  handleCloseWithdrawModal = () => {
    this.setState({ isWithdrawalModalOpen: false });
  };

  handleSaveMoreModal = (e) => {
    e.preventDefault();
    this.setState({ isSaveMoreModalOpen: true });
  };

  handleCloseSaveMoreModal = () => {
    this.setState({ isSaveMoreModalOpen: false });
  };

  render() {
    const { isWithdrawalModalOpen, isSaveMoreModalOpen } = this.state;

    return (
      <div className={styles['account-details']}>
        <div className={styles['account-details-box']}>
          <AccountDetails isActive={this.props.isActive} />
        </div>
        <Transactions />
        <div className={styles['custom-btns']}>
          <a
            href="#"
            className={cx('btn btn-primary', styles['custom-btn'])}
            target="_blank"
            rel="noreferrer"
            onClick={this.handleSaveMoreModal}
          >
            Invest More
          </a>
          <a
            href="#"
            className={cx('btn btn-primary', styles['custom-btn'])}
            target="_blank"
            rel="noreferrer"
            onClick={this.handleWithdrawModal}
          >
            Withdraw Savings
          </a>
        </div>
        {isSaveMoreModalOpen && (
          <SaveMore isModalOpen={isSaveMoreModalOpen} closeModal={this.handleCloseSaveMoreModal} />
        )}
        {isWithdrawalModalOpen && (
          <WithDrawnMoney
            isModalOpen={isWithdrawalModalOpen}
            closeModal={this.handleCloseWithdrawModal}
          />
        )}
      </div>
    );
  }
}

export default TransactionAccountDetails;

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminOfferCreate(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.ADMIN_OFFERS_LIST,
    data,
  }).then((response) => response.data);
}

export function adminOfferCreateV2(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.ADMIN_OFFERS_LIST_V2,
    data,
  }).then((response) => response.data);
}

export function adminOfferDelete({uuid}) {
  return ApiRequest.fetchAdmin({
    method: 'delete',
    url: `${API_END_POINTS.ADMIN_OFFERS_LIST}/${uuid}`,
  }).then((response) => response.data);
}

export function adminOfferUpdate(data) {
  return ApiRequest.fetchAdmin({
    method: 'put',
    url: `${API_END_POINTS.ADMIN_OFFERS_LIST}/${data.uuid}`,
    data,
  }).then((response) => response.data);
}

export function adminOfferDetail({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_OFFERS_LIST}/${uuid}`,
  }).then((response) => response.data);
}

export function adminOffersList(data) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_OFFERS_LIST}?page=${data.page}`,
    data,
  }).then((response) => response.data);
}

export function adminOffersListByProductId({ product_id }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_OFFERS_LIST}?product_id=${product_id}`,
  }).then((response) => response.data);
}

export function createAdminOffer(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.ADMIN_OFFERS_LIST,
    data,
  }).then((response) => response.data);
}


export function requestShortenUrl(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.URL_SHORTENERS,
    data,
  }).then((response) => response.data);
}

export function adminPaymentSchedule(params) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: API_END_POINTS.PAYMENT_SCHEDULES,
    params,
  }).then((response) => response.data);
}

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// import InputMask from 'react-input-mask';
// import EventBus from 'eventing-bus';
// import _ from 'lodash';
import { InputFileTwo } from 'src/components/form-inputs';
import { SecuredPartners, CtaTwo, MandatoryMark } from 'src/components';
import { ApiRequest } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { notify } from 'react-notify-toast';
import styles from './_panverification.module.scss';

export class PanVerification extends Component {
  state = {
    loading: false,
    // eslint-disable-next-line
    panCardParams: {},
    frontPanLoading: false,
  };

  fileInput = null;

  componentDidMount() {
    this.extractPanCardRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_PAN_CARD_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.extractPanCardRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_PAN_CARD_SUCCESS,
      this._onExtractedPanCard,
    );
    this.extractPanCardRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_PAN_CARD_FAILED,
      () => this.setState({ loading: false }),
    );

    this.uploadPanCardRequestSubscription = EventBus.on(ACTION_TYPES.UPLOAD_PAN_CARD_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.uploadPanCardSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_PAN_CARD_SUCCESS,
      this._onPanCardUploaded,
    );
    this.uploadPanCardFailureSubscription = EventBus.on(ACTION_TYPES.UPLOAD_PAN_CARD_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.uploadFormRequestSubscription = EventBus.on(ACTION_TYPES.UPLOAD_FORM_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.uploadFormSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_FORM_SUCCESS,
      this._onFormUploaded,
    );
    this.uploadFormFailureSubscription = EventBus.on(ACTION_TYPES.UPLOAD_FORM_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.extractPanCardRequestSuccessSubscription();
    this.extractPanCardRequestFailureSubscription();

    this.uploadPanCardRequestSubscription();
    this.uploadPanCardSuccessSubscription();
    this.uploadPanCardFailureSubscription();

    this.uploadFormRequestSubscription();
    this.uploadFormSuccessSubscription();
    this.uploadFormFailureSubscription();
  };

  _onPanFrontImageUpdated = (event) => {
    this.setState({ frontPanLoading: true });
    const [file] = event.target.files;

    ApiRequest.uploadToSignzy(file).then((res) => {
      const { panCardParams } = this.state;
      panCardParams.image_url = res.data.file.directUrl;

      this.setState({ panCardParams, frontPanLoading: false });
    });
  };

  _onExtractedPanCard = (res) => {
    if (res.name && res.dateOfBirth && res.panNumber && res.fathersName) {
      const panCard = {
        name: res.name,
        date_of_birth: res.dateOfBirth,
        pan_number: res.panNumber,
        fathers_name: res.fathersName,
        image_url: this.state.panCardParams.image_url,
      };
      // eslint-disable-next-line
      this.setState({ panCardParams: panCard }, () => {
        this.props.updateKycParams({ panCard });
        this.props.uploadPanCardRequest(panCard);
      });
    } else {
      // eslint-disable-next-line
      this.setState({ loading: false, panCardParams: {} }, () => {        
        notify.show(`Document extraction failed, make sure the pan document is valid, readable and doesn't have blur, glare, or dim lighting.`, 'error', 5000);
      });
    }
  };

  _onSubmitPanCard = () => {
    this.props.extractPanCardRequest({ image_url: this.state.panCardParams.image_url });
  };

  _onPanCardUploaded = () => {
    const { kyc } = this.props;
    const { userInfo } = kyc;

    this.props.uploadFormRequest({
      gender: userInfo.gender,
      marital_status: userInfo.maritalStatus,
      occupation_description: userInfo.occupationDescription,
      occupation_code: userInfo.occupation,
      annual_income: userInfo.salary,
    });
  };

  _onFormUploaded = () => {
    this.props.onComplete(); // Proceed to id proof verification
  };

  render() {
    const { panCardParams } = this.state;
    
    return (
      <div className={styles['pan-verification']}>
        <div className={styles['pv-group-input']}>
          <div>
            <label className={styles['pv-group-input-label']}>
              Upload your pan card
              <MandatoryMark />
            </label>
          </div>
          <Row>
            <Col lg={6} className={styles.formItem}>
              <InputFileTwo
                ref={ref => this.fileInput = ref}
                label="Front of your PAN card"
                name="panFront"
                id="panFront"
                accept="image/png, image/jpeg"
                container={styles.inputFileTwo}
                loading={this.state.frontPanLoading}
                onChange={this._onPanFrontImageUpdated}
              />
            </Col>
            {/* <Col lg={6}>
              <InputFileTwo
                label="Back of your PAN card"
                name="panBack"
                id="panBack"
                accept="image/png, image/jpeg"
                container={styles.inputFileTwo}
                loading={this.state.backPanLoading}                    
              />
            </Col> */}
          </Row>
          <div className={styles['pv-description']}>
            Note: Your PAN card number will become your investor ID. For any future investments all
            you need is your PAN number.
          </div>
          <CtaTwo
            type="submit"
            text="Next"
            className={styles['pv-next-btn']}
            loading={this.state.loading}
            disabled={!panCardParams.image_url}
            onClick={this._onSubmitPanCard}
          />
          <div className={styles['secure-partners']}>
            <SecuredPartners />
          </div>
        </div>
      </div>
    );
  }
}

export default PanVerification;

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function SavingGoalReducer(state = INITIAL_STATE.savingGoals, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS: {
      return {...action.payload.savingGoal};      
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.savingGoals;
    }

    default:
      return state;
  }
}

import { all } from 'redux-saga/effects';
import { watchUserRequests } from './user.saga';
import { watchProductRequests } from './product.saga';
import { watchSavingGoalRequests } from './saving-goal.saga';
import { watchPaymentRequests } from './payment.saga';
import { watchKycRequests } from './kyc.saga';
import { watchFolioRequests } from './folio.saga';
import { watchMandateRequests } from './mandate.saga';

import { watchAdminRequests } from './admin.saga';
import { watchAdminUsersRequests } from './admin-users.saga';
import { watchAdminProductRequests } from './admin-products.saga';
import { watchAdminOffersRequests } from './admin-offers.saga';
import { watchAdminGoalsRequests } from './admin-goals.saga';
import { watchTransactionRequests } from './transactions.saga';
import { watchAdminTransactionRequests } from './admin-transactions.saga';
import { watchAdminAgentsRequests } from './admin-agents.saga';
import { watchAdminPaymentsRequests } from './admin-payments.saga';
import { watchAdminPartnersRequests } from './admin-partners.saga';
import { watchAdminCollectionsRequests } from './admin-collections.saga';
import { watchAdminCancelRequests } from './admin-cancel.saga';
import { watchMarketingRequests } from './marketing.saga';

export default function* rootSaga() {
  yield all([
    watchUserRequests(),
    watchProductRequests(),
    watchSavingGoalRequests(),
    watchPaymentRequests(),
    watchKycRequests(),
    watchFolioRequests(),
    watchMandateRequests(),
    watchTransactionRequests(),

    watchAdminRequests(),
    watchAdminUsersRequests(),
    watchAdminProductRequests(),
    watchAdminOffersRequests(),
    watchAdminGoalsRequests(),
    watchAdminTransactionRequests(),
    watchAdminAgentsRequests(),
    watchAdminPaymentsRequests(),
    watchAdminPartnersRequests(),
    watchAdminCollectionsRequests(),
    watchMarketingRequests(),
    watchAdminCancelRequests(),
  ]);
}

import React from 'react'
import { Dropdown } from 'react-bootstrap';
import styles from './_dropdownToggle.module.scss'

export default function DropdownToggle(props) {
  const {variant = 'basic'} = props
  
  return (
    <div className={styles.container}>
      <Dropdown.Toggle className={styles[variant]} variant="">
        {props.children}
      </Dropdown.Toggle>
    </div>
  )
}
import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function getCancelRequestList() {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.CANCEL_REQUESTS}?cancel_requests=true`,
  }).then((response) => response.data);
}

export function getCancelRequestDetail({ id }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.CANCEL_REQUESTS}/${id}`,
  }).then((response) => response.data);
}

export function confirmCancelRequest({ id }) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.CONFIRM_CANCEL_REQUESTS.replace('{GOAL_ID}', id),
  }).then((response) => response.data);
}

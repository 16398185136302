import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminUsersReducer(state = INITIAL_STATE.adminUsers, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_ADMIN_USER_DETAIL: {
      return state;
    }

    case ACTION_TYPES.ADMIN_USER_DETAIL_SUCCESS: {
      return {
        ...action.payload.user,
      };
    }

    case ACTION_TYPES.ADMIN_USER_DETAIL_FAILED: {
      return state;
    }

    case ACTION_TYPES.ADMIN_USERS_LIST_SUCCESS: {
      const users = [];
      if (action.payload?.users && action.payload?.users.length > 0) {
        action.payload?.users.forEach((data) => {
          users.push(data?.user);
        });
      }
      return { ...action.payload, users };
    }

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminUsers;

    default:
      return state;
  }
}

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function getCollections() {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: API_END_POINTS.COLLECTION_REQUESTS,
  }).then((response) => response.data);
}

export function getCollectionDetail({ id }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.COLLECTION_REQUESTS}/${id}`,
  }).then((response) => response.data);
}

export function getUserCollectionDetail({ id }) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.USER_COLLECTION_REQUESTS}/${id}`,
  }).then((response) => response.data);
}

export function acceptCollection({ id }) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.COLLECTION_REQUESTS_ACCEPT.replace('{ID}', id),
  }).then((response) => response.data);
}

export function confirmCollection({ id, data }) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.COLLECTION_REQUESTS_CONFIRM.replace('{ID}', id),
    data,
  }).then((response) => response.data);
}

export function createCollection(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.COLLECTION_REQUESTS,
    data,
  }).then((response) => response.data);
}

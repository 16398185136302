import { connect } from 'react-redux';
import {
  updateCredentials,
  updateProduct,
  requestVerifyUser,
  updateUser,
  updateSavingPlan,
  requestGoalDetail,
} from 'src/redux/actions';

import Home from './home';

function mapStateToProps(state) {
  return {
    user: state.user,
    credentials: state.credentials,
    product: state.product,
    savingGoal: state.savingGoal,
  };
}

export default connect(mapStateToProps, {
  updateCredentials,
  updateProduct,
  requestVerifyUser,
  updateUser,
  updateSavingPlan,
  requestGoalDetail,
})(Home);

import { connect } from 'react-redux';
import { 
  longKycVerificationRequest 
} from 'src/redux/actions';
import ContractCreated from './contract-created';

function mapStateToProps(state) {
  return {
    user: state.user    
  };
}

export default connect(mapStateToProps, { 
  longKycVerificationRequest
})(ContractCreated);

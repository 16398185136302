import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Loader } from 'src/components';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { InputSelectOne } from 'src/components/form-inputs';
import { withRouter } from 'react-router-dom';
import PaymentLinksTable from './payment-links-table';
import { Container, Section, InputText } from '../../components';
import styles from './_payment-links.module.scss';

class PaymentLinks extends Component {
  state = {
    loading: false,
    pageNumber: 1,
    searchLinkName: '',
    searchType: '',
    startDate: '',
    endDate: '',
    filteredData: [],
    totalCount: 0,
  };

  _onActionClick = () => {
    this.props.history.push('admin/paymentlink/new');
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminOffersList({
          page: pageNumber,
        }),
    );
  };

  componentDidMount() {
    this.requestAdminOffersList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminOffersListSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_SUCCESS, () => {
      this.setState({ loading: false });
      const { productOffers, productOffersTotal } = this.props.productOffers;
      this.setState({ filteredData: productOffers, totalCount: productOffersTotal });
    });
    this.adminOffersListFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminOffersList({
      page: this.state.pageNumber,
    });
  }

  componentWillUnmount() {
    this.requestAdminOffersList();
    this.adminOffersListSuccess();
    this.adminOffersListFailure();
  }

  getPaymentLinkProperty = (paymentLinks, propertyName) => {
    const paymentLink = paymentLinks.length ? paymentLinks[0] : '';
    return paymentLink ? paymentLink[propertyName] : false;
  };

  getStatus = (data) => {
    const { paymentLinks, savingGoals } = data;
    let status = '';

    if (paymentLinks.length) {
      status = 'created';

      const { expiry } = paymentLinks[0];

      // check if link has expired
      const isExpired = moment().diff(moment(expiry), 'milliseconds') > 0;
      if (isExpired) {
        status = 'expired';
        return status;
      }

      if (savingGoals.length) {
        const { isPaymentMade, kycd, mandate, status: statusFromData } = savingGoals[0];

        if (statusFromData == 'completed') {
          return 'completed';
        }

        if (isPaymentMade) {
          status = kycd && mandate ? 'ongoing' : 'booked';
        }
      }
    }

    return status;
  };

  getOrganizedData = () => {
    const { productOffers } = this.props.productOffers;
    const getFilteredData =
      (productOffers &&
        productOffers.length > 0 &&
        productOffers.map((data) => {
          const searchCreatedAt = this.getPaymentLinkProperty(data?.paymentLinks, 'createdAt');
          const searchStatus = this.getStatus(data);
          return {
            ...data,
            searchCreatedAt,
            searchStatus,
          };
        })) ||
      [];
    return getFilteredData;
  };

  handleChange = (e) => {
    const { startDate, endDate, searchType, searchLinkName } = this.state;
    const organizedData = this.getOrganizedData();
    let filteredData = organizedData || [];
    let searchStartDate = '';
    let searchEndDate = '';

    if (
      e.target.name === 'searchLinkName' ||
      (searchLinkName && e.target.name !== 'searchLinkName')
    ) {
      let regex = new RegExp(
        searchLinkName && e.target.name !== 'searchLinkName' ? searchLinkName : e.target.value,
        'i',
      );
      filteredData = filteredData.filter((item) => regex.test(item?.productOfferName));
    }

    if (e.target.name === 'startDate' || (startDate && e.target.name !== 'startDate')) {
      searchStartDate = startDate && e.target.name !== 'startDate' ? startDate : e.target.value;
    }

    if (e.target.name === 'endDate' || (endDate && e.target.name !== 'endDate')) {
      searchEndDate = endDate && e.target.name !== 'endDate' ? endDate : e.target.value;
    }

    if (e.target.name === 'searchType' || (searchType && e.target.name !== 'searchType')) {
      let regex = new RegExp(
        searchType && e.target.name !== 'searchType'
          ? searchType
          : e.target.value
          ? e.target.value.toLowerCase().trim()
          : '',
        'i',
      );
      filteredData = filteredData.filter((item) =>
        regex.test(item?.searchStatus ? item?.searchStatus.toLowerCase() : ''),
      );
    }

    if (searchStartDate && searchEndDate) {
      filteredData = filteredData.filter(
        (item) =>
          moment(item?.searchCreatedAt, 'DDMMYYYY').format('YYYY-MM-DD') >= searchStartDate &&
          moment(item?.searchCreatedAt, 'DDMMYYYY').format('YYYY-MM-DD') <= searchEndDate,
      );
    }

    this.setState({
      [e.target.name]: (e.target.value && e.target.value.trim()) || '',
      filteredData,
      totalCount: filteredData.length || 0,
    });
  };

  render() {
    const { loading, searchLinkName, startDate, endDate, filteredData, totalCount } = this.state;

    return (
      <div className={styles['payment-links']}>
        <Container
          title="Payment Links"
          buttonText="create a new link"
          onButtonClick={this._onActionClick}
        >
          <Formik
            initialValues={{
              searchLinkName: '',
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Section title="Filter By">
                  <Row>
                    <Col>
                      <InputText
                        label="Link Name"
                        onChange={this.handleChange}
                        type="text"
                        name="searchLinkName"
                        value={searchLinkName}
                      />
                    </Col>
                    <Col>
                      <InputText
                        label="Start Date"
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <InputText
                        label="End Date"
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <InputSelectOne
                        label="Status of Link"
                        name="searchType"
                        labelStyle={styles['label']}
                        onChange={(e) => {
                          this.handleChange(e);
                          setFieldValue('searchType', e.target.value);
                        }}
                        className={styles['select']}
                      >
                        <option>&nbsp;</option>
                        <option value="created">Created</option>
                        <option value="expired">Expired</option>
                        <option value="completed">Completed</option>
                        <option value="ongoing">Ongoing</option>
                        <option value="booked">Booked</option>
                      </InputSelectOne>
                    </Col>
                  </Row>
                </Section>
              </Form>
            )}
          </Formik>
          <PaymentLinksTable
            data={filteredData}
            handlePaginationChange={this._handlePaginationChange}
            totalItems={totalCount}
          />
        </Container>
        <Loader loading={loading} />
      </div>
    );
  }
}

export default withRouter(PaymentLinks);

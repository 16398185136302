import { ACTION_TYPES } from "src/constants";

/** admin dashboard transactions */

export function requestAdminTransactionList(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST,
        payload
    }
}

export function requestAdminTransactionListSuccess(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST_SUCCESS,
        payload
    }
}

export function requestAdminTransactionListFailed(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST_FAILED,
        payload
    }
}

/** admin dashboard deposit list */

export function requestAdminTransactionDepositList(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST,
        payload
    }
}

export function requestAdminTransactionDepositListSuccess(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_SUCCESS,
        payload
    }
}

export function requestAdminTransactionDepositListFailed(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_FAILED,
        payload
    }
}

/** admin dashboard deposit list */

export function requestAdminTransactionWithdrawalList(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST,
        payload
    }
}

export function requestAdminTransactionWithdrawalListSuccess(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST_SUCCESS,
        payload
    }
}

export function requestAdminTransactionWithdrawalListFailed(payload){
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST_FAILED,
        payload
    }
}

/**
 *
 * Transaction Detail Actions
 *
 */

export function requestAdminTransactionDetail(payload) {
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DETAIL,
        payload
    };
}

export function requestAdminTransactionDetailSuccess(payload) {
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DETAIL_SUCCESS,
        payload
    };
}

export function requestAdminTransactionDetailFailure(payload) {
    return {
        type: ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DETAIL_FAILED,
        payload
    };
}
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Loader } from 'src/components';
import { Convert } from 'src/helpers';
import { Container } from '../../../components';
import { InfoBlock } from '../../../components/info-block';
import UsersTable from './users-table';

export class UserDetails extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const uuid = this.props.match.params?.uuid;

    this.requestAdminUserDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_USER_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.adminUserDetailSuccess = EventBus.on(ACTION_TYPES.ADMIN_USER_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminUserDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_USER_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (uuid) {
      this.props.requestAdminUserDetail({ uuid });
    }
  }

  componentWillUnmount() {
    this.requestAdminUserDetail();
    this.adminUserDetailSuccess();
    this.adminUserDetailFailure();
  }

  _onActionClick = () => {
    this.props.history.push('/admin/paymentlink/new');
  };

  getOngoingPurchasesData = () => {
    const { user } = this.props;

    let onGoingStructure = {
      numberOfPurchases: 0,
      totalPaid: 0,
      totalPending: 0,
      totalAmountToBePaid: 0,
    };
    if (user?.savingGoals && user?.savingGoals.length > 0) {
      onGoingStructure.numberOfPurchases = user?.savingGoals.length || 0;

      user?.savingGoals.forEach((savingGoal) => {
        if (savingGoal?.savingGoalContributions && savingGoal?.savingGoalContributions.length > 0) {
          savingGoal?.savingGoalContributions.forEach((data) => {
            if (data?.status === 'completed' || data?.status === 'pending_investments') {
              onGoingStructure.totalPaid += parseFloat(data?.amount) || 0;
            }
          });
        }
      });

      user?.savingGoals.forEach((savingGoal) => {
        onGoingStructure.totalPaid += Number(savingGoal?.productOffer?.upfrontPaymentAmount || 0);
        onGoingStructure.totalAmountToBePaid += Number(savingGoal?.goalAbsoluteAmount || 0);
      });
    }
    onGoingStructure.totalPending =
      onGoingStructure.totalAmountToBePaid - onGoingStructure.totalPaid;
    return onGoingStructure;
  };

  render() {
    const { user } = this.props;
    const { numberOfPurchases, totalPaid, totalPending } = this.getOngoingPurchasesData();

    return (
      <Container
        title="User Details"
        buttonText="create a new link"
        onButtonClick={this._onActionClick}
      >
        <InfoBlock
          title="User Information"
          data={{
            Name: `${user?.firstName || ''} ${user?.lastName || ''}`,
            Email: `${user?.email || ''}`,
            'Phone Number': `${user?.phoneNumber || ''}`,
          }}
        />
        <InfoBlock
          title="Users ongoing purchases"
          horizontal
          data={{
            'Total no.of purchases': numberOfPurchases,
            'Total Paid:': Convert.toCurrencyValue(totalPaid),
            'Total Pending:': Convert.toCurrencyValue(totalPending),
          }}
        />
        <UsersTable data={user?.savingGoals || []} />
        <Loader loading={this.state.loading} />
      </Container>
    );
  }
}

export default withRouter(UserDetails);

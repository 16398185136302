import { connect } from 'react-redux';
import HeaderNav from './header-nav';

function mapStateToProps(state) {
  return {
    user: state.user,
    partner: state.partner || state.admin.partner,
    credentials: state.credentials,
  };
}

export default connect(mapStateToProps, {})(HeaderNav);

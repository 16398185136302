import { connect } from 'react-redux'
import { uploadSignatureRequest, updateKycParams } from 'src/redux/actions';
import PersonalVerification from './PersonalVerification'

function mapStateToProps(){
  return {

  }
}

export default connect(mapStateToProps,{
  uploadSignatureRequest, updateKycParams
})(PersonalVerification)


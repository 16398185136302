import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import StatsContainer from 'src/components/stats-container';
import { GoalsTable } from 'src/components/tables';
import { ACTION_TYPES } from 'src/constants';

class Goals extends Component {
  state = {
    loading: false,
    pageNumber: 1
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState({
      pageNumber
    }, () => this.props.requestAdminGoalsList({
      page: pageNumber
    }))
  }

  componentDidMount() {
    this.requestAdminGoalsList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_GOALS_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminGoalsListSuccess = EventBus.on(ACTION_TYPES.ADMIN_GOALS_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminGoalsListFailure = EventBus.on(ACTION_TYPES.ADMIN_GOALS_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminGoalsList({
      page: this.state.pageNumber
    });
  }

  componentWillUnmount() {
    this.requestAdminGoalsList();
    this.adminGoalsListSuccess();
    this.adminGoalsListFailure();
  }

  render() {
    const { savingGoals, savingGoalsTotal } = this.props.adminGoals;
    return (
      <>
        <StatsContainer
          title="Purchases Summary"
          data={[
            {
              title: 'Total Number of Purchases',
              field: 'totalProducts',
              value: savingGoalsTotal && savingGoalsTotal,
            },
          ]}
        />
        {savingGoals && savingGoals.length > 0 
        ? <GoalsTable 
            data={savingGoals} 
            totalItems={savingGoalsTotal}
            handlePaginationChange={this._handlePaginationChange}
          /> 
        : null}
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default Goals;

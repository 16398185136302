import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import * as yup from 'yup';

import EventBus from 'eventing-bus';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import CustomCardOne from 'src/components/cards/custom-card-one';
import { TextInputOne, InputSelectOne } from 'src/components/form-inputs';
import { BorderedHeader, Loader } from 'src/components';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { ACTION_TYPES } from 'src/constants';
import { ButtonMain, Heading, Label, AsyncSelectInput } from 'src/components/theme';
import moment from 'moment';
import styles from './_generatepaymentlink.module.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class GenerateProductPaymentLink extends Component {
  shortenedUrl = null;

  state = {
    shortenedUrl: '',
    linkCopied: false,
    loading: false,
    isOfferFetching: false,
  };

  _validationSchema = yup.object().shape({
    productOfferUuid: yup.string().required('Please select a product offer.'),
    // nameOfUser: yup.string().required('Please provide name of the user.'),
    // sendLinkEmail: yup.string().email().required(),
    // userPhoneNumber: yup
    //   .string()
    //   .required('Please enter a phone number.')
    //   .matches(/^[6-9]\d{9}$/, { message: 'Phone number not valid' }),
    totalPax: yup.number().min(1).required('Please provide total pax'),
  });

  componentDidMount() {
    this.requestAdminOffersListByProductId = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST_BY_PRODUCT_ID,
      () => this.setState({ isOfferFetching: true }),
    );
    this.adminOffersListByProductIdSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_OFFERS_LIST_BY_PRODUCT_ID_SUCCESS,
      () => this.setState({ isOfferFetching: false }),
    );
    this.adminOffersListByProductIdFailure = EventBus.on(
      ACTION_TYPES.ADMIN_OFFERS_LIST_BY_PRODUCT_ID_FAILED,
      () => this.setState({ isOfferFetching: false }),
    );

    this.requestAdminProductList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminProductListSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminProductListFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.generateUrlSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL, () =>
      this.setState({ loading: true }),
    );
    this.generateUrlSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SHORTEN_URL_SUCCESS,
      this._onUrlGenerated,
    );
    this.generateUrlFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminProductList({ page: 1 });
  }

  componentWillUnmount() {
    this.requestAdminProductList();
    this.adminProductListSuccess();
    this.adminProductListFailure();

    this.requestAdminOffersListByProductId();
    this.adminOffersListByProductIdSuccess();
    this.adminOffersListByProductIdFailure();

    this.generateUrlSubscription();
    this.generateUrlSuccessSubscription();
    this.generateUrlFailureSubscription();
  }

  _onGeneratePaymentLink = (values, { setSubmitting }) => {
    const { origin } = document.location;
    const { adminCredentials } = this.props;
    const nameSplit = values.nameOfUser.split(' ');
    const firstName = nameSplit[0];
    const lastName = nameSplit[1] || '';
    const phoneNumber = `+91${values.userPhoneNumber}`;
    const expiry = moment().add(1, 'year').format('YYYY-MM-DD');

    let link = `${origin}?partnerAccessKey=${adminCredentials.partnerAccessKey}&firstName=${firstName}&lastName=${lastName}&email=${values.sendLinkEmail}&phoneNumber=${phoneNumber}&totalPax=${values.totalPax}`;
    link += values.productOfferUuid ? `&productOfferUuid=${values.productOfferUuid}` : '';
    // : `&productUuid=${values.productUuid}`;

    const linkParams = {
      payment_link: {
        link,
        expiry,
        sent_to_email: values.sendLinkEmail,
        sent_to_phone_number: phoneNumber,
        sent_to_name: values.nameOfUser,
      },
    };

    if (values.productOfferUuid)
      linkParams.payment_link.product_offer_uuid = values.productOfferUuid;
    // if (values.productUuid) linkParams.payment_link.product_uuid = values.productUuid;

    this.props.requestShortenUrl(linkParams);
    setSubmitting(false);
  };

  _onUrlGenerated = (response) => {
    const { referenceId = '' } = response || {};
    const shortenedUrl = `${API_ENDPOINT_URL}?id=${referenceId}`;
    this.setState({ loading: false, shortenedUrl });
  };

  _copyClipboard = (e) => {
    this.shortenedUrl.select();
    this.shortenedUrl.setSelectionRange(0, 99999);

    document.execCommand('copy');
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 2000);
    e.preventDefault();
  };

  loadOptions = (inputValue, callback) => {
    this.props.requestAdminProductListByName({ name: inputValue, callback });
  };

  render() {
    const { products = [], offers = [] } = this.props;
    const { isOfferFetching } = this.state;
    let productsData = [];
    if (products && products.length > 0) {
      productsData = products.map((data) => ({
        ...data,
        value: data?.productName,
        label: data?.productName,
      }));
    }

    return (
      <Container className={styles.container}>
        <Heading variant="section">Generate New Payment Link</Heading>
        <Formik
          initialValues={{
            productUuid: '',
            product: {},
            productOffer: {},
            productOfferUuid: '',
            nameOfUser: '',
            sendLinkEmail: '',
            userPhoneNumber: '',
            totalPax: 1,
          }}
          validationSchema={this._validationSchema}
          onSubmit={this._onGeneratePaymentLink}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <Card className={styles.card}>
                <Card.Header className={styles.cardHeader}>
                  <Heading variant="sub-heading">Payment Details</Heading>
                </Card.Header>
                <Card.Body className={styles.cardBody}>
                  <Row className={styles.cardSection} style={{ padding: '0 1rem' }}>
                    <Col>
                      <Label style={{ marginBottom: '0.2rem' }}>Select Product</Label>
                      <AsyncSelectInput
                        cacheOptions
                        loadOptions={this.loadOptions}
                        defaultOptions={productsData}
                        labelsize="large"
                        name="productUuid"
                        onChange={(e) => {
                          const product = products.find((product) => product.uuid === e?.uuid);
                          this.props.requestAdminOffersListByProductId({ product_id: e?.uuid });
                          setFieldValue('productUuid', product ? product?.uuid : '');
                        }}
                      />
                      {errors?.productUuid && (
                        <div className={styles['error-box']}>{errors?.productUuid}</div>
                      )}
                      {/* <InputSelectOne
                        label="Select the product"
                        name="productUuid"
                        onChange={(e) => {
                          const product = products.find(
                            (product) => product.uuid === e.target.value,
                          );
                          const productOffers = offers.filter(
                            (offer) => offer.product.uuid === e.target.value,
                          );
                          this.setState({ offers: productOffers });
                          setFieldValue('product', product || '');
                          setFieldValue('productUuid', product ? product?.uuid : '');
                        }}
                      >
                        <option>Choose a Product</option>
                        {products.map((product) => (
                          <option key={product.uuid} value={product.uuid}>
                            {product.productName}
                          </option>
                        ))}
                      </InputSelectOne> */}
                    </Col>
                  </Row>
                  <Row className={styles.cardSection} style={{ padding: '0 1rem' }}>
                    <Col>
                      <InputSelectOne
                        className={styles['select-p-offer']}
                        label="Select an Offer"
                        name="productOfferUuid"
                        onChange={(e) => {
                          const offer = offers.find((offer) => offer.uuid === e.target.value);
                          setFieldValue('productOffer', offer || {});
                          setFieldValue('productOfferUuid', offer ? offer?.uuid : '');
                        }}
                        labelsize="large"
                      >
                        <option>
                          {isOfferFetching ? 'Fetching...' : 'Choose a Product Offer'}
                        </option>
                        {offers &&
                          offers.length > 0 &&
                          offers.map((offer) => (
                            <option key={offer.uuid} value={offer.uuid}>
                              {offer.productOfferName}
                            </option>
                          ))}
                      </InputSelectOne>
                    </Col>
                  </Row>

                  {values.product.uuid && (
                    <div className={styles.cardSection}>
                      <BorderedHeader
                        heading="Offer Details"
                        fontSize="1rem"
                        borderColor="#a4a4a4"
                        styles={{
                          fontWeight: 600,
                          paddingLeft: '1.5rem',
                        }}
                      />
                      <CustomCardOne
                        title={
                          values.productOffer.uuid
                            ? `${values.product.productName} (${values.productOffer.months} Months)`
                            : values.product.productName
                        }
                        imgSrc={values.product.imageLink}
                        price={
                          values.productOffer.uuid
                            ? Number(values.product.amount) -
                              Number(values.product.amount) /
                                Number(values.productOffer.discountPercentage)
                            : values.product.amount
                        }
                        loading={this.state.loading}
                      >
                        {values?.product?.description && (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: stateToHTML(
                                  convertFromRaw(JSON.parse(values.product.description)),
                                ),
                              }}
                            />
                          </>
                        )}
                      </CustomCardOne>
                    </div>
                  )}
                </Card.Body>
              </Card>
              <Card className={styles.card}>
                <Card.Header className={styles.cardHeader}>
                  <Heading variant="sub-heading">User Details</Heading>
                </Card.Header>
                <Card.Body>
                  <Row className={styles.cardRow}>
                    <Col md={6} className={styles.cardCol}>
                      <TextInputOne
                        label="Name of User"
                        labelsize="large"
                        name="nameOfUser"
                        type="text"
                      />
                    </Col>
                    <Col md={6}>
                      <TextInputOne
                        label="Email ID to send link to"
                        name="sendLinkEmail"
                        type="text"
                        labelsize="large"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className={styles.cardCol}>
                      <TextInputOne
                        labelsize="large"
                        label="Use Phone Number"
                        name="userPhoneNumber"
                        type="text"
                      />
                    </Col>
                    <Col md={6} className={styles.cardCol}>
                      {/* <TextInputOne
                        label="Total Pax"
                        name="totalPax"
                        type="number"
                        maxlength={10}
                      /> */}
                    </Col>
                  </Row>
                  <div className={styles.urlContainer}>
                    {this.state.shortenedUrl ? (
                      <>
                        <input
                          className={styles.urlInput}
                          ref={(ref) => (this.shortenedUrl = ref)}
                          value={this.state.shortenedUrl}
                          readOnly
                        />
                        {!this.state.linkCopied && (
                          <div
                            className={styles.copied}
                            style={{ cursor: 'pointer' }}
                            onClick={this._copyClipboard}
                          >
                            Click to copy payment link
                          </div>
                        )}
                        {this.state.linkCopied && (
                          <div className={styles.copied}>Link Copied to clipboard</div>
                        )}
                      </>
                    ) : null}
                  </div>
                  <ButtonMain
                    type="submit"
                    align="center"
                    containerstyles={{
                      marginBottom: '1rem',
                      marginTop: '2rem',
                    }}
                  >
                    Generate Payment Link
                  </ButtonMain>
                </Card.Body>
              </Card>
            </Form>
          )}
        </Formik>
        <Loader loading={this.state.loading} />
      </Container>
    );
  }
}

export default GenerateProductPaymentLink;

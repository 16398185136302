import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Convert } from 'src/helpers';
import { Loader, SavvySupport } from 'src/components';
import styles from './pay-success.module.scss';
import OfferBox from '../pay/offer-box';
// import ExtraInfo from '../extra-info';

class PaySuccess extends Component {
  state = {
    loading: false,
    // isShowSavvyPayModal: true,
    // payment: {},
  };

  redirectToSetupSipPage = () => {
    const { history } = this.props;
    history.push('checkout/sip');
  };

  redirectToKyc = () => {
    const { history } = this.props;
    history.push('checkout/kyc');
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user, savingPlan } = this.props;
    const { paymentCalendar = {}, totalPax, paymentMethod } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );

    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const firstPaymentAmount = totalPax
      ? paymentCalendar[firstPaymentKey] * totalPax
      : paymentCalendar[firstPaymentKey];
    const totalOfferPrice = Number(firstPaymentAmount) + Number(savingPlan.goalAbsoluteAmount);

    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>Payment ID: {savingPlan?.paymentRequestId}</div>
              <div className={styles['tick']}>
                <img src="/img/tick-2.svg" alt="tick" />
              </div>
              <div className={styles['title']}>
                Thank you for your payment of {Convert.currency(firstPaymentAmount)}
                <br />
                you have claimed the offer of {Convert.currency(totalOfferPrice)}!
              </div>

              <div className={styles['message']}>
                The receipt will be sent to you via email and whatsapp as well
                <br />{' '}
                <span>
                  Start saving for the remaining {Convert.currency(savingPlan.goalAbsoluteAmount)}!
                </span>
              </div>
            </div>
            {this.props.showKyc && paymentMethod === 'book_save_buy' ? (
              <div className={styles['footer']} onClick={this.redirectToKyc}>
                {' '}
                VERIFY KYC
              </div>
            ) : (
              <div className={styles['footer']} onClick={this.redirectToSetupSipPage}>
                {paymentMethod === 'pay_in_instalments' ? 'SETUP AUTO-PAY' : 'START SAVING'}
              </div>
            )}
          </div>
          <div className={styles['right-box']}>
            <OfferBox
              partner={partner}
              product={product}
              user={user}
              onProceedPayment={this._onProceedPayment}
            />
          </div>
        </div>
        {/* <ExtraInfo /> */}
        <div className="payment-page-support">
          <SavvySupport />
        </div>
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(PaySuccess);

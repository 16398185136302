import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import queryString from 'query-string';
import { Convert } from 'src/helpers';
import { Screen, Wizard, Loader, BootstrapModal } from 'src/components';
import './_custom-modal.scss';

class SipRedirect extends Component {
  state = {
    showSuccessModal: false,
    showErrorModal: false,
    loading: true,
  };

  componentDidMount() {
    const { status } = queryString.parse(this.props.location.search);

    if (status === 'success') {
      this.setState({ loading: false, showSuccessModal: true });
    } else {
      this.setState({ loading: false, showErrorModal: true });
    }
  }

  closeModal = () => {
    this.props.history.push('/dashboard');
  };

  render() {
    const { showSuccessModal, showErrorModal } = this.state;
    const { savingPlan } = this.props;
    const { mandate } = savingPlan;

    return (
      <Screen>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <Wizard progress={100} />
          </Col>
        </Row>
        <BootstrapModal show={showSuccessModal} className="mandate-modal">
          <div className="lock-fare-deposite-box">
            <Player
              autoplay
              keepLastFrame
              src="https://assets7.lottiefiles.com/packages/lf20_te6kna0d.json"
              className="lock-fare-ok-icon"
            />
            <div className="lock-fare-title">Your SIP setup successfully!</div>
            <div className="lock-fare-row">
              <span>Amount:</span> {Convert.currency(mandate?.amount)}/month
            </div>
            <div className="lock-fare-row">
              <span>Starts on:</span> {mandate?.start_date}
            </div>
            <div className="lock-fare-row">
              <span>Ends on:</span> {mandate?.end_date}
            </div>
            <div className="lock-fare-start-saving">
              Transaction ID: {mandate?.partner_transaction_id || 'N/A'}
            </div>
            <Link className="verify-kyc-btn" to="/dashboard">
              GO TO DASHBOARD
            </Link>
          </div>
        </BootstrapModal>
        <BootstrapModal show={showErrorModal} className="mandate-modal">
          <div className="lock-fare-deposite-box">
            <div className="cross-icon">
              <span onClick={this.closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
            <Player
              autoplay
              keepLastFrame
              src="https://assets8.lottiefiles.com/temp/lf20_yYJhpG.json"
              className="lock-fare-error-icon"
            />
            <div className="lock-fare-start-saving">SIP setup failed!</div>
            <div className="lock-fare-failed">We were unable to setup your SIP</div>
            <div className="lock-fare-failed-reason">Please try again.</div>
            <Link className="verify-kyc-btn" to="/setup-sip">
              RETRY SIP SETUP
            </Link>
          </div>
        </BootstrapModal>
        <Loader loading={this.state.loading} title="Verifying mandate, please wait..." />
        <Loader loading={this.state.retrying} title="Retrying mandate, please wait..." />
      </Screen>
    );
  }
}

export default withRouter(SipRedirect);

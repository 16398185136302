import { connect } from 'react-redux'
import { uploadSelfieRequest, updateKycParams } from 'src/redux/actions';
import SelfieVerification from './SelfieVerification';

function mapStateToProps(){
  return {

  }
}

export default connect(mapStateToProps,{
  uploadSelfieRequest, updateKycParams
})(SelfieVerification)



import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { verifyUser, signInUser, verifyOTP, resendOTP, validateEmail, updateUser } from 'src/redux/api';
import {
  verifyUserSuccess,
  verifyUserFailure,
  signInUserSuccess,
  signInUserFailure,
  validateEmailSuccess,
  validateEmailFailure,
  verifyOTPSuccess,
  verifyOTPFailure,
  requestLogoutSuccess,
  requestLogoutFailure,
  resendOtpSuccess,
  resendOtpFailure,
  updateUserSuccess,
  updateUserFailure,
} from 'src/redux/actions';

export function* watchUserRequests() {
  yield takeEvery(ACTION_TYPES.USER_UPDATE_REQUEST, requestUpdateUser);
  yield takeEvery(ACTION_TYPES.REQUEST_VERIFY_USER_TOKEN, requestVerifyUser);
  yield takeEvery(ACTION_TYPES.REQUEST_SIGN_IN_USER, requestSignInUser);
  yield takeEvery(ACTION_TYPES.REQUEST_VERIFY_OTP, requestVerifyOTP);
  yield takeEvery(ACTION_TYPES.REQUEST_RESEND_OTP, requestResendOtp);
  yield takeEvery(ACTION_TYPES.REQUEST_EMAIL_VALIDATION, requestEmailValidation);
  yield takeEvery(ACTION_TYPES.REQUEST_LOGOUT, requestuserLogout);
}

function* requestUpdateUser(action) {
  try {
    const user = yield call(updateUser, action.payload);
    yield put(updateUserSuccess(user));
  } catch (e) {
    yield put(updateUserFailure(e));
  }
}

function* requestVerifyUser(action) {
  try {
    const user = yield call(verifyUser, action.payload.uuid);
    yield put(verifyUserSuccess(user));
  } catch (e) {
    yield put(verifyUserFailure(e));
  }
}

function* requestSignInUser(action) {
  try {
    const user = yield call(signInUser, action.payload);
    yield put(signInUserSuccess(user));
  } catch (e) {
    yield put(signInUserFailure(e));
  }
}

function* requestVerifyOTP(action) {
  try {
    const user = yield call(verifyOTP, action.payload);
    yield put(verifyOTPSuccess(user));
  } catch (e) {
    yield put(verifyOTPFailure(e));
  }
}

function* requestResendOtp(action) {
  try {
    const user = yield call(resendOTP, action.payload);
    yield put(resendOtpSuccess(user));
  } catch (e) {
    yield put(resendOtpFailure(e));
  }
}

function* requestEmailValidation(action) {
  try {
    const response = yield call(validateEmail, action.payload.email);
    yield put(validateEmailSuccess(response));
  } catch (e) {
    yield put(validateEmailFailure(e));
  }
}

function* requestuserLogout() {
  try {
    yield put(requestLogoutSuccess());
  } catch (e) {
    yield put(requestLogoutFailure(e));
  }
}

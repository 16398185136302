import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { userIdentityToken, uuid, search } = restOfProps;
  const isAuthenticated = userIdentityToken && uuid;  
  let redirectRoute = `/${search}`; 
  
  if (!userIdentityToken || !uuid) redirectRoute = `/sign-in?redirectTo=/checkout${encodeURIComponent(search)}`;  
  
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={redirectRoute} />
      }
    />
  );
}

export default ProtectedRoute;

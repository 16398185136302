import { connect } from 'react-redux';
import { requestPaymentLinks, requestAdminCollections } from 'src/redux/actions';
import CollectRequest from './collect-request';

const mapStateToProps = (state) => ({
  collections: state?.adminCollections?.collectionsList,
});

export default connect(mapStateToProps, { requestPaymentLinks, requestAdminCollections })(
  CollectRequest,
);

import { connect } from 'react-redux';
import { requestGoalList } from 'src/redux/actions';
import Dashboard from './dashboard';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { requestGoalList })(Dashboard);

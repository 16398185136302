import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { adminUserDetail, adminUsersList, adminUserPurchases } from 'src/redux/api';
import {
  adminUsersListSuccess,
  adminUsersListFailure,
  adminUserDetailSuccess,
  adminUserDetailFailure,
  adminUserPurchasesSuccess,
  adminUserPurchasesFailure,
} from 'src/redux/actions';

export function* watchAdminUsersRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_USER_PURCHASES, requestAdminUserPurchases);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_USERS_LIST, requestAdminUserList);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_USER_DETAIL, requestAdminUserDetail);
}

function* requestAdminUserDetail(action) {
  try {
    const user = yield call(adminUserDetail, action.payload);
    yield put(adminUserDetailSuccess(user));
  } catch (e) {
    yield put(adminUserDetailFailure);
  }
}

function* requestAdminUserList(action) {
  try {
    const admin = yield call(adminUsersList, action.payload);
    yield put(adminUsersListSuccess(admin));
  } catch (e) {
    yield put(adminUsersListFailure(e));
  }
}

function* requestAdminUserPurchases(action) {
  try {
    const purchases = yield call(adminUserPurchases, action.payload);
    yield put(adminUserPurchasesSuccess(purchases));
  } catch (e) {
    yield put(adminUserPurchasesFailure(e));
  }
}

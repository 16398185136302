import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import './_modal.scss';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  _onClose = () => {
    this.setState({ showModal: false });
  };

  _onDismiss = () => {
    this.props.onClose && this.props.onClose();
  };

  _onExited = () => {
    this.props.onClosed && this.props.onClosed();
  };

  render() {
    return (
      <CSSTransition
        in={this.props.show}
        timeout={300}
        classNames="app-modal"
        unmountOnExit
        onEntering={() => this.setState({ showModal: true })}
        onExited={this._onExited}
      >
        <div className="app-modal-container">
          <CSSTransition
            in={this.state.showModal}
            timeout={300}
            classNames="app-modal-wrapper"
            appear
            unmountOnExit
            onExited={this._onDismiss}
          >
            <div className="app-modal-body">
              {this.props.onClose ? (
                <button className="close-button" onClick={this._onClose}>
                  x
                </button>
              ) : null}
              {this.props.children}
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    );
  }
}

export default Modal;

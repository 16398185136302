import { connect } from 'react-redux';
import { requestSendPennyDrop, createContractRequest } from 'src/redux/actions';
import LinkAccount from './link-account';

function mapStateToProps(state) {
  return {
    user: state.user,
    kyc: state.kyc,
  };
}

export default connect(mapStateToProps, { requestSendPennyDrop, createContractRequest })(
  LinkAccount,
);

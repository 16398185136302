import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { store, persistor } from './redux/store';
import {
  ProtectedRoute,
  AdminProtectedRoute,
  AuthRoute,
  DashboardProtectedRoute,
  AdminAuthRoute,
  ErrorFallback,
} from './components';
import {
  Home,
  PlanGoal,
  Payment,
  SetupSip,
  AutoPay,
  LinkBankAccount,
  SetupAutoPay,
  SipRedirect,
  MandateRedirect,
  Kyc,
  LongKyc,
  ContractCreated,
  Overview,
  Progress,
  TransactionAccountDetails,
  FundInformation,
  Admin,
  Logout,
  NotFound,
  AdminLogin,
  AdminLogout,
  FinalPayment,
  OtpLogin,
  OtpLoginVerify,
  ThemeDemo,
  UserCollectRequest,
  Pay,
  Sip,
  PayKyc,
  PayKycSuccess,
  SipRedirectNew,
  CollectRequest,
  PayLongKyc,
  ReviewPayLongKyc,
  V2Admin,
  CompletePurchase,
  LinkError,
  MandateSuccess,
  MissedPayment,
  MakePayment,
  ReceivedPayment,
  ReceivedPaymentSetup,
} from './screens';
import './scss/_styles.scss';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const ENVIRONMENT = process.env.REACT_APP_ENV || 'development';

class App extends Component {
  constructor(props) {
    super(props);

    this.store = store;
    this.persistor = persistor;
    global.store = this.store;

    this._initSentry();
  }

  _initSentry = () => {
    if (ENVIRONMENT === 'production') {
      const { email, phoneNumber, uuid } = this.store?.getState()?.user || {};

      Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });

      Sentry.setUser({ id: uuid, email, phoneNumber });
    }
  };

  render() {
    return (
      <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
        <Provider store={this.store}>
          <PersistGate persistor={this.persistor}>
            <Notifications />
            <div className="savvy-main">
              <Router>
                <Switch>
                  <Route path="*">
                    <AnimatedSwitch />
                  </Route>
                </Switch>
              </Router>
            </div>
          </PersistGate>
        </Provider>
      </Sentry.ErrorBoundary>
    );
  }
}

function AnimatedSwitch() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="page"
        timeout={300}
        mountOnEnter={false}
        unmountOnExit
      >
        <Switch location={location}>
          <Route exact path="/" component={Home} />
          <Route exact path="/link-error" component={LinkError} />
          <AuthRoute path="/sign-in" component={OtpLogin} />
          <Route path="/verify-sign-in" component={OtpLoginVerify} />
          <Route path="/plan*" component={PlanGoal} />
          <Route exact path="/checkout/sip/redirect" component={SipRedirectNew} />
          <Route exact path="/checkout/missed-payment" component={MissedPayment} />
          <Route exact path="/checkout/make-payment" component={MakePayment} />
          <Route exact path="/checkout/received-payment" component={ReceivedPayment} />
          <Route exact path="/checkout/received-payment-setup" component={ReceivedPaymentSetup} />
          <Route exact path="/checkout" component={Pay} />
          <Route exact path="/checkout/sip" component={Sip} />
          <Route exact path="/checkout/kyc" component={PayKyc} />
          <Route exact path="/checkout/collect-request/:id" component={CollectRequest} />
          <Route exact path="/checkout/long-kyc" component={PayLongKyc} />
          <Route exact path="/checkout/mandate/success" component={MandateSuccess} />
          <Route exact path="/checkout/long-kyc/review" component={ReviewPayLongKyc} />
          <Route exact path="/checkout/long-kyc/:step" component={PayLongKyc} />
          <Route exact path="/checkout/success" component={PayKycSuccess} />
          <ProtectedRoute path="/payment" component={Payment} />
          <ProtectedRoute path="/setup-sip" component={SetupSip} />
          <ProtectedRoute exact path="/auto-pay" component={AutoPay} />
          <ProtectedRoute path="/auto-pay/link-bank-account" component={LinkBankAccount} />
          <ProtectedRoute path="/auto-pay/setup" component={SetupAutoPay} />
          <ProtectedRoute path="/complete-purchase" component={SipRedirect} />
          <ProtectedRoute path="/sip-redirect" component={CompletePurchase} />
          <Route path="/mandate-redirect" component={MandateRedirect} />
          <ProtectedRoute path="/kyc" component={Kyc} />
          <ProtectedRoute exact path="/long-kyc" component={LongKyc} />
          <ProtectedRoute path="/long-kyc/contract-created" component={ContractCreated} />
          <Route path="/final-payment/:goalId/:key" component={FinalPayment} />
          <DashboardProtectedRoute exact path="/dashboard" component={Overview} />
          <DashboardProtectedRoute exact path="/dashboard/progress" component={Progress} />
          <DashboardProtectedRoute exact path="/dashboard/progress/:goalId" component={Progress} />
          <DashboardProtectedRoute
            exact
            path="/dashboard/transactions"
            component={TransactionAccountDetails}
          />

          <DashboardProtectedRoute
            path="/dashboard/collect-request/:uuid"
            component={UserCollectRequest}
          />

          <DashboardProtectedRoute exact path="/dashboard/fund" component={FundInformation} />
          <Route path="/logout" component={Logout} />
          <AdminAuthRoute path="/admin/sign-in" component={AdminLogin} />
          <AdminProtectedRoute path="/admin/logout" component={AdminLogout} />
          <AdminProtectedRoute path="/v1/admin" component={Admin} />
          <AdminProtectedRoute path="/admin" component={V2Admin} />
          <Route path="/theme-demo" component={ThemeDemo} />
          <Route path="*" component={NotFound} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;

import React, { Component } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import cx from 'classnames';
import { Heading } from 'src/components/theme';
import styles from './_account-details.module.scss';

class AccountDetails extends Component {
  render() {
    const { bankAccount } = this.props;
    const { accountNumber, bankName, ifscCode, branch } = bankAccount;
    return (
      <div className={cx(styles['account-details'], { [styles['overview']]: this.props.overview })}>
        <Heading variant="section" className={styles['title']}>Account Details</Heading>
        <p className={styles['description']}>Your money is being invested from:</p>
        <div className={styles['wrapper']}>
          <p>
            Bank account no: <strong>{accountNumber || 'N/A'}</strong>
          </p>
          <p>
            Bank name: <strong>{bankName || 'N/A'}</strong>
          </p>
          <p>
            IFSC code: <strong>{ifscCode || 'N/A'}</strong>
          </p>
          <p>
            Branch name: <strong>{branch || 'N/A'}</strong>
          </p>
          {this.props.isActive && accountNumber ? (
            <Player
              autoplay
              keepLastFrame
              src="https://assets1.lottiefiles.com/packages/lf20_yy29xfpy.json"
              className={styles['secure-shield-lottie']}
            />
          ) : null}
          <img src="/img/protech.png" alt="protech" className={styles['protech']} />
        </div>
      </div>
    );
  }
}

export default AccountDetails;

import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { ACTION_TYPES } from 'src/constants';
import * as yup from 'yup';
// import moment from 'moment';
import { Loader } from 'src/components';
import EventBus from 'eventing-bus';
import { SelectInput } from 'src/components/theme';
import { Container, Section, ButtonOne } from '../../components';
import styles from './_product-offer-link.module.scss';
import ProductOfferLinkModal from './product-offer-link-modal';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class ProductOfferLink extends Component {
  codeInput = null;

  state = {
    loading: false,
    pageNumber: 1,
    showGenerateCodeModal: false,
    isOfferFetching: false,
    code: '',
    codeCopied: false,
    selectOfferId: '',
  };

  _validationSchema = yup.object().shape({
    offer: yup.string().required('Please select a offer type.'),
    // product_list: yup.array().of(
    //     productUuid: yup.string().required('Please select a Product'),
    //   }),
    // ),
  });

  componentDidMount() {
    const uuid = this.props.match.params?.uuid;

    this.requestAdminProductDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.adminProductDetailSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminProductDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (uuid) {
      this.props.requestAdminProductDetail({ uuid });
    }

    this.requestAdminOffersList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminOffersListSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_SUCCESS, () => {
      this.setState({ loading: false });
    });
    this.adminOffersListFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );
    if (!this.props.offers || (this.props.offers && this.props.offers.length <= 0)) {
      this.props.requestAdminOffersList({
        page: this.state.pageNumber,
      });
    }

    this.generateUrlSubscription = EventBus.on(ACTION_TYPES.PRODUCT_LINK_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.generateUrlSuccessSubscription = EventBus.on(
      ACTION_TYPES.PRODUCT_LINK_SUCCESS,
      this._onUrlGenerated,
    );
    this.generateUrlFailureSubscription = EventBus.on(ACTION_TYPES.PRODUCT_LINK_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestAdminProductDetail();
    this.adminProductDetailSuccess();
    this.adminProductDetailFailure();

    this.requestAdminOffersList();
    this.adminOffersListSuccess();
    this.adminOffersListFailure();

    this.generateUrlSubscription();
    this.generateUrlSuccessSubscription();
    this.generateUrlFailureSubscription();
  }

  _onUrlGenerated = (response) => {
    let shortUrl = response?.productLink?.shortLink;
    const urlId = shortUrl && shortUrl.substr(shortUrl.indexOf('?product_id=') + 12);
    const shortenedUrl = `${API_ENDPOINT_URL}?product_id=${urlId}`;
    this.setState({ loading: false, showGenerateCodeModal: true, code: shortenedUrl });
  };

  _onGenerateLink = (values, { setSubmitting }) => {
    const { selectOfferId } = this.state;
    const productId = this.props.match.params?.uuid;
    const { origin } = document.location;
    const { adminCredentials } = this.props;
    let link = `${origin}?partnerAccessKey=${adminCredentials.partnerAccessKey}&productOfferUuid=${selectOfferId}&productUuid=${productId}`;
    const linkParams = {
      product_link: {
        link,
        product_offer_uuid: selectOfferId,
        product_uuid: productId,
      },
    };
    this.props.adminProductLink(linkParams);
    setSubmitting(false);
  };

  _onGenerateEmailCodeSuccess = (code) => {
    this.setState({ loading: false, showGenerateCodeModal: true, code });
  };

  // loadOptions = (inputValue, callback) => {
  //   this.props.requestAdminProductListByName({ name: inputValue, callback });
  // };

  _decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  _copyCodeToClipboard = (e) => {
    this.codeInput.select();
    this.codeInput.setSelectionRange(0, 99999);

    document.execCommand('copy');
    this.setState({ codeCopied: true });
    setTimeout(() => {
      this.setState({ codeCopied: false });
    }, 2000);
    e.preventDefault();
  };

  closeModal = () => {
    this.setState({ showGenerateCodeModal: false });
  };

  render() {
    const { product, offers } = this.props;
    const { showGenerateCodeModal, code, isOfferFetching } = this.state;
    let productsOffers = [];
    if (offers && offers.length > 0) {
      offers.forEach((offer) => {
        productsOffers.push({
          ...offers,
          label: offer?.productOfferName,
          value: offer?.uuid,
        });
      });
    }
    return (
      <div className={styles['payment-links']}>
        <Container title="Select the offer you would like to share">
          <Formik
            initialValues={{
              offer: '',
            }}
            validationSchema={this._validationSchema}
            onSubmit={this._onGenerateLink}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <Section title={`Product :  ${product?.productName}`}>
                  <br />
                  {console.log('values', values)}
                  {console.log('errors', errors)}
                  <div className={styles['label']}>
                    {isOfferFetching ? 'Fetching...' : 'Select an offer to generate the link'}
                  </div>
                  <SelectInput
                    name="offer"
                    cacheOptions
                    placeholder=""
                    labelsize="large"
                    options={productsOffers}
                    onChange={(e) => {
                      this.setState({ selectOfferId: e?.value });
                      setFieldValue('offer', e?.value);
                    }}
                  />
                  {errors && errors?.offer && (
                    <div className={styles['error-box']}>{errors?.offer}</div>
                  )}
                </Section>
                <Section title="">
                  <div className={styles['save-btn']}>
                    <ButtonOne text="Generate link" type="submit" />
                  </div>
                </Section>
              </Form>
            )}
          </Formik>
        </Container>
        {showGenerateCodeModal && (
          <ProductOfferLinkModal
            code={code || ''}
            copyCodeToClipboard={this._copyCodeToClipboard}
            decodeHtml={this._decodeHtml}
            codeInput={(ref) => (this.codeInput = ref)}
            codeCopied={this.state.codeCopied}
            closeModal={this.closeModal}
          />
        )}

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default withRouter(ProductOfferLink);

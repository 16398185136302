import React, { Component } from 'react';
import './_row.scss';

class Row extends Component {
  render() {
    const {
      children,
      flex = 1,
      direction = 'row',
      justify = 'flex-start',
      align = 'flex-start',
    } = this.props;

    return (
      <div
        className={`grid-row ${
          flex ? `flex-${flex}` : ''
        } ${direction} ${`justify-${justify}`} ${`align-${align}`}`}
      >
        {children}
      </div>
    );
  }
}

export default Row;

import { connect } from 'react-redux';
import { createDepositRequest, fetchDepositRequest, requestGoalDetail } from 'src/redux/actions';
import SaveMore from './save-more';

function mapStateToProps(state) {
  const savingAccount = state.user.accounts.find((account) => account.accountType === 'savings');

  return {
    product: state.product,
    bankAccount: state.user.depositBankAccount,
    savingAccount,
  };
}

export default connect(mapStateToProps, {
  createDepositRequest,
  fetchDepositRequest,
  requestGoalDetail,
})(SaveMore);

import { connect } from "react-redux";
import { requestAdminOffersList } from "src/redux/actions";
import PaymentLinks from "./payment-links";

const mapStateToProps = (state) => ({
  productOffers: state.adminOffers,
});

export default connect(mapStateToProps, {
  requestAdminOffersList,
})(PaymentLinks);

import { connect } from 'react-redux';
import {
  requestAdminProductList,
  requestAdminProductListByName,
  requestAdminOffersListByProductId,
  fetchEmailTemplatesRequest,
  generateEmailCodeRequest,
} from 'src/redux/actions';
import EmailTemplates from './email-templates';

function mapStateToProps(state) {
  return {
    products: state.adminProducts.products,
    offers: state.adminOffers.productOffers,
  };
}

export default connect(mapStateToProps, {
  requestAdminProductList,
  requestAdminProductListByName,
  requestAdminOffersListByProductId,
  fetchEmailTemplatesRequest,
  generateEmailCodeRequest,
})(EmailTemplates);

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function OccupationsReducer(state = INITIAL_STATE.occupations, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_OCCUPATION_LIST_SUCCEEDED: {
      return action.payload;
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.occupations;
    }

    default:
      return state;
  }
}

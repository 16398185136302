import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Dashboard from 'src/screens/dashboard';

function DashboardProtectedRoute({ component: Component, ...restOfProps }) {  
  const { userIdentityToken, uuid, location } = restOfProps;
  const isAuthenticated = userIdentityToken && uuid;
  const { pathname } = location;
  let redirectRoute = '/';
  
  if (!userIdentityToken || !uuid) redirectRoute = `/sign-in?redirectTo=${pathname}`;
  
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Dashboard>
            <Component {...props} />
          </Dashboard>
        ) : (
          <Redirect to={redirectRoute} />
        )
      }
    />
  );
}

export default DashboardProtectedRoute;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import moment from 'moment';
import OfferTabItem from '../offer-tab-item';
import './_offer-tabs.scss';

const PAYMENT_METHODS = {
  'book_save_buy' : 'Book. Save. Buy',
  'pay_in_instalments': 'Pay in Installments'
};

class OfferTabs extends Component {
  /**
   * converts input date to a specific format
   * @param {int} date
   * @return {string}
   */
   _getFormattedDate = (date) => moment(date, 'DDMMYYYY').format('Do MMMM');

   /**
    * append suffix to number example: 'st' to 1, 'nd' to 2 etc.
    * @param {*} i
    */
   _ordinalSuffix(i) {
     const j = i % 10;
     const k = i % 100;
 
     if (j == 1 && k != 11) {
       return `${i}st`;
     }
     if (j == 2 && k != 12) {
       return `${i}nd`;
     }
     if (j == 3 && k != 13) {
       return `${i}rd`;
     }
     return `${i}th`;
   }

  _onProceedPay = (offer) => {
    this.props.onProceedFirstInstallment(offer);
  }

  render() {
    const { product, user, isFromPlanPage } = this.props;   
    const { productOffers = [] } = product;
    const offer = productOffers.length > 0 ? productOffers[0] : {};
    const { paymentMethodsAvailable = [] } = offer;
    
    return (
      <div className="offer-tabs">
        <h4 className="title">Choose your buying method</h4>
        <Tab.Container defaultActiveKey="0">          
          <Nav className="tab-links">
            {
              paymentMethodsAvailable && 
              paymentMethodsAvailable.length > 0 && 
              paymentMethodsAvailable.map((paymentMethod, index) => {
                if (!PAYMENT_METHODS[paymentMethod]) return null;
                return <Nav.Item>
                  <Nav.Link eventKey={index}>
                    <img src={`/img/${paymentMethod}.svg`} alt={PAYMENT_METHODS[paymentMethod]} />
                    <h4>{PAYMENT_METHODS[paymentMethod]}</h4>
                  </Nav.Link>
                </Nav.Item>
              })
            }            
          </Nav>                    
          <Tab.Content className="tab-content">
            {
              paymentMethodsAvailable && 
              paymentMethodsAvailable.length > 0 && 
              paymentMethodsAvailable.map((paymentMethod, index) => {
                if (!PAYMENT_METHODS[paymentMethod]) return null;
                return <Tab.Pane eventKey={index}>
                  <OfferTabItem 
                    {...product}
                    paymentMethod={paymentMethod}
                    user={user}
                    onProceedPay={this._onProceedPay}
                    isFromPlanPage={isFromPlanPage}
                  />
                </Tab.Pane>
              })
            }               
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
}

export default withRouter(OfferTabs);

import React, { useLayoutEffect, useState } from 'react';
import cx from 'classnames';
import styles from './_jumbotrontwo.module.scss';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function JumbotronTwo(props) {
  const [width] = useWindowSize();

  return (
    <div
      className={cx(styles.container, props.className)}
      style={
        props.imgSrc
          ? {
              backgroundImage: `url(${props.imgSrc})`,
              height: `${width < 991 ? '200px' : '60.2vh'}`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundColor: 'black',
              backgroundRepeat: 'no-repeat',
            }
          : {}
      }
    >
      <div className={cx(styles.body, props?.bodyContainerStyles)}>{props?.content || ''}</div>
    </div>
  );
}

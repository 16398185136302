import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import _ from 'lodash';
import EventBus from 'eventing-bus';
// import { GoalProgressTable } from 'src/components/tables';
import {
  // Header, StatsContainer,
  Loader,
} from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import { Convert } from 'src/helpers';
import GoalProgress from '../goal-progress';
import ProgressProvider from '../goal-progress/progress-provider';
import styles from './_goalprogresslist.module.scss';
import GoalDetails from '../goal-details';

class GoalProgressList extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.requestGoalList = EventBus.on(ACTION_TYPES.REQUEST_GOAL_LIST, () =>
      this.setState({ loading: true }),
    );
    this.requestGoalListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_GOAL_LIST_SUCCESS,
      this._computeGoalsStats,
    );
    this.requestGoalListFailure = EventBus.on(ACTION_TYPES.REQUEST_GOAL_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );
    this.requestGoalDetail = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.requestGoalDetailSuccess = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.requestGoalDetailFailure = EventBus.on(ACTION_TYPES.REQUEST_GOAL_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    const { match } = this.props;
    if (match?.params?.goalId) {
      this.props.requestGoalDetail({ id: match?.params?.goalId });
    } else {
      this.props.requestGoalList();
    }
  }

  componentWillUnmount() {
    this.requestGoalList();
    this.requestGoalListSuccess();
    this.requestGoalListFailure();
    this.requestGoalDetail();
    this.requestGoalDetailSuccess();
    this.requestGoalDetailFailure();
  }

  /**
   * Computes stats to be shown on goal list
   */
  _computeGoalsStats = () => {
    let stats = {};

    stats['invested'] = this.props.savingGoals.reduce(
      (acc, obj) => acc + Number(obj.investmentPlusPendingBalance),
      0,
    );

    stats['totalGoalAmount'] = this.props.savingGoals.reduce(
      (acc, obj) => acc + Number(obj.goalAbsoluteAmount),
      0,
    );

    // todo:compute withdrawn

    stats['numOfProducts'] = this.props.savingGoals.length;

    // console.log(invested, totalGoalAmount, numOfProducts)

    this.setState({ loading: false, ...stats });
  };

  removeGoalId = () => {
    const { history } = this.props;
    history.push('/dashboard/progress');
  };

  circleProgressBar = ({ valueEnd, data = {} }) => {
    let imageUrl = '';

    if (data.savingGoalCategory === 'snbl') {
      imageUrl =
        (data?.productOffer?.product?.imageUrls.length > 0 &&
          data?.productOffer?.product?.imageUrls[0]?.url) ||
        '/img/image.png';
    } else {
      imageUrl = data?.unsplashImage?.link || '/img/image.png';
    }

    return (
      <ProgressProvider valueStart={0} valueEnd={valueEnd}>
        {(value) => (
          <CircularProgressbarWithChildren
            value={value}
            styles={buildStyles({
              pathColor: '#F9C301',
              trailColor: '#DDDDDD',
              pathTransitionDuration: 1.5,
            })}
          >
            <div
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
              className={styles['goal-image']}
            />
          </CircularProgressbarWithChildren>
        )}
      </ProgressProvider>
    );
  };

  handleGoalProgress = ({ savingGoal }) => {
    this.props.history.push(`/dashboard/progress/${savingGoal.id}`);
  };

  render() {
    const { savingGoals, savingGoal, match, transactions } = this.props;
    let groupByData = [];
    if (savingGoals && savingGoals.length > 0) {
      groupByData = _.mapValues(_.groupBy(savingGoals, 'productOffer.partner.name'), (clist) =>
        clist.map((car) => _.omit(car, 'productOffer.partner.name')),
      );
    }
    const unsplashImage = savingGoal?.unsplashImage || {};
    const totalGoalsAmount = savingGoal?.goalAbsoluteAmount || 0;
    const imageUrl =
      (savingGoal?.productOffer?.product?.imageUrls.length > 0 &&
        savingGoal?.productOffer?.product?.imageUrls[0]?.url) ||
      '';

    let contribution = 0;
    // let payment = 0;
    if (savingGoal?.savingGoalContributions && savingGoal?.savingGoalContributions.length > 0) {
      savingGoal?.savingGoalContributions.forEach((data) => {
        if (data?.status === 'completed' || data?.status === 'pending_investments') {
          contribution += parseFloat(data?.amount) || 0;
        }
      });
    }

    let pendingAmount = 0;
    if (transactions && transactions.deposits && transactions.deposits.length > 0) {
      transactions.deposits.forEach((data) => {
        if (data?.amount && data?.status === 'pending_investment') {
          pendingAmount += Number(data?.amount);
        }
      });
    }
    // if (savingGoal?.payments && savingGoal?.payments.length > 0) {
    //   savingGoal?.payments.forEach((data) => {
    //     if (data?.status === 'completed') {
    //       payment += parseFloat(data?.amount) || 0;
    //     }
    //   });
    // }

    const totalAmountSaved = contribution;
    const returnAmount =
      Number(savingGoal?.currentInvestmentBalance) - Number(savingGoal?.currentBalance);
    const returns = returnAmount > 0 ? returnAmount : 0;
    // const noOfProducts = savingGoals.length || 0;

    // const totalProductGoalAmount =
    //   (savingGoals &&
    //     savingGoals.length > 0 &&
    //     _.sumBy(savingGoals, (goal) => Number(goal.goalAbsoluteAmount))) ||
    //   0;

    // let productContribution = 0;
    // let productPayment = 0;
    // let productWithDrawals = 0;
    // if (savingGoals && savingGoals.length > 0) {
    //   savingGoals.forEach((saving) => {
    //     if (saving?.savingGoalContributions && saving?.savingGoalContributions.length > 0) {
    //       saving?.savingGoalContributions.forEach((data) => {
    //         if (data?.status === 'completed' || data?.status === 'pending_investments') {
    //           productContribution += parseFloat(data?.amount) || 0;
    //         }
    //       });
    //     }
    //     if (saving?.payments && saving?.payments.length > 0) {
    //       saving?.payments.forEach((data) => {
    //         if (data?.status === 'completed') {
    //           productPayment += parseFloat(data?.amount) || 0;
    //         }
    //       });
    //     }
    //     if (saving?.savingGoalWithdrawals && saving?.savingGoalWithdrawals.length > 0) {
    //       saving?.savingGoalWithdrawals.forEach((data) => {
    //         if (data?.status === 'completed' || data?.status === 'pending') {
    //           productWithDrawals += parseFloat(data?.amount) || 0;
    //         }
    //       });
    //     }
    //   });
    // }
    // const totalInvested = productPayment + productContribution;

    return (
      <div>
        {match?.params?.goalId && (
          <button className={styles['back-btn']} onClick={this.removeGoalId}>
            Back
          </button>
        )}
        {!match?.params?.goalId ? (
          <div className={styles['main-content']}>
            <div>
              <div className={styles['goal-header']}>
                <div>Your ongoing purchases : {(savingGoals && savingGoals.length) || 0}</div>
                {/* <div>Filter by</div> */}
              </div>
            </div>

            {groupByData &&
              Object.entries(groupByData) &&
              Object.entries(groupByData).length > 0 &&
              Object.entries(groupByData).map(([name, goalData]) => (
                <div key={name}>
                  <div className={styles['header-tab']}>
                    <div>{name !== 'undefined' ? name : 'SavvyPay Purchases'}</div>
                  </div>

                  <div className={styles['content-box']}>
                    {goalData &&
                      goalData.length > 0 &&
                      goalData.map((savingGoal, index) => {
                        const totalPaid = _.sumBy(savingGoal.payments, (goal) => goal.amount);
                        let amountSaved = 0;
                        savingGoal?.savingGoalContributions.forEach((data) => {
                          if (
                            data?.status === 'completed' ||
                            data?.status === 'pending_investments'
                          ) {
                            amountSaved += parseFloat(data?.amount) || 0;
                          }
                        });
                        const valueEnd =
                          (parseFloat(amountSaved) / savingGoal?.goalAbsoluteAmount) * 100;

                        return (
                          <div
                            className={cx(styles['goal-progress'])}
                            key={index}
                            onClick={() => this.handleGoalProgress({ savingGoal })}
                          >
                            <div>
                              <div className={styles['goal-image-container']}>
                                {this.circleProgressBar({
                                  valueEnd: valueEnd || 0,
                                  data: savingGoal,
                                })}
                              </div>
                              <h4 className={styles['offer-title']}> {savingGoal.description}</h4>
                              <p className={styles['amount-saved']}>
                                Amount Paid: {Convert.currency(totalPaid)}
                              </p>
                              <p className={cx(styles['total-goal-amount'])}>
                                Amount Saved {Convert.currency(amountSaved)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}

            <Loader loading={this.state.loading} />
          </div>
        ) : (
          <>
            <GoalProgress
              data={{
                goalAbsoluteAmount: totalGoalsAmount,
                investmentPlusPendingBalance: totalAmountSaved,
                unsplashImage,
                description: savingGoal?.description,
                imageUrl,
                productOffer: savingGoal?.productOffer,
                savingGoalCategory: savingGoal?.savingGoalCategory,
                payments: savingGoal?.payments,
                pendingAmount,
                price: savingGoal?.productOffer?.discountedAmount,
                returns: returns || 0,
              }}
              title={savingGoal?.productOffer?.partner?.name}
              isActive={savingGoal?.active}
            />
            <GoalDetails
              savingGoal={savingGoal}
              loading={this.state.loading}
              data={{
                goalAbsoluteAmount: totalGoalsAmount,
                investmentPlusPendingBalance: totalAmountSaved,
                unsplashImage,
                returns: returns || 0,
                imageUrl,
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default withRouter(GoalProgressList);

import React, { Component } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import { Loader } from 'src/components';
import styles from './mandate-success.module.scss';
import OfferBox from '../pay/offer-box';
// import ExtraInfo from '../extra-info';

class MandateSuccess extends Component {
  state = {
    loading: false,
    // isShowSavvyPayModal: true,
    // payment: {},
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user } = this.props;

    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={cx(styles['header'], styles['mobile'])}>
              <div className={styles['inner-header']}>
                <div className={styles['sub-inner-header']}>
                  <div className={styles['title']}>Your mandate has been setup!</div>
                  <div className={styles['price']}>Rs. 10,000</div>
                  <div className={styles['note']}>Total amount to be saved</div>
                  <div className={styles['amount']}>
                    <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(styles['progress-bar'], styles['mobile'])}>
              <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
            </div>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>Your mandate has been setup!</div>
              <div className={styles['tick']}>
                <img src="/img/tick-2.svg" alt="tick" />
              </div>
              <div className={styles['message']}>
                Rs.[x] will be saved on the [date] of each month
                <div>until [last save date]</div>
              </div>
            </div>
            <div className={styles['footer']}>Done</div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox
              partner={partner}
              product={product}
              user={user}
              onProceedPayment={this._onProceedPayment}
            />
          </div>
        </div>
        {/* <ExtraInfo /> */}
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(MandateSuccess);

import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { InputFileTwo } from 'src/components/form-inputs';
import { Formik, Form as FormikForm } from 'formik';
import { ApiRequest } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import cx from 'classnames';
import styles from './signature-verification.module.scss';

class SignatureVerification extends Component {
  state = {
    loading: false,
    signatureImage: '',
    signatureLoading: false,
  };

  componentDidMount() {
    this.uploadSignatureRequestSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SIGNATURE_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.uploadSignatureRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SIGNATURE_SUCCESS,
      this._onUploadedSignature,
    );
    this.uploadSignatureRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_SIGNATURE_FAILED,
      () => this.setState({ loading: false }),
    );
    this.setState({ signatureImage: this.props?.kyc?.signature?.image_url });
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.uploadSignatureRequestSubscription();
    this.uploadSignatureRequestSuccessSubscription();
    this.uploadSignatureRequestFailureSubscription();
  };

  _onSignatureImageUpdated = (event) => {
    this.setState({ signatureLoading: true });
    const [file] = event.target.files;

    ApiRequest.uploadToSignzy(file).then((res) => {
      this.setState({ signatureLoading: false, signatureImage: res.data.file.directUrl });
    });
  };

  _onSubmitSignature = () => {
    this.props.updateKycParams({ signature: { image_url: this.state.signatureImage } });
    this.props.uploadSignatureRequest({
      image_url: this.state.signatureImage,
    });
  };

  _onUploadedSignature = () => {
    this.setState({ loading: false }, () => {
      this.props.onGoto('video');
    });
  };

  render() {
    // const { onNext } = this.props;

    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <Formik
          initialValues={{
            identity_proof: '',
          }}
          validationSchema={this._validationSchema}
          onSubmit={this._onSubmitAddressProof}
        >
          <FormikForm>
            <div className={styles['another-account']}>
              <div className={styles['pay-content']}>
                <div className={styles['title']}>Verify your KYC Start Saving</div>
                <div className={styles['line']} />
                <div className={styles['sub-title']}>SIGNATURE VERIFICATION</div>
                <div
                  className={cx(
                    'pb-3',
                    styles['form-box'],
                    styles['upload-img-box'],
                    styles['mobile-space'],
                  )}
                >
                  <InputFileTwo
                    mandatory
                    heading="Upload your signature"
                    label="Upload a copy of your signature in a white background"
                    name="signature"
                    id="signature"
                    accept="image/png, image/jpeg"
                    loading={this.state.signatureLoading}
                    onChange={this._onSignatureImageUpdated}
                    labelStyle={styles['label-style']}
                    imageSrc={this.props?.kyc?.signature?.image_url}
                  />
                </div>
              </div>
              <button
                className={styles['footer']}
                type="submit"
                disabled={!this.state.signatureImage || this.state.loading}
                onClick={this._onSubmitSignature}
              >
                {this.state.loading ? 'LOADING...' : 'NEXT'}
              </button>
            </div>
          </FormikForm>
        </Formik>
      </div>
    );
  }
}

export default SignatureVerification;

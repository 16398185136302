import { connect } from 'react-redux';
import {
  requestAdminCancelRequestDetail,
  requestAdminConfirmCancelRequest,
} from 'src/redux/actions';
import CancelRequestsDetails from './cancel-requests-details';

const mapStateToProps = (state) => ({
  cancelRequestsDetail: state.adminCancelRequests?.cancelRequestsDetail,
});

export default connect(mapStateToProps, {
  requestAdminCancelRequestDetail,
  requestAdminConfirmCancelRequest,
})(CancelRequestsDetails);

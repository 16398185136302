import React, { Component } from 'react';
import cx from 'classnames';
import { Row, Col } from 'react-bootstrap';
import GoalProgress from '../progress/goal-progress';
// import GoalDetails from '../progress/goal-details';
import Transactions from '../transaction-account-details/transactions';
// import AccountDetails from '../transaction-account-details/account-details';
import FundInfo from '../fund-information/fund-info';
import styles from './_overview.module.scss';

class Overview extends Component {
  componentDidMount() {
    this.props.requestGoalList();
  }

  render() {
    return (
      <div className={styles['overview']}>
        <Row>
          <Col lg={6}>
            <div className={cx(styles['left-space'], styles['right-space'])}>
              <GoalProgress
                overview
                title={`No. of Offers: ${this.props.noOfGoals || 0}`}
                data={{
                  goalAbsoluteAmount: this.props.totalGoalAmount,
                  investmentPlusPendingBalance: this.props.totalAmountSaved,
                  payments: this.props.payments,
                  unsplashImage: this.props.unsplashImage,
                  imageUrl: this.props.imageUrl,
                  returns: this.props.returns,
                  price: this.props.price || 0,
                }}
              />
            </div>
            {/* <GoalDetails overview /> */}
          </Col>
          <Col lg={6}>
            {/* <Transactions overview /> */}
            <div className={styles['right-side']}>
              <FundInfo overview />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={cx(styles['left-space'], styles['transactions'])}>
              <Transactions overview />
            </div>
          </Col>
          {/* <Col lg={6}>
            <AccountDetails overview isActive={this.props.isActive} />
          </Col>
          <Col lg={6}>
            <FundInfo overview />
          </Col> */}
        </Row>
      </div>
    );
  }
}

export default Overview;

import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import Countdown from 'react-countdown';
import { Player } from '@lottiefiles/react-lottie-player';
import { ACTION_TYPES } from 'src/constants';
import { Loader, BootstrapModal, DrawerModal } from 'src/components';
import ConfirmBankAccount from './confirm-bank-account';
import BankAccountInput from './bank-account-input';
import styles from './_link-bank-account.module.scss';
import './_custom-modal.scss';

class LinkBankAccount extends Component {
  state = {
    accountNumber: this.props.bankDetails.bankAccountNumber || '',
    ifscCode: this.props.bankDetails.ifscCode || '',
    showPennyCheck: false,
    loadingText: '',
    requestedOTMBanks: false,
    noOTMBanks: false,
    loading: false,
  };

  componentDidMount() {
    this.fullKycRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION, () =>
      this.setState({
        loading: true,
        loadingText: 'Registering details...',
      }),
    );
    this.fullKycSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_SUCCEEDED,
      this._onFullKycReceive,
    );
    this.fullKycFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_FAILED,
      () => this.setState({ loading: false }),
    );

    this.kycLoginSubscription = EventBus.on(ACTION_TYPES.REQUEST_KYC_LOGIN, () =>
      this.setState({
        loading: true,
        loadingText: 'Requesting access...',
      }),
    );
    this.kycLoginSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_KYC_LOGIN_SUCCEEDED,
      this._onKycLogin,
    );
    this.kycLoginFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_KYC_LOGIN_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.pullOTMBanksRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_OTM_BANKS, () =>
      this.setState({ loading: true }),
    );
    this.pullOTMBanksSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_OTM_BANKS_SUCCEEDED,
      this._onReceivedOTMBanks,
    );
    this.pullOTMBanksFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_OTM_BANKS_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestBankDetailsRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BANK_DETAILS,
      () =>
        this.setState({
          loading: true,
          loadingText: 'Requesting bank details...',
        }),
    );
    this.requestBankDetailsSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BANK_DETAILS_SUCCEEDED,
      this._onBankDetailsFetched,
    );
    this.requestBankDetailsFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BANK_DETAILS_FAILED,
      () => this.setState({ loading: false }),
    );

    this.sendPennyDropRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_SEND_PENNY_DROP, () =>
      this.setState({ loading: false, showPennyCheck: true }),
    );
    this.sendPennyDropSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_PENNY_DROP_SUCCEEDED,
      this._onPennyDropSuccess,
    );
    this.sendPennyDropFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SEND_PENNY_DROP_FAILED,
      this._onPennyDropFailed,
    );

    this.submitBankAccountRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT,
      () =>
        this.setState({
          loading: true,
          loadingText: 'Saving bank details...',
        }),
    );
    this.submitBankAccountSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT_SUCCEEDED,
      this._onBankAccountSubmissionSuccess,
    );
    this.submitBankAccountFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT_FAILED,
      () => this.setState({ loading: false }),
    );

    if (
      !this.state.requestedOTMBanks &&
      (!this.props.bankDetails || !this.props.bankDetails.bankAccountNumber)
    ) {
      this.props.requestOTMBanks();
    }
  }

  componentWillUnmount() {
    this.fullKycRequestSubscription();
    this.fullKycSuccessSubscription();
    this.fullKycFailureSubscription();

    this.kycLoginSubscription();
    this.kycLoginSuccessSubscription();
    this.kycLoginFailureSubscription();

    this.pullOTMBanksRequestSubscription();
    this.pullOTMBanksSuccessSubscription();
    this.pullOTMBanksFailureSubscription();

    this.requestBankDetailsRequestSubscription();
    this.requestBankDetailsSuccessSubscription();
    this.requestBankDetailsFailureSubscription();

    this.sendPennyDropRequestSubscription();
    this.sendPennyDropSuccessSubscription();
    this.sendPennyDropFailureSubscription();

    this.submitBankAccountRequestSubscription();
    this.submitBankAccountSuccessSubscription();
    this.submitBankAccountFailureSubscription();
  }

  _onAccountNumberChange = (accountNumber) => {
    this.setState({ accountNumber });
  };

  _onIFSCCodeChange = (ifscCode) => {
    this.setState({ ifscCode });
  };

  _isValidForm = () => this.state.accountNumber.length > 5 && this.state.ifscCode.length === 11;

  _onKycLogin = () => {
    this.props.requestBankDetails({
      ifsc_code: this.state.ifscCode,
    });
  };

  _onLinkNewAccount = () => {
    this.setState({ noOTMBanks: true });
  };

  _onFetchOTMBanks = () => {
    this.props.requestOTMBanks();
  };

  _onFullKycReceive = () => {
    this.props.requestKycLogin();
  };

  _onReceivedOTMBanks = (response) => {
    this.setState({ loading: false, requestedOTMBanks: true }, () => {
      if (response && response.length > 0) {
        const bank = response[0] || {};
        this.setState({
          accountNumber: bank.bankAccountNumber,
          ifscCode: bank.ifscCode,
          noOTMBanks: false,
        });
      } else {
        this.setState({ noOTMBanks: true });
      }
    });
  };

  _onBankDetailsFetched = () => {
    this.setState({ showPennyCheck: true }, () => {
      this.props.requestSendPennyDrop({
        bank_account_number: this.state.accountNumber,
        ifsc_code: this.state.ifscCode,
      });
    });
  };

  _onPennyDropSuccess = () => {
    this.setState({ showPennyCheck: false }, () => {
      this.props.requestSubmitBankAccount({
        payment_mode: 'NIIB',
        bank_account_type: 'SB',
        bank_account_number: this.state.accountNumber,
        bank_name: this.props.bankDetails.bankName,
        bank_code: this.props.bankDetails.depositBankCode || this.props.bankDetails.bankCode,
        ifsc_code: this.state.ifscCode,
        city: this.props.bankDetails.city,
        branch: this.props.bankDetails.branchName,
        otm_bank_code: this.props.bankDetails.otmBankCode,
        otm_pay_mech: this.props.bankDetails.payMech,
        otm_om_umrn: this.props.bankDetails.omUmrn,
        otm_micr: this.props.bankDetails.micr,
        address: 'address',
        otm_allowed: this.props.bankDetails.otmAllowed,
      });
    });
  };

  _onPennyDropFailed = () => {
    this.setState({ showPennyCheck: false });
  };

  _onBankAccountSubmissionSuccess = () => {
    this.setState({ loading: false }, () => {
      this.props.onComplete && this.props.onComplete();
    });
  };

  _onSubmit = () => {
    const { email, firstName, lastName } = this.props.user;

    this.props.requestFullKycRegistration({
      email,
      full_name: `${firstName} ${lastName}`,
    });
  };

  render() {
    return (
      <div className={styles['link-bank-account']}>
        <Row>
          <Col xs={12}>
            <div className={styles['bank-ac-title']}>
              Link {this.state.noOTMBanks && 'Another'} Bank Account
            </div>
            {!this.state.noOTMBanks && (
              <div className={styles['bank-ac-heading']}>
                This is the bank account linked to your PAN Card
              </div>
            )}
          </Col>
          <Col xs={12}>
            {this.state.noOTMBanks ? (
              <BankAccountInput
                accountNumber={this.state.accountNumber}
                ifscCode={this.state.ifscCode}
                onAccountNumberChange={this._onAccountNumberChange}
                onIFSCCodeChange={this._onIFSCCodeChange}
                onFetchOTM={this._onFetchOTMBanks}
                disabled={this.state.loading}
              />
            ) : (
              <ConfirmBankAccount
                {...this.props.bankDetails}
                onLinkAccount={this._onLinkNewAccount}
              />
            )}
            <div className={styles['mb-5']}>
              <Button
                disabled={!this._isValidForm() || this.state.loading}
                className={styles['next-btn']}
                onClick={this._onSubmit}
                loading={this.state.loading}
              >
                {this.state.loading ? 'Loading...' : 'Next'}
              </Button>
              {!this.state.noOTMBanks && (
                <Button
                  className={styles['link-account-btn']}
                  onClick={this._onLinkNewAccount}
                  variant="outline-warning"
                >
                  I want to link another account
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {window.innerWidth > 768 ? (
          <BootstrapModal show={this.state.showPennyCheck} className="custom-modal">
            <div className="penny-check-box">
              <div className="title">PENNY CHECK</div>
              <div className="description">
                We are depositing a small amount into your <br />
                account to verify it
              </div>
              <Player
                autoplay
                loop
                keepLastFrame
                src="https://assets6.lottiefiles.com/packages/lf20_5xjxnhli.json"
                className="sand-clock"
              />
              <div className="timer">
                This may take upto{' '}
                <Countdown date={Date.now() + 10000} renderer={({ seconds }) => seconds} /> seconds
              </div>
            </div>
          </BootstrapModal>
        ) : (
          <DrawerModal show={this.state.showPennyCheck} className={styles['mobile-penny-check']}>
            <div className={styles['title']}>PENNY CHECK</div>
            <div className={styles['description']}>
              We are depositing a small amount <br />
              into your account to verify it
            </div>
            <Player
              autoplay
              loop
              keepLastFrame
              src="https://assets6.lottiefiles.com/packages/lf20_5xjxnhli.json"
              className={styles['sand-clock-mobile']}
            />
            <div className={styles['timer']}>
              This may take upto{' '}
              <Countdown date={Date.now() + 10000} renderer={({ seconds }) => seconds} /> seconds
            </div>
          </DrawerModal>
        )}
        <Loader loading={this.state.loading} title={this.state.loadingText} />
      </div>
    );
  }
}

export default LinkBankAccount;

import React, { Component } from 'react';
import styles from './_column.module.scss';

class Column extends Component {
  render() {
    return <div className={styles['grid-column']}>{this.props.children}</div>;
  }
}

export default Column;

import React, { Component } from 'react';
import moment from 'moment';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import { withRouter, Link } from 'react-router-dom';
import CustomBadge from 'src/components/custom-badge';
import { Dropdown, Button, Modal } from 'react-bootstrap';
import styles from './_user-payment-detail.module.scss';
import './_user-modal.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class UserPaymentDetail extends Component {
  state = {
    loading: false,
    isExpiring: false,
    isModalOpen: false,
    isSuccessModalOpen: false,
  };

  componentDidMount() {
    const { requestPaymentLinksDetail, match } = this.props;

    this.requestPaymentDetail = EventBus.on(ACTION_TYPES.PAYMENT_LINKS_DETAIL_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.paymentDetailSuccess = EventBus.on(ACTION_TYPES.PAYMENT_LINKS_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.paymentDetailFailure = EventBus.on(ACTION_TYPES.PAYMENT_LINKS_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestPaymentExpiryLink = EventBus.on(ACTION_TYPES.PAYMENT_EXPIRE_LINK_REQUEST, () =>
      this.setState({ isExpiring: true }),
    );
    this.paymentExpiryLinkSuccess = EventBus.on(ACTION_TYPES.PAYMENT_EXPIRE_LINK_SUCCESS, () => {
      this.setState({ isExpiring: false, isModalOpen: false, isSuccessModalOpen: true });
      if (match?.params?.uuid) requestPaymentLinksDetail({ uuid: match?.params?.uuid });
    });
    this.paymentExpiryLinkFailure = EventBus.on(ACTION_TYPES.PAYMENT_EXPIRE_LINK_FAILED, () =>
      this.setState({ isExpiring: false }),
    );

    if (match?.params?.uuid) requestPaymentLinksDetail({ uuid: match?.params?.uuid });
  }

  componentWillUnmount() {
    this.requestPaymentDetail();
    this.paymentDetailSuccess();
    this.paymentDetailFailure();

    this.requestPaymentExpiryLink();
    this.paymentExpiryLinkSuccess();
    this.paymentExpiryLinkFailure();
  }

  handleExpirePaymentLink = () => {
    const { requestPaymentExpireLink, match } = this.props;
    requestPaymentExpireLink({
      payment_link_id: match?.params?.uuid,
    });
  };

  handleModal = ({ isModalOpen }) => {
    this.setState({ isModalOpen });
  };

  closeSuccessModal = () => {
    this.setState({ isSuccessModalOpen: false });
  };

  render() {
    const { adminPaymentDetail } = this.props;
    const { loading, isExpiring, isModalOpen, isSuccessModalOpen } = this.state;
    let isActive = true;
        
    if( adminPaymentDetail?.expiry) isActive = moment().diff(moment(adminPaymentDetail?.expiry)) < 0;

    return (
      <div className={styles['payment-links']}>
        <div className={styles['heading']}>
          <h2 className="p-3">Payment Links</h2>
          <Dropdown className={styles['dropdown']}>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className={styles['dropdown-toggle-btn']}
            >
              Export Data
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">CSV</Dropdown.Item>
              <Dropdown.Item href="#/action-2">PDF</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={styles['title']}>
          Purchase Details for{' '}
          <a
            href={`${API_ENDPOINT_URL}?id=${adminPaymentDetail?.referenceId}` || '#'}
            target="_blank"
            alt="short-link"
            rel="noreferrer"
          >
            {`${API_ENDPOINT_URL}?id=${adminPaymentDetail?.referenceId}`}
          </a>
        </div>
        <div className={styles['detail']}>
          <div>
            {/* <p>Purchase ID: </p> */}
            <p>
              Product:{' '}
              <Link to={`/admin/product/${adminPaymentDetail?.productOfferUuid}`}>
                {adminPaymentDetail?.productOfferUuid}
              </Link>
            </p>
            <p>
              Offer:{' '}
              <Link to={`/admin/product/offer/${adminPaymentDetail?.productOfferUuid}`}>
                {adminPaymentDetail?.productOfferUuid}
              </Link>
            </p>
            <p>Offer activated on: {adminPaymentDetail?.createdAt}</p>
          </div>
          <div>
            {isActive ? (
              <CustomBadge value="Active" type="success" className={styles['badge-style']} />
            ) : (
              <CustomBadge value="Inactive" type="error" className={styles['badge-style']} />
            )}
          </div>
        </div>
        {adminPaymentDetail?.sentToName ||
        adminPaymentDetail?.sentToEmail ||
        (adminPaymentDetail?.sentToPhoneNumber &&
          adminPaymentDetail?.sentToPhoneNumber !== '+91') ? (
          <>
            <div className={styles['title']}>User Details</div>
            <div className={styles['detail']}>
              <div>
                {adminPaymentDetail?.sentToName ? (
                  <p>Name : {adminPaymentDetail?.sentToName}</p>
                ) : null}
                {adminPaymentDetail?.sentToPhoneNumber &&
                adminPaymentDetail?.sentToPhoneNumber !== '+91' ? (
                  <p>Phone Number: {adminPaymentDetail?.sentToPhoneNumber}</p>
                ) : null}
                {adminPaymentDetail?.sentToEmail ? (
                  <p>Email ID: {adminPaymentDetail?.sentToEmail}</p>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
        {isActive && (
          <div className={styles['expiry-btn']}>
            <Button onClick={() => this.handleModal({ isModalOpen: true })}>
              Expire Payment Link
            </Button>
          </div>
        )}
        <Loader loading={loading} />
        <Modal show={isModalOpen} size="lg">
          <div className="custom-modal-title">
            Are you Sure you want to <br />
            expire the payment link?
          </div>
          <div className="custom-modal-footer">
            <Button
              className="modal-btn"
              variant="primary"
              disabled={isExpiring}
              onClick={this.handleExpirePaymentLink}
            >
              {isExpiring ? 'Expiring...' : 'Yes, I am sure'}
            </Button>
            <Button
              className="modal-btn"
              variant="primary"
              disabled={isExpiring}
              onClick={() => this.handleModal({ isModalOpen: false })}
            >
              No, don&apos;t expire the link
            </Button>
          </div>
        </Modal>
        <Modal show={isSuccessModalOpen} size="lg">
          <div className="alert-icon">
            <img src="/img/new-offer-ok.svg" alt="alert-icon" />
          </div>
          <div className="custom-success-modal-title">Your payment link has expired.</div>
          <div className="custom-modal-footer">
            <Button className="modal-btn" variant="primary" onClick={this.closeSuccessModal}>
              Done
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(UserPaymentDetail);

import React, { Component } from 'react';
import { ButtonOne, Loader } from 'src/components';
import moment from 'moment';
import { Convert } from 'src/helpers';
import { notify } from 'react-notify-toast';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Container, LinkOne } from '../../../components';
import { InfoBlock } from '../../../components/info-block';
import styles from './_cancel-requests-details.module.scss';

export class CancelRequestsDetails extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { requestAdminCancelRequestDetail, match } = this.props;
    this.requestAdminCancelRequestDetail = EventBus.on(
      ACTION_TYPES.CANCEL_REQUESTS_DETAIL_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.adminCancelRequestDetailSuccess = EventBus.on(
      ACTION_TYPES.CANCEL_REQUESTS_DETAIL_SUCCESS,
      () => {
        this.setState({ loading: false });
      },
    );
    this.adminCancelRequestDetailFailure = EventBus.on(
      ACTION_TYPES.CANCEL_REQUESTS_DETAIL_FAILED,
      () => this.setState({ loading: false }),
    );

    this.requestAdminConfirmCancelRequest = EventBus.on(
      ACTION_TYPES.CONFIRM_CANCEL_REQUESTS_REQUEST,
      () => {
        this.setState({ loading: true });
      },
    );
    this.adminConfirmCancelRequestSuccess = EventBus.on(
      ACTION_TYPES.CONFIRM_CANCEL_REQUESTS_SUCCESS,
      () => {
        notify.show('Request submitted successfully', 'success');
        this.setState({ loading: false });
      },
    );
    this.adminConfirmCancelRequestFailure = EventBus.on(
      ACTION_TYPES.CONFIRM_CANCEL_REQUESTS_FAILED,
      () => this.setState({ loading: false }),
    );

    if (match?.params?.uuid) requestAdminCancelRequestDetail({ id: match?.params?.uuid });
  }

  componentWillUnmount() {
    this.requestAdminCancelRequestDetail();
    this.adminCancelRequestDetailSuccess();
    this.adminCancelRequestDetailFailure();

    this.requestAdminConfirmCancelRequest();
    this.adminConfirmCancelRequestSuccess();
    this.adminConfirmCancelRequestFailure();
  }

  offerRefund = () => {
    const { requestAdminConfirmCancelRequest, match } = this.props;
    if (match?.params?.uuid) requestAdminConfirmCancelRequest({ id: match?.params?.uuid });
  };

  noRefund = () => {
    const { history } = this.props;
    history.push('/admin/cancel-requests');
  };

  render() {
    const { loading } = this.state;
    const { cancelRequestsDetail } = this.props;
    let bookingAmount = 0;
    if (cancelRequestsDetail?.payments && cancelRequestsDetail?.payments.length > 0) {
      bookingAmount = cancelRequestsDetail?.payments[0]?.amount;
    }
    return (
      <Container title="Cancellation and Refund" buttonText="create a new link">
        <InfoBlock
          title="Purchase Details"
          data={{
            'Booking Amount': Convert.toCurrencyValue(bookingAmount || 0),
            'Purchase ID': cancelRequestsDetail?.id || '',
            'Offer ID': cancelRequestsDetail?.productOffer.uuid || '',
            'Offer Activated on':
              moment(cancelRequestsDetail?.createdAt, 'DD/MM/YYYY').format('DD MMMM, YYYY') || '',
            'Offer to be completed on ':
              moment(cancelRequestsDetail?.endDate, 'DD/MM/YYYY').format('DD MMMM, YYYY') || '',
            'User ID': (
              <LinkOne to={`/admin/analytics/users/${cancelRequestsDetail?.userUuid}`}>
                {cancelRequestsDetail?.userUuid}
              </LinkOne>
            ),
          }}
        />

        <div className={styles.buttonContainer}>
          <div className={styles['offer-refund-btn']}>
            <ButtonOne text="OFFER REFUND" onClick={this.offerRefund} />
          </div>
          <ButtonOne text="NO REFUND" onClick={this.noRefund} />
        </div>
        <Loader loading={loading} />
      </Container>
    );
  }
}

export default CancelRequestsDetails;

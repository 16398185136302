import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import cx from 'classnames';
import SideNavigation from './side-navigation';
import TopNavigation from './top-navigation';
import FooterNavigation from './footer-navigation';
import styles from './_adminlayout.module.scss';

const AdminLayout = (props) => {
  const { children } = props;
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div className={styles.wrapper}>
      <Container
        fluid
        style={{ paddingLeft: 0, paddingRight: 0 }}
        className={cx('admin-screen-layout', styles['admin-layout'])}
      >
        <div className={styles['layout-container']}>
          <aside className={`${styles.aside} ${sidebarToggle ? styles.toggle : ''}`}>
            <SideNavigation handleSidebarToggle={() => handleSidebarToggle()} />
          </aside>
          <main className={`${styles.main} ${sidebarToggle ? '' : styles.toggle}`}>
            <TopNavigation handleSidebarToggle={() => handleSidebarToggle()} />
            <div className="admin-screen-animate">{children}</div>
            <FooterNavigation />
          </main>
        </div>
      </Container>
    </div>
  );
};

export default AdminLayout;

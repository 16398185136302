import { useField } from 'formik';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import cx from 'classnames';
import { Label, TextInput } from 'src/components/theme';
import styles from './_textinputone.module.scss';
import globalStyles from '../_styles.module.scss';

const TextInputOne = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { labelsize } = props || {};
  const isAutoCompleteOff = props?.isAutoComplete ? 'on' : 'off';
  return (
    <>
      <Row style={{ ...props.containerstyles }}>
        {label && label != '' && (
          <Col md={12} className={styles.inputLabelContainer}>
            <Label
              htmlFor={props.id || props.name}
              className={cx(styles.inputLabel, props.labelstyles, {
                [styles.largeLabel]: labelsize === 'large',
              })}
            >
              {label}
            </Label>
          </Col>
        )}
        <Col md={12}>
          <TextInput {...field} {...props} autoComplete={isAutoCompleteOff} />
          {meta.touched && meta.error ? (
            <div className={globalStyles.error}>{meta.error}</div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default TextInputOne;

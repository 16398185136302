import React, { Button } from 'react-bootstrap';
import cx from 'classnames';
import styles from './_savvy-support.module.scss';

const SavvySupport = ({ text }) => {
  const onOpenSavvySupport = () => {
    window.open(`https://wa.me/919136099947?text=${text || ''}`, '_blank');
  };

  return (
    <div className={cx('global-savvy-support', styles['savvy-support'])}>
      <Button onClick={onOpenSavvySupport} className={styles['savvy-support-btn']}>
        <img src="/img/support.svg" alt="Support" />
      </Button>
      <br />
      <div className={styles['savvy-support-text']}>
        We are available
        <br /> 8am-9pm
      </div>
    </div>
  );
};

export default SavvySupport;

import React from 'react';
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

function CustomPieChart(props) {
  const { data } = props;
  return (
    <ResponsiveContainer>
      <PieChart width={730} height={250}>
        <Legend />
        <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" fill="#8884d8" />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default CustomPieChart;

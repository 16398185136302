import { connect } from 'react-redux';
import {
  extractAddressProofRequest,
  uploadAddressProofRequest,
  updateKycParams,
} from 'src/redux/actions';
import AddressVerification from './address-verification';

function mapStateToProps(state) {
  return {
    kyc: state.kyc,
  };
}

export default connect(mapStateToProps, {
  extractAddressProofRequest,
  uploadAddressProofRequest,
  updateKycParams,
})(AddressVerification);

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function PartnerReducer(state = INITIAL_STATE.partner, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_PRODUCT_DETAIL_SUCCEEDED:
      const { productOffers } = action.payload;
      const partner = productOffers && productOffers[0] ? productOffers[0].partner : {};

      return {
        ...partner,
      };

    case ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED:
      return {
        ...action.payload.partner,
      };

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED:
      return INITIAL_STATE.partner;

    default:
      return state;
  }
}

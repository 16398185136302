import { connect } from 'react-redux'
import { requestProductOffers, requestProductDetail } from 'src/redux/actions';
import LoginBanner from './LoginBanner'

function mapStateToProps(state){
  return {
    product: state.product
  }
}

export default connect(mapStateToProps, {
  requestProductDetail,
  requestProductOffers
})(LoginBanner)
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AdminLayout from './layout';
import {
  PaymentLinks,
  GeneratePaymentLink,
  PaymentLinknDetails,
  Users,
  UserDetails,
  Transactions,
  Products,
  AddEditProduct,
  EmailTemplates,
  SocialMediaTemplates,
  Agent,
  Profile,
  CancelRequests,
  CancelRequestsDetails,
  ProductOfferLink,
} from './screens';

class V2Admin extends Component {
  render() {
    return (
      <>
        <AdminLayout>
          <Switch>
            <Route exact path="/admin" component={PaymentLinks} />
            <Route exact path="/admin/agent" component={Agent} />
            <Route exact path="/admin/profile" component={Profile} />
            <Route exact path="/admin/paymentlink/new" component={GeneratePaymentLink} />
            <Route exact path="/admin/paymentlink/edit/:uuid" component={GeneratePaymentLink} />
            <Route exact path="/admin/paymentlink/:uuid" component={PaymentLinknDetails} />
            <Route exact path="/admin/analytics/users" component={Users} />
            <Route exact path="/admin/analytics/transactions" component={Transactions} />
            <Route exact path="/admin/analytics/users/:uuid" component={UserDetails} />
            <Route exact path="/admin/marketing/products" component={Products} />
            <Route exact path="/admin/marketing/products/new" component={AddEditProduct} />
            <Route exact path="/admin/marketing/products/edit/:uuid" component={AddEditProduct} />
            <Route exact path="/admin/marketing/products/:uuid/link" component={ProductOfferLink} />
            <Route exact path="/admin/marketing/email-templates" component={EmailTemplates} />
            <Route
              exact
              path="/admin/marketing/social-media-templates"
              component={SocialMediaTemplates}
            />
            <Route exact path="/admin/cancel-requests" component={CancelRequests} />
            <Route exact path="/admin/cancel-requests/:uuid" component={CancelRequestsDetails} />
          </Switch>
        </AdminLayout>
      </>
    );
  }
}

export default withRouter(V2Admin);

import {
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  requestGoalDetail,
} from 'src/redux/actions';
import { connect } from 'react-redux';

import PayLongKyc from './pay-long-kyc';

function mapStateToProps(state) {
  return {
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
    taxStatuses: state.taxStatuses,
  };
}

export default connect(mapStateToProps, {
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  requestGoalDetail,
})(PayLongKyc);

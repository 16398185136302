import { ACTION_TYPES } from 'src/constants';

/**
 *
 * Admin cancel requests
 *
 */
export function requestAdminCancelRequestList(payload) {
  return {
    type: ACTION_TYPES.CANCEL_REQUESTS_LIST_REQUEST,
    payload,
  };
}
export function adminCancelRequestListSuccess(payload) {
  return {
    type: ACTION_TYPES.CANCEL_REQUESTS_LIST_SUCCESS,
    payload,
  };
}
export function adminCancelRequestListFailed(payload) {
  return {
    type: ACTION_TYPES.CANCEL_REQUESTS_LIST_FAILED,
    payload,
  };
}

export function requestAdminCancelRequestDetail(payload) {
  return {
    type: ACTION_TYPES.CANCEL_REQUESTS_DETAIL_REQUEST,
    payload,
  };
}
export function adminCancelRequestDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.CANCEL_REQUESTS_DETAIL_SUCCESS,
    payload,
  };
}
export function adminCancelRequestDetailFailed(payload) {
  return {
    type: ACTION_TYPES.CANCEL_REQUESTS_DETAIL_FAILED,
    payload,
  };
}

export function requestAdminConfirmCancelRequest(payload) {
  return {
    type: ACTION_TYPES.CONFIRM_CANCEL_REQUESTS_REQUEST,
    payload,
  };
}
export function adminConfirmCancelRequestSuccess(payload) {
  return {
    type: ACTION_TYPES.CONFIRM_CANCEL_REQUESTS_SUCCESS,
    payload,
  };
}
export function adminConfirmCancelRequestFailed(payload) {
  return {
    type: ACTION_TYPES.CONFIRM_CANCEL_REQUESTS_FAILED,
    payload,
  };
}

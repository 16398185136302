import React, { Component } from 'react';
// import { Row, Col } from 'react-bootstrap';
import { InputSelectOne } from 'src/components/form-inputs';
import { Form, Formik } from 'formik';
import { withRouter } from 'react-router-dom';
// import { ACTION_TYPES } from 'src/constants';
// import moment from 'moment';
// import EventBus from 'eventing-bus';
import { Container, Section, ButtonOne } from '../../components';
import styles from './_social-media-templates.module.scss';

class SocialMediaTemplates extends Component {
  render() {
    return (
      <div className={styles['payment-links']}>
        <Container title="Social Media Templates">
          <h2 className={styles['coming-soon']}>Coming Soon...</h2>
          {false && (
            <Formik>
              {() => (
                <Form>
                  <Section title="Choose a Template">
                    <InputSelectOne label="Select Social Media Platform" name="offerLink">
                      <option> </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </InputSelectOne>
                    <br />
                    <InputSelectOne label="Select Template Type" name="offerLink">
                      <option> </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </InputSelectOne>
                    <br />
                    <InputSelectOne label="Select Product" name="offerLink">
                      <option> </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </InputSelectOne>
                  </Section>
                  <Section title="">
                    <div className={styles['save-btn']}>
                      <ButtonOne text="Generate Code" />
                    </div>
                  </Section>
                </Form>
              )}
            </Formik>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(SocialMediaTemplates);

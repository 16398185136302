import React, { Component } from 'react';
import cx from 'classnames';
import { Convert } from 'src/helpers';
import { ButtonOne } from 'src/components';
import moment from 'moment';
import './_offer-tab-item.scss';

class OfferTabItemSetup extends Component {
  /**
   * converts input date to a specific format
   * @param {int} date
   * @return {string}
   */
  _getFormattedDate = (date) => moment(date, 'DDMMYYYY').format('Do MMMM');

  /**
   * append suffix to number example: 'st' to 1, 'nd' to 2 etc.
   * @param {*} i
   */
  _ordinalSuffix(i) {
    const j = i % 10;
    const k = i % 100;

    if (j == 1 && k != 11) {
      return `${i}st`;
    }
    if (j == 2 && k != 12) {
      return `${i}nd`;
    }
    if (j == 3 && k != 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  _navigateToDashboard = () => {
    this.props.onNavigateToDashboard && this.props.onNavigateToDashboard();
  };

  render() {
    const { amount, productOffers, productOffer, taxes, paymentMethod, autopay, totalPax } =
      this.props;
    const offer = productOffers?.length > 0 ? productOffers[0] : productOffer || {};
    let { upfrontPaymentAmount, paymentCalendar, discountPercentage, savingReturn, product } =
      offer;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar || {}).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const actualProductPrice = Number(amount || product?.amount) * Number(totalPax);
    let monthlyPayment = paymentCalendar ? paymentCalendar[sortedPaymentCalendarDates[1]] : 0;
    const discount = (actualProductPrice / 100) * discountPercentage;
    const offerAmount = actualProductPrice - discount;
    const finalPrice = offerAmount - upfrontPaymentAmount;
    const returnAmount = (finalPrice / 100) * Number(savingReturn);
    const getGst =
      taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'gst');
    const getTcs =
      taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'tcs');

    let gstAmount = 0;
    let tcsAmount = 0;

    if (getGst?.percentage && getGst?.percentage > 0) {
      gstAmount = (Number(getGst?.percentage) / 100) * offerAmount;
    }

    if (getTcs?.percentage && getTcs?.percentage > 0) {
      tcsAmount = (Number(getTcs?.percentage) / 100) * offerAmount;
    }

    const fromKycScreen = false;
    monthlyPayment *= Number(totalPax);
    upfrontPaymentAmount *= Number(totalPax);

    return (
      <div className="offer-tab-item">
        <div className="offer-content">
          <div className="offer-upper-header">
            <div className="offer-header-tab">
              <div className="offer-header-title">
                <span>Book</span> today for
              </div>
              <div className="offer-header-heading">{Convert.currency(upfrontPaymentAmount)}</div>
            </div>
            <div className="offer-header-tab offer-header-tab-arrow">
              <img src="/img/right-arrow3.svg" alt="arrow" />
            </div>
            <div className="offer-header-tab">
              {' '}
              <div className="offer-header-title">
                {paymentMethod === 'book_save_buy' ? (
                  <>
                    <span>Save</span> for{' '}
                    {sortedPaymentCalendarDates.length > 0
                      ? sortedPaymentCalendarDates.length - 1
                      : 0}{' '}
                    months
                  </>
                ) : (
                  <>
                    <span>Pay the rest</span> in{' '}
                    {sortedPaymentCalendarDates.length > 0
                      ? sortedPaymentCalendarDates.length - 1
                      : 0}{' '}
                    installments
                  </>
                )}
              </div>
              <div className="offer-header-heading-bold">
                {Convert.currency(monthlyPayment)}/<span>month</span>
              </div>
            </div>
            {paymentMethod === 'book_save_buy' ? (
              <>
                <div className="offer-header-tab offer-header-tab-arrow">
                  <img src="/img/right-arrow3.svg" alt="arrow" />
                </div>
                <div className="offer-header-tab">
                  {' '}
                  <div className="offer-header-title">
                    <span>Buy</span> and Travel
                  </div>
                  <div className="offer-header-heading-bold">in {offer?.months} months</div>
                </div>
              </>
            ) : null}
          </div>
          <div className="offer-detail">
            <div className="header">
              <span className="heading">
                {paymentMethod === 'book_save_buy'
                  ? 'Book, Save and Buy Schedule'
                  : 'Pay in Installments Schedule'}
              </span>
            </div>
            <div className="total-amount-content">
              <span>Total amount :</span> {Convert.currency(offerAmount + gstAmount + tcsAmount)}*
            </div>
            <div className="offer-amount-content">
              {gstAmount || tcsAmount ? (
                <>
                  * Offer amount : {Convert.currency(offerAmount)}
                  {gstAmount
                    ? ` + GST: ${Convert.currency(
                        Math.round((Number(gstAmount) + Number.EPSILON) * 100) / 100,
                      )}`
                    : ''}
                  {tcsAmount
                    ? ` + TCS: ${Convert.currency(
                        Math.round((Number(tcsAmount) + Number.EPSILON) * 100) / 100,
                      )}`
                    : ''}
                </>
              ) : (
                'The price is inclusive of all the taxes'
              )}
            </div>
            <div className="timeline-wrapper">
              <div className="timeline">
                <div className={`common-box ${fromKycScreen ? '' : 'grey-bg'} first-common-box`}>
                  <div className="content">
                    <div className="left">
                      <img src="/img/ok-5.svg" alt="tick" className="tick" />
                      <span className="dark-box">Book&nbsp;</span>
                      your holiday today & secure your voucher
                    </div>
                    <div className="right">{Convert.currency(upfrontPaymentAmount)}</div>
                  </div>
                </div>
                <div
                  className={`common-box ${fromKycScreen ? 'grey-bg pb-3 common-box-after' : ''}`}
                >
                  <div className="content remaining-installments-text">
                    {paymentMethod === 'book_save_buy' ? (
                      <div>
                        <span className="dark-box"> Save&nbsp;</span>for remaining in easy
                        installments with <br />
                      </div>
                    ) : (
                      <div>
                        <img src="/img/ok-5.svg" alt="tick" className="tick" />
                        <span className="dark-box"> Auto-Pay&nbsp;</span>the merchant in{' '}
                        {sortedPaymentCalendarDates.length - 1} easy installments <br />
                      </div>
                    )}
                  </div>
                  {paymentMethod === 'book_save_buy' ? (
                    <a
                      className="icici-link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.icicipruamc.com/mutual-fund/debt-funds/icici-prudential-liquid-fund"
                    >
                      {' '}
                      ICICI Prudential Liquid Fund
                    </a>
                  ) : null}
                </div>
                {sortedPaymentCalendarDates &&
                  sortedPaymentCalendarDates.length > 0 &&
                  sortedPaymentCalendarDates.map((installment, index) => {
                    if (index === 0) return null;
                    return (
                      <div
                        className={cx('point-node', {
                          'point-node-last': index === sortedPaymentCalendarDates.length - 1,
                        })}
                        key={index}
                      >
                        <div className="content">
                          <div className="left">
                            {this._ordinalSuffix(index)} installment on{' '}
                            {this._getFormattedDate(installment)}
                          </div>
                          <div className="right">
                            {Convert.currency(paymentCalendar[installment] * totalPax)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {paymentMethod === 'book_save_buy' ? (
                  <div className="earn-cashback">
                    Earn returns upto <span>{Convert.currency(returnAmount)}</span> on your savings
                  </div>
                ) : null}
                {paymentMethod === 'book_save_buy' &&
                  sortedPaymentCalendarDates &&
                  sortedPaymentCalendarDates.length > 0 &&
                  sortedPaymentCalendarDates.map((installment, index) => {
                    if (index == sortedPaymentCalendarDates.length - 1) {
                      return (
                        <div className="common-box buy-the-holiday" key={index}>
                          <div className="content">
                            <div className="hidder" />
                            <div style={{ maxWidth: '460px' }}>
                              <span className="dark-box"> Buy&nbsp;</span>
                              the holiday by paying the booking amount with money saved and enjoy
                              your travels.
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="text-center">
              {!fromKycScreen && !autopay && (
                <div className="tnc-container">
                  <div className="tnc-text">Enjoy your travels!</div>
                </div>
              )}{' '}
              <div className="pay-first-btn">
                <ButtonOne text="Done" type="submit" onClick={this._navigateToDashboard} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OfferTabItemSetup;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { Convert } from 'src/helpers';
import { ButtonOne } from 'src/components';
import moment from 'moment';
import './_offer-tab-item.scss';

class OfferTabItem extends Component {
  state = {
    isTermsAccepted: false,
  };

  /**
   * converts input date to a specific format
   * @param {int} date
   * @return {string}
   */
  _getFormattedDate = (date) => moment(date, 'DDMMYYYY').format('Do MMMM');

  /**
   * append suffix to number example: 'st' to 1, 'nd' to 2 etc.
   * @param {*} i
   */
  _ordinalSuffix(i) {
    const j = i % 10;
    const k = i % 100;

    if (j == 1 && k != 11) {
      return `${i}st`;
    }
    if (j == 2 && k != 12) {
      return `${i}nd`;
    }
    if (j == 3 && k != 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  _onAcceptTerms = (e) => {
    this.setState({ isTermsAccepted: e.target.checked });
  };

  _onProceedPay = () => {
    const { paymentMethod, productOffers, amount } = this.props;
    const offer = productOffers.length > 0 ? productOffers[0] : {};
    const discountAbsoluteAmount = (Number(amount) / 100) * Number(offer?.discountPercentage);
    const discountedPrice = Number(amount) - discountAbsoluteAmount;
    this.props.onProceedPay &&
      this.props.onProceedPay({
        ...offer,
        discountedPrice,
        discountAbsoluteAmount,
        paymentMethod,
      });
  };

  _redirectToNewFlow = ({ paymentMethod }) =>
    this.props.history.push({
      pathname: `/checkout`,
      search: `${this.props.location.search}&selectedPaymentMethod=${paymentMethod}`,
    });

  _onSetupAutoPay = () => {
    this.props.onProceedAutoPay && this.props.onProceedAutoPay();
  };

  render() {
    const {
      // amount,
      productOffers,
      productOffer,
      // taxes,
      closeIsKycVerificationPage,
      paymentMethod,
      autopay,
      isFromPlanPage,
      totalPax = 1,
    } = this.props;
    const offer = productOffers?.length > 0 ? productOffers[0] : productOffer || {};
    let { upfrontPaymentAmount, paymentCalendar, amount } = offer;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar || {}).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );

    const productAmount = Number(amount * totalPax);
    const discountPercentage = Number(offer?.discountPercentage || 0);
    const discountAmount = (productAmount / 100) * discountPercentage;
    const offerAmount = productAmount - discountAmount;
    const finalPrice = offerAmount - offer?.upfrontPaymentAmount;
    const returnAmount = (finalPrice / 100) * Number(offer?.savingReturn);
    const totalAmount = Number(offer?.taxedAmount * (totalPax || 1));
    // const sortedPaymentCalendar = Object.keys(paymentCalendar || {}).sort(
    //   (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    // );
    // // const actualProductPrice = Number(offer?.discountedAmount || 0) * Number(totalPax || 1);
    let monthlyPayment = paymentCalendar ? paymentCalendar[sortedPaymentCalendarDates[1]] : 0;
    // // const discount = (Number(actualProductPrice) / 100) * discountPercentage;
    // const offerAmount = Number(offer?.discountedAmount || 0) * Number(totalPax || 1);

    // const returnAmount =
    //   (offer?.amount || 0 - upfrontPaymentAmount / 100) * Number(savingReturn || 0);

    // const getGst =
    //   taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'gst');
    // const getTcs =
    //   taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'tcs');

    // let gstAmount = 0;
    // let tcsAmount = 0;

    // if (getGst?.percentage && getGst?.percentage > 0) {
    //   gstAmount = (Number(getGst?.percentage) / 100) * offerAmount;
    // }

    // if (getTcs?.percentage && getTcs?.percentage > 0) {
    //   tcsAmount = (Number(getTcs?.percentage) / 100) * offerAmount;
    // }

    const fromKycScreen = false;
    monthlyPayment *= Number(totalPax);
    upfrontPaymentAmount *= Number(totalPax);

    const taxAmount =
      Number(offer?.taxedAmount * totalPax) - Number(offer?.discountedAmount * totalPax);

    return (
      <div className="offer-tab-item">
        <div className="offer-content">
          <div className="offer-upper-header">
            <div className="offer-header-tab">
              <div className="offer-header-title">
                <span>Book</span> today for
              </div>
              <div className="offer-header-heading">{Convert.currency(upfrontPaymentAmount)}</div>
            </div>
            <div className="offer-header-tab offer-header-tab-arrow">
              <img src="/img/right-arrow3.svg" alt="arrow" />
            </div>
            <div className="offer-header-tab">
              {' '}
              <div className="offer-header-title">
                {paymentMethod === 'book_save_buy' ? (
                  <>
                    <span>Save</span> for{' '}
                    {sortedPaymentCalendarDates.length > 0
                      ? sortedPaymentCalendarDates.length - 1
                      : 0}{' '}
                    months
                  </>
                ) : (
                  <>
                    <span>Pay the rest</span> in{' '}
                    {sortedPaymentCalendarDates.length > 0
                      ? sortedPaymentCalendarDates.length - 1
                      : 0}{' '}
                    installments
                  </>
                )}
              </div>
              <div className="offer-header-heading-bold">
                {Convert.currency(monthlyPayment)}/<span>month</span>
              </div>
            </div>
            {paymentMethod === 'book_save_buy' ? (
              <>
                <div className="offer-header-tab offer-header-tab-arrow">
                  <img src="/img/right-arrow3.svg" alt="arrow" />
                </div>
                <div className="offer-header-tab">
                  {' '}
                  <div className="offer-header-title">
                    <span>Buy</span> and Travel
                  </div>
                  <div className="offer-header-heading-bold">
                    {offer?.months
                      ? `in ${offer?.months} months`
                      : offer?.finalDate
                      ? `in ${moment(offer.finalDate, 'YYYY-MM-DD').format('MMMM')}`
                      : ''}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="offer-detail">
            <div className="header">
              <span className="heading">
                {paymentMethod === 'book_save_buy'
                  ? 'Book, Save and Buy Schedule'
                  : 'Pay in Installments Schedule'}
              </span>
            </div>
            <div className="total-amount-content">
              Total amount : {Convert.currency(totalAmount)}*
            </div>
            <div className="offer-amount-content">
              {taxAmount && taxAmount > 0 ? (
                <div>
                  * Offer amount : {Convert.currency(totalAmount - taxAmount)}/-, Tax:{' '}
                  {Convert.currency(taxAmount)}/-
                </div>
              ) : (
                ' The price is inclusive of all the taxes'
              )}
              {/* {gstAmount || tcsAmount ? (
                <>
                  * Offer amount : {Convert.currency(offerAmount)}
                  {gstAmount
                    ? ` + GST: ${Convert.currency(
                        Math.round((Number(gstAmount) + Number.EPSILON) * 100) / 100,
                      )}`
                    : ''}
                  {tcsAmount
                    ? ` + TCS: ${Convert.currency(
                        Math.round((Number(tcsAmount) + Number.EPSILON) * 100) / 100,
                      )}`
                    : ''}
                </>
              ) : ( 
               )} */}
              The price is inclusive of all the taxes
            </div>
            <div className="timeline-wrapper">
              <div className="timeline">
                <div
                  className={`common-box first-common-box ${
                    isFromPlanPage ? 'highlighted-box plan-highlighted' : ''
                  } `}
                >
                  <div className="content">
                    <div className="left">
                      {!isFromPlanPage && (
                        <>
                          {fromKycScreen ? (
                            <img src="/img/correct.png" alt="tick" className="tick" />
                          ) : (
                            <img src="/img/ok-5.svg" alt="tick" className="tick" />
                          )}
                        </>
                      )}
                      <span className="dark-box">Book&nbsp;</span>
                      your holiday today & secure your voucher
                    </div>
                    <div className="right">{Convert.currency(upfrontPaymentAmount)}</div>
                  </div>
                </div>
                <div
                  className={`common-box ${isFromPlanPage ? '' : 'highlighted-box'} ${
                    fromKycScreen ? 'grey-bg pb-3' : ''
                  }`}
                >
                  <div className="content remaining-installments-text">
                    {paymentMethod === 'book_save_buy' ? (
                      <div>
                        <span className="dark-box"> Save&nbsp;</span>for remaining in easy
                        installments with <br />
                      </div>
                    ) : (
                      <div>
                        <span className="dark-box"> Auto-Pay&nbsp;</span>the merchant in{' '}
                        {sortedPaymentCalendarDates.length - 1} easy installments <br />
                      </div>
                    )}
                  </div>
                  {paymentMethod === 'book_save_buy' ? (
                    <a
                      className="icici-link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.icicipruamc.com/mutual-fund/debt-funds/icici-prudential-liquid-fund"
                    >
                      {' '}
                      ICICI Prudential Liquid Fund
                    </a>
                  ) : null}
                </div>
                {sortedPaymentCalendarDates &&
                  sortedPaymentCalendarDates.length > 0 &&
                  sortedPaymentCalendarDates.map((installment, index) => {
                    if (index === 0) return null;
                    return (
                      <div
                        className={cx('point-node', {
                          'point-node-last': index === sortedPaymentCalendarDates.length - 1,
                        })}
                        key={index}
                      >
                        <div className="content">
                          <div className="left">
                            {this._ordinalSuffix(index)} installment on{' '}
                            {this._getFormattedDate(installment)}
                          </div>
                          <div className="right">
                            {Convert.currency(paymentCalendar[installment] * totalPax)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {paymentMethod === 'book_save_buy' ? (
                  <div className="earn-cashback">
                    Earn returns upto <span>{Convert.currency(returnAmount)}</span> on your savings
                  </div>
                ) : null}
                {paymentMethod === 'book_save_buy' &&
                  sortedPaymentCalendarDates &&
                  sortedPaymentCalendarDates.length > 0 &&
                  sortedPaymentCalendarDates.map((installment, index) => {
                    if (index == sortedPaymentCalendarDates.length - 1) {
                      return (
                        <div className="common-box buy-the-holiday" key={index}>
                          <div className="content">
                            <div className="hidder" />
                            <div style={{ maxWidth: '460px' }}>
                              <span className="dark-box"> Buy&nbsp;</span>
                              the holiday by paying the booking amount with money saved and enjoy
                              your travels.
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="text-center">
              {!fromKycScreen && !autopay && (
                <div className="tnc-container">
                  <div className="tnc-text">
                    <input
                      type="checkbox"
                      className="tnc-checkbox"
                      onChange={this._onAcceptTerms}
                    />
                    I accept the{' '}
                    <a
                      href="https://www.savvyapp.in/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions
                    </a>{' '}
                    of SavvyPay
                  </div>
                </div>
              )}{' '}
              <div className="pay-first-btn">
                {fromKycScreen ? (
                  <ButtonOne rounded text="COMPLETE MY KYC" onClick={closeIsKycVerificationPage} />
                ) : !autopay ? (
                  <ButtonOne
                    rounded
                    text={
                      paymentMethod === 'book_save_buy'
                        ? 'Book with SavvyPay'
                        : 'Pay in Installments'
                    }
                    disabled={!this.state.isTermsAccepted}
                    onClick={() => {
                      isFromPlanPage
                        ? this._redirectToNewFlow({ paymentMethod })
                        : this._onProceedPay();
                    }}
                  />
                ) : (
                  <ButtonOne rounded text="Set up Auto-Pay" onClick={this._onSetupAutoPay} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfferTabItem);

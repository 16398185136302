import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import styles from './pan-card.module.scss';

class PanCard extends Component {
  render() {
    const { onNext } = this.props;
    console.log('this.props', this.props);
    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['another-account']}>
          <div className={styles['pay-content']}>
            <div className={cx(styles['title'], styles['title-heading'])}>
              <div>Review your information (2/7)</div>
              <div>
                <FontAwesomeIcon icon={faAngleLeft} onClick={this.props.onPrev} /> &nbsp;&nbsp;
                <FontAwesomeIcon icon={faAngleRight} onClick={this.props.onNext} />
              </div>
            </div>
            <div className={styles['line']} />
            <div className={styles['sub-title']}>PAN CARD</div>
            <div>
              <Row>
                <Col sm={12} md={8}>
                  <div className={styles['list-group']}>
                    <div className={styles['list']}>
                      Full name: <span>{this.props?.panCard?.name}</span>
                    </div>
                    <div className={styles['list']}>
                      Date of birth: <span>{this.props?.panCard?.date_of_birth || 'N/A'}</span>
                    </div>
                    <div className={styles['list']}>
                      Father’s name: <span>{this.props?.panCard?.fathers_name || 'N/A'}</span>
                    </div>
                    <div className={styles['list']}>
                      PAN card number: <span>{this.props?.panCard?.pan_number}</span>
                    </div>
                    {/* <div className={styles['list']}>
                      Phone number: <span>+91 9704172040</span>
                    </div> */}
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  {this.props?.panCard?.image_url ? (
                    <div className={styles['pan-img']}>
                      <img src={this.props?.panCard?.image_url} alt="pan-img" />
                    </div>
                  ) : (
                    <div className={styles['placeholder-outer']}>
                      <div className={styles['placeholder']}>
                        <div className={styles['placeholder-inner']}>Pancard Image</div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles['spacer']} />

          <div className={styles['footer']} onClick={onNext}>
            NEXT
          </div>
        </div>
      </div>
    );
  }
}

export default PanCard;

import React, { Component } from 'react';
import { Loader, SecuredPartners } from 'src/components';
import { ApiRequest } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import cx from 'classnames';
import { faCamera, faRedo, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './_selfieverification.module.scss';

class SelfieVerification extends Component {
  constructor(props) {
    super(props);
    
    this.state = {    
      loading:false,
      imageUri: null,
      imageCaptured: false
    };
    this._stream = null;
    this._video = null;
    this._canvas = null;
  }  

  componentDidMount() {    
    this.uploadPhotoRequestSuccessSubscription = EventBus.on(ACTION_TYPES.UPLOAD_SELFIE_SUCCESS, this._onUploadedPhoto);
    this.uploadPhotoRequestFailureSubscription = EventBus.on(ACTION_TYPES.UPLOAD_SELFIE_FAILED, () => this.setState({ loading: false }));

    this._startCamera();
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.uploadPhotoRequestSuccessSubscription();
    this.uploadPhotoRequestFailureSubscription();

    this._stopCamera();
  }

  _startCamera = () => {
    navigator.mediaDevices
    .getUserMedia({ video: {
      facingMode: 'user',
      width: 720, 
      height: 480 
    } })
    .then((stream) => {
      this._stream = stream;
      this._video.srcObject = stream;
    })
  }

  _stopCamera = () => {    
    this._stream.getTracks().forEach((track) => {
      if (track.readyState == 'live') {
        track.stop();
      }
    });
  }

  _onCaptureImage = () => {
    this._canvas.getContext("2d").drawImage(this._video, 0, 0, 720, 480, 0, 0, 720, 480);
    const imageUri = this._canvas.toDataURL('image/jpeg');
    this.setState({ imageUri, imageCaptured: true }, () => {
      this._stopCamera();
    });
  }

  _onReTakeImage = () => {
    this.setState({ imageCaptured: false, imageUri: null }, () => {
      this._startCamera();
    })
  }

  _onUsePhoto = () => {
    this.setState({ loading: true });
    this._canvas.toBlob((blob) => {
      ApiRequest.uploadToSignzy(blob, 'selfie.jpeg')
        .then(res => {       
          this.props.updateKycParams({ selfie: { image_url: res.data.file.directUrl }})     
          this.props.uploadSelfieRequest({ image_url: res.data.file.directUrl });          
        })
    }, 'image/jpeg');
  }

  _onUploadedPhoto = () => {
    this.setState({ loading: false }, () => {
      this.props.onComplete && this.props.onComplete()
    })
  }

  render() {
    return (
      <div className={styles['pan-verification']}>        
        <div className={styles.selfieContentContainer}>          
          <div className={styles.camera}>            
            <p>Take a nice photo of yourself</p>
            {
              this.state.imageCaptured
              ?
              <div className={styles.image} style={{ backgroundImage: `url(${this.state.imageUri})` }} />
              :
              /* eslint-disable-next-line */
              <video ref={ref => this._video = ref} width="300" autoPlay className={styles.video} />
            }
            <canvas ref={ref => this._canvas = ref} style={{ display: 'none' }} width="720" height="480" />
          </div>          
          <div className={styles.buttonContainer}>
            {
              this.state.imageCaptured
              ?
              <>
                <button 
                  className={cx(styles.button, styles.danger)}
                  onClick={this._onReTakeImage}
                >
                  <FontAwesomeIcon icon={faRedo} className={styles.icon} />
                  Retake
                </button>
                <button 
                  className={styles.button}
                  onClick={this._onUsePhoto}
                >
                  <FontAwesomeIcon icon={faThumbsUp} className={styles.icon} />
                  Use Photo
                </button>
              </>
            :
              <button 
                className={styles.button}
                onClick={this._onCaptureImage}
              >
                <FontAwesomeIcon icon={faCamera} className={styles.icon} />
                Capture
              </button>
            }            
          </div>
          <div className="text-center">
            <SecuredPartners styles={{
                marginTop: '1rem'
              }}/>
          </div>
          <Loader loading={this.state.loading} />   
        </div>             
      </div>
    );
  }
}

export default SelfieVerification;

import React from 'react'
import cx from 'classnames'
import styles from './_customcardfour.module.scss'

function CustomCardFour(props) {
  return (
    <div className={cx(styles.container, props.containerstyles)}>
      <div className={cx(styles.leftContainer, props.leftContainerStyles)}>
        <div className={cx(styles.titleContainer, props.titleContainerStyles)}>
          <span>{`${props?.title}`}</span>
        </div>
      </div>
      <div className={cx(styles.rightContainer, props.rightContainerStyles)}>
        <span>
          {props?.text || ''}
        </span>
      </div>
    </div>
  )
}

export default CustomCardFour

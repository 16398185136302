import { ACTION_TYPES } from 'src/constants';

export function updateProduct(payload) {  
  return {
    type: ACTION_TYPES.UPDATE_PRODUCT,
    payload,
  };
}

export function requestProductDetail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_DETAIL,
    payload,
  };
}

export function productDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_DETAIL_SUCCEEDED,
    payload,
  };
}

export function productDetailFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_DETAIL_FAILED,
    payload,
  };
}

export function requestProductOffers(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_OFFERS,
    payload,
  };
}

export function productOffersSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED,
    payload,
  };
}

export function productOffersFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_OFFERS_FAILED,
    payload,
  };
}

export function requestProductOffersDelete(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_OFFERS_DELETE,
    payload,
  };
}
export function productOffersDeleteSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED_DELETE,
    payload,
  };
}
export function productOffersDeleteFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_PRODUCT_OFFERS_FAILED_DELETE,
    payload,
  };
}

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Form as FormikForm, Formik } from 'formik';
import React, { Component } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
// import EventBus from 'eventing-bus';
// import { ACTION_TYPES } from 'src/constants';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import {
  // TextInputThree,
  // InputSelectTwo,
  InputRadio,
} from 'src/components/form-inputs';
import styles from './demographic.module.scss';

class Demographic extends Component {
  render() {
    return (
      <div className={styles['demographic']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['pay-content']}>
          <div className={cx(styles['title'], styles['title-heading'])}>
            <div>Review your information (1/7)</div>
            <div>
              <FontAwesomeIcon icon={faAngleLeft} onClick={this.props.onPrev} /> &nbsp;&nbsp;
              <FontAwesomeIcon icon={faAngleRight} onClick={this.props.onNext} />
            </div>
          </div>
          <div className={styles['line']} />
          <div className={styles['sub-title']}>Demographic Information</div>
          <div className={styles['sub-title-light']}>
            Lets wrap up the formalities and start saving for your holiday!
          </div>
          <div className={cx(styles['mobile-space'], styles['mobile'])}>
            <div className={cx(styles['line-mob'])} />
          </div>

          <Formik>
            <FormikForm>
              <div>
                <Row>
                  <Col xs={12} lg={6}>
                    <div className={cx(styles['form-box'], styles['mobile-space'])}>
                      <div>
                        <label>Full Name</label>
                      </div>
                      <div>
                        <InputMask
                          value={`${this.props?.firstName} ${this.props?.lastName}`}
                          className={styles['input-box']}
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className={cx(styles['form-box'], styles['mobile-space'])}>
                      <div>
                        <label>Enter your Email ID</label>
                      </div>
                      <div>
                        <InputMask
                          value={this.props?.email}
                          className={styles['input-box']}
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div
                      className={cx(
                        styles['form-box'],
                        styles['gender-form-box'],
                        styles['mobile-space'],
                      )}
                    >
                      <div>
                        <label>Gender</label>
                      </div>
                      <div>
                        <Dropdown className={styles['custom-dropdown']}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {this.props?.userInfo?.gender === 'M'
                              ? 'Male'
                              : this.props?.gender === 'F'
                              ? 'Female'
                              : 'Others'}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div
                      className={cx(
                        styles['form-box'],
                        styles['married-form-box'],
                        styles['mobile-space'],
                      )}
                    >
                      <div>
                        <InputRadio
                          mandatory
                          label="Are you married?"
                          name="marital_status"
                          id="marital_status"
                          initialValue={this.props?.userInfo?.maritalStatus}
                          disabled
                          radioButtonsContainer={styles['radio-btns']}
                          radioOptions={[
                            { value: 'MARRIED', label: 'Yes' },
                            { value: 'UNMARRIED', label: 'No' },
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div
                      className={cx(
                        styles['form-box'],
                        styles['form-box-space'],
                        styles['mobile-space'],
                      )}
                    >
                      <div>
                        <label>Occupation</label>
                      </div>
                      <div>
                        <Dropdown className={styles['custom-dropdown']}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {this.props?.userInfo?.occupationDescription}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div
                      className={cx(
                        styles['form-box'],
                        styles['form-box-space'],
                        styles['mobile-space'],
                      )}
                    >
                      <div>
                        <label>Salary Range</label>
                      </div>
                      <div>
                        <Dropdown className={styles['custom-dropdown']}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {this.props?.userInfo?.salaryDescription}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <div className={cx(styles['checkbox'], styles['mobile-space'])}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      className={styles['demo-info-checkbox']}
                      type="checkbox"
                      label="I am an Indian citizen and not a PEP (Politically  Exposed Person) or related to a PEP"
                    />
                  </Form.Group>{' '}
                </div> */}
              </div>
            </FormikForm>
          </Formik>
        </div>

        <button className={styles['footer-button']} onClick={() => this.props.onNext()}>
          NEXT
        </button>
      </div>
    );
  }
}

export default Demographic;

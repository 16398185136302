import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import StatsContainer from 'src/components/stats-container';
import { ACTION_TYPES } from 'src/constants';
import { UsersTable } from 'src/components/tables';
import { Loader } from 'src/components';

class UserCatalogue extends Component {
  state = {
    loading: false,
    pageNumber: 1,
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminUsersList({
          page: pageNumber,
        }),
    );
  };

  componentDidMount() {
    this.requestAdminUsersList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_USERS_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminUsersListSuccess = EventBus.on(ACTION_TYPES.ADMIN_USERS_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminUsersListFailure = EventBus.on(ACTION_TYPES.ADMIN_USERS_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminUsersList({
      page: this.state.pageNumber,
    });
  }

  componentWillUnmount() {
    this.requestAdminUsersList();
    this.adminUsersListSuccess();
    this.adminUsersListFailure();
  }

  render() {
    const { adminUsers } = this.props;
    return (
      <div>
        <StatsContainer
          title="User Summary"
          data={[
            {
              title: 'Total Users',
              field: 'totalUsers',
              value: adminUsers?.users && adminUsers?.users.length,
            },
            // {
            //     title: 'Active Users',
            //     field: 'activeUsers',
            //     value: '37,200',
            // },
            // {
            //     title: 'Inactive Users',
            //     field: 'inactiveUsers',
            //     value: '13,800',
            // },
            // {
            //     title: 'New Users Today',
            //     field: 'newUsers',
            //     value: '1401',
            // }
          ]}
        />
        {adminUsers?.users && adminUsers?.users.length > 0 && (
          <UsersTable
            data={{ users: adminUsers?.users, usersTotal: adminUsers?.usersCount }}
            handlePaginationChange={this._handlePaginationChange}
          />
        )}
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default UserCatalogue;

import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { OfferDetailsEdit } from 'src/components/admin';
import styles from './_newproductoffer.module.scss';

class NewProductOffer extends Component {
  render() {
    return (
      <Container className={styles.container}>
        <OfferDetailsEdit isNew />
      </Container>
    );
  }
}

export default NewProductOffer;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import moment from 'moment';
import queryString from 'query-string';
import { ACTION_TYPES } from 'src/constants';
import { Screen, Wizard, Loader, SavvySupport, SavvyPayWorksModal } from 'src/components';
import ProductImage from './product-image';
import ProductBanner from './product-banner';
import ProductDetails from './product-details';
import OfferDetailItem from './offer-detail-item';
import styles from './_plan-goal.module.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';
const PAYU_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://secure.payu.in/_payment'
    : 'https://test.payu.in/_payment';
const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;

class PlanGoal extends Component {
  paymentForm = null;

  state = {
    loading: false,
    isShowSavvyPayModal: false,
    payment: {},
  };

  componentDidMount() {
    let { partnerAccessKey, userIdentityToken, productUuid, productOfferUuid } = queryString.parse(
      this.props.location.search,
    );

    this.requestProductSubscription = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.productSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_PRODUCT_DETAIL_SUCCEEDED,
      () => this.setState({ loading: false }),
    );
    this.productSubscriptionFailure = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestOfferSubscription = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_OFFERS, () =>
      this.setState({ loading: true }),
    );
    this.offerSubscriptionSuccess = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED, () =>
      this.setState({ loading: false }),
    );
    this.offerSubscriptionFailure = EventBus.on(ACTION_TYPES.REQUEST_PRODUCT_OFFERS_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestPaymentSubscription = EventBus.on(ACTION_TYPES.REQUEST_GENERATE_PAYMENT, () =>
      this.setState({ loading: true }),
    );
    this.paymentSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.GENERATE_PAYMENT_SUCCESS,
      this._onPaymentGenerated,
    );
    this.paymentSubscriptionFailure = EventBus.on(ACTION_TYPES.GENERATE_PAYMENT_FAILED, () =>
      this.setState({ loading: false }),
    );

    const credentialParams = {};
    const productParams = {};

    if (partnerAccessKey) credentialParams.partnerAccessKey = partnerAccessKey;
    if (userIdentityToken) credentialParams.userIdentityToken = userIdentityToken;

    if (productUuid) productParams.productUuid = productUuid;
    if (productOfferUuid) productParams.productOfferUuid = productOfferUuid;

    if (Object.keys(credentialParams).length > 0) this.props.updateCredentials(credentialParams);
    if (Object.keys(productParams).length > 0) this.props.updateProduct(productParams);

    productUuid = productUuid || this.props.product.productUuid;
    productOfferUuid = productOfferUuid || this.props.product.productOfferUuid;

    if (!this.props.product.uuid && !productUuid && !productOfferUuid) {
      return this.props.history.push('/dashboard');
    }

    if (productUuid) {
      this.props.requestProductDetail({ uuid: productUuid });
    }
    if (productOfferUuid) {
      this.props.requestProductOffers({ uuid: productOfferUuid });
    }
  }

  componentWillUnmount() {
    this.requestProductSubscription();
    this.productSubscriptionSuccess();
    this.productSubscriptionFailure();

    this.requestOfferSubscription();
    this.offerSubscriptionSuccess();
    this.offerSubscriptionFailure();

    this.requestPaymentSubscription();
    this.paymentSubscriptionSuccess();
    this.paymentSubscriptionFailure();
  }

  _onPaymentGenerated = ({ payment, orderId }) => {
    const { user, partner, savingPlan } = this.props;
    const amount = Number(payment.amount) * 100; // send it as cents ex: 100Rs as 10000 cents

    const options = {
      key: RAZORPAY_KEY,
      amount,
      currency: 'INR',
      name: partner.name,
      description: savingPlan.productOfferName,
      image: partner.logoImageLink,
      order_id: orderId,
      callback_url: `${API_ENDPOINT_URL}/api/partners/razorpay/payments/redirect`,
      prefill: {
        name: user.firstName,
        email: user.email,
        contact: user.phoneNumber,
      },
      theme: {
        color: '#ffd401',
      },
    };

    this.setState({ loading: false }, () => {
      this.props.updateSavingPlan({
        ...savingPlan,
        paymentId: payment.id,
        paymentRequestId: payment.requestId,
      });

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });

    // <!-- PayU code, enable it if required -->
    // const payment = {
    //   ...response.payment,
    //   hash: response.hash,
    // };

    // this.setState({ payment }, () => {
    //   const { savingPlan } = this.props;
    //   this.props.updateSavingPlan({
    //     ...savingPlan,
    //     paymentId: response.payment.id,
    //     paymentRequestId: response.payment.requestId,
    //   });

    //   setTimeout(() => {
    //     this.paymentForm.submit();
    //   }, 100);
    // });
  };

  _onProceedPayment = (savingPlan) => {
    const { totalPax } = this.props;

    this.props.updateSavingPlan({
      ...savingPlan,
      totalPax,
    });

    const { paymentCalendar } = savingPlan;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const firstPaymentKey = sortedPaymentCalendarDates[0];
    const firstPaymentAmount = paymentCalendar[firstPaymentKey] * totalPax;
    const { origin } = document.location;

    this.props.requestGeneratePayment({
      payment: {
        amount: firstPaymentAmount,
        info: savingPlan.productOfferName,
        redirect_url: `${origin}/payment`,
        // user_email: user.email,
        // user_first_name: user.firstName,
        // success_url: `${origin}/payment?status=success`,
        // failure_url: `${origin}/payment?status=failure`,
      },
    });
  };

  _onOpenSavvySupport = () => {};

  openSavvyPayWorksModal = () => {
    this.setState({ isShowSavvyPayModal: true });
  };

  closeSavvyPayWorksModal = () => {
    this.setState({ isShowSavvyPayModal: false });
  };

  render() {
    const { product, partner, user } = this.props;
    const { payment, isShowSavvyPayModal } = this.state;

    return (
      <Screen>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <Wizard progress={25} />
          </Col>
        </Row>
        <div className={styles['plan-goal']}>
          {product.imageUrls && product.imageUrls.length > 0 ? (
            <>
              <ProductBanner {...partner} images={product.imageUrls} />
              <ProductDetails {...product} />
            </>
          ) : null}
          {product.imageLink && (!product.imageUrls || product.imageUrls.length === 0) ? (
            <>
              <ProductImage {...partner} image={product.imageLink} />
              <ProductDetails {...product} />
            </>
          ) : null}
          <OfferDetailItem
            product={product}
            user={user}
            onProceedFirstInstallment={this._onProceedPayment}
            isFromPlanPage
          />
        </div>
        <form action={PAYU_ENDPOINT_URL} method="post" ref={(ref) => (this.paymentForm = ref)}>
          <input type="hidden" name="key" value={payment.merchantKey} />
          <input type="hidden" name="txnid" value={payment.requestId} />
          <input type="hidden" name="productinfo" value={payment.info} />
          <input type="hidden" name="amount" value={payment.amount} />
          <input type="hidden" name="email" value={payment.userEmail} />
          <input type="hidden" name="firstname" value={payment.userFirstName} />
          <input type="hidden" name="phone" value={payment.phoneNumber} />
          <input type="hidden" name="hash" value={payment.hash} />
          <input
            type="hidden"
            name="surl"
            value={`${API_ENDPOINT_URL}/api/partners/payu/payments/success`}
          />
          <input
            type="hidden"
            name="furl"
            value={`${API_ENDPOINT_URL}/api/partners/payu/payments/failure`}
          />
        </form>
        <SavvySupport />
        <SavvyPayWorksModal
          closeModal={this.closeSavvyPayWorksModal}
          isShow={isShowSavvyPayModal}
        />
        <Loader loading={this.state.loading} fixed />
      </Screen>
    );
  }
}

export default withRouter(PlanGoal);

import { connect } from 'react-redux';
import { requestTransactionList } from 'src/redux/actions';
import Transactions from './transactions';

function mapStateToProps(state) {
  return {
    transactions: state.transactions,
    savingGoals: state.savingGoals,
    savingGoal: state.savingGoal,
  };
}

export default connect(mapStateToProps, { requestTransactionList })(Transactions);

import { ACTION_TYPES } from 'src/constants';

/**
 *
 * Product offer Create actions
 *
 */
export function requestAdminOfferCreate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_CREATE,
    payload,
  };
}


export function requestAdminOfferCreateV2(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_CREATE_V2,
    payload,
  };
}

export function adminOfferCreateSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_CREATE_SUCCESS,
    payload,
  };
}

export function adminOfferCreateFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_CREATE_FAILED,
    payload,
  };
}

/**
 * 
 * Delete actions
 */
export function requestAdminOfferDelete(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_DELETE,
    payload,
  };
}

export function adminOfferDeleteSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_DELETE_SUCCESS,
    payload,
  };
}

export function adminOfferDeleteFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_DELETE_FAILED,
    payload,
  };
}

/**
 * 
 * Copy actions
 */
export function requestAdminOfferCopy(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_COPY,
    payload,
  };
}

export function adminOfferCopySuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_COPY_SUCCESS,
    payload,
  };
}

export function adminOfferCopyFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_COPY_FAILED,
    payload,
  };
}

/**
 *
 * Product offer Update actions
 *
 */
export function requestAdminOfferUpdate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_UPDATE,
    payload,
  };
}

export function adminOfferUpdateSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_UPDATE_SUCCESS,
    payload,
  };
}

export function adminOfferUpdateFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_UPDATE_FAILED,
    payload,
  };
}

/**
 *
 * Get Admin Product offer details by id
 *
 */

export function requestAdminOfferDetail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFER_DETAIL,
    payload,
  };
}

export function adminOfferDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_DETAIL_SUCCESS,
    payload,
  };
}

export function adminOfferDetailFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFER_DETAIL_FAILED,
    payload,
  };
}

/**
 *
 * Get Product offers list
 *
 */

export function requestAdminOffersList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST,
    payload,
  };
}

export function adminOffersListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFERS_LIST_SUCCESS,
    payload,
  };
}

export function adminOffersListFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFERS_LIST_FAILED,
    payload,
  };
}

export function requestAdminOffersListByProductId(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST_BY_PRODUCT_ID,
    payload,
  };
}

export function adminOffersListByProductIdSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFERS_LIST_BY_PRODUCT_ID_SUCCESS,
    payload,
  };
}

export function adminOffersListByProductIdFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_OFFERS_LIST_BY_PRODUCT_ID_FAILED,
    payload,
  };
}

export function createAdminOffer(payload) {
  return {
    type: ACTION_TYPES.CREATE_ADMIN_OFFER,
    payload,
  };
}

export function createAdminOfferSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_ADMIN_OFFER_SUCCESS,
    payload,
  };
}

export function createAdminOfferFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_ADMIN_OFFER_FAILED,
    payload,
  };
}

export function requestShortenUrl(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SHORTEN_URL,
    payload,
  };
}

export function urlShortenSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SHORTEN_URL_SUCCESS,
    payload,
  };
}

export function urlShortenFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SHORTEN_URL_FAILED,
    payload,
  };
}

export function adminPaymentScheduleRequest(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PAYMENT_SCHEDULE_REQUEST,
    payload
  }
}

export function adminPaymentScheduleSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PAYMENT_SCHEDULE_SUCCESS,
    payload
  }
}

export function adminPaymentScheduleFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PAYMENT_SCHEDULE_FAILED,
    payload
  }
}

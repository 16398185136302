import { connect } from 'react-redux'
import { requestAdminTransactionDepositList } from 'src/redux/actions'
import Deposits from './Deposits'

function mapStateToProps(state){
    return{
        transactions: state.adminTransactions
    }
}

export default connect(mapStateToProps, {
    requestAdminTransactionDepositList
})(Deposits)

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function generatePayment(data) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.GENERATE_PAYMENT,
    data,
  }).then((response) => response.data);
}

export function createDeposit(data) {
  return ApiRequest.fetch({
      method: 'post',
      url: API_END_POINTS.DEPOSITS,
      data
  })
  .then(response => response.data);
}

export function fetchDeposit(id) {
  return ApiRequest.fetch({
      method: 'get',
      url: `${API_END_POINTS.DEPOSITS}/${id}`,
  })
  .then(response => response.data);
}
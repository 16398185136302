import React, { Component } from 'react';
import styles from './penny-check.module.scss';

class PennyCheck extends Component {
  render() {
    return (
      <div className={styles['penny-check']}>
        <div className={styles['penny-check-layer']} />
        <div className={styles['penny-check-box']}>
          <div className={styles['title']}>PENNY CHECK</div>
          <div className={styles['sub-title-light']}>
            We are depositing a small amount into your account to verify it
          </div>
          <div className={styles['loader']}>
            <img src="/img/loader-2.svg" alt="loader" />
          </div>
          <div className={styles['mobile-loader']}>
            <img src="/img/mobile-loader.svg" alt="loader" />
          </div>
          <div className={styles['sub-title-light']}>This may take upto 10 seconds</div>
        </div>
      </div>
    );
  }
}

export default PennyCheck;

import React from 'react';
import cx from 'classnames';
import styles from './_textinput.module.scss';

export default function TextInput(props) {
  let input = null;

  const onScroll = () => {
    input.blur(); // to disabled increase/decrease inside [type=number]
  };

  return (
    <input
      ref={(ref) => (input = ref)}
      onWheel={onScroll}
      className={cx(styles.input, 
      {
        [styles.inline]: props?.inline
      }  
      ,props.className)}
      {...props}
    />
  );
}

import React, { Component } from 'react';
import {
  Header,
  // CustomCardFour,
  Loader,
  LoginBanner,
} from 'src/components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { ACTION_TYPES, IMAGES } from 'src/constants';
import EventBus from 'eventing-bus';
import queryString from 'query-string';
import { TextInputThree } from 'src/components/form-inputs';
import { ButtonMain } from 'src/components/theme';
import styles from './_otplogin.module.scss';

export class OtpLogin extends Component {
  state = {
    loading: false,
  };

  _validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required('Please enter phone number.')
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits')
      .matches(/^[6-9]\d{9}$/, {
        message: 'Phone number not valid',
      }),
  });

  componentDidMount() {
    this.requestSignInSubscription = EventBus.on(ACTION_TYPES.REQUEST_SIGN_IN_USER, () =>
      this.setState({ loading: true }),
    );
    this.signInSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.SIGN_IN_USER_SUCCESS,
      this._onLoginSuccess,
    );
    this.signInSubscriptionFailure = EventBus.on(ACTION_TYPES.SIGN_IN_USER_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestSignInSubscription();
    this.signInSubscriptionSuccess();
    this.signInSubscriptionFailure();
  }

  _onLoginSuccess = () => {
    this.setState({ loading: false }, () => {
      const { redirectTo, redirectToPath } = queryString.parse(this.props.location.search);
      this.props.history.push({
        pathname: '/verify-sign-in',
        state: {
          redirectTo: redirectToPath ? this.props.location.search : redirectTo,
          showPromo: this.props.location.state ? this.props.location.state.showPromo : false,
        },
      });
    });
  };

  _onLogin = (values) => {
    const { user } = this.props;
    const { phoneNumber } = values;
    const parsedPhoneNumber = phoneNumber.toString().replace('-', '');

    this.props.requestSignInUser({
      phone_number: parsedPhoneNumber,
      email: user.email || null,
      first_name: user.firstName || null,
      last_name: user.lastName || null,
    });
  };

  render() {
    const { productUuid, productOfferUuid } = this.props.product;
    const showPromo = this.props.location.state ? this.props.location.state.showPromo : false;

    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.customHeader}>
            <Header
              heading={
                showPromo ? (
                  <div className={styles.headingStyles}>
                    Pay with <span className={styles.highlightedText}>SavvyPay</span>
                  </div>
                ) : null
              }
              headerStyles={styles.headerStyles}
            />
          </div>
          <LoginBanner
            productOfferUuid={productOfferUuid}
            productUuid={productUuid}
            showPromo={showPromo}
          />
        </div>
        <div className={styles.loginContainer}>
          <img src={IMAGES.SAVVY_ICON} alt="logo" className={styles.logo} />
          <div className={styles.loginBox}>
            <div className={styles.loginTitle}>{showPromo ? 'Sign in to book' : 'Login'}</div>
            <Formik
              initialValues={{
                phoneNumber: this.props.user.phoneNumber
                  ? this.props.user.phoneNumber.substr(3)
                  : '',
              }}
              validationSchema={this._validationSchema}
              onSubmit={(values) => {
                this._onLogin(values);
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <TextInputThree
                    labelcontainerstyles={styles.labelcontainerstyles}
                    containerstyles={styles.containerstyles}
                    fieldcontainerstyles={styles.fieldcontainerstyles}
                    labelstyles={styles.label}
                    inputstyles={styles.input}
                    label="Please enter your mobile number"
                    name="phoneNumber"
                    id="phoneNumber"
                    type="number"
                    onChange={(e) => {
                      if ((e.target.value && e.target.value.length <= 10) || !e.target.value) {
                        setFieldValue('phoneNumber', e.target.value);
                      }
                    }}
                    value={values?.phoneNumber}
                  />
                  <ButtonMain type="submit" className={styles.submitBtn}>
                    START BOOKING
                  </ButtonMain>
                </Form>
              )}
            </Formik>
          </div>
          <Loader loading={this.state.loading} />
        </div>
      </div>
    );
  }
}

export default OtpLogin;

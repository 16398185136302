import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { ButtonOne } from '../../../../../../components';

import styles from './_email-template-modal.module.scss';

class EmailTemplateModal extends Component {
  redirectToTemplates = () => {
    const { history } = this.props;
    history.push('/admin/marketing/email-templates');
  };

  redirectToProducts = () => {
    const { history } = this.props;
    history.push('/admin/marketing/products');
  };

  render() {
    const { copyCodeToClipboard, code, decodeHtml, codeInput, codeCopied, closeModal } = this.props;
    return (
      <div className={styles['product-create-modal']}>
        <div className={styles['product-box']}>
          <div>
            <div onClick={closeModal} className={styles['close-btn']}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </div>
          </div>
          <div className={styles['product-img']}>
            <img src="/img/ok-3.png" alt="ok" />
          </div>
          <div className={styles['title']}>Generated Email</div>

          {codeCopied && <div className="code-copied">Copied to clipboard</div>}
          <Tabs defaultActiveKey="html">
            <Tab eventKey="html" title="HTML">
              <div
                className={cx(styles['confirmation'], styles['html'])}
                dangerouslySetInnerHTML={{ __html: decodeHtml(code) }}
              />
            </Tab>
            <Tab eventKey="code" title="CODE">
              <textarea
                className={cx(styles['confirmation'], styles['html-code'])}
                value={decodeHtml(code)}
                ref={(ref) => codeInput(ref)}
              />
              <div className={styles['custom-btns-group']}>
                <ButtonOne text="Copy to Clipboard" onClick={copyCodeToClipboard} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailTemplateModal);

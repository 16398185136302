import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonOne } from 'src/components';
import { Convert } from 'src/helpers';
import './_setup-pay-modal.scss';

class SetUpPayModal extends Component {
  render() {
    const { isVisibleModal, monthlyAmount, sipUntil, onSetUpMandate } = this.props;

    return (
      <div className="main-setup-pay-modal">
        <Modal show={isVisibleModal} size="lg" className="setup-pay-modal">
          <div className="title">
            You will now be taken to your bank website to complete the final steps to pay in
            installments
          </div>
          <div className="sub-title">The monthly debit will : {Convert.currency(monthlyAmount)}</div>
          <div className="sub-title">Money will only be debited till : {sipUntil}</div>
          <div className="setup-btn">
            <ButtonOne text="SETUP PAY IN INSTALLMENTS" type="submit" onClick={onSetUpMandate} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default SetUpPayModal;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { Accordion, Button as BsButton } from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import { Convert } from 'src/helpers';
import moment from 'moment';
// import FirstInstallment from '../../offer-detail/first-installment';
// import RemainingInstallment from '../../offer-detail/remaining-installments';
// import { ButtonMain, Heading, Text } from 'src/components/theme';
import './_offer-accordion.scss';
import { Button, SignInSignUpModal, SavvyPayWorksModal } from 'src/components';

class OfferAccordion extends Component {
  state = {
    activeEventKey: 0,
    offers: [],
    accepetedOffer: null,
    selectedOffer: {},
    isShowSavvyPayModal: false,
    showLoginModal: false,
  };

  history = createBrowserHistory();

  /**
   * PaymentCalendar data is arranged so as to get booking data which is first entry(earliest date) and
   * rest of them are installments
   * @param {object} paymentCalendar
   * @return {object}
   */
  _getPaymentPlanData = (paymentCalendar = {}) => {
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const bookingData = {
      date: sortedPaymentCalendarDates[0],
      amount: paymentCalendar[sortedPaymentCalendarDates[0]],
    };

    const monthlySaved = paymentCalendar[sortedPaymentCalendarDates[1]];

    const installmentsData = { ...paymentCalendar };
    delete installmentsData[sortedPaymentCalendarDates[0]];

    let installmentsArr = [];
    let installmentsTotalAmount = 0;

    if (Object.keys(installmentsData).length > 0) {
      installmentsArr = Object.keys(installmentsData).map((item) => ({
        date: item,
        amount: installmentsData[item],
      }));
      // installmentsArr.sort((a,b) => moment(b.date).valueOf() - moment(a.date).valueOf())
      installmentsTotalAmount = Object.values(installmentsData).reduce((acc, v) => acc + v);
    }

    return {
      bookingData,
      monthlySaved,
      installmentsArr,
      installmentsTotalAmount,
    };
  };

  /**
   * converts input date to a specific format
   * @param {int} date
   * @return {string}
   */
  _getFormattedDate = (date) => moment(date, 'DDMMYYYY').format('MMMM');

  /**
   * append suffix to number example: 'st' to 1, 'nd' to 2 etc.
   * @param {*} i
   */
  _ordinalSuffix(i) {
    const j = i % 10;
    const k = i % 100;

    if (j == 1 && k != 11) {
      return `${i}st`;
    }
    if (j == 2 && k != 12) {
      return `${i}nd`;
    }
    if (j == 3 && k != 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  componentDidMount() {
    const { amount, productOffers, totalPax } = this.props;
    const sortedOffers = productOffers.sort((a, b) => a.months - b.months);
    const offers = sortedOffers.map((offer) => {
      const { months, discountPercentage, paymentCalendar } = offer;
      const paymentPlanData = this._getPaymentPlanData(paymentCalendar);
      const discountAmount = (Number(amount * totalPax) / 100) * Number(discountPercentage);
      const discountedPrice = Number(amount * totalPax) - discountAmount;
      const frequencyAmount = parseInt(discountedPrice / months);

      return {
        ...offer,
        paymentPlanData,
        frequencyAmount,
        discountedPrice,
        discountAmount,
      };
    });

    this.setState({ offers, activeEventKey: offers.length === 1 ? 1 : 0 });
  }

  _onChange = (eventKey) => {
    const activeEventKey = this.state.activeEventKey !== eventKey ? eventKey : null;
    this.setState({ activeEventKey });
  };

  _onPayFirstInstallment = (offer) => {
    if (!this.props.user.uuid) {
      this.setState({ selectedOffer: offer, showLoginModal: true });
    } else {
      this.props.onProceedFirstInstallment(offer);
    }
  };

  _onAcceptTerms = (event, index) => {
    this.setState({ accepetedOffer: event.currentTarget.checked ? index : null });
  };

  _onCloseSignInModal = () => {
    this.setState({ showLoginModal: false });
  };

  _onSignInComplete = () => {
    this.setState({ showLoginModal: false }, () => {
      this.props.onProceedFirstInstallment(this.state.selectedOffer);
    });
  };

  openSavvyPayWorksModal = () => {
    this.setState({ isShowSavvyPayModal: true });
  };

  closeSavvyPayWorksModal = () => {
    this.setState({ isShowSavvyPayModal: false });
  };

  render() {
    const { amount, totalPax, fromKycScreen, closeIsKycVerificationPage, taxes } = this.props || {};
    const { isShowSavvyPayModal } = this.state;
    const getGst =
      taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'gst');
    const getTcs =
      taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'tcs');

    return (
      <div className="offer-accordion">
        <Accordion activeKey={this.state.activeEventKey}>
          {this.state.offers &&
            this.state.offers.length > 0 &&
            this.state.offers.map((offer, index) => {
              const discountPercentage = Number(offer?.discountPercentage || 0);
              const totalAmount = Number(amount * totalPax);
              const discount = (totalAmount / 100) * discountPercentage;
              const offerAmount = totalAmount - discount;
              const finalPrice =
                (offerAmount || 0) - (offer.paymentPlanData.bookingData.amount || 0);
              const returnAmount = (finalPrice / 100) * offer?.savingReturn;
              let gstAmount = 0;
              if (getGst?.percentage && getGst?.percentage > 0) {
                gstAmount = (Number(getGst?.percentage) / 100) * offerAmount;
              }

              let tcsAmount = 0;
              if (getTcs?.percentage && getTcs?.percentage > 0) {
                tcsAmount = (Number(getTcs?.percentage) / 100) * offerAmount;
              }

              return (
                <div
                  className={cx('offer-accordion-item', {
                    'offer-accordion-item-active': this.state.activeEventKey === index + 1,
                  })}
                  key={index}
                >
                  <Accordion.Toggle
                    as={BsButton}
                    variant="link"
                    eventKey={index + 1}
                    className={cx('offer-header', {
                      'offer-header-active': this.state.activeEventKey === index + 1,
                    })}
                    onClick={() => this._onChange(index + 1)}
                  >
                    <div className="offer-upper-header">
                      <div className="offer-header-tab">
                        <div className="offer-header-title">
                          <span>Book</span> today for
                        </div>
                        <div className="offer-header-heading">
                          {Convert.currency(
                            offer.paymentPlanData.bookingData.amount * this.props.totalPax || 0,
                          )}
                        </div>
                      </div>
                      <div className="offer-header-tab offer-header-tab-arrow">
                        <img src="/img/right-arrow3.svg" alt="arrow" />
                      </div>
                      <div className="offer-header-tab">
                        {' '}
                        <div className="offer-header-title">
                          <span>Save</span> for{' '}
                          {offer?.paymentPlanData?.installmentsArr?.length || offer?.months} months
                        </div>
                        <div className="offer-header-heading-bold">
                          {offer.paymentPlanData?.monthlySaved &&
                            `${Convert.currency(
                              offer.paymentPlanData.monthlySaved * this.props.totalPax,
                            )}`}
                          /<span>month</span>
                        </div>
                      </div>
                      <div className="offer-header-tab offer-header-tab-arrow">
                        <img src="/img/right-arrow3.svg" alt="arrow" />
                      </div>
                      <div className="offer-header-tab">
                        {' '}
                        <div className="offer-header-title">
                          <span>Buy</span> and Travel
                        </div>
                        <div className="offer-header-heading-bold">in {offer.months} months</div>
                      </div>
                    </div>
                    <div className="offer-header-wrapper">
                      <div className="left">
                        Earn returns upto
                        <div className="return-amount">
                          &nbsp;{Convert.currency(Number(returnAmount).toFixed(2))}&nbsp;
                        </div>{' '}
                        <span className="on-your-saving">on your savings</span>
                        {/* <div className="saved">
                          <div className="saved-amount">
                            {offer.paymentPlanData?.monthlySaved &&
                              `${Convert.currency(
                                offer.paymentPlanData.monthlySaved * this.props.totalPax,
                              )}`}
                          </div>
                          <div className="saved-amount-suffix">/monthly save</div>
                        </div>
                        <div className="description">
                          <div className="left">
                            <div className="description-title">One-time booking amount </div>
                            <div className="description-value">
                              {Convert.currency(
                                offer.paymentPlanData.bookingData.amount * this.props.totalPax || 0,
                              )}
                            </div>
                          </div>
                          <div className="right">
                            <div className="description-title">Returns upto</div>
                            <div className="description-value cashback-percentage">
                              {Convert.currency(Number(returnAmount).toFixed(2))}
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="right">
                        <div className="right-content">
                          <div className="right-inner-content">
                            {/* <div className="button">{offer.months} Months</div> */}
                            <img
                              src="/img/down-arrow.svg"
                              alt="arrow"
                              className={`${
                                this.state.activeEventKey !== index + 1 ? 'active-arrow' : ''
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <>
                      <div className="accordion-body">
                        <div className="header">
                          <span className="heading">Book, Save and Buy Schedule</span>
                        </div>
                        <div className="total-amount-content">
                          Total amount : {Convert.currency(offerAmount + gstAmount + tcsAmount)}*
                        </div>
                        <div className="offer-amount-content">
                          {gstAmount || tcsAmount ? (
                            <>
                              * Offer amount : {Convert.currency(offerAmount)}
                              {gstAmount
                                ? ` + GST: ${Convert.currency(
                                    Math.round((Number(gstAmount) + Number.EPSILON) * 100) / 100,
                                  )}`
                                : ''}
                              {tcsAmount
                                ? ` + TCS: ${Convert.currency(
                                    Math.round((Number(tcsAmount) + Number.EPSILON) * 100) / 100,
                                  )}`
                                : ''}
                            </>
                          ) : (
                            'The price is inclusive of all the taxes'
                          )}
                        </div>
                        <div className="timeline-wrapper">
                          <div className="timeline">
                            <div className={`common-box ${fromKycScreen ? '' : 'grey-bg'}`}>
                              <div className="content">
                                <div className="left">
                                  {fromKycScreen && (
                                    <img src="/img/correct.png" alt="tick" className="tick" />
                                  )}
                                  <div className="orange-box">Book&nbsp;</div>
                                  your holiday today & secure your voucher
                                  {/* {moment(offer?.paymentPlanData?.bookingData?.date, 'DDMMYYYY').format(
                              'Do MMMM',
                            )}{' '} */}
                                </div>
                                <div className="right">
                                  {Convert.currency(
                                    offer?.paymentPlanData?.bookingData?.amount *
                                      this.props.totalPax,
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`common-box ${
                                fromKycScreen ? 'grey-bg pb-3 common-box-after' : ''
                              }`}
                            >
                              <div className="content remaining-installments-text">
                                <div className="orange-box"> Save&nbsp;</div>
                                <div>monthly for the remaining amount in</div> <br />
                                {/* {Convert.currency(
                              offer?.paymentPlanData?.installmentsTotalAmount * this.props.totalPax,
                            )} */}
                              </div>
                              <div>
                                <a
                                  className="icici-link"
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://www.icicipruamc.com/mutual-fund/debt-funds/icici-prudential-liquid-fund"
                                >
                                  {' '}
                                  ICICI Prudential Liquid Fund
                                </a>
                              </div>
                            </div>
                            {offer?.paymentPlanData?.installmentsArr &&
                              offer?.paymentPlanData?.installmentsArr.length > 0 &&
                              offer.paymentPlanData.installmentsArr.map((installment, index) => {
                                if (index == offer.paymentPlanData.installmentsArr.length)
                                  return null;
                                return (
                                  <div
                                    className={cx('point-node', {
                                      'point-node-last':
                                        index === offer.paymentPlanData.installmentsArr.length - 1,
                                    })}
                                    key={index}
                                  >
                                    <div className="content">
                                      <div className="left">
                                        {this._ordinalSuffix(index + 1)} save in{' '}
                                        {this._getFormattedDate(installment.date)}
                                      </div>
                                      <div className="right">
                                        {Convert.currency(installment.amount * this.props.totalPax)}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {offer.paymentPlanData.installmentsArr &&
                              offer.paymentPlanData.installmentsArr.length > 0 && (
                                <div className="earn-cashback">
                                  <span>(Earn returns </span>as you go)
                                </div>
                              )}

                            {offer?.paymentPlanData?.installmentsArr &&
                              offer?.paymentPlanData?.installmentsArr.length > 0 &&
                              offer.paymentPlanData.installmentsArr.map((installment, index) => {
                                if (index == offer.paymentPlanData.installmentsArr.length - 1) {
                                  return (
                                    <div className="common-box buy-the-holiday" key={index}>
                                      <div className="content">
                                        <div className="hidder" />
                                        <span className="orange-box"> Buy&nbsp;</span>
                                        <div style={{ maxWidth: '460px' }}>
                                          the holiday with money saved and enjoy your <br />
                                          travels.
                                          {/* Travel on{' '}
                                        {installment.date &&
                                          moment(installment.date, 'DDMMYYYY').format('MMMM')}
                                        ! */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        </div>
                        <div className="text-center">
                          {!fromKycScreen && (
                            <div className="tnc-container">
                              <input
                                type="checkbox"
                                className="tnc-checkbox"
                                onChange={(e) => this._onAcceptTerms(e, index)}
                              />
                              <div className="tnc-text">
                                I accept the{' '}
                                <a
                                  href="https://www.savvyapp.in/terms-and-conditions"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  terms and conditions
                                </a>{' '}
                                of SavvyPay
                              </div>
                            </div>
                          )}{' '}
                          <div className="pay-first-btn">
                            {fromKycScreen ? (
                              <Button
                                rounded
                                title="COMPLETE MY KYC"
                                onClick={closeIsKycVerificationPage}
                              />
                            ) : (
                              <Button
                                rounded
                                disabled={index !== this.state.accepetedOffer}
                                title="Book with SavvyPay"
                                onClick={() => this._onPayFirstInstallment(offer)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="learn-more" onClick={this.openSavvyPayWorksModal}>
                        Learn more about how SavvyPay Works
                      </div>
                      <div className="partner-images">
                        <div className="invested-at">Secured by:</div>
                        <img src="/img/icici3.png" alt="mutual-fund" className="icici-img" />
                        <img src="/img/seb4.png" alt="sebi" />
                      </div>
                    </>
                  </Accordion.Collapse>
                </div>
              );
            })}
        </Accordion>
        <SignInSignUpModal
          isShow={this.state.showLoginModal}
          closeModal={this._onCloseSignInModal}
          onComplete={this._onSignInComplete}
        />
        <SavvyPayWorksModal
          closeModal={this.closeSavvyPayWorksModal}
          isShow={isShowSavvyPayModal}
        />
      </div>
    );
  }
}

export default withRouter(OfferAccordion);

import React, { Component } from 'react';
import Loader from 'src/components/loader';
import styles from './_button.module.scss';

class Button extends Component {
  render() {
    const {
      title = 'Title',
      outline,
      rounded,
      class: className,
      onClick,
      disabled,
      loading,
    } = this.props;

    return (
      <button
        className={`${styles['app-button']} ${className || ''}${outline ? styles['outline'] : ''}${
          rounded ? styles['rounded'] : ''
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        {title}
        {loading && !disabled ? <Loader loading button /> : null}
      </button>
    );
  }
}

export default Button;

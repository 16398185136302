import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Screen, Wizard, Loader, BootstrapModal, Button } from 'src/components';
import styles from './_contract-created.module.scss';
import './_custom-modal.scss';

class ContractCreated extends Component {
  state = {
    showFolioCreated: false,    
    loading: true,
  };

  componentDidMount() {
    this.longKycVerificationRequestSubscription = EventBus.on(ACTION_TYPES.LONG_KYC_VERIFICATION_REQUEST, () => this.setState({ loading: true }));
    this.longKycVerificationSuccessSubscription = EventBus.on(ACTION_TYPES.LONG_KYC_VERIFICATION_SUCCESS, this._onLongKycVerificationSuccess);
    this.longKycVerificationFailureSubscription = EventBus.on(ACTION_TYPES.LONG_KYC_VERIFICATION_FAILED, () => this.setState({ loading: false }));

    this.props.longKycVerificationRequest();
  }

  componentWillUnmount() {
    this.longKycVerificationRequestSubscription();
    this.longKycVerificationSuccessSubscription();
    this.longKycVerificationFailureSubscription();
  }

  _onLongKycVerificationSuccess = () => {
    this.setState({ loading: false, showFolioCreated: true });
  }
  
  _doneFolioCreation = () => {
    this.setState({ showFolioCreated: false }, () => {
      this.props.history.push('/setup-sip');
    });
  };

  render() {
    const { showFolioCreated } = this.state;
    const { user } = this.props;

    return (
      <Screen>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <Wizard progress={100} />
          </Col>
        </Row>
        <BootstrapModal show={showFolioCreated} className="folio-created-modal">
          <div className={styles['folio-created-box']}>
            <Player
              autoplay
              keepLastFrame
              src="https://assets5.lottiefiles.com/packages/lf20_fjxwjvws.json"
              className={styles['folio-created-ok-icon']}
            />
            <div className={styles['folio-created-thank-you']}>
              Your KYC has been verified!
              <br />
              You may now start saving for your purchase.
            </div>
            <div className={styles['folio-created-start-saving']}>
              Your folio number is No. {user?.folio?.folioNumber}
            </div>
            <Button title="SETUP SIP" className={styles['folio-created-btn']} onClick={this._doneFolioCreation} />                          
          </div>
        </BootstrapModal>       
        <Loader loading={this.state.loading} title="Verifying request, please wait..." />        
      </Screen>
    );
  }
}

export default withRouter(ContractCreated);

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminGoalDetail({ id }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_GOAL_LIST}/${id}`,
  }).then((response) => response.data);
}

export function adminGoalsList(data) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_GOAL_LIST}?page=${data.page}`,
    data,
  }).then((response) => response.data);
}

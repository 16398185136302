import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Convert } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import { Loader } from 'src/components';
import EventBus from 'eventing-bus';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import gethhmmss from 'gethhmmss';
import cx from 'classnames';
import './_modal.scss';

// const ICICI_BANK_URLS = [
//   "https://apps.icicipruamc.com/IpruServices/frmGetresponse.aspx", // production internet banking
//   "https://recycle.icicipruamc.com/Distributorsvcs/frmGetresponse.aspx", // staging internet banking
//   "https://apps.icicipruamc.com/IpruServices/DebitCardPayment/frmDebitCardResponse.aspx", // production debit card
//   "https://recycle.icicipruamc.com/Distributorsvcs/DebitCardPayment/frmDebitCardResponse.aspx" // staging debit card
// ];

const ENVIRONMENT = process.env.REACT_APP_ENV || 'development';

const timerProps = {
  isPlaying: true,
  size: 100,
  strokeWidth: 6,
};

const STEPS = {
  SAVE_MORE: 'SAVE_MORE',
  SAVING_METHOD: 'SAVING_METHOD',
  DONE: 'DONE',
  FAILED: 'FAILED',
  TIMER: 'TIMER',
};

const PAYMENT_TYPES = {
  UPI: 'UPI',
  NET_BANKING: 'Internet-Banking',
};

const PAYMENT_METHODS = {
  GOOGLE_PAY: 'Google Pay',
  BHIM: 'BHIM',
  PAYTM: 'PayTM',
  PHONE_PE: 'PhonePe',
  OTHERS: 'Others',
};

const VALID_PAYMENT_FORMAT = {
  [PAYMENT_METHODS.GOOGLE_PAY]: ['@okicici', '@okaxis', '@okhdfcbank', '@oksbi'],
  [PAYMENT_METHODS.BHIM]: ['@upi'],
  [PAYMENT_METHODS.PAYTM]: ['@paytm'],
  [PAYMENT_METHODS.PHONE_PE]: ['@ybl','@ibl', '@axl'],
  [PAYMENT_METHODS.OTHERS]: ['@ikwik']
};

const PAYMENT_METHODS_LIST = [
  {
    name: PAYMENT_METHODS.GOOGLE_PAY,
    image: '/img/gpay.png',
  },
  {
    name: PAYMENT_METHODS.BHIM,
    image: '/img/bhim.png',
  },
  {
    name: PAYMENT_METHODS.PAYTM,
    image: '/img/paytm.png',
  },
  {
    name: PAYMENT_METHODS.PHONE_PE,
    image: '/img/phonepay2.png',
  },
  {
    name: PAYMENT_METHODS.OTHERS,
    image: '/img/others.png',
  },
];

export const DEPOSIT_STATUSES = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  PENDING_INVESTMENT: 'pending_investment',
  TEMPORARY_PENDING_INVESTMENT: 'temporary_pending_investment',
  ERROR: 'error',
};

const ICICI_PRU_LIQUID_FUND_CODE = '1565';
const SAVING_RULE_BOOST = 'Boost';

class SaveMore extends Component {
  state = {
    activeStep: STEPS.SAVE_MORE,
    selectedPaymentType: PAYMENT_TYPES.UPI,
    selecedPaymentMethod: PAYMENT_METHODS.GOOGLE_PAY,
    amount: null,
    upiVpa: null,
    depositId: null,
    upiMessage: '',
    isContinuousCheck: false,
    loading: false,
    errors: {
      e_amount: '',
    },
  };

  interval = null;

  componentDidMount() {
    this.createDepositRequestSubscription = EventBus.on(ACTION_TYPES.CREATE_DEPOSIT_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.createDepositSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_DEPOSIT_SUCCESS,
      this._oncreateDepositSuccess,
    );
    this.createDepositFailureSubscription = EventBus.on(ACTION_TYPES.CREATE_DEPOSIT_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.fetchDepositSuccessSubscription = EventBus.on(
      ACTION_TYPES.FETCH_DEPOSIT_SUCCESS,
      this._onfetchDepositSuccess,
    );
  }

  componentWillUnmount() {
    this.createDepositRequestSubscription();
    this.createDepositSuccessSubscription();
    this.createDepositFailureSubscription();

    this.fetchDepositSuccessSubscription();

    if (this.interval) clearInterval(this.interval);
  }

  _oncreateDepositSuccess = (response) => {
    this.setState({ depositId: response.deposit.id, upiMessage: response.message });

    if (response.gatewayUrl) {
      const newWindow = window.open(response.gatewayUrl);
      this.interval = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(this.interval);
          this.props.fetchDepositRequest(this.state.depositId);
        }

        // const currentUrl = `${newWindow.location.protocol}//${newWindow.location.host}${newWindow.location.pathname}`;

        // if (ICICI_BANK_URLS.indexOf(currentUrl) !== -1) {
        //   clearInterval(this.interval);
        //   this.props.fetchDepositRequest(this.state.depositId);
        // }
      }, 2000);
    } else if (response.message || ENVIRONMENT === 'production') {
      this.setState({ loading: false, isContinuousCheck: true }, () => {
        this.handleStep({ stepName: STEPS.TIMER });

        setTimeout(() => {
          this.props.fetchDepositRequest(this.state.depositId);
        }, 5000);
      });
    } else {
      this.setState({ loading: false }, () => {
        this.handleStep({ stepName: STEPS.DONE });
      });
    }
  };

  _onfetchDepositSuccess = (response) => {
    const { deposit } = response;

    if (!this.state.isContinuousCheck) {
      return this.setState({ loading: false }, () => {
        if (
          deposit.status === DEPOSIT_STATUSES.COMPLETED ||
          deposit.status === DEPOSIT_STATUSES.PENDING_INVESTMENT ||
          deposit.status === DEPOSIT_STATUSES.TEMPORARY_PENDING_INVESTMENT
        ) {
          this.handleStep({ stepName: STEPS.DONE });
        } else if (
          deposit.status === DEPOSIT_STATUSES.ERROR ||
          deposit.status === DEPOSIT_STATUSES.IN_PROGRESS
        ) {
          this.handleStep({ stepName: STEPS.FAILED });
        }
      });
    }

    if (
      deposit.status === DEPOSIT_STATUSES.COMPLETED ||
      deposit.status === DEPOSIT_STATUSES.PENDING_INVESTMENT ||
      deposit.status === DEPOSIT_STATUSES.TEMPORARY_PENDING_INVESTMENT
    ) {
      this.setState({ isContinuousCheck: false }, () => {
        this._onfetchDepositSuccess(response);
      });
    } else {
      setTimeout(() => {
        this.props.fetchDepositRequest(this.state.depositId);
      }, 5000);
    }
  };

  _onFetchDeposit = () => {
    this.setState({ isContinuousCheck: false, loading: true }, () => {
      this.props.fetchDepositRequest(this.state.depositId);
    });
  };

  handleStep = ({ stepName }) => {
    this.setState({ activeStep: stepName });
  };

  handleSelectedPaymentType = ({ selectedPaymentType }) => {
    this.setState({ selectedPaymentType });
  };

  handleSelectedPaymentMethod = ({ selecedPaymentMethod }) => {
    this.setState({ selecedPaymentMethod });
  };

  _onCreateDeposit = () => {
    const { savingGoal, savingAccount } = this.props;
    const { id: savingGoalId, savingRules } = savingGoal;
    const savingRule = savingRules.find((rule) => rule.description === SAVING_RULE_BOOST);
    const { amount, upiVpa, selectedPaymentType } = this.state;
    const depositParams = {
      deposit: {
        amount,
        saving_rule_id: savingRule.id,
        saving_goal_id: savingGoalId,
        account_id: savingAccount.id,
        product_code: ICICI_PRU_LIQUID_FUND_CODE,
        preferred_payment_method: selectedPaymentType,
        upi_vpa: upiVpa,
      },
    };
    this.props.createDepositRequest(depositParams);
  };

  handleAmount = (e) => {
    this.setState({ amount: e.target.value }, () => {
      const { amount } = this.state;
      if (!amount) {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_amount: 'Required amount' },
        });
      } else if (amount && parseInt(amount) < 1) {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_amount: 'Amount must be greater than zero' },
        });
      } else {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, e_amount: '' },
        });
      }
    });
  };

  _getUPIPlaceholder = (selecedPaymentMethod) => (
    `jane.doe${selecedPaymentMethod === PAYMENT_METHODS.GOOGLE_PAY
      ? VALID_PAYMENT_FORMAT[PAYMENT_METHODS.GOOGLE_PAY][0]
      : selecedPaymentMethod === PAYMENT_METHODS.PAYTM
      ? VALID_PAYMENT_FORMAT[PAYMENT_METHODS.PAYTM][0]
      : selecedPaymentMethod === PAYMENT_METHODS.BHIM
      ? VALID_PAYMENT_FORMAT[PAYMENT_METHODS.BHIM][0]
      : selecedPaymentMethod === PAYMENT_METHODS.PHONE_PE
      ? VALID_PAYMENT_FORMAT[PAYMENT_METHODS.PHONE_PE][0]
      : selecedPaymentMethod === PAYMENT_METHODS.OTHERS
      ? VALID_PAYMENT_FORMAT[PAYMENT_METHODS.OTHERS][0]
      : null}`
    )   

  render() {
    const { isModalOpen, closeModal, savingGoal, bankAccount } = this.props;
    const { activeStep, selectedPaymentType, selecedPaymentMethod, errors, amount, upiVpa } =
      this.state;
    const isDisabledContinue = !amount || (amount && parseInt(amount) < 1);      
    const upiSuffix = upiVpa && upiVpa.toLowerCase().split('@')[1];          
    const isDisabledUpi = VALID_PAYMENT_FORMAT[selecedPaymentMethod] !== VALID_PAYMENT_FORMAT[PAYMENT_METHODS.OTHERS] ? 
      VALID_PAYMENT_FORMAT[selecedPaymentMethod].indexOf(`@${upiSuffix}`) === -1 : 
      (!upiVpa || upiVpa.length < 3 || upiVpa.indexOf('@') === -1);
    
    return (
      <div>
        <Modal show={isModalOpen} size="lg">
          <div className="save-more-modal">
            <div className="cross-icon">
              <span onClick={closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
            {activeStep === STEPS.SAVE_MORE && (
              <div className="withdraw-saving">
                <div className="title">How much would you like to save?</div>
                <div className="amount">
                  Total Saved: {Convert.currency(savingGoal?.investmentPlusPendingBalance || 0)}
                </div>
                <div className="input-box">
                  <input
                    value={this.state.amount || ''}
                    type="number"
                    className="text-input"
                    placeholder="Please enter the amount you wish to save"
                    onChange={this.handleAmount}
                  />
                  {errors && errors?.e_amount && <div className="error">{errors?.e_amount}</div>}
                </div>
                <div className="withdraw-btn">
                  <button
                    className={cx('custom-btn', {
                      'disabled-btn': isDisabledContinue,
                    })}
                    disabled={isDisabledContinue}
                    onClick={() => this.handleStep({ stepName: STEPS.SAVING_METHOD })}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
            {activeStep === STEPS.SAVING_METHOD && (
              <div className="confirm-withdraw">
                <div className="title">Pick a Saving Method</div>
                <div className="horizontal-line-box">
                  <div className="horizontal-line" />
                </div>
                <div className="amount">Saving Amount: {Convert.currency(this.state.amount)}</div>
                <div className="payment-type">
                  <div
                    className={cx('upi', {
                      'active-payment': selectedPaymentType === 'UPI',
                    })}
                    onClick={() =>
                      this.handleSelectedPaymentType({
                        selectedPaymentType: 'UPI',
                      })
                    }
                  >
                    {PAYMENT_TYPES.UPI}
                  </div>
                  <div
                    className={cx('banking', {
                      'active-payment': selectedPaymentType === 'I',
                    })}
                    onClick={() =>
                      this.handleSelectedPaymentType({
                        selectedPaymentType: 'I',
                      })
                    }
                  >
                    {PAYMENT_TYPES.NET_BANKING}
                  </div>
                </div>
                {selectedPaymentType === 'UPI' && (
                  <>
                    <div className="payment-methods-group">
                      {PAYMENT_METHODS_LIST &&
                        PAYMENT_METHODS_LIST.length > 0 &&
                        PAYMENT_METHODS_LIST.map((data, index) => (
                          <div
                            className="payment-method-box "
                            key={index}
                            onClick={() =>
                              this.handleSelectedPaymentMethod({ selecedPaymentMethod: data.name })
                            }
                          >
                            <div
                              className={cx('payment-method', {
                                'active-payment-method': selecedPaymentMethod === data.name,
                              })}
                            >
                              <img src={data.image} alt={`img-${index}`} />
                            </div>
                            <div className="label">{data.name}</div>
                          </div>
                        ))}
                    </div>
                    <div className="input-box">
                      <div className="label">VPA:</div>
                      <input
                        value={this.state.upiVpa}
                        type="text"
                        className="text-input"
                        placeholder={this._getUPIPlaceholder(selecedPaymentMethod)}
                        onChange={(e) => this.setState({ upiVpa: e.target.value })}
                      />
                    </div>
                  </>
                )}

                {selectedPaymentType === 'I' && (
                  <div className="bank-details">
                    (XXXXX{bankAccount.accountNumberLast4}) {bankAccount.bankName},{' '}
                    {bankAccount.branch}
                  </div>
                )}

                <div className="confirm-btn">
                  <button
                    className={cx('custom-btn', {
                      'disabled-btn': isDisabledUpi,
                      'mt-extra': selectedPaymentType === PAYMENT_TYPES.NET_BANKING,
                    })}
                    disabled={isDisabledUpi}
                    onClick={this._onCreateDeposit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}

            {activeStep === STEPS.DONE && (
              <div className="withdrawal-successfull">
                <div className="tick">
                  <img src="/img/tick.png" alt="tick" />
                </div>
                <div className="title">Savings Successful</div>
                <div className="amount">{Convert.currency(this.state.amount)}</div>
                <div className="description">The units will be allocated 2-3 working days</div>

                <div className="done-btn">
                  <button className="custom-btn" onClick={closeModal}>
                    Done
                  </button>
                </div>
              </div>
            )}
            {activeStep === STEPS.FAILED && (
              <div className="withdrawal-successfull">
                <div className="tick">
                  <img src="/img/report.png" alt="report" />
                </div>
                <div className="title">Failed</div>
                <div className="description">
                  The transcation could not be completed. Please try again
                </div>

                <div className="done-btn">
                  <button
                    className="custom-btn"
                    onClick={() => this.handleStep({ stepName: STEPS.SAVE_MORE })}
                  >
                    Retry
                  </button>
                </div>
              </div>
            )}
            {activeStep === STEPS.TIMER && (
              <div className="timer">
                <div className="payment">Complete Payment</div>
                <div className="amount">Amount : {Convert.currency(this.state.amount)}</div>
                <div className="inner-box">
                  <div className="description-one">{this.state.upiMessage || "Check your UPI app to complete the transaction"}</div>
                  <div className="countdown">
                    <CountdownCircleTimer
                      isPlaying
                      duration={180}
                      {...timerProps}
                      colors={[['#ffd401']]}
                      onComplete={() => this.setState({ isContinuousCheck: false })}
                    >
                      {({ remainingTime }) => (
                        <div className="remaining-time">
                          {gethhmmss(remainingTime).replace('00:', '')}
                        </div>
                      )}
                    </CountdownCircleTimer>
                  </div>
                  <div className="title">
                    Please approve the payment request before it times out
                  </div>
                  <div className="description-two">
                    Do not close this screen until the transaction is complete
                  </div>
                  <div className="fetch-transaction">
                    <button className="custom-btn" onClick={this._onFetchDeposit}>
                      Check status
                    </button>
                  </div>
                </div>
                <div className="cancel">
                  {/* <button
                    className="custom-btn"
                    onClick={() => this.handleStep({ stepName: STEPS.SAVE_MORE })}
                  >
                    Retry
                  </button>                                    */}
                </div>
              </div>
            )}
          </div>
          <Loader loading={this.state.loading} />
        </Modal>
      </div>
    );
  }
}

export default SaveMore;

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonOne } from 'src/components';
import moment from 'moment';
import './_setup-pay-success-modal.scss';
import { Convert } from 'src/helpers';

class SetUpPaySuccessModal extends Component {
  _onRetry = () => {
    if (this.props.paymentMethod === "book_save_buy") {
      this.props.history.push('/setup-sip');
    } else {
      this.props.history.push('/auto-pay/setup');
    }
  }

  _getOrdinal(n) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  render() {
    const { isVisibleModal, closeModal, paymentCalendar, mandate, isError, errorMessage } = this.props;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    
    return (
      <div className="main-setup-pay-modal">
        <Modal show={isVisibleModal} size="lg" className="setup-pay-modal">
          {
            !isError
            ?
            <>
              <div>
                <img src="/img/ok-4.svg" alt="ok" />
              </div>
              <div className="title">Your payment plan has been setup succesfully!</div>
              <div className="sub-title sub-title-two">
                You will receive an email and whatsapp with the confirmation too.{' '}
              </div>
              {
                sortedPaymentCalendarDates.map((calendar, index) => {
                  if (index === 0) return;                  
                  return (
                    <div className="sub-title">
                      {this._getOrdinal(index)} installment on {moment(calendar, 'DDMMYYYY').format('Do MMMM')} : {Convert.currency(paymentCalendar[calendar])}
                    </div>
                  )
                })                
              }
              {/* <div className="sub-title">Amount : {Convert.currency(paymentCalendar[firstPaymentKey])} /month</div>
              <div className="sub-title">Starting on: {mandate?.start_date}</div>
              <div className="sub-title">Ending on: {mandate?.end_date}</div> */}
              <div className="sub-title sub-title-three">Transaction ID: {mandate?.partner_transaction_id}</div>
              <div className="setup-btn">
                <ButtonOne text="DONE" type="submit" onClick={closeModal} />
              </div>
            </>
            :
            <>
              <div>
                <img src="/img/report.png" alt="ok" />
              </div>
              <div className="title">Your payment plan setup failed!</div>
              <div className="sub-title sub-title-two">
                {errorMessage || 'We were unable to setup your SIP'}
              </div>
              <div className="sub-title">Please try again.</div>              
              <div className="setup-btn">
                <ButtonOne text="RETRY SIP SETUP" type="submit" onClick={this._onRetry} />
              </div>
            </>
          }          
        </Modal>
      </div>
    );
  }
}

export default SetUpPaySuccessModal;

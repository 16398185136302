import React, { Component } from 'react'
import { ACTION_TYPES } from 'src/constants'
import EventBus from 'eventing-bus'
import { Loader } from 'src/components'
import moment from 'moment'
import { TransactionsTable } from 'src/components/tables'

class Deposits extends Component {

    state={ 
        loading: false
    }

    componentDidMount(){
        this.requestAdminTransactionDepositList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST,
            () => this.setState({loading: true})
        )
    
        this.requestAdminTransactionDepositListSuccess = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_SUCCESS,
            () => this.setState({loading: false})
        )
    
        this.rrequestAdminTransactionDepositListFailed = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_FAILED,
            () => this.setState({loading: false})
        )
    
        this.props.requestAdminTransactionDepositList()
    
    }

    componentWillUnmount(){
        this.requestAdminTransactionDepositList()
        this.requestAdminTransactionDepositListSuccess()
        this.rrequestAdminTransactionDepositListFailed()
    }

    /**
   * add a label to the transactions array
   * example: 'Deposited' for savingsDeposit data, 'withdrwal' for withdrawals data 
   * @param {array} data 
   * @param {string} labelName 
   * @returns {array}
   */
    _addTransactionLabel = (data, labelName) => {

        const labeledData = data.map(val => {
            val['label'] = labelName
            return val
        })

        return labeledData

    }

    /**
     * Raw data in the form of object of arrays is received which needs to be merged 
     * and sorted according to date
     * 
     * @param {object} transactionsData 
     * @returns {array}
     */
    _organizeTransactionsData = (deposits) => {

        deposits = this._addTransactionLabel(deposits,'Deposited')

        let organizedData = [
            ...deposits
        ]

        return organizedData.sort((a,b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf() )

        // console.log(organizedData);
    }

    render() {
        const {transactions:{deposits = []}} = this.props

        const transactions = this._organizeTransactionsData(deposits)

        return (
            <div>
                <TransactionsTable data={transactions}/>
                <Loader loading={this.state.loading}/>
            </div>
        )
    }
}

export default Deposits


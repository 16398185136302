import { Modal } from 'react-bootstrap';
import { ButtonOne } from 'src/components';
import React, { Component } from 'react';
import styles from './_offer-update-confirmation-modal.module.scss';
import './_modal.scss';

class OfferUpdateConfirmationModal extends Component {
  render() {
    const {
      showUpdateConfirmationModal,
      closeUpdateConfirmationModal,
      product,
      handleUpdateConfirmationModal,
    } = this.props;
    return (
      <div>
        <Modal show={showUpdateConfirmationModal} size="lg">
          <div className={styles['close-btn']}>
            <span onClick={closeUpdateConfirmationModal}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </span>
          </div>
          <div className={styles['alert-icon']}>
            <img src="/img/new-offer-ok.svg" alt="alert-icon" />
          </div>
          <div className={styles['title']}>
            Are you sure want to update ‘{product?.productName}’ product offer?
          </div>
          <div className={styles['group-btns']}>
            <div className={styles['inner-btn']}>
              <ButtonOne text="Ok" type="submit" onClick={handleUpdateConfirmationModal} />
            </div>
            <div className={styles['inner-btn']}>
              <ButtonOne text="Cancel" type="submit" onClick={closeUpdateConfirmationModal} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OfferUpdateConfirmationModal;

import React, { Component } from 'react';
import cx from 'classnames';
import FundInfo from './fund-info';
import styles from './_fund-information.module.scss';

class FundInformation extends Component {
  componentDidMount() {
    this.props.requestGoalList();
  }

  render() {
    return (
      <div className={styles['fund-information']}>
        <h4 className={styles['title']}>Fund Information</h4>
        <p className={styles['description']}>Your money is being invested in</p>
        <div className={styles['fund-info-box']}>
          <FundInfo />
        </div>
        <a
          href="https://individuals.savvyapp.in/fund-performance"
          className={cx('btn btn-primary', styles['know-more'])}
          target="_blank"
          rel="noreferrer"
        >
          Know More About This Fund
        </a>
      </div>
    );
  }
}

export default FundInformation;

import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import * as yup from 'yup';
import EventBus from 'eventing-bus';
import { ProductDetailsEdit } from 'src/components/admin';
import { ACTION_TYPES } from 'src/constants';
import { Heading } from 'src/components/theme';
import styles from './_newproduct.module.scss';

class NewProduct extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.createProductSubscription = EventBus.on(ACTION_TYPES.CREATE_ADMIN_PRODUCT, () =>
      this.setState({ loading: true }),
    );
    this.createProductSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_ADMIN_PRODUCT_SUCCESS,
      this._onProductCreateSuccess,
    );
    this.createProductFailureSubscription = EventBus.on(
      ACTION_TYPES.CREATE_ADMIN_PRODUCT_FAILED,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.createProductSubscription();
    this.createProductSuccessSubscription();
    this.createProductFailureSubscription();
  }

  _validationSchema = yup.object().shape({
    product_name: yup.string().required('Please enter name of the product'),
    description: yup.string().required('Please provide the number of offers available.'),
    amount: yup.number().required('Please enter the product amount.'),
    // productImage: yup.mixed()
    //     .nullable()
    //     .notRequired()
    //     .test(
    //         'imageSize',
    //         'Image size is large',
    //         value => {
    //             if(value){
    //                 console.log(value);
    //             }
    //         }
    //     )
    //     .test(
    //         'imageFormat',
    //         'Image format is not valid',
    //         val => val && ['image/jpg', 'image/jpeg', 'image/png'].includes(val.type)
    //     ),
    image_link: yup.string().url('Image url is not valid.'),
    productDescription: yup.string().required('Please provide description for the product.'),
    productAmount: yup.number().required('Please enter the product amount.'),
    productImageLink: yup.string().url('Image url is not valid.'),
    productInclusions: yup.string().required("Please provide product's Inclusions and Exclusions."),
    productTerms: yup.string().required("Please provide product's Terms & Conditions."),
    productCancellationPolicy: yup
      .string()
      .required("Please provide product's Cancellation Policy."),
    offerName: yup.string().required('Please enter the offer name.'),
    offerDescription: yup.string().required('Please enter offer Description.'),
    offerAmount: yup.number().required('Offer price is required'),
    discountPercentage: yup.number().required('Discount percentage is required'),
    totalMonths: yup.number().required('Total months is required'),
    agentId: yup.string().required('agent is is required'),
  });

  _onSubmitProduct = (values, { setSubmitting }) => {
    this.props.createAdminProduct({
      product: {
        product_name: values.productName,
        description: values.productDescription,
        amount: values.productAmount,
        image_link: values.productImageLink,
        product_offers: [
          {
            product_offer_name: values.offerName,
            description: values.offerDescription,
            is_fixed_months: true,
            months: values.totalMonths,
            is_discount_percentage: true,
            discount_percentage: values.discountPercentage,
          },
        ],
      },
    });
    setSubmitting(false);
  };

  _onProductCreateSuccess = () => {
    this.setState({ loading: false }, () => {
      this.props.history.push('/admin/products');
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <Container className={styles.container}>
        <Heading className={styles.heading}>Add a New Product</Heading>
        <ProductDetailsEdit isNew loading={loading} />
      </Container>
    );
  }
}

export default NewProduct;

import { ACTION_TYPES } from 'src/constants';

/**
 *
 * Product offer Create actions
 *
 */
export function requestAdminAgentCreate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_AGENT_CREATE,
    payload,
  };
}

export function adminAgentCreateSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_AGENT_CREATE_SUCCESS,
    payload,
  };
}

export function adminAgentCreateFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_AGENT_CREATE_FAILED,
    payload,
  };
}
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CollectMoney, Loader } from 'src/components';
import { Convert } from 'src/helpers';
import cx from 'classnames';
// import moment from 'moment';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import _ from 'lodash';
import OfferBox from '../pay/offer-box';
import styles from './collect-request.module.scss';

class CollectRequest extends Component {
  state = {
    isCollectModalOpen: false,
    isLoading: false,
    fetching: false,
  };

  componentDidMount() {
    const { match } = this.props;

    this.requestCollectionList = EventBus.on(ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL, () =>
      this.setState({ fetching: true }),
    );
    this.requestCollectionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL_SUCCESS,
      () => this.setState({ fetching: false }),
    );
    this.requestCollectionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL_FAILED,
      () => this.setState({ fetching: false }),
    );

    this.requestAcceptCollectionList = EventBus.on(ACTION_TYPES.REQUEST_COLLECTION_ACCEPT, () =>
      this.setState({ isLoading: true }),
    );
    this.requestAcceptCollectionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_ACCEPT_SUCCESS,
      this.onCollectionAcceptSuccess,
    );
    this.requestAcceptCollectionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_ACCEPT_FAILED,
      this.onCollectionAcceptFailed,
    );

    this.props.requestUserCollectionsDetail({ id: match?.params?.id });
  }

  componentWillUnmount() {
    this.requestCollectionList();
    this.requestCollectionListSuccess();
    this.requestCollectionListFailed();

    this.requestAcceptCollectionList();
    this.requestAcceptCollectionListSuccess();
    this.requestAcceptCollectionListFailed();
  }

  onCollectionAcceptSuccess = () => {
    this.openCollectModal();
    this.setState({ isLoading: false });
  };

  onCollectionAcceptFailed = () => {
    this.setState({ isLoading: false });
  };

  closeCollectModal = (success = false) => {
    this.setState({ isCollectModalOpen: false }, () => {
      if (success) this.props.history.push('/dashboard');
    });
  };

  openCollectModal = () => {
    this.setState({ isCollectModalOpen: true });
  };

  handleApprovePayment = () => {
    const { requestAdminCollectionsAccept, match } = this.props;
    requestAdminCollectionsAccept({ id: match?.params?.uuid });
  };

  render() {
    const { fetching, isCollectModalOpen, isLoading } = this.state;
    const { user, collectionDetail } = this.props;
    const { savingGoal = {} } = collectionDetail || {};
    const {
      productOffer,
      goalAbsoluteAmount,
      investmentPlusPendingBalance,
      savingGoalContributions,
    } = savingGoal;
    const totalOfferCost =
      Number(goalAbsoluteAmount || 0) + Number(productOffer?.upfrontPaymentAmount || 0);
    const investedAmount = Number(investmentPlusPendingBalance || 0);
    const totalAmountSaved = Number(productOffer?.upfrontPaymentAmount || 0) + investedAmount;
    const totalContribution = _.sumBy(
      savingGoalContributions,
      (contribution) =>
        (contribution.status === 'completed' || contribution.status == 'pending_investment') &&
        Number(contribution.amount),
    );
    const interestEarned = investedAmount - totalContribution;
    const amount = productOffer?.product?.amount || 0;
    const payableAmount = amount - (productOffer.discountPercentage / 100) * amount;

    return (
      <div className={styles['sip']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={cx(styles['header'], styles['mobile'])}>
              <div className={styles['inner-header']}>
                <div className={styles['sub-inner-header']}>
                  <div className={styles['main-title']}>{productOffer?.productOfferName || ''}</div>
                  <div className={styles['price']}>
                    {Convert.currency(investmentPlusPendingBalance || 0)}
                  </div>
                  <div className={styles['note']}>PENDING AMOUNT</div>
                </div>
              </div>
            </div>
            <div className={cx(styles['progress-bar'], styles['mobile'])}>
              <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
            </div>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>
                Make your final payment <span> AND BOOK YOUR HOLIDAY</span>
              </div>
              <div className={styles['line']} />

              <div className={styles['content-wrapper-box']}>
                <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Total Cost of Offer</div>
                  <div className={styles['right']}>{Convert.currency(totalOfferCost)}</div>
                </div>
                <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>You have already paid </div>
                  <div className={styles['right']}>{Convert.currency(totalAmountSaved)}</div>
                </div>
                <div className={styles['content-wrapper']}>
                  <div className={styles['left']}>Amount Pending</div>
                  <div className={styles['right']}>
                    {Convert.currency(investmentPlusPendingBalance || 0)}
                  </div>
                </div>
                <div className={styles['footer-title']}>
                  RETURNS EARNED : {Convert.currency(interestEarned > 0 ? interestEarned : 0)}
                </div>
              </div>

              <div className={styles['content-wrapper-box-desktop']}>
                <div className={styles['d-title']}>{Convert.currency(payableAmount)}</div>
                <div className={styles['d-sub-title']}>AMOUNT PAYABLE</div>
                <div className={styles['d-note']}>
                  This is the final payment for {productOffer?.productOfferName || ''} to be paid to
                  {productOffer?.partner?.name || ''}
                </div>
                <div className={styles['line']} />
                <div className={styles['d-sub-title']}>PAYMENT HISTORY</div>
                <div className={styles['d-footer-box']}>
                  <div className={styles['d-footer-left']}>
                    <div className={styles['d-footer-price']}>
                      {Convert.currency(totalAmountSaved)}
                    </div>
                    <div className={styles['d-footer-title']}>Paid so far</div>
                  </div>
                  <div className={styles['d-footer-right']}>
                    <div className={styles['d-footer-price']}>
                      {Convert.currency(interestEarned > 0 ? interestEarned : 0)}
                    </div>
                    <div className={styles['d-footer-title']}>Returns Earned</div>
                  </div>
                </div>
              </div>
              <div className={styles['secure']}>
                <img src="/img/secure-lock.svg" alt="secure" className={styles['secure-lock']} />
                <span>This transaction is secured by</span>
                <img src="/img/logo-savvy.svg" alt="logo-savvy" />
              </div>
            </div>
            <div
              onClick={this.handleApprovePayment}
              disabled={isLoading}
              className={cx(styles['footer'], {
                [styles['disabled-btn']]: isLoading,
              })}
            >
              {isLoading ? 'Loading...' : 'GENERATE OTP & PAY'}
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox partner={productOffer?.partner} product={productOffer?.product} user={user} />
          </div>
        </div>
        {isCollectModalOpen && (
          <CollectMoney isModalOpen={isCollectModalOpen} closeModal={this.closeCollectModal} />
        )}
        <Loader loading={fetching} fixed />
      </div>
    );
  }
}

export default withRouter(CollectRequest);

import { ACTION_TYPES } from "src/constants";

/** user dashboard transactions */

export function requestTransactionList(payload){
    return {
        type: ACTION_TYPES.REQUEST_TRANSACTION_LIST,
        payload
    }
}

export function requestTransactionListSuccess(payload){
    return {
        type: ACTION_TYPES.REQUEST_TRANSACTION_LIST_SUCCESS,
        payload
    }
}

export function requestTransactionListFailed(payload){
    return {
        type: ACTION_TYPES.REQUEST_TRANSACTION_LIST_FAILED,
        payload
    }
}

/**
 *
 * Transaction Detail Actions
 *
 */

export function requestTransactionDetail(payload) {
    return {
        type: ACTION_TYPES.REQUEST_TRANSACTION_DETAIL,
        payload
    };
}

export function requestTransactionDetailSuccess(payload) {
    return {
        type: ACTION_TYPES.REQUEST_TRANSACTION_DETAIL_SUCCESS,
        payload
    };
}

export function requestTransactionDetailFailure(payload) {
    return {
        type: ACTION_TYPES.REQUEST_TRANSACTION_DETAIL_FAILED,
        payload
    };
}
import React, { useMemo } from 'react';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import { Badge } from 'src/components/theme';
import {FiCopy} from 'react-icons/fi'
import { Convert } from 'src/helpers';
import { DataTable, TextOne, LinkOne } from '../../../components';
import './_payment-links-table.style.scss';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

console.log(API_ENDPOINT_URL)

const PaymentLinksTable = (props) => {  
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#ffffff',
      borderBottomColor: '#f3f3f3',
    },
    tableHeader: {
      backgroundColor: '#f3f3f3'
    }
  };

  const {totalItems} = props
  
  const pageSize = 50

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems/pageSize)
  }

  const _getStatus = (data) => {
    const {paymentLinks, savingGoals} = data
    let status = ''

    if(paymentLinks.length){
      status = 'created'

      const {expiry} = paymentLinks[0] 
  
      // check if link has expired 
      const isExpired = moment().diff(moment(expiry), 'milliseconds') > 0
      if(isExpired){
        status = 'expired'
        return status
      }
  
      if(savingGoals.length){
        const {isPaymentMade, kycd, mandate, status: statusFromData} = savingGoals[0]
        
        if(statusFromData == 'completed'){
          return 'completed'
        }

        if(isPaymentMade){
          status = kycd && mandate ? 'ongoing' : 'booked'
        }
        
      }
    }

    return status

  }

  const _onCopyLink = (e, value) => {  
    const link = `${API_ENDPOINT_URL}?id=${value}`; 
    const inputLink = document.getElementById("textcopy");
    
    inputLink.value = link;
    inputLink.select();
    inputLink.setSelectionRange(0, 99999);

    if(!navigator.clipboard){
      document.execCommand('copy');
      notify.show('Link copied to clipboard', 'success');
    }else{
      navigator.clipboard.writeText(link)
      .then(() => {
          notify.show('Link copied to clipboard', 'success');
        }
      )
      .catch((err) => console.log('clipboard copy issue.', err))
    }

    e.preventDefault();
    
  };

  const _getPaymentLinkProperty = (paymentLinks, propertyName) => {
    const paymentLink = paymentLinks.length ? paymentLinks[0] : '';
    
    return paymentLink ? paymentLink[propertyName] : false

  }

  const columns = useMemo(
    () => [    
      {
        Header: <TextOne variant="head">Link URL</TextOne>,
        accessor: 'paymentLinks',
        id:"paymentLinksOne",
        disableSortBy: true,
        Cell: ({ value }) => {
          const paymentLink = _getPaymentLinkProperty(value, 'shortLink')
          const referenceId = _getPaymentLinkProperty(value, 'referenceId')
          const link = paymentLink ? (paymentLink.length > 15 ? `...${paymentLink.slice(-15)}` : paymentLink) : ''
          
          return (<>
            <TextOne>{link}</TextOne>
            {link && <FiCopy onClick={(e) => _onCopyLink(e, referenceId)} style={{'cursor':'pointer', 'marginLeft': '10px'}}/>}
          </>)
        },
      },              
      {
        Header: <TextOne variant="head">Link Name</TextOne>,
        accessor: 'productOfferName',
        disableSortBy: true,
        Cell: ({row, value}) => <TextOne><LinkOne to={`admin/paymentlink/${row?.original?.uuid}`}>{value}</LinkOne></TextOne>,
      },
      {
        Header: <TextOne variant="head">Date Created</TextOne>,
        accessor: 'paymentLinks',
        id:'paymentLinkTwo',
        disableSortBy: false,
        Cell: ({ value }) => {
          const createdAt = _getPaymentLinkProperty(value, 'createdAt')
          const date = createdAt ? moment(createdAt,'DDMMYYYY').format('Do MMMM, YYYY') : ''
          return <TextOne>{date}</TextOne>
        },
      },
      {
        Header: <TextOne variant="head">Booking Amount</TextOne>,
        accessor: 'upfrontPaymentAmount',
        disableSortBy: false,
        Cell: ({ value }) => {

          let bookingAmount = value ? Convert.toCurrencyValue(value) : ''
          return <TextOne>{bookingAmount}</TextOne>
        },
      },      
      {
        Header: <TextOne variant="head">User</TextOne>,
        accessor: 'paymentLinks',
        id:"paymentLinksThree",
        disableSortBy: true,
        Cell: ({ value }) => {
          const user = _getPaymentLinkProperty(value, 'sentToName')

          return (<TextOne>{user || ''}</TextOne>)
        },
      },      
      {
        Header: <TextOne variant="head">Status</TextOne>,
        accessor: 'expiry',
        disableSortBy: true,
        Cell: ({ row:{original} }) => {
          let status = _getStatus(original)
          return (
            <div className="badge-box">
              <Badge variant={status} className='badge-style'>{status}</Badge>
            </div>
          )
        }
      }   
    ],
    [],
  );
  return ( 
    <>   
      <DataTable 
        columns={columns} 
        data={props.data || []} 
        tableStyles={tableStyles} 
        paginationConfig={paginationConfig}
        handlePaginationChange={props.handlePaginationChange}
      />
      <input id="textcopy" className="link-input" style={{'display':'none'}}/>
    </>
  );
}

export default PaymentLinksTable;
import {
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  requestGoalDetail,
  requestSendPennyDrop,
  createContractRequest,
} from 'src/redux/actions';
import { connect } from 'react-redux';

import ReviewPayLongKyc from './review-pay-long-kyc';

function mapStateToProps(state) {
  return {
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
    kyc: state.kyc,
    taxStatuses: state.taxStatuses,
  };
}

export default connect(mapStateToProps, {
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  requestGoalDetail,
  requestSendPennyDrop,
  createContractRequest,
})(ReviewPayLongKyc);

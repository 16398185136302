import React, { Component } from 'react';
import { VideoRecording, Loader } from 'src/components';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { ApiRequest } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import styles from './video-verification.module.scss';

class VideoVerification extends Component {
  state = {
    loading: false,
    transactionId: null,
    videoOtp: null,
    videoUrl: '',
  };

  componentDidMount() {
    this.startVideoRequestSubscription = EventBus.on(
      ACTION_TYPES.START_VIDEO_VERIFICATION_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.startVideoRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.START_VIDEO_VERIFICATION_SUCCESS,
      this._onStartedVideoVerification,
    );
    this.startVideoRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.START_VIDEO_VERIFICATION_FAILED,
      () => this.setState({ loading: false }),
    );

    this.uploadVideoRequestSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.uploadVideoRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_SUCCESS,
      this._onUploadedVideoVerification,
    );
    this.uploadVideoRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_FAILED,
      () => this.setState({ loading: false }),
    );

    this.props.startVideoVerificationRequest();
    this.setState({ videoUrl: this.props?.kyc?.video?.video_url });
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.startVideoRequestSubscription();
    this.startVideoRequestSuccessSubscription();
    this.startVideoRequestFailureSubscription();

    this.uploadVideoRequestSubscription();
    this.uploadVideoRequestSuccessSubscription();
    this.uploadVideoRequestFailureSubscription();
  };

  _onStartedVideoVerification = (res) => {
    this.setState({ loading: false, transactionId: res.transactionId, videoOtp: res.randomNumber });
  };

  _onUploadedVideoVerification = () => {
    this.setState({ loading: false }, () => {
      this.props.onGoto('selfie');
    });
  };

  _onVideoChanged = (video) => {
    this.setState({ loading: true });

    ApiRequest.uploadToSignzy(video, 'video.mp4')
      .then((res) => {
        this.setState({ loading: false, videoUrl: res?.data?.file?.directUrl }, () => {
          this.props.updateKycParams({ video: { video_url: res.data.file.directUrl } });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log('UPLOAD_ERROR:', error);
      });
  };

  _onVideoVerificationSubmit = () => {
    this.props.uploadVideoVerificationRequest({
      video_url: this.state.videoUrl,
      transaction_id: this.state.transactionId,
    });
  };

  render() {
    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['another-account']}>
          <div className={styles['pay-content']}>
            <div className={styles['title']}>Verify your KYC Start Saving</div>
            <div className={styles['line']} />
            <div className={styles['sub-title']}>VIDEO VERIFICATION</div>
            <div className={cx(styles['mobile-space'], styles['mobile'])}>
              <div className={cx(styles['line-mob'])} />
            </div>
            <div className={cx('pb-3 pt-4', styles['form-box'], styles['mobile-space'])}>
              <VideoRecording
                otp={this.state.videoOtp}
                source={this.state.videoUrl}
                onVideoChanged={this._onVideoChanged}
              />
            </div>
          </div>
          <button
            className={styles['footer']}
            type="submit"
            disabled={!this.state.videoUrl || this.state.loading}
            onClick={this._onVideoVerificationSubmit}
          >
            {this.state.loading ? 'LOADING...' : 'NEXT'}
          </button>
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default withRouter(VideoVerification);

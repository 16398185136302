import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, SavvySupport, SignInSignUpModal } from 'src/components';
import moment from 'moment';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Convert } from 'src/helpers';
import { notify } from 'react-notify-toast';
import _ from 'lodash';
import cx from 'classnames';
import styles from './make-payment.module.scss';
import OfferBox from '../pay/offer-box';
// import ExtraInfo from '../extra-info';
const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

class MakePayment extends Component {
  state = {
    loading: false,
    showLoginModal: false,
  };

  componentDidMount() {
    if (!this.props.user.uuid) {
      this.setState({ showLoginModal: true });
    }

    this.requestPaymentSubscription = EventBus.on(ACTION_TYPES.REQUEST_GENERATE_PAYMENT, () =>
      this.setState({ loading: true }),
    );
    this.paymentSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.GENERATE_PAYMENT_SUCCESS,
      this._onPaymentGenerated,
    );
    this.paymentSubscriptionFailure = EventBus.on(ACTION_TYPES.GENERATE_PAYMENT_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestPaymentSubscription();
    this.paymentSubscriptionSuccess();
    this.paymentSubscriptionFailure();
  }

  _onPaymentGenerated = ({ payment, orderId }) => {
    const { user, partner, savingPlan, savingGoal } = this.props;
    const amount = Number(payment.amount) * 100; // send it as cents ex: 100Rs as 10000 cents

    const options = {
      key: RAZORPAY_KEY,
      amount,
      currency: 'INR',
      name: partner.name,
      description: savingGoal?.productOffer?.productOfferName,
      image: partner.logoImageLink,
      order_id: orderId,
      callback_url: `${API_ENDPOINT_URL}/api/partners/razorpay/payments/redirect`,
      prefill: {
        name: user.firstName,
        email: user.email,
        contact: user.phoneNumber,
      },
      theme: {
        color: '#ffd401',
      },
    };

    this.setState({ loading: false }, () => {
      this.props.updateSavingPlan({
        ...savingPlan,
        paymentId: payment.id,
        paymentRequestId: payment.requestId,
      });

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  };

  _onProceedPayment = () => {
    if (!this.props.user.uuid) {
      this.setState({ showLoginModal: true });
    } else {
      const missedPayment = this.getMissedPaymentData();
      if (missedPayment?.amount <= 0) {
        notify.show(`Amount must be greater than ${Convert.toCurrencyValue(0)}`, 'error');
        return;
      }
      this._onProceedBooking();
    }
  };

  _onProceedBooking = () => {
    const { savingGoal } = this.props;
    const missedPayment = this.getMissedPaymentData();
    this.props.requestGeneratePayment({
      payment: {
        amount: missedPayment?.amount || 0,
        info: savingGoal?.productOffer?.productOfferName,
        redirect_url: `${origin}/checkout`,
      },
    });
  };

  _onCloseSignInModal = () => {
    this.setState({ showLoginModal: false });
  };

  _onSignInComplete = () => {
    this.setState({ showLoginModal: false }, () => {
      this._onProceedPayment();
    });
  };

  getMissedPaymentData = () => {
    let missedPaymentStructure = {
      amount: 0,
      date: '',
      paymentNumber: '',
    };
    const { savingGoal } = this.props;
    const savingGoalContributions = savingGoal?.savingGoalContributions || [];
    const paymentCalendar = savingGoal?.paymentCalendar || [];
    let formattedPaymentCalendarDates = [];

    if (savingGoalContributions && savingGoalContributions.length > 0) {
      savingGoalContributions.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }
        return 0;
      });
    }
    const paymentCalendarKeys = Object.keys(paymentCalendar || {});
    formattedPaymentCalendarDates = paymentCalendarKeys.map((calendar) => ({
      date: moment(calendar, 'DDMMYYYY').format('YYYY/MM/DD'),
      amount: paymentCalendar[calendar],
    }));

    if (formattedPaymentCalendarDates && formattedPaymentCalendarDates.length > 0) {
      formattedPaymentCalendarDates.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
    }
    formattedPaymentCalendarDates.shift();
    formattedPaymentCalendarDates = formattedPaymentCalendarDates.filter(
      (data) => data.date <= moment().format('YYYY/MM/DD'),
    );

    const formattedSavingGoalContributionsDates = savingGoalContributions.map((goal) => ({
      ...goal,
      createdAt: moment(goal?.createdAt).format('YYYY/MM/DD'),
    }));

    if (formattedPaymentCalendarDates && formattedPaymentCalendarDates.length > 0) {
      formattedPaymentCalendarDates.some((data, index) => {
        const contribution = formattedSavingGoalContributionsDates.find(
          (contribution) => contribution?.createdAt == data.date,
        );
        if (!contribution) {
          missedPaymentStructure = {
            ...missedPaymentStructure,
            amount: data.amount,
            date: data.date,
            paymentNumber: index + 1,
          };
          return true;
        }
        return false;
      });
    }

    return missedPaymentStructure;
  };

  _ordinalSuffix(i) {
    const j = i % 10;
    const k = i % 100;

    if (j == 1 && k != 11) {
      return `${i}st`;
    }
    if (j == 2 && k != 12) {
      return `${i}nd`;
    }
    if (j == 3 && k != 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  render() {
    const { loading } = this.state;
    const { partner, product, user, savingGoal } = this.props;
    const missedPayment = this.getMissedPaymentData();
    const totalContribution = _.sumBy(
      savingGoal?.savingGoalContributions,
      (contribution) =>
        (contribution.status === 'completed' || contribution.status == 'pending_investment') &&
        Number(contribution.amount),
    );
    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={styles['pay-content']}>
              <div className={cx(styles['title'])}>
                Make your payment{' '}
                <span className={styles['mobile']}>
                  of {Convert.currency(missedPayment?.amount || 0)}
                </span>
              </div>
              <div className={cx(styles['note'], styles['mobile'])}>
                for {savingGoal?.productOffer?.productOfferName}
              </div>
              <div className={cx(styles['progress-bar'], styles['mobile'])}>
                <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
              </div>
              <div className={cx(styles['pay-in-installment'], styles['mobile'])}>
                PAY IN INSTALLMENTS{' '}
              </div>
              <div className={cx(styles['line-mob'], styles['desktop'])} />
              <div className={cx(styles['rupee'], styles['desktop'])}>
                {Convert.currency(missedPayment?.amount || 0)}
              </div>
              <div className={cx(styles['sub-title'], styles['desktop'])}>AMOUNT TO BE PAID</div>
              <div className={styles['payment-description']}>
                This is the {this._ordinalSuffix(missedPayment?.paymentNumber || 0)} payment for{' '}
                {savingGoal?.productOffer?.productOfferName} to be paid to{' '}
                {savingGoal?.productOffer?.partner?.name}
              </div>
              <div className={cx(styles['line-mob'], styles['desktop'])} />
              <div className={cx(styles['sub-title'], styles['payment-title'])}>
                PAYMENT HISTORY
              </div>
              <div className={styles['payment-rupee']}>
                {Convert.currency(totalContribution || 0)}
              </div>
              <div className={styles['paid']}>PAID SO FAR</div>
            </div>
            <div className={styles['footer']} onClick={this._onProceedPayment}>
              PAY
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox partner={partner} product={product} user={user} />
          </div>
        </div>
        <SignInSignUpModal
          isShow={this.state.showLoginModal}
          closeModal={this._onCloseSignInModal}
          onComplete={this._onSignInComplete}
        />
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(MakePayment);

import { connect } from 'react-redux';
import DashboardProtectedRoute from './dashboard-protected-route';

function mapStateToProps({ credentials, user }) {
  const { partnerAccessKey, userIdentityToken } = credentials;
  const { uuid } = user;

  return {
    partnerAccessKey,
    userIdentityToken,
    uuid,
  };
}

export default connect(mapStateToProps, {})(DashboardProtectedRoute);

import React, { Component } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import styles from './_product-banner.module.scss';

class ProductImage extends Component {
  state = {
    loaded: false,
  };

  _onClickImage = () => {
    const { image, index } = this.props;
    this.props.onClick({ image, index });
  };

  render() {
    return (
      <div className={styles['product-image']}>
        {this.state.loaded ? (
          <button className={styles['product-image-button']} onClick={this._onClickImage}>
            <div
              className={styles['product-image-content']}
              style={{ backgroundImage: `url(${this.props.image})` }}
            />
          </button>
        ) : (
          <div className={styles['product-image-content']}>
            <img
              src={this.props.image}
              style={{ display: 'none' }}
              onLoad={() => this.setState({ loaded: true })}
              alt="Product"
            />
            <img className={styles['loader-image']} src="/img/loader.gif" alt="loader" />
          </div>
        )}
      </div>
    );
  }
}

class ProductBanner extends Component {
  state = {
    selectedIndex: 0,
    showSlideShow: false,
  };

  _onClickProductImage = ({ index }) => {
    this.setState({ showSlideShow: true, selectedIndex: index });
  };

  _handleSlide = (selectedIndex) => {
    this.setState({ selectedIndex });
  };

  _onHide = () => {
    this.setState({ showSlideShow: false });
  };

  render() {
    const { images } = this.props;

    return (
      <div className={styles['product-banner']}>
        {images.length === 1 ? (
          <div className={styles['product-image-center']}>
            <ProductImage image={images[0].url} index={0} onClick={this._onClickProductImage} />
          </div>
        ) : null}
        {images.length === 2 ? (
          <>
            <div className={styles['product-image-left']} style={{ width: '50%' }}>
              <ProductImage image={images[0].url} index={0} onClick={this._onClickProductImage} />
            </div>
            <div className={styles['product-image-right']} style={{ width: '50%' }}>
              <ProductImage image={images[1].url} index={1} onClick={this._onClickProductImage} />
            </div>
          </>
        ) : null}
        {images.length === 3 ? (
          <>
            <div className={styles['product-image-left']}>
              <ProductImage image={images[0].url} index={0} onClick={this._onClickProductImage} />
            </div>
            <div className={styles['product-image-right']}>
              <div className={styles['product-image-row']}>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[1].url}
                    index={1}
                    onClick={this._onClickProductImage}
                  />
                </div>
              </div>
              <div className={styles['product-image-row']}>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[2].url}
                    index={2}
                    onClick={this._onClickProductImage}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {images.length === 4 ? (
          <>
            <div className={styles['product-image-right']} style={{ width: '100%' }}>
              <div className={styles['product-image-row']}>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[0].url}
                    index={0}
                    onClick={this._onClickProductImage}
                  />
                </div>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[1].url}
                    index={1}
                    onClick={this._onClickProductImage}
                  />
                </div>
              </div>
              <div className={styles['product-image-row']}>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[2].url}
                    index={2}
                    onClick={this._onClickProductImage}
                  />
                </div>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[3].url}
                    index={3}
                    onClick={this._onClickProductImage}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {images.length >= 5 ? (
          <>
            <div className={styles['product-image-left']}>
              <ProductImage image={images[0].url} index={0} onClick={this._onClickProductImage} />
            </div>
            <div className={cx(styles['product-image-right'], styles['fifth'])}>
              <div className={styles['product-image-row']}>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[1].url}
                    index={1}
                    onClick={this._onClickProductImage}
                  />
                </div>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[2].url}
                    index={2}
                    onClick={this._onClickProductImage}
                  />
                </div>
              </div>
              <div className={styles['product-image-row']}>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[3].url}
                    index={3}
                    onClick={this._onClickProductImage}
                  />
                </div>
                <div className={styles['product-image-wrapper']}>
                  <ProductImage
                    image={images[4].url}
                    index={4}
                    onClick={this._onClickProductImage}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        <Modal
          dialogClassName={styles['slideshow-modal']}
          show={this.state.showSlideShow}
          onHide={this._onHide}
        >
          <button onClick={this._onHide} className={styles['close-button']}>
            <FontAwesomeIcon icon={faTimesCircle} className={styles['close-button-icon']} />
          </button>
          <Carousel
            activeIndex={this.state.selectedIndex}
            onSelect={this._handleSlide}
            touch
            indicators
            keyboard
            fade
          >
            {images &&
              images.length > 0 &&
              images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className={cx('d-block w-100', styles['slide-image'])}
                    src={image.url}
                    alt={`Slide ${index}`}
                  />
                </Carousel.Item>
              ))}
          </Carousel>
        </Modal>
      </div>
    );
  }
}

export default ProductBanner;

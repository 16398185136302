import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminAgentCreate(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.ADMIN_AGENTS,
    data
  }).then((response) => response.data);
}

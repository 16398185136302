import React, { Component } from 'react';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import moment from 'moment';
import { TransactionsTable } from 'src/components/tables';
import { withRouter } from 'react-router-dom';
import styles from './_admin-transactions.module.scss';

class AdminTransactions extends Component {
  state = {
    loading: false,
    pageNumber: 1,
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminTransactionList({
          page: pageNumber,
        }),
    );
  };

  componentDidMount() {
    this.requestAdminTransactionList = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST,
      () => this.setState({ loading: true }),
    );

    this.requestAdminTransactionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST_SUCCESS,
      () => this.setState({ loading: false }),
    );

    this.requestAdminTransactionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST_FAILED,
      () => this.setState({ loading: false }),
    );

    this.props.requestAdminTransactionList({
      page: this.state.pageNumber,
    });
  }

  componentWillUnmount() {
    this.requestAdminTransactionList();
    this.requestAdminTransactionListSuccess();
    this.requestAdminTransactionListFailed();
  }

  /**
   * add a label to the transactions array
   * example: 'Deposited' for savingsDeposit data, 'withdrwal' for withdrawals data
   * @param {array} data
   * @param {string} labelName
   * @returns {array}
   */
  _addTransactionLabel = (data, labelName) => {
    const labeledData = data.map((val) => {
      val['label'] = labelName;
      return val;
    });

    return labeledData;
  };

  /**
   * Raw data in the form of object of arrays is received which needs to be merged
   * and sorted according to date
   *
   * @param {object} transactionsData
   * @returns {array}
   */
  _organizeTransactionsData = (transactionsData) => {
    let { deposits = [], withdrawals = [], payments = [] } = transactionsData;
    deposits = this._addTransactionLabel(deposits, 'Deposited');
    withdrawals = this._addTransactionLabel(withdrawals, 'Withdrawn');
    payments = this._addTransactionLabel(payments, 'Payments');

    let organizedData = [...deposits, ...withdrawals, ...payments];

    return organizedData.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
    );
  };

  render() {
    let { transactions } = this.props;
    const { depositsTotal, paymentsTotal, withdrawalsTotal } = this.props.transactions;
    const totalItems = Number(depositsTotal + paymentsTotal + withdrawalsTotal);
    transactions = this._organizeTransactionsData(transactions);

    return (
      <div>
        <h3 className={styles['title']}>Transactions</h3>
        <TransactionsTable
          data={transactions}
          handlePaginationChange={this._handlePaginationChange}
          totalItems={totalItems}
        />
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default withRouter(AdminTransactions);

import React from 'react';
import Select from 'react-select';
import cx from 'classnames';
import './_asyncSelectInput.scss';

export default function SelectInput(props) {
  return (
    <div className={cx('async-select', props.customClassnames)}>
      <Select
        {...props}
        // className={cx(styles['main-container'], props.className)}
        className="main-container"
        classNamePrefix="main-container"
      />
    </div>
  );
}

import React, { Component } from 'react'
import { CtaTwo, SecuredPartners, BootstrapModal, DrawerModal } from 'src/components'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { TextInputThree } from 'src/components/form-inputs';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup'
import Countdown from 'react-countdown';
import { Player } from '@lottiefiles/react-lottie-player';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import ReviewDemoGraphicInfo from './review-demographic-info';
import ReviewPanCard from './review-pan-card';
import ReviewAddressProof from './review-address-proof';
import ReviewSignature from './review-signature';
import ReviewVideo from './review-video';
import ReviewSelfie from './review-selfie';
import styles from './_reviewsummary.module.scss'

class ReviewSummary extends Component {
  state = {
    showPennyCheck: false,
    loading: false
  }

  _validationSchema = yup.object().shape({
    account_number: yup.string().required('Please enter your account number'),
    ifsc_code: yup.string().required('Please enter your IFSC Code')
  })


  componentDidMount() {
    this.sendPennyDropRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_SEND_PENNY_DROP, () => this.setState({ showPennyCheck: true }));
    this.sendPennyDropSuccessSubscription = EventBus.on(ACTION_TYPES.REQUEST_SEND_PENNY_DROP_SUCCEEDED, this._onsendPennyDropSuccess);
    this.sendPennyDropFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_SEND_PENNY_DROP_FAILED, () => this.setState({ showPennyCheck: false }));    

    this.createContractRequestSubscription = EventBus.on(ACTION_TYPES.CREATE_CONTRACT_REQUEST, () => this.setState({ loading: true }));
    this.createContractSuccessSubscription = EventBus.on(ACTION_TYPES.CREATE_CONTRACT_SUCCESS, this._onCreateContractSuccess);
    this.createContractFailureSubscription = EventBus.on(ACTION_TYPES.CREATE_CONTRACT_FAILED, () => this.setState({ loading: false }));
  }

  componentWillUnmount() {
    this.sendPennyDropRequestSubscription();
    this.sendPennyDropSuccessSubscription();
    this.sendPennyDropFailureSubscription();

    this.createContractRequestSubscription();
    this.createContractSuccessSubscription();
    this.createContractFailureSubscription();
  }

  _onPennyCheck = ({ account_number, ifsc_code }) => {
    this.props.requestSendPennyDrop({ 
      bank_account_number: account_number, 
      ifsc_code 
    });
  }

  _onsendPennyDropSuccess = () => {
    this.setState({ showPennyCheck: false }, () => {
      this.props.createContractRequest();
    });
  }

  _onCreateContractSuccess = ({ url }) => {
    window.location.href = url;
  }

  render() {
    const { user, kyc } = this.props;

    return (
      <>
        <div className={styles.container}>
          <header className={styles.containerHeader}>
            <span className={styles.containerHeading}>
              Review Your Details and Start Saving!
            </span>
          </header>          
          <section className={styles.section}>
            <ReviewDemoGraphicInfo
              heading="Demographic Information"                  
              className={styles.customCardTwoContainer}
              {...user}
              {...kyc}
            />
          </section>
          <section className={styles.section}>
            <ReviewPanCard
              heading="PAN Card"                  
              className={styles.customCardTwoContainer}                  
              {...kyc}
              onClick={this.props.onComplete}
            />
          </section>
          <section className={styles.section}>
            <ReviewAddressProof
              heading="Address Proof"                  
              className={styles.customCardTwoContainer}                  
              {...kyc}
              onClick={this.props.onComplete}
            />
          </section>
          <section className={styles.section}>
            <ReviewSignature
              heading="Signature"                  
              className={styles.customCardTwoContainer}                  
              {...kyc}
              onClick={this.props.onComplete}
            />
          </section>
          <section className={styles.section}>
            <ReviewVideo
              heading="Video"                  
              className={styles.customCardTwoContainer}                  
              {...kyc}
              onClick={this.props.onComplete}
            />
          </section>
          <section className={styles.section}>
            <ReviewSelfie
              heading="Selfie"                  
              className={styles.customCardTwoContainer}                  
              {...kyc}
              onClick={this.props.onComplete}
            />
          </section>
          <Formik
            initialValues={{
              account_number: '',
              ifsc_code: ''
            }}
            validationSchema={this._validationSchema}
            onSubmit={this._onPennyCheck}
          >
            <FormikForm>
              <section className={styles.section}>                
                <div className={styles.sectionHeading}>
                  Bank Account Detail
                </div>                
                <Row>
                  <Col lg={6}>
                    <div className={styles['demo-info-group-input']}>
                      <TextInputThree
                        mandatory
                        label="Account Number"
                        name="account_number"
                        id="account_number"
                        inputstyles={styles['demo-info-input']}
                        labelstyles={styles['.demo-info-label']}
                        placeholder="Enter account number"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className={styles['demo-info-group-input']}>
                      <TextInputThree
                        mandatory
                        label="IFSC Code"
                        name="ifsc_code"
                        id="ifsc_code"
                        inputstyles={styles['demo-info-input']}
                        labelstyles={styles['.demo-info-label']}
                        placeholder="Enter IFSC code"
                        // mask="ICICI1000020"
                      />
                    </div>
                  </Col>
                </Row>
              </section>              
              <div className={styles.buttonContainer}>
                <CtaTwo
                  type="submit"
                  text="Submit My Details"
                  className={styles['next-btn']}
                  loading={this.state.loading}
                />
                <SecuredPartners styles={{
                  marginTop: '1rem'
                }}/>
              </div>
            </FormikForm>
          </Formik>
          {window.innerWidth > 768 ? (
            <BootstrapModal show={this.state.showPennyCheck} className="custom-modal">
              <div className="penny-check-box">
                <div className="title">PENNY CHECK</div>
                <div className="description">
                  We are depositing a small amount into your <br />
                  account to verify it
                </div>
                <Player
                  autoplay
                  loop
                  keepLastFrame
                  src="https://assets6.lottiefiles.com/packages/lf20_5xjxnhli.json"
                  className="sand-clock"
                />
                <div className="timer">
                  This may take upto{' '}
                  <Countdown date={Date.now() + 10000} renderer={({ seconds }) => seconds} /> seconds
                </div>
              </div>
            </BootstrapModal>
          ) : (
            <DrawerModal show={this.state.showPennyCheck} className={styles['mobile-penny-check']}>
              <div className={styles['title']}>PENNY CHECK</div>
              <div className={styles['description']}>
                We are depositing a small amount <br />
                into your account to verify it
              </div>
              <Player
                autoplay
                loop
                keepLastFrame
                src="https://assets6.lottiefiles.com/packages/lf20_5xjxnhli.json"
                className={styles['sand-clock-mobile']}
              />
              <div className={styles['timer']}>
                This may take upto{' '}
                <Countdown date={Date.now() + 10000} renderer={({ seconds }) => seconds} /> seconds
              </div>
            </DrawerModal>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(ReviewSummary)

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Convert } from 'src/helpers';
import cx from 'classnames';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { CollectMoney, Loader } from 'src/components';
import _ from 'lodash';
import styles from './_user-collect-request.module.scss';

class UserCollectRequest extends Component {
  state = {
    isCollectModalOpen: false,
    isDetailOpen: false,
    isLoading: false,
    fetching: false
  };

  componentDidMount() {
    const { match } = this.props;

    this.requestCollectionList = EventBus.on(ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL, () =>
      this.setState({ fetching: true }),
    );
    this.requestCollectionListSuccess = EventBus.on(ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL_SUCCESS, () =>
      this.setState({ fetching: false }),
    );
    this.requestCollectionListFailed = EventBus.on(ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL_FAILED, () => 
      this.setState({ fetching: false }),
    );

    this.requestAcceptCollectionList = EventBus.on(ACTION_TYPES.REQUEST_COLLECTION_ACCEPT, () =>
      this.setState({ isLoading: true }),
    );
    this.requestAcceptCollectionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_ACCEPT_SUCCESS,
      this.onCollectionAcceptSuccess,
    );
    this.requestAcceptCollectionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_ACCEPT_FAILED,
      this.onCollectionAcceptFailed,
    );

    this.props.requestUserCollectionsDetail({ id: match?.params?.uuid });
  }

  componentWillUnmount() {
    this.requestCollectionList();
    this.requestCollectionListSuccess();
    this.requestCollectionListFailed();

    this.requestAcceptCollectionList();
    this.requestAcceptCollectionListSuccess();
    this.requestAcceptCollectionListFailed();
  }

  onCollectionAcceptSuccess = () => {
    this.openCollectModal();
    this.setState({ isLoading: false });
  };

  onCollectionAcceptFailed = () => {
    this.setState({ isLoading: false });
  };

  closeCollectModal = (success = false) => {
    this.setState({ isCollectModalOpen: false }, () => {
      if (success) this.props.history.push('/dashboard');
    });
  };

  openCollectModal = () => {
    this.setState({ isCollectModalOpen: true });
  };

  openDetail = () => {
    this.setState({ isDetailOpen: true });
  };

  handleApprovePayment = () => {
    const { requestAdminCollectionsAccept, match } = this.props;
    requestAdminCollectionsAccept({ id: match?.params?.uuid });
  };

  render() {
    const { isCollectModalOpen, isDetailOpen, isLoading } = this.state;
    const { collectionDetail } = this.props;
    const { savingGoal = {} } = collectionDetail || {};
    const { productOffer, goalAbsoluteAmount, investmentPlusPendingBalance, savingGoalContributions } = savingGoal;
    const totalOfferCost = Number(goalAbsoluteAmount || 0) + Number(productOffer?.upfrontPaymentAmount || 0);
    const investedAmount = Number(investmentPlusPendingBalance || 0);
    const totalAmountSaved = Number(productOffer?.upfrontPaymentAmount || 0) + investedAmount;
    const totalContribution = _.sumBy(savingGoalContributions, (contribution) => (contribution.status === 'completed' || contribution.status == 'pending_investment') && Number(contribution.amount));
    const interestEarned = investedAmount - totalContribution;

    return (
      <div className={styles['user-collect-request']}>
        <div className={styles['title']}>
          Please redeem your savings and pay for &#34;{productOffer?.productOfferName}&#34;
        </div>
        <div className={styles['card-group']}>
          <div className={styles['card']}>
            <div className={styles['card-title']}>Total Cost of Offer</div>
            <div className={styles['card-footer']}>{Convert.currency(totalOfferCost)}</div>
          </div>
          <div className={styles['card']}>
            <div className={styles['card-title']}>You have already paid</div>
            <div className={styles['card-footer']}>{Convert.currency(totalAmountSaved)}</div>
          </div>
          <div className={styles['card']}>
            <div className={styles['card-title']}>Amount in Savings</div>
            <div className={styles['card-footer']}>{Convert.currency(investmentPlusPendingBalance || 0)}</div>
          </div>
          <div className={styles['card']}>
            <div className={styles['card-title']}>
              Interest <br /> Earned
            </div>
            <div className={styles['card-footer']}>{Convert.currency(interestEarned > 0 ? interestEarned : 0)}</div>
          </div>
        </div>
        <div className={styles['amount']}>
          <div className={styles['title']}>
            Amount Requested by <br /> &#34;{productOffer?.partner?.name}&#34;
          </div>
          <div className={styles['desc']}>
            {Convert.toCurrencyValue(collectionDetail?.requestedAmount)}
          </div>
        </div>
        <div className={styles['custom-btn-group']}>
          <button
            onClick={this.handleApprovePayment}
            disabled={isLoading}
            className={cx(styles['custom-btn'], {
              [styles['disabled-btn']]: isLoading,
            })}
          >
            {isLoading ? 'Loading...' : 'APPROVE PAYMENT & SEND OTP'}
          </button>
        </div>
        <div
          className={cx(styles['redeem-screen'], {
            [styles['redeem-screen-active']]: isDetailOpen,
          })}
        >
          <div
            className={cx(styles['redeem-screen-content'], {
              [styles['screen-content']]: isDetailOpen,
            })}
          >
            {/* <div className={styles['heading']} onClick={this.openDetail}>
              How will the money be transferred?
            </div> */}
            {isDetailOpen && (
              <div className={styles['transfer']}>
                <div className={styles['content-box']}>
                  <div className={styles['number']}>1</div>
                  <div className={styles['sub-heading']}>
                    Rs. 10,500 will be redeemed from your savings and credited to your linked bank
                    account
                  </div>
                </div>
                <div className={styles['icon']}>
                  <img src="/img/arrow-right-2.svg" alt="arrow-i" />
                </div>
                <div className={styles['content-box']}>
                  <div className={styles['number']}>1</div>
                  <div className={styles['sub-heading']}>
                    Rs. 10,500 will transferred to [merchant name] within 2-3 business days.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isCollectModalOpen && (
          <CollectMoney isModalOpen={isCollectModalOpen} closeModal={this.closeCollectModal} />
        )}
        <Loader loading={this.state.fetching} />
      </div>
    );
  }
}

export default withRouter(UserCollectRequest);

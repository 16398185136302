import { connect } from 'react-redux';
import OfferDetailItem from './offer-detail-item';

function mapStateToProps(state) {
  return {
    user: state.user,
    totalPax: state.savingPlan.totalPax || 1,
    partner: state.partner,
  };
}

export default connect(mapStateToProps, {})(OfferDetailItem);

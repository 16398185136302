import { connect } from 'react-redux'
import LongKyc from './LongKyc'

function mapStateToProps(state){
  return {
    user: state.user,    
    kyc: state.kyc
  };
}

export default connect(mapStateToProps, {
  
})(LongKyc)

import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Screen, Loader, ResponsiveTab } from 'src/components';

import styles from './_dashboard.module.scss';

class Dashboard extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.requestGoalList = EventBus.on(ACTION_TYPES.REQUEST_GOAL_LIST, () =>
      this.setState({ loading: true }),
    );
    this.requestGoalListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_GOAL_LIST_SUCCESS,
      this._computeGoalsStats,
    );
    this.requestGoalListFailure = EventBus.on(ACTION_TYPES.REQUEST_GOAL_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestGoalList();
    this.requestGoalListSuccess();
    this.requestGoalListFailure();
  }

  _onChangeTab = () => {};

  _onLogout = () => {};

  handleRoutes = ({ link }) => {
    const { history } = this.props;
    history.push(link);
  };

  getSelectedRoute = () => {
    const { match } = this.props;
    if (match.path === '/dashboard') {
      return 'overview';
    }
    if (match.path === '/dashboard/progress') {
      return 'goal';
    }
    if (match.path === '/dashboard/progress/:goalId') {
      return 'goal';
    }
    if (match.path === '/dashboard/transactions') {
      return 'account';
    }
    if (match.path === '/dashboard/fund') {
      return 'fund';
    }
  };

  render() {
    const { children } = this.props;
    const activeKey = this.getSelectedRoute();
    const { match } = this.props;
    const isCollectScreen = match.path === '/dashboard/collect-request/:uuid';
    return (
      <Screen contentClassName={styles['content-container']}>
        <div className={styles['dashboard']}>
          {isCollectScreen ? (
            children
          ) : (
            <ResponsiveTab defaultKey="overview" activeKey={activeKey} onChange={this._onChangeTab}>
              <ResponsiveTab.Content title="Overview" eventKey="overview" link="/dashboard">
                {activeKey === 'overview' && children}
              </ResponsiveTab.Content>
              <ResponsiveTab.Content
                title="Purchase Progress"
                eventKey="goal"
                link="/dashboard/progress"
              >
                {activeKey === 'goal' && children}
              </ResponsiveTab.Content>
              <ResponsiveTab.Content
                title="Transaction & Account Details"
                eventKey="account"
                link="/dashboard/transactions"
              >
                {activeKey === 'account' && children}
              </ResponsiveTab.Content>
              <ResponsiveTab.Content
                title="Fund Information"
                eventKey="fund"
                link="/dashboard/fund"
              >
                {activeKey === 'fund' && children}
              </ResponsiveTab.Content>
            </ResponsiveTab>
          )}
          <button className={styles['logout']} onClick={this._onLogout}>
            Log Out
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
          <Loader loading={this.state.loading} />
        </div>
      </Screen>
    );
  }
}

export default withRouter(Dashboard);

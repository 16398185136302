import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { Convert } from 'src/helpers';
import styles from './_fund-info.module.scss';

class FundInfo extends Component {
  state = {
    amount: 5000,
    returnPercent: 3.3,
  };

  calculateSavedAmount = () => {
    const { amount, returnPercent } = this.state;
    const savedAmount = (amount * returnPercent) / 100 + amount;
    return savedAmount;
  };

  render() {
    const savedAmount = this.calculateSavedAmount();
    const { amount, returnPercent } = this.state;
    return (
      <div className={cx(styles['fund-info'], { [styles['overview']]: this.props.overview })}>
        {this.props.overview ? <div className={styles['heading']}>Fund Details</div> : null}
        <div className={styles['wrapper']}>
          <div className={styles['top-container']}>
            <div className={styles['title']}>ICICI Prudential Liquid Mutual Fund</div>
            <div className={styles['details']}>
              <div className={styles['invested']}>
                <div className={styles['year-ago']}>1 year ago:</div>
                <div className={styles['if-saved']}>If you saved</div>
                <div className={styles['amount']}>{Convert.toCurrencyValue(amount)}</div>
                {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                {this.props.overview ? (
                  <img src="/img/right-arrow.png" alt="right" className={styles['chevron-icon']} />
                ) : (
                  <img
                    src="/img/right-arrow2.png"
                    alt="right"
                    className={cx(styles['chevron-icon'], styles['chevron-icon-2'])}
                  />
                )}
              </div>
              <div className={styles['become']}>
                <div className={styles['year-ago']}>1 year ago:</div>

                <div className={styles['have-become']}>It would have become</div>
                <div className={styles['amount']}>{Convert.toCurrencyValue(savedAmount)}</div>
              </div>
            </div>
          </div>
          <div className={styles['bottom-container']}>
            <div className={styles['risk']}>
              <div>
                <img src="/img/lowrisk.png" alt="High risk" />
              </div>
              <div>LOW RISK</div>
            </div>
            <div className={styles['returns']}>
              <div className={styles['percentage']}>{returnPercent}%</div>
              <div className={styles['year-returns']}>1Y RETURN</div>
            </div>
            <div className={styles['suitable']}>
              <div>
                <img src="/img/suitable.png" alt="Suitable for" />
              </div>
              <div>SUITABLE FOR</div>
            </div>
            <div className={styles['dropdown']} />
          </div>
        </div>
      </div>
    );
  }
}

export default FundInfo;

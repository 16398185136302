import { ACTION_TYPES } from 'src/constants';

export function createFolio(payload) {
  return {
    type: ACTION_TYPES.CREATE_FOLIO,
    payload,
  };
}

export function createFolioSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_FOLIO_SUCCEEDED,
    payload,
  };
}

export function createFolioFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_FOLIO_FAILED,
    payload,
  };
}

export function updateFolio(payload) {
  return {
    type: ACTION_TYPES.UPDATE_FOLIO,
    payload,
  };
}

export function checkInstantWithdrawalRequest(payload) {
  return {
    type: ACTION_TYPES.CHECK_INSTANT_WITHDRAWAL_REQUEST,
    payload
  }
}

export function checkInstantWithdrawalSuccess(payload) {
  return {
    type: ACTION_TYPES.CHECK_INSTANT_WITHDRAWAL_SUCCESS,
    payload
  }
}

export function checkInstantWithdrawalFailure(payload) {
  return {
    type: ACTION_TYPES.CHECK_INSTANT_WITHDRAWAL_FAILED,
    payload
  }
}


export function folioWithdrawRequest(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_REQUEST,
    payload
  }
}

export function folioWithdrawSuccess(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_SUCCESS,
    payload
  }
}

export function folioWithdrawFailure(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_FAILED,
    payload
  }
}

export function folioWithdrawOTPRequest(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_OTP_REQUEST,
    payload
  }
}

export function folioWithdrawOTPSuccess(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_OTP_SUCCESS,
    payload
  }
}

export function folioWithdrawOTPFailure(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_OTP_FAILED,
    payload
  }
}

export function folioWithdrawResendOTPRequest(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_REQUEST,
    payload
  }
}

export function folioWithdrawResendOTPSuccess(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_SUCCESS,
    payload
  }
}

export function folioWithdrawResendOTPFailure(payload) {
  return {
    type: ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_FAILED,
    payload
  }
}

export function validateFolioWithdrawRequest(payload) {
  return {
    type: ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_REQUEST,
    payload
  }
}

export function validateFolioWithdrawSuccess(payload) {
  return {
    type: ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_SUCCESS,
    payload
  }
}

export function validateFolioWithdrawFailure(payload) {
  return {
    type: ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_FAILED,
    payload
  }
}

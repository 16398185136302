import { connect } from 'react-redux'
import { 
  checkInstantWithdrawalRequest, 
  folioWithdrawRequest, 
  folioWithdrawOTPRequest, 
  folioWithdrawResendOTPRequest, 
  validateFolioWithdrawRequest 
} from 'src/redux/actions';
import WithDrawnMoney from './withdrawn-money'

function mapStateToProps(state){
  const savingAccount = state.user.accounts.find(account => account.accountType === 'savings');

  return {
    user: state.user,
    bankAccount: state.user.depositBankAccount,
    savingAccount
  }
}

export default connect(mapStateToProps, {  
  checkInstantWithdrawalRequest,
  folioWithdrawRequest,
  folioWithdrawOTPRequest, 
  folioWithdrawResendOTPRequest, 
  validateFolioWithdrawRequest
})(WithDrawnMoney)
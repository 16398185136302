import { useField } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styles from '../_styles.module.scss';
import localStyles from './_inputtextarea.module.scss';

function InputTextarea({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <>
      <Row style={{ ...props.containerstyles }}>
        <Col md={12} className={styles.inputLabelContainer}>
          <label htmlFor={props.id || props.name} className={styles.inputLabel}>
            {label}
          </label>
        </Col>
        <Col md={12}>
          <Form.Control
            as="textarea"
            className={localStyles.textarea}
            rows={4}
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
        </Col>
      </Row>
    </>
  );
}

export default InputTextarea;

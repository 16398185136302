import React, { Component } from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { Convert } from 'src/helpers';
import { ContactUsModal, SignInSignUpModal } from 'src/components';
import { Heading, Text } from 'src/components/theme';
import OfferTabs from './offer-tabs';
import './_offer-detail-item.scss';

class OfferDetailItem extends Component {
  state = {
    isModalOpen: false,
    modalData: { title: '', description: '' },
    selectedOffer: {},
    isContactUsModalOpen: false,
    showLoginModal: false
  };

  handleModal = ({ title, description }) => {
    const modalData = { title, description };
    this.setState({ modalData, isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  closeContactUsModal = () => {
    this.setState({ isContactUsModalOpen: false });
  };

  openContactUsModal = () => {
    this.setState({ isContactUsModalOpen: true });
  };

  _onSignInComplete = () => {
    this.setState({ showLoginModal: false }, () => {
      this.props.onProceedFirstInstallment(this.state.selectedOffer);
    });
  }

  _onProceedFirstInstallment = (selectedOffer) => {
    if (!this.props.user.uuid) {
      this.setState({ selectedOffer, showLoginModal: true });
    } else {
      this.props.onProceedFirstInstallment(selectedOffer);
    }
  }

  render() {
    const { product, partner, isFromPlanPage } = this.props;
    const { isModalOpen, modalData, isContactUsModalOpen } = this.state;

    const textFields = product?.textFields || [];
    const dynamicFields = textFields.map((item) => {
      const content = Convert.toRawHtml(item.body);
      return {
        heading: item.heading,
        body: content,
        preview:
          content && content.length > 200 && content.slice(0, 200)
            ? `${content.slice(0, 200)}...`
            : content,
      };
    });

    return (
      <div className="offer-detail-item">
        <div className="offer-container">
          <Row>
            <Col lg={6}>
              {dynamicFields.map((item, index) => (
                <div className="left-box" key={index}>
                  <Heading variant="section" className="offer-title">
                    {item.heading}
                  </Heading>
                  {/* <div className="offer-description">
                    </div> */}
                  <Text
                    variant="p2"
                    dangerouslySetInnerHTML={{
                      __html: item.preview,
                    }}
                  />
                  <Text
                    variant="p2"
                    className="offer-view-more"
                    onClick={() =>
                      this.handleModal({
                        title: item.heading,
                        description: item.body,
                      })
                    }
                  >
                    View more
                  </Text>
                </div>
              ))}
              <div className="plane-contact-us">
                <img src="/img/plane.svg" alt="plane" />
                <div className="plane-contact-us-text" onClick={this.openContactUsModal}>
                  Need more information?
                  <br /> Contact us {partner?.name && `to ${partner?.name}`}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <OfferTabs
                product={this.props.product}
                user={this.props.user}
                onProceedFirstInstallment={this._onProceedFirstInstallment}
                isFromPlanPage={isFromPlanPage}
              />
            </Col>
          </Row>
        </div>
        <Modal className="detail-modal" show={isModalOpen} size="lg" onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title className="detail-header">
              <strong>{modalData?.title}</strong>
            </Modal.Title>
            <div className="close-btn">
              <span onClick={this.closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="product-html-content"
              dangerouslySetInnerHTML={{
                __html: modalData?.description,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.closeModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <ContactUsModal isShow={isContactUsModalOpen} closeModal={this.closeContactUsModal} />
        <SignInSignUpModal
          isShow={this.state.showLoginModal}
          closeModal={() => this.setState({ showLoginModal: false })}
          onComplete={this._onSignInComplete}
        />
      </div>
    );
  }
}

export default OfferDetailItem;

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Pagination, Table } from 'react-bootstrap';
import { usePagination, useSortBy, useTable } from 'react-table';
import './_datatable.scss';
import styles from './_datatable.module.scss';
import { CustomPagination } from '..';

const DataTable = (props) => {
  const { columns, data } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 50 } },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <div className={styles.tableContainer}>
        <Table {...getTableProps()} responsive className={styles.table}>
          <thead className={props.theadStyles}>
            {headerGroups &&
              headerGroups.length > 0 &&
              headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup?.headers &&
                    headerGroup?.headers.length > 0 &&
                    headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({
                            style: {
                              ...column?.styles,
                            },
                          }),
                        )}
                      >
                        <div className={styles.tableHeaderContainer}>
                          <span className={styles.tableHeader}>{column.render('Header')}</span>
                          {column.canSort &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
                              ) : (
                                <FontAwesomeIcon className={styles.icon} icon={faChevronUp} />
                              )
                            ) : (
                              <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
                            ))}
                        </div>
                      </th>
                    ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()} className={props.bodyStyles}>
            {page &&
              page.length > 0 &&
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    key={index}
                    {...row.getRowProps()}
                    className={props.bodyRowStyles}
                    style={{ ...props?.tableStyles?.bodyRow }}
                  >
                    {row?.cells &&
                      row.cells.length > 0 &&
                      row.cells.map((cell, index) => (
                        <td
                          key={index}
                          {...cell.getCellProps({
                            style: {
                              ...cell.column.styles,
                              verticalAlign: 'middle',
                            },
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {props?.paginationConfig ? (
        <CustomPagination
          paginationConfig={props.paginationConfig}
          handlePaginationChange={props.handlePaginationChange}
          className={styles.pagination}
        />
      ) : (
        <Pagination className={styles.pagination}>
          <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
          <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
          <span className={styles.paginationLabel}>
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
        </Pagination>
      )}
    </>
  );
};

export default DataTable;

import { connect } from 'react-redux';
import { uploadSignatureRequest, updateKycParams } from 'src/redux/actions';
import SignatureVerification from './signature-verification';

function mapStateToProps(state) {
  return {
    kyc: state.kyc,
  };
}

export default connect(mapStateToProps, {
  uploadSignatureRequest,
  updateKycParams,
})(SignatureVerification);

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Loader, ContactUsModal } from 'src/components';
import { Convert } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import './_modal.scss';

const STEPS = {
  CONFIRM_SCREEN: 'CONFIRM_SCREEN',
  DONE: 'DONE',
};

class CancelBooking extends Component {
  state = {
    activeStep: STEPS.CONFIRM_SCREEN,
    isContactUsModalOpen: false,
    loading: false,
  };

  componentDidMount() {
    this.cancelPurchaseRequestSubscription = EventBus.on(ACTION_TYPES.CANCEL_PURCHASE_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.cancelPurchaseSuccessSubscription = EventBus.on(
      ACTION_TYPES.CANCEL_PURCHASE_SUCCESS,
      this._oncancelPurchaseSuccess,
    );
    this.cancelPurchaseFailureSubscription = EventBus.on(ACTION_TYPES.CANCEL_PURCHASE_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.cancelPurchaseRequestSubscription();
    this.cancelPurchaseSuccessSubscription();
    this.cancelPurchaseFailureSubscription();
  }

  closeContactUsModal = () => {
    this.setState({ isContactUsModalOpen: false });
  };

  openContactUsModal = () => {
    this.setState({ isContactUsModalOpen: true });
  };

  handleStep = ({ stepName }) => {
    this.setState({ activeStep: stepName });
  };

  _onCancelBooking = () => {
    const withdrawAmount = Number(this.props.savingGoal.actualShadowCurrentInvestmentBalance);

    if (withdrawAmount > 1) {
      this.props.withdrawAllMoney();
    } else {
      this.props.cancelPurchaseRequest({
        saving_goal_id: this.props.savingGoal.id,
      });
    }
  };

  _oncancelPurchaseSuccess = () => {
    this.setState({ loading: false, activeStep: STEPS.DONE });
  };

  _talkToUs = () => {
    this.openContactUsModal();
  };

  render() {
    const { isModalOpen, closeModal, savingGoal } = this.props;
    const { activeStep, isContactUsModalOpen } = this.state;
    const withdrawAmount = Number(savingGoal.actualShadowCurrentInvestmentBalance);

    return (
      <div>
        <Modal show={isModalOpen} size="lg">
          <div className="cancel-booking-modal">
            <div className="cross-icon">
              <span onClick={closeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
            {activeStep === STEPS.CONFIRM_SCREEN && (
              <div className="content">
                <div className="report">
                  <img src="/img/report.png" alt="report" />
                </div>
                {withdrawAmount >= 1 && (
                  <div className="title">
                    You have saved {Convert.currency(withdrawAmount)} so far
                  </div>
                )}
                <div className="description">
                  Are you sure you would like to cancel your <br /> booking?
                </div>

                <div className="custom-btn-group">
                  <button className="custom-btn" onClick={closeModal}>
                    NO, DONT CANCEL
                  </button>
                  <button className="custom-btn" onClick={this._onCancelBooking}>
                    YES, CANCEL BOOKING
                  </button>
                  <button className="custom-btn" onClick={this._talkToUs}>
                    TALK TO US
                  </button>
                </div>
              </div>
            )}

            {activeStep === STEPS.DONE && (
              <div className="done">
                <div className="description description-one">
                  We have received your request for{' '}
                  <strong>&quot;{this.props.productName}&quot;</strong> <br />
                  cancellation. An intimation to{' '}
                  <strong>&quot;{this.props.partnerName}&quot;</strong> has been <br />
                  sent now
                </div>
                {/* <div className="title">Your Request ID : - XXXXXXX</div> */}
                <div className="description description-two">
                  You will be contacted withing 24-48 business
                  <br /> working hours to understand your requirements
                  <br /> and help you with the cancellation.
                </div>
                <div className="custom-btn-group">
                  <button className="custom-btn" onClick={closeModal}>
                    DONE
                  </button>
                </div>
              </div>
            )}
          </div>
          <Loader loading={this.state.loading} />
        </Modal>
        <ContactUsModal
          isShow={isContactUsModalOpen}
          closeModal={this.closeContactUsModal}
          callback={closeModal}
        />
      </div>
    );
  }
}

export default CancelBooking;

import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import styles from '../_styles.module.scss';

function InputDatepicker({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <div className={styles.inputLabelContainer}>
        <label htmlFor={props.id || props.name} className={styles.inputLabel}>
          {label}
        </label>
      </div>
      <div>
        <InputGroup>
          <DatePicker className="form-control" {...field} {...props} />
          <InputGroup.Text id="inputGroupPrepend" style={{ backgroundColor: '#fff' }}>
            <FontAwesomeIcon icon={faCalendarDay} />
          </InputGroup.Text>
        </InputGroup>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>
    </>
  );
}

export default InputDatepicker;

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminTransactionsReducer(state = INITIAL_STATE.adminTransactions, action) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_LIST_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

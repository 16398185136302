import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

/*
* Gets list of transactions for admin
*/
export function adminTransactionList(data) {
    return ApiRequest.fetchAdmin({
        method: 'get',
        url: `${API_END_POINTS.ADMIN_TRANSACTION_LIST}?page=${data.page}`,
    })
    .then(response => response.data);
}

/*
* Gets list of deposit transactions for admin
*/
export function adminTransactionDepositList() {
    return ApiRequest.fetchAdmin({
        method: 'get',
        url: API_END_POINTS.ADMIN_TRANSACTION_DEPOSIT_LIST,
    })
    .then(response => response.data);
}

/*
* Gets list of waithdrawals transactions for admin
*/
export function adminTransactionWithdrawalList() {
    return ApiRequest.fetchAdmin({
        method: 'get',
        url: API_END_POINTS.ADMIN_TRANSACTION_WITHDRAWAL_LIST,
    })
    .then(response => response.data);
}

/*
* Gets detail of transactions for admin
*/
export function adminTransactionDetail({type, id}) {
    
    let url = ''

    switch (type) {
        case 'deposits':
            url = `${API_END_POINTS.ADMIN_DEPOSIT_DETAIL}/${id}`
            break;

        case 'withdrawals':
            url = `${API_END_POINTS.ADMIN_WITHDRAWAL_DETAIL}/${id}`
            break;
    
        default:
            url = `${API_END_POINTS.ADMIN_DEPOSIT_DETAIL}/${id}`
            break;
    }

    return ApiRequest.fetchAdmin({
        method: 'get',
        url,
    })
    .then(response => response.data);

}
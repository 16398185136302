import axios from 'axios';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import { notify } from 'react-notify-toast';
import { requestLogoutSuccess, adminLogoutSuccess } from 'src/redux/actions';
import { Convert } from './convert';

const SIGNZY_FILE_UPLOAD_URL = process.env.REACT_APP_SIGNZY_FILE_UPLOAD_URL || 'https://preproduction-persist.signzy.tech/api/files/upload';

class ApiRequest {
  static headers(isAdmin) {
    const headers = { 'Content-Type': 'application/json' };
    const store = global.store.getState();

    if (!isAdmin && store.credentials.partnerAccessKey)
      headers['X-PARTNER-ACCESS-KEY'] = store.credentials.partnerAccessKey;
    if (!isAdmin && store.credentials.userIdentityToken)
      headers['X-USER-IDENTITY-TOKEN'] = store.credentials.userIdentityToken;

    if (isAdmin && store.adminCredentials.partnerAccessKey)
      headers['X-PARTNER-ACCESS-KEY'] = store.adminCredentials.partnerAccessKey;
    if (isAdmin && store.adminCredentials.userIdentityToken)
      headers['X-USER-IDENTITY-TOKEN'] = store.adminCredentials.userIdentityToken;

    return headers;
  }

  static async error(message, status, isAdmin) {
    if (status === 401) {
      // clean up redux
      global.store.dispatch(requestLogoutSuccess()); 
      global.store.dispatch(adminLogoutSuccess());

      setTimeout(() => {
        const history = createBrowserHistory();     
        const pathname = isAdmin ? '/admin/sign-in' : '/sign-in';
        
        history.push({ 
          pathname, 
          state: { 
            redirectTo: history.location.pathname
          } 
        });
        window.location.reload();
      }, 100);
    }
    notify.show(message, 'error');
  }

  static apiCall(options, isAdmin) {
    options.headers = _.merge(this.headers(isAdmin), options.headers);

    return axios(options)
      .then((response) => Convert.toCamelCase(response))
      .catch((errorResponse) => {
        if (!options.silent) {
          const errors =
            errorResponse.response &&
            errorResponse.response.data &&
            errorResponse.response.data.errors;
          let errorMessages = '';
          const errorCode = errorResponse.response && errorResponse.response.status;

          if (errors) {
            if (errors instanceof Array || errors.full_messages) {
              errorMessages = errors.full_messages
                ? _.join(errors.full_messages, '\n')
                : _.join(errors, '\n');
            } else {
              errorMessages = errors;
            }
          } else {
            errorMessages = 'Something went wrong!, please try again or contact support';
          }

          this.error(errorMessages, errorCode, isAdmin);
        }

        throw errorResponse;
      });
  }

  static fetch(options) {
    return this.apiCall(options, false);
  }

  static fetchAdmin(options) {
    return this.apiCall(options, true);
  }

  static uploadToSignzy(file, fileName) {
    const data = new FormData();
    data.append('ttl', '3 years');
    
    if (fileName) {
      data.append('file', file, fileName);
    } else {
      data.append('file', file);
    }    

    return axios.post(SIGNZY_FILE_UPLOAD_URL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => Convert.toCamelCase(response))
    .catch(err => {
        this.error(err.message, 400);

        throw err
    });
  }
}

export { ApiRequest };

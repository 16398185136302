import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { Container } from 'react-bootstrap';
import { ACTION_TYPES } from 'src/constants';
import { OffersDetailsLayout } from 'src/components/admin-details';
import { Loader } from 'src/components';
import { OfferDetailsEdit } from 'src/components/admin';
import styles from './_productofferdetails.module.scss';

class ProductOfferDetails extends Component {
  state = {
    loading: false,
    edit: false,
  };

  _handleEditState = () => {
    this.setState({
      edit: !this.state.edit,
    });
  };

  componentDidMount() {
    const uuid = this.props.match.params?.uuid;

    this.requestAdminOfferDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.adminOfferDetailSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFER_DETAIL_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminOfferDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFER_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (uuid) {
      this.props.requestAdminOfferDetail({ uuid });
    }
  }

  componentWillUnmount() {
    this.requestAdminOfferDetail();
    this.adminOfferDetailSuccess();
    this.adminOfferDetailFailure();
  }

  render() {
    const { productOffer } = this.props;
    return (
      <>
        <Container className={styles.container}>
          {this.state.edit ? (
            <OfferDetailsEdit productOffer={productOffer} handleEditState={this._handleEditState} />
          ) : (
            <OffersDetailsLayout
              productOffer={productOffer}
              handleEditState={this._handleEditState}
            />
          )}
        </Container>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withRouter(ProductOfferDetails);

import React from 'react';
import styles from './_circleicon.module.scss';

const CircleIcon = (props) => (
  <button className={styles['circleiconContainer']} style={{ ...props?.styles }} {...props}>
    {props.children}
  </button>
);

export default CircleIcon;

import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import styles from './_input.module.scss';

class Input extends Component {
  _onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.props.onPressEnter && this.props.onPressEnter(e);
    }
  };

  render() {
    return (
      <div className={styles['app-input']}>
        {this.props.label ? <label>{this.props.label}</label> : null}
        <InputMask
          className={this.props.className}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
          onKeyDown={this._onKeyDown}
          mask={this.props.mask}
          maskChar={this.props.maskChar}
          disabled={this.props.loading}
          type={this.props.type}
        />
        <div className={styles['description']}>{this.props.description}</div>
      </div>
    );
  }
}

export default Input;

import { ACTION_TYPES } from 'src/constants';

export function requestAdminPartnerList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PARTNER_LIST,
    payload,
  };
}

export function adminPartnerListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PARTNER_LIST_SUCCESS,
    payload,
  };
}

export function adminPartnerListFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PARTNER_LIST_FAILED,
    payload,
  };
}

export function requestAdminProfile(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PROFILE,
    payload,
  };
}

export function adminProfileSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PROFILE_SUCCESS,
    payload,
  };
}

export function adminProfileFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PROFILE_FAILED,
    payload,
  };
}

export function requestAdminProfileUpdate(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_PROFILE_UPDATE,
    payload,
  };
}

export function adminProfileUpdateSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PROFILE_UPDATE_SUCCESS,
    payload,
  };
}

export function adminProfileUpdateFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_PROFILE_UPDATE_FAILED,
    payload,
  };
}
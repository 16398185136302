import React, { Component } from 'react';
import cx from 'classnames';
import styles from './_loader.module.scss';

class Loader extends Component {
  render() {
    const { button, loading, title, fixed } = this.props;

    if (!loading) return null;

    return (
      <div
        className={`${styles['app-loader']} ${button ? styles['button'] : ''} ${
          title ? styles['column'] : ''
        } ${fixed ? styles['fixed'] : ''}`}
      >
        <div className={styles['loader']} />
        {title ? (
          <p className={cx(styles['loader-title'], this.props.titleClassNames)}>{title}</p>
        ) : null}
      </div>
    );
  }
}

export default Loader;

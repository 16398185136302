import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import CredentialReducer from './credentials.reducer';
import UserReducer from './user.reducer';
import ProductReducer from './product.reducer';
import PartnerReducer from './partner.reducer';
import SavingGoalReducer from './saving-goal.reducer';
import SavingGoalsReducer from './saving-goals.reducer';
import SavingPlanReducer from './saving-plan.reducer';
import FolioReducer from './folio.reducer';
import TaxStatuReducer from './tax-statuses.reducer';
import BankDetailsReducer from './bank-details.reducer';
import OccupationsReducer from './occupations.reducer';
import MandateReducer from './mandate.reducer';
import TransactionsReducer from './transactions.reducer';

import AdminReducer from './admin.reducer';
import AdminCredentialReducer from './admin-credentials.reducer';
import AdminProductsReducer from './admin-products.reducer';
import AdminOffersReducer from './admin-offers.reducer';
import AdminUsersReducer from './admin-users.reducer';
import AdminGoalsReducer from './admin-goals.reducer';
import AdminTransactionsReducer from './admin-transactions.reducer';
import KycReducer from './kyc.reducer';
import AdminAgentsReducer from './admin-agents.reducer';
import AdminPaymentReducer from './admin-payment.reducer';
import AdminPartnersReducer from './admin-partners.reducer';
import AdminCollectionReducer from './admin-collection.reducer';
import AdminCancelRequestsReducer from './admin-cancel.reducer';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = persistCombineReducers(persistConfig, {
  credentials: CredentialReducer,
  user: UserReducer,
  product: ProductReducer,
  partner: PartnerReducer,
  savingGoal: SavingGoalReducer,
  savingGoals: SavingGoalsReducer,
  savingPlan: SavingPlanReducer,
  folio: FolioReducer,
  taxStatuses: TaxStatuReducer,
  bankDetails: BankDetailsReducer,
  occupations: OccupationsReducer,
  mandates: MandateReducer,
  transactions: TransactionsReducer,

  admin: AdminReducer,
  adminCredentials: AdminCredentialReducer,
  adminProducts: AdminProductsReducer,
  adminOffers: AdminOffersReducer,
  adminUsers: AdminUsersReducer,
  adminGoals: AdminGoalsReducer,
  adminTransactions: AdminTransactionsReducer,
  adminAgents: AdminAgentsReducer,
  adminPayments: AdminPaymentReducer,
  kyc: KycReducer,
  adminPartners: AdminPartnersReducer,
  adminCollections: AdminCollectionReducer,
  adminCancelRequests: AdminCancelRequestsReducer,
});

export default rootReducer;

import { connect } from 'react-redux';
import { 
  requestGeneratePayment, 
  createSavingGoal,
  updateSavingPlan,  
} from 'src/redux/actions';
import SipRedirect from './sip-redirect';

function mapStateToProps(state) {
  return {
    user: state.user,
    partner: state.partner,
    product: state.product,
    savingPlan: state.savingPlan    
  };
}

export default connect(mapStateToProps, { 
  requestGeneratePayment, 
  createSavingGoal,
  updateSavingPlan  
})(SipRedirect);

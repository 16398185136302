import { connect } from 'react-redux';
import { requestAdminTransactionList } from 'src/redux/actions';
import Transactions from './transactions';

function mapStateToProps(state) {
  return {
    transactions: state.adminTransactions,
  };
}

export default connect(mapStateToProps, {
  requestAdminTransactionList,
})(Transactions);

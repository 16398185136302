import { connect } from 'react-redux';
import { requestAdminAgentCreate } from 'src/redux/actions';
import Agent from './agent';

function mapStateToProps(state) {
  return {
    admin: state.admin,
    adminAgents: state.adminAgents,
  };
}

export default connect(mapStateToProps, {
  requestAdminAgentCreate,
})(Agent);

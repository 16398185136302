import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { ButtonOne } from 'src/components';
import moment from 'moment';
import { Convert } from 'src/helpers';
import DeleteProductOfferModal from './delete-product-offer-modal';
import styles from './_offerdetailslayout.module.scss';

class OffersDetailsLayout extends Component {
  state = {
    showDeleteModal: false,
  };

  handleDeleteModal = () => {
    this.setState({ showDeleteModal: true });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  render() {
    const { productOffer, handleEditState } = this.props;
    const { showDeleteModal } = this.state;
    const productOfferDescriptionData =
      (productOffer &&
        productOffer?.description &&
        productOffer?.description !== 'null' &&
        Convert.toRawHtml(productOffer.description)) ||
      '';
    const imageUrl =
      (productOffer?.product?.imageUrls &&
        productOffer?.product?.imageUrls.length > 0 &&
        productOffer?.product?.imageUrls[0].url) ||
      '';

    return (
      <div>
        <div className={styles['offer-details-title']}>Offer Details</div>
        <Card className={styles.card}>
          <Card.Header className={styles['card-header']}>
            <span className={styles['card-heading']}>Overview</span>
            <span
              className={`${styles.anchorHand} ${styles['toggle-button']}`}
              onClick={() => handleEditState()}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className={styles['image-box']}>
                  {imageUrl || productOffer?.product?.imageLink ? (
                    <img src={imageUrl || productOffer?.product?.imageLink} alt="img" />
                  ) : (
                    <div className={styles['inner-image-box']}>No Image</div>
                  )}
                </div>
              </Col>
              <Col md={8}>
                <div className={styles['right-side']}>
                  <div className={styles['list']}>
                    <div className={styles['list-group']}>
                      <div className={styles['label']}>Price&nbsp;</div>
                      <div className={styles['value']}>
                        :{' '}
                        {productOffer?.product?.amount &&
                          Convert.toCurrencyValue(productOffer?.product?.amount)}
                      </div>
                    </div>
                    <div className={styles['list-group']}>
                      <div className={styles['label']}>Discount&nbsp;</div>
                      <div className={styles['value']}>
                        :{' '}
                        {(productOffer?.isDiscountPercentage && productOffer?.discountPercentage) ||
                          0}
                        %
                      </div>
                    </div>
                    <div className={styles['list-group']}>
                      <div className={styles['label']}>Purchase Date&nbsp;</div>
                      <div className={styles['value']}>
                        : {moment(productOffer?.finalDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className={styles['title']}>{productOffer?.product?.productName}</div>
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: productOfferDescriptionData,
                      }}
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className={styles.card}>
          <Card.Header className={styles['card-header']}>
            <span className={styles['card-heading']}>Payment Timeline</span>
          </Card.Header>
          <Card.Body>
            <div className={styles['timeline']}>
              {productOffer?.upfrontPaymentAmount ? (
                <div className={styles['timeline-group']}>
                  <div className={styles['label']}>
                    {Convert.toCurrencyValue(productOffer?.upfrontPaymentAmount)}
                  </div>
                  <div className={styles['arrow']}>
                    <img src="/img/long-arrow.svg" alt="arrow" />
                  </div>
                  <div className={styles['value']}>first payment</div>
                </div>
              ) : null}
              {productOffer?.paymentSchedule?.paymentEvents &&
                productOffer?.paymentSchedule?.paymentEvents.length > 0 &&
                productOffer?.paymentSchedule?.paymentEvents.map((paymentData, index) => {
                  const { amountRequired, date } = paymentData || {};

                  return (
                    <div className={styles['timeline-group']} key={index}>
                      <div className={styles['label']}>
                        {Convert.toCurrencyValue(amountRequired)}
                      </div>
                      <div className={styles['arrow']}>
                        <img src="/img/long-arrow.svg" alt="arrow" />
                      </div>
                      <div className={styles['value']}>{date}</div>
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>

        <Card className={styles.card}>
          <Card.Header className={`${styles['card-header']} ${styles['expire-card-header']}`}>
            <span className={styles['card-heading']}>
              Offer Expires on:{' '}
              {productOffer?.expiryDate && moment(productOffer?.expiryDate).format('DD/MM/YYYY')}
            </span>
          </Card.Header>
        </Card>

        <div className={styles['detail-btns']}>
          {/* <ButtonOne text="Send Offer via Email" type="submit" parentstyles={{ width: '100%' }} />
          <ButtonOne
            text="Send Offer via Whatsapp"
            type="submit"
            parentstyles={{ width: '100%', padding: '0px 29px' }}
          /> */}
          <ButtonOne
            text="Delete Offer"
            type="submit"
            parentstyles={{ width: '100%' }}
            onClick={this.handleDeleteModal}
          />
        </div>
        {showDeleteModal && (
          <DeleteProductOfferModal
            showDeleteModal={showDeleteModal}
            closeDeleteModal={this.closeDeleteModal}
            productOffer={productOffer}
          />
        )}
      </div>
    );
  }
}

export default OffersDetailsLayout;

import { connect } from 'react-redux'
import { extractPanCardRequest, uploadPanCardRequest, uploadFormRequest, updateKycParams } from 'src/redux/actions';
import PanVerification from './PanVerification'

function mapStateToProps(state) {
  return {
    kyc: state.kyc
  };
}

export default connect(mapStateToProps,{
  extractPanCardRequest,
  uploadPanCardRequest,
  uploadFormRequest,
  updateKycParams
})(PanVerification)

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function productOffersDetail({ uuid }) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.PRODUCTS_OFFERS}/${uuid}`,
  }).then((response) => response.data.productOffer);
}

export function productOffersDelete({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: 'delete',
    url: `${API_END_POINTS.ADMIN_PRODUCTS_OFFERS_DELETE}/${uuid}`,
  }).then((response) => response.data);
}

export function productDetail({ uuid }) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.PRODUCTS}/${uuid}`,
  }).then((response) => response.data.product);
}

import {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,
  requestGeneratePayment,
  updateCredentials,
  updateProduct,
  createSavingGoal,
  requestVerifyUser,
} from 'src/redux/actions';
import { connect } from 'react-redux';

import Pay from './pay';

function mapStateToProps(state) {
  return {
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
  };
}

export default connect(mapStateToProps, {
  updateSavingPlan,
  requestProductDetail,
  requestProductOffers,
  requestGeneratePayment,
  updateCredentials,
  updateProduct,
  createSavingGoal,
  requestVerifyUser,
})(Pay);

import { connect } from 'react-redux';
import { requestGoalList, requestGoalDetail } from 'src/redux/actions';
import GoalProgressList from './GoalProgressList';

function mapStateToProps(state) {
  return {
    savingGoals: state.savingGoals || [],
    savingGoal: state.savingGoal || {},
    transactions: state.transactions,
  };
}

export default connect(mapStateToProps, {
  requestGoalList,
  requestGoalDetail,
})(GoalProgressList);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, SavvySupport } from 'src/components';
import styles from './pay-long-kyc.module.scss';
import OfferBox from '../pay/offer-box';
import Demographic from './demographic';
import UploadPan from './upload-pan';
import AddressVerification from './address-verification';
import SignatureVerification from './signature-verification';
import VideoVerification from './video-verification';
import SelfieVerification from './selfie-verification';

class PayLongKyc extends Component {
  state = {
    loading: false,
    step: 'demographic',
  };

  componentDidMount() {
    this.setState({ step: this.props.match?.params?.step || 'demographic' });
  }

  _onGoto = (step) => {
    if (step === 'demographic') {
      this.props.history.push(`/checkout/long-kyc`);
    } else {
      this.props.history.push(`/checkout/long-kyc/${step}`);
    }
  };

  _navigateToReview = () => {
    this.props.history.push('/checkout/long-kyc/review');
  };

  render() {
    const { loading, step } = this.state;
    const { partner, product, user } = this.props;

    return (
      <>
        <div className={styles['pay-kyc']}>
          <div className={styles['pay-box']}>
            <div className={styles['left-box']}>
              {step === 'demographic' && <Demographic onGoto={this._onGoto} />}
              {step === 'pancard' && <UploadPan onGoto={this._onGoto} />}
              {step === 'address' && <AddressVerification onGoto={this._onGoto} />}
              {step === 'signature' && <SignatureVerification onGoto={this._onGoto} />}
              {step === 'video' && <VideoVerification onGoto={this._onGoto} />}
              {step == 'selfie' && (
                <SelfieVerification
                  onGoto={this._onGoto}
                  navigateToReview={this._navigateToReview}
                />
              )}
            </div>
            <div className={styles['right-box']}>
              <OfferBox partner={partner} product={product} user={user} />
            </div>
          </div>
          {/* <ExtraInfo /> */}
          <SavvySupport />
          <Loader loading={loading} fixed />
        </div>
      </>
    );
  }
}

export default withRouter(PayLongKyc);

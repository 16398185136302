import React, { Component } from 'react';
import styles from './_section.module.scss';

class Section extends Component {
  _onClick = () => {
    this.props.onButtonClick && this.props.onButtonClick();
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.left}>
            {this.props.title || ''}
          </div>          
        </div>
        <div className={styles.body}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Section;

import { connect } from 'react-redux';
import {
  requestAdminProductCreate,
  requestAdminProductUpdate,
  adminProductImageUpload,
} from 'src/redux/actions';
import ProductDetailsEdit from './ProductDetailsEdit';

function mapStateToProps(state) {
  return {
    products: state.adminProducts.products,
  };
}

export default connect(mapStateToProps, {
  requestAdminProductUpdate,
  requestAdminProductCreate,
  adminProductImageUpload,
})(ProductDetailsEdit);

import React, { Component, createRef } from 'react';
import InputMask from 'react-input-mask';
import { withRouter } from 'react-router-dom';
import { Row, Col, Dropdown, Button, Form } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import EventBus from 'eventing-bus';
import cx from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import moment from 'moment';
import { Loader, BootstrapModal, CustomDatepicker } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './_demographic-info.module.scss';
import './modal.styles.scss';

const INCOME_LIST = [
  {
    value: 1,
    text: '1 - 5 lacs',
  },
  {
    value: 2,
    text: '5 - 10 lacs',
  },
  {
    value: 3,
    text: '10 - 25 lacs',
  },
  {
    value: 4,
    text: '> 25 Lacs - 1 crore',
  },
  {
    value: 5,
    text: 'Below 1 lac',
  },
  {
    value: 6,
    text: '> 1 crore',
  },
];

class DropDownItem extends Component {
  _onClick = () => {
    const { text, value } = this.props;
    this.props.onClick && this.props.onClick({ text, value });
  };

  render() {
    return <Dropdown.Item onClick={this._onClick}>{this.props.text}</Dropdown.Item>;
  }
}

class DemographicInfo extends Component {
  state = {
    email: this.props.user.email || '',
    dateOfBirth: this.props.user.dateOfBirth
      ? new Date(moment(this.props.user.dateOfBirth, 'DD/MM/YYYY').toString())
      : '',
    occupationList: [],
    occupation: {},
    income: {},
    acceptPep: false,
    showFolioCreated: false,
    loading: false,
  };

  datepickerRef = createRef();

  componentDidMount() {
    this.occupationRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_OCCUPATION_LIST, () =>
      this.setState({ loading: true }),
    );
    this.occupationSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_OCCUPATION_LIST_SUCCEEDED,
      this._onFetchedOccupations,
    );
    this.occupationFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_OCCUPATION_LIST_FAILED,
      () => this.setState({ loading: false }),
    );

    this.submitInvestorDetailsRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS,
      () => this.setState({ loading: true }),
    );
    this.submitInvestorDetailsSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_SUCCEEDED,
      this._onInvestorDetailsSubmitted,
    );
    this.submitInvestorDetailsFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_FAILED,
      () => this.setState({ loading: false }),
    );

    this.submitInvestorKYCRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC,
      () => this.setState({ loading: true }),
    );
    this.submitInvestorKYCSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC_SUCCEEDED,
      this._onInvestorKYCSubmitted,
    );
    this.submitInvestorKYCFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC_FAILED,
      () => this.setState({ loading: false }),
    );

    this.createFolioRequestSubscription = EventBus.on(ACTION_TYPES.CREATE_FOLIO, () =>
      this.setState({ loading: true }),
    );
    this.createFolioSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_FOLIO_SUCCEEDED,
      this._onFolioCreateSuccess,
    );
    this.createFolioFailureSubscription = EventBus.on(ACTION_TYPES.CREATE_FOLIO_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (this.props.occupations.length === 0) {
      this.props.requestOccupationList();
    } else {
      this._parseOccupationList();
    }

    const datePickerInput = document.querySelector('.react-datepicker__input-container input');
    const placeholder =
      window.innerWidth > 768
        ? this.datepickerRef.current.props.placeholder
        : `Birthday : ${this.datepickerRef.current.props.placeholder}`;
    datePickerInput.setAttribute('placeholder', placeholder);
  }

  componentWillUnmount() {
    this.occupationRequestSubscription();
    this.occupationSuccessSubscription();
    this.occupationFailureSubscription();

    this.submitInvestorDetailsRequestSubscription();
    this.submitInvestorDetailsSuccessSubscription();
    this.submitInvestorDetailsFailureSubscription();

    this.submitInvestorKYCRequestSubscription();
    this.submitInvestorKYCSuccessSubscription();
    this.submitInvestorKYCFailureSubscription();

    this.createFolioRequestSubscription();
    this.createFolioSuccessSubscription();
    this.createFolioFailureSubscription();
  }

  _onFetchedOccupations = () => {
    this.setState({ loading: false }, () => {
      this._parseOccupationList();
    });
  };

  _parseOccupationList = () => {
    const occupationList = this.props.occupations.map((occupation) => ({
      value: Number(occupation.occupationCode),
      text: occupation.occupationName,
    }));

    this.setState({ occupationList });
  };

  _onInvestorKYCSubmitted = () => {
    this.props.createFolio({ without_otp: true });
  };

  _onFolioCreateSuccess = () => {
    this.setState({ loading: false }, () => {
      this.setState({ showFolioCreated: true });
    });
  };

  _selectOccuption = (occupation) => {
    this.setState({ occupation });
  };

  _selectIncome = (income) => {
    this.setState({ income });
  };

  _isValidForm = () => {
    const { email, dateOfBirth, occupation, income } = this.state;
    return email && dateOfBirth && occupation && occupation.value && income && income.value;
  };

  _onInvestorDetailsSubmitted = () => {
    this.props.requestSubmitInvestorKYC({
      occupation_code: String(this.state.occupation.value),
      annual_salary: this.state.income.text,
      pep_check: this.state.acceptPep ? 'Applicable' : 'Not applicable',
    });
  };

  _allDone = () => {
    this.props.requestSubmitInvestorDetails({
      email: this.state.email,
      phone_number: this.props.user && this.props.user.phoneNumber,
      date_of_birth: moment(this.state.dateOfBirth).format('YYYY-MM-DD'),
    });
  };

  _doneFolioCreation = () => {
    this.setState({ showFolioCreated: false }, () => {
      this.props.history.push('/setup-sip');
    });
  };

  handleDatePicker = (value) => {
    this.setState({ dateOfBirth: value });
  };

  render() {
    const { user } = this.props;
    const { email, dateOfBirth, occupationList, occupation, income, acceptPep, showFolioCreated } =
      this.state;

    return (
      <div className={styles['demographic-info']}>
        <Formik>
          {() => (
            <FormikForm>
              <Row>
                <Col xs={12}>
                  <div className={styles['demo-info-title']}>Demographic Information</div>
                  <div className={styles['demo-info-heading']}>
                    Lets wrap up the formalities and start saving for your holiday!
                  </div>
                  <div className={styles['demo-info-group-input']}>
                    <div>
                      <label>Enter your Email ID</label>
                    </div>
                    <div>
                      <InputMask
                        value={email}
                        className={styles['demo-info-input']}
                        placeholder="example@gmail.com"
                        onChange={({ target }) => this.setState({ email: target.value })}
                      />
                    </div>
                  </div>
                  <div className={styles['demo-info-group-input']}>
                    <div className="d-none d-md-block">
                      <CustomDatepicker
                        selectedValue={dateOfBirth}
                        handleDatePicker={this.handleDatePicker}
                        label="Date of Birth"
                        name="dateOfBirth"
                      />
                    </div>
                    <div className="d-md-none">
                      <div>
                        <label>Date of Birth</label>
                      </div>
                      <div>
                        <DatePicker
                          dateFormat="dd/MM/yyyy" // don't change to uppercase
                          placeholder="DD/MM/YYYY"
                          selected={dateOfBirth}
                          ref={this.datepickerRef}
                          onChange={(dateOfBirth) => {
                            this.handleDatePicker(dateOfBirth);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={styles['demo-info-group-input']}>
                    <div>
                      <label>Occupation</label>
                    </div>
                    <div>
                      <Dropdown className={styles['custom-dropdown']}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className={
                            !occupation || !occupation.text
                              ? styles['drodown-light-placeholder']
                              : ''
                          }
                        >
                          {occupation && occupation.text ? occupation.text : 'Occupation'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {occupationList &&
                            occupationList.length > 0 &&
                            occupationList.map((item, index) => (
                              <DropDownItem
                                {...item}
                                key={`occupation-item-${index}`}
                                onClick={this._selectOccuption}
                              />
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
                <Col lg={1} />
                <Col lg={4}>
                  <div className={styles['demo-info-group-input']}>
                    <div>
                      <label>Annual salary</label>
                    </div>
                    <div>
                      <Dropdown className={cx(styles['custom-dropdown'], styles['demo-info-lakh'])}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className={
                            !income || !income.text ? styles['drodown-light-placeholder'] : ''
                          }
                        >
                          {income && income.text ? income.text : 'Income '}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {INCOME_LIST.map((item, index) => (
                            <DropDownItem
                              {...item}
                              key={`income-item-${index}`}
                              onClick={this._selectIncome}
                            />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId={styles['formBasicCheckbox']}>
                    <Form.Check
                      className={styles['demo-info-checkbox']}
                      type="checkbox"
                      label="I am an Indian citizen and not a PEP (Politically  Exposed Person) or related to a PEP"
                      checked={acceptPep}
                      onChange={({ target }) => this.setState({ acceptPep: target.checked })}
                    />
                  </Form.Group>
                  <Button
                    className={styles['demo-info-all-done-btn']}
                    onClick={this._allDone}
                    disabled={!this._isValidForm()}
                  >
                    All done
                  </Button>
                  <div className={styles['partner-imgs']}>
                    <img src="/img/sebi2.png" alt="sebi" />
                    <img src="/img/icici1.png" alt="icici" />
                  </div>
                  <div className={styles['message']}>
                    Your data is encrypted by 256-bit security
                  </div>
                </Col>
              </Row>
              <BootstrapModal show={showFolioCreated} className="folio-created-modal">
                <div className={styles['folio-created-box']}>
                  <Player
                    autoplay
                    keepLastFrame
                    src="https://assets5.lottiefiles.com/packages/lf20_fjxwjvws.json"
                    className={styles['folio-created-ok-icon']}
                  />
                  <div className={styles['folio-created-thank-you']}>
                    Your KYC has been verified!
                    <br />
                    You may now start saving for your purchase.
                  </div>
                  <div className={styles['folio-created-start-saving']}>
                    Your folio number is No. {user?.folio?.folioNumber}
                  </div>
                  <Button title="DONE" className={styles['folio-created-btn']} onClick={this._doneFolioCreation}>
                    DONE
                  </Button>
                </div>
              </BootstrapModal>
              <Loader loading={this.state.loading} />
            </FormikForm>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(DemographicInfo);

import React from 'react';

function Header(props) {
  return (
    <div
      className={props.headerStyles}
    >
      <span
        className={props.headingStyles}
      >
        {props.heading}
      </span>
      {props?.rightComponent &&
        <>{props.rightComponent()}</>
      }
    </div>
  );
}

export default Header;

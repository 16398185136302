import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function ProductReducer(state = INITIAL_STATE.product, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_PRODUCT: {
      return {
        ...action.payload,
      };
    }

    // PRODUCT
    case ACTION_TYPES.REQUEST_PRODUCT_DETAIL: {
      return state;
    }

    case ACTION_TYPES.REQUEST_PRODUCT_DETAIL_SUCCEEDED: {
      const updateParams = {};
      if (state.productUuid) updateParams.productUuid = state.productUuid;
      if (state.productOfferUuid) updateParams.productOfferUuid = state.productOfferUuid;
      if (state.productOffers) updateParams.productOffers = state.productOffers;
      const appendFields = {
        imageLink: action.payload?.imageLink || '',
        imageUrls: action.payload?.imageUrls || [],
        productName: action.payload?.productName || '',
        textFields: action.payload?.textFields || [],
      };
      return {
        ...appendFields,
        ...updateParams,
      };
    }

    case ACTION_TYPES.REQUEST_PRODUCT_DETAIL_FAILED: {
      return state;
    }

    // PRODUCT OFFERS
    case ACTION_TYPES.REQUEST_PRODUCT_OFFERS: {
      return state;
    }

    case ACTION_TYPES.REQUEST_PRODUCT_OFFERS_SUCCEEDED: {
      const updateParams = {};
      if (state.productUuid) updateParams.productUuid = state.productUuid;
      if (state.productOfferUuid) updateParams.productOfferUuid = state.productOfferUuid;
      return {
        ...state,
        ...updateParams,
        amount: Number(action.payload?.discountedAmount || 0),
        productOffers: [
          {
            ...action.payload,
            product: null,
          },
        ],
      };
    }

    case ACTION_TYPES.REQUEST_PRODUCT_OFFERS_FAILED: {
      return state;
    }

    // LOGOUT
    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.product;
    }

    default:
      return state;
  }
}

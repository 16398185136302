import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './_not-found.module.scss';

class NotFound extends Component {
  render() {
    return <div className={styles.container}>
      Oops!!, the page you are looking for is not found
    </div>;
  }
}

export default withRouter(NotFound);

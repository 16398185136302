import React, { Component } from 'react';
import * as yup from 'yup';
import { Loader, InfoBox } from 'src/components';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Formik, Form, FieldArray } from 'formik';
import {
  InputCheckboxTwo,
  TextInputCurrency,
  InputDatepickerTwo,
} from 'src/components/form-inputs';
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { getDay, isSameDay } from 'date-fns';
import { isNumber } from 'lodash';
import { Helpers, Convert } from 'src/helpers';
import { AiFillInfoCircle } from 'react-icons/ai';
import { notify } from 'react-notify-toast';
import { Section, Container, ButtonOne, TextOne } from '../../components';
import { TextInputOne, InputSwitch } from '../../components/form-inputs';
import styles from './_generate-payment-link.module.scss';
import ModalPaymentLink from '../../components/modal-payment-link';

const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.savvyapp.in'
    : 'https://www.thesavvyapp.in';

export class GeneratePaymentLink extends Component {
  state = {
    loading: false,
    offerAmount: {
      afterDiscountAndTaxes: '',
      afterBookingAmount: '',
      remainingScheduleAmount: '',
    },
    showModal: false,
    isEdit: false,
  };

  _paymentEventsDateFilter = (date) => {
    const day = getDay(date);
    const { payment_events } = this.formik.values;
    const sameDay = payment_events.find((item) => isSameDay(date, item.date));
    return day !== 0 && day !== 6 && !sameDay;
  };

  _dateFilter = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  componentDidMount() {
    const { match, requestAdminOfferDetail } = this.props;

    this.generateUrlSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL, () =>
      this.setState({ loading: true }),
    );

    this.generateUrlSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SHORTEN_URL_SUCCESS,
      this._onUrlGenerated,
    );

    this.generateUrlFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_SHORTEN_URL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminOfferCreate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_CREATE_V2, () =>
      this.setState({ loading: true }),
    );

    this.adminOfferCreateSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_OFFER_CREATE_SUCCESS,
      this._generatePaymentLink,
    );

    this.adminOfferCreateFailed = EventBus.on(ACTION_TYPES.ADMIN_OFFER_CREATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    // this.requestAdminOfferCleanup = EventBus.on(ACTION_TYPES.CREATE_ADMIN_OFFER_SUCCESS, () =>
    //   this.setState({ loading: true }),
    // );

    this.requestAdminOfferDetail = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_DETAIL, () =>
      this.setState({ loading: true }),
    );

    this.adminOfferDetailSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_OFFER_DETAIL_SUCCESS,
      this._handleOfferDetailSuccess,
    );

    this.adminOfferDetailFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFER_DETAIL_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminOfferUpdate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFER_UPDATE, () => {
      this.setState({ loading: true });
    });

    this.adminOfferUpdateSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFER_UPDATE_SUCCESS, () => {
      this.setState({ loading: false });
      notify.show('Offer updated successfully', 'success');
    });

    this.adminOfferUpdateFailed = EventBus.on(ACTION_TYPES.ADMIN_OFFER_UPDATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.adminPaymentScheduleRequestSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_PAYMENT_SCHEDULE_REQUEST,
      () => this.setState({ loading: true }),
    );

    this.adminPaymentScheduleSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_PAYMENT_SCHEDULE_SUCCESS,
      this._onAdminPaymentScheduleSuccess,
    );

    this.adminPaymentScheduleFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_PAYMENT_SCHEDULE_FAILED,
      () => this.setState({ loading: false }),
    );

    if (match?.params?.uuid) {
      requestAdminOfferDetail({ uuid: match?.params?.uuid });
    }
  }

  componentWillUnmount() {
    this.generateUrlSubscription();
    this.generateUrlSuccessSubscription();
    this.generateUrlFailureSubscription();

    this.requestAdminOfferCreate();
    this.adminOfferCreateSuccess();
    this.adminOfferCreateFailed();

    this.requestAdminOfferDetail();
    this.adminOfferDetailSuccess();
    this.adminOfferDetailFailure();

    this.adminPaymentScheduleRequestSubscription();
    this.adminPaymentScheduleSuccessSubscription();
    this.adminPaymentScheduleFailureSubscription();
  }

  _validationSchema = yup.object().shape({
    product_offer_name: yup.string().required('Please enter offer name.'),
    final_date: yup.string().required('Please select a date'),
    is_upfront_payment_required: yup.bool(),
    amount: yup
      .number('Must be number')
      .required('Please enter a valid amount')
      .positive('Enter a valid amount'),
    is_discount_percentage: yup.string().required('Please select an option.').default('N'),
    discount_percentage: yup.number().when('is_discount_percentage', {
      is: 'Y',
      then: yup
        .number()
        .required('Please enter discount percentage')
        .min(0, 'Discount cannot be negative')
        .max(100),
    }),
    gst: yup.number('Please enter a valid value.').min(0, 'Tax cannot be negative'),
    tcs: yup.number('Please enter a valid value.').min(0, 'Tax cannot be negative'),
    upfront_payment_amount: yup
      .number('Please enter a valid amount.')
      .when('is_upfront_payment_required', {
        is: true,
        then: yup
          .number('Please enter a valid amount.')
          .required('Please enter amount')
          .test('Max amount check', 'Booking amount cannot exceed payment schedule amount', (val) =>
            val ? val <= this.state.offerAmount.afterDiscountAndTaxes : true,
          ),
      }),
    payment_events: yup.mixed().when(['upfront_payment_amount', 'payment_methods_available'], {
      is: (upfront_payment_amount, payment_methods_available) =>
        this.state.offerAmount.afterBookingAmount > 0 &&
        !payment_methods_available.includes('book_save_buy'),
      then: yup.array().of(
        yup.object().shape({
          amount_required: yup
            .number('Must be number')
            .required('Please enter an amount')
            .min(1, 'Enter a valid amount'),
          date: yup
            .string()
            .required('Please choose a date')
            .test('max_date', '', (val, { createError, path }) => {
              const { final_date: finalDate, payment_methods_available } = this.formik.values;

              if (finalDate && val) {
                const maxDate = payment_methods_available.includes('book_save_buy')
                  ? Helpers.addWeekdays(moment(finalDate), -8)
                  : moment(finalDate);

                if (maxDate.diff(moment(val), 'days') < 0) {
                  return createError({
                    path,
                    message: payment_methods_available.includes('book_save_buy')
                      ? 'Balance payment date must be 8 business days prior to purchase date.'
                      : 'Balance payment date must not exceed purchase date.',
                  });
                }
              }
              return true;
            }),
        }),
      ),
    }),
    name_of_user: yup.string('Please enter name of user.'),
    user_phone_number: yup.string().matches(/^[6-9]\d{9}$/, {
      message: 'Phone number not valid',
    }),
    send_link_email: yup.string().email('Please enter a valid email.'),
    payment_methods_available: yup
      .mixed()
      .test('checked', 'Choose at least one payment method', (val) => val.length),
  });

  _handleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  _handlePaymentMethodChange = (e) => {
    const { name, checked } = e.target;
    const { payment_methods_available: paymentMethodsAvailable } = this.formik.values;
    this.formik.setFieldValue(name, checked);

    if (paymentMethodsAvailable.length === 0) {
      this.formik.setFieldValue('payment_events', []);
    }
  };

  _setPaymentEventsTouched = () => {
    const { payment_events } = this.formik.values;

    payment_events.forEach((item, index) => {
      this.formik.setFieldTouched(`payment_events[${index}].amount_required`, true);
      this.formik.setFieldTouched(`payment_events[${index}].date`, true);
    });
  };

  _onAdminPaymentScheduleSuccess = ({ schedule }) => {
    this.setState({ loading: false });

    Object.keys(schedule).forEach((key, index) => {
      this.formik.setFieldValue(
        `payment_events[${index}].amount_required`,
        Number(schedule[key]) || 0,
      );
      this.formik.setFieldValue(`payment_events[${index}].date`, moment(key, 'DDMMYYYY').toDate());
    });

    this._computeBalancePayment();
  };

  _handleOfferDetailSuccess = () => {
    const { productOffer = {} } = this.props;
    const events = productOffer?.paymentSchedule?.paymentEvents.map((data) => ({
      amount_required: Number(data?.amountRequired),
      date: new Date(moment(data?.date, 'DD/MM/YYYY').format('MM/DD/YYYY')),
    }));
    this.formik.setValues({
      product_offer_name: productOffer?.productOfferName || '',
      final_date: productOffer?.finalDate || '',
      is_upfront_payment_required: productOffer?.isUpfrontPaymentRequired || '',
      amount: Number(productOffer?.amount) || '',
      is_discount_percentage: productOffer?.isDiscountPercentage || false,
      discount_percentage: Number(productOffer?.discountPercentage) || '',
      gst: Number(productOffer?.taxes?.find((val) => val.taxFieldName === 'gst')?.percentage) || '',
      tcs: Number(productOffer?.taxes?.find((val) => val.taxFieldName === 'tcs')?.percentage) || '',
      payment_events: events || [],
      upfront_payment_amount: Number(productOffer?.upfrontPaymentAmount) || '',
      book_save_buy: productOffer?.paymentMethodsAvailable?.includes('book_save_buy') || false,
      pay_in_instalments:
        productOffer?.paymentMethodsAvailable?.includes('pay_in_instalments') || false,
      payment_methods_available: productOffer?.paymentMethodsAvailable || [],
      expiry_date: productOffer?.expiryDate || '',
    });

    this.setState(
      {
        isEdit: true,
        loading: false,
      },
      () => this._computeOfferAmountInitially({ productOffer }),
    );
  };

  // Handle amount field Values
  _handleChange = (e) => {
    const val = e.target.value;
    const precision = e.target.getAttribute('decimalsAllowed')
      ? parseInt(e.target.getAttribute('decimalsAllowed')) + 1
      : 3;
    const refinedValue =
      val.indexOf('.') >= 0 && val
        ? val.substr(0, val.indexOf('.')) + val.substr(val.indexOf('.'), precision)
        : val;
    this.formik.setFieldValue(e.target.name, refinedValue && Number(refinedValue));
  };

  // handle discount
  _handleDiscount = () => {
    const { discount_percentage, amount } = this.formik.values;
    return Helpers.round(Helpers.percentToAmount(discount_percentage, amount));
  };

  // handle taxes
  _handleTaxes = () => {
    const { gst, tcs, amount } = this.formik.values;
    let gstAmount = 0;
    let tcsAmount = 0;

    if (gst > 0 && isNumber(gst)) {
      gstAmount = Helpers.round(Helpers.percentToAmount(gst, amount));
    }

    if (tcs > 0 && isNumber(tcs)) {
      tcsAmount = Helpers.round(Helpers.percentToAmount(tcs, amount));
    }

    return {
      gstAmount,
      tcsAmount,
    };
  };

  resetPaymentScheduleDates = () => {
    if (this.formik.values?.payment_events && this.formik.values?.payment_events.length > 0) {
      this.formik.values?.payment_events.forEach((data, index) => {
        this.formik.setFieldValue(
          `payment_events[${index}].amount_required`,
          data?.amount_required || 0,
        );
        const balancePaymentDate = Helpers.addWeekdays(moment(), 8).format('YYYY-MM-DD');
        if (index === 0) {
          this.formik.setFieldValue(`payment_events[${index}].date`, balancePaymentDate);
        } else {
          this.formik.setFieldValue(`payment_events[${index}].date`, '');
        }
      });
    }
  };

  _computeOfferAmountInitially = ({ productOffer }) => {
    let is_discount_percentage = Number(productOffer?.discountPercentage) || '';
    let gst =
      Number(productOffer?.taxes?.find((val) => val.taxFieldName === 'gst')?.percentage) || '';
    let tcs = Number(productOffer?.taxes?.find((val) => val.taxFieldName === 'tcs')?.percentage);
    let finalOfferAmount = Number(productOffer?.amount);

    if (gst || tcs) {
      let { gstAmount, tcsAmount } = this._handleTaxes();
      finalOfferAmount = finalOfferAmount + gstAmount + tcsAmount;
    }

    if (is_discount_percentage) {
      let discount = this._handleDiscount();
      finalOfferAmount -= discount;
    }

    finalOfferAmount = finalOfferAmount && Helpers.round(finalOfferAmount);
    this.setState(
      {
        offerAmount: {
          ...this.state.offerAmount,
          afterDiscountAndTaxes: finalOfferAmount,
        },
      },
      () => this._computePaymentScheduleInitially({ productOffer }),
    );
  };

  _computePaymentScheduleInitially = ({ productOffer }) => {
    let upfront_payment_amount = Number(productOffer?.upfrontPaymentAmount) || '';
    const {
      offerAmount: { afterDiscountAndTaxes: finalOfferAmount },
    } = this.state;
    let afterBookingAmount = finalOfferAmount;
    afterBookingAmount = finalOfferAmount - upfront_payment_amount;
    if (afterBookingAmount < 0) {
      this.formik.setFieldTouched('upfront_payment_amount', true);
      afterBookingAmount = 0;
    }

    this.setState(
      {
        offerAmount: {
          ...this.state.offerAmount,
          afterBookingAmount: Helpers.round(afterBookingAmount),
        },
      },
      () => this._computeBalancePayment(),
    );
  };

  _loadPaymentSchedule = () => {
    const { paymentSchedule: { paymentEvents = {} } = {} } = this.props?.productOffer;

    paymentEvents.forEach(({ amountRequired, date }, index) => {
      this.formik.setFieldValue(`payment_events[${index}].amount_required`, Number(amountRequired));
      this.formik.setFieldValue(`payment_events[${index}].date`, moment(date, 'DDMMYYYY').toDate());
    });
    this.setState({
      loadScheduleFromProps: false,
    });
  };

  _computeOfferAmountInitially = ({ productOffer }) => {
    let is_discount_percentage = Number(productOffer?.discountPercentage) || '';
    let gst =
      Number(productOffer?.taxes?.find((val) => val.taxFieldName === 'gst')?.percentage) || '';
    let tcs = Number(productOffer?.taxes?.find((val) => val.taxFieldName === 'tcs')?.percentage);
    let finalOfferAmount = Number(productOffer?.amount);

    if (gst || tcs) {
      let { gstAmount, tcsAmount } = this._handleTaxes();
      finalOfferAmount = finalOfferAmount + gstAmount + tcsAmount;
    }

    if (is_discount_percentage) {
      let discount = this._handleDiscount();
      finalOfferAmount -= discount;
    }

    finalOfferAmount = finalOfferAmount && Helpers.round(finalOfferAmount);
    this.setState(
      {
        offerAmount: {
          ...this.state.offerAmount,
          afterDiscountAndTaxes: finalOfferAmount,
        },
      },
      () => this._computePaymentScheduleInitially({ productOffer }),
    );
  };

  _computePaymentScheduleInitially = ({ productOffer }) => {
    let upfront_payment_amount = Number(productOffer?.upfrontPaymentAmount) || '';
    const {
      offerAmount: { afterDiscountAndTaxes: finalOfferAmount },
    } = this.state;
    let afterBookingAmount = finalOfferAmount;
    afterBookingAmount = finalOfferAmount - upfront_payment_amount;
    if (afterBookingAmount < 0) {
      this.formik.setFieldTouched('upfront_payment_amount', true);
      afterBookingAmount = 0;
    }

    this.setState(
      {
        offerAmount: {
          ...this.state.offerAmount,
          afterBookingAmount: Helpers.round(afterBookingAmount),
        },
      },
      () => this._computeBalancePayment(),
    );
  };

  // This function is fired everytime any of the following fields are edited
  // base offer amount, taxes, discount
  // resulting in recomputation of payment schedule
  _computeOfferAmount = () => {
    const {
      amount,
      is_discount_percentage,
      gst,
      tcs,
      payment_methods_available: paymentMethodsAvailable,
    } = this.formik.values;
    let finalOfferAmount = amount;
    let resetBookingAmount = 0;
    let resetFirstPaymentAmount = 0;

    if (gst || tcs) {
      let { gstAmount, tcsAmount } = this._handleTaxes();
      finalOfferAmount = finalOfferAmount + gstAmount + tcsAmount;
    }

    if (is_discount_percentage) {
      let discount = this._handleDiscount();
      finalOfferAmount -= discount;
    }

    finalOfferAmount = finalOfferAmount && Helpers.round(finalOfferAmount);

    // setup default values for upfront payment and balance amount
    // loadschedulefrom props is set to true in ofeer detail success callback & its purpose is to initially load schedule from data
    // while updating the offer
    if (this.state.loadScheduleFromProps) {
      this._loadPaymentSchedule();
    } else {
      const balancePaymentDate = Helpers.addWeekdays(moment(), 8).toDate();

      resetBookingAmount = Helpers.round(Helpers.percentToAmount('25', finalOfferAmount));

      resetFirstPaymentAmount = Helpers.round(finalOfferAmount - resetBookingAmount);
      if (resetFirstPaymentAmount) {
        const [, resetFirstPaymentAmountAfterDecimal] = resetFirstPaymentAmount
          .toString()
          .split('.');
        if (resetFirstPaymentAmountAfterDecimal) {
          resetFirstPaymentAmount -= Number(`0.${resetFirstPaymentAmountAfterDecimal}`);
          resetBookingAmount += Number(`0.${resetFirstPaymentAmountAfterDecimal}`);
        }
      }

      // this.formik.setFieldValue('payment_events', []);
      this.formik.setFieldValue('upfront_payment_amount', Helpers.round(resetBookingAmount));
      if (
        paymentMethodsAvailable.length === 1 &&
        paymentMethodsAvailable.indexOf('pay_in_instalments') != -1
      ) {
        this.formik.setFieldValue('payment_events[0].amount_required', resetFirstPaymentAmount);
        this.formik.setFieldValue('payment_events[0].date', balancePaymentDate);
      }
    }

    this.setState(
      {
        offerAmount: {
          ...this.state.offerAmount,
          afterDiscountAndTaxes: finalOfferAmount,
        },
      },
      this._computePaymentSchedule,
    );
  };

  _computePaymentSchedule = () => {
    const { upfront_payment_amount, payment_methods_available, final_date } = this.formik.values;
    const {
      offerAmount: { afterDiscountAndTaxes: finalOfferAmount },
    } = this.state;

    let afterBookingAmount = finalOfferAmount;

    afterBookingAmount = finalOfferAmount - upfront_payment_amount;

    if (afterBookingAmount < 0) {
      this.formik.setFieldTouched('upfront_payment_amount', true);
      afterBookingAmount = 0;
    }

    this.setState(
      {
        offerAmount: {
          ...this.state.offerAmount,
          afterBookingAmount: Helpers.round(afterBookingAmount),
        },
      },
      () => {
        if (payment_methods_available.includes('book_save_buy')) {
          this.props.adminPaymentScheduleRequest({
            amount: this.state?.offerAmount?.afterBookingAmount || 0,
            end_date: moment(final_date).format('DD/MM/YYYY'),
          });
        } else {
          this._computeBalancePayment();
        }
      },
    );
  };

  _computeBalancePayment = () => {
    this._setPaymentEventsTouched();
    let paymentEventsError = {};

    const { payment_events } = this.formik.values;
    const {
      offerAmount: { afterBookingAmount },
    } = this.state;
    const paymentEventsTotal = payment_events.reduce(
      (acc, obj) => acc + (Number(obj.amount_required) || 0),
      0,
    );
    const paymentEventsDates = payment_events.map((item) => item.date);

    const remainingScheduleAmount = afterBookingAmount - paymentEventsTotal;

    if (afterBookingAmount > 0) {
      if (paymentEventsTotal !== afterBookingAmount) {
        paymentEventsError[
          'paymentEventsTotal'
        ] = `Total Balance amount must be equal to ${Convert.toCurrencyFormat(
          remainingScheduleAmount,
        )}`;
      }

      if (Helpers.findDuplicatesInArray(paymentEventsDates).length) {
        paymentEventsError['paymentEventsDates'] = 'Two Balance dates cannot be set to same day.';
      }
    }

    if (!Object.keys(paymentEventsError).length) {
      this.setState({
        offerAmount: {
          ...this.state.offerAmount,
          remainingScheduleAmount,
        },
      });
    }

    this.formik.setStatus({
      ...paymentEventsError,
    });
  };

  //  Step 1: create an offer
  _handleSubmit = async (values) => {
    const { status = {} } = this.formik;
    if (Object.keys(status).length > 0) {
      return false;
    }
    const { payment_events, tcs, gst, discount_percentage, amount } = values;
    const formValues = { ...values };

    const removeFields = ['payment_events', 'tcs', 'gst', 'book_save_buy', 'pay_in_instalments'];
    removeFields.forEach((key) => delete formValues[key]);

    const taxFields = {
      tcs,
      gst,
    };

    // const paymentEvents = payment_events.map(item => ({
    //   ...item,
    //   amount_required: Helpers.round(item.amount_required,0)
    // }))

    // formValues['upfront_payment_amount'] = Helpers.round(formValues['upfront_payment_amount'], 0)
    const paymentEvents = payment_events.map((item) => ({
      ...item,
      date: moment(item.date).format('YYYY-MM-DD'),
    }));

    const finalDate = moment(values.final_date).format('YYYY-MM-DD');
    let expiryDate = '';
    if (values?.expiry_date) expiryDate = moment(values.expiry_date).format('YYYY-MM-DD');
    let sortedPaymentCalendar = [];
    if (paymentEvents && paymentEvents.length > 0 && !this.state.isEdit && !expiryDate) {
      sortedPaymentCalendar = paymentEvents.sort(
        (a, b) => moment(a.date, 'YYYY-MM-DD').valueOf() - moment(b.date, 'YYYY-MM-DD').valueOf(),
      );
      const firstPaymentEvent = sortedPaymentCalendar[0];
      expiryDate = moment(firstPaymentEvent?.date, 'YYYY-MM-DD')
        .subtract(5, 'days')
        .format('YYYY-MM-DD');
    }

    formValues['expiry_date'] = expiryDate || moment().add(2, 'd').format('YYYY-MM-DD');

    formValues['payment_schedule'] = {
      final_date: finalDate,
      payment_events: paymentEvents,
    };

    // organize tax array
    formValues['taxes'] = [];

    formValues['final_date'] = finalDate;

    Object.keys(taxFields).forEach((taxField) => {
      if (taxFields[taxField]) {
        formValues['taxes'].push({ tax_field_name: taxField, percentage: taxFields[taxField] });
      }
    });

    if (values.is_discount_percentage) {
      formValues['discount_absolute_amount'] = Helpers.round(
        Helpers.percentToAmount(discount_percentage, amount),
      );
    }

    formValues['is_fixed_months'] = true;
    formValues['is_upfront_payment_required'] = values?.is_upfront_payment_required;

    if (this.state.isEdit) {
      this.props.requestAdminOfferUpdate({
        uuid: this.props?.match?.params?.uuid,
        product_offer: {
          ...formValues,
        },
      });
    } else {
      this.props.requestAdminOfferCreateV2({
        product_offer: {
          ...formValues,
        },
      });
    }
  };

  // Step 2: generates payment link
  _generatePaymentLink = (response) => {
    const { origin } = document.location;
    const {
      productOffer: { uuid },
    } = response;
    const { adminCredentials } = this.props;
    const {
      name_of_user,
      user_phone_number: userPhoneNumber,
      send_link_email: sendLinkEmail,
      expiry_date,
    } = this.formik.values;

    let expiryDate = '';
    if (expiry_date) expiryDate = moment(expiry_date).format('YYYY-MM-DD');

    const nameSplit = name_of_user.split(' ');
    const firstName = nameSplit[0];
    const lastName = nameSplit[1] || '';
    const expiry = expiryDate || moment().add(2, 'd').format('YYYY-MM-DD');

    const link = `${origin}?partnerAccessKey=${adminCredentials.partnerAccessKey}&productOfferUuid=${uuid}&firstName=${firstName}&lastName=${lastName}&email=${sendLinkEmail}&phoneNumber=+91${userPhoneNumber}`;

    const linkParams = {
      payment_link: {
        link,
        expiry,
        product_offer_uuid: uuid,
        sent_to_email: sendLinkEmail,
        sent_to_phone_number: userPhoneNumber,
        sent_to_name: name_of_user,
      },
    };

    this.props.requestShortenUrl(linkParams);
  };

  // step 3: create shrtened url
  _onUrlGenerated = (response) => {
    const { referenceId = '' } = response || {};
    const shortenedUrl = `${API_ENDPOINT_URL}?id=${referenceId}`;
    this.setState(
      {
        loading: false,
        shortenedUrl,
      },
      this._handleModal,
    );
  };

  subtractWeekdays = (date, days) => {
    date = moment(date); // use a clone
    while (days > 0) {
      date = date.subtract(1, 'days');
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }
    return date;
  };

  render() {
    const { loading, offerAmount } = this.state;

    return (
      <div>
        <Container
          title={
            this.state.loading
              ? ''
              : this.state.isEdit
              ? 'Update payment link'
              : 'Create a new payment link'
          }
          buttonText="create a new link"
        >
          <Formik
            initialValues={{
              product_offer_name: '',
              final_date: '',
              amount: '',
              is_discount_percentage: false,
              discount_percentage: '',
              gst: '',
              tcs: '',
              payment_events: [],
              upfront_payment_amount: '',
              book_save_buy: false,
              is_upfront_payment_required: true,
              pay_in_instalments: false,
              payment_methods_available: [],
              name_of_user: '',
              user_phone_number: '',
              send_link_email: '',
              notify_via_sms: false,
              notify_via_email: false,
              expiry: '',
              // discount: false
            }}
            validationSchema={this._validationSchema}
            onSubmit={this._handleSubmit}
            innerRef={(ref) => (this.formik = ref)}
            // enableReinitialize
          >
            {({ values, setFieldValue, status, setStatus, errors, touched }) => {
              const maxDays = 1;
              let maxExpiryCalendarDate = Helpers.addWeekdays(moment(), maxDays).toDate();
              if (values?.payment_events && values?.payment_events.length > 0) {
                const sortedPaymentCalendarDates = values?.payment_events.sort(
                  (a, b) => a.date - b.date,
                );
                const firstCalenderPaymentEvent = sortedPaymentCalendarDates[0];
                const firstCalenderPaymentDate = this.subtractWeekdays(
                  firstCalenderPaymentEvent?.date,
                  maxDays,
                ).format('YYYY-MM-DD');
                maxExpiryCalendarDate = this.subtractWeekdays(
                  firstCalenderPaymentEvent?.date,
                  maxDays,
                ).toDate();
                const isExpiryGreater =
                  moment(values?.expiry_date).format('YYYY-MM-DD') > firstCalenderPaymentDate;
                if (isExpiryGreater) {
                  setFieldValue('expiry_date', firstCalenderPaymentDate);
                }
              } else if (values?.expiry_date) {
                setFieldValue('expiry_date', '');
              }
              return (
                <Form>
                  <Section title="Offer Details">
                    <Row className={styles.rowOne}>
                      <Col sm={7}>
                        <TextInputOne
                          label="Name of offer"
                          name="product_offer_name"
                          id="product_offer_name"
                          type="text"
                        />
                      </Col>
                      {/* <Col sm={5}>
                      <TextInputOne
                        label="Final Payment date"
                        name="final_date"
                        id="final_date"
                        placeholder=""
                        type="date"
                        onChange={(e) => {
                          setFieldValue('final_date', e.target.value);
                          this.resetPaymentScheduleDates();
                        }}
                        min={Helpers.addWeekdays(moment(), 8).format('YYYY-MM-DD')}
                        // max={Helpers.addWeekdays(moment(), 8).format('YYYY-MM-DD')}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </Col> */}
                      <Col sm={5}>
                        <InputDatepickerTwo
                          label="Purchase date"
                          name="final_date"
                          id="final_date"
                          placeholder=""
                          value={
                            values.final_date ? moment(values.final_date).format('DD/MM/YYYY') : ''
                          }
                          minDate={Helpers.addWeekdays(moment(), 8).toDate()}
                          filterDate={this._dateFilter}
                          dateFormat="dd/MM/yyyy"
                          onChange={(final_date) => {
                            setFieldValue('final_date', final_date);
                            if (
                              (values.payment_methods_available || []).indexOf('book_save_buy') > -1
                            ) {
                              this.props.adminPaymentScheduleRequest({
                                amount: this.state?.offerAmount?.afterBookingAmount || 0,
                                end_date: moment(final_date).format('DD/MM/YYYY'),
                              });
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.rowOne}>
                      <Col sm={4}>
                        <TextInputCurrency
                          label="Base price of offer"
                          name="amount"
                          id="amount"
                          type="number"
                          min="0"
                          step="0.01"
                          onBlur={async () => {
                            this._computeOfferAmount();
                            await Promise.resolve();
                            if (
                              (values.payment_methods_available || []).indexOf('book_save_buy') > -1
                            ) {
                              this.props.adminPaymentScheduleRequest({
                                amount: this.state?.offerAmount?.afterBookingAmount || 0,
                                end_date: moment(values.final_date).format('DD/MM/YYYY'),
                              });
                            }
                          }}
                          onChange={this._handleChange}
                        />
                      </Col>
                      <Col md={3}>
                        <InputSwitch
                          label="Is there a discount offered?"
                          val={`${values.is_discount_percentage ? 'Yes' : 'No'}`}
                          name="is_discount_percentage"
                          id="is_discount_percentage"
                          labelPosition="right"
                          checked={values.is_discount_percentage}
                          onClick={async (e) => {
                            setFieldValue('is_discount_percentage', e.target.value);
                            await Promise.resolve();
                            this._computeOfferAmount();
                          }}
                        />
                      </Col>
                      {values.is_discount_percentage && (
                        <Col sm={12} md={4}>
                          <TextInputOne
                            label="Enter Discount Percentage"
                            type="number"
                            min="0"
                            max="100"
                            name="discount_percentage"
                            id="discount_percentage"
                            inline
                            suffix="%"
                            step="0.01"
                            onBlur={async () => {
                              this._computeOfferAmount();
                              await Promise.resolve();
                              if (
                                (values.payment_methods_available || []).indexOf('book_save_buy') >
                                -1
                              ) {
                                this.props.adminPaymentScheduleRequest({
                                  amount: this.state?.offerAmount?.afterBookingAmount || 0,
                                  end_date: moment(values.final_date).format('DD/MM/YYYY'),
                                });
                              }
                            }}
                            onChange={this._handleChange}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <TextOne>Add Taxes</TextOne>
                        <div className={styles.stackedFields}>
                          <TextInputOne
                            name="gst"
                            type="number"
                            min="0"
                            max="100"
                            id="gst"
                            inline
                            prefix="GST"
                            suffix="%"
                            decimalsAllowed="0"
                            onBlur={async () => {
                              this._computeOfferAmount();
                              await Promise.resolve();
                              if (
                                (values.payment_methods_available || []).indexOf('book_save_buy') >
                                -1
                              ) {
                                this.props.adminPaymentScheduleRequest({
                                  amount: this.state?.offerAmount?.afterBookingAmount || 0,
                                  end_date: moment(values.final_date).format('DD/MM/YYYY'),
                                });
                              }
                            }}
                            onChange={this._handleChange}
                          />
                          <TextInputOne
                            name="tcs"
                            type="number"
                            min="0"
                            max="100"
                            id="tcs"
                            inline
                            prefix="TCS"
                            suffix="%"
                            decimalsAllowed="0"
                            onBlur={async () => {
                              this._computeOfferAmount();
                              await Promise.resolve();
                              if (
                                (values.payment_methods_available || []).indexOf('book_save_buy') >
                                -1
                              ) {
                                this.props.adminPaymentScheduleRequest({
                                  amount: this.state?.offerAmount?.afterBookingAmount || 0,
                                  end_date: moment(values.final_date).format('DD/MM/YYYY'),
                                });
                              }
                            }}
                            onChange={this._handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Section>
                  {/* <Section title="Savvy Credits">
                      <Row className={styles.rowOne}>
                        <Col xl={4} sm={6}>
                          <InputSwitch
                            label="Offer a savvy discount?"
                            val={`${values.discount ? 'Yes' : 'No'}`}
                            name="discount"
                            id="discount"
                            labelPosition="right"
                            checked={values.discount}
                          />
                        </Col>
                        <Col xl={4} sm={6}>
                          <TextInputOne
                            label="Enter amount you would like to offer"
                            name="fieldname"
                            prefix="Rs."
                          />
                        </Col>
                      </Row>
                    </Section> */}
                  <Section title="What type of payment methods would you like to offer?">
                    <Row>
                      <Col md={8}>
                        <InfoBox type="info" className={styles.infoBox}>
                          <TextOne style={{ fontSize: '0.7rem' }}>
                            BSB (Book Save Buy) works best for plans beyond 2 months, and PII (Pay
                            in instalments) works best for plans less than 2 months.
                          </TextOne>
                        </InfoBox>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputCheckboxTwo
                          label="Book.Save.Buy"
                          name="book_save_buy"
                          id="book_save_buy"
                          onChange={(e) => {
                            const paymentMethodsAvailable = values.payment_methods_available;
                            if (e.target.checked) {
                              paymentMethodsAvailable.push('book_save_buy');
                              this.props.adminPaymentScheduleRequest({
                                amount: this.state?.offerAmount?.afterBookingAmount || 0,
                                end_date: moment(values.final_date).format('DD/MM/YYYY'),
                              });
                            } else {
                              const index = paymentMethodsAvailable.indexOf('book_save_buy');
                              paymentMethodsAvailable.splice(index, 1);
                              this.formik.setFieldValue(`payment_events`, []);
                              this.formik.setFieldValue(
                                `payment_events[0].amount_required`,
                                this.state?.offerAmount?.afterBookingAmount || 0,
                              );
                              this.formik.setFieldValue(
                                `payment_events[0].date`,
                                Helpers.addWeekdays(moment(), 8).toDate(),
                              );
                            }
                            setStatus({});
                            setFieldValue('book_save_buy', e.target.checked);
                            this._handlePaymentMethodChange(e);
                          }}
                          checkboxContainer={styles.checkboxContainer}
                          suffix={
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="tooltip-1">
                                  Charge your customers a minimum booking amount, allow them to save
                                  for the rest of the payment in ICICI Prudential Liquid Fund and
                                  pay you within the schedule you set up below.
                                </Tooltip>
                              }
                            >
                              <AiFillInfoCircle className={styles.tooltip} />
                            </OverlayTrigger>
                          }
                        />
                      </Col>
                      <Col>
                        <InputCheckboxTwo
                          label="Pay in instalments"
                          name="pay_in_instalments"
                          id="pay_in_instalments"
                          onChange={(e) => {
                            const paymentMethodsAvailable = values.payment_methods_available;
                            if (e.target.checked) {
                              paymentMethodsAvailable.push('pay_in_instalments');
                              this._computeOfferAmount();
                            } else {
                              const index = paymentMethodsAvailable.indexOf('pay_in_instalments');
                              paymentMethodsAvailable.splice(index, 1);
                            }
                            setFieldValue('pay_in_instalments', e.target.checked);
                            this._handlePaymentMethodChange(e);
                          }}
                          checkboxContainer={styles.checkboxContainer}
                          suffix={
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="tooltip-2">
                                  Charge your customers a minimum booking amount, the remaining
                                  balance will be paid to you as per the schedule you set below.
                                </Tooltip>
                              }
                            >
                              <AiFillInfoCircle className={styles.tooltip} />
                            </OverlayTrigger>
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        {/* <TextOne>
                        Charge your customers a minimum booking amount, allow them to save for the
                        rest of the payment in ICICI prudential liquid fund and pay within the
                        schedule you set up above.
                      </TextOne> */}
                      </Col>
                      {errors.payment_methods_available && touched.payment_methods_available && (
                        <Col sm={12}>
                          <TextOne variant="error">{errors.payment_methods_available}</TextOne>
                        </Col>
                      )}
                    </Row>
                  </Section>
                  <Section
                    title={`Structure a payment schedule for ${
                      Convert.toCurrencyFormat(offerAmount.afterDiscountAndTaxes) || 'X'
                    }`}
                  >
                    <Row className={styles.rowOne}>
                      <Col sm={4}>
                        <InputCheckboxTwo
                          label="Is booking amount required"
                          name="is_upfront_payment_required"
                          id="is_upfront_payment_required"
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.formik.setFieldValue(`upfront_payment_amount`, '');
                              setTimeout(() => {
                                this._computePaymentSchedule();
                              }, 200);
                            }
                            setFieldValue('is_upfront_payment_required', e.target.checked);
                          }}
                          checkboxContainer={styles.checkboxContainer}
                        />
                        <TextInputCurrency
                          label="Amount required at time of booking"
                          type="number"
                          name="upfront_payment_amount"
                          id="upfront_payment_amount"
                          min="0"
                          step="0.01"
                          onBlur={this._computePaymentSchedule}
                          onChange={this._handleChange}
                          disabled={this.state.isEdit}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.rowOne}>
                      <Col>
                        <TextOne>
                          Remaining amount to be received:{' '}
                          {Convert.toCurrencyFormat(offerAmount.remainingScheduleAmount) || 'X'}
                        </TextOne>
                      </Col>
                    </Row>
                    {this.state?.offerAmount?.afterBookingAmount > 0 &&
                      values.payment_methods_available.length > 0 && (
                        <FieldArray
                          name="payment_events"
                          render={(arrayHelpers) => (
                            <>
                              {values.payment_events.length > 0 &&
                                values.payment_events.map((paymentEvent, index) => {
                                  const finalDate = moment(values.final_date);
                                  const minDate = Helpers.addWeekdays(moment(), 8).toDate();
                                  const maxDate = values.final_date
                                    ? values.payment_methods_available.includes('book_save_buy')
                                      ? Helpers.addWeekdays(finalDate, -8).toDate()
                                      : finalDate.toDate()
                                    : '';

                                  return (
                                    <Row className={styles.rowOne} key={index}>
                                      <Col sm={4}>
                                        <TextInputCurrency
                                          label="Balance amount Receivable"
                                          type="number"
                                          name={`payment_events[${index}].amount_required`}
                                          id={`payment_events[${index}].amount_required`}
                                          min="0"
                                          step="0.01"
                                          onBlur={this._computeBalancePayment}
                                          onChange={this._handleChange}
                                          disabled={
                                            values.payment_methods_available.includes(
                                              'book_save_buy',
                                            ) || this.state.isEdit
                                          }
                                        />
                                      </Col>
                                      <Col sm={4}>
                                        <InputDatepickerTwo
                                          label="Choose Date"
                                          name={`payment_events[${index}].date`}
                                          id={`payment_events[${index}].date`}
                                          minDate={minDate}
                                          maxDate={maxDate}
                                          filterDate={this._paymentEventsDateFilter}
                                          onBlur={this._computeBalancePayment}
                                          dateFormat="dd/MM/yyyy"
                                          onChange={async (val) => {
                                            setFieldValue(`payment_events[${index}].date`, val);
                                            await Promise.resolve();
                                            this._computeBalancePayment();
                                          }}
                                          disabled={
                                            values.payment_methods_available.includes(
                                              'book_save_buy',
                                            ) || this.state.isEdit
                                          }
                                        />
                                      </Col>
                                      {index > 0 &&
                                        !values.payment_methods_available.includes(
                                          'book_save_buy',
                                        ) &&
                                        !this.state.isEdit && (
                                          <Col className={styles.removeField}>
                                            <TextOne
                                              variant="link"
                                              onClick={async () => {
                                                await arrayHelpers.remove(index, '');
                                                this._computeBalancePayment();
                                              }}
                                            >
                                              Remove
                                            </TextOne>
                                          </Col>
                                        )}
                                    </Row>
                                  );
                                })}
                              {status && status.paymentEventsTotal && (
                                <TextOne block variant="error">
                                  {status.paymentEventsTotal}
                                </TextOne>
                              )}
                              {status && status.paymentEventsDates && (
                                <TextOne block variant="error">
                                  {status.paymentEventsDates}
                                </TextOne>
                              )}
                              {!values.payment_methods_available.includes('book_save_buy') &&
                                !this.state.isEdit && (
                                  <TextOne
                                    onClick={() => arrayHelpers.push({})}
                                    variant="link"
                                    className={styles.rowLink}
                                  >
                                    Add Row
                                  </TextOne>
                                )}
                            </>
                          )}
                        />
                      )}
                  </Section>

                  {!this.state.isEdit && (
                    <Section title="Enter customer information (optional)">
                      <Row className={styles.rowOne}>
                        <Col sm={4}>
                          <TextInputOne
                            label="Customers Name"
                            name="name_of_user"
                            id="name_of_user"
                          />
                        </Col>
                        <Col sm={4}>
                          <TextInputOne
                            label="Customer Phone Number"
                            name="user_phone_number"
                            id="user_phone_number"
                          />
                          {/* <InputCheckbox
                          label="Notify via SMS"
                          name="notify_via_sms"
                          id="notify_via_sms"
                          checkboxContainer={styles.checkboxContainer}
                        /> */}
                        </Col>
                        <Col sm={4}>
                          <TextInputOne
                            label="Customer Email"
                            name="send_link_email"
                            id="send_link_email"
                          />
                          {/* <InputCheckbox
                          label="Notify via Email"
                          name="notify_via_email"
                          id="notify_via_email"
                          checkboxContainer={styles.checkboxContainer}
                        /> */}
                        </Col>
                      </Row>
                    </Section>
                  )}
                  {/* {this.state.isEdit && ( */}
                  <Section>
                    <Row>
                      <Col sm={4}>
                        <InputDatepickerTwo
                          label="Expiry date"
                          name="expiry_date"
                          id="expiry_date"
                          placeholder=""
                          value={
                            values.expiry_date
                              ? moment(values.expiry_date).format('DD/MM/YYYY')
                              : ''
                          }
                          maxDate={maxExpiryCalendarDate}
                          filterDate={this._dateFilter}
                          dateFormat="dd/MM/yyyy"
                          onChange={(expiry_date) => {
                            setFieldValue('expiry_date', expiry_date);
                          }}
                        />
                      </Col>
                      {/* <Col sm={2}>
                        <TextOne block center className="pt-4 mt-2">
                          or
                        </TextOne>
                      </Col> */}

                      {/* <Col sm={3}>
                        <TextOne block center semiBold className="pt-4 mt-2">
                          This offer will expire in 48 hours
                        </TextOne>
                      </Col> */}
                    </Row>
                  </Section>
                  {/* )} */}
                  <input
                    id="offerAmount"
                    name="offerAmountAfterTaxes"
                    value={this.state.offerAmount.afterBookingAmount}
                    style={{ display: 'none' }}
                  />
                  <div className={styles.buttonContainer}>
                    <ButtonOne
                      text={this.state.isEdit ? 'Update payment Link' : 'Generate Link'}
                      type="submit"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>
        <ModalPaymentLink
          shortenedUrl={this.state.shortenedUrl}
          show={this.state.showModal}
          offerName={this.props?.productOffer?.productOfferName}
          handleModal={this._handleModal}
        />
        <Loader loading={loading} />
      </div>
    );
  }
}

export default GeneratePaymentLink;

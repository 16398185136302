import { connect } from 'react-redux';
import { 
  updateUserRequest, 
  requestBankDetails, 
  purchaseBanksRequest, 
  updateBankAccountRequest  
} from 'src/redux/actions';
import LinkBankAccount from './link-bank-account';

function mapStateToProps(state) {
  return {
    user: state.user,     
    savingPlan: state.savingPlan,
  };
}

export default connect(mapStateToProps, { 
  updateUserRequest, 
  requestBankDetails, 
  purchaseBanksRequest, 
  updateBankAccountRequest 
})(LinkBankAccount);

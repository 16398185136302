export const ACTION_TYPES = {
    REDUX_PERSIST                         : 'persist/PERSIST',
    REDUX_PERSIST_FLUSH                   : 'persist/FLUSH',
    REDUX_PERSIST_REHYDRATE               : 'persist/REHYDRATE',
    REDUX_PERSIST_PAUSE                   : 'persist/PAUSE',
    REDUX_PERSIST_PURGE                   : 'persist/PURGE',
    REDUX_PERSIST_REGISTER                : 'persist/REGISTER',
    
    UPDATE_CREDENTIALS              : 'UPDATE_CREDENTIALS',
    UPDATE_USER                     : 'UPDATE_USER',

    REQUEST_SIGN_IN_USER            : 'REQUEST_SIGN_IN_USER',
    SIGN_IN_USER_SUCCESS            : 'SIGN_IN_USER_SUCCESS',
    SIGN_IN_USER_FAILED             : 'SIGN_IN_USER_FAILED',

    REQUEST_VERIFY_OTP              : 'REQUEST_VERIFY_OTP',
    VERIFY_OTP_SUCCESS              : 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_FAILED               : 'VERIFY_OTP_FAILED',

    REQUEST_RESEND_OTP              : 'REQUEST_RESEND_OTP',
    RESEND_OTP_SUCCESS              : 'RESEND_OTP_SUCCESS',
    RESEND_OTP_FAILED               : 'RESEND_OTP_FAILED',

    REQUEST_LOGOUT                  : 'REQUEST_LOGOUT',
    REQUEST_LOGOUT_SUCCEEDED        : 'REQUEST_LOGOUT_SUCCEEDED',
    REQUEST_LOGOUT_FAILED           : 'REQUEST_LOGOUT_FAILED',

    UPDATE_PRODUCT                  : 'UPDATE_PRODUCT',

    REQUEST_PRODUCT_DETAIL           : 'REQUEST_PRODUCT_DETAIL',
    REQUEST_PRODUCT_DETAIL_SUCCEEDED : 'REQUEST_PRODUCT_DETAIL_SUCCEEDED',
    REQUEST_PRODUCT_DETAIL_FAILED    : 'REQUEST_PRODUCT_DETAIL_FAILED',

    REQUEST_PRODUCT_OFFERS           : 'REQUEST_PRODUCT_OFFERS',
    REQUEST_PRODUCT_OFFERS_SUCCEEDED : 'REQUEST_PRODUCT_OFFERS_SUCCEEDED',
    REQUEST_PRODUCT_OFFERS_FAILED    : 'REQUEST_PRODUCT_OFFERS_FAILED',

    REQUEST_PRODUCT_OFFERS_DELETE           : 'REQUEST_PRODUCT_OFFERS_DELETE',
    REQUEST_PRODUCT_OFFERS_SUCCEEDED_DELETE : 'REQUEST_PRODUCT_OFFERS_SUCCEEDED_DELETE',
    REQUEST_PRODUCT_OFFERS_FAILED_DELETE    : 'REQUEST_PRODUCT_OFFERS_FAILED_DELETE',

    UPDATE_SAVING_PLAN              : 'UPDATE_SAVING_PLAN',

    CREATE_SAVING_GOAL              : 'CREATE_SAVING_GOAL',
    CREATE_SAVING_GOAL_SUCCESS      : 'CREATE_SAVING_GOAL_SUCCESS',
    CREATE_SAVING_GOAL_FAILED       : 'CREATE_SAVING_GOAL_FAILED',

    REQUEST_GOAL_LIST              : 'REQUEST_GOAL_LIST',
    REQUEST_GOAL_LIST_SUCCESS      : 'REQUEST_GOAL_LIST_SUCCESS',
    REQUEST_GOAL_LIST_FAILED       : 'REQUEST_GOAL_LIST_FAILED',

    REQUEST_GOAL_DETAIL              : 'REQUEST_GOAL_DETAIL',
    REQUEST_GOAL_DETAIL_SUCCESS      : 'REQUEST_GOAL_DETAIL_SUCCESS',
    REQUEST_GOAL_DETAIL_FAILED       : 'REQUEST_GOAL_DETAIL_FAILED',

    REQUEST_TRANSACTION_LIST            : 'REQUEST_TRANSACTION_LIST',
    REQUEST_TRANSACTION_LIST_SUCCESS    : 'REQUEST_TRANSACTION_LIST_SUCCESS',
    REQUEST_TRANSACTION_LIST_FAILED     : 'REQUEST_TRANSACTION_LIST_FAILED',

    REQUEST_TRANSACTION_DETAIL            : 'REQUEST_TRANSACTION_DETAIL',
    REQUEST_TRANSACTION_DETAIL_SUCCESS    : 'REQUEST_TRANSACTION_DETAIL_SUCCESS',
    REQUEST_TRANSACTION_DETAIL_FAILED     : 'REQUEST_TRANSACTION_DETAIL_FAILED',
    
    REQUEST_GENERATE_PAYMENT        : 'REQUEST_GENERATE_PAYMENT',
    GENERATE_PAYMENT_SUCCESS        : 'GENERATE_PAYMENT_SUCCESS',
    GENERATE_PAYMENT_FAILED         : 'GENERATE_PAYMENT_FAILED',

    REQUEST_TAX_STATUS              : 'REQUEST_TAX_STATUS',
    REQUEST_TAX_STATUS_SUCCEEDED    : 'REQUEST_TAX_STATUS_SUCCEEDED',
    REQUEST_TAX_STATUS_FAILED       : 'REQUEST_TAX_STATUS_FAILED',

    REQUEST_VERIFY_USER_TOKEN       : 'REQUEST_VERIFY_USER_TOKEN',
    VERIFY_USER_TOKEN_SUCCESS       : 'VERIFY_USER_TOKEN_SUCCESS',
    VERIFY_USER_TOKEN_FAILED        :  'VERIFY_USER_TOKEN_FAILED',

    REQUEST_CHECK_PAN               : 'REQUEST_CHECK_PAN',
    REQUEST_CHECK_PAN_SUCCEEDED     : 'REQUEST_CHECK_PAN_SUCCEEDED',
    REQUEST_CHECK_PAN_FAILED        : 'REQUEST_CHECK_PAN_FAILED',

    REQUEST_SUBMIT_PAN              : 'REQUEST_SUBMIT_PAN',
    REQUEST_SUBMIT_PAN_SUCCEEDED    : 'REQUEST_SUBMIT_PAN_SUCCEEDED',
    REQUEST_SUBMIT_PAN_FAILED       : 'REQUEST_SUBMIT_PAN_FAILED',

    REQUEST_FULL_KYC_REGISTRATION             : 'REQUEST_FULL_KYC_REGISTRATION',
    REQUEST_FULL_KYC_REGISTRATION_SUCCEEDED   : 'REQUEST_FULL_KYC_REGISTRATION_SUCCEEDED',
    REQUEST_FULL_KYC_REGISTRATION_FAILED      : 'REQUEST_FULL_KYC_REGISTRATION_FAILED',

    REQUEST_KYC_LOGIN               : 'REQUEST_KYC_LOGIN',
    REQUEST_KYC_LOGIN_SUCCEEDED     : 'REQUEST_KYC_LOGIN_SUCCEEDED',
    REQUEST_KYC_LOGIN_FAILED        : 'REQUEST_KYC_LOGIN_FAILED',

    REQUEST_OTM_BANKS               : 'REQUEST_OTM_BANKS',
    REQUEST_OTM_BANKS_SUCCEEDED     : 'REQUEST_OTM_BANKS_SUCCEEDED',
    REQUEST_OTM_BANKS_FAILED        : 'REQUEST_OTM_BANKS_FAILED',

    REQUEST_BANK_DETAILS              : 'REQUEST_BANK_DETAILS',
    REQUEST_BANK_DETAILS_SUCCEEDED    : 'REQUEST_BANK_DETAILS_SUCCEEDED',
    REQUEST_BANK_DETAILS_FAILED       : 'REQUEST_BANK_DETAILS_FAILED',

    REQUEST_SEND_PENNY_DROP           : 'REQUEST_SEND_PENNY_DROP',
    REQUEST_SEND_PENNY_DROP_SUCCEEDED : 'REQUEST_SEND_PENNY_DROP_SUCCEEDED',
    REQUEST_SEND_PENNY_DROP_FAILED    : 'REQUEST_SEND_PENNY_DROP_FAILED',

    REQUEST_SUBMIT_BANK_ACCOUNT             : 'REQUEST_SUBMIT_BANK_ACCOUNT',
    REQUEST_SUBMIT_BANK_ACCOUNT_SUCCEEDED   : 'REQUEST_SUBMIT_BANK_ACCOUNT_SUCCEEDED',
    REQUEST_SUBMIT_BANK_ACCOUNT_FAILED      : 'REQUEST_SUBMIT_BANK_ACCOUNT_FAILED',

    REQUEST_OCCUPATION_LIST           : 'REQUEST_OCCUPATION_LIST',
    REQUEST_OCCUPATION_LIST_SUCCEEDED : 'REQUEST_OCCUPATION_LIST_SUCCEEDED',
    REQUEST_OCCUPATION_LIST_FAILED    : 'REQUEST_OCCUPATION_LIST_FAILED',

    REQUEST_EMAIL_VALIDATION            :   'REQUEST_EMAIL_VALIDATION',
    REQUEST_EMAIL_VALIDATION_SUCCEEDED  :   'REQUEST_EMAIL_VALIDATION_SUCCEEDED',
    REQUEST_EMAIL_VALIDATION_FAILED     :   'REQUEST_EMAIL_VALIDATION_FAILED',

    REQUEST_SUBMIT_INVESTOR_DETAILS           : 'REQUEST_SUBMIT_INVESTOR_DETAILS',
    REQUEST_SUBMIT_INVESTOR_DETAILS_SUCCEEDED : 'REQUEST_SUBMIT_INVESTOR_DETAILS_SUCCEEDED',
    REQUEST_SUBMIT_INVESTOR_DETAILS_FAILED    : 'REQUEST_SUBMIT_INVESTOR_DETAILS_FAILED',

    REQUEST_SUBMIT_INVESTOR_KYC           : 'REQUEST_SUBMIT_INVESTOR_KYC',
    REQUEST_SUBMIT_INVESTOR_KYC_SUCCEEDED : 'REQUEST_SUBMIT_INVESTOR_KYC_SUCCEEDED',
    REQUEST_SUBMIT_INVESTOR_KYC_FAILED    : 'REQUEST_SUBMIT_INVESTOR_KYC_FAILED',

    CREATE_CONTRACT_REQUEST : 'CREATE_CONTRACT_REQUEST',
    CREATE_CONTRACT_SUCCESS : 'CREATE_CONTRACT_SUCCESS',
    CREATE_CONTRACT_FAILED  : 'CREATE_CONTRACT_FAILED',

    LONG_KYC_VERIFICATION_REQUEST : 'LONG_KYC_VERIFICATION_REQUEST',
    LONG_KYC_VERIFICATION_SUCCESS : 'LONG_KYC_VERIFICATION_SUCCESS',
    LONG_KYC_VERIFICATION_FAILED  : 'LONG_KYC_VERIFICATION_FAILED',

    CREATE_FOLIO            : 'CREATE_FOLIO',
    CREATE_FOLIO_SUCCEEDED  : 'CREATE_FOLIO_SUCCEEDED',
    CREATE_FOLIO_FAILED     : 'CREATE_FOLIO_FAILED',

    CHECK_INSTANT_WITHDRAWAL_REQUEST : 'CHECK_INSTANT_WITHDRAWAL_REQUEST',
    CHECK_INSTANT_WITHDRAWAL_SUCCESS : 'CHECK_INSTANT_WITHDRAWAL_SUCCESS',
    CHECK_INSTANT_WITHDRAWAL_FAILED  : 'CHECK_INSTANT_WITHDRAWAL_FAILED',

    FOLIO_WITHDRAW_REQUEST : 'FOLIO_WITHDRAW_REQUEST',
    FOLIO_WITHDRAW_SUCCESS : 'FOLIO_WITHDRAW_SUCCESS',
    FOLIO_WITHDRAW_FAILED  : 'FOLIO_WITHDRAW_FAILED',

    FOLIO_WITHDRAW_OTP_REQUEST : 'FOLIO_WITHDRAW_OTP_REQUEST',
    FOLIO_WITHDRAW_OTP_SUCCESS : 'FOLIO_WITHDRAW_OTP_SUCCESS',
    FOLIO_WITHDRAW_OTP_FAILED  : 'FOLIO_WITHDRAW_OTP_FAILED',

    FOLIO_WITHDRAW_RESEND_OTP_REQUEST : 'FOLIO_WITHDRAW_RESEND_OTP_REQUEST',
    FOLIO_WITHDRAW_RESEND_OTP_SUCCESS : 'FOLIO_WITHDRAW_RESEND_OTP_SUCCESS',
    FOLIO_WITHDRAW_RESEND_OTP_FAILED  : 'FOLIO_WITHDRAW_RESEND_OTP_FAILED',

    VALIDATE_FOLIO_WITHDRAW_REQUEST : 'VALIDATE_FOLIO_WITHDRAW_REQUEST',
    VALIDATE_FOLIO_WITHDRAW_SUCCESS : 'VALIDATE_FOLIO_WITHDRAW_SUCCESS',
    VALIDATE_FOLIO_WITHDRAW_FAILED  : 'VALIDATE_FOLIO_WITHDRAW_FAILED',

    UPDATE_FOLIO            : 'UPDATE_FOLIO',
    UPDATE_KYC_PARAMS       : 'UPDATE_KYC_PARAMS',

    REQUEST_CREATE_MANDATE : 'REQUEST_CREATE_MANDATE',
    CREATE_MANDATE_SUCCESS : 'CREATE_MANDATE_SUCCESS',
    CREATE_MANDATE_FAILED  : 'CREATE_MANDATE_FAILED',

    CREATE_DEPOSIT_REQUEST : 'CREATE_DEPOSIT_REQUEST',
    CREATE_DEPOSIT_SUCCESS : 'CREATE_DEPOSIT_SUCCESS',
    CREATE_DEPOSIT_FAILED  : 'CREATE_DEPOSIT_FAILED',

    FETCH_DEPOSIT_REQUEST : 'FETCH_DEPOSIT_REQUEST',
    FETCH_DEPOSIT_SUCCESS : 'FETCH_DEPOSIT_SUCCESS',
    FETCH_DEPOSIT_FAILED  : 'FETCH_DEPOSIT_FAILED',

    PURCHASE_BANKS_REQUEST : 'PURCHASE_BANKS_REQUEST',
    PURCHASE_BANKS_SUCCESS : 'PURCHASE_BANKS_SUCCESS',
    PURCHASE_BANKS_FAILED  : 'PURCHASE_BANKS_FAILED',

    UPDATE_BANK_ACCOUNT_REQUEST : 'UPDATE_BANK_ACCOUNT_REQUEST',
    UPDATE_BANK_ACCOUNT_SUCCESS : 'UPDATE_BANK_ACCOUNT_SUCCESS',
    UPDATE_BANK_ACCOUNT_FAILED  : 'UPDATE_BANK_ACCOUNT_FAILED',

    REQUEST_ADMIN_LOGIN     : 'REQUEST_ADMIN_LOGIN',
    ADMIN_LOGIN_SUCCESS     : 'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_FAILED      : 'ADMIN_LOGIN_FAILED',

    REQUEST_ADMIN_LOGOUT     : 'REQUEST_ADMIN_LOGOUT',
    ADMIN_LOGOUT_SUCCESS     : 'ADMIN_LOGOUT_SUCCESS',
    ADMIN_LOGOUT_FAILED      : 'ADMIN_LOGOUT_FAILED',

    REQUEST_ADMIN_PRODUCT_CREATE  : 'REQUEST_ADMIN_PRODUCT_CREATE',
    ADMIN_PRODUCT_CREATE_SUCCESS  : 'ADMIN_PRODUCT_CREATE_SUCCESS',
    ADMIN_PRODUCT_CREATE_FAILED   : 'ADMIN_PRODUCT_CREATE_FAILED',

    REQUEST_ADMIN_PRODUCT_UPDATE  : 'REQUEST_ADMIN_PRODUCT_UPDATE',
    ADMIN_PRODUCT_UPDATE_SUCCESS  : 'ADMIN_PRODUCT_UPDATE_SUCCESS',
    ADMIN_PRODUCT_UPDATE_FAILED   : 'ADMIN_PRODUCT_UPDATE_FAILED',
    
    REQUEST_ADMIN_PRODUCT_DETAIL  : 'REQUEST_ADMIN_PRODUCT_DETAIL',
    ADMIN_PRODUCT_DETAIL_SUCCESS  : 'ADMIN_PRODUCT_DETAIL_SUCCESS',
    ADMIN_PRODUCT_DETAIL_FAILED   : 'ADMIN_PRODUCT_DETAIL_FAILED',

    REQUEST_ADMIN_PRODUCT_LIST  : 'REQUEST_ADMIN_PRODUCT_LIST',
    ADMIN_PRODUCT_LIST_SUCCESS  : 'ADMIN_PRODUCT_LIST_SUCCESS',
    ADMIN_PRODUCT_LIST_FAILED   : 'ADMIN_PRODUCT_LIST_FAILED',

    REQUEST_ADMIN_PRODUCT_LIST_BY_NAME  : 'REQUEST_ADMIN_PRODUCT_LIST_BY_NAME',
    ADMIN_PRODUCT_LIST_BY_NAME_SUCCESS  : 'ADMIN_PRODUCT_LIST_BY_NAME_SUCCESS',
    ADMIN_PRODUCT_LIST_BY_NAME_FAILED   : 'ADMIN_PRODUCT_LIST_BY_NAME_FAILED',

    REQUEST_ADMIN_OFFER_CREATE      : 'REQUEST_ADMIN_OFFER_CREATE',
    REQUEST_ADMIN_OFFER_CREATE_V2   : 'REQUEST_ADMIN_OFFER_CREATE_V2',
    ADMIN_OFFER_CREATE_SUCCESS      : 'ADMIN_OFFER_CREATE_SUCCESS',
    ADMIN_OFFER_CREATE_FAILED       : 'ADMIN_OFFER_CREATE_FAILED',

    ADMIN_PAYMENT_SCHEDULE_REQUEST : 'ADMIN_PAYMENT_SCHEDULE_REQUEST',
    ADMIN_PAYMENT_SCHEDULE_SUCCESS : 'ADMIN_PAYMENT_SCHEDULE_SUCCESS',
    ADMIN_PAYMENT_SCHEDULE_FAILED  : 'ADMIN_PAYMENT_SCHEDULE_FAILED',

    REQUEST_ADMIN_OFFER_DELETE      : 'REQUEST_ADMIN_OFFER_DELETE',
    ADMIN_OFFER_DELETE_SUCCESS      : 'ADMIN_OFFER_DELETE_SUCCESS',
    ADMIN_OFFER_DELETE_FAILED       : 'ADMIN_OFFER_DELETE_FAILED',

    REQUEST_ADMIN_OFFER_COPY      : 'REQUEST_ADMIN_OFFER_COPY',
    ADMIN_OFFER_COPY_SUCCESS      : 'ADMIN_OFFER_COPY_SUCCESS',
    ADMIN_OFFER_COPY_FAILED       : 'ADMIN_OFFER_COPY_FAILED',

    REQUEST_ADMIN_AGENT_CREATE  : 'REQUEST_ADMIN_AGENT_CREATE',
    ADMIN_AGENT_CREATE_SUCCESS  : 'ADMIN_AGENT_CREATE_SUCCESS',
    ADMIN_AGENT_CREATE_FAILED   : 'ADMIN_AGENT_CREATE_FAILED',
    
    REQUEST_ADMIN_OFFER_UPDATE  : 'REQUEST_ADMIN_OFFER_UPDATE',
    ADMIN_OFFER_UPDATE_SUCCESS  : 'ADMIN_OFFER_UPDATE_SUCCESS',
    ADMIN_OFFER_UPDATE_FAILED   : 'ADMIN_OFFER_UPDATE_FAILED',

    REQUEST_ADMIN_OFFER_DETAIL : 'REQUEST_ADMIN_OFFER_DETAIL',
    ADMIN_OFFER_DETAIL_SUCCESS : 'ADMIN_OFFER_DETAIL_SUCCESS',
    ADMIN_OFFER_DETAIL_FAILED  : 'ADMIN_OFFER_DETAIL_FAILED',

    CREATE_ADMIN_PRODUCT  : 'CREATE_ADMIN_PRODUCT',
    CREATE_ADMIN_PRODUCT_SUCCESS  : 'CREATE_ADMIN_PRODUCT_SUCCESS',
    CREATE_ADMIN_PRODUCT_FAILED   : 'CREATE_ADMIN_PRODUCT_FAILED',

    REQUEST_ADMIN_OFFERS_LIST  : 'REQUEST_ADMIN_OFFERS_LIST',
    ADMIN_OFFERS_LIST_SUCCESS  : 'ADMIN_OFFERS_LIST_SUCCESS',
    ADMIN_OFFERS_LIST_FAILED   : 'ADMIN_OFFERS_LIST_FAILED',
    
    REQUEST_ADMIN_OFFERS_LIST_BY_PRODUCT_ID  : 'REQUEST_ADMIN_OFFERS_LIST_BY_PRODUCT_ID',
    ADMIN_OFFERS_LIST_BY_PRODUCT_ID_SUCCESS  : 'ADMIN_OFFERS_LIST_BY_PRODUCT_ID_SUCCESS',
    ADMIN_OFFERS_LIST_BY_PRODUCT_ID_FAILED   : 'ADMIN_OFFERS_LIST_BY_PRODUCT_ID_FAILED',

    REQUEST_ADMIN_USER_DETAIL : 'REQUEST_ADMIN_USER_DETAIL',
    ADMIN_USER_DETAIL_SUCCESS : 'ADMIN_USER_DETAIL_SUCCESS',
    ADMIN_USER_DETAIL_FAILED  : 'ADMIN_USER_DETAIL_FAILED',

    CREATE_ADMIN_OFFER  : 'CREATE_ADMIN_OFFER',
    CREATE_ADMIN_OFFER_SUCCESS  : 'CREATE_ADMIN_OFFER_SUCCESS',
    CREATE_ADMIN_OFFER_FAILED   : 'CREATE_ADMIN_OFFER_FAILED',

    REQUEST_ADMIN_USERS_LIST  : 'REQUEST_ADMIN_USERS_LIST',
    ADMIN_USERS_LIST_SUCCESS  : 'ADMIN_USERS_LIST_SUCCESS',
    ADMIN_USERS_LIST_FAILED   : 'ADMIN_USERS_LIST_FAILED',

    REQUEST_ADMIN_USER_PURCHASES  : 'REQUEST_ADMIN_USER_PURCHASES',
    ADMIN_USER_PURCHASES_SUCCESS  : 'ADMIN_USER_PURCHASES_SUCCESS',
    ADMIN_USER_PURCHASES_FAILED   : 'ADMIN_USER_PURCHASES_FAILED',

    REQUEST_ADMIN_GOAL_DETAIL  : 'REQUEST_ADMIN_GOAL_DETAIL',
    ADMIN_GOAL_DETAIL_SUCCESS  : 'ADMIN_GOAL_DETAIL_SUCCESS',
    ADMIN_GOAL_DETAIL_FAILED   : 'ADMIN_GOAL_DETAIL_FAILED',

    REQUEST_ADMIN_GOALS_LIST  : 'REQUEST_ADMIN_GOALS_LIST',
    ADMIN_GOALS_LIST_SUCCESS  : 'ADMIN_GOALS_LIST_SUCCESS',
    ADMIN_GOALS_LIST_FAILED   : 'ADMIN_GOALS_LIST_FAILED',

    REQUEST_SHORTEN_URL  : 'REQUEST_SHORTEN_URL',
    REQUEST_SHORTEN_URL_SUCCESS  : 'REQUEST_SHORTEN_URL_SUCCESS',
    REQUEST_SHORTEN_URL_FAILED   : 'REQUEST_SHORTEN_URL_FAILED',

    REQUEST_ADMIN_TRANSACTION_LIST            : 'REQUEST_ADMIN_TRANSACTION_LIST',
    REQUEST_ADMIN_TRANSACTION_LIST_SUCCESS    : 'REQUEST_ADMIN_TRANSACTION_LIST_SUCCESS',
    REQUEST_ADMIN_TRANSACTION_LIST_FAILED     : 'REQUEST_ADMIN_TRANSACTION_LIST_FAILED',

    REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST         : 'REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST',
    REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_SUCCESS : 'REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_SUCCESS',
    REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_FAILED  : 'REQUEST_ADMIN_TRANSACTION_DEPOSIT_LIST_FAILED',
    
    REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST         : 'REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST',
    REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST_SUCCESS : 'REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST_SUCCESS',
    REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST_FAILED  : 'REQUEST_ADMIN_TRANSACTION_WITHDRAWAL_LIST_FAILED',

    REQUEST_ADMIN_TRANSACTION_DETAIL            : 'REQUEST_ADMIN_TRANSACTION_DETAIL',
    REQUEST_ADMIN_TRANSACTION_DETAIL_SUCCESS    : 'REQUEST_ADMIN_TRANSACTION_DETAIL_SUCCESS',
    REQUEST_ADMIN_TRANSACTION_DETAIL_FAILED     : 'REQUEST_ADMIN_TRANSACTION_DETAIL_FAILED',

    USER_UPDATE_REQUEST : 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS : 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILED  : 'USER_UPDATE_FAILED',

    EXTRACT_PAN_CARD_REQUEST : 'EXTRACT_PAN_CARD_REQUEST',
    EXTRACT_PAN_CARD_SUCCESS : 'EXTRACT_PAN_CARD_SUCCESS',
    EXTRACT_PAN_CARD_FAILED  : 'EXTRACT_PAN_CARD_FAILED',

    UPLOAD_PAN_CARD_REQUEST : 'UPLOAD_PAN_CARD_REQUEST',
    UPLOAD_PAN_CARD_SUCCESS : 'UPLOAD_PAN_CARD_SUCCESS',
    UPLOAD_PAN_CARD_FAILED  : 'UPLOAD_PAN_CARD_FAILED',

    UPLOAD_FORM_REQUEST : 'UPLOAD_FORM_REQUEST',
    UPLOAD_FORM_SUCCESS : 'UPLOAD_FORM_SUCCESS',
    UPLOAD_FORM_FAILED  : 'UPLOAD_FORM_FAILED',

    EXTRACT_ADDRESS_PROOF_REQUEST : 'EXTRACT_ADDRESS_PROOF_REQUEST',
    EXTRACT_ADDRESS_PROOF_SUCCESS : 'EXTRACT_ADDRESS_PROOF_SUCCESS',
    EXTRACT_ADDRESS_PROOF_FAILED  : 'EXTRACT_ADDRESS_PROOF_FAILED',

    UPLOAD_ADDRESS_PROOF_REQUEST : 'UPLOAD_ADDRESS_PROOF_REQUEST',
    UPLOAD_ADDRESS_PROOF_SUCCESS : 'UPLOAD_ADDRESS_PROOF_SUCCESS',
    UPLOAD_ADDRESS_PROOF_FAILED  : 'UPLOAD_ADDRESS_PROOF_FAILED',

    UPLOAD_SIGNATURE_REQUEST : 'UPLOAD_SIGNATURE_REQUEST',
    UPLOAD_SIGNATURE_SUCCESS : 'UPLOAD_SIGNATURE_SUCCESS',
    UPLOAD_SIGNATURE_FAILED  : 'UPLOAD_SIGNATURE_FAILED',

    START_VIDEO_VERIFICATION_REQUEST : 'START_VIDEO_VERIFICATION_REQUEST',
    START_VIDEO_VERIFICATION_SUCCESS : 'START_VIDEO_VERIFICATION_SUCCESS',
    START_VIDEO_VERIFICATION_FAILED  : 'START_VIDEO_VERIFICATION_FAILED',

    UPLOAD_VIDEO_VERIFICATION_REQUEST : 'UPLOAD_VIDEO_VERIFICATION_REQUEST',
    UPLOAD_VIDEO_VERIFICATION_SUCCESS : 'UPLOAD_VIDEO_VERIFICATION_SUCCESS',
    UPLOAD_VIDEO_VERIFICATION_FAILED  : 'UPLOAD_VIDEO_VERIFICATION_FAILED',

    UPLOAD_SELFIE_REQUEST : 'UPLOAD_SELFIE_REQUEST',
    UPLOAD_SELFIE_SUCCESS : 'UPLOAD_SELFIE_SUCCESS',
    UPLOAD_SELFIE_FAILED  : 'UPLOAD_SELFIE_FAILED',

    ADMIN_PRODUCT_IMAGE_UPLOAD           : 'ADMIN_PRODUCT_IMAGE_UPLOAD',
    ADMIN_PRODUCT_IMAGE_UPLOAD_SUCCESS   : 'ADMIN_PRODUCT_IMAGE_UPLOAD_SUCCESS',
    ADMIN_PRODUCT_IMAGE_UPLOAD_FAILED    : 'ADMIN_PRODUCT_IMAGE_UPLOAD_FAILED',
    

    ADMIN_PRODUCT_IMAGE_DELETE           : 'ADMIN_PRODUCT_IMAGE_DELETE',
    ADMIN_PRODUCT_IMAGE_DELETE_SUCCESS   : 'ADMIN_PRODUCT_IMAGE_DELETE_SUCCESS',
    ADMIN_PRODUCT_IMAGE_DELETE_FAILED    : 'ADMIN_PRODUCT_IMAGE_DELETE_FAILED',
    
    PAYMENT_LINKS_REQUEST : 'PAYMENT_LINKS_REQUEST',
    PAYMENT_LINKS_SUCCESS : 'PAYMENT_LINKS_SUCCESS',
    PAYMENT_LINKS_FAILED  : 'PAYMENT_LINKS_FAILED',
    
    PAYMENT_LINKS_DETAIL_REQUEST : 'PAYMENT_LINKS_DETAIL_REQUEST',
    PAYMENT_LINKS_DETAIL_SUCCESS : 'PAYMENT_LINKS_DETAIL_SUCCESS',
    PAYMENT_LINKS_DETAIL_FAILED  : 'PAYMENT_LINKS_DETAIL_FAILED',

    PAYMENT_EXPIRE_LINK_REQUEST : 'PAYMENT_EXPIRE_LINK_REQUEST',
    PAYMENT_EXPIRE_LINK_SUCCESS : 'PAYMENT_EXPIRE_LINK_SUCCESS',
    PAYMENT_EXPIRE_LINK_FAILED  : 'PAYMENT_EXPIRE_LINK_FAILED',

    REQUEST_ADMIN_PARTNER_LIST : 'REQUEST_ADMIN_PARTNER_LIST',
    ADMIN_PARTNER_LIST_SUCCESS : 'ADMIN_PARTNER_LIST_SUCCESS',
    ADMIN_PARTNER_LIST_FAILED  : 'ADMIN_PARTNER_LIST_FAILED',

    REQUEST_ADMIN_PROFILE : 'REQUEST_ADMIN_PROFILE',
    ADMIN_PROFILE_SUCCESS : 'ADMIN_PROFILE_SUCCESS',
    ADMIN_PROFILE_FAILED  : 'ADMIN_PROFILE_FAILED',

    REQUEST_ADMIN_PROFILE_UPDATE : 'REQUEST_ADMIN_PROFILE_UPDATE',
    ADMIN_PROFILE_UPDATE_SUCCESS : 'ADMIN_PROFILE_UPDATE_SUCCESS',
    ADMIN_PROFILE_UPDATE_FAILED  : 'ADMIN_PROFILE_UPDATE_FAILED',

    FATCHA_REQUEST   : 'FATCHA_REQUEST',
    FATCHA_SUCCESS   : 'FATCHA_SUCCESS',
    FATCHA_FAILED    : 'FATCHA_FAILED',

    FATCHA_FORM_REQUEST   : 'FATCHA_FORM_REQUEST',
    FATCHA_FORM_SUCCESS   : 'FATCHA_FORM_SUCCESS',
    FATCHA_FORM_FAILED    : 'FATCHA_FORM_FAILED',

    KYC_COUNTRIES_REQUEST      : 'KYC_COUNTRIES_REQUEST',
    KYC_COUNTRIES_SUCCEEDED    : 'KYC_COUNTRIES_SUCCEEDED',
    KYC_COUNTRIES_FAILED       : 'KYC_COUNTRIES_FAILED',
    
    KYC_IDENTIFICATION_TYPES_REQUEST      : 'KYC_IDENTIFICATION_TYPES_REQUEST',
    KYC_IDENTIFICATION_TYPES_SUCCEEDED    : 'KYC_IDENTIFICATION_TYPES_SUCCEEDED',
    KYC_IDENTIFICATION_TYPES_FAILED       : 'KYC_IDENTIFICATION_TYPES_FAILED',
    
    KYC_SOURCES_OF_WEALTH_REQUEST      : 'KYC_SOURCES_OF_WEALTH_REQUEST',
    KYC_SOURCES_OF_WEALTH_SUCCEEDED    : 'KYC_SOURCES_OF_WEALTH_SUCCEEDED',
    KYC_SOURCES_OF_WEALTH_FAILED       : 'KYC_SOURCES_OF_WEALTH_FAILED',

    CANCEL_PURCHASE_REQUEST :    'CANCEL_PURCHASE_REQUEST',
    CANCEL_PURCHASE_SUCCESS :    'CANCEL_PURCHASE_SUCCESS',
    CANCEL_PURCHASE_FAILED  :    'CANCEL_PURCHASE_FAILED',
    
    REQUEST_COLLECTION_LIST              : 'REQUEST_COLLECTION_LIST',
    REQUEST_COLLECTION_LIST_SUCCESS      : 'REQUEST_COLLECTION_LIST_SUCCESS',
    REQUEST_COLLECTION_LIST_FAILED       : 'REQUEST_COLLECTION_LIST_FAILED',
    
    REQUEST_CREATE_COLLECTION_LIST              : 'REQUEST_CREATE_COLLECTION_LIST',
    REQUEST_CREATE_COLLECTION_LIST_SUCCESS      : 'REQUEST_CREATE_COLLECTION_LIST_SUCCESS',
    REQUEST_CREATE_COLLECTION_LIST_FAILED       : 'REQUEST_CREATE_COLLECTION_LIST_FAILED',

    REQUEST_COLLECTION_DETAIL              : 'REQUEST_COLLECTION_DETAIL',
    REQUEST_COLLECTION_DETAIL_SUCCESS      : 'REQUEST_COLLECTION_DETAIL_SUCCESS',
    REQUEST_COLLECTION_DETAIL_FAILED       : 'REQUEST_COLLECTION_DETAIL_FAILED',

    REQUEST_USER_COLLECTION_DETAIL              : 'REQUEST_USER_COLLECTION_DETAIL',
    REQUEST_USER_COLLECTION_DETAIL_SUCCESS      : 'REQUEST_USER_COLLECTION_DETAIL_SUCCESS',
    REQUEST_USER_COLLECTION_DETAIL_FAILED       : 'REQUEST_USER_COLLECTION_DETAIL_FAILED',

    REQUEST_COLLECTION_ACCEPT              : 'REQUEST_COLLECTION_ACCEPT',
    REQUEST_COLLECTION_ACCEPT_SUCCESS      : 'REQUEST_COLLECTION_ACCEPT_SUCCESS',
    REQUEST_COLLECTION_ACCEPT_FAILED       : 'REQUEST_COLLECTION_ACCEPT_FAILED',

    REQUEST_COLLECTION_CONFIRM              : 'REQUEST_COLLECTION_CONFIRM',
    REQUEST_COLLECTION_CONFIRM_SUCCESS      : 'REQUEST_COLLECTION_CONFIRM_SUCCESS',
    REQUEST_COLLECTION_CONFIRM_FAILED       : 'REQUEST_COLLECTION_CONFIRM_FAILED',

    FETCH_EMAIL_TEMPLATE_REQUEST : 'FETCH_EMAIL_TEMPLATE_REQUEST',
    FETCH_EMAIL_TEMPLATE_SUCCESS : 'FETCH_EMAIL_TEMPLATE_SUCCESS',
    FETCH_EMAIL_TEMPLATE_FAILED  : 'FETCH_EMAIL_TEMPLATE_FAILED',

    GENERATE_EMAIL_CODE_REQUEST : 'GENERATE_EMAIL_CODE_REQUEST',
    GENERATE_EMAIL_CODE_SUCCESS : 'GENERATE_EMAIL_CODE_SUCCESS',
    GENERATE_EMAIL_CODE_FAILED  : 'GENERATE_EMAIL_CODE_FAILED',

    CANCEL_REQUESTS_LIST_REQUEST : 'CANCEL_REQUESTS_LIST_REQUEST',
    CANCEL_REQUESTS_LIST_SUCCESS : 'CANCEL_REQUESTS_LIST_SUCCESS',
    CANCEL_REQUESTS_LIST_FAILED  : 'CANCEL_REQUESTS_LIST_FAILED',

    CANCEL_REQUESTS_DETAIL_REQUEST : 'CANCEL_REQUESTS_DETAIL_REQUEST',
    CANCEL_REQUESTS_DETAIL_SUCCESS : 'CANCEL_REQUESTS_DETAIL_SUCCESS',
    CANCEL_REQUESTS_DETAIL_FAILED  : 'CANCEL_REQUESTS_DETAIL_FAILED',

    CONFIRM_CANCEL_REQUESTS_REQUEST : 'CONFIRM_CANCEL_REQUESTS_REQUEST',
    CONFIRM_CANCEL_REQUESTS_SUCCESS : 'CONFIRM_CANCEL_REQUESTS_SUCCESS',
    CONFIRM_CANCEL_REQUESTS_FAILED  : 'CONFIRM_CANCEL_REQUESTS_FAILED',

    PRODUCT_LINK_REQUEST : 'PRODUCT_LINK_REQUEST',
    PRODUCT_LINK_SUCCESS : 'PRODUCT_LINK_SUCCESS',
    PRODUCT_LINK_FAILED  : 'PRODUCT_LINK_FAILED',
};
import { connect } from 'react-redux'
import { startVideoVerificationRequest, uploadVideoVerificationRequest, updateKycParams } from 'src/redux/actions';
import VideoVerification from './VideoVerification'

function mapStateToProps(){
  return {

  }
}

export default connect(mapStateToProps,{
  startVideoVerificationRequest, uploadVideoVerificationRequest, updateKycParams
})(VideoVerification)



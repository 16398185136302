export const INITIAL_STATE = {
  credentials: {
    partnerAccessKey: '',
    userIdentityToken: '',
  },
  user: {
    uuid: '',
  },
  savingPlan: {},
  product: {
    productOffers: [],
  },
  partner: {
    saveLaterTitle: '',
    saveLaterSubtitle: '',
  },
  folio: {},
  collections: {
    collectionsList: [],
    collectionDetail: {},
    userCollectionDetail: {},
    collectionAccept: {},
  },
  cancelRequests: {
    cancelRequestsList: [],
    cancelRequestsDetail: {},
  },
  savingGoals: [],
  taxStatuses: [],
  bankDetails: {},
  occupations: [],
  mandates: [],
  transactions: [],
  kyc: {},
  admin: {},
  adminCredentials: {},
  adminUsers: [],
  adminProducts: [],
  adminOffers: [],
  adminGoals: [],
  adminTransactions: {},
  adminPayments: [],
  adminPayment: {},
  adminAgents: {},
  adminPartners: {},
};

import { connect } from 'react-redux'
import { requestAdminTransactionWithdrawalList } from 'src/redux/actions'
import Withdrawals from './Withdrawals'

function mapStateToProps(state){
    return{
        transactions: state.adminTransactions
    }
}

export default connect(mapStateToProps, {
    requestAdminTransactionWithdrawalList
})(Withdrawals)

import { connect } from 'react-redux';
import {
  requestAdminProductList,
  requestAdminProductListByName,
  requestAdminOffersListByProductId,
  requestShortenUrl,
} from 'src/redux/actions';
import GenerateProductPaymentLink from './GenerateProductPaymentLink';

function mapStateToProps(state) {
  return {
    admin: state.admin,
    adminCredentials: state.adminCredentials,
    products: state.adminProducts.products,
    offers: state.adminOffers.productOffers,
  };
}

export default connect(mapStateToProps, {
  requestAdminProductList,
  requestAdminProductListByName,
  requestAdminOffersListByProductId,
  requestShortenUrl,
})(GenerateProductPaymentLink);

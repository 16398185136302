import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminProductsReducer(state = INITIAL_STATE.adminProducts, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_PRODUCT_UPDATE_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.ADMIN_PRODUCT_DETAIL_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.ADMIN_PRODUCT_LIST_BY_NAME_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.CREATE_ADMIN_PRODUCT_SUCCESS: {
      return [...state, action.payload.product];
    }

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminProducts;

    case ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE_SUCCESS: {
      return { ...state, ...action.payload };
    }

    case ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD_SUCCESS: {
      return { ...state, lastUploadedImage: action.payload };
    }

    default:
      return state;
  }
}

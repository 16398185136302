import { useField } from 'formik';
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
// import styles from './_inputcheckbox.module.scss';
import globalStyles from '../_styles.module.scss';

const InputCheckboxTwo = ({ label, suffix, ...props }) => {
  const [field, meta] = useField(props);  
  return (
    <>
      <Row style={{ ...props.containerstyles }}>
        {label && label != '' && (
          <Col md={12} className={props.checkboxContainer}>
            <Form.Check size="sm" {...field} {...props} className={props.styles} checked={field.value} />
            <label htmlFor={props.id || props.name} className={props.labelstyles}>
              {label}
            </label>
            { suffix || ''}
          </Col>
        )}
        <Col md={12}>
          {meta.touched && meta.error ? (
            <div className={globalStyles.error}>{meta.error}</div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default InputCheckboxTwo;

import React, { Component } from 'react';
import { Col, Row, Dropdown, Form } from 'react-bootstrap';
import { Loader } from 'src/components';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';
import styles from './demographic.module.scss';

const INCOME_LIST = [
  {
    value: 1,
    text: '1 - 5 lacs',
  },
  {
    value: 2,
    text: '5 - 10 lacs',
  },
  {
    value: 3,
    text: '10 - 25 lacs',
  },
  {
    value: 4,
    text: '> 25 Lacs - 1 crore',
  },
  {
    value: 5,
    text: 'Below 1 lac',
  },
  {
    value: 6,
    text: '> 1 crore',
  },
];

class DropDownItem extends Component {
  _onClick = () => {
    const { text, value } = this.props;
    this.props.onClick && this.props.onClick({ text, value });
  };

  render() {
    return <Dropdown.Item onClick={this._onClick}>{this.props.text}</Dropdown.Item>;
  }
}

class Demographic extends Component {
  state = {
    email: '',
    dateOfBirth: '',
    occupationList: [],
    occupation: {},
    income: {},
    acceptPep: true,
    loading: false,
    isLoading: false,
    isSubmit: false,
  };

  componentDidMount() {
    this.setState({
      email: this.props.user.email || '',
      dateOfBirth: this.props.user.dateOfBirth
        ? new Date(moment(this.props.user.dateOfBirth, 'DD/MM/YYYY').toString())
        : '',
    });
    this.occupationRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_OCCUPATION_LIST, () =>
      this.setState({ loading: true }),
    );
    this.occupationSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_OCCUPATION_LIST_SUCCEEDED,
      this._onFetchedOccupations,
    );
    this.occupationFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_OCCUPATION_LIST_FAILED,
      () => this.setState({ loading: false }),
    );

    this.submitInvestorDetailsRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS,
      () => this.setState({ isLoading: true }),
    );
    this.submitInvestorDetailsSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_SUCCEEDED,
      this._onInvestorDetailsSubmitted,
    );
    this.submitInvestorDetailsFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_FAILED,
      () => this.setState({ isLoading: false }),
    );

    this.submitInvestorKYCRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC,
      () => this.setState({ isLoading: true }),
    );
    this.submitInvestorKYCSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC_SUCCEEDED,
      this._onInvestorKYCSubmitted,
    );
    this.submitInvestorKYCFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC_FAILED,
      () => this.setState({ isLoading: false }),
    );

    this.createFolioRequestSubscription = EventBus.on(ACTION_TYPES.CREATE_FOLIO, () =>
      this.setState({ isLoading: true }),
    );
    this.createFolioSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_FOLIO_SUCCEEDED,
      this._onFolioCreateSuccess,
    );
    this.createFolioFailureSubscription = EventBus.on(ACTION_TYPES.CREATE_FOLIO_FAILED, () =>
      this.setState({ isLoading: false }),
    );
    if (this.props?.kyc?.userInfo?.salaryDescription) {
      const income = INCOME_LIST.find(
        ({ text }) =>
          text && text.toLowerCase() === this.props?.kyc?.userInfo?.salaryDescription.toLowerCase(),
      );
      this.setState({ income });
    }
    if (this.props?.kyc?.userInfo?.acceptPep) {
      this.setState({ acceptPep: this.props?.kyc?.userInfo?.acceptPep });
    }
  }

  componentWillUnmount() {
    this.occupationRequestSubscription();
    this.occupationSuccessSubscription();
    this.occupationFailureSubscription();

    this.submitInvestorDetailsRequestSubscription();
    this.submitInvestorDetailsSuccessSubscription();
    this.submitInvestorDetailsFailureSubscription();

    this.submitInvestorKYCRequestSubscription();
    this.submitInvestorKYCSuccessSubscription();
    this.submitInvestorKYCFailureSubscription();

    this.createFolioRequestSubscription();
    this.createFolioSuccessSubscription();
    this.createFolioFailureSubscription();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isActive &&
      this.props.isActive !== prevProps.isActive &&
      this.props.occupations.length === 0
    ) {
      console.log('STEP1');
      this.props.requestOccupationList();
    } else if (
      this.props.isActive &&
      this.props.isActive !== prevProps.isActive &&
      this.props.occupations.length > 0
    ) {
      console.log('STEP2');
      this._parseOccupationList();
    }
  }

  _onFetchedOccupations = () => {
    this.setState({ loading: false }, () => {
      this._parseOccupationList();
    });
  };

  _parseOccupationList = () => {
    const occupationList = this.props.occupations.map((occupation) => ({
      value: Number(occupation.occupationCode),
      text: occupation.occupationName,
    }));
    if (this.props?.kyc?.userInfo?.occupation) {
      const occupation = occupationList.find(
        ({ value }) => Number(value) === Number(this.props?.kyc?.userInfo?.occupation),
      );
      this.setState({ occupation });
    }

    this.setState({ occupationList });
  };

  _onInvestorKYCSubmitted = () => {
    this.props.createFolio({ without_otp: true });
  };

  _onFolioCreateSuccess = () => {
    this.setState({ isLoading: false }, () => {
      this.props.onNext && this.props.onNext();
    });
  };

  _selectOccuption = (occupation) => {
    this.setState({ occupation });
  };

  _selectIncome = (income) => {
    this.setState({ income });
  };

  _isValidForm = () => {
    const { email, dateOfBirth, occupation, income } = this.state;
    return email && dateOfBirth && occupation && occupation.value && income && income.value;
  };

  _onInvestorDetailsSubmitted = () => {
    this.props.requestSubmitInvestorKYC({
      occupation_code: String(this.state.occupation.value),
      annual_salary: this.state.income.text,
      pep_check: this.state.acceptPep ? 'Applicable' : 'Not applicable',
    });
  };

  _allDone = () => {
    this.props.requestSubmitInvestorDetails({
      email: this.state.email,
      phone_number: this.props.user && this.props.user.phoneNumber,
      date_of_birth: moment(this.state.dateOfBirth).format('YYYY-MM-DD'),
    });
  };

  handleDatePicker = (value) => {
    this.setState({ dateOfBirth: value });
  };

  handleSubmit = () => {
    this.setState({ isSubmit: true }, () => {
      const { isValidate } = this.handleValidation();
      if (isValidate) {
        this._allDone();
      }
    });
  };

  handleValidation = () => {
    const { isSubmit, email, dateOfBirth, occupation, income } = this.state;
    const errorStructure = {
      errorEmail: '',
      errorDateOfBirth: '',
      errorOccupation: '',
      errorIncome: '',
      isValidate: false,
    };
    if (!isSubmit) {
      return errorStructure;
    }
    if (!email) {
      errorStructure.errorEmail = 'Required Email';
    }
    if (!dateOfBirth) {
      errorStructure.errorDateOfBirth = 'Required DOB';
    }
    if (!occupation?.value) {
      errorStructure.errorOccupation = 'Required Occupation';
    }
    if (!income?.value) {
      errorStructure.errorIncome = 'Required Income';
    }

    if (email && dateOfBirth && occupation && occupation.value && income && income.value) {
      errorStructure.isValidate = true;
    }
    return errorStructure;
  };

  render() {
    const { email, dateOfBirth, occupationList, occupation, income, acceptPep, isLoading } =
      this.state;
    const { errorEmail, errorDateOfBirth, errorOccupation, errorIncome } = this.handleValidation();
    return (
      <div className={styles['demographic']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>
        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['pay-content']}>
          <div className={styles['title']}>Verify your KYC Start Saving</div>
          <div className={styles['line']} />
          <div className={styles['sub-title']}>Demographic Information</div>
          <div className={styles['sub-title-light']}>
            Lets wrap up the formalities and start saving for your holiday!
          </div>
          <div className={cx(styles['mobile-space'], styles['mobile'])}>
            <div className={cx(styles['line-mob'])} />
          </div>
          <Row>
            <Col xs={12} lg={6}>
              <div className={cx(styles['form-box'], styles['mobile-space'])}>
                <div>
                  <label>Enter your Email ID</label>
                </div>
                <div>
                  <InputMask
                    value={email}
                    className={cx(styles['input-box'], {
                      [styles['error-border']]: errorEmail,
                    })}
                    placeholder="example@gmail.com"
                    onChange={({ target }) => this.setState({ email: target.value })}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className={cx(styles['form-box'], styles['mobile-space'])}>
                <div>
                  <label>Enter your Date of Birthday</label>
                </div>
                <div className="demographic-datepicker-dob">
                  <DatePicker
                    dateFormat="dd/MM/yyyy" // don't change to uppercase
                    placeholder="DD/MM/YYYY"
                    scrollableYearDropdown
                    selected={dateOfBirth}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    maxDate={new Date()}
                    ref={this.datepickerRef}
                    onChange={(dateOfBirth) => {
                      this.handleDatePicker(dateOfBirth);
                    }}
                    className={cx('pay-demographic-datepicker', {
                      [styles['error-border']]: errorDateOfBirth,
                    })}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className={cx(styles['form-box'], styles['mobile-space'])}>
                <div>
                  <label>Occupation</label>
                </div>
                <div>
                  <Dropdown className={styles['custom-dropdown']}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className={cx({
                        [styles['error-border']]: errorOccupation,
                      })}
                    >
                      {occupation && occupation.text ? occupation.text : 'Occupation'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ height: '143px', overflowY: 'scroll', width: '100%' }}>
                      {occupationList &&
                        occupationList.length > 0 &&
                        occupationList.map((item, index) => (
                          <DropDownItem
                            {...item}
                            key={`occupation-item-${index}`}
                            onClick={this._selectOccuption}
                          />
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className={cx(styles['form-box'], styles['mobile-space'])}>
                <div>
                  <label>Salary Range</label>
                </div>
                <div>
                  <Dropdown className={styles['custom-dropdown']}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className={cx({
                        [styles['error-border']]: errorIncome,
                      })}
                    >
                      {income && income.text ? income.text : 'Income '}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ height: '143px', overflowY: 'scroll', width: '100%' }}>
                      {INCOME_LIST.map((item, index) => (
                        <DropDownItem
                          {...item}
                          key={`income-item-${index}`}
                          onClick={this._selectIncome}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
          <div className={cx(styles['checkbox'], styles['mobile-space'])}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                className={styles['demo-info-checkbox']}
                type="checkbox"
                label="I am an Indian citizen and not a PEP (Politically  Exposed Person) or related to a PEP"
                checked={acceptPep}
                onChange={({ target }) => this.setState({ acceptPep: target.checked })}
              />
            </Form.Group>{' '}
          </div>
        </div>
        <button className={styles['footer-button']} onClick={this.handleSubmit}>
          ALL DONE
        </button>
        <Loader
          loading={this.state.loading || isLoading}
          title={
            isLoading
              ? 'We are verifying your KYC please wait a few seconds and do not close the window'
              : ''
          }
          titleClassNames={cx({ [styles['loader-title']]: isLoading })}
        />
      </div>
    );
  }
}

export default Demographic;

import React from 'react'
import cx from 'classnames'
import styles from './_jumbotronone.module.scss'

export default function JumbotronOne(props) {
  return (
    <div 
      className={cx(styles.container,props.className)}
      style={{
        backgroundImage: `url(${props.imgSrc})`
      }}
    >
      <div className={styles.body}>
        <header className={styles.header}>
          <div className={styles.title}>
            {props.title}
          </div>
          <div className={styles['sub-title']}>
            {props.subTitle}
          </div>
        </header>
        <div className={styles.leading}>
          {props.leading}
        </div>
      </div>
    </div>
  )
}

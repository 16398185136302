import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import cx from 'classnames';
import styles from './_statscontainer.module.scss';
import { ButtonMain, DropdownToggle } from '../theme';

class StatsContainer extends Component {
  render() {
    const { title, data, button } = this.props;
    return (
      <div className={styles['container']}>
        <div className={styles['title']}>{title}</div>
        <div className={styles['content']}>
          <div className={cx(styles.statsContainer, this.props.statsContainer)}>
            {data &&
              data.length > 0 &&
              data.map((obj, i) => (
                <div className={cx(styles['stats'], this.props.statItemStyles)} key={i}>
                  <div className={styles['value']}>{obj.value}</div>
                  <div className={styles['stats-title']}>{obj.title}</div>
                </div>
              ))}
          </div>
          <div className={cx(styles.metaContainer, this.props.metaContainer)}>
            <div className={styles['left-side']}>
              {!this.props.disableDataExport && (
                <Dropdown className={styles.dropdown}>
                  <DropdownToggle>Export Data</DropdownToggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">CSV</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">PDF</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {button?.name && (
                <Link to={button?.link}>
                  <ButtonMain variant="compact" className={styles.link}>
                    {button?.name}
                  </ButtonMain>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsContainer;

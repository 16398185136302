import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function CredentialReducer(state = INITIAL_STATE.adminCredentials, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        userIdentityToken: action.payload.authCredentials
          ? action.payload.authCredentials.xUserIdentityToken
          : '',
        partnerAccessKey: action.payload.authCredentials
          ? action.payload.authCredentials.xPartnerAccessKey
          : '',
      };

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminCredentials;

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

import styles from './address-proof.module.scss';

class AddressProof extends Component {
  render() {
    const { onNext } = this.props;

    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['another-account']}>
          <div className={styles['pay-content']}>
            <div className={cx(styles['title'], styles['title-heading'])}>
              <div>Review your information (3/7)</div>
              <div>
                <FontAwesomeIcon icon={faAngleLeft} onClick={this.props.onPrev} /> &nbsp;&nbsp;
                <FontAwesomeIcon icon={faAngleRight} onClick={this.props.onNext} />
              </div>
            </div>
            <div className={styles['line']} />
            <div className={styles['sub-title']}>ADDRESS PROOF</div>
            <div>
              <Row>
                <Col sm={12} md={8}>
                  <div className={styles['list-group']}>
                    <div className={styles['list']}>
                      Proof Type:{' '}
                      <div className={styles['list-content']}>
                        {this.props.addressProof?.address_proof_type}
                      </div>
                    </div>
                    <div className={styles['list']}>
                      Full name:{' '}
                      <div className={styles['list-content']}>{this.props.addressProof?.name}</div>
                    </div>
                    <div className={styles['list']}>
                      ID Number:{' '}
                      <div className={styles['list-content']}>
                        {this.props?.addressProof?.aadhaar_uid ||
                          this.props?.addressProof?.license_number ||
                          this.props?.addressProof?.passport_number ||
                          this.props?.addressProof?.voter_id_number}
                      </div>
                    </div>
                    <div className={styles['list']}>
                      Address:{' '}
                      <div
                        className={styles['list-content']}
                        title={this.props.addressProof?.address}
                      >
                        {this.props.addressProof?.address}
                      </div>
                    </div>
                    <div className={styles['list']}>
                      State:{' '}
                      <div className={styles['list-content']}>{this.props.addressProof?.state}</div>
                    </div>
                    <div className={styles['list']}>
                      District:{' '}
                      <div className={styles['list-content']}>
                        {this.props.addressProof?.district}
                      </div>
                    </div>
                    <div className={styles['list']}>
                      City:{' '}
                      <div className={styles['list-content']}>{this.props.addressProof?.city}</div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  {this.props.addressProof?.image_url ? (
                    <div className={styles['pan-img']}>
                      <img src={this.props.addressProof?.image_url} alt="pan-img" />
                    </div>
                  ) : (
                    <div className={styles['placeholder-outer']}>
                      <div className={styles['placeholder']}>
                        <div className={styles['placeholder-inner']}>Image</div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles['spacer']} />

          <div className={styles['footer']} onClick={onNext}>
            NEXT
          </div>
        </div>
      </div>
    );
  }
}

export default AddressProof;

import React, { Component } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { TextInputThree } from 'src/components/form-inputs'
import { Loader, CtaTwo, Header } from 'src/components'
import cx from 'classnames'
import EventBus from 'eventing-bus'
import { ACTION_TYPES } from 'src/constants'
import { notify } from 'react-notify-toast'
import { withRouter } from 'react-router-dom'
import styles from './_adminprofile.module.scss'

export class AdminProfile extends Component {
  state={
    loading:false
  }

  _validationSchema = () => yup.object().shape({
    // logo_image_link: yup.mixed()
    //   .test(
    //     'imaegSize',
    //     'Image size is large',
    //     (val) => {
    //       console.log(val.size, 'test 1')
    //       return val?.size < (5 * 1000 * 1000)
    //     }
    //   )
    //   .test(
    //     'imageType',
    //     'Image type is not valid',
    //     (val) =>  {
    //       console.log(val.type, 'test 2');
    //       return ['image/jpg', 'image/jpeg', 'image/png'].includes(val?.type)
    //     }
    //   ),
    logo_image_link: yup.string().required('Please enter a url.').url('Please enter a valid url.'),
    legal_name: yup.string()
      .required('Please enter legal name'),
    email: yup.string()
      .email('Please enter a valid email address')
      .required('Please enter email address.'),
    phone_number: yup
      .string()
      .required('Please enter phone number.')
      .matches(/^\+[1-9]{2}[6-9]\d{9}$/, {
        message: 'Phone number not valid',
      }),
    address: yup.string()
      .required('Please enter address line one'),
    pincode: yup.string()
      .required('Please enter pin code')
      .matches(/^[1-9][0-9]{5}$/, {
        message: 'Please enter a valid pin code'
      }),
    pan_number: yup.string()
      .required('Please enter Pan number')
      .matches(/^[A-Z]{5}\d{4}[A-Z]{1}$/, {
        message: 'Please enter a valid pan number.'
      }),
    gst_number: yup.string()
      .required('Please enter gst number.')
      .matches(/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, {
        message: 'PLease enter a valid gst number.'
      }),
  })

  componentDidMount() {
    this.requestAdminProfile = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PROFILE, () =>
      this.setState({ loading: true })
    );
    this.adminProfileSuccess = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_SUCCESS, () => 
      this.setState({ loading: false })
    );
    this.adminProfileFailed = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_FAILED, () =>
      this.setState({ loading: false })
    );

    this.requestAdminProfileUpdate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PROFILE_UPDATE, () =>
      this.setState({ loading: true })
    );
    this.adminProfileUpdateSuccess = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_UPDATE_SUCCESS, () => {
      this.setState({ loading: false })
      notify.show('Profile updated successfully', 'success')
    });
    this.adminProfileUpdateFailed = EventBus.on(ACTION_TYPES.ADMIN_PROFILE_UPDATE_FAILED, () =>
      this.setState({ loading: false })
    );

    if(!this.props.admin.isAdmin){
      return this.props.history.push('/admin')
    }

    this.props.requestAdminProfile()
  }

  componentWillUnmount() {
    this.requestAdminProfile();
    this.adminProfileSuccess();
    this.adminProfileFailed();

    this.requestAdminProfileUpdate();
    this.adminProfileUpdateSuccess();
    this.adminProfileUpdateFailed();
  }

  render() {
    const {partner = {} } = this.props

    return (
      <div className={styles.container}>
        <Header
          heading="Your Profile"
          headerStyles={styles.headerStyles}
          headingStyles={styles.headingStyles}
          // rightComponent={()=><span 
          //   className={styles.iconContainer} 
          //   onClick={()=>this.setState(prevState =>  ({readMode: !prevState.readMode}) )}
          // >
          //   <FontAwesomeIcon icon={this.state.readMode ? faPen : faEye} className={styles.iconContainer}/>
          // </span>}
        />
        <Formik
          initialValues={{
            legal_name: partner?.legalName || '',
            email: partner?.email || '',
            phone_number: partner?.phoneNumber || '',
            address: partner?.address || '',
            pincode: partner?.pincode || '',
            pan_number: partner?.panNumber || '',
            gst_number: partner?.gstNumber || '',
            logo_image_link: partner?.logoImageLink || '',
          }}
          validationSchema={this._validationSchema}
          onSubmit={(values)=>{
            // let formData = new FormData()
            // const formValues = {...values}

            // Object.keys(formValues).map(key => formData.append(`partner[${key}]`, formValues[key]))

            // for (let val of formData.entries()) {
            //   console.log(val[0], val[1]);
            // }
            this.props.requestAdminProfileUpdate({partner: {...values}})
          }}
        >
            <Form
              className={styles.formContainer}
            >
              {/* <InputFileFour
                label="Logo Image"
                name="logo_image_link"
                id="logo_image_link"
                type="file"
                containerstyles={styles.imageContainer}
                labelContainerStyles={styles.labelContainer}
                labelstyles={styles.label}
                onChange={e => setFieldValue('logo_image_link', e.target.files[0])}
              /> */}
              <TextInputThree
                label="Logo Image"
                name="logo_image_link"
                id="logo_image_link"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Please Enter an Image url"
              />
              <TextInputThree
                label="Legal"
                name="legal_name"
                id="legal_name"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Legal Name"
              />
              <TextInputThree
                label="Email Id"
                name="email"
                id="email"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Enter email address"
              />
              <TextInputThree
                label="Phone Number"
                name="phone_number"
                id="phone_number"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="9999999999"
              />
              <TextInputThree
                label="Address"
                name="address"
                id="address"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={styles.fieldContainer}
                inputstyles={styles.inputStyles}
                placeholder="Address line 1"
              />
              <TextInputThree
                label="Picode"
                name="pincode"
                id="pincode"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={cx(styles.fieldContainer, styles.variantOne)}
                inputstyles={styles.inputStyles}
                placeholder="111111"
              />
              <TextInputThree
                label="Pan Number"
                name="pan_number"
                id="pan_number"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={cx(styles.fieldContainer, styles.variantOne)}
                inputstyles={styles.inputStyles}
                placeholder="Enter Pan Number"
              />
              <TextInputThree
                label="GST Number"
                name="gst_number"
                id="gst_number"
                containerstyles={styles.inputContainer}
                labelcontainerstyles={styles.labelContainer}
                fieldcontainerstyles={cx(styles.fieldContainer, styles.variantOne)}
                inputstyles={styles.inputStyles}
                placeholder="Enter GST Number"
              />
              {!this.state.readMode &&
                <div className={styles.buttonContainer}>
                  <CtaTwo
                    text="Save Details"
                    className={styles.ctaTwoStyles}
                    type="submit"
                  />
                </div>
              }
            </Form>
        </Formik>
        <Loader loading={this.state.loading}/>
      </div>
    )
  }
}

export default withRouter(AdminProfile)

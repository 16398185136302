import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import { TextInput } from 'src/components/theme';
import styles from './_textinputtwo.module.scss';
import globalStyles from '../_styles.module.scss';

const TextInputTwo = ({ label, addonValue, addonPosition, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Row style={{ ...props.containerstyles }}>
        <Col md={6} className={styles.inputLabelContainer}>
          <label htmlFor={props.id || props.name} className={styles.inputLabel}>
            {label}
          </label>
        </Col>
        <Col md={6}>
          <InputGroup size="sm">
            {addonPosition == 'left' && [
              addonValue.type == 'text' ? (
                <InputGroup.Text
                  key="1"
                  id="inputGroup-sizing-default"
                  className={styles.addonText}
                >
                  {addonValue.value}
                </InputGroup.Text>
              ) : (
                <InputGroup.Text key="2">
                  <FontAwesomeIcon icon={faPercent} />
                </InputGroup.Text>
              ),
            ]}
            <TextInput {...field} {...props} style={{ width: 'auto' }} />
            {addonPosition == 'right' && [
              addonValue.type == 'text' ? (
                <InputGroup.Text
                  key="3"
                  id="inputGroup-sizing-default"
                  className={styles.addonText}
                >
                  {addonValue.value}
                </InputGroup.Text>
              ) : (
                <InputGroup.Text key="4">
                  <FontAwesomeIcon icon={faPercent} />
                </InputGroup.Text>
              ),
            ]}
          </InputGroup>
          {meta.touched && meta.error ? (
            <div className={globalStyles.error}>{meta.error}</div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default TextInputTwo;

import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import CustomAreaChart from 'src/components/custom-area-chart';
import CustomPieChart from 'src/components/custom-pie-chart';
import StatsContainer from 'src/components/stats-container';
import { customAreaChartData, customPieChartData } from './data';
import styles from './_investments.module.scss';

export class Investments extends Component {
  render() {
    return (
      <div>
        <StatsContainer
          title="Investment Summary"
          data={[
            {
              title: 'Total Amount Invested',
              value: '15,50,000',
            },
            {
              title: 'Total Amount Withdrawn ',
              value: '50,000',
            },
          ]}
        />
        <div>
          <div className={styles.chartWrapperOne}>
            <div className={styles.contentContainer}>
              <div>
                <b>Total Amount Invested </b>
                <span>15,50,000</span>
              </div>
              <div className={styles.right}>
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    All products
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className={styles.chartContainer}>
              <CustomAreaChart data={customAreaChartData} />
            </div>
          </div>
          <div className={styles.multiChartWrapper}>
            <div className={styles.chartWrapperTwo}>
              <div className={styles.contentContainer}>
                <b>Amount invested per product</b>
              </div>
              <div className={styles.chartContainer}>
                <CustomPieChart data={customPieChartData} />
              </div>
            </div>
            <div className={styles.chartWrapperTwo}>
              <div className={styles.contentContainer}>
                <b>Amount withdrawn per product</b>
              </div>
              <div className={styles.chartContainer}>
                <CustomPieChart data={customPieChartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Investments;

import React, { Component } from 'react';
import { Loader } from 'src/components';
import { ApiRequest } from 'src/helpers';
import { faCamera, faRedo, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import styles from './_selfiecontainer.module.scss';

class Selfie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      imageUri: null,
      imageCaptured: false,
      imageUploaded: false,
    };
    this._stream = null;
    this._video = null;
    this._canvas = null;
  }

  componentDidMount() {
    if (this.props?.imageSrc) {
      this.setState({
        imageUri: this.props?.imageSrc || '',
        imageCaptured: true,
        imageUploaded: true,
      });
    } else {
      this._startCamera();
    }
  }

  componentWillUnmount() {
    this._stopCamera();
  }

  _startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: 'user',
          width: 720,
          height: 480,
        },
      })
      .then((stream) => {
        this._stream = stream;
        this._video.srcObject = stream;
      });
  };

  _stopCamera = () => {
    this._stream?.getTracks().forEach((track) => {
      if (track?.readyState == 'live') {
        track?.stop();
      }
    });
  };

  _onCaptureImage = () => {
    this._canvas?.getContext('2d').drawImage(this._video, 0, 0, 720, 480, 0, 0, 720, 480);
    const imageUri = this._canvas?.toDataURL('image/jpeg');
    this.setState({ imageUri, imageCaptured: true }, () => {
      this._stopCamera();
    });
  };

  _onReTakeImage = () => {
    this.setState({ imageCaptured: false, imageUploaded: false, imageUri: null }, () => {
      this._startCamera();
    });
  };

  _onUsePhoto = () => {
    this.setState({ loading: true });
    this._canvas?.toBlob((blob) => {
      ApiRequest.uploadToSignzy(blob, 'selfie.jpeg').then((res) => {
        this.setState({ loading: false, imageUploaded: true }, () => {
          this.props.onSelfieChange(res?.data?.file?.directUrl);
        });
      });
    }, 'image/jpeg');
  };

  render() {
    return (
      <div className={styles.container}>
        {!this.props.otp ? <header>Take a nice photo of yourself</header> : null}
        <div className={styles.videoContainer}>
          {this.state.imageCaptured ? (
            <img className={styles.image} src={this.state.imageUri} alt="selfie" />
          ) : (
            // <div
            //   className={styles.image}
            //   style={{ backgroundImage: `url(${this.state.imageUri})` }}
            // />
            /* eslint-disable-next-line */
            <video
              ref={(ref) => (this._video = ref)}
              width="300"
              autoPlay
              className={styles.video}
            />
          )}
          <canvas
            ref={(ref) => (this._canvas = ref)}
            style={{ display: 'none' }}
            width="720"
            height="480"
          />
        </div>
        <div className={styles.buttonContainer}>
          {this.state.imageCaptured ? (
            <>
              <button className={cx(styles.button, styles.danger)} onClick={this._onReTakeImage}>
                <FontAwesomeIcon icon={faRedo} className={styles.icon} />
                Retake
              </button>

              {!this.state.imageUploaded ? (
                <button className={cx(styles.button, styles.primary)} onClick={this._onUsePhoto}>
                  <FontAwesomeIcon icon={faThumbsUp} className={styles.icon} />
                  Use Photo
                </button>
              ) : null}
            </>
          ) : (
            <button className={cx(styles.button, styles.primary)} onClick={this._onCaptureImage}>
              <FontAwesomeIcon icon={faCamera} className={styles.icon} />
              Capture
            </button>
          )}
          <Loader loading={this.state.loading} />
        </div>
      </div>
    );
  }
}

export default withRouter(Selfie);

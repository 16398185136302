import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';

export default function CustomPagination(props) {
  const { paginationConfig } = props;
  const [page, setPage] = useState(1);

  const handleSetPage = (i) => {
    setPage(i);
    props.handlePaginationChange(i);
  };

  let pages = [];

  for (let i = 1; i <= paginationConfig.totalPages; i += 1) {
    pages.push(
      <Pagination.Item onClick={() => handleSetPage(i)} active={i === page} key={i}>
        {i}
      </Pagination.Item>,
    );
  }

  return (
    <>
      {paginationConfig?.totalPages > 1 && (
        <Pagination className={props.className}>{pages}</Pagination>
      )}
    </>
  );
}

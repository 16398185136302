import React, { useMemo } from 'react';
// import { Link } from 'react-router-dom';
import moment from 'moment';
// import { faEdit, faExternalLinkAlt, faCopy, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'src/components/theme';
import { Convert } from 'src/helpers';
import { DataTable, TextOne, LinkOne } from '../../../../components';
import './_users-table.style.scss';

const UsersTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#ffffff',
      borderBottomColor: '#f3f3f3',
    },
    tableHeader: {
      backgroundColor: '#f3f3f3',
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <TextOne variant="head">Plan URL</TextOne>,
        accessor: 'url',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <TextOne
            title={`${window.location.origin}/admin/paymentlink/${original?.productOffer?.uuid}`}
          >
            <div
              style={{
                textOverflow: 'ellipsis',
                width: '200px',
                whiteSpace: 'pre',
                overflow: 'hidden',
              }}
            >
              {`${window.location.origin}/admin/paymentlink/${original?.productOffer?.uuid}}`}
            </div>
          </TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Link Name</TextOne>,
        accessor: 'description',
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <TextOne>
            <LinkOne to={`/admin/paymentlink/${original?.productOffer?.uuid}`}>{value}</LinkOne>
          </TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Date Created</TextOne>,
        accessor: 'createdAt',
        disableSortBy: false,
        Cell: ({ value }) => <TextOne>{moment(value, 'DDMMYYYY').format('Do MMMM, YYYY')}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Cost of Offer</TextOne>,
        accessor: 'costOfOffer',
        disableSortBy: false,
        Cell: ({ row: { original } }) => (
          <TextOne>{Convert.toCurrencyValue(original?.productOffer?.discountedAmount)}</TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Amount Paid</TextOne>,
        accessor: 'amount',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
          let contribution = 0;

          if (original?.savingGoalContributions && original?.savingGoalContributions.length > 0) {
            original?.savingGoalContributions.forEach((data) => {
              if (data?.status === 'completed' || data?.status === 'pending_investments') {
                contribution += parseFloat(data?.amount) || 0;
              }
            });
          }
          contribution += Number(original?.productOffer?.upfrontPaymentAmount || 0);

          return <TextOne>{Convert.toCurrencyValue(contribution || 0)}</TextOne>;
        },
      },
      {
        Header: <TextOne variant="head">Status</TextOne>,
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ value }) => {
          let variant = 'created';
          let displayValue = 'In progress';
          if (value === 'pending_delete') {
            variant = 'expired';
            displayValue = 'Pending';
          }
          if (value === 'completed') {
            variant = 'completed';
            displayValue = 'Completed';
          }
          return (
            <div className="badge-box">
              <Badge variant={variant} className="badge-style">
                {displayValue}
              </Badge>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <DataTable
      columns={columns}
      data={props.data || []}
      tableStyles={tableStyles}
      paginationConfig={paginationConfig}
      handlePaginationChange={props.handlePaginationChange}
    />
  );
};

export default UsersTable;

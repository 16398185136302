import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminPartnersReducer(state = INITIAL_STATE.adminPartners, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_PARTNER_LIST_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.ADMIN_PROFILE_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.ADMIN_PROFILE_UPDATE_SUCCESS: {
      return {
        ...action.payload,
      };
    }

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminPartners;

    default:
      return state;
  }
}

import { connect } from 'react-redux';
import { 
  requestTaxStatus, 
  requestCheckPan, 
  requestSubmitPan,
  updateFolio
} from 'src/redux/actions';

import VerifyKyc from './verify-kyc';

function mapStateToProps(state) {
  return {    
    user: state.user,
    folio: state.folio,
    taxStatuses: state.taxStatuses,
    savingPlan: state.savingPlan,
  };
}

export default connect(mapStateToProps, {
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  updateFolio
})(VerifyKyc);

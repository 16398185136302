import React, { Component } from 'react';
import { Screen, Loader } from 'src/components';
import queryString from 'query-string';

class MandateRedirect extends Component {
  state = {
    loading: true,
  };
  
  timer = null;

  componentDidMount() {
    const { status } = queryString.parse(this.props.location.search);
    this.timer = setTimeout(() => {      
      window.opener.postMessage(status, '*');
      window.close();
    }, 2000)
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
  }

  render() {
    return (
      <Screen>     
        <Loader loading={this.state.loading} title="Verifying mandate, please wait..." />        
      </Screen>
    );
  }
}

export default MandateRedirect;

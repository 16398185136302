import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { Row, Col } from 'react-bootstrap';
import { Loader } from 'src/components';
import { Form, Formik } from 'formik';
import { ACTION_TYPES } from 'src/constants';
import { withRouter } from 'react-router-dom';
import { InputSelectOne } from 'src/components/form-inputs';
import { Container, Section, InputText } from '../../components';
import UsersTable from './users-table';
import styles from './_users.module.scss';

class Users extends Component {
  state = {
    loading: false,
    pageNumber: 1,
    filteredData: [],
    searchStatus: '',
    searchPhoneNumber: '',
    searchUserName: '',
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminUsersList({
          page: pageNumber,
        }),
    );
  };

  componentDidMount() {
    this.requestAdminUsersList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_USERS_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminUsersListSuccess = EventBus.on(ACTION_TYPES.ADMIN_USERS_LIST_SUCCESS, () => {
      const { adminUsers } = this.props;
      this.setState({ loading: false });
      this.setState({ filteredData: adminUsers?.users || [] });
    });
    this.adminUsersListFailure = EventBus.on(ACTION_TYPES.ADMIN_USERS_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminUsersList({
      page: this.state.pageNumber,
    });
  }

  componentWillUnmount() {
    this.requestAdminUsersList();
    this.adminUsersListSuccess();
    this.adminUsersListFailure();
  }

  _onActionClick = () => {
    // console.log('onButtonClick')
    this.props.history.push('/admin/paymentlink/new');
  };

  handleChange = (e) => {
    const { adminUsers } = this.props;
    const { searchStatus, searchPhoneNumber, searchUserName } = this.state;
    let filteredData = [];
    if (adminUsers?.users && adminUsers?.users.length > 0) {
      adminUsers?.users.forEach((data) => {
        const searchedStatus =
          data?.folio?.identificationConfirmed && data?.savingGoals.length > 0
            ? `KYC Completed`
            : data?.savingGoals.length > 0 && !data?.folio?.identificationConfirmed
            ? `KYC Pending`
            : 'KYC Pending';
        filteredData.push({ ...data, searchStatus: searchedStatus });
      });
    }

    if (e.target.name === 'searchStatus' || (searchStatus && e.target.name !== 'searchStatus')) {
      let regex = new RegExp(
        searchStatus && e.target.name !== 'searchStatus' ? searchStatus : e.target.value.trim(),
        'i',
      );
      filteredData = filteredData.filter((item) => regex.test(item?.searchStatus));
    }
    if (
      e.target.name === 'searchPhoneNumber' ||
      (searchPhoneNumber && e.target.name !== 'searchPhoneNumber')
    ) {
      let regex = new RegExp(
        searchPhoneNumber && e.target.name !== 'searchPhoneNumber'
          ? searchPhoneNumber
          : e.target.value,
        'i',
      );
      filteredData = filteredData.filter((item) => regex.test(item?.phoneNumber));
    }
    if (
      e.target.name === 'searchUserName' ||
      (searchUserName && e.target.name !== 'searchUserName')
    ) {
      let regex = new RegExp(
        searchUserName && e.target.name !== 'searchUserName' ? searchUserName : e.target.value,
        'i',
      );
      filteredData = filteredData.filter(
        (item) => regex.test(item?.firstName) || regex.test(item?.lastName),
      );
    }

    this.setState({
      [e.target.name]: (e.target.value && e.target.value.trim()) || '',
      filteredData,
    });
  };

  render() {
    const { loading, filteredData, searchPhoneNumber, searchUserName } = this.state;

    return (
      <div className={styles['payment-links']}>
        <Container
          title="User Analytics"
          buttonText="create a new link"
          onButtonClick={this._onActionClick}
        >
          <Formik
            initialValues={{
              searchStatus: '',
              searchUserName: '',
              searchPhoneNumber: '',
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Section title="Filter By">
                  <Row>
                    <Col>
                      <InputSelectOne
                        label="Status of User"
                        name="searchStatus"
                        labelStyle={styles['label']}
                        onChange={(e) => {
                          this.handleChange(e);
                          setFieldValue('searchStatus', e.target.value);
                        }}
                        className={styles['select']}
                      >
                        <option>&nbsp;</option>
                        <option value="Booking Completed">Booking Completed</option>
                        <option value="KYC Pending">KYC Pending</option>
                        <option value="KYC Completed">KYC Completed</option>
                        <option value="Auto Pay Pending">Auto Pay Pending</option>
                        <option value="Auto Pay Completed">Auto Pay Completed</option>
                      </InputSelectOne>
                    </Col>
                    <Col>
                      <InputText
                        label="User Name"
                        value={searchUserName}
                        name="searchUserName"
                        onChange={this.handleChange}
                        type="text"
                      />
                    </Col>
                    <Col>
                      <InputText
                        label="Customer Phone Number"
                        name="searchPhoneNumber"
                        onChange={this.handleChange}
                        value={searchPhoneNumber}
                      />
                    </Col>
                  </Row>
                </Section>
              </Form>
            )}
          </Formik>
          <UsersTable
            data={filteredData || []}
            totalItems={filteredData.length || 0}
            handlePaginationChange={this._handlePaginationChange}
          />
        </Container>
        <Loader loading={loading} />
      </div>
    );
  }
}

export default withRouter(Users);

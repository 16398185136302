import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
  requestFullKycRegistration,
  requestKycLogin,
  requestOTMBanks,
  requestBankDetails,
  requestSendPennyDrop,
  requestSubmitBankAccount,
  requestOccupationList,
  requestSubmitInvestorDetails,
  requestSubmitInvestorKYC,
  requestExtractPanCard,
  requestUploadPanCard,
  requestUploadForm,
  requestExtractAddressProof,
  requestUploadAddressProof,
  requestUploadSignature,
  requestStartVideoVerification,
  requestUploadVideoVerification,
  requestUploadPhoto,
  requestCreateContract,
  requestLongKycVerification,
  requestFatcha,
  requestKycCountries,
  requestKycIdentificationTypes,
  requestKycSourceOfWealth,
  requestPurchaseBanks,
  createBankAccount
} from 'src/redux/api';
import {
  requestTaxStatusSuccess,
  requestTaxStatusFailure,
  requestCheckPanSuccess,
  requestCheckPanFailure,
  requestSubmitPanSuccess,
  requestSubmitPanFailure,
  requestFullKycRegistrationSuccess,
  requestFullKycRegistrationFailure,
  requestKycLoginSuccess,
  requestKycLoginFailure,
  requestOTMBanksSuccess,
  requestOTMBanksFailure,
  requestBankDetailsSuccess,
  requestBankDetailsFailure,
  requestSendPennyDropSuccess,
  requestSendPennyDropFailure,
  requestSubmitBankAccountSuccess,
  requestSubmitBankAccountFailure,
  requestOccupationListSuccess,
  requestOccupationListFailure,
  requestSubmitInvestorDetailsSuccess,
  requestSubmitInvestorDetailsFailure,
  requestSubmitInvestorKYCSuccess,
  requestSubmitInvestorKYCFailure,
  extractPanCardSuccess,
  extractPanCardFailure,
  uploadPanCardSuccess,
  uploadPanCardFailure,
  uploadFormSuccess,
  uploadFormFailure,
  extractAddressProofSuccess,
  extractAddressProofFailure,
  uploadAddressProofSuccess,
  uploadAddressProofFailure,
  uploadSignatureSuccess,
  uploadSignatureFailure,
  startVideoVerificationSuccess,
  startVideoVerificationFailure,
  uploadVideoVerificationSuccess,
  uploadVideoVerificationFailure,
  uploadSelfieSuccess,
  uploadSelfieFailure,
  createContractSuccess,
  createContractFailure,
  longKycVerificationSuccess,
  longKycVerificationFailure,
  fatchaSuccess,
  fatchaFailure,
  countriesSuccess,
  countriesFailure,
  identificationTypeSuccess,
  identificationTypeFailure,
  sourcesOfWealthSuccess,
  sourcesOfWealthFailure,
  purchaseBanksSuccess,
  purchaseBanksFailure,
  updateBankAccountSuccess,
  updateBankAccountFailure
} from 'src/redux/actions';

export function* watchKycRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_TAX_STATUS, requestTaxStatuses);
  yield takeEvery(ACTION_TYPES.REQUEST_CHECK_PAN, checkPanStatus);
  yield takeEvery(ACTION_TYPES.REQUEST_SUBMIT_PAN, submitPanStatus);
  yield takeEvery(ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION, fullKycRegistration);
  yield takeEvery(ACTION_TYPES.REQUEST_KYC_LOGIN, kycLogin);
  yield takeEvery(ACTION_TYPES.REQUEST_OTM_BANKS, otmBanksRequest);
  yield takeEvery(ACTION_TYPES.REQUEST_BANK_DETAILS, bankDetailsRequest);
  yield takeEvery(ACTION_TYPES.REQUEST_SEND_PENNY_DROP, sendPennyDrop);
  yield takeEvery(ACTION_TYPES.REQUEST_SUBMIT_BANK_ACCOUNT, submitBankAccount);
  yield takeEvery(ACTION_TYPES.REQUEST_OCCUPATION_LIST, requestOccupations);
  yield takeEvery(ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS, submitInvestorDetails);
  yield takeEvery(ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_KYC, submitInvestorKYC);
  yield takeEvery(ACTION_TYPES.EXTRACT_PAN_CARD_REQUEST, extractPanCard);
  yield takeEvery(ACTION_TYPES.UPLOAD_PAN_CARD_REQUEST, uploadPanCard);
  yield takeEvery(ACTION_TYPES.UPLOAD_FORM_REQUEST, uploadForm);
  yield takeEvery(ACTION_TYPES.EXTRACT_ADDRESS_PROOF_REQUEST, extractAddressProof);
  yield takeEvery(ACTION_TYPES.UPLOAD_ADDRESS_PROOF_REQUEST, uploadAddressProof);
  yield takeEvery(ACTION_TYPES.UPLOAD_SIGNATURE_REQUEST, uploadSignature);
  yield takeEvery(ACTION_TYPES.START_VIDEO_VERIFICATION_REQUEST, startVideoVerification);
  yield takeEvery(ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_REQUEST, uploadVideoVerification);
  yield takeEvery(ACTION_TYPES.UPLOAD_SELFIE_REQUEST, uploadSelfie);
  yield takeEvery(ACTION_TYPES.CREATE_CONTRACT_REQUEST, createContract);
  yield takeEvery(ACTION_TYPES.LONG_KYC_VERIFICATION_REQUEST, longKycVerification);
  yield takeEvery(ACTION_TYPES.FATCHA_REQUEST, fatcha);
  yield takeEvery(ACTION_TYPES.KYC_COUNTRIES_REQUEST, countriesRequest);
  yield takeEvery(ACTION_TYPES.KYC_IDENTIFICATION_TYPES_REQUEST, identificationTypeRequest);
  yield takeEvery(ACTION_TYPES.KYC_SOURCES_OF_WEALTH_REQUEST, sourcesOfWealthRequest);
  yield takeEvery(ACTION_TYPES.PURCHASE_BANKS_REQUEST, purchaseBanks);
  yield takeEvery(ACTION_TYPES.UPDATE_BANK_ACCOUNT_REQUEST, updateBank);
}

function* fatcha(action) {
  try {
    const fatcha = yield call(requestFatcha, action.payload);
    yield put(fatchaSuccess(fatcha));
  } catch (e) {
    yield put(fatchaFailure(e));
  }
}

function* requestTaxStatuses() {
  try {
    const taxStatuses = yield call(requestTaxStatus);
    yield put(requestTaxStatusSuccess(taxStatuses));
  } catch (e) {
    yield put(requestTaxStatusFailure(e));
  }
}

function* identificationTypeRequest() {
  try {
    const identificationTypes = yield call(requestKycIdentificationTypes);
    yield put(identificationTypeSuccess(identificationTypes));
  } catch (e) {
    yield put(identificationTypeFailure(e));
  }
}

function* sourcesOfWealthRequest() {
  try {
    const sourcesOfWealth = yield call(requestKycSourceOfWealth);
    yield put(sourcesOfWealthSuccess(sourcesOfWealth));
  } catch (e) {
    yield put(sourcesOfWealthFailure(e));
  }
}

function* countriesRequest() {
  try {
    const countries = yield call(requestKycCountries);
    yield put(countriesSuccess(countries));
  } catch (e) {
    yield put(countriesFailure(e));
  }
}

function* checkPanStatus(action) {
  try {
    const checkPan = yield call(requestCheckPan, action.payload);
    yield put(requestCheckPanSuccess(checkPan));
  } catch (e) {
    yield put(requestCheckPanFailure(e));
  }
}

function* submitPanStatus(action) {
  try {
    const submitPan = yield call(requestSubmitPan, action.payload);
    yield put(requestSubmitPanSuccess(submitPan));
  } catch (e) {
    yield put(requestSubmitPanFailure(e));
  }
}

function* fullKycRegistration(action) {
  try {
    const folio = yield call(requestFullKycRegistration, action.payload);
    yield put(requestFullKycRegistrationSuccess(folio));
  } catch (e) {
    yield put(requestFullKycRegistrationFailure(e));
  }
}

function* kycLogin(action) {
  try {
    const folio = yield call(requestKycLogin, action.payload);
    yield put(requestKycLoginSuccess(folio));
  } catch (e) {
    yield put(requestKycLoginFailure(e));
  }
}

function* otmBanksRequest(action) {
  try {
    const banks = yield call(requestOTMBanks, action.payload);
    yield put(requestOTMBanksSuccess(banks));
  } catch (e) {
    yield put(requestOTMBanksFailure(e));
  }
}

function* bankDetailsRequest(action) {
  try {
    const bankDetails = yield call(requestBankDetails, action.payload);
    yield put(requestBankDetailsSuccess(bankDetails));
  } catch (e) {
    yield put(requestBankDetailsFailure(e));
  }
}

function* sendPennyDrop(action) {
  try {
    const pennyDrop = yield call(requestSendPennyDrop, action.payload);
    yield put(requestSendPennyDropSuccess(pennyDrop));
  } catch (e) {
    yield put(requestSendPennyDropFailure(e));
  }
}

function* submitBankAccount(action) {
  try {
    const response = yield call(requestSubmitBankAccount, action.payload);
    yield put(requestSubmitBankAccountSuccess(response));
  } catch (e) {
    yield put(requestSubmitBankAccountFailure(e));
  }
}

function* requestOccupations() {
  try {
    const occupations = yield call(requestOccupationList);
    yield put(requestOccupationListSuccess(occupations));
  } catch (e) {
    yield put(requestOccupationListFailure(e));
  }
}

function* submitInvestorDetails(action) {
  try {
    const details = yield call(requestSubmitInvestorDetails, action.payload);
    yield put(requestSubmitInvestorDetailsSuccess(details));
  } catch (e) {
    yield put(requestSubmitInvestorDetailsFailure(e));
  }
}

function* submitInvestorKYC(action) {
  try {
    const kyc = yield call(requestSubmitInvestorKYC, action.payload);
    yield put(requestSubmitInvestorKYCSuccess(kyc));
  } catch (e) {
    yield put(requestSubmitInvestorKYCFailure(e));
  }
}

function* extractPanCard(action) {
  try {
    const kyc = yield call(requestExtractPanCard, action.payload);
    yield put(extractPanCardSuccess(kyc));
  } catch (e) {
    yield put(extractPanCardFailure(e));
  }
}

function* uploadPanCard(action) {
  try {
    const pancard = yield call(requestUploadPanCard, action.payload);
    yield put(uploadPanCardSuccess(pancard));
  } catch (e) {
    yield put(uploadPanCardFailure(e));
  }
}

function* uploadForm(action) {
  try {
    const form = yield call(requestUploadForm, action.payload);
    yield put(uploadFormSuccess(form));
  } catch (e) {
    yield put(uploadFormFailure(e));
  }
}

function* extractAddressProof(action) {
  try {
    const proof = yield call(requestExtractAddressProof, action.payload);
    yield put(extractAddressProofSuccess(proof));
  } catch (e) {
    yield put(extractAddressProofFailure(e));
  }
}

function* uploadAddressProof(action) {
  try {
    const proof = yield call(requestUploadAddressProof, action.payload);
    yield put(uploadAddressProofSuccess(proof));
  } catch (e) {
    yield put(uploadAddressProofFailure(e));
  }
}

function* uploadSignature(action) {
  try {
    const signature = yield call(requestUploadSignature, action.payload);
    yield put(uploadSignatureSuccess(signature));
  } catch (e) {
    yield put(uploadSignatureFailure(e));
  }
}

function* startVideoVerification(action) {
  try {
    const response = yield call(requestStartVideoVerification, action.payload);
    yield put(startVideoVerificationSuccess(response));
  } catch (e) {
    yield put(startVideoVerificationFailure(e));
  }
}

function* uploadVideoVerification(action) {
  try {
    const response = yield call(requestUploadVideoVerification, action.payload);
    yield put(uploadVideoVerificationSuccess(response));
  } catch (e) {
    yield put(uploadVideoVerificationFailure(e));
  }
}

function* uploadSelfie(action) {
  try {
    const response = yield call(requestUploadPhoto, action.payload);
    yield put(uploadSelfieSuccess(response));
  } catch (e) {
    yield put(uploadSelfieFailure(e));
  }
}

function* createContract(action) {
  try {
    const response = yield call(requestCreateContract, action.payload);
    yield put(createContractSuccess(response));
  } catch (e) {
    yield put(createContractFailure(e));
  }
}

function* longKycVerification(action) {
  try {
    const response = yield call(requestLongKycVerification, action.payload);
    yield put(longKycVerificationSuccess(response));
  } catch (e) {
    yield put(longKycVerificationFailure(e));
  }
}

function* purchaseBanks(action) {
  try {
    const response = yield call(requestPurchaseBanks, action.payload);
    yield put(purchaseBanksSuccess(response));
  } catch (e) {
    yield put(purchaseBanksFailure(e));
  }
}

function* updateBank(action) {
  try {
    const response = yield call(createBankAccount, action.payload);
    yield put(updateBankAccountSuccess(response));
  } catch (e) {
    yield put(updateBankAccountFailure(e));
  }
}
import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { Form, Dropdown } from 'react-bootstrap';
import cx from 'classnames';
import moment from 'moment';
import styles from './_custom-datepicker.module.scss';

const months = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
];

const CustomDatepicker = ({ label, ...props }) => {
  const { handleDatePicker, selectedValue } = props;
  const [field, meta] = useField(props);

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [daysInMonth, setDaysInMonth] = useState(30);
  const dt = new Date();
  const getYear = dt.getFullYear();

  useEffect(() => {
    if (!selectedValue) return;
    const check = moment(selectedValue, 'YYYY/MM/DD');
    const month = check.format('M');
    const day = check.format('D');
    const year = check.format('YYYY');
    setDay(day);
    setMonth(month);
    setYear(year);
  }, [selectedValue]);

  useEffect(() => {
    handleDaysInMonth();
    handleDatePickerValue();
  }, [day, month, year, daysInMonth]);

  const handleDay = (day) => {
    setDay(day);
  };

  const handleMonth = (month) => {
    setMonth(month);
  };

  const handleYear = (year) => {
    setYear(year);
  };

  const handleDatePickerValue = () => {
    if (day && month && year) {
      const date = `${month}/${day}/${year}`;
      handleDatePicker(new Date(date));
    } else {
      handleDatePicker('');
    }
  };

  const handleDaysInMonth = () => {
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day > daysInMonth) {
      setDay('');
    }
    setDaysInMonth(daysInMonth);
  };

  return (
    <div className={styles['c-datepicker']}>
      <h2>{label}</h2>
      <div className={styles['dropdown-group']}>
        <Dropdown className={styles['dropdown']}>
          <Dropdown.Toggle
            className={styles['dropdown-button']}
            variant="default"
            id="dropdown-basic"
            placeholder="Day"
          >
            {day}
          </Dropdown.Toggle>
          {!day && <div className={styles['error']}>Required Day</div>}
          <Dropdown.Menu className={styles['dropdown-menu']}>
            {new Array(daysInMonth).fill().map((d, index) => (
              <Dropdown.Item
                key={index}
                className={styles['dropdown-item']}
                onClick={() => handleDay(index + 1)}
              >
                {index < 9 ? `0${index + 1}` : index + 1}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className={styles['dropdown']}>
          <Dropdown.Toggle
            className={cx(styles['dropdown-button'], styles['dropdown-button-name'])}
            variant="default"
            id="dropdown-basic"
          >
            {months.find((m) => m.id === parseInt(month))?.name}
          </Dropdown.Toggle>
          {!month && <div className={styles['error']}>Required Month</div>}
          <Dropdown.Menu className={styles['dropdown-menu']}>
            {months.map((data, index) => {
              const { name } = data || {};
              return (
                <Dropdown.Item
                  key={index}
                  className={styles['dropdown-item']}
                  onClick={() => handleMonth(index + 1)}
                >
                  {name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className={styles['dropdown']}>
          <Dropdown.Toggle
            className={styles['dropdown-button']}
            variant="default"
            id="dropdown-basic"
          >
            {year}
          </Dropdown.Toggle>
          {!year && <div className={styles['error']}>Required Year</div>}
          <Dropdown.Menu className={styles['dropdown-menu']}>
            {new Array(120).fill().map((d, index) => (
              <Dropdown.Item
                key={index}
                className={styles['dropdown-item']}
                onClick={() => handleYear(getYear - index)}
              >
                {getYear - index}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Form.Control
        size="sm"
        {...field}
        {...props}
        value={selectedValue}
        className={styles['hidden-input']}
      />
      {meta.touched && meta.error ? <div className={styles['error']}>{meta.error}</div> : null}
    </div>
  );
};

export default CustomDatepicker;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import styles from './_previewimages.module.scss';

export class PreviewImages extends Component {
  state = {
    loading: false,
    deleteId: '',
  };

  componentDidMount() {
    this.adminProductImageDelete = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE, () =>
      this.setState({ loading: true }),
    );
    this.adminProductImageDeleteSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE_SUCCESS,
      () => this._handleDeleteSuccess(),
    );
    this.adminProductImageDeleteFailed = EventBus.on(
      ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE_FAILED,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.adminProductImageDelete();
    this.adminProductImageDeleteSuccess();
    this.adminProductImageDeleteFailed();
  }

  _handleDeleteSuccess = () => {
    const imageUrls = this.props.imageUrls.filter((imageUrl) => imageUrl.id != this.state.deleteId);
    this.props.formikRef.setFieldValue('image_urls', imageUrls);
    this.setState({ loading: false, deleteId: '' });
  };

  _handleImageDelete = (id) => {
    this.setState({ deleteId: id }, () => this.props.adminProductImageDelete({ id }));
  };

  _handleImageDataUriDelete = (index) => {
    this.props.imageDataUris.splice(index, 1);
    this.props.formikRef.setFieldValue('images', this.props.imageDataUris);
  };

  _handleImageDeleteUrl = (id) => {
    const imageUrls = this.props.imageUrls.filter((imageUrl) => imageUrl.id != id);
    this.props.formikRef.setFieldValue('image_urls', imageUrls);
  };

  render() {
    const { imageUrls, imageDataUris = [], isImageUploading } = this.props;

    return (
      <div className={styles.container}>
        {imageUrls &&
          imageUrls.length > 0 &&
          imageUrls.map((image) => (
            <div className={styles.imageContainer} key={image.id}>
              <img src={image.url} alt={image.id} />
              <span
                className={styles.icon}
                onClick={() => {
                  if (image?.isTempImage) {
                    this._handleImageDeleteUrl(image.id);
                  } else {
                    this._handleImageDelete(image.id);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          ))}
        {imageDataUris &&
          imageDataUris.length > 0 &&
          imageDataUris.map((imageData, index) => (
            <div className={styles.imageContainer} key={index}>
              <img src={imageData.src} alt={imageData.id} />
              <span className={styles.icon} onClick={() => this._handleImageDataUriDelete(index)}>
                <FontAwesomeIcon icon={faWindowClose} />
              </span>
            </div>
          ))}
        {isImageUploading && (
          <div className={styles.imageUploading}>
            Image&nbsp; &nbsp;
            <br />
            Uploading...
          </div>
        )}
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default PreviewImages;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { Loader, SavvySupport } from 'src/components';
import styles from './pay-kyc.module.scss';
import OfferBox from '../pay/offer-box';
import VerifyKyc from './verify-kyc';
import LinkAccount from './link-account';
import Demographic from './demographic';
import KycVerified from './kyc-verified';
// import KycFailed from './kyc-failed';
// import ExtraInfo from '../extra-info';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class PayKyc extends Component {
  state = {
    loading: false,
    currentIndex: 0,
    oldIndex: 0,
    // currentIndex: 0,
    // showBack: false,
  };

  settings = {
    dots: false,
    swipe: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  componentDidMount() {}

  componentWillUnmount() {}

  _onNext = () => {
    this.setState({ currentIndex: this.state.currentIndex + 1 }, () => {
      this.slider.slickNext();
    });
  };

  _onPrev = () => {
    this.setState({ currentIndex: this.state.currentIndex - 1 }, () => {
      this.slider.slickPrev();
    });
  };

  _onGoto = (index) => {
    this.setState({ currentIndex: index }, () => {
      this.slider.slickGoTo(index);
    });
  };

  _onGoBack = () => {
    this.setState({ currentIndex: this.state.oldIndex }, () => {
      this._onGoto(this.state.oldIndex);
    });
  };

  _navigateToLongKyc = () => {
    this.props.history.push('/checkout/long-kyc');
  };

  _navigateToSIP = () => {
    this.props.history.push('/checkout/sip');
  };

  render() {
    const { loading, currentIndex } = this.state;
    const { partner, product, user } = this.props;

    return (
      <>
        <div className={styles['pay-kyc']}>
          <div className={styles['pay-box']}>
            <div className={styles['left-box']}>
              <Slider ref={(slider) => (this.slider = slider)} {...this.settings}>
                <div>
                  <VerifyKyc
                    isActive={currentIndex === 0}
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                    navigateToLongKyc={this._navigateToLongKyc}
                  />
                </div>
                <div>
                  <LinkAccount
                    isActive={currentIndex === 1}
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                  />
                </div>
                <div>
                  <Demographic
                    isActive={currentIndex === 2}
                    onNext={this._onNext}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                  />
                </div>
                <div>
                  <KycVerified
                    isActive={currentIndex === 3}
                    onNext={this._navigateToSIP}
                    onPrev={this._onPrev}
                    onGoto={this._onGoto}
                  />
                </div>
                {/* <div>
                  <KycFailed onNext={this._onNext} onPrev={this._onPrev} onGoto={this._onGoto} />
                </div> */}
              </Slider>
            </div>

            <div className={styles['right-box']}>
              <OfferBox partner={partner} product={product} user={user} />
            </div>
          </div>
          {/* <ExtraInfo /> */}
          <SavvySupport />
          <Loader loading={loading} fixed />
        </div>
      </>
    );
  }
}

export default withRouter(PayKyc);

import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AdminLayout from './admin-layout';
import {
  Goals,
  ProductCatalogue,
  ProductOffers,
  Investments,
  NewProduct,
  GenerateGoal,
  UserCatalogue,
  GeneratePaymentLink,
  UserDetails,
  ProductDetails,
  GoalDetails,
  ProductOfferDetails,
  GenerateProductPaymentLink,
  NewProductOffer,
  AdminTransactions,
  Deposits,
  withdrawals,
  AdminProfile,
  AdminPartnerUser,
  UserPaymentDetail,
  PaymentLinks,
  AdminPartners,
  CollectRequest,
  CollectPaymentDetail,
  EmailTemplates,
} from './screens';

class Admin extends Component {
  render() {
    return (
      <>
        <AdminLayout>
          <Switch>
            <Route exact path="/admin" component={GenerateProductPaymentLink} />
            <Route exact path="/admin/products/offers" component={ProductOffers} />
            <Route exact path="/admin/products" component={ProductCatalogue} />
            <Route exact path="/admin/products/new" component={NewProduct} />
            <Route exact path="/admin/products/offers/new" component={NewProductOffer} />
            <Route exact path="/admin/product/payment" component={GenerateProductPaymentLink} />
            <Route exact path="/admin/product/:uuid" component={ProductDetails} />
            <Route exact path="/admin/product/offer/:uuid" component={ProductOfferDetails} />
            <Route exact path="/admin/offer/payment" component={GeneratePaymentLink} />
            <Route exact path="/admin/purchases" component={Goals} />
            <Route exact path="/admin/goal/new" component={GenerateGoal} />
            <Route exact path="/admin/goal/:id" component={GoalDetails} />
            <Route exact path="/admin/products/offers" component={ProductOffers} />
            <Route exact path="/admin/payment" component={GeneratePaymentLink} />
            <Route exact path="/admin/payment_links" component={PaymentLinks} />
            <Route exact path="/admin/payment_links/:uuid" component={UserPaymentDetail} />
            <Route exact path="/admin/collect-requests" component={CollectRequest} />
            <Route exact path="/admin/collect-requests/:uuid" component={CollectPaymentDetail} />
            <Route exact path="/admin/profile" component={AdminProfile} />
            <Route exact path="/admin/agent" component={AdminPartnerUser} />
            <Route exact path="/admin/partners" component={AdminPartners} />
            {/* <Route exact path="/admin/transactions" component={AdminTransactions} /> */}
            <Route exact path="/admin/transactions" component={AdminTransactions} />
            <Route exact path="/admin/transactions/deposits" component={Deposits} />
            <Route exact path="/admin/transactions/withdrawals" component={withdrawals} />
            <Route exact path="/admin/users" component={UserCatalogue} />
            <Route exact path="/admin/user/:uuid" component={UserDetails} />
            <Route path="/admin/investments" component={Investments} />
            <Route exact path="/admin/marketing/email-templates" component={EmailTemplates} />
          </Switch>
        </AdminLayout>
      </>
    );
  }
}

export default withRouter(Admin);

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IMAGES } from 'src/constants';
// import globalStyles from '../_styles.module.scss';
import styles from './_inputfilethree.module.scss';

const InputFileThree = ({ label, ...props }) => (
  <>
    <Row>
      <Col md={12}>
        {props.heading &&
          <div className={styles.header}>
            <span className={styles.heading}>{props.heading}</span>
          </div>
        }
        <div className={styles.imageInputContainer}>
          <div className={styles.imageContainer}>
            { props.src? <img alt={props.name} src={props.src} /> : null }
            {/* eslint-disable-next-line */}
            { props.source? <video src={props.source} controls className={styles.video} />  : null }
          </div>
          <div className={styles.rightContainer}>
            {/* <FontAwesomeIcon icon={faRedo}/> */}
            <img src={IMAGES.ICON_RELOAD} alt="" width="20"/>
            <button htmlFor={props.id || props.name} className={`${styles.label} btn btn-primary`} onClick={props.onClick}>
              {props.ctaTitle}
            </button>
          </div>
        </div>
        {/* {meta.touched && meta.error ? (
          <div className={globalStyles.error}>{meta.error}</div>
        ) : null} */}
      </Col>
    </Row>
  </>
)

export default InputFileThree;

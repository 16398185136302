import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminLogin(data) {
  return ApiRequest.fetchAdmin({
    method: 'put',
    url: API_END_POINTS.ADMIN_LOGIN,
    data,
  }).then((response) => ({ user: response.data, authCredentials: response.headers }));
}

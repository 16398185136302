import React, { Component } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import Select from 'react-select';
import { ACTION_TYPES } from 'src/constants';
import styles from './_fatca.module.scss';

const ADDRESS_TYPES = {
  RESIDENTIAL_OR_BUSINESS: 'Residential or Business',
  RESIDENTIAL: 'Residential',
  BUSINESS: 'Business',
  RESIDENTIAL_OFFICE: 'Registered Office',
};

const ADDRESS_TYPES_LIST = [
  {
    id: 1,
    label: ADDRESS_TYPES.RESIDENTIAL_OR_BUSINESS,
    value: ADDRESS_TYPES.RESIDENTIAL_OR_BUSINESS,
  },
  {
    id: 2,
    label: ADDRESS_TYPES.RESIDENTIAL,
    value: ADDRESS_TYPES.RESIDENTIAL,
  },
  {
    id: 3,
    label: ADDRESS_TYPES.BUSINESS,
    value: ADDRESS_TYPES.BUSINESS,
  },
  {
    id: 4,
    label: ADDRESS_TYPES.RESIDENTIAL_OFFICE,
    value: ADDRESS_TYPES.RESIDENTIAL_OFFICE,
  },
];

const INCOME_LIST = [
  {
    value: '32',
    label: '1-5 lacs',
  },
  {
    value: '33',
    label: '5-10 lacs',
  },
  {
    value: '34',
    label: '10-25 lacs',
  },
  {
    value: '35',
    label: '>25 Lacs-1 crore',
  },
  {
    value: "31",
    label: 'Below 1 lac',
  },
  {
    value: '36',
    label: '>1 crore',
  },
];

class Fatca extends Component {
  state = {
    form: {
      birth_country_code: '',
      citizenship_country_code: '',
      tax_country_code: '',
      tax_id_text: '',
      tax_identification_type: '',
      place_of_birth: '',
      address_type: '',
      occupation: '',
      gross_income: '',
      source_wealth: '',
      // tin_resn_text: 'abd',
      // tin_resn: 'asdf',
    },
    countries: [],
    identificationTypes: [],
    sources: [],
    occupations: [],
    loading: false,
    isFetchingCountries: false,
    isFetchingIdentificationTypes: false,
    isFetchingOccupationList: false,
    isFetchingSourcesWealth: false,
  };

  componentDidMount() {
    this.props.countriesRequest();
    this.props.identificationTypeRequest();
    this.props.requestOccupationList();
    this.props.sourcesOfWealthRequest();
    this.setState({ form: this.props.form || this.state.form });

    this.handleCountriesRequest = EventBus.on(ACTION_TYPES.KYC_COUNTRIES_REQUEST, () =>
      this.setState({ isFetchingCountries: true }),
    );
    this.handleCountriesSuccess = EventBus.on(
      ACTION_TYPES.KYC_COUNTRIES_SUCCEEDED,
      this.handleCountries,
    );
    this.handleCountriesFailure = EventBus.on(ACTION_TYPES.KYC_COUNTRIES_FAILED, () =>
      this.setState({ isFetchingCountries: false }),
    );

    this.handleSourcesOfWealthRequest = EventBus.on(
      ACTION_TYPES.KYC_SOURCES_OF_WEALTH_REQUEST,
      () => this.setState({ isFetchingSourcesWealth: true }),
    );
    this.handleSourcesOfWealthSuccess = EventBus.on(
      ACTION_TYPES.KYC_SOURCES_OF_WEALTH_SUCCEEDED,
      this.handleSourcesOfWealth,
    );
    this.handleSourcesOfWealthFailure = EventBus.on(ACTION_TYPES.KYC_SOURCES_OF_WEALTH_FAILED, () =>
      this.setState({ isFetchingSourcesWealth: false }),
    );

    this.handleOccupationListRequest = EventBus.on(ACTION_TYPES.REQUEST_OCCUPATION_LIST, () =>
      this.setState({ isFetchingOccupationList: true }),
    );
    this.handleOccupationListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_OCCUPATION_LIST_SUCCEEDED,
      this.handleOccupationList,
    );
    this.handleOccupationListFailure = EventBus.on(
      ACTION_TYPES.REQUEST_OCCUPATION_LIST_FAILED,
      () => this.setState({ isFetchingOccupationList: false }),
    );

    this.handleIdentificationTypeRequest = EventBus.on(
      ACTION_TYPES.KYC_IDENTIFICATION_TYPES_REQUEST,
      () => this.setState({ isFetchingIdentificationTypes: true }),
    );
    this.handleIdentificationTypeSuccess = EventBus.on(
      ACTION_TYPES.KYC_IDENTIFICATION_TYPES_SUCCEEDED,
      this.handleIdentificationTypes,
    );
    this.handleIdentificationTypeFailure = EventBus.on(
      ACTION_TYPES.KYC_IDENTIFICATION_TYPES_FAILED,
      () => this.setState({ isFetchingIdentificationTypes: false }),
    );

    this.submitFatchaRequest = EventBus.on(ACTION_TYPES.FATCHA_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.submitFatchaSuccess = EventBus.on(ACTION_TYPES.FATCHA_SUCCESS, this.fatchaSubmitted);
    this.submitFatchaFailure = EventBus.on(ACTION_TYPES.FATCHA_FAILED, () =>
      this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.handleCountriesRequest();
    this.handleCountriesSuccess();
    this.handleCountriesFailure();

    this.handleIdentificationTypeRequest();
    this.handleIdentificationTypeSuccess();
    this.handleIdentificationTypeFailure();

    this.submitFatchaRequest();
    this.submitFatchaSuccess();
    this.submitFatchaFailure();
  }

  fatchaSubmitted = (response) => {
    console.log('response', response); // To do
    this.props.onComplete && this.props.onComplete();
  };

  handleCountries = (response) => {
    const countries = response?.countries?.map((country) => ({
      label: country?.name,
      value: country?.code,
    }));
    this.setState({ isFetchingCountries: false, countries });
  };

  handleOccupationList = (response) => {
    const occupations = response?.map((occupation) => ({
      label: occupation?.occupationName,
      value: occupation?.occupationCode,
    }));
    this.setState({ isFetchingOccupationList: false, occupations });
  };

  handleSourcesOfWealth = (response) => {
    const sources = response?.types?.map((source) => ({
      label: source?.name,
      value: source?.code,
    }));
    this.setState({ isFetchingSourcesWealth: false, sources });
  };

  handleIdentificationTypes = (response) => {
    const identificationTypes = response?.types?.map((type) => ({
      label: type?.name,
      value: type?.code,
    }));
    this.setState({ isFetchingIdentificationTypes: false, identificationTypes });
  };

  handleChangeInput = (e) => {
    this.setState({ ...this.state, form: { ...this.state.form, [e.target.name]: e.target.value } });
  };

  onFormSubmit = () => {
    const { fatchaRequest, fatchaFormSuccess, isFatca } = this.props;
    const { form } = this.state;
    // const store = global.store.getState();
    fatchaFormSuccess({ form });

    const paramData = {
      birth_country_code: form?.birth_country_code?.value || '',
      citizenship_country_code: form?.citizenship_country_code?.value || '',
      tax_country_code: form?.tax_country_code?.value || '',
      tax_id_text: form?.tax_id_text || '',
      tax_identification_type: form?.tax_identification_type?.value || '',
      place_of_birth: form?.place_of_birth || '',
      address_type: form?.address_type?.value || '',
      occupation: form?.occupation?.value || '',
      gross_income: String(form?.gross_income?.value) || '',
      source_wealth: form?.source_wealth?.value || '',
      tin_resn: "",
      tin_resn_text: "",
      is_fatca: isFatca ? 'Y' : 'N'
    };
    fatchaRequest(paramData);
  };

  handleSelect = ({ key, selectedData }) => {    
    this.setState({ form: { ...this.state.form, [key]: selectedData } });
  };

  render() {
    const {
      form: {
        birth_country_code,
        citizenship_country_code,
        tax_country_code,
        tax_id_text,
        tax_identification_type,
        place_of_birth,
        address_type,
        occupation,
        gross_income,
        source_wealth,
      },
      countries,
      occupations,
      sources,
      identificationTypes,
      isFetchingCountries,
      isFetchingIdentificationTypes,
      isFetchingOccupationList,
      isFetchingSourcesWealth,
    } = this.state;

    return (
      <div className={styles['fatcha']}>
        <Row>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Birth country</label>
              </div>
              <div>
                <Select
                  placeholder={isFetchingCountries ? 'Fetching...' : 'Birth country'}
                  value={birth_country_code}
                  options={countries}
                  isDisabled={isFetchingCountries}
                  name="birth_country_code"
                  className={styles['custom-select']}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>
                    this.handleSelect({ key: 'birth_country_code', selectedData })
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Citizenship country</label>
              </div>
              <div>
                <Select
                  placeholder={isFetchingCountries ? 'Fetching...' : 'Citizenship country'}
                  value={citizenship_country_code}
                  options={countries}
                  name="citizenship_country_code"
                  className={styles['custom-select']}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>
                    this.handleSelect({ key: 'citizenship_country_code', selectedData })
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Tax country</label>
              </div>
              <div>
                <Select
                  placeholder={isFetchingCountries ? 'Fetching...' : 'Tax country'}
                  value={tax_country_code}
                  options={countries}
                  isDisabled={isFetchingCountries}
                  name="tax_country_code"
                  className={styles['custom-select']}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>
                    this.handleSelect({ key: 'tax_country_code', selectedData })
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Tax ID type</label>
              </div>
              <div>
                <Select
                  placeholder={isFetchingIdentificationTypes ? 'Fetching...' : 'Tax ID type'}
                  value={tax_identification_type}
                  options={identificationTypes}
                  isDisabled={isFetchingIdentificationTypes}
                  name="tax_identification_type"
                  className={styles['custom-select']}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>                     
                    this.handleSelect({ key: 'tax_identification_type', selectedData })
                  }
                />
              </div>
            </div>
          </Col>

          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Tax ID number</label>
              </div>
              <div>
                <InputMask
                  className={styles['pv-pan-verify-input']}
                  name="tax_id_text"
                  placeholder="Tax ID number"
                  value={tax_id_text}
                  onChange={this.handleChangeInput}
                  disabled={this.state.loading}
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Place of birth</label>
              </div>
              <div>
                <InputMask
                  className={styles['pv-pan-verify-input']}
                  name="place_of_birth"
                  placeholder="Place of birth"
                  value={place_of_birth}
                  onChange={this.handleChangeInput}
                  disabled={this.state.loading}
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Address Type</label>
              </div>
              <div>
                <Select
                  placeholder="Address Type"
                  value={address_type}
                  options={ADDRESS_TYPES_LIST}
                  className={styles['custom-select']}
                  name="address_type"
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>
                    this.handleSelect({ key: 'address_type', selectedData })
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Occupation</label>
              </div>
              <div>
                <Select
                  placeholder={isFetchingOccupationList ? 'Fetching...' : 'Occupation'}
                  value={occupation}
                  options={occupations}
                  isDisabled={isFetchingOccupationList}
                  className={styles['custom-select']}
                  name="occupation"
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>
                    this.handleSelect({ key: 'occupation', selectedData })
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Gross Income</label>
              </div>
              <div>
                <Select
                  placeholder="Gross Income"
                  value={gross_income}
                  options={INCOME_LIST}
                  name="gross_income"
                  className={styles['custom-select']}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>
                    this.handleSelect({ key: 'gross_income', selectedData })
                  }
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles['pv-group-input']}>
              <div>
                <label>Source of Wealth</label>
              </div>
              <div>
                <Select
                  placeholder={isFetchingSourcesWealth ? 'Fetching...' : 'Source of Wealth'}
                  value={source_wealth}
                  options={sources}
                  isDisabled={isFetchingSourcesWealth}
                  name="source_wealth"
                  className={styles['custom-select']}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'white',
                      height: '49px',
                      borderRadius: '8px',
                    }),
                  }}
                  onChange={(selectedData) =>
                    this.handleSelect({ key: 'source_wealth', selectedData })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <Button
          disabled={this.state.loading}
          className={styles['pv-next-btn']}
          onClick={this.onFormSubmit}
          loading={this.state.loading}
        >
          {this.state.loading ? 'Loading...' : 'Next'}
        </Button>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default Fatca;

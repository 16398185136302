import { connect } from 'react-redux';
import {
  startVideoVerificationRequest,
  uploadVideoVerificationRequest,
  updateKycParams,
} from 'src/redux/actions';
import VideoVerification from './video-verification';

function mapStateToProps(state) {
  return { kyc: state.kyc };
}

export default connect(mapStateToProps, {
  startVideoVerificationRequest,
  uploadVideoVerificationRequest,
  updateKycParams,
})(VideoVerification);

import React, { Component } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { withRouter, Link } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { Screen, Loader } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import styles from './_admin-logout.module.scss';

class AdminLogout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logoutSuccess: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.adminLogoutSuccess = EventBus.on(ACTION_TYPES.ADMIN_LOGOUT_SUCCESS, () =>
      this.setState({ loading: false, logoutSuccess: true }),
    );
    this.adminLogoutFailure = EventBus.on(ACTION_TYPES.ADMIN_LOGOUT_FAILED, () =>
      this.setState({ loading: false }),
    );

    setTimeout(() => {
      this.props.requestAdminLogout();
    }, 1000);
  }

  componentWillUnmount() {
    this.adminLogoutSuccess();
    this.adminLogoutFailure();
  }

  render() {
    return (
      <Screen hideHeaderNav>
        <div className={styles['logout-container']}>
          <Loader loading={this.state.loading} title="Logging you out, please wait.." />
          {this.state.logoutSuccess ? (
            <div>
              <Player
                autoplay
                keepLastFrame
                src="https://assets7.lottiefiles.com/packages/lf20_te6kna0d.json"
                style={{ height: 150, width: 150 }}
              />
              <div className={styles['description']}>
                <h1>Logout Success!</h1>
                <p>Your have successfully logged out.</p>
                <Link to="/admin/sign-in">Click here</Link> to sign-in again
              </div>
            </div>
          ) : null}
        </div>
      </Screen>
    );
  }
}

export default withRouter(AdminLogout);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import {
  TextInputThree,
  InputSelectTwo,
  InputRadio,
  InputCheckbox,
} from 'src/components/form-inputs';
import { ApiRequest } from 'src/helpers';
import { CtaTwo, SecuredPartners } from 'src/components';
import { ACTION_TYPES, API_END_POINTS } from 'src/constants';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './_demographic-info.module.scss';

class DemographicInfo extends Component {
  state = {
    occupationList: [
      { id: '01', text: 'Private Sector Service' },
      { id: '02', text: 'Public Sector Service' },
      { id: '03', text: 'Business' },
      { id: '04', text: 'Professional' },
      { id: '06', text: 'Retired' },
      { id: '07', text: 'Housewife' },
      { id: '08', text: 'Student' },
      { id: '10', text: 'Government Service' },
      { id: '99', text: 'Others' },
      { id: '11', text: 'Self Employed' },
      { id: '12', text: 'Not Categorized' },
    ],
    salaries: [
      { id: '31', text: 'Below 1 Lac' },
      { id: '32', text: '1-5 lacs' },
      { id: '33', text: '5-10 lacs' },
      { id: '34', text: '10-25 lacs' },
      { id: '35', text: '25 Lacs-1 crore' },
      { id: '36', text: '>1 crore' },
    ],
    loading: false,
  };

  componentDidMount() {
    this.fullKycRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION, () =>
      this.setState({ loading: true }),
    );
    this.fullKycSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_SUCCEEDED,
      this._onFullKycReceive,
    );
    this.fullKycFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_FULL_KYC_REGISTRATION_FAILED,
      () => this.setState({ loading: false }),
    );

    this.submitInvestorDetailsRequestSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS,
      () => this.setState({ loading: true }),
    );
    this.submitInvestorDetailsSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_SUCCEEDED,
      this._onInvestorDetailsSubmitted,
    );
    this.submitInvestorDetailsFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_INVESTOR_DETAILS_FAILED,
      () => this.setState({ loading: false }),
    );

    this.userUpdateRequestSubscription = EventBus.on(
      ACTION_TYPES.USER_UPDATE_REQUEST, 
      () => this.setState({ loading: true }),
    );
    this.userUpdateSuccessSubscription = EventBus.on(
      ACTION_TYPES.USER_UPDATE_SUCCESS, 
      this._onUserUpdatedSuccess
    );
    this.userUpdateFailureSubscription = EventBus.on(
      ACTION_TYPES.USER_UPDATE_FAILED, 
      () => this.setState({ loading: false })
    );
  }

  componentWillUnmount() {
    this.fullKycRequestSubscription();
    this.fullKycSuccessSubscription();
    this.fullKycFailureSubscription();

    this.submitInvestorDetailsRequestSubscription();
    this.submitInvestorDetailsSuccessSubscription();
    this.submitInvestorDetailsFailureSubscription();

    this.userUpdateRequestSubscription();
    this.userUpdateSuccessSubscription();
    this.userUpdateFailureSubscription();
  }

  _onInvestorDetailsSubmitted = () => {
    this.props.requestSubmitInvestorKYC({
      occupation_code: String(this.state.occupation.value),
      annual_salary: this.state.income.text,
      pep_check: this.state.acceptPep ? 'Applicable' : 'Not applicable',
    });
  };

  _validationSchema = yup.object().shape({
    name: yup.string().required('Please enter your name.'),
    email: yup.string().email('Please enter a valid email').required('Please enter your email.'),
    gender: yup.string().required('Please select Gender'),
    marital_status: yup.string().required('Please select marital status.'),
    occupation: yup.string().required('Please enter your occupation.'),
    income: yup.string().required('Please select income.'),
    acceptPep: yup.bool().required(),
  });

  _onSubmitInvestorDetails = ({
    name,
    email,
    gender,
    marital_status,
    occupation,
    income,
    acceptPep,
  }) => {
    const { occupationList, salaries } = this.state;
    const occupationObj = occupationList.find((item) => item.id === occupation);
    const salaryObj = salaries.find((item) => item.id === income);
    const userInfo = {
      gender,
      maritalStatus: marital_status,
      occupation,
      occupationDescription: occupationObj.text,
      salary: income,
      salaryDescription: salaryObj.text,
      acceptPep,
    };

    this.props.updateKycParams({ userInfo });
    const nameParts = name.split(' ');
    
    this.props.updateUserRequest({
      email,
      first_name: nameParts[0],
      last_name: nameParts[1],
    });
  };

  _onUserUpdatedSuccess = () => {
    if (this.props.user.folio && this.props.user.folio.signzyOnboardingId) {
      this._loginKycWithSignzy();
    } else {
      const { email, firstName, lastName } = this.props.user;
      this.props.requestFullKycRegistration({
        email,
        full_name: `${firstName} ${lastName}`,
        redirect_url: `${document.location.origin}/long-kyc/contract-created`
      });
    }
  };

  _onFullKycReceive = () => {
    this._loginKycWithSignzy();
  };

  _loginKycWithSignzy = () => {
    ApiRequest.fetch({
      method: 'post',
      url: API_END_POINTS.KYC_LOGIN,
      data: {},
    })
      .then(() =>
        this.setState({ loading: false }, () => {
          this.props.onComplete(); // Proceed to pan verification
        }),
      )
      .catch(() => this.setState({ loading: false }));
  };

  render() {
    const { user } = this.props;
    const { occupationList, salaries } = this.state;

    return (
      <div className={styles['demographic-info']}>
        <Row>
          <Col xs={12}>
            <div className={styles['demo-info-title']}>Demographic Information</div>
            <div className={styles['demo-info-heading']}>
              Lets wrap up the fomarlities and start saving for your holiday!
            </div>
          </Col>
        </Row>
        <Formik
          initialValues={{
            name: `${user.firstName || ''} ${user.lastName || ''}`,
            email: user.email,
            gender: user?.folio?.gender,
            marital_status: user?.folio?.maritalStatus,
            occupation: user?.folio?.occupationCode,
            income: user?.folio?.annualIncome,
            acceptPep: false,
          }}
          validationSchema={this._validationSchema}
          onSubmit={this._onSubmitInvestorDetails}
        >
          <FormikForm>
            <Row>
              <Col lg={6} className={styles['demo-info-group-input']}>
                <TextInputThree
                  mandatory
                  label="Full name"
                  name="name"
                  inputstyles={styles['demo-info-input']}
                  labelstyles={styles['.demo-info-label']}
                  placeholder="Enter your name"
                />
              </Col>
              <Col lg={6} className={styles['demo-info-group-input']}>
                <TextInputThree
                  mandatory
                  label="Email Id"
                  name="email"
                  inputstyles={styles['demo-info-input']}
                  labelstyles={styles['.demo-info-label']}
                  placeholder="Enter your email id"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} className={styles.radioButtonsWrapper}>
                <InputRadio
                  mandatory
                  label="Gender"
                  name="gender"
                  id="gender"
                  radioButtonsContainer={styles.radioButtonsContainer}
                  radioOptions={[
                    { value: 'M', label: 'Male' },
                    { value: 'F', label: 'Female' },
                    { value: 'T', label: 'Other' },
                  ]}
                />
              </Col>
              <Col lg={6} className={styles.radioButtonsWrapper}>
                <InputRadio
                  mandatory
                  label="Are you married?"
                  name="marital_status"
                  id="marital_status"
                  radioButtonsContainer={styles.radioButtonsContainer}
                  radioOptions={[
                    { value: 'MARRIED', label: 'Yes' },
                    { value: 'UNMARRIED', label: 'No' },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} className={styles['demo-info-group-input']}>
                <InputSelectTwo mandatory label="Occupation" name="occupation" id="occupation">
                  <option value="">Select an Occupaction</option>
                  {occupationList &&
                    occupationList.length > 0 &&
                    occupationList.map((item, index) => (
                      <option value={item.id} key={`occupation-item-${index}`}>
                        {item.text}
                      </option>
                    ))}
                </InputSelectTwo>
              </Col>
              <Col lg={6} className={styles['demo-info-group-input']}>
                <InputSelectTwo
                  mandatory
                  label="Income"
                  name="income"
                  id="income"
                  // labelstyles={styles.labelContainerStyles}
                >
                  <option value="">Select your Income</option>
                  {salaries &&
                    salaries.length > 0 &&
                    salaries.map((item, index) => (
                      <option value={item.id} key={`income-item-${index}`}>
                        {item.text}
                      </option>
                    ))}
                </InputSelectTwo>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputCheckbox
                  label="I am an Indian citizen and not a PEP (Politically Exposed Person) or related to a PEP"
                  name="acceptPep"
                  id="acceptPep"
                  checkboxContainer={styles['checkbox-container']}
                  styles={styles['demo-info-checkbox']}
                  labelstyles={styles['demo-info-checkbox-label']}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className={styles['footer']}>
                  <CtaTwo
                    type="submit"
                    text="Next"
                    className={styles.ctaTwoStyles}
                    loading={this.state.loading}
                  />
                  <div className={styles['secure-partners']}>
                    <SecuredPartners />
                  </div>
                </div>
              </Col>
            </Row>
          </FormikForm>
        </Formik>
      </div>
    );
  }
}

export default withRouter(DemographicInfo);

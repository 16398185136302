import React, { useMemo } from 'react';
// import moment from 'moment';
import { Badge } from 'src/components/theme';
// import { Link } from 'react-router-dom';
// import { faEdit, faExternalLinkAlt, faCopy, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Convert } from 'src/helpers';
import { DataTable, TextOne, LinkOne } from '../../../components';
import './_cancel-requests-table.style.scss';

const CancelRequestsTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#ffffff',
      borderBottomColor: '#f3f3f3',
    },
    tableHeader: {
      backgroundColor: '#f3f3f3',
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <TextOne variant="head">Name</TextOne>,
        accessor: 'productName',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <TextOne title={original?.description}>
            <div
              style={{
                textOverflow: 'ellipsis',
                width: '300px',
                whiteSpace: 'pre',
                overflow: 'hidden',
              }}
            >
              {original?.description}
            </div>
          </TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Purchase ID</TextOne>,
        accessor: 'id',
        disableSortBy: false,
        Cell: ({ value, row }) => (
          <TextOne>
            <LinkOne to={`/admin/cancel-requests/${row?.original?.id}`}>{value}</LinkOne>
          </TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Status</TextOne>,
        accessor: 'status',
        disableSortBy: false,
        Cell: ({ value }) => {
          const statusValue = value ? value.toLowerCase() : '';
          let badgeVariant = 'booked';
          if (statusValue === 'pending_delete') {
            badgeVariant = 'expired';
          }
          if (statusValue === 'completed') {
            badgeVariant = 'completed';
          }
          return (
            <div className="badge-box">
              <Badge variant={badgeVariant} className="badge-style">
                {statusValue}
              </Badge>
            </div>
          );
        },
      },
      {
        Header: <TextOne variant="head">Booking Fee</TextOne>,
        accessor: 'amount',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
          if (original?.payments && original?.payments.length > 0) {
            return <TextOne>{Convert.toCurrencyValue(original?.payments[0]?.amount || 0)}</TextOne>;
          }
          return null;
        },
      },
    ],
    [],
  );

  return (
    <DataTable
      columns={columns}
      data={props.data || []}
      tableStyles={tableStyles}
      paginationConfig={paginationConfig}
      handlePaginationChange={props.handlePaginationChange}
    />
  );
};

export default CancelRequestsTable;

import { connect } from 'react-redux';
import {
  updateFolio,
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
} from 'src/redux/actions';

import PanVerification from './pan-verification';

function mapStateToProps(state) {
  return {
    user: state.user,
    folio: state.folio,
    taxStatuses: state.taxStatuses,
  };
}

export default connect(mapStateToProps, {
  updateFolio,
  requestTaxStatus,
  requestCheckPan,
  requestSubmitPan,
})(PanVerification);

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminProductCreate(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.ADMIN_PRODUCT_LIST,
    data,
  }).then((response) => response.data);
}

export function adminProductUpdate({ uuid, data }) {
  return ApiRequest.fetchAdmin({
    method: 'put',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `${API_END_POINTS.ADMIN_PRODUCT_LIST}/${uuid}`,
    data,
  }).then((response) => response.data);
}

export function adminProductDetail({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_PRODUCT_LIST}/${uuid}`,
  }).then((response) => response.data);
}

export function adminProductList({ page }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_PRODUCT_LIST}?page=${page}`,
  }).then((response) => response.data);
}

export function adminProductListByName({ name }) {
  const url =
    (name && `${API_END_POINTS.ADMIN_PRODUCT_LIST}?product_name=${name}`) ||
    API_END_POINTS.ADMIN_PRODUCT_LIST;

  return ApiRequest.fetchAdmin({
    method: 'get',
    url,
  }).then((response) => response.data);
}

export function createAdminProduct(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.ADMIN_PRODUCT_LIST,
    data,
  }).then((response) => response.data);
}

export function adminProductImageDelete({ id }) {
  return ApiRequest.fetchAdmin({
    method: 'delete',
    url: `${API_END_POINTS.ADMIN_ATTACHMENTS}/${id}`,
  }).then((response) => response.data);
}

export function adminProductImageUpload(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.ADMIN_ATTACHMENTS,
    data,
  }).then((response) => response.data);
}

export function adminProductLinkGenerate(data) {
  return ApiRequest.fetchAdmin({
    method: 'post',
    url: API_END_POINTS.PRODUCT_LINK,
    data,
  }).then((response) => response.data);
}

import React, { Component } from 'react';
import { Loader, VideoRecordingContainer, SecuredPartners } from 'src/components';
import { ApiRequest } from 'src/helpers';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import styles from './_videoverification.module.scss';

class VideoVerification extends Component {
  state = {    
    loading:false,
    transactionId: null,
    videoOtp: null
  }

  componentDidMount() {
    this.startVideoRequestSubscription = EventBus.on(ACTION_TYPES.START_VIDEO_VERIFICATION_REQUEST, () => this.setState({ loading: true }));
    this.startVideoRequestSuccessSubscription = EventBus.on(ACTION_TYPES.START_VIDEO_VERIFICATION_SUCCESS, this._onStartedVideoVerification);
    this.startVideoRequestFailureSubscription = EventBus.on(ACTION_TYPES.START_VIDEO_VERIFICATION_FAILED, () => this.setState({ loading: false }));

    this.uploadVideoRequestSubscription = EventBus.on(ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_REQUEST, () => this.setState({ loading: true }));
    this.uploadVideoRequestSuccessSubscription = EventBus.on(ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_SUCCESS, this._onUploadedVideoVerification);
    this.uploadVideoRequestFailureSubscription = EventBus.on(ACTION_TYPES.UPLOAD_VIDEO_VERIFICATION_FAILED, () => this.setState({ loading: false }));
    
    this.props.startVideoVerificationRequest()
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.startVideoRequestSubscription();
    this.startVideoRequestSuccessSubscription();
    this.startVideoRequestFailureSubscription();

    this.uploadVideoRequestSubscription();
    this.uploadVideoRequestSuccessSubscription();
    this.uploadVideoRequestFailureSubscription();
  }

  _onStartedVideoVerification = (res) => {
    this.setState({ loading: false, transactionId: res.transactionId, videoOtp: res.randomNumber });
  }

  _onUploadedVideoVerification = () => {
    this.setState({loading: false }, () => {
      this.props.onComplete && this.props.onComplete();
    })
  }

  _onVideoChanged = (video) => {    
    this.setState({loading: true});     

    ApiRequest.uploadToSignzy(video, 'video.mp4')
      .then(res => {    
        this.props.updateKycParams({ video: { video_url: res.data.file.directUrl }});
        this.props.uploadVideoVerificationRequest({ video_url: res.data.file.directUrl, transaction_id: this.state.transactionId })                        
      }).catch(error => {
        this.setState({ loading: false });
        console.log('UPLOAD_ERROR:', error);
      })
  }

  render() {
    return (
      <div className={styles['pan-verification']}>        
        <div className={styles.videoContentContainer}>
          <div className={styles.item}>
            <VideoRecordingContainer
              otp={this.state.videoOtp}
              onVideoChanged={this._onVideoChanged}
            />
          </div>
          <div className={styles.button}>
            <SecuredPartners styles={{
              marginTop: '1rem'
            }}/>
          </div>
          <Loader loading={this.state.loading} />   
        </div>             
      </div>
    );
  }
}

export default VideoVerification;

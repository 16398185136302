import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { 
  createFolio, 
  checkInstantWithdraw,
  folioWithdraw,
  requestSendWithdrawOtp,
  requestResendWithdrawOtp,
  requestValidateWithdrawOtp 
} from 'src/redux/api';
import { 
  createFolioSuccess, 
  createFolioFailure,
  checkInstantWithdrawalSuccess,
  checkInstantWithdrawalFailure,
  folioWithdrawSuccess,
  folioWithdrawFailure,
  folioWithdrawOTPSuccess,
  folioWithdrawOTPFailure,
  folioWithdrawResendOTPSuccess,
  folioWithdrawResendOTPFailure,
  validateFolioWithdrawSuccess,
  validateFolioWithdrawFailure
} from 'src/redux/actions';

export function* watchFolioRequests() {
  yield takeEvery(ACTION_TYPES.CREATE_FOLIO, requestCreateFolio);
  yield takeEvery(ACTION_TYPES.CHECK_INSTANT_WITHDRAWAL_REQUEST, requestCheckInstantWithdrawal);
  yield takeEvery(ACTION_TYPES.FOLIO_WITHDRAW_REQUEST, requestFolioWithdraw);
  yield takeEvery(ACTION_TYPES.FOLIO_WITHDRAW_OTP_REQUEST, requestFolioWithdrawOTP);
  yield takeEvery(ACTION_TYPES.FOLIO_WITHDRAW_RESEND_OTP_REQUEST, requestFolioWithdrawResendOTP);
  yield takeEvery(ACTION_TYPES.VALIDATE_FOLIO_WITHDRAW_REQUEST, requestValidateFolioWithdraw);
}

function* requestCreateFolio(action) {
  try {
    const folio = yield call(createFolio, action.payload);
    yield put(createFolioSuccess(folio));
  } catch (e) {
    yield put(createFolioFailure(e));
  }
}

function* requestCheckInstantWithdrawal(action) {
  try {
    const folio = yield call(checkInstantWithdraw, action.payload);
    yield put(checkInstantWithdrawalSuccess(folio));
  } catch (e) {
    yield put(checkInstantWithdrawalFailure(e));
  }
}

function* requestFolioWithdraw(action) {
  try {
    const withdraw = yield call(folioWithdraw, action.payload);
    yield put(folioWithdrawSuccess(withdraw));
  } catch (e) {
    yield put(folioWithdrawFailure(e));
  }
}

function* requestFolioWithdrawOTP(action) {
  try {
    const response = yield call(requestSendWithdrawOtp, action.payload);
    yield put(folioWithdrawOTPSuccess(response));
  } catch (e) {
    yield put(folioWithdrawOTPFailure(e));
  }
}

function* requestFolioWithdrawResendOTP(action) {
  try {
    const response = yield call(requestResendWithdrawOtp, action.payload);
    yield put(folioWithdrawResendOTPSuccess(response));
  } catch (e) {
    yield put(folioWithdrawResendOTPFailure(e));
  }
}

function* requestValidateFolioWithdraw(action) {
  try {
    const response = yield call(requestValidateWithdrawOtp, action.payload);
    yield put(validateFolioWithdrawSuccess(response));
  } catch (e) {
    yield put(validateFolioWithdrawFailure(e));
  }
}
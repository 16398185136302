import React, { Component } from 'react';
import {
  Header,
  //  CustomCardFour,
  Loader,
  LoginBanner,
} from 'src/components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import queryString from 'query-string';
import { ACTION_TYPES, IMAGES } from 'src/constants';
import EventBus from 'eventing-bus';
import { TextInputThree } from 'src/components/form-inputs';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { ButtonMain } from 'src/components/theme';
import styles from './_otploginverify.module.scss';

const timerProps = {
  isPlaying: true,
  size: 22,
  strokeWidth: 0.5,
};

export class OtpLoginVerify extends Component {
  state = {
    loading: false,
    isResending: false,
    timer: '',
  };

  _validationSchema = yup.object().shape({
    code: yup
      .string()
      .required('Please enter OTP.')
      .matches(/^\d{6}$/, {
        message: 'OTP is not valid',
      }),
  });

  componentDidMount() {
    this.requestSignInSubscription = EventBus.on(ACTION_TYPES.REQUEST_VERIFY_OTP, () =>
      this.setState({ loading: true }),
    );
    this.signInSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.VERIFY_OTP_SUCCESS,
      this._onVerifyCodeSuccess,
    );
    this.signInSubscriptionFailure = EventBus.on(ACTION_TYPES.VERIFY_OTP_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestResendOtp = EventBus.on(ACTION_TYPES.REQUEST_RESEND_OTP, () =>
      this.setState({ isResending: true }),
    );
    this.resendOtpSuccess = EventBus.on(ACTION_TYPES.RESEND_OTP_SUCCESS, () => {
      this.setState({ isResending: false });
      this.handleStartTimer();
    });
    this.resendOtpFailure = EventBus.on(ACTION_TYPES.RESEND_OTP_FAILED, () =>
      this.setState({ isResending: false }),
    );
  }

  componentWillUnmount() {
    this.requestSignInSubscription();
    this.signInSubscriptionSuccess();
    this.signInSubscriptionFailure();

    this.requestResendOtp();
    this.resendOtpSuccess();
    this.resendOtpFailure();
  }

  handleStartTimer = () => {
    const timer = setTimeout(() => {
      this.handleClearTimer();
    }, 30000);
    this.setState({ timer });
  };

  handleClearTimer = () => {
    this.setState({ timer: '' });
  };

  _onVerifyCodeSuccess = () => {
    if (this.props.location.state && this.props.location.state.redirectTo) {
      const { redirectToPath } = queryString.parse(this.props.location.state.redirectTo);
      if (redirectToPath) {
        return this.props.history.push(
          `${redirectToPath}${this.props.location.state.redirectTo.replace(
            `&redirectToPath=${redirectToPath}`,
            '',
          )}`,
        );
      }
      return this.props.history.push(this.props.location.state.redirectTo);
    }

    const { user, product } = this.props;
    const { savingGoals } = user;
    const { productOfferUuid } = product;
    const existingGoal = savingGoals.find((goal) => goal.productOffer?.uuid === productOfferUuid);

    if (productOfferUuid && existingGoal) {
      return this.props.history.push(`/dashboard/progress/${existingGoal.id}`);
    }

    if (this.props.product.productOfferUuid || this.props.product.productUuid) {
      return this.props.history.push('/checkout');
    }

    return this.props.history.push('/dashboard');
  };

  _onVerifyCode = (values) => {
    this.props.requestVerifyOTP({
      sms_verification_code: values.code,
    });
  };

  handleResendOtp = () => {
    const { requestResendOtp } = this.props;
    const { timer } = this.state;
    if (timer) return;
    requestResendOtp();
  };

  render() {
    const { isResending, timer } = this.state;
    const { productUuid, productOfferUuid } = this.props.product;
    const showPromo = this.props.location.state ? this.props.location.state.showPromo : false;

    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.customHeader}>
            <Header
              heading={
                showPromo ? (
                  <div className={styles.headingStyles}>
                    Pay with <span className={styles.highlightedText}>SavvyPay</span>
                  </div>
                ) : null
              }
              headerStyles={styles.headerStyles}
            />
          </div>
          <LoginBanner
            productOfferUuid={productOfferUuid}
            productUuid={productUuid}
            showPromo={showPromo}
          />

          {/* <div className={styles.featuresContainer}>
            {!showPromo && <div className={styles['how-works']}>How it works</div>}
            <CustomCardFour
              containerstyles={styles.cardContainerStyles}
              titleContainerStyles={styles.titleContainer}
              leftContainerStyles={styles.leftContainer}
              rightContainerStyles={styles.rightContainer}
              title="1. Book"
              text="Secure your booking with minimal deposit. Protect yourself from future price shock"
            />
            <CustomCardFour
              containerstyles={styles.cardContainerStyles}
              titleContainerStyles={styles.titleContainer}
              leftContainerStyles={styles.leftContainer}
              rightContainerStyles={styles.rightContainer}
              title="2. Save"
              text="Choose a plan, make periodic deposits & earn returns too"
            />
            <CustomCardFour
              containerstyles={styles.cardContainerStyles}
              titleContainerStyles={styles.titleContainer}
              leftContainerStyles={styles.leftContainer}
              rightContainerStyles={styles.rightContainer}
              title="3. Buy"
              text="Get the best value deals, earn cashback, rewards and more"
            />
          </div>
       */}
        </div>
        <div className={styles.loginContainer}>
          <img src={IMAGES.SAVVY_ICON} alt="logo" className={styles.logo} />
          <div className={styles.loginBox}>
            <div className={styles.loginTitle}>Verify OTP</div>
            <Formik
              initialValues={{
                code: '',
              }}
              validationSchema={this._validationSchema}
              onSubmit={(values) => {
                this._onVerifyCode(values);
              }}
            >
              <Form>
                <TextInputThree
                  labelcontainerstyles={styles.labelcontainerstyles}
                  containerstyles={styles.containerstyles}
                  fieldcontainerstyles={styles.fieldcontainerstyles}
                  labelstyles={styles.label}
                  inputstyles={styles.input}
                  label="Please enter the 6 digit OTP"
                  name="code"
                  id="code"
                  type="number"
                />
                <div className={styles['resend-otp-box']}>
                  {isResending ? (
                    <span className={styles.link}>Loading...</span>
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleResendOtp();
                      }}
                      className={styles.link}
                    >
                      Resend OTP
                    </a>
                  )}
                  {timer && (
                    <div className={`d-flex center ${timer ? styles['cursor-not-allowed'] : ''}`}>
                      <span> &nbsp;in&nbsp;</span>
                      <CountdownCircleTimer
                        isPlaying
                        duration={30}
                        {...timerProps}
                        colors={[['#eda12c', 0.33]]}
                      >
                        {({ remainingTime }) => (
                          <div className={styles['remaining-time']}>{remainingTime}</div>
                        )}
                      </CountdownCircleTimer>
                    </div>
                  )}
                </div>

                <ButtonMain type="submit" className={styles.submitBtn}>
                  SUBMIT OTP
                </ButtonMain>
              </Form>
            </Formik>
            <Loader loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default OtpLoginVerify;

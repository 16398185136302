import { connect } from 'react-redux';
import { requestGoalDetail } from 'src/redux/actions';
import GoalDetails from './goal-details';

function mapStateToProps(state){
  return{
    goalDetail: state.savingGoal
  }
}

export default connect(mapStateToProps, {
  requestGoalDetail
})(GoalDetails);

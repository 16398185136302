import React from 'react'
import cx from 'classnames'
import styles from './_info-block.module.scss'

export const InfoBlock = (props) => {
  const {
    variant="one",
    title = '',
    data = {},
    horizontal=false
  } = props

  return (
    <section className={cx(styles[variant], {
      [styles.horizontal]: horizontal
    } ,props?.className)}>
      <div>
        <div className={styles.header}>
          {title}
        </div>
        <div className={styles.content}>
          {Object.entries(data).map((item, index) => (
            <div key={index} className={styles.infoItem}>
              <span className={cx(styles.infoText, styles.left)}>{item[0]}:</span>
              <span className={cx(styles.infoText, styles.right)}>{item[1]}</span>
            </div>
            ))
          }
        </div>
      </div>
      {props?.buttonComponent && <div className={styles.button}>{props?.buttonComponent}</div>}
      
    </section>
  )
}

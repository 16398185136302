import React, { Component } from 'react';
import styles from './_h2.module.scss';

class H2 extends Component {
  render() {
    return <div className={styles['app-h2']}>{this.props.children}</div>;
  }
}

export default H2;

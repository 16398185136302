import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function requestTaxStatus() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.KYC_GET_TAX_STATUS,
  }).then((response) => response.data.taxStatuses);
}

export function requestCheckPan(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_CHECK_PAN,
    data: params,
  }).then((response) => response.data);
}

export function requestFatcha(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.FATCHA,
    data: params,
  }).then((response) => response.data);
}

export function requestSubmitPan(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_SUBMIT_PAN,
    data: params,
  }).then((response) => response.data);
}

export function requestFullKycRegistration(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_FULL,
    data: params,
  }).then((response) => response.data.folio);
}

export function requestKycLogin() {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_LOGIN,
  }).then((response) => response.data);
}

export function requestOTMBanks() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.KYC_GET_OTM_BANKS,
  }).then((response) => response.data.otmBanks);
}

export function requestBankDetails(params) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.KYC_GET_BANK_DETAILS}?payment_mode=${params['payment_mode']}&ifsc_code=${params['ifsc_code']}`,
  }).then((response) => response.data.bankDetails);
}

export function requestSendPennyDrop(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_PENNY_DROP,
    data: params,
  }).then((response) => response.data);
}

export function requestSubmitBankAccount(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_SUBMIT_INVESTOR_BANK_ACCOUNT,
    data: params,
  }).then((response) => response.data.user);
}

export function requestOccupationList() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.KYC_GET_OCCUPATION_LIST,
  }).then((response) => response.data.occupations);
}

export function requestSubmitInvestorDetails(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_SUBMIT_INVESTOR_DETAILS,
    data: params,
  }).then((response) => response.data.user);
}

export function requestExtractPanCard(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_EXTRACT_PAN_CARD,
    data: params,
  }).then((response) => response.data);
}

export function requestUploadPanCard(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_UPLOAD_PAN_CARD,
    data: params,
  }).then((response) => response.data);
}

export function requestUploadForm(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_UPLOAD_FORM,
    data: params,
  }).then((response) => response.data);
}

export function requestExtractAddressProof(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_EXTRACT_ADDRESS_PROOF,
    data: params,
  }).then((response) => response.data);
}

export function requestUploadAddressProof(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_UPLOAD_ADDRESS_PROOF,
    data: params,
  }).then((response) => response.data);
}

export function requestUploadSignature(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_UPLOAD_SIGNATURE,
    data: params,
  }).then((response) => response.data);
}

export function requestStartVideoVerification(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_START_VIDEO_VERIFICATION,
    data: params,
  }).then((response) => response.data);
}

export function requestUploadVideoVerification(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_UPLOAD_VIDEO_VERIFICATION,
    data: params,
  }).then((response) => response.data);
}

export function requestUploadPhoto(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_UPLOAD_PHOTO,
    data: params,
  }).then((response) => response.data);
}

export function requestCreateContract(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_CREATE_CONTRACT,
    data: params,
  }).then((response) => response.data);
}

export function requestLongKycVerification(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_EXECUTE_VERIFICATION,
    data: params,
  }).then((response) => response.data);
}

export function requestKycCountries() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.KYC_COUNTRIES,
  }).then((response) => response.data);
}

export function requestKycIdentificationTypes() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.KYC_IDENTIFICATION_TYPES,
  }).then((response) => response.data);
}

export function requestKycSourceOfWealth() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.KYC_SOURCES_OF_WEALTH,
  }).then((response) => response.data);
}

export function requestPurchaseBanks() {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.PURCHASE_BANKS}?payment_mode=NIIB`,
  }).then((response) => response.data);
}

export function createBankAccount(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.UPDATE_BANK_ACCOUNT,
    data: params
  }).then((response) => response.data);
}
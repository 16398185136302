import { connect } from 'react-redux';
import {
  requestAdminOffersList,
  createAdminOffer,
  requestShortenUrl,
} from 'src/redux/actions';
import GeneratePaymentLink from './GeneratePaymentLink';

function mapStateToProps(state) {
  return {
    admin: state.admin,
    adminCredentials: state.adminCredentials,
    offers: state.adminOffers.productOffers,
  };
}

export default connect(mapStateToProps, {
  requestAdminOffersList,
  createAdminOffer,
  requestShortenUrl,
})(GeneratePaymentLink);

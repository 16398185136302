import { useField } from 'formik';
import React from 'react';
import cx from 'classnames';
import { Row } from 'react-bootstrap';
import { MandatoryMark } from 'src/components';
import { TextInput } from 'src/components/theme';
import globalStyles from '../_styles.module.scss';
import styles from './_textinputfive.module.scss';

const TextInputFive = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Row className={props.containerstyles}>
        <div className={styles['text-input']}>
          {label && label != '' && (
            <label
              htmlFor={props.id || props.name}
              className={cx(styles['text-label'], props?.labelstyles)}
            >
              {label}
              {props.mandatory && <MandatoryMark />}
            </label>
          )}
          <TextInput
            size="sm"
            {...field}
            {...props}
            className={cx(styles['text-input-box'], props?.inputstyles)}
          />
        </div>
        {meta.touched && meta.error ? <div className={globalStyles.error}>{meta.error}</div> : null}
      </Row>
    </>
  );
};

export default TextInputFive;

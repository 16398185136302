import React, { Component } from 'react';
// import GoalProgress from './goal-progress';
// import GoalDetails from './goal-details';
import styles from './_progress.module.scss';
import GoalProgressList from './goal-progress-list';

class Progress extends Component {
  render() {
    return (
      <div className={styles['progress']}>
        <GoalProgressList/>
        {/* <GoalProgress isActive={this.props.isActive} />
        <GoalDetails /> */}
      </div>
    );
  }
}

export default Progress;

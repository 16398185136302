import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ListItem from './list-item';
import styles from './_sidenavigation.module.scss';

const SideNavigation = (props) => {
  const admin = useSelector((state) => state.admin);
  const [isLogoutSidebar, setIsLogoutSidebar] = useState(false);

  const menuItems = [
    {
      id: 1,
      name: 'Payment Links',
      toggleKey: '/admin',
      nav: '/admin',
    },
    {
      id: 2,
      name: 'Analytics',
      toggleKey: '/admin/analytics',
      values: [
        {
          id: 21,
          name: 'Users',
          nav: '/admin/analytics/users',
        },
        {
          id: 22,
          name: 'Transactions',
          nav: '/admin/analytics/transactions',
        },
      ],
    },
    {
      id: 3,
      name: 'Marketing',
      toggleKey: '/admin/marketing',
      values: [
        {
          id: 31,
          name: 'Products',
          nav: '/admin/marketing/products',
        },
        {
          id: 32,
          name: 'Email Templates',
          nav: '/admin/marketing/email-templates',
        },
        {
          id: 33,
          name: 'Social Media Templates',
          nav: '/admin/marketing/social-media-templates',
        },
      ],
    },
    {
      id: 4,
      name: 'Cancel Requests',
      toggleKey: '/admin/cancel-requests',
      nav: '/admin/cancel-requests',
    },
  ];

  const menuItemsTwo = [
    {
      id: 1,
      name: 'Edit Business Profile',
      toggleKey: '/admin/profile',
      nav: '/admin/profile',
    },
    {
      id: 2,
      name: 'Add a Sales Agent',
      toggleKey: '/admin/agent',
      nav: '/admin/agent',
    },
    {
      id: 3,
      name: 'Log Out',
      toggleKey: '/admin/logout',
      nav: '/admin/logout',
    },
  ];

  const toggleLogoutSideBar = () => {
    setIsLogoutSidebar(!isLogoutSidebar);
  };

  return (
    <>
      <div className={styles.titleContainer}>
        <button
          className={`${styles.menuCollapseButton}`}
          onClick={() => props.handleSidebarToggle()}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span className={styles.title} title={admin.partner?.name}>
          {admin.partner?.name}{' '}
        </span>
      </div>
      <div className={styles['menu-box']}>
        <div
          className={cx(styles['menu-box-inner'], {
            [styles['menu-box-inner-inactive']]: isLogoutSidebar,
          })}
        >
          <ul className={styles.menu}>
            {menuItems &&
              menuItems.length > 0 &&
              menuItems.map((i, index) => <ListItem item={i} key={index} keyIndex={index} />)}
          </ul>
        </div>
        <div
          className={cx(styles['menu-box-outer'], {
            [styles['menu-box-outer-active']]: isLogoutSidebar,
          })}
        >
          <ul className={styles.menu}>
            {menuItemsTwo &&
              menuItemsTwo.length > 0 &&
              menuItemsTwo.map((i, index) => (
                <ListItem item={i} key={index} keyIndex={index} isBorder />
              ))}
          </ul>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div onClick={toggleLogoutSideBar} className={styles['savvy-icon']}>
          <img className={styles.logo} src="/img/savvy-pay.svg" alt="Savvy Pay" />
        </div>
      </div>
    </>
  );
};

export default SideNavigation;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import SipSuccess from '../sip-success';
import SipFailure from '../sip-failure';

class SipRedirectNew extends Component {
  render() {
    const { status } = queryString.parse(this.props.location.search);
    return <div>{status === 'success' ? <SipSuccess /> : <SipFailure />}</div>;
  }
}

export default withRouter(SipRedirectNew);

import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Convert } from 'src/helpers';
import styles from './_transactions.module.scss';

class Transactions extends Component {
  /**
   * add a label to the transactions array
   * example: 'Deposited' for savingsDeposit data, 'withdrwn' for withdrawals data
   * @param {array} data
   * @param {string} labelName
   * @returns {array}
   */
  _addTransactionLabel = (data, labelName) => {
    const labeledData = data.map((val) => {
      val['label'] = labelName;
      return val;
    });

    return labeledData;
  };

  /**
   * Raw data in the form of object of arrays is received which needs to be merged
   * and sorted, filtered according to date
   *
   * @param {object} transactionsData
   * @returns {array}
   */
  _organizeTransactionsData = (transactionsData) => {
    let { deposits = [], withdrawals = [], payments = [] } = transactionsData;    
    deposits = this._addTransactionLabel(deposits, 'Deposits');
    withdrawals = this._addTransactionLabel(withdrawals, 'Withdrawal');
    payments = this._addTransactionLabel(payments, 'Booking amount');
    payments = payments.map(payment => ({
      ...payment,
      createdAt: moment(payment.createdAt, 'DD/MM/YYYY').format('YYYY/MM/DD')
    }))
    
    let organizedData = [...deposits, ...withdrawals, ...payments];            
    return organizedData.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
    );

    // console.log(organizedData);
  };

  _getPayments = (savingGoals) => {
    let paymentsData = [];
    if (savingGoals && savingGoals.length > 0) {
      savingGoals.forEach((payment) => {
        if (payment?.payments) {
          paymentsData = [...paymentsData, ...payment?.payments];
        }
      });
    }
    if (paymentsData && paymentsData.length > 0) {
      paymentsData = paymentsData.map((data) => {
        const createdData = data?.createdAt && data?.createdAt.split('/');
        const formattedDate =
          (createdData &&
            createdData.length > 2 &&
            `${createdData[2]}/${createdData[1]}/${createdData[0]} 00:00:00`) ||
          '';
        return {
          ...data,
          createdAt: formattedDate,
        };
      });
    }

    return paymentsData;
  };

  render() {
    const { savingGoal } = this.props;    
    const { payments, savingGoalContributions, savingGoalWithdrawals } = savingGoal;
    const transactionsData = this._organizeTransactionsData({ 
      payments,
      deposits: savingGoalContributions,
      withdrawals: savingGoalWithdrawals,
    });    
    return (
      <div className={cx(styles['transactions'], { [styles['overview']]: this.props.overview })}>
        <div className={cx({ [styles['inner-content']]: !this.props.overview })}>
          <div className={styles['header']}>
            <div className={styles['description']}>Transactions</div>            
          </div>          
          <div className={styles['header-row']}>
            <div className={styles['title']}>Type</div>            
            <div className={styles['amount']}>Amount</div>            
            <div className={styles['status']}>Status</div>
            <div className={styles['date']}>Date</div>
          </div>
          <div className={styles['body']}>
            {transactionsData &&
              transactionsData.length > 0 &&
              transactionsData.map((item, index) => (
                <div className={styles['row']} key={`transation-item-${index}`}>
                  <div className={styles['description']}>
                    <div className={styles['title']}>{item.label}</div>
                  </div>                  
                  <div className={styles['amount']}>
                    {Convert.toCurrencyValue(item.amount)}
                    {/* <div
                      className={`${styles['type']} ${
                        item.label == 'Withdrawn' ? styles['red'] : styles['green']
                      }`}
                    >
                      {item.label}
                    </div> */}
                  </div>
                  <div className={styles['status']}>
                    <span className={
                      item.status === 'completed' ? styles['completed'] : 
                      item.status === 'in_progress' ? styles['in_progress'] : 
                      item.status === 'pending_investment' ? styles['pending_investment'] : 
                      item.status === 'error' ? styles['error'] : {}
                    }>{item.status}</span>
                  </div>
                  <div className={styles['date']}>
                    {moment(item.createdAt).format('MMM Do YYYY')}
                  </div>
                </div>
              ))}
            {transactionsData && transactionsData.length === 0 ? (
              <div className={styles['row']}>- No transactions found -</div>
            ) : null}            
          </div>
        </div>
      </div>
    );
  }
}

export default Transactions;

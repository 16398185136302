import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Loader } from 'src/components';
import { withRouter } from 'react-router-dom';
import { ACTION_TYPES } from 'src/constants';
import moment from 'moment';
import EventBus from 'eventing-bus';
import { Container, Section, InputText } from '../../components';
import ProductsTable from './products-table';
import styles from './_products.module.scss';

class Products extends Component {
  state = {
    loading: false,
    pageNumber: 1,
    filteredData: [],
    searchAmount: '',
    totalCount: 0,
    searchDate: '',
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminProductList({
          page: pageNumber,
        }),
    );
  };

  componentDidMount() {
    this.requestAdminProductList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminProductListSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS, () => {
      const { products, productsTotal } = this.props.adminProducts;
      this.setState({ loading: false });
      this.setState({ filteredData: products || [], totalCount: productsTotal });
    });
    this.adminProductListFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminProductList({
      page: this.state.pageNumber,
    });
  }

  componentWillUnmount() {
    this.requestAdminProductList();
    this.adminProductListSuccess();
    this.adminProductListFailure();
  }

  _onActionClick = () => {
    // console.log('onButtonClick')
    this.props.history.push('/admin/marketing/products/new');
  };

  handleChange = (e) => {
    const { products } = this.props.adminProducts;
    const { searchAmount, searchDate } = this.state;
    let filteredData = products || [];

    if (e.target.name === 'searchAmount' || (searchAmount && e.target.name !== 'searchAmount')) {
      let regex = new RegExp(
        searchAmount && e.target.name !== 'searchAmount' ? searchAmount : e.target.value,
        'i',
      );
      filteredData = filteredData.filter((item) => regex.test(item?.amount));
    }

    if (e.target.name === 'searchDate' || (searchDate && e.target.name !== 'searchDate')) {
      const dateValue = searchDate && e.target.name !== 'searchDate' ? searchDate : e.target.value;
      if (dateValue) {
        filteredData = filteredData.filter(
          (item) => moment(item?.createdAt, 'DDMMYYYY').format('YYYY-MM-DD') === dateValue,
        );
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      filteredData,
      totalCount: filteredData.length || 0,
    });
  };

  render() {
    const { loading, filteredData, totalCount, searchAmount, searchDate } = this.state;

    return (
      <div className={styles['payment-links']}>
        <Container
          title="Product Catalogue"
          buttonText="create a product"
          onButtonClick={this._onActionClick}
        >
          <Section title="Filter By">
            <Row>
              <Col md={3}>
                <InputText
                  label="Amount"
                  value={searchAmount}
                  name="searchAmount"
                  onChange={this.handleChange}
                  type="number"
                />
              </Col>
              <Col md={3}>
                <InputText
                  label="Date"
                  type="date"
                  value={searchDate}
                  name="searchDate"
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </Section>
          <ProductsTable
            data={filteredData}
            totalItems={totalCount}
            handlePaginationChange={this._handlePaginationChange}
          />
        </Container>
        <Loader loading={loading} />
      </div>
    );
  }
}

export default withRouter(Products);

import React, { Component } from 'react'
import { faVideo, faStop, faPlay, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import styles from './_videorecordingcontainer.module.scss'

class Counter extends Component {
  state = {
    counter: 3
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({ counter: this.state.counter - 1 });

      if (this.state.counter < 0) {
        this.props.onComplete && this.props.onComplete();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <div className={styles.counterContainer}>
        <span className={styles.counter}>{this.state.counter > 0 ? this.state.counter : 'Go!'}</span>
      </div>
    )
  }
};

class VideoRecordingContainer extends Component {
  constructor(props) {
    super(props);
     
    this.state = {
      recording: false,   
      recordingCompleted: false,   
      videoEnded: true,
      video: null,
      blob: null,
      counterCompleted: false      
    };

    this._stream = null;
    this._player = null;
    this._recorder = null;    
    this._chunks = [];
  }  

  _startCamera = () => {   
    this.setState({ counterCompleted: false });

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          sampleSize: 8,
          echoCancellation: true
        },
        video: { 
          facingMode: 'user',
          width: 720, 
          height: 480 
        }
      })
      .then((stream) => {        
        this._stream = stream;                      
        this._player.srcObject = stream; 
        this._player.muted = true;    
        this._recorder = new MediaRecorder(stream)
        
        this._recorder.ondataavailable = ({ data }) => {          
          this._chunks = [];
          this._chunks.push(data);          
        };

        this._recorder.onstop = () => {            
          let blob = new Blob(this._chunks, { 'type' : 'video/mp4' });
          let video = window.URL.createObjectURL(blob);
          this.setState({ video, blob, recordingCompleted: true });
          this._player.srcObject = null;
          this._player.muted = false; 
          this._player.src = this.state.video;   
          this._player.pause(); 
          this._stopCamera();                     
        };

        this._player.onended = () => {
          this.setState({ videoEnded: true });
        };

        this._recorder.start(); 
      })
      .catch(console.error);
  }

  _stopCamera = () => {    
    this._stream.getTracks().forEach((track) => {
      if (track.readyState == 'live') {
        track.stop();
      }
    });
  }

  _onToggleRecord = () => {   
    if (this.state.recording) {      
      this.setState({ recording: false }, () => {
        this._recorder && this._recorder.stop();            
      });
    } else {
      this.setState({ recording: true, recordingCompleted: false }, () => {        
        this._startCamera();
      });
    }
  }

  _onTogglePlay = () => {  
    if (this.state.videoEnded) {
      this.setState({ videoEnded: false }, () => {
        this._player.play();
      }); 
    } else {
      this.setState({ videoEnded: true }, () => {
        this._player.pause();
        this._player.currentTime = 0;
      }); 
    }   
  }

  _onCounterComplete = () => {
    this.setState({ counterCompleted: true });
  }

  _onUseVideo = () => {
    this.props.onVideoChanged && this.props.onVideoChanged(this.state.blob);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.otp ? <header>Please record yourself saying {this.props.otp}</header> : null }
        <div className={styles.videoContainer}>
          {
            !this.state.recording && !this.state.recordingCompleted
            ?
            <div className={styles.placeholder}>
              <FontAwesomeIcon icon={faVideo} className={styles.placeholderIcon} />
            </div>
            :
            <>
            {/* eslint-disable-next-line */}
            <video ref={ref => this._player = ref} autoPlay className={styles.video} />          
            { !this.state.counterCompleted ? <Counter onComplete={this._onCounterComplete} /> : null }
            </>
          }                 
        </div>    
        <div className={styles.buttonContainer}>
          <button 
            className={cx(styles.button, styles.danger)}
            onClick={this._onToggleRecord}
          >
            <FontAwesomeIcon icon={this.state.recording ? faStop : faVideo} className={styles.icon} />
            {
              this.state.recording 
              ? 
              'Stop' 
              : 
              this.state.recordingCompleted ? 'Retake' : 'Record'
            }
          </button>
          {
            this.state.recordingCompleted
            ?
            <>
               <button 
                className={styles.button}
                onClick={this._onTogglePlay}
              >
                <FontAwesomeIcon icon={this.state.videoEnded ? faPlay : faStop} className={styles.icon} />
                {this.state.videoEnded ? 'Play' : 'Stop'}
              </button>
              <button 
                className={cx(styles.button, styles.primary)}
                onClick={this._onUseVideo}
              >
                <FontAwesomeIcon icon={faThumbsUp} className={styles.icon} />
                Use Video
              </button>
            </>
            :
            null
          }
        </div>        
      </div>
    )
  }
}

export default VideoRecordingContainer;
import { connect } from 'react-redux';
import { requestCreateMandate, updateSavingPlan, requestGoalDetail  } from 'src/redux/actions';
import AutoPay from './auto-pay';

function mapStateToProps(state) {
  return {
    savingPlan: state.savingPlan,
  };
}

export default connect(mapStateToProps, { requestCreateMandate, updateSavingPlan, requestGoalDetail  })(AutoPay);

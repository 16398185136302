import React, { useMemo } from 'react';
import moment from 'moment';
// import { Link } from 'react-router-dom';
// import { faEdit, faExternalLinkAlt, faCopy, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'src/components/theme';
import { Convert } from 'src/helpers';
import { DataTable, TextOne, LinkOne } from '../../../components';
import './_transactions-table.style.scss';

const TransactionsTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#ffffff',
      borderBottomColor: '#f3f3f3',
    },
    tableHeader: {
      backgroundColor: '#f3f3f3',
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <TextOne variant="head">ID</TextOne>,
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value }) => <TextOne>{value}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Link Name</TextOne>,
        accessor: 'info',
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <TextOne>
            <LinkOne to={`/admin/paymentlink/${original?.productOfferUuid}`}>{value}</LinkOne>
          </TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Amount</TextOne>,
        accessor: 'amount',
        disableSortBy: false,
        Cell: ({ value }) => <TextOne>{Convert.toCurrencyValue(value)}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Date</TextOne>,
        accessor: 'createdAt',
        disableSortBy: false,
        Cell: ({ value }) => <TextOne>{moment(value, 'DDMMYYYY').format('Do MMMM, YYYY')}</TextOne>,
      },
      {
        Header: <TextOne variant="head">User</TextOne>,
        accessor: 'userFirstName',
        disableSortBy: true,
        Cell: ({ value }) => <TextOne>{value}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Type</TextOne>,
        accessor: 'label',
        disableSortBy: true,
        Cell: ({ value }) => <TextOne>{value}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Status</TextOne>,
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ value }) => (
          <div className="badge-box">
            <>
              {value === 'completed' ? (
                <Badge variant={value} className="badge-style">
                  {value}
                </Badge>
              ) : value === 'failed' ? (
                <Badge variant="expired" className="badge-style">
                  {value}
                </Badge>
              ) : value === 'in_progress' || value === 'in progress' ? (
                <Badge variant="booked" className="badge-style">
                  {value}
                </Badge>
              ) : value === 'success' ? (
                <Badge variant="completed" className="badge-style">
                  {value}
                </Badge>
              ) : (
                <Badge variant={value} className="badge-style">
                  {value}
                </Badge>
              )}
            </>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <DataTable
      columns={columns}
      data={props.data || []}
      tableStyles={tableStyles}
      paginationConfig={paginationConfig}
      handlePaginationChange={props.handlePaginationChange}
    />
  );
};

export default TransactionsTable;

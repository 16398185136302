import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminCancelRequestsReducer(state = INITIAL_STATE.cancelRequests, action) {
  switch (action.type) {
    case ACTION_TYPES.CANCEL_REQUESTS_LIST_SUCCESS:
      return {
        ...state,
        cancelRequestsList: action.payload,
      };

    case ACTION_TYPES.CANCEL_REQUESTS_DETAIL_SUCCESS: {
      return {
        ...state,
        cancelRequestsDetail: action.payload,
      };
    }
    default:
      return state;
  }
}

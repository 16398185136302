import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import * as yup from 'yup';
import ButtonOne from 'src/components/buttons/button-one';
import CustomCardOne from 'src/components/cards/custom-card-one';
import InputSelectOne from 'src/components/form-inputs/input-select-one';
import TextInputOne from 'src/components/form-inputs/textinput-one';
import BorderedHeader from 'src/components/headings/bordered-header';
import styles from './_generategoal.module.scss';

class GenerateGoal extends Component {
  _validationSchema = yup.object().shape({
    offerLink: yup.string().required('Please provide an offer link'),
    nameOfUser: yup.string().required('Please provide name of the user.'),
    sendLinkEmail: yup.string().email().required(),
    userPhoneNumber: yup.string().required(),
    agentId: yup.string().required('Please provide an agent id'),
  });

  render() {
    return (
      <Container className={styles.container}>
        <h1 className={styles.pageHeading}>Generate New Goal</h1>
        <Formik
          initialValues={{
            offerLink: '',
            nameOfUser: '',
            sendLinkEmail: '',
            userPhoneNumber: '',
            agentId: '',
          }}
          validationSchema={this._validationSchema}
        >
          <Form>
            <Card className={styles.card}>
              <Card.Header className={styles.cardHeader}>
                <span className={styles.cardHeading}>Payment Details</span>
              </Card.Header>
              <Card.Body className={styles.cardBody}>
                <Row className={styles.cardSection} style={{ padding: '0 1rem' }}>
                  <Col>
                    <InputSelectOne
                      label="Select the offer you would like to generate a goal for"
                      name="offerLink"
                    >
                      <option>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </InputSelectOne>
                  </Col>
                </Row>
                <div className={styles.cardSection}>
                  <BorderedHeader
                    heading="Offer Details"
                    fontSize="1rem"
                    borderColor="#a4a4a4"
                    styles={{
                      fontWeight: 600,
                      paddingLeft: '1.5rem',
                    }}
                  />
                  <CustomCardOne imgSrc="https://via.placeholder.com/320x200" price={200000}>
                    6 nights, 7 days.
                    <br />
                    7 guided safaris
                    <br />
                    Breakfast Included
                    <br />
                    Transport from airport available on request
                    <br />
                  </CustomCardOne>
                </div>
              </Card.Body>
            </Card>
            <Card className={styles.card}>
              <Card.Header className={styles.cardHeader}>
                <span className={styles.cardHeading}>User Details</span>
              </Card.Header>
              <Card.Body>
                <Row className={styles.cardRow}>
                  <Col md={6} className={styles.cardCol}>
                    <TextInputOne label="Name of User" name="nameOfUser" type="text" />
                  </Col>
                  <Col md={6}>
                    <TextInputOne
                      label="Email ID to send link to"
                      name="sendLinkEmail"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className={styles.cardCol}>
                    <TextInputOne label="Use Phone Number" name="userPhoneNumber" type="text" />
                  </Col>
                  <Col md={6} className={styles.cardCol}>
                    <InputSelectOne label="Agent ID" name="agentId">
                      <option>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </InputSelectOne>
                  </Col>
                </Row>
                <ButtonOne
                  parentstyles={{
                    marginTop: '2rem',
                  }}
                  text="Generate New Purchase"
                  type="submit"
                />
              </Card.Body>
            </Card>
          </Form>
        </Formik>
      </Container>
    );
  }
}

export default GenerateGoal;

import React from 'react'
import { InputFileThree } from 'src/components/form-inputs';
import cx from 'classnames';
import styles from './_review-signature.module.scss';

export default function ReviewSignature(props) {
  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <span className={styles.heading}>
          {props?.heading}
        </span>
      </div>
      <div className={styles.inputContainer}>
        <InputFileThree
          name="signature"
          id="signature"
          ctaTitle="Re-upload"
          src={props.signature?.image_url}
          onClick={() => props.onClick(3)}
        />
      </div>                 
    </div>
  )
}

import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './another-account.module.scss';

class AnotherAccount extends Component {
  _onChangeAccountNumber = (e) => {
    this.props.onAccountNumberChange(e.target.value);
  };

  _onChangeIFSCCode = (e) => {
    const ifscCode = (e.target.value || '').toUpperCase();
    this.props.onIFSCCodeChange(ifscCode);
  };

  _onFetchOTM = () => {
    this.props.onFetchOTM && this.props.onFetchOTM();
  };

  render() {
    return (
      <div className={styles['another-account-overlay']}>
        <div className={styles['another-account']}>
          <div className={cx(styles['header'], styles['mobile'])}>
            <div className={styles['inner-header']}>
              <div className={styles['sub-inner-header']}>
                <div className={styles['title']}>Complete your KYC to start saving</div>
                {/* <div className={styles['price']}>Rs. 10,000</div>
                <div className={styles['note']}>Total amount to be saved</div>
                <div className={styles['amount']}>
                  <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
                </div> */}
              </div>
            </div>
          </div>
          <div className={cx(styles['progress-bar'], styles['mobile'])}>
            <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
          </div>

          <div className={styles['pay-content']}>
            <div className={styles['title']}>Verify your KYC Start Saving</div>
            <div className={styles['line']} />
            <div className={cx(styles['sub-title'], styles['title-heading'])}>
              <div>LINK ANOTHER BANK ACCOUNT</div>
              <div>
                <FontAwesomeIcon icon={faAngleLeft} onClick={this.props.previousAccount} />{' '}
                &nbsp;&nbsp;
              </div>
            </div>
            <div className={styles['sub-title-light']}>
              Please enter details of the account you would like to link
            </div>
            <div className={cx(styles['mobile-space'], styles['mobile'])}>
              <div className={cx(styles['line-mob'])} />
            </div>

            <div className={cx('pb-3 pt-2', styles['form-box'], styles['mobile-space'])}>
              <div>
                <label>Enter your bank account number</label>
              </div>
              <div>
                <InputMask
                  className={styles['input-box']}
                  placeholder="099XXXXXXXXX90"
                  // value={this.props.accountNumber}
                  onChange={this._onChangeAccountNumber}
                  mask="99999999999999999999"
                  maskChar={null}
                  disabled={this.props.disabled}
                />
              </div>
            </div>

            <div className={cx(styles['form-box'], styles['mobile-space'])}>
              <div>
                <label>Enter IFSC Code</label>
              </div>
              <div>
                <InputMask
                  className={`${styles['input-box']} ${styles['ifsc-input-box']}`}
                  placeholder="HDFC0000001"
                  // value={this.props.ifscCode}
                  onChange={this._onChangeIFSCCode}
                  mask="aaaa9999999"
                  maskChar={null}
                  disabled={this.props.disabled}
                />
              </div>
            </div>
            <div className={styles['link']} onClick={this._onFetchOTM}>
              <div className={styles['link-desc']}>Try getting PAN-linked bank account</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnotherAccount;

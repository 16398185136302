import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
// import moment from 'moment';
// import { faEdit, faExternalLinkAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { notify } from 'react-notify-toast';
// import { Badge } from 'src/components/theme';
import { Convert } from 'src/helpers';
import DataTable from '../../data-table';
import './_payment-links-table.style.scss';

const PaymentLinksRequestTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#f3f3f3',
      borderBottom: '12px solid white',
      borderBottomColor: '#fff',
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [    
      {
        Header: 'Purchase ID',
        accessor: 'goal_id',
        disableSortBy: false,
        Cell: ({ row }) => (
          <span>
            <Link to={`/admin/goal/${row?.original?.savingGoal?.id}`}>
              {row?.original?.savingGoal?.id}
            </Link>
          </span>
        ),
      },           
      {
        Header: 'Package Name',
        accessor: 'packageName',
        disableSortBy: true,
        Cell: ({ row }) => <span>
          <Link to={`/admin/product/offer/${row?.original?.savingGoal?.productOffer?.uuid}`}>{row?.original?.savingGoal?.productOffer?.productOfferName || 'N/A'}</Link>
        </span>,
      },      
      {
        Header: 'Purchase Amount',
        accessor: 'user_id',
        disableSortBy: false,
        Cell: ({ row }) => <span>{Convert.currency(row?.original?.savingGoal?.goalAbsoluteAmount)}</span>,
      },
      {
        Header: 'Requested Amount',
        accessor: 'requestedAmount',
        disableSortBy: false,
        Cell: ({ value }) => <span>{Convert.currency(value)}</span>,
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   disableSortBy: false,
      //   Cell: () => {
      //     const isActive = true;

      //     return (
      //       <div className="badge-box">
      //         {isActive ? (
      //           <Badge variant="success">Resolved</Badge>
      //         ) : (
      //           <Badge variant="error">Inactive</Badge>
      //         )}
      //       </div>
      //     );
      //   },
      // },      
      {
        Header: 'Actions',
        accessor: 'uuid',
        disableSortBy: false,
        Cell: ({ value }) => (
          <span>
            <Link to={`collect-requests/${value}`}>
              View
            </Link>
          </span>
        ),
      },
    ],
    [],
  );
  return (
    <div className="payment-links-table">
      <DataTable
        columns={columns}
        data={props.data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={props.handlePaginationChange}
      />
      <input id="input-link" className="link-input" />
    </div>
  );
};

export default PaymentLinksRequestTable;

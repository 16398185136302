import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  adminCancelRequestListSuccess,
  adminCancelRequestListFailed,
  adminCancelRequestDetailSuccess,
  adminCancelRequestDetailFailed,
  adminConfirmCancelRequestSuccess,
  adminConfirmCancelRequestFailed,
} from 'src/redux/actions';
import { getCancelRequestList, getCancelRequestDetail } from '../api/admin-cancel.api';

export function* watchAdminCancelRequests() {
  yield takeEvery(ACTION_TYPES.CANCEL_REQUESTS_LIST_REQUEST, requestAdminCancelRequestList);
  yield takeEvery(ACTION_TYPES.CANCEL_REQUESTS_DETAIL_REQUEST, requestAdminCancelRequestDetail);
  yield takeEvery(ACTION_TYPES.CONFIRM_CANCEL_REQUESTS_REQUEST, requestAdminConfirmCancelRequest);
}

function* requestAdminCancelRequestList() {
  try {
    const cancelRequests = yield call(getCancelRequestList);
    yield put(adminCancelRequestListSuccess(cancelRequests));
  } catch (e) {
    yield put(adminCancelRequestListFailed(e));
  }
}

function* requestAdminCancelRequestDetail(action) {
  try {
    const cancelRequestsDetail = yield call(getCancelRequestDetail, action.payload);
    yield put(adminCancelRequestDetailSuccess(cancelRequestsDetail?.savingGoal));
  } catch (e) {
    yield put(adminCancelRequestDetailFailed(e));
  }
}

function* requestAdminConfirmCancelRequest(action) {
  try {
    const confirmCancelRequest = yield call(getCancelRequestDetail, action.payload);
    yield put(adminConfirmCancelRequestSuccess(confirmCancelRequest));
  } catch (e) {
    yield put(adminConfirmCancelRequestFailed(e));
  }
}

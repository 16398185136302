import { takeEvery, put, call } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { createMandate } from 'src/redux/api';
import { createMandateSuccess, createMandateFailure } from 'src/redux/actions';

export function* watchMandateRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_MANDATE, requestCreateMandate);
}

function* requestCreateMandate(action) {
  try {
    const mandate = yield call(createMandate, action.payload);
    yield put(createMandateSuccess(mandate));
  } catch (e) {
    yield put(createMandateFailure(e));
  }
}

import { takeEvery, put, call } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { productDetail, productOffersDetail, productOffersDelete } from 'src/redux/api';
import {
  productDetailSuccess,
  productDetailFailure,
  productOffersSuccess,
  productOffersFailure,
  productOffersDeleteSuccess,
  productOffersDeleteFailure,
} from 'src/redux/actions';

export function* watchProductRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_PRODUCT_DETAIL, requestProductDetail);
  yield takeEvery(ACTION_TYPES.REQUEST_PRODUCT_OFFERS, requestProductOffers);
  yield takeEvery(ACTION_TYPES.REQUEST_PRODUCT_OFFERS_DELETE, requestProductOffersDelete);
}

function* requestProductDetail(action) {
  try {
    const product = yield call(productDetail, action.payload);
    yield put(productDetailSuccess(product));
  } catch (e) {
    yield put(productDetailFailure(e));
  }
}

function* requestProductOffers(action) {
  try {
    const offers = yield call(productOffersDetail, action.payload);    
    yield put(productOffersSuccess(offers));
  } catch (e) {
    yield put(productOffersFailure(e));
  }
}

function* requestProductOffersDelete(action) {
  try {
    const offers = yield call(productOffersDelete, action.payload);
    yield put(productOffersDeleteSuccess(offers));
  } catch (e) {
    yield put(productOffersDeleteFailure(e));
  }
}

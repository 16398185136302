import React, { Component } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link, withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { Screen, Loader } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import styles from './_logout.module.scss';

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logoutSuccess: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.userLogoutSuccess = EventBus.on(ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED, () =>
      this.setState({ loading: false, logoutSuccess: true }),
    );
    this.userLogoutFailure = EventBus.on(ACTION_TYPES.REQUEST_LOGOUT_FAILED, () =>
      this.setState({ loading: false }),
    );

    setTimeout(() => {
      this.props.requestLogout();
    }, 1000);
  }

  componentWillUnmount() {    
    this.userLogoutSuccess();
    this.userLogoutFailure();
  }

  render() {
    return (
      <Screen>
        <div className={styles['logout-container']}>
          <Loader loading={this.state.loading} title="Logging you out, please wait.." />
          {this.state.logoutSuccess ? (
            <div>
              <Player
                autoplay
                keepLastFrame
                src="https://assets7.lottiefiles.com/packages/lf20_te6kna0d.json"
                style={{ height: 150, width: 150 }}
              />
              <div className={styles['description']}>
                <h1>Logout Success!</h1>
                <p>Your have successfully logged out.</p>
                <p><Link to="/sign-in">Click here</Link> to login again</p>
              </div>
            </div>
          ) : null}
        </div>
      </Screen>
    );
  }
}

export default withRouter(Logout);

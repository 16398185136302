import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  adminAgentCreateSuccess,
  adminAgentCreateFailed,
} from 'src/redux/actions';
import { adminAgentCreate } from '../api/admin-agents.api';

export function* watchAdminAgentsRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_AGENT_CREATE, requestAdminAgentCreate);
}

function* requestAdminAgentCreate(action) {
  try {
    const agent = yield call(adminAgentCreate, action.payload);
    yield put(adminAgentCreateSuccess(agent));
  } catch (e) {
    yield put(adminAgentCreateFailed(e));
  }
}

import { ACTION_TYPES } from 'src/constants';

export function requestAdminUserDetail(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_USER_DETAIL,
    payload,
  };
}

export function adminUserDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_USER_DETAIL_SUCCESS,
    payload,
  };
}

export function adminUserDetailFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_USER_DETAIL_FAILED,
    payload,
  };
}

export function requestAdminUsersList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_USERS_LIST,
    payload,
  };
}

export function adminUsersListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_USERS_LIST_SUCCESS,
    payload,
  };
}

export function adminUsersListFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_USERS_LIST_FAILED,
    payload,
  };
}

export function requestAdminUserPurchases(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_USER_PURCHASES,
    payload,
  };
}
export function adminUserPurchasesSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_USER_PURCHASES_SUCCESS,
    payload,
  };
}
export function adminUserPurchasesFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_USER_PURCHASES_FAILED,
    payload,
  };
}

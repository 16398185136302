import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import EventBus from 'eventing-bus';
import _ from 'lodash';
import { Loader } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import styles from './_pan-verification.module.scss';
import './_modal.scss';

const TAX_STATUS_MAP = { P: 'Individual', H: 'Hindu Undivided Family' };

class PanVerification extends Component {
  state = {
    panNumber: this.props.folio.panNumber || '',
    taxStatusCode: this.props.folio.taxStatusCode || '',
    loading: false,
    isPanMessageModal: false,
    panData: {},
  };

  componentDidMount() {
    this.taxStatusRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_TAX_STATUS, () =>
      this.setState({ loading: true }),
    );
    this.taxStatusSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_TAX_STATUS_SUCCEEDED,
      this._onFetchedTaxStatuses,
    );
    this.taxStatusFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_TAX_STATUS_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.checkPanRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_CHECK_PAN, () =>
      this.setState({ loading: true }),
    );
    this.checkPanSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CHECK_PAN_SUCCEEDED,
      this._onPanChecked,
    );
    this.checkPanFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_CHECK_PAN_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.submitPanRequestSubscription = EventBus.on(ACTION_TYPES.REQUEST_SUBMIT_PAN, () =>
      this.setState({ loading: true }),
    );
    this.submitPanSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_SUBMIT_PAN_SUCCEEDED,
      this._onPanSubmitted,
    );
    this.submitPanFailureSubscription = EventBus.on(ACTION_TYPES.REQUEST_SUBMIT_PAN_FAILED, () =>
      this.setState({ loading: false }),
    );

    if (this.props.taxStatuses.length === 0) {
      this.props.requestTaxStatus();
    }
  }

  componentWillUnmount() {
    this.taxStatusRequestSubscription();
    this.taxStatusSuccessSubscription();
    this.taxStatusFailureSubscription();

    this.checkPanRequestSubscription();
    this.checkPanSuccessSubscription();
    this.checkPanFailureSubscription();

    this.submitPanRequestSubscription();
    this.submitPanSuccessSubscription();
    this.submitPanFailureSubscription();
  }

  _onFetchedTaxStatuses = () => {
    this.setState({ loading: false });
  };

  _taxStatusFromPanNumber(panNumber) {
    const { taxStatuses = [] } = this.props;

    if (!panNumber) {
      return null;
    }

    const fourthAlphabet = panNumber.substring(3, 4);
    const taxStatusName = TAX_STATUS_MAP[fourthAlphabet];

    const taxStatus = _.find(taxStatuses, (status) => status.taxDesc === taxStatusName);

    if (taxStatus) {
      return taxStatus.taxCode;
    }
    return taxStatuses && taxStatuses[0] ? taxStatuses[0].taxCode : '';
  }

  _onChangePanNumber = (e) => {
    const panNumber = (e.target.value || '').toUpperCase();
    const taxStatusCode = this._taxStatusFromPanNumber(panNumber);

    this.setState({ panNumber, taxStatusCode }, () => {
      if (panNumber.length === 10) {
        this.props.updateFolio({ panNumber, taxStatusCode });
      }
    });
  };

  _isValidForm = () => {
    const regEx = /^[A-Z]{5}\d{4}[A-Z]$/;
    return regEx.test(this.state.panNumber);
  };

  _onSubmit = () => {
    const { panNumber, taxStatusCode } = this.state;

    this.props.requestCheckPan({
      pan_number: panNumber,
      tax_status_code: taxStatusCode,
    });
  };

  _onPanChecked = (response) => {
    this.setState({ loading: false, panData: response }, () => {
      if (response.status === '1') {      
        this.props.requestSubmitPan();
      } else {      
        this.props.history.push('/long-kyc');              
      }    
    });
  };

  _closePanMessageModal = () => {
    this.setState({ isPanMessageModal: false });
  };

  _handleYes = () => {
    this.setState({ isPanMessageModal: false }, () => {
      this.props.onComplete && this.props.onComplete({ isFatcaVisible: true, isFatca: true });
    });    
  };

  _handleNo = () => {
    this.setState({ isPanMessageModal: false }, () => {
      this.props.onComplete && this.props.onComplete({ isFatcaVisible: true, isFatca: false });
    });
  }

  _onPanSubmitted = () => {
    const { panData } = this.state;

    if (panData?.fatca?.popupRequired) {
      this.setState({ loading: false, isPanMessageModal: true });      
    } else {
      this.setState({ loading: false }, () => {
        this.props.onComplete && this.props.onComplete();
      })
    }
  };

  render() {
    const { panNumber, isPanMessageModal, panData } = this.state;
    return (
      <div className={styles['pan-verification']}>
        <div className={styles['pv-to-start']}>
          To start saving for the rest of your trip
          <br /> please enter your pan card{' '}
        </div>
        <div className={styles['pv-group-input']}>
          <div>
            <label>Enter your pan card number</label>
          </div>
          <div>
            <InputMask
              className={styles['pv-pan-verify-input']}
              placeholder="Enter PAN number"
              value={panNumber}
              onChange={this._onChangePanNumber}
              mask="aaaaa9999a"
              maskChar={null}
              disabled={this.state.loading}
            />
          </div>
          <div className={styles['pv-description']}>
            To invest in India, a PAN Card is mandatory as per regulations
          </div>
          <Button
            disabled={!this._isValidForm() || this.state.loading}
            className={styles['pv-next-btn']}
            onClick={this._onSubmit}
            loading={this.state.loading}
          >
            {this.state.loading ? 'Loading...' : 'Next'}
          </Button>
          <div className={styles['message']}>Your data is encrypted by 256-bit security</div>
          <div className={styles['partner-imgs']}>
            <img src="/img/sebi2.png" alt="sebi" />
            <img src="/img/icici1.png" alt="icici" />
          </div>
          <Modal show={isPanMessageModal} size="lg">
            <div className="pan-message-modal">
              <div className="cross-icon">
                <span onClick={this._closePanMessageModal}>
                  <img src="/img/cross-icon.svg" alt="cross-icon" />
                </span>
              </div>

              <div className="message-successfull">                
                <div className="description">{panData?.fatca?.popupMessage || ''}</div>
                <div className="done-btn">
                  <button className="custom-btn" onClick={this._handleYes}>
                    Yes
                  </button>
                  <button className="cancel-btn" onClick={this._handleNo}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Loader loading={this.state.loading} />
        </div>
      </div>
    );
  }
}

export default PanVerification;

import React from 'react';
import styles from './_footernavigation.module.scss';

const FooterNavigation = () => (
  <div className={styles.footer}>      
    <a className={styles.link} href="https://www.savvyapp.in/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Condition</a> |    
    <a className={styles.link} href="https://www.savvyapp.in/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> |
    <a className={styles.link} href="https://www.savvyapp.in/merchant-agreement" target="_blank" rel="noreferrer">Merchant Agreement</a>
  </div>
);

export default FooterNavigation;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function Auth({ component: Component, ...restOfProps }) {
  const { partnerAccessKey, userIdentityToken, uuid, isPhoneVerified } = restOfProps;
  
  return (
    <Route
      {...restOfProps}
      render={(props) => {
        if (partnerAccessKey && userIdentityToken && uuid && isPhoneVerified)
          return <Redirect to='/dashboard' />
        // if (!partnerAccessKey)
        //   return <Redirect to='/' />        
        return <Component {...props} />                
      }}
    />
  );
}

export default Auth;

import { call, put, takeEvery } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { 
    requestTransactionListSuccess, 
    requestTransactionListFailed,
    requestTransactionDetailSuccess,
    requestTransactionDetailFailure
} from "../actions";

import { transactionList, transactionDetail } from "../api";

export function* watchTransactionRequests(){
    yield takeEvery(ACTION_TYPES.REQUEST_TRANSACTION_LIST, requestTransactionList) 
    yield takeEvery(ACTION_TYPES.REQUEST_TRANSACTION_DETAIL, requestTransactionDetail) 
}

function* requestTransactionList(action){
    try {
        const transactions = yield call(transactionList, action.payload)
        yield put(requestTransactionListSuccess(transactions))
    } catch (e) {
        yield put(requestTransactionListFailed(e))
    }
}

function* requestTransactionDetail(action) {
    try {
        const transaction = yield call(transactionDetail, action.payload);
        yield put(requestTransactionDetailSuccess(transaction));
    } catch (e) {
        yield put(requestTransactionDetailFailure(e));
    }
}

import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import StatsContainer from 'src/components/stats-container';
import { ACTION_TYPES } from 'src/constants';
import { ProductsTable } from 'src/components/tables';

class ProductCatalogue extends Component {
  state = {
    loading: false,
    pageNumber: 1
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState({
      pageNumber
    }, () => this.props.requestAdminProductList({
      page: pageNumber
    }))
  }

  componentDidMount() {
    this.requestAdminProductList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminProductListSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminProductListFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.props.requestAdminProductList({
      page: this.state.pageNumber
    });
  }

  componentWillUnmount() {
    this.requestAdminProductList();
    this.adminProductListSuccess();
    this.adminProductListFailure();
  }

  render() {
    const { products, productsTotal } = this.props.adminProducts;
    return (
      <>
        <StatsContainer
          title="Products Summary"
          data={[
            {
              title: 'Total Number of products',
              field: 'totalProducts',
              value: productsTotal && productsTotal,
            },
          ]}
          button={{ name: 'create new product', link: '/admin/products/new' }}
        />
        {products && products.length > 0 && <ProductsTable data={ {products, productsTotal} } handlePaginationChange={this._handlePaginationChange}/>}
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default ProductCatalogue;

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Screen, Wizard, SavvySupport } from 'src/components';
import queryString from 'query-string';
import OfferTabItemSetup from '../plan-goal/offer-detail-item/offer-tab-item-setup';
import SetUpPaySuccessModal from './setup-pay-success-modal';
import styles from './_complete-purchase.module.scss';

class CompletePurchase extends Component {
  state = {
    showResponseModal: false,   
    isError: false, 
    errorMessage: ''
  }

  componentDidMount() {
    const { status, reason = '' } = queryString.parse(this.props.location.search);

    if (status === 'success') {
      this.setState({ showResponseModal: true, isError: false });
    } else if (status === 'failure') {
      this.setState({ showResponseModal: true, isError: true, errorMessage: reason });
    }
  }

  _onNavigateToDashboard = () => {
    this.props.history.push('/dashboard');
  }

  render() {
    const { savingPlan } = this.props;
    const { showResponseModal, errorMessage, isError } = this.state;
    
    return (
      <Screen>
        <div className={styles['auto-pay']}>
          <Row className="justify-content-md-center">
            <Col lg="9">
              <Wizard progress={100} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={7}>
              <h4 className={styles.title}>
                Your payment plan &#34;{savingPlan?.productOffer?.productOfferName}&#34; has now
                been setup!
              </h4>
              <div className={styles['sub-title']}>
                You will receive an email and whatsapp with the confirmation too.{' '}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={5}>
              <div className={styles['offer-tab']}>
                <div className={styles.offerHeader}>
                  <img src={`/img/${savingPlan?.paymentMethod}.svg`} alt={savingPlan?.paymentMethod} />
                  <span>
                    {savingPlan?.paymentMethod === 'book_save_buy' ? 'Book. Save. Buy' : 'Pay in Installments' }
                  </span>                           
                </div>
                <OfferTabItemSetup 
                  {...savingPlan}                   
                  onNavigateToDashboard={this._onNavigateToDashboard}
                />
              </div>
            </Col>
          </Row>
        </div>
        <SavvySupport />
        <SetUpPaySuccessModal 
          {...savingPlan}
          isVisibleModal={showResponseModal} 
          isError={isError}
          errorMessage={errorMessage} 
          history={this.props.history}
          closeModal={() => this.setState({ showResponseModal: false })} 
        />
      </Screen>
    );
  }
}

export default withRouter(CompletePurchase);

import React from 'react';
import { Navbar, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './_header-nav.module.scss';

function HeaderNav(props) {
  const { credentials } = props;

  return (
    <Navbar expand="lg" className={styles['header-nav']}>
      <Container>
        <Navbar.Brand href="/">
          <div className={styles['savvy-logo']}>
            <div>
              <img alt="Savvy Logo" src="/img/savvy-logo.svg" />
            </div>
            {props.partner.logoImageLink ? (
              <>
                <div className={styles['savvy-logo-x']}>X</div>
                <div className={styles['another-logo']}>
                  <img alt="Savvy Logo" src={props.partner.logoImageLink} />
                </div>
              </>
            ) : null}
          </div>
        </Navbar.Brand>
        <Navbar className="justify-content-end dropdown-profile-menu">
          {credentials?.userIdentityToken ? (
            <NavDropdown
              title={
                <span className={styles['dashboard']}>
                  <span className={styles['name']}>
                    {props.user.firstName ? `${props.user.firstName}` : null}{' '}
                  </span>
                  <img alt="Profile" src="/img/menu-icon.svg" />
                </span>
              }
            >
              <NavDropdown.Item as={Link} to="/dashboard">
                Dashboard
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/logout">
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          ) : null}
        </Navbar>
      </Container>
    </Navbar>
  );
}

export default HeaderNav;

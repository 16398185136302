import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminPaymentReducer(state = INITIAL_STATE.adminPayments, action) {
  switch (action.type) {
    case ACTION_TYPES.PAYMENT_LINKS_SUCCESS: {
      return { ...state, adminPayments: {...action.payload} };
    }

    case ACTION_TYPES.PAYMENT_LINKS_DETAIL_SUCCESS: {
      return { ...state, adminPaymentDetail: { ...action.payload } };
    }

    default:
      return state;
  }
}

import { connect } from 'react-redux';
import NotFound from './not-found';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {})(NotFound);

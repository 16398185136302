import React from 'react';
import cx from 'classnames'
import styles from './_heading.module.scss'

function Heading(props) {
  const {variant = 'main'} = props

  return (
    <div {...props} className={cx(styles[variant], props?.className)}>
      {props.children}
    </div>
  );
}

export default Heading;

import { connect } from 'react-redux';
import {
  requestOccupationList,
  requestValidateEmail,
  requestSubmitInvestorDetails,
  requestSubmitInvestorKYC,
  createFolio,
} from 'src/redux/actions';
import DemographicInfo from './demographic-info';

function mapStateToProps(state) {
  return {
    user: state.user,
    occupations: state.occupations,
  };
}

export default connect(mapStateToProps, {
  requestOccupationList,
  requestValidateEmail,
  requestSubmitInvestorDetails,
  requestSubmitInvestorKYC,
  createFolio,
})(DemographicInfo);

import { connect } from "react-redux";
import { 
  requestAdminOfferCreateV2, 
  requestShortenUrl, 
  requestAdminOfferDetail, 
  requestAdminOfferUpdate,
  adminPaymentScheduleRequest,
} from "src/redux/actions";
import { GeneratePaymentLink } from "./GeneratePaymentLink";

function mapStateToProps(state){
  return{
    admin: state.admin,
    adminCredentials: state.adminCredentials,
    productOffer: state.adminOffers.productOffer,
    adminOffers: state.adminOffers,
  }
}

export default connect(mapStateToProps, {
  requestAdminOfferDetail,
  requestAdminOfferCreateV2,
  requestShortenUrl,
  requestAdminOfferUpdate,
  adminPaymentScheduleRequest
})(GeneratePaymentLink)

import { useField } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import cx from 'classnames';
import { ButtonMain } from 'src/components/theme';
import globalStyles from '../_styles.module.scss';
import styles from './_inputfilefour.module.scss';

const InputFileFour = ({ label, image, ...props }) => {
  const [, meta] = useField(props);

  return (
    <div className={cx(styles.container, props.containerstyles)}>
      <Row>
        <Col md={12}>
          <div className={cx(styles.imageInputContainer)}>
            <ButtonMain node="label" variant="compact"  htmlFor={props.id || props.name}>
              {label || 'Upload'}
            </ButtonMain>
            <Form.Group style={{ position: 'fixed', top: '-1000px' }}>
              <Form.Control {...props} />
            </Form.Group>
          </div>
          {meta.touched && meta.error ? (
            <div className={globalStyles.error}>{meta.error}</div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default InputFileFour;

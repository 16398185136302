import React, { Component } from 'react';
import styles from './_h1.module..scss';

class H1 extends Component {
  render() {
    return <div className={styles['app-h1']}>{this.props.children}</div>;
  }
}

export default H1;

import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  adminProductCreate,
  adminProductDetail,
  adminProductList,
  adminProductListByName,
  adminProductUpdate,
  createAdminProduct,
  adminProductImageDelete,
  adminProductImageUpload,
  adminProductLinkGenerate,
} from 'src/redux/api';
import {
  adminProductListSuccess,
  adminProductListFailure,
  adminProductDetailSuccess,
  adminProductDetailFailure,
  adminProductUpdateSuccess,
  adminProductUpdateFailure,
  adminProductCreateSuccess,
  adminProductCreateFailure,
  createAdminProductSuccess,
  createAdminProductFailure,
  adminProductListByNameSuccess,
  adminProductListByNameFailure,
  adminProductImageDeleteSuccess,
  adminProductImageDeleteFailed,
  adminProductImageUploadSuccess,
  adminProductImageUploadFailed,
  adminProductLinkSuccess,
  adminProductLinkFailed,
} from 'src/redux/actions';

export function* watchAdminProductRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, requestAdminProductList);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST_BY_NAME, requestAdminProductListByName);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_DETAIL, requestAdminProductDetail);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_UPDATE, requestAdminProductUpdate);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_CREATE, requestAdminProductCreate);
  yield takeEvery(ACTION_TYPES.CREATE_ADMIN_PRODUCT, requestCreateAdminProduct);
  yield takeEvery(ACTION_TYPES.ADMIN_PRODUCT_IMAGE_DELETE, requestAdminProductImageDelete);
  yield takeEvery(ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD, requestAdminProductImageUpload);
  yield takeEvery(ACTION_TYPES.PRODUCT_LINK_REQUEST, requestAdminProductLink);
}

function* requestAdminProductCreate(action) {
  try {
    const response = yield call(adminProductCreate, action.payload);
    yield put(adminProductCreateSuccess(response));
  } catch (e) {
    yield put(adminProductCreateFailure(e));
  }
}

function* requestAdminProductUpdate(action) {
  try {
    const response = yield call(adminProductUpdate, action.payload);
    yield put(adminProductUpdateSuccess(response));
  } catch (e) {
    yield put(adminProductUpdateFailure(e));
  }
}

function* requestAdminProductDetail(action) {
  try {
    const product = yield call(adminProductDetail, action.payload);
    yield put(adminProductDetailSuccess(product));
  } catch (e) {
    yield put(adminProductDetailFailure(e));
  }
}

function* requestAdminProductList(action) {
  try {
    const products = yield call(adminProductList, action.payload);
    yield put(adminProductListSuccess(products));
  } catch (e) {
    yield put(adminProductListFailure(e));
  }
}

function* requestAdminProductListByName(action) {
  try {
    const products = yield call(adminProductListByName, action.payload);
    let productsData = [];
    if (products?.products && products?.products.length > 0) {
      productsData = products?.products.map((data) => ({
        ...data,
        value: data?.productName,
        label: data?.productName,
      }));
    }
    action.payload.callback(productsData || []);
    yield put(adminProductListByNameSuccess(products));
  } catch (e) {
    yield put(adminProductListByNameFailure(e));
  }
}

function* requestCreateAdminProduct(action) {
  try {
    const product = yield call(createAdminProduct, action.payload);
    yield put(createAdminProductSuccess(product));
  } catch (e) {
    yield put(createAdminProductFailure(e));
  }
}

function* requestAdminProductImageDelete(action) {
  try {
    const res = yield call(adminProductImageDelete, action.payload);
    yield put(adminProductImageDeleteSuccess(res));
  } catch (e) {
    yield put(adminProductImageDeleteFailed(e));
  }
}

function* requestAdminProductImageUpload(action) {
  try {
    const res = yield call(adminProductImageUpload, action.payload);
    yield put(adminProductImageUploadSuccess(res));
  } catch (e) {
    yield put(adminProductImageUploadFailed(e));
  }
}

function* requestAdminProductLink(action) {
  try {
    const response = yield call(adminProductLinkGenerate, action.payload);
    yield put(adminProductLinkSuccess(response));
  } catch (e) {
    yield put(adminProductLinkFailed(e));
  }
}

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function FolioReducer(state = INITIAL_STATE.folio, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_FOLIO_SUCCEEDED:
      return {
        ...action.payload.folio,
      };

    case ACTION_TYPES.UPDATE_FOLIO: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED: {
      return INITIAL_STATE.folio;
    }

    default:
      return state;
  }
}

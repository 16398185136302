import { connect } from 'react-redux';
import AccountDetails from './account-details';

function mapStateToProps(state) {  
  return {
    bankAccount: state.user.depositBankAccount || {}
  };
}

export default connect(mapStateToProps, {})(AccountDetails);

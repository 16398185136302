import { connect } from 'react-redux';
import OfferTabItem from './offer-tab-item';

function mapStateToProps(state) {
  return {
    totalPax: state.savingPlan.totalPax || 1,
  };
}

export default connect(mapStateToProps, {})(OfferTabItem);

import React, { Component } from 'react';
import cx from 'classnames';
import styles from './_product-image.module.scss';

class ProductImage extends Component {
  state = {
    loaded: false,
  };

  render() {
    return (
      <div className={cx(styles['image-container'], this.props.className)}>
        {this.state.loaded ? (
          <div
            className={styles['catalog-image']}
            style={{ backgroundImage: `url(${this.props.image})` }}
          >
            <div className={styles['partner-title']}>{this.props.saveLaterTitle}</div>
            <div className={styles['partner-sub-title']}>{this.props.saveLaterSubtitle}</div>
          </div>
        ) : (
          <div>
            <img
              src={this.props.image}
              style={{ display: 'none' }}
              onLoad={() => this.setState({ loaded: true })}
              alt="Product"
            />
            <img className={styles['loader-image']} src="/img/loader.gif" alt="loader" />
          </div>
        )}
      </div>
    );
  }
}

export default ProductImage;

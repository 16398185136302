import { updateSavingPlan } from 'src/redux/actions';
import { connect } from 'react-redux';

import OfferBox from './offer-box';

function mapStateToProps(state) {
  return {
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
  };
}

export default connect(mapStateToProps, { updateSavingPlan })(OfferBox);

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Screen, Wizard, SavvySupport } from 'src/components';
import OfferTabItem from '../plan-goal/offer-detail-item/offer-tab-item';
import styles from './_auto-pay.module.scss';

class AutoPay extends Component {
  _onProceed = () => {
    this.props.history.push('/auto-pay/link-bank-account');
  }

  render() {
    const { savingPlan } = this.props;
    
    return (
      <Screen>
        <div className={styles['auto-pay']}>
          <Row className="justify-content-md-center">
            <Col lg="9">
              <Wizard progress={33} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={7}>
              <h4 className={styles.title}>To complete your purchase of &#34;{savingPlan?.productOffer?.productOfferName}&#34; you will now setup your payment plan</h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={5}>                    
              <div className={styles["offer-tab"]}>  
                <div className={styles.offerHeader}>
                  <img src="/img/pay_in_instalments.svg" alt="pay_in_instalments" />
                  <span>Pay in Installments</span>
                </div>
                <OfferTabItem 
                  {...savingPlan}
                  type="pay_in_instalments"
                  onProceedAutoPay={this._onProceed}
                  autopay
                />
              </div>
            </Col>
          </Row>          
        </div>
        <SavvySupport />
      </Screen>
    );
  }
}

export default withRouter(AutoPay);

import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import styles from './_responsive-tab.module.scss';

const MobileToggle = (props) => {
  const _onClick = () => {
    props.onClick && props.onClick(props.eventKey);
  };

  return (
    <Accordion.Toggle eventKey={props.eventKey} className={styles['toggle']} onClick={_onClick}>
      {props.link ? (
        <Link to={props.link}>
          <div className={styles['title']}>{props.title}</div>
          <div className={styles['icon']}>
            <FontAwesomeIcon
              icon={props.activeKey === props.eventKey ? faChevronUp : faChevronDown}
            />
          </div>
        </Link>
      ) : (
        <>
          <div className={styles['title']}>{props.title}</div>
          <div className={styles['icon']}>
            <FontAwesomeIcon
              icon={props.activeKey === props.eventKey ? faChevronUp : faChevronDown}
            />
          </div>
        </>
      )}
    </Accordion.Toggle>
  );
};

const DesktopToggle = (props) => {
  const _onClick = () => {
    props.onClick && props.onClick(props.eventKey);
  };

  return (
    <>
      {props.link ? (
        <Link to={props.link}>
          <button
            onClick={_onClick}
            className={cx(styles['toggle'], {
              [styles['active']]: props.eventKey === props.activeKey,
            })}
          >
            {props.title}
          </button>
        </Link>
      ) : (
        <button
          onClick={_onClick}
          className={cx(styles['toggle'], {
            [styles['active']]: props.eventKey === props.activeKey,
          })}
        >
          {props.title}
        </button>
      )}
    </>
  );
};

class ResponsiveTab extends Component {
  state = {
    viewWidth: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  };

  componentDidMount() {
    window.addEventListener('resize', this._onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onWindowResize);
  }

  _onWindowResize = () => {
    const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    this.setState({ viewWidth });

    if (!this.props.activeKey) {
      this.props.onChange && this.props.onChange(this.props.defaultKey);
    }
  };

  _onToggleAccordion = (eventKey) => {
    const currentKey = this.props.activeKey !== eventKey ? eventKey : null;
    this.props.onChange && this.props.onChange(currentKey);
  };

  _onToggleTab = (eventKey) => {
    this.props.onChange && this.props.onChange(eventKey);
  };

  render() {
    const { children } = this.props;
    const isMobile = this.state.viewWidth < 992;

    return (
      <div className={styles['responsive-tab']}>
        {isMobile ? (
          <Accordion className={styles['accordion']} activeKey={this.props.activeKey}>
            {children &&
              children.length > 0 &&
              children.map((child, index) => (
                <div className={styles['accordion-content']} key={`accordion-content-${index}`}>
                  <MobileToggle
                    {...child.props}
                    activeKey={this.props.activeKey}
                    onClick={this._onToggleAccordion}
                  />
                  <Accordion.Collapse eventKey={child.props.eventKey}>
                    <>{child.props.children}</>
                  </Accordion.Collapse>
                </div>
              ))}
          </Accordion>
        ) : (
          <>
            <div className={styles['toggle-container']}>
              {children &&
                children.length > 0 &&
                children.map((child, index) => (
                  <DesktopToggle
                    {...child.props}
                    activeKey={this.props.activeKey}
                    onClick={this._onToggleTab}
                    key={`toggle-item-${index}`}
                  />
                ))}
            </div>
            <div className={styles['body-container']}>
              {children &&
                children.length > 0 &&
                children.map((child, index) => (
                  <div
                    className={cx(styles['body'], {
                      [styles['active']]: child.props.eventKey === this.props.activeKey,
                    })}
                    key={`body-item-${index}`}
                  >
                    {child.props.children}
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

class Content extends Component {
  render() {
    return this.props.children;
  }
}

export default Object.assign(ResponsiveTab, {
  Content,
});

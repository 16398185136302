import React, { Component } from 'react';
import cx from 'classnames';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { Loader } from 'src/components';
import moment from 'moment';
import { Convert } from 'src/helpers';
// import DatePicker from 'react-datepicker';
import styles from './_transactions.module.scss';

// function CustomDatepickerInput({onChange, value, onClick}){
//   return(
//     <button className={styles['example-custom-input']} onClick={onClick} onChange={onChange}>
//       {value}
//     </button>
//   )
// }

// const TABS = {
//   UPCOMING: 'Upcoming',
//   RECENT: 'Recent',
// };

// const TABS_LIST = [TABS.UPCOMING, TABS.RECENT];

class Transactions extends Component {
  state = {
    loading: false,
    selectedDate: new Date(),
    // selectedTab: TABS.UPCOMING,
    filteredTransactionsData: [],
  };

  componentDidMount() {
    this.requestTransactionList = EventBus.on(ACTION_TYPES.REQUEST_TRANSACTION_LIST, () =>
      this.setState({ loading: true }),
    );

    this.requestTransactionListSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_TRANSACTION_LIST_SUCCESS,
      () => this.setState({ loading: false }),
    );

    this.requestTransactionListFailed = EventBus.on(
      ACTION_TYPES.REQUEST_TRANSACTION_LIST_FAILED,
      () => this.setState({ loading: false }),
    );

    this.props.requestTransactionList();
  }

  componentWillUnmount() {
    this.requestTransactionList();
    this.requestTransactionListSuccess();
    this.requestTransactionListFailed();
  }

  /**
   * returns data according to date selected
   * @param {array} data
   * @returns {array}
   */
  _filterTransactionsByDate = (organizedData) =>
    organizedData.filter((item) => moment(item.createdAt).isSame(this.state.selectedDate, 'month'));

  /**
   * add a label to the transactions array
   * example: 'Deposited' for savingsDeposit data, 'withdrwn' for withdrawals data
   * @param {array} data
   * @param {string} labelName
   * @returns {array}
   */
  _addTransactionLabel = (data, labelName) => {
    const labeledData = data.map((val) => {
      val['label'] = labelName;
      return val;
    });

    return labeledData;
  };

  /**
   * Raw data in the form of object of arrays is received which needs to be merged
   * and sorted, filtered according to date
   *
   * @param {object} transactionsData
   * @returns {array}
   */
  _organizeTransactionsData = (transactionsData) => {
    let { deposits = [], withdrawals = [], payments = [] } = transactionsData;
    deposits = this._addTransactionLabel(deposits, 'Deposits');
    withdrawals = this._addTransactionLabel(withdrawals, 'Withdrawal');
    payments = this._addTransactionLabel(payments, 'Booking amount');

    let organizedData = [...deposits, ...withdrawals, ...payments];
    organizedData = this._filterTransactionsByDate(organizedData);
    return organizedData.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
    );

    // console.log(organizedData);
  };

  _getPayments = (savingGoals) => {
    let paymentsData = [];
    if (savingGoals && savingGoals.length > 0) {
      savingGoals.forEach((payment) => {
        if (payment?.payments) {
          paymentsData = [...paymentsData, ...payment?.payments];
        }
      });
    }
    if (paymentsData && paymentsData.length > 0) {
      paymentsData = paymentsData.map((data) => {
        const createdData = data?.createdAt && data?.createdAt.split('/');
        const formattedDate =
          (createdData &&
            createdData.length > 2 &&
            `${createdData[2]}/${createdData[1]}/${createdData[0]} 00:00:00`) ||
          '';
        return {
          ...data,
          createdAt: formattedDate,
        };
      });
    }

    return paymentsData;
  };

  // handleTab = (selectedTab) => {
  //   const { transactions, savingGoals } = this.props;
  //   const payments = this._getPayments(savingGoals);

  //   let transactionsData = this._organizeTransactionsData({ ...transactions, payments });
  //   if (selectedTab === TABS.RECENT) {
  //     transactionsData = transactionsData.sort(
  //       (first, second) => moment(second.createdAt) - moment(first.createdAt),
  //     );
  //   }
  //   this.setState({ selectedTab, filteredTransactionsData: transactionsData });
  // };

  render() {
    const { savingGoals, savingGoal } = this.props;
    const { filteredTransactionsData } = this.state;
    const payments = this._getPayments(savingGoals);
    const transactionsData =
      (filteredTransactionsData &&
        filteredTransactionsData.length > 0 &&
        filteredTransactionsData) ||
      this._organizeTransactionsData({
        payments,
        deposits: savingGoal?.savingGoalContributions,
        withdrawals: savingGoal?.savingGoalWithdrawals,
      });
    return (
      <div className={cx(styles['transactions'], { [styles['overview']]: this.props.overview })}>
        <div className={cx({ [styles['inner-content']]: !this.props.overview })}>
          <div className={styles['header']}>
            <div className={styles['description']}>Transactions</div>
            {/* <DatePicker
              className={styles['date']}
              selected={this.state.selectedDate}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              onChange={(date) => this.setState({ selectedDate: date })}
              // customInput={CustomDatepickerInput}
            /> */}
          </div>
          {/* <div className={styles['header-tabs']}>
            {TABS_LIST &&
              TABS_LIST.length > 0 &&
              TABS_LIST.map((tab, index) => (
                <div
                  key={index}
                  className={cx(styles['tab'], { [styles['active-tab']]: selectedTab === tab })}
                  onClick={() => this.handleTab(tab)}
                >
                  {tab}
                </div>
              ))}
          </div> */}
          <div className={styles['header-row']}>
            <div className={styles['title']}>Type</div>            
            <div className={styles['amount']}>Amount</div>            
            <div className={styles['status']}>Status</div>
            <div className={styles['date']}>Date</div>
          </div>
          <div className={styles['body']}>
            {transactionsData &&
              transactionsData.length > 0 &&
              transactionsData.map((item, index) => (
                <div className={styles['row']} key={`transation-item-${index}`}>
                  <div className={styles['description']}>
                    <div className={styles['title']}>{item.label}</div>
                  </div>                  
                  <div className={styles['amount']}>
                    {Convert.toCurrencyValue(item.amount)}
                    {/* <div
                      className={`${styles['type']} ${
                        item.label == 'Withdrawn' ? styles['red'] : styles['green']
                      }`}
                    >
                      {item.label}
                    </div> */}
                  </div>
                  <div className={styles['status']}>
                    <span className={
                      item.status === 'completed' ? styles['completed'] : 
                      item.status === 'in_progress' ? styles['in_progress'] : 
                      item.status === 'pending_investment' ? styles['pending_investment'] : 
                      item.status === 'error' ? styles['error'] : {}
                    }>{item.status}</span>
                  </div>
                  <div className={styles['date']}>
                    {moment(item.createdAt).format('MMM Do YYYY, HH:mm:ss')}
                  </div>
                </div>
              ))}
            {transactionsData && transactionsData.length === 0 ? (
              <div className={styles['row']}>- No transactions found -</div>
            ) : null}
            <Loader loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default Transactions;

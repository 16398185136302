import React, { useState, useEffect } from 'react';
import styles from './_wizard.module.scss';
import { Text } from '../theme';

function Wizard(props) {
  const [progress, setProgress] = useState(props.progress - 25);

  useEffect(() => {
    setTimeout(() => {
      setProgress(props.progress);
    }, 100);
  });

  return (
    <div className={styles['app-wizard']}>
      <div className={styles['progress-wrapper']}>
        <div className={styles['progress-bar']} style={{ width: `${progress}%` }}>
          <div className={styles['progress-circle']} />
        </div>
      </div>
      <div className={styles['section-container']}>
        <Text variant="p2" className={styles['section']}>
          Make booking
        </Text>        
        {props.showKyc && props.paymentMethod === 'book_save_buy' ? <div className={styles['section']}>KYC</div> : null}
        <Text variant="p2" className={styles['section']}>
          { props.paymentMethod === 'book_save_buy' ? 'Set up SIP' : 'Set up Auto-Pay' }
        </Text>
        <Text variant="p2" className={styles['section']}>
          { props.paymentMethod === 'book_save_buy' ? 'Start Saving' : 'Complete Purchase' }
        </Text>
      </div>
    </div>
  );
}

export default Wizard;

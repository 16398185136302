import { ACTION_TYPES } from 'src/constants';

export function requestAdminLogin(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_LOGIN,
    payload,
  };
}

export function adminLoginSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGIN_SUCCESS,
    payload,
  };
}

export function adminLoginFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGIN_FAILED,
    payload,
  };
}

export function requestAdminLogout(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_LOGOUT,
    payload,
  };
}

export function adminLogoutSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGOUT_SUCCESS,
    payload,
  };
}

export function adminLogoutFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGOUT_FAILED,
    payload,
  };
}

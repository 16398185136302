import React, { Component } from 'react';
import { Loader } from 'src/components';
import EventBus from 'eventing-bus';
import { ACTION_TYPES } from 'src/constants';
import { Link, withRouter } from 'react-router-dom';
// import CustomBadge from 'src/components/custom-badge';
import { Convert } from 'src/helpers';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import styles from './_user-payment-detail.module.scss';
import './_user-modal.scss';

class CollectPaymentDetail extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const uuid = this.props.match.params?.uuid;
    this.props.requestAdminCollectionsDetail({ id: uuid });

    this.requestAdminCollectionDetail = EventBus.on(ACTION_TYPES.REQUEST_COLLECTION_DETAIL, () =>
      this.setState({ loading: true }),
    );
    this.requestAdminCollectionDetailSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_DETAIL_SUCCESS,
      () => this.setState({ loading: false }),
    );
    this.requestAdminCollectionDetailFailure = EventBus.on(
      ACTION_TYPES.REQUEST_COLLECTION_DETAIL_FAILED,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this.requestAdminCollectionDetail();
    this.requestAdminCollectionDetailSuccess();
    this.requestAdminCollectionDetailFailure();
  }

  render() {
    const { collectionDetail } = this.props;
    const { savingGoal, requestedAmount } = collectionDetail;
    const { loading } = this.state;
    // const isActive = false;    
    return (
      <div className={styles['payment-links']}>
        <div className={styles['heading']}>
          <h2 className="p-3">Collect Detail</h2>
          <Dropdown className={styles['dropdown']}>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className={styles['dropdown-toggle-btn']}
            >
              Filter By
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">CSV</Dropdown.Item>
              <Dropdown.Item href="#/action-2">PDF</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={styles['title']}>Purchase Details</div>
        <div className={styles['detail']}>
          <div>
            <p className={styles['booking']}>Booking Amount: {Convert.currency(savingGoal?.productOffer?.upfrontPaymentAmount)}</p>
            <p>Requested Amount: {Convert.currency(requestedAmount)}</p>
            <p>Purchase ID: <Link to={`/admin/goal/${savingGoal?.id}`}>{savingGoal?.id}</Link></p>
            <p>Product ID: <Link to={`/admin/product/${savingGoal?.productOffer?.product?.uuid}`}>{savingGoal?.productOffer?.product?.uuid}</Link></p>
            <p>Offer ID: <Link to={`/admin/product/offer/${savingGoal?.productOffer?.uuid}`}>{savingGoal?.productOffer?.uuid}</Link></p>
            <p>Offer Activated on: {moment(savingGoal?.createdAt, 'DD/MM/YYYY').format('DD/MM/YYYY')}</p>
            <p>Offer To be completed on: {savingGoal?.endDate}</p>
          </div>
          {/* <div>
            {isActive ? (
              <CustomBadge value="Active" type="success" className={styles['badge-style']} />
            ) : (
              <CustomBadge value="Inactive" type="error" className={styles['badge-style']} />
            )}
          </div> */}
        </div>
        {/* <div className={styles['title']}>User Details</div>
        <div className={styles['detail']}>
          <div>
            <p>Name : Jane Doe</p>
            <p>Name : Jane Doe</p>
            <p>Email ID: jane.doe@gmail.com</p>
          </div>
        </div>
        <div className={styles['custom-btn-group']}>
          <Button className={styles['custom-btn']}>OFFER REFUND</Button>
          <Button className={styles['custom-btn']}>NO REFUND</Button>
        </div> */}
        <Loader loading={loading} />
      </div>
    );
  }
}

export default withRouter(CollectPaymentDetail);

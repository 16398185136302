import { useField } from 'formik';
import React from 'react';
import styles from './_inputswitch.module.scss';

function InputSwitch({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <div className={styles.container} style={{ ...props.containerstyles }}>
        {
          props.labelPosition !== 'right'
          ?
          <label className={styles.inputLabel} htmlFor={props.id || props.name}>
            {label}
          </label>
          :
          null
        }        
        <div className={`${styles.input} form-check form-switch`}>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            {...field}
            {...props}
          />
        </div>
        {
          props.labelPosition === 'right'
          ?
          <label className={styles.inputLabel} htmlFor={props.id || props.name}>
            {label}
          </label>
          :
          null
        }
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>
    </>
  );
}

export default InputSwitch;

import moment from 'moment';
import React, { useMemo } from 'react';
import { Convert } from 'src/helpers';
import { Link } from 'react-router-dom';
import DataTable from '../../data-table';
// import styles from '../_styles.module.scss';
import './_transactionsTable.scss';

export default function TransactionsTable(props) {
  // let history = useHistory();
  // const location = useLocation()

  /**
   *
   * Handles click to uuid to take user to product detail screen
   *
   */
  // const _handleDetailLink = (e) => {
  //     e.preventDefault();
  //     // history.push(`/admin/product/${uuid}`);
  // };
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#f3f3f3',
      borderBottom: '1rem',
      borderBottomColor: '#fff',
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: 'Purchase',
        accessor: 'savingGoalId',
        disableSortBy: true,
        Cell: ({ value }) => (
          <Link to={`/admin/goal/${value}`} title="Purchase">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Offer',
        accessor: 'productOfferUuid',
        disableSortBy: true,
        Cell: ({ value }) => (
          <Link to={`/admin/product/offer/${value}`}>
            <div className="transactions-offer-uuid">{value}</div>
          </Link>
        ),
      },      
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => <span>{Convert.toCurrencyValue(value)}</span>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {value === 'completed' ? (
              <span style={{ color: '#1eb605' }}>Completed</span>
            ) : value === 'failed' ? (
              <span style={{ color: '#DC3545' }}>Failed</span>
            ) : value === 'in_progress' || value === 'in progress' ? (
              <span style={{ color: '#007BFF' }}>In progress</span>
            ) : value === 'success' ? (
              <span style={{ color: '#1eb605' }}>Success</span>
            ) : (
              <span style={{ color: '#eda12c', textTransform: 'capitalize' }}>{value}</span>
            )}
          </>
        ),
      },
      // {
      //   Header: 'Type',
      //   accessor: 'label',
      //   disableSortBy: true,
      //   Cell: ({ value }) => (
      //     <span style={{ color: value == 'Withdrawn' ? '#DC3545' : '#1eb605' }}>{value}</span>
      //   ),
      // },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (!value) return;
          const [day, month, year] = value.split('/');
          return (
            <span style={{ textAlign: 'center' }}>
              {moment(`${month}/${day}/${year}`).format('MMMM Do YYYY')}
            </span>
          );
        },
      },
    ],
    [],
  );
  return (
    <div className="transaction-data-table">
      <DataTable
        columns={columns}
        data={props.data}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        handlePaginationChange={props.handlePaginationChange}
      />
    </div>
  );
}

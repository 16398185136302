import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';
import { BsExclamationOctagonFill } from 'react-icons/bs';
import { HiBadgeCheck } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { TextOne } from '../text-one';
import styles from './_modal-payment-link.module.scss';
import './_modal-payment-link.scss';
import { ButtonOne } from '..';

export default function ModalPaymentLink(props) {
  const history = useHistory();
  let codeInput = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  const reload = () => history.go(0);
  const copyCodeToClipboard = (e) => {
    codeInput.select();
    codeInput.setSelectionRange(0, 99999);

    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    e.preventDefault();
  };

  return (
    <div className="modal-link-payment">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.container}
      >
        <Modal.Header className={cx(styles.header, 'd-flex justify-content-center')}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.shortenedUrl ? (
              <HiBadgeCheck size="70" className={cx(styles.icon, styles.success)} />
            ) : (
              <BsExclamationOctagonFill size="70" className={cx(styles.icon, styles.error)} />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={cx(styles.body, 'd-flex justify-content-center')}>
          {props.shortenedUrl ? (
            <TextOne semiBold className={styles.text}>
              Your link for offer - {props?.offerName}, has been created successfully!
              <br />
              <textarea
                className={styles['link']}
                value={props.shortenedUrl}
                ref={(ref) => (codeInput = ref)}
              />
              {isCopied && <div className={styles['code-copied']}>Copied to clipboard</div>}
              {/* <div className={styles['custom-btns-group']}>
                <ButtonOne text="Copy to Clipboard" onClick={copyCodeToClipboard} />
              </div> */}
            </TextOne>
          ) : (
            <TextOne semiBold className={styles.text}>
              Uh-Oh! We were unable to create the payment link.
              <br />
              Please try again
            </TextOne>
          )}
        </Modal.Body>
        <Modal.Footer className={cx(styles.footer, 'd-flex justify-content-center')}>
          {props.shortenedUrl ? (
            <div className={styles.buttonsContainer}>
              <ButtonOne
                text="Create another link"
                className={styles.buttonOne}
                onClick={() => reload()}
              />
              <div className={styles.buttonOne}>
                <ButtonOne text="Copy link" onClick={copyCodeToClipboard} />
              </div>
              <ButtonOne text="Done" onClick={() => reload()} />
            </div>
          ) : (
            <ButtonOne text="Retry" onClick={() => props.handleModal()} />
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import { useField } from 'formik';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import styles from './_textinputthree.module.scss';
import { MandatoryMark } from 'src/components';
import { TextInput } from 'src/components/theme';
import globalStyles from '../_styles.module.scss';

const TextInputThree = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Row className={props.containerstyles}>
        {label && label != '' && (
          <Col md={12} className={props?.labelcontainerstyles}>
            <label htmlFor={props.id || props.name} className={props?.labelstyles}>
              {label}
              {props.mandatory && <MandatoryMark/>}
            </label>
          </Col>
        )}
        <Col md={12} className={props?.fieldcontainerstyles}>
          <TextInput size="sm" {...field} {...props} className={props.inputstyles}/>
          {meta.touched && meta.error ? (
            <div className={globalStyles.error}>{meta.error}</div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default TextInputThree;

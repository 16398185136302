import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Convert } from 'src/helpers';
import cx from 'classnames';
import { Loader, SavvySupport, SignInSignUpModal } from 'src/components';
import styles from './received-payment-setup.module.scss';
import OfferBox from '../pay/offer-box';

class ReceivedPaymentSetup extends Component {
  state = {
    loading: false,
    showLoginModal: false,
  };

  componentDidMount() {
    if (!this.props.user.uuid) {
      this.setState({ showLoginModal: true });
    }
  }

  redirectToAutoPay = () => {
    if (!this.props.user.uuid) {
      this.setState({ showLoginModal: true });
      return;
    }
    const { history } = this.props;
    history.push('/auto-pay/setup');
  };

  _onCloseSignInModal = () => {
    this.setState({ showLoginModal: false });
  };

  _onSignInComplete = () => {
    this.setState({ showLoginModal: false }, () => {});
  };

  getPaymentDetail = () => {
    const { savingGoal } = this.props;
    const savingGoalContributions = savingGoal?.savingGoalContributions || [];

    if (savingGoalContributions && savingGoalContributions.length > 0) {
      savingGoalContributions.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }
        return 0;
      });
    }

    return savingGoalContributions.pop();
  };

  render() {
    const { loading } = this.state;
    const { partner, product, user, savingGoal } = this.props;
    const paymentDetail = this.getPaymentDetail();

    return (
      <div className={styles['pay']}>
        <div className={styles['pay-box']}>
          <div className={styles['left-box']}>
            <div className={styles['pay-content']}>
              <div className={styles['title']}>
                We have receieved your payment amount of
                <br /> {Convert.currency(paymentDetail?.amount || 0)}
              </div>
              <div className={cx(styles['note'], styles['mobile'])}>
                for {savingGoal?.productOffer?.productOfferName}
              </div>
              <div className={cx(styles['progress-bar'], styles['mobile'])}>
                <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
              </div>
              <div className={cx(styles['pay-in-installment'], styles['mobile'])}>
                PAY IN INSTALLMENTS{' '}
              </div>
              <div className={styles['tick']}>
                <img src="/img/tick-2.svg" alt="tick" />
              </div>
              <div className={cx(styles['folio'])}>
                Your Folio Number is No. {user?.folio?.folioNumber}
              </div>

              <div className={styles['message']}>
                Please proceed to setup your auto-debit for{' '}
                {savingGoal?.productOffer?.productOfferName} to avoid missing any future payments
              </div>
            </div>
            <div className={styles['footer']} onClick={this.redirectToAutoPay}>
              SETUP AUTO-PAY
            </div>
          </div>
          <div className={styles['right-box']}>
            <OfferBox partner={partner} product={product} user={user} />
          </div>
        </div>
        <SignInSignUpModal
          isShow={this.state.showLoginModal}
          closeModal={this._onCloseSignInModal}
          onComplete={this._onSignInComplete}
        />
        <SavvySupport />
        <Loader loading={loading} fixed />
      </div>
    );
  }
}

export default withRouter(ReceivedPaymentSetup);

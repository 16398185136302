import React, { Component } from 'react';
import cx from 'classnames';
import { Convert } from 'src/helpers';
import moment from 'moment';
import './_offer-tab-item.scss';

class OfferTabItem extends Component {
  // state = {
  //   isTermsAccepted: false    
  // };

  /**
   * converts input date to a specific format
   * @param {int} date
   * @return {string}
   */
  _getFormattedDate = (date) => moment(date, 'DDMMYYYY').format('Do MMMM');

  /**
   * append suffix to number example: 'st' to 1, 'nd' to 2 etc.
   * @param {*} i
   */
  _ordinalSuffix(i) {
    const j = i % 10;
    const k = i % 100;

    if (j == 1 && k != 11) {
      return `${i}st`;
    }
    if (j == 2 && k != 12) {
      return `${i}nd`;
    }
    if (j == 3 && k != 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  // _onAcceptTerms = (e) => {
  //   this.setState({ isTermsAccepted: e.target.checked });
  // };

  _onProceedPay = () => {
    const { paymentMethod, productOffers, amount } = this.props;
    const offer = productOffers.length > 0 ? productOffers[0] : {};
    const discountAbsoluteAmount = (Number(amount) / 100) * Number(offer?.discountPercentage);
    const discountedPrice = Number(amount) - discountAbsoluteAmount;
    this.props.onProceedPay &&
      this.props.onProceedPay({
        ...offer,
        discountedPrice,
        discountAbsoluteAmount,
        paymentMethod,
      });
  }

  _onSetupAutoPay = () => {
    this.props.onProceedAutoPay && this.props.onProceedAutoPay();
  };

  render() {
    const {
      amount,
      productOffers,
      productOffer,
      taxes,
      paymentMethod,
      autopay,
      isFromPlanPage,
    } = this.props;
    const offer = productOffers?.length > 0 ? productOffers[0] : productOffer || {};
    let { upfrontPaymentAmount, paymentCalendar, discountPercentage, savingReturn, product } =
      offer;
    const sortedPaymentCalendarDates = Object.keys(paymentCalendar || {}).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const actualProductPrice = Number(amount || product?.amount);
    let monthlyPayment = paymentCalendar ? paymentCalendar[sortedPaymentCalendarDates[1]] : 0;
    const discount = (Number(actualProductPrice) / 100) * discountPercentage;
    const offerAmount = Number(actualProductPrice) - discount;
    const finalPrice = offerAmount - upfrontPaymentAmount;
    // const returnAmount = (finalPrice / 100) * Number(savingReturn);
    const getGst =
      taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'gst');
    const getTcs =
      taxes && taxes.length > 0 && taxes.find(({ taxFieldName }) => taxFieldName === 'tcs');

    let gstAmount = 0;
    let tcsAmount = 0;

    if (getGst?.percentage && getGst?.percentage > 0) {
      gstAmount = (Number(getGst?.percentage) / 100) * offerAmount;
    }

    if (getTcs?.percentage && getTcs?.percentage > 0) {
      tcsAmount = (Number(getTcs?.percentage) / 100) * offerAmount;
    }
    const fromKycScreen = false;
    
    console.log(gstAmount, tcsAmount, monthlyPayment, savingReturn, finalPrice)
    return (
      <div className="offer-tab-item">
        <div className="offer-content">
          <div className="offer-detail">
            <div className="timeline-wrapper">
              <div className="timeline">
                <div
                  className={`common-box first-common-box ${
                    isFromPlanPage ? 'highlighted-box plan-highlighted' : ''
                  } `}
                >
                  <div className="content">
                    <div className="left">
                      {!isFromPlanPage && (
                        <>
                          {fromKycScreen ? (
                            <img src="/img/correct.png" alt="tick" className="tick" />
                          ) : (
                            <img src="/img/ok-5.svg" alt="tick" className="tick" />
                          )}
                        </>
                      )}
                      <span className="dark-box">Book&nbsp;</span>
                      your holiday today & secure your voucher
                    </div>
                    <div className="right">{Convert.toCurrencyFormat(upfrontPaymentAmount)}</div>
                  </div>
                </div>
                <div
                  className={`common-box ${isFromPlanPage ? '' : 'highlighted-box'} ${
                    fromKycScreen ? 'grey-bg pb-3' : ''
                  }`}
                >
                  <div className="content remaining-installments-text">
                    {paymentMethod === 'book_save_buy' ? (
                      <div>
                        <span className="dark-box"> Save&nbsp;</span>for remaining in easy
                        installments with <br />
                      </div>
                    ) : (
                      <div>
                        <span className="dark-box"> Pay&nbsp;</span>for remaining in{' '}
                        easy installments <br />
                      </div>
                    )}
                  </div>
                  {paymentMethod === 'book_save_buy' ? (
                    <span
                      className="icici-link"
                    >
                      {' '}
                      ICICI Prudential Liquid Fund
                    </span>
                  ) : null}
                </div>
                <div className="installments-wrapper">
                {sortedPaymentCalendarDates &&
                  sortedPaymentCalendarDates.length > 0 &&
                  sortedPaymentCalendarDates.map((installment, index) => {
                    if (index === 0) return null;
                    return (
                      <div
                        className={cx('point-node', {
                          'point-node-last': index === sortedPaymentCalendarDates.length - 1,
                        })}
                        key={index}
                      >
                        <div className="content">
                          <div className="left">
                            {this._ordinalSuffix(index)} installment on{' '}
                            {this._getFormattedDate(installment)}
                          </div>
                          <div className="right">
                            {Convert.currency(paymentCalendar[installment])}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {paymentMethod === 'book_save_buy' ? (
                  <div className="earn-cashback">
                    (<span>Earn returns</span> as you go)
                  </div>
                ) : null}
                {paymentMethod === 'book_save_buy' &&
                  sortedPaymentCalendarDates &&
                  sortedPaymentCalendarDates.length > 0 &&
                  sortedPaymentCalendarDates.map((installment, index) => {
                    if (index == sortedPaymentCalendarDates.length - 1) {
                      return (
                        <div className="common-box buy-the-holiday" key={index}>
                          <div className="content">
                            <div className="hidder" />
                            <div style={{ maxWidth: '460px' }}>
                              <span className="dark-box"> Buy&nbsp;</span>
                              the holiday by paying the booking amount with money saved and enjoy
                              your travels.
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="text-center">
              {!fromKycScreen && !autopay && (
                <div className="tnc-container">
                  <div className="tnc-text">
                    <input
                      type="checkbox"
                      className="tnc-checkbox"
                      onChange={this._onAcceptTerms}
                    />
                    I accept the{' '}
                    <a
                      href="https://www.savvyapp.in/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions
                    </a>{' '}
                    of SavvyPay
                  </div>
                </div>
              )}{' '}
            </div>
          </div>
        </div>        
      </div>
    );
  }
}

export default OfferTabItem;

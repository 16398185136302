import React, { Component } from 'react';
import styles from './_confirm-bank-account.module.scss';

class ConfirmBankAccount extends Component {
  _onClick = () => {
    this.props.onLinkAccount && this.props.onLinkAccount();
  };

  render() {
    const { bankAccountNumber, bankName, branchName, city } = this.props;

    return (
      <>
        <div className={styles['cb-card']}>
          <div className={styles['cb-card-content']}>
            <div className={styles['cb-card-content-row']}>
              <div className={styles['cb-card-key']}>Bank account no:</div>
              <div className={styles['cb-card-value']}>{bankAccountNumber}</div>
            </div>
            <div className={styles['cb-card-content-row']}>
              <div className={styles['cb-card-key']}>Bank name:</div>
              <div className={styles['cb-card-value']}>{bankName}</div>
            </div>
            <div className={styles['cb-card-content-row']}>
              <div className={styles['cb-card-key']}>Bank branch:</div>
              <div className={styles['cb-card-value']}>{branchName}</div>
            </div>
            <div className={styles['cb-card-content-row']}>
              <div className={styles['cb-card-key']}>Bank city:</div>
              <div className={styles['cb-card-value']}>{city}</div>
            </div>
          </div>
          <img src="img/guard-icon.svg" alt="guard-icon" className={styles['cb-guard-icon-img']} />
          <img
            src="img/ok-icon-mobile.svg"
            alt="guard-icon"
            className={styles['cb-guard-icon-mobile-img']}
          />
        </div>
        <button className={styles['link-account']} onClick={this._onClick}>
          I want to link another account
        </button>
      </>
    );
  }
}

export default ConfirmBankAccount;

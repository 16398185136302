import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik, FieldArray } from 'formik';
import * as yup from 'yup';
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { notify } from 'react-notify-toast';
import { TextInputOne, InputFileFour, TextInputTwo, InputSwitch } from 'src/components/form-inputs';
import { ACTION_TYPES } from 'src/constants';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Convert } from 'src/helpers';
import { ButtonMain, Heading } from 'src/components/theme';
import CreateAnotherProductModal from './create-another-product-modal';
import PreviewImages from '../preview-images';
import styles from './_productdetailsedit.module.scss';

const UPLOAD_IMAGES_CONFIG = {
  MAX_IMAGES: 5, // Should be greater than 0
  IMAGE_SIZE: 1, // In Mb
};

class ProductDetailsEdit extends Component {
  state = {
    loading: false,
    showCreateAnotherModal: false,
    // imageDataUris: []
    isGST: false,
    isTCS: false,
    createdProduct: {},
    isImageUploading: false,
  };

  _validationSchema = yup.object().shape(
    {
      gst: yup.number().max(100).min(1),
      tcs: yup.number().max(100).min(1),
      product_name: yup.string().required('Please enter name of the product'),
      amount: yup.string().required('Please enter the product amount.'),
      // images: yup.array()
      //   .of(
      //     yup.object().shape({
      //       file: yup.mixed()
      //       .test(
      //         'imaegSize',
      //         'Image size is large',
      //         (val) => {
      //           console.log(val.size, 'test 1')
      //           return val?.size < (5 * 1000 * 1000)
      //         }
      //       )
      //       .test(
      //         'imageType',
      //         'Image type is not valid',
      //         (val) =>  {
      //           console.log(val.type, 'test 2');
      //           return ['image/jpg', 'image/jpeg', 'image/png'].includes(val?.type)
      //         }
      //       )
      //     })
      //   ),
      images: yup.mixed().test('imageValidation', 'Image size/format not valid', (val) => {
        const imageResults = val.map(
          ({ file }) =>
            file.size < 5 * 1000 * 1000 &&
            ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type),
        );
        // console.log(imageResults);
        return imageResults.indexOf('false') == -1 ? 1 : 0;
      }),
      // image_link: yup.string().when(['images','image_urls'], {
      //   is: (images, image_urls) => !images.length && !image_urls.length,
      //   then: yup.string().url('Please enter a valid url').required('Please enter a url or upload an image.')
      // }),
      text_fields: yup.array().of(
        yup.object().shape({
          heading: yup.string().required('Please enter the heading'),
          body: yup.mixed().test('body', 'Please enter some text in the body.', (val) => {
            const value =
              (val &&
                val.getCurrentContent().hasText() &&
                JSON.stringify(convertToRaw(val.getCurrentContent()))) ||
              null;
            return value != null ? 1 : 0;
          }),
        }),
      ),
    },
    ['image', 'image_urls'],
  );

  componentDidMount() {
    this.requestImageUploading = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD, () =>
      this.setState({ isImageUploading: true }),
    );
    this.imageUploadingSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD_SUCCESS,
      (data) => {
        this.formik.setFieldValue('image_urls', [
          ...this.formik.values?.image_urls,
          { ...data, isTempImage: true },
        ]);
        this.setState({ isImageUploading: false });
      },
    );

    this.imageUploadingFailed = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_IMAGE_UPLOAD_FAILED, () =>
      this.setState({ isImageUploading: false }),
    );

    this.requestAdminProductUpdate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_UPDATE, () =>
      this.setState({ loading: true }),
    );
    this.adminProductUpdateSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_UPDATE_SUCCESS, () => {
      this.setState({ loading: false });
      notify.show('Product updated successfully', 'success');
    });
    this.adminProductUpdateFailed = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_UPDATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminProductCreate = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_CREATE, () =>
      this.setState({ loading: true }),
    );

    this.adminProductCreateSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_PRODUCT_CREATE_SUCCESS,
      (response) => {
        this.showCreateAnotherModal();
        this.setState({ loading: false, createdProduct: response?.product });
      },
    );

    this.adminProductCreateFailed = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_CREATE_FAILED, () =>
      this.setState({ loading: false }),
    );

    const getGst =
      this.props?.product?.taxes &&
      this.props?.product?.taxes.length > 0 &&
      this.props?.product?.taxes.find(({ taxFieldName }) => taxFieldName === 'gst');
    const getTcs =
      this.props?.product?.taxes &&
      this.props?.product?.taxes.length > 0 &&
      this.props?.product?.taxes.find(({ taxFieldName }) => taxFieldName === 'tcs');
    if (getGst?.percentage) {
      this.formik.setFieldValue('gst', getGst?.percentage);
    }
    if (getTcs?.percentage) {
      this.formik.setFieldValue('tcs', getTcs?.percentage);
    }

    if (!this.props.isNew) {
      this.setState({
        isGST: Boolean(getGst?.percentage && Number(getGst?.percentage) > 0),
      });
      this.setState({
        isTCS: Boolean(getTcs?.percentage && Number(getTcs?.percentage) > 0),
      });
    }
  }

  componentWillUnmount() {
    this.requestImageUploading();
    this.imageUploadingSuccess();
    this.imageUploadingFailed();
    this.requestAdminProductUpdate();
    this.adminProductUpdateSuccess();
    this.adminProductUpdateFailed();
    this.requestAdminProductCreate();
    this.adminProductCreateSuccess();
    this.adminProductCreateFailed();
  }

  closeCreateAnotherModal = () => {
    this.setState({ showCreateAnotherModal: false }, this.clearFormData);
  };

  clearFormData = () => {
    if (this.formik) {
      this.formik.resetForm();
      this.formik.setFieldValue('product_name', '');
    }
  };

  handleCreateOffer = () => {
    const { history } = this.props;
    const { createdProduct } = this.state;
    this.closeCreateAnotherModal();
    if (createdProduct?.uuid) {
      history.push(`/admin/products/offers/new?selectedProduct=${createdProduct?.uuid}`);
    } else {
      history.push('/admin/products/offers/new');
    }
  };

  showCreateAnotherModal = () => {
    this.setState({ showCreateAnotherModal: true }, () =>
      setTimeout(() => this.redirectAfterSuccess(), 2000),
    );
  };

  redirectAfterSuccess = () => {};

  _handleSubmit = ({ uuid, formData }) => {
    if (this.props.isNew) {
      this.props.requestAdminProductCreate(formData);
    } else {
      this.props.requestAdminProductUpdate({ uuid, data: formData });
    }
  };

  _getEditorState = () => {
    const textFields =
      this.props.product?.textFields &&
      this.props.product?.textFields.length > 0 &&
      this.props.product?.textFields.map((textField) => {
        const textFieldBody =
          textField.body && textField.body != 'null'
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(stateToHTML(convertFromRaw(JSON.parse(textField.body)))),
                ),
              )
            : EditorState.createEmpty();
        return {
          ...textField,
          body: textFieldBody,
        };
      });

    return textFields;
  };

  _prepareImageDataUri = (images) => {
    let promises = Object.values(images).map((image) =>
      Convert.toDataUri(image).then((dataUri) => ({ src: dataUri, file: image })),
    );

    Promise.all(promises)
      .then((results) => {
        if (
          results.length > UPLOAD_IMAGES_CONFIG.MAX_IMAGES ||
          this.formik.values.image_urls.length + results.length > UPLOAD_IMAGES_CONFIG.MAX_IMAGES
        ) {
          notify.show(
            `You can upload a maximum of ${UPLOAD_IMAGES_CONFIG.MAX_IMAGES} images`,
            'error',
          );
          this.formik.setFieldValue('images', [...this.formik.values.images]);
        } else {
          results.forEach((imageFile) => {
            if (imageFile && imageFile.file.size > UPLOAD_IMAGES_CONFIG.IMAGE_SIZE * 1024 * 1024) {
              notify.show(
                `Product image file cannot exceed ${UPLOAD_IMAGES_CONFIG.IMAGE_SIZE}mb`,
                'error',
              );
              document.getElementById('images').value = '';
            } else {
              let formData = new FormData();
              formData.append(`attachment[image]`, imageFile.file);
              this.props.adminProductImageUpload(formData);
              this.formik.setFieldValue('images', [...this.formik.values.images, ...results]);
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleGST = () => {
    const { isGST } = this.state;
    this.setState({ isGST: !isGST });
  };

  handleTCS = () => {
    const { isTCS } = this.state;
    this.setState({ isTCS: !isTCS });
  };

  render() {
    const { product = {}, handleEditState } = this.props;
    const { loading, showCreateAnotherModal, isGST, isTCS, isImageUploading } = this.state;

    return (
      <>
        <Formik
          innerRef={(ref) => (this.formik = ref)}
          initialValues={{
            product_name: product?.productName || '',
            amount: product?.amount || '',
            images: [],
            image_urls: product?.imageUrls || [],
            gst: product?.taxes?.gst || '',
            tcs: product?.taxes?.tcs || '',
            is_locked_quantity: product?.isLockedQuantity || false,
            quantity_text: product?.quantityText || '',
            // image_link: product?.imageLink || '',
            text_fields: this._getEditorState() || [{ heading: '', body: '' }],
          }}
          validationSchema={this._validationSchema}
          onSubmit={(values) => {
            let formValues = { ...values };

            const textFields = formValues.text_fields || [];
            const gst = formValues.gst;
            const tcs = formValues.tcs;
            let amount = formValues.amount.replace(/,/g, '');

            delete formValues.images;
            delete formValues.text_fields;
            delete formValues.amount;
            delete formValues.gst;
            delete formValues.tcs;

            let formData = new FormData();

            if (this.formik.values?.image_urls && this.formik.values?.image_urls.length > 0) {
              Object.values(this.formik.values?.image_urls).forEach((image) => {
                if (image?.isTempImage) {
                  formData.append(`product[attachment_ids][]`, image.id);
                }
              });
            }

            Object.keys(formValues).map((key) => formData.append(`product[${key}]`, values[key]));

            const textFieldsData = textFields.map(
              (info) =>
                info.body &&
                info.body.getCurrentContent().hasText() && {
                  ...info,
                  body: JSON.stringify(convertToRaw(info.body.getCurrentContent())),
                },
            );

            // formData.append("product[image]", values.image?.value)
            formData.append('product[amount]', amount);
            if (gst && this.state.isGST) {
              formData.append('product[taxes][][tax_field_name]', 'gst');
              formData.append(
                'product[taxes][][percentage]',
                Math.round((Number(gst) + Number.EPSILON) * 100) / 100,
              );
            }

            if (tcs && this.state.isTCS) {
              formData.append('product[taxes][][tax_field_name]', 'tcs');
              formData.append(
                'product[taxes][][percentage]',
                Math.round((Number(tcs) + Number.EPSILON) * 100) / 100,
              );
            }
            if (!this.state.isGST && !this.state.isTCS) {
              formData.append('product[taxes]', []);
            }
            formData.append('product[text_fields]', JSON.stringify(textFieldsData));

            // for logging formdata

            // for (let property of formData.entries()) {
            //   console.log(property[0], property[1]);
            // }

            this._handleSubmit({ uuid: product?.uuid, formData });
          }}
        >
          {(formikProps) => {
            const { values, setFieldValue, errors, submitCount } = formikProps;

            return (
              <Form>
                <Card className={styles.card}>
                  <Card.Header className={styles.cardHeader}>
                    <Heading variant="sub-heading" className={styles.cardHeaderTitle}>
                      {this.props.isNew ? '' : 'Edit '}
                      Product Details
                    </Heading>
                    {!this.props.isNew && (
                      <span
                        className={`${styles.anchorHand} ${styles.toggleButton}`}
                        onClick={() => handleEditState()}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </span>
                    )}
                  </Card.Header>
                  <>
                    <Card.Body className={styles.cardBody}>
                      <div className={styles.cardSection}>
                        <Row className={styles.cardRow}>
                          <Col md={6} className={styles.cardCol}>
                            <TextInputOne
                              labelsize="large"
                              label="Name of Product"
                              name="product_name"
                              id="product_name"
                              type="text"
                              containerstyles={{
                                marginBottom: '1rem',
                              }}
                            />
                          </Col>
                          <Col md={6}>
                            <TextInputOne
                              labelsize="large"
                              label="Base Amount"
                              name="amount"
                              id="amount"
                              type="text"
                              containerstyles={{
                                marginBottom: '1rem',
                              }}
                              value={Convert.toCurrencyValue(values.amount, {
                                currencyDisplay: 'none',
                                allowCurrencyOnly: false,
                              })}
                              onChange={(e) => {
                                const currencyValue = Convert.toCurrencyValue(e.target.value, {
                                  currencyDisplay: 'none',
                                  allowCurrencyValue: false,
                                });
                                setFieldValue('amount', currencyValue);
                              }}
                            />
                          </Col>
                          <Col md={12}>
                            <div className={styles['taxes']}>
                              <div className={styles['label']}>Taxes</div>
                              <div className={styles['checkboxes-group']}>
                                <div className={styles['checkbox']}>
                                  <div className={styles['tick-box']} onClick={this.handleGST}>
                                    {isGST && <img src="/img/checkbox.png" alt="checkox" />}
                                  </div>
                                  <div className={styles['checkbox-label']}>GST</div>
                                  {isGST && (
                                    <>
                                      <div className={styles['checkbox-text']}>
                                        <TextInputOne
                                          labelsize="large"
                                          name="gst"
                                          id="gst"
                                          min="0"
                                          type="text"
                                        />
                                      </div>
                                      <div className={styles['checkbox-percentage']}>%</div>
                                    </>
                                  )}
                                </div>
                                <div className={styles['checkbox']}>
                                  <div className={styles['tick-box']} onClick={this.handleTCS}>
                                    {isTCS && <img src="/img/checkbox.png" alt="checkox" />}
                                  </div>
                                  <div className={styles['checkbox-label']}>TCS</div>
                                  {isTCS && (
                                    <>
                                      <div className={styles['checkbox-text']}>
                                        <TextInputOne
                                          labelsize="large"
                                          name="tcs"
                                          id="tcs"
                                          type="text"
                                        />
                                      </div>
                                      <div className={styles['checkbox-percentage']}>%</div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <div className={styles['locked-quantity']}>
                            <div className={styles['locked-quantity-wrapper']}>
                              <Row>
                                <Col sm={4} className={styles['label']}>
                                  Is the No. of Pax fixed?
                                </Col>
                                <Col sm={1} className={styles['is-locked-quantity']}>
                                  <InputSwitch
                                    label={`${values.is_locked_quantity ? 'YES' : 'NO'}`}
                                    name="is_locked_quantity"
                                    id="is_locked_quantity"
                                    labelPosition="right"
                                    checked={values.is_locked_quantity}
                                    containerstyles={{
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  />
                                </Col>
                                <Col sm={1} />
                                {values.is_locked_quantity && (
                                  <Col sm={6}>
                                    <div className={styles['quantity-text']}>
                                      <TextInputTwo
                                        label="Enter No. of Pax"
                                        name="quantity_text"
                                        id="quantity_text"
                                        containerstyles={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      />
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>
                          <Col md={6} className={styles.cardCol}>
                            <Col
                              md={12}
                              style={{
                                marginBottom: '0.2rem',
                              }}
                            >
                              <label>
                                Image
                                <span className={styles.helpText}>(Upload upto 5 images)</span>
                              </label>
                            </Col>
                            <Col md={6} className={styles.cardCol}>
                              <InputFileFour
                                label="Upload Images"
                                name="images"
                                id="images"
                                type="file"
                                multiple="multiple"
                                onChange={(e) => {
                                  const files = e.target.files;
                                  this._prepareImageDataUri(files);
                                }}
                              />
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PreviewImages
                              imageUrls={values.image_urls}
                              // imageDataUris={values.images}
                              formikRef={this.formik}
                              isImageUploading={isImageUploading}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </>
                </Card>
                <Card className={styles.card}>
                  <Card.Header className={styles.cardHeader}>
                    <Heading variant="sub-heading">
                      {this.props.isNew ? 'Add ' : 'Edit '}
                      Product Details
                    </Heading>
                  </Card.Header>
                  <>
                    <Card.Body className={styles.cardBody}>
                      <div className={styles.cardSection}>
                        <FieldArray
                          name="text_fields"
                          render={(arrayHelpers) => (
                            <>
                              <Row>
                                <Col md={12} className="pt-3">
                                  {values.text_fields.map((infoField, index) => (
                                    <div key={index} className={styles.contentWrapper}>
                                      <div className={styles.titleContainer}>
                                        <div className={styles.titleInput}>
                                          <TextInputOne
                                            labelsize="large"
                                            label="Title"
                                            name={`text_fields[${index}].heading`}
                                            id={`text_fields[${index}].heading`}
                                            placeholder="Enter a title for this section"
                                            containerstyles={{
                                              marginBottom: '1rem',
                                            }}
                                          />
                                        </div>
                                        <div className={styles.titleAction}>
                                          <span
                                            className={styles.delete}
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            Delete
                                          </span>
                                        </div>
                                      </div>
                                      <div className={styles['label']}>Content</div>
                                      <Editor
                                        editorState={values.text_fields[index].body}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName={styles['editorClassName']}
                                        onEditorStateChange={(val) =>
                                          setFieldValue(`text_fields[${index}].body`, val)
                                        }
                                      />
                                      {errors?.text_fields &&
                                        errors?.text_fields.length > 0 &&
                                        errors?.text_fields[index]?.body &&
                                        submitCount > 0 && (
                                          <div className={styles['error']}>Required content</div>
                                        )}
                                    </div>
                                  ))}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className={styles.addMoreContainer}>
                                    <span
                                      className={styles.addMore}
                                      onClick={() => arrayHelpers.push({ heading: '', body: '' })}
                                    >
                                      Add Section
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        />
                      </div>
                    </Card.Body>
                  </>
                </Card>
                <ButtonMain disabled={loading} type="submit" align="center">
                  {loading ? 'Loading...' : 'Save Details'}
                </ButtonMain>
                {showCreateAnotherModal && (
                  <CreateAnotherProductModal
                    productName={values?.product_name || ''}
                    showCreateAnotherModal={showCreateAnotherModal}
                    closeCreateAnotherModal={this.closeCreateAnotherModal}
                    handleCreateOffer={this.handleCreateOffer}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withRouter(ProductDetailsEdit);

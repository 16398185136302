import { connect } from 'react-redux';
import { requestAdminAgentCreate } from 'src/redux/actions';
import AdminPartnerUser from './AdminPartnerUser'

function mapStateToProps(state) {
  return{
    admin: state.admin,
    adminAgents: state.adminAgents
  }
}

export default connect(mapStateToProps, {
  requestAdminAgentCreate
})(AdminPartnerUser);

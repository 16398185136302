import { connect } from 'react-redux';
import {
  requestAdminProductCreate,
  requestAdminProductUpdate,
  adminProductImageUpload,
  requestAdminProductDetail,
} from 'src/redux/actions';
import AddEditProduct from './addEditProduct';

function mapStateToProps(state) {
  return {
    products: state.adminProducts.products,
    product: state.adminProducts.product,
  };
}

export default connect(mapStateToProps, {
  requestAdminProductUpdate,
  requestAdminProductCreate,
  adminProductImageUpload,
  requestAdminProductDetail,
})(AddEditProduct);

import React, { Component } from 'react';
import { InputFileTwo } from 'src/components/form-inputs';
import { ApiRequest } from 'src/helpers';
import cx from 'classnames';
import { ACTION_TYPES } from 'src/constants';
import EventBus from 'eventing-bus';
import { notify } from 'react-notify-toast';
import styles from './upload-pan.module.scss';

class UploadPan extends Component {
  state = {
    loading: false,
    panCardParams: {},
    frontPanLoading: false,
    isPanUploadFailed: false,
  };

  fileInput = null;

  componentDidMount() {
    this.extractPanCardRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_PAN_CARD_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.extractPanCardRequestSuccessSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_PAN_CARD_SUCCESS,
      this._onExtractedPanCard,
    );
    this.extractPanCardRequestFailureSubscription = EventBus.on(
      ACTION_TYPES.EXTRACT_PAN_CARD_FAILED,
      () => this.setState({ loading: false }),
    );

    this.uploadPanCardRequestSubscription = EventBus.on(ACTION_TYPES.UPLOAD_PAN_CARD_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.uploadPanCardSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_PAN_CARD_SUCCESS,
      this._onPanCardUploaded,
    );
    this.uploadPanCardFailureSubscription = EventBus.on(ACTION_TYPES.UPLOAD_PAN_CARD_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.uploadFormRequestSubscription = EventBus.on(ACTION_TYPES.UPLOAD_FORM_REQUEST, () =>
      this.setState({ loading: true }),
    );
    this.uploadFormSuccessSubscription = EventBus.on(
      ACTION_TYPES.UPLOAD_FORM_SUCCESS,
      this._onFormUploaded,
    );
    this.uploadFormFailureSubscription = EventBus.on(ACTION_TYPES.UPLOAD_FORM_FAILED, () =>
      this.setState({ loading: false }),
    );

    const { panCardParams } = this.state;
    panCardParams.image_url = this.props?.kyc?.panCard?.image_url;

    this.setState({ panCardParams, frontPanLoading: false });
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.extractPanCardRequestSuccessSubscription();
    this.extractPanCardRequestFailureSubscription();

    this.uploadPanCardRequestSubscription();
    this.uploadPanCardSuccessSubscription();
    this.uploadPanCardFailureSubscription();

    this.uploadFormRequestSubscription();
    this.uploadFormSuccessSubscription();
    this.uploadFormFailureSubscription();
  };

  _onPanFrontImageUpdated = (event) => {
    this.setState({ frontPanLoading: true });
    const [file] = event.target.files;

    ApiRequest.uploadToSignzy(file).then((res) => {
      const { panCardParams } = this.state;
      panCardParams.image_url = res.data.file.directUrl;

      this.setState({ panCardParams, frontPanLoading: false });
    });
  };

  _onExtractedPanCard = (res) => {
    if (res.name && res.dateOfBirth && res.panNumber && res.fathersName) {
      const panCard = {
        name: res.name,
        date_of_birth: res.dateOfBirth,
        pan_number: res.panNumber,
        fathers_name: res.fathersName,
        image_url: this.state.panCardParams.image_url,
      };
      // eslint-disable-next-line
      this.setState({ panCardParams: panCard }, () => {
        this.props.updateKycParams({ panCard });
        this.props.uploadPanCardRequest(panCard);
      });
    } else {
      // eslint-disable-next-line
      this.setState({ loading: false, panCardParams: {}, isPanUploadFailed: true }, () => {
        notify.show(
          `Document extraction failed, make sure the pan document is valid, readable and doesn't have blur, glare, or dim lighting.`,
          'error',
          5000,
        );
      });
    }
  };

  _onSubmitPanCard = () => {
    this.props.extractPanCardRequest({ image_url: this.state.panCardParams.image_url });
  };

  _onPanCardUploaded = () => {
    const { kyc } = this.props;
    const { userInfo } = kyc;

    this.props.uploadFormRequest({
      gender: userInfo.gender,
      marital_status: userInfo.maritalStatus,
      occupation_description: userInfo.occupationDescription,
      occupation_code: userInfo.occupation,
      annual_income: userInfo.salary,
    });
  };

  _onFormUploaded = () => {
    this.props.onGoto('address');
  };

  render() {
    const { panCardParams, isPanUploadFailed } = this.state;

    return (
      <div className={styles['another-account-overlay']}>
        <div className={cx(styles['header'], styles['mobile'])}>
          <div className={styles['inner-header']}>
            <div className={styles['sub-inner-header']}>
              <div className={styles['title']}>Complete your KYC to start saving</div>
              {/* <div className={styles['price']}>Rs. 10,000</div>
              <div className={styles['note']}>Total amount to be saved</div>
              <div className={styles['amount']}>
                <img src="/img/ok-2.svg" alt="ok" /> Booking amount paid : Rs. 2000
              </div> */}
            </div>
          </div>
        </div>

        <div className={cx(styles['progress-bar'], styles['mobile'])}>
          <div className={styles['active-progress-bar']} style={{ width: `${80}%` }} />
        </div>
        <div className={styles['another-account']}>
          <div className={styles['pay-content']}>
            <div className={styles['title']}>Verify your KYC Start Saving</div>
            <div className={styles['line']} />
            <div className={styles['sub-title']}>UPLOAD YOUR PAN CARD</div>
            <div className={styles['sub-title-light']}>
              Upload photos of the front and back of PAN card below
            </div>
            <div className={cx(styles['mobile-space'], styles['mobile'])}>
              <div className={cx(styles['line-mob'])} />
            </div>
            <div className={cx('pb-3 pt-4', styles['form-box'], styles['mobile-space'])}>
              <InputFileTwo
                ref={(ref) => (this.fileInput = ref)}
                label="Front of your PAN card (.JPG or.PNG)"
                name="panFront"
                id="panFront"
                accept="image/png, image/jpeg"
                loading={this.state.frontPanLoading}
                onChange={this._onPanFrontImageUpdated}
                labelStyle={styles['label-style']}
                isPanUploadFailed={isPanUploadFailed}
                imageSrc={this.props?.kyc?.panCard?.image_url}
              />
            </div>
            <div className={cx('pb-3', styles['form-box'], styles['mobile-space'])}>
              {/* <InputFileTwo
                label="Back of your PAN card (.JPG or.PNG)"
                name="panBack"
                id="panBack"
                accept="image/png, image/jpeg"
                labelStyle={styles['label-style']}
                loading={this.state.backPanLoading}
              /> */}
            </div>
            <div className={styles['pan-note']}>
              Your PAN card number will become your investor ID. For any future investments all you
              need is your PAN number.
            </div>
          </div>
          <div className={styles['spacer']} />

          <button
            className={styles['footer']}
            type="submit"
            disabled={!panCardParams.image_url || this.state.loading}
            onClick={this._onSubmitPanCard}
          >
            {this.state.loading ? 'LOADING...' : 'NEXT'}
          </button>
        </div>
      </div>
    );
  }
}

export default UploadPan;

import {
  updateSavingPlan,
  requestCreateMandate,
} from 'src/redux/actions';
import { connect } from 'react-redux';

import Sip from './sip';

function mapStateToProps(state) {
  return {
    user: state.user,
    savingPlan: state.savingPlan,
    product: state.product,
    partner: state.partner,
  };
}

export default connect(mapStateToProps, {
  updateSavingPlan,
  requestCreateMandate,
})(Sip);

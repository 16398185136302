import React from 'react'
import { InputFileThree } from 'src/components/form-inputs';
import cx from 'classnames';
import styles from './_review-video.module.scss';

export default function ReviewVideo(props) {
  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <span className={styles.heading}>
          {props?.heading}
        </span>
      </div>
      <div className={styles.inputContainer}>
        <InputFileThree
          name="video"
          id="video"
          ctaTitle="Re-take"
          source={props.video?.video_url}
          onClick={() => props.onClick(4)}
        />
      </div>                 
    </div>
  )
}

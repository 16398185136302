import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import styles from './_timer.module.scss';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this._millisecondsToTime(props.counterInSeconds),
      counterInSeconds: props.counterInSeconds,
      progress: 100,
    };
    this.interval = null;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.counterInSeconds === 0) {
        const text = this._millisecondsToTime(0);
        const progress = (100 / this.props.counterInSeconds) * this.state.counterInSeconds;

        this.setState({ text, progress });

        clearInterval(this.interval);
        this.props.onComplete && this.props.onComplete();
      } else {
        const { counterInSeconds } = this.state;
        const text = this._millisecondsToTime(counterInSeconds);
        const progress = (100 / this.props.counterInSeconds) * this.state.counterInSeconds;
        this.setState({ text, progress, counterInSeconds: counterInSeconds - 1 });
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  _millisecondsToTime = (milliseconds) =>
    (milliseconds - (milliseconds %= 60)) / 60 + (milliseconds > 9 ? ':' : ':0') + milliseconds;

  render() {
    return (
      <div
        className={styles['app-timer']}
        style={{ width: this.props.size, height: this.props.size }}
      >
        <CircularProgressbar
          value={this.state.progress}
          text={`${this.state.text}`}
          background
          styles={{
            path: {
              stroke: '#F9C301',
            },
            trail: {
              stroke: '#505050',
            },
            text: {
              fill: '#F9C301',
            },
            background: {
              fill: '#333',
            },
          }}
        />
      </div>
    );
  }
}

export default Timer;

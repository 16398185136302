import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import './_bootstrap-modal.module.scss';

class BootstrapModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  _onClose = () => {
    this.setState({ showModal: false });
  };

  _onDismiss = () => {
    this.props.onClose && this.props.onClose();
  };

  _onExited = () => {
    this.props.onClosed && this.props.onClosed();
  };

  _onHide = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    return (
      <CSSTransition
        in={this.props.show}
        timeout={300}
        unmountOnExit
        onEntering={() => this.setState({ showModal: true })}
        onExiting={() => this.setState({ showModal: false })}
        onExited={this._onExited}
      >
        <Modal show={this.state.showModal} onHide={this._onHide} className={this.props.className}>
          {this.props.children}
        </Modal>
      </CSSTransition>
    );
  }
}

export default BootstrapModal;

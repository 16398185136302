import React, { useMemo } from 'react';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Convert } from 'src/helpers';
import { DataTable, TextOne, LinkOne } from '../../../components';
import './_products-table.style.scss';

const ProductsTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: '#ffffff',
      borderBottomColor: '#f3f3f3',
    },
    tableHeader: {
      backgroundColor: '#f3f3f3',
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <TextOne variant="head">Name</TextOne>,
        accessor: 'productName',
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <TextOne title={`${window.location.origin}/admin/marketing/products/${original?.uuid}`}>
            <LinkOne to={`/admin/marketing/products/edit/${original?.uuid}`}>
              <div
                style={{
                  textOverflow: 'ellipsis',
                  width: '300px',
                  whiteSpace: 'pre',
                  overflow: 'hidden',
                }}
              >
                {value}
              </div>
            </LinkOne>
          </TextOne>
        ),
      },
      {
        Header: <TextOne variant="head">Base Amount</TextOne>,
        accessor: 'amount',
        disableSortBy: false,
        Cell: ({ value }) => <TextOne>{Convert.toCurrencyValue(value)}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Created on</TextOne>,
        accessor: 'createdAt',
        disableSortBy: false,
        Cell: ({ value }) => <TextOne>{moment(value, 'DDMMYYYY').format('Do MMMM, YYYY')}</TextOne>,
      },
      {
        Header: <TextOne variant="head">Generate Link</TextOne>,
        accessor: 'id',
        disableSortBy: true,
        align: 'center',
        styles: { textAlign: 'center' },
        Cell: ({ row: { original } }) => (
          <TextOne
            title={`${window.location.origin}/admin/marketing/products/${original?.uuid}/link`}
            style={{ textAlign: 'center' }}
          >
            <LinkOne to={`/admin/marketing/products/${original?.uuid}/link`}>
              <FontAwesomeIcon icon={faLink} />
            </LinkOne>
          </TextOne>
        ),
      },
    ],
    [],
  );
  return (
    <DataTable
      columns={columns}
      data={props.data || []}
      tableStyles={tableStyles}
      paginationConfig={paginationConfig}
      handlePaginationChange={props.handlePaginationChange}
    />
  );
};

export default ProductsTable;

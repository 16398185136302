import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function verifyUser(uuid) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.USERS}/${uuid}`,
  }).then((response) => response.data.user);
}

export function resendOTP(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.RESEND_OTP}`,
    data: params,
  }).then((response) => response.data);
}

export function updateUser(params) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.USER}`,
    data: params,
  }).then((response) => response.data.user);
}

export function signInUser(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.SIGN_IN}`,
    data: params,
  }).then((response) => ({ user: response.data.user, authCredentials: response.headers }));
}

export function verifyOTP(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.VERIFY_OTP}`,
    data: params,
  }).then((response) => response.data.user);
}

export function validateEmail(email) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.VALIDATE_EMAIL}?email=${email}`,
  }).then((response) => response.data);
}

export function requestSubmitInvestorKYC(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.KYC_SUBMIT_INVESTOR_KYC,
    data: params,
  }).then((response) => response.data.user);
}

import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import './_drawer-modal.scss';

class DrawerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  _onClose = () => {
    this.setState({ showModal: false });
  };

  _onDismiss = () => {
    this.props.onClose && this.props.onClose();
  };

  _onExited = () => {
    this.props.onClosed && this.props.onClosed();
  };

  _onHide = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    return (
      <CSSTransition
        in={this.props.show}
        timeout={300}
        unmountOnExit
        onEntering={() => this.setState({ showModal: true })}
        onExiting={() => this.setState({ showModal: false })}
        onExited={this._onExited}
      >
        <div className={cx('drawer-modal', this.props.className, { open: this.state.showModal })}>
          <div className="drawer-modal-wrapper">{this.props.children}</div>
        </div>
      </CSSTransition>
    );
  }
}

export default DrawerModal;

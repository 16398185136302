import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminUserDetail({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_USERS_LIST}/${uuid}`,
  }).then((response) => response.data);
}

export function adminUsersList({ page }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_USERS_LIST}?page=${page}`,
  }).then((response) => response.data);
}

export function adminUserPurchases({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_USER_PURCHASES}?uuid=${uuid}`,
  }).then((response) => response.data);
}

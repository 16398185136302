import { ACTION_TYPES } from 'src/constants';

export function requestPaymentLinks(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_LINKS_REQUEST,
    payload,
  };
}

export function requestPaymentLinksSuccess(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_LINKS_SUCCESS,
    payload,
  };
}

export function requestPaymentLinksFailed(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_LINKS_FAILED,
    payload,
  };
}

export function requestPaymentLinksDetail(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_LINKS_DETAIL_REQUEST,
    payload,
  };
}

export function requestPaymentLinksDetailSuccess(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_LINKS_DETAIL_SUCCESS,
    payload,
  };
}

export function requestPaymentLinksDetailFailed(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_LINKS_DETAIL_FAILED,
    payload,
  };
}

export function requestPaymentExpireLink(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_EXPIRE_LINK_REQUEST,
    payload,
  };
}

export function requestPaymentExpireLinkSuccess(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_EXPIRE_LINK_SUCCESS,
    payload,
  };
}

export function requestPaymentExpireLinkFailed(payload) {
  return {
    type: ACTION_TYPES.PAYMENT_EXPIRE_LINK_FAILED,
    payload,
  };
}

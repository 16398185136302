import { takeEvery, call, put } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import {
  adminCollectionsSuccess,
  adminCollectionsFailed,
  createAdminCollectionsSuccess,
  createAdminCollectionsFailed,
  adminCollectionsDetailSuccess,
  adminCollectionsDetailFailed,
  adminCollectionsAcceptSuccess,
  adminCollectionsAcceptFailed,
  adminCollectionsConfirmSuccess,
  adminCollectionsConfirmFailed,
  userCollectionsDetailSuccess,
  userCollectionsDetailFailed,
} from 'src/redux/actions';
import {
  getCollections,
  createCollection,
  getCollectionDetail,
  acceptCollection,
  confirmCollection,
  getUserCollectionDetail,
} from '../api/admin-collections.api';

export function* watchAdminCollectionsRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_COLLECTION_LIST, requestAdminCollections);
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_COLLECTION_LIST, requestCreateAdminCollections);
  yield takeEvery(ACTION_TYPES.REQUEST_COLLECTION_DETAIL, requestAdminCollectionsDetail);
  yield takeEvery(ACTION_TYPES.REQUEST_COLLECTION_ACCEPT, requestAdminCollectionsAccept);
  yield takeEvery(ACTION_TYPES.REQUEST_COLLECTION_CONFIRM, requestAdminCollectionsConfirm);
  yield takeEvery(ACTION_TYPES.REQUEST_USER_COLLECTION_DETAIL, requestUserCollectionsDetail);
}

function* requestAdminCollectionsAccept(action) {
  try {
    const collection = yield call(acceptCollection, action.payload);
    yield put(adminCollectionsAcceptSuccess(collection));
  } catch (e) {
    yield put(adminCollectionsAcceptFailed(e));
  }
}

function* requestAdminCollectionsConfirm(action) {
  try {
    const collection = yield call(confirmCollection, action.payload);
    yield put(adminCollectionsConfirmSuccess(collection));
  } catch (e) {
    yield put(adminCollectionsConfirmFailed(e));
  }
}

function* requestAdminCollections() {
  try {
    const collection = yield call(getCollections);
    yield put(adminCollectionsSuccess(collection.collectRequests));
  } catch (e) {
    yield put(adminCollectionsFailed(e));
  }
}

function* requestCreateAdminCollections(action) {
  try {
    const collection = yield call(createCollection, action.payload);
    yield put(createAdminCollectionsSuccess(collection.collectRequest));
  } catch (e) {
    yield put(createAdminCollectionsFailed(e));
  }
}

function* requestAdminCollectionsDetail(action) {
  try {
    const collection = yield call(getCollectionDetail, action.payload);
    yield put(adminCollectionsDetailSuccess(collection.collectRequest));
  } catch (e) {
    yield put(adminCollectionsDetailFailed(e));
  }
}

function* requestUserCollectionsDetail(action) {
  try {
    const collection = yield call(getUserCollectionDetail, action.payload);
    yield put(userCollectionsDetailSuccess(collection.collectRequest));
  } catch (e) {
    yield put(userCollectionsDetailFailed(e));
  }
}

import { Modal } from 'react-bootstrap';
import { ButtonOne } from 'src/components';
import React, { Component } from 'react';
import styles from './_create-another-product-modal.module.scss';
import './_modal.scss';

class CreateAnotherProductModal extends Component {
  render() {
    const { showCreateAnotherModal, closeCreateAnotherModal, handleCreateOffer, productName } =
      this.props;
    return (
      <div>
        <Modal show={showCreateAnotherModal} size="lg">
          <div className={styles['close-btn']}>
            <span onClick={closeCreateAnotherModal}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </span>
          </div>
          <div className={styles['alert-icon']}>
            <img src="/img/new-offer-ok.svg" alt="alert-icon" />
          </div>
          <div className={styles['title']}>Your product ‘{productName}’ has been created</div>
          {/* <div className={styles['group-btns']}>
            <div className={styles['inner-btn']}>
              <ButtonOne
                text="Ok"
                type="submit"
                onClick={handleCreateOffer}
              />
            </div>
            {/* <div className={styles['inner-btn']}>
              <ButtonOne text="Send offer" type="submit" onClick={closeCreateAnotherModal} />
            </div> */}
          {/* </div> */}
          <div className="create-offer-btn">
            <ButtonOne text="create an offer for this product?" onClick={handleCreateOffer} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default CreateAnotherProductModal;

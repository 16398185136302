import mixpanel from 'mixpanel-browser';
import _ from 'lodash';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || 'e1b8f94de4b70a555181caf2ae46d026';

mixpanel.init(MIXPANEL_TOKEN);

const Analytics = ({    
  selectLoggedInUser = () => null, 
  selectLogoutUser = () => null,    
  selectUserProfileData = () => null,       
  selectEventName = (action) => action.type,  
  selectProperties = () => null,  
  ignoreAction = () => null
}) => store => next => action => {  
  // Don't track falsy actions or actions that should be ignored    
  if (!action.type || ignoreAction(action)) {
    return next(action);
  }
  
  // Get store state; select distinct id for action & state
  const state = store.getState();     
  const identity = selectLoggedInUser(action, state);        
  const reset = selectLogoutUser(action, state);
  const eventName = selectEventName(action, state);    
  const properties = selectProperties(action, state);
  const userProfileData = selectUserProfileData(action, state);  

  // Track action event with mixpanel
  if(_.isArray(properties) || _.isString(properties)) {    
    mixpanel.track(eventName, { properties });
  } else {    
    mixpanel.track(eventName, properties);
  }

  // set distinctId, use this for login    
  if (identity) {         
    mixpanel.identify(identity)
  }

  // update user profile on login/update user information
  if (userProfileData) {                 
    mixpanel.people.set(userProfileData);
  }  

  // reset 
  if (reset) {      
    mixpanel.reset();
  }
  
  return next(action)
};

export { Analytics };
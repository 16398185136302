import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';

function CustomAreaChart(props) {
  const { data } = props;
  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <Area isAnimationActive={false} dataKey="unit" stroke="#8884d8" fill="#8884d8" />
        <Tooltip />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default CustomAreaChart;

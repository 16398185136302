import { connect } from 'react-redux';
import {
  requestValidateEmail,
  requestSubmitInvestorDetails,
  requestSubmitInvestorKYC,
  requestFullKycRegistration,
  createFolio,
  updateKycParams,
  updateUserRequest
} from '../../../redux/actions';
import DemographicInfo from './demographic-info';

function mapStateToProps(state) {
  return {
    user: state.user  
  };
}

export default connect(mapStateToProps, {
  requestValidateEmail,
  requestSubmitInvestorDetails,
  requestFullKycRegistration,
  requestSubmitInvestorKYC,
  createFolio,
  updateKycParams,
  updateUserRequest
})(DemographicInfo);

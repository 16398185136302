import React from 'react';
import cx from 'classnames'
import styles from './_text.module.scss'

function Text(props) {
  const {variant = 'section'} = props

  return (
    <div {...props} className={cx(styles[variant], props?.className)}>
      {props.children}
    </div>
  );
}

export default Text;
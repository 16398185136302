import { connect } from 'react-redux';
import ProtectedRoute from './protected-route';

function mapStateToProps({ credentials, user }) {
  const { userIdentityToken } = credentials;
  const { uuid } = user;
  
  return {    
    userIdentityToken,
    uuid,
    search: window.location.search
  };
}

export default connect(mapStateToProps, {})(ProtectedRoute);

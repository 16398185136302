import { ACTION_TYPES } from 'src/constants';

export function requestGeneratePayment(payload) {
  return {
    type: ACTION_TYPES.REQUEST_GENERATE_PAYMENT,
    payload,
  };
}

export function generatePaymentSuccess(payload) {
  return {
    type: ACTION_TYPES.GENERATE_PAYMENT_SUCCESS,
    payload,
  };
}

export function generatePaymentFailure(payload) {
  return {
    type: ACTION_TYPES.GENERATE_PAYMENT_FAILED,
    payload,
  };
}

export function createDepositRequest(payload) {
  return {
    type: ACTION_TYPES.CREATE_DEPOSIT_REQUEST,
    payload
  }
}

export function createDepositSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_DEPOSIT_SUCCESS,
    payload
  }
}

export function createDepositFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_DEPOSIT_FAILED,
    payload
  }
}

export function fetchDepositRequest(payload) {
  return {
    type: ACTION_TYPES.FETCH_DEPOSIT_REQUEST,
    payload
  }
}

export function fetchDepositSuccess(payload) {
  return {
    type: ACTION_TYPES.FETCH_DEPOSIT_SUCCESS,
    payload
  }
}

export function fetchDepositFailure(payload) {
  return {
    type: ACTION_TYPES.FETCH_DEPOSIT_FAILED,
    payload
  }
}

import { connect } from 'react-redux';
import { requestAdminCancelRequestList } from 'src/redux/actions';
import CancelRequests from './cancel-requests';

function mapStateToProps(state) {
  return {
    cancelRequests: state.adminCancelRequests?.cancelRequestsList,
  };
}

export default connect(mapStateToProps, {
  requestAdminCancelRequestList,
})(CancelRequests);

import React, { Component } from 'react';
import styles from './_input-text.module.scss';

class InputText extends Component {
  render() {
    return (
      <div className={styles.container}>
        <label className={styles.label}>{this.props.label || 'Label Text'}</label>
        <input {...this.props} className={styles.input}/>
      </div>
    );
  }
}

export default InputText;

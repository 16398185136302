import { connect } from 'react-redux';
import { requestAdminGoalDetail, requestAdminUserPurchases } from 'src/redux/actions';
import GoalDetails from './GoalDetails';

const mapStateToProps = (state) => ({
  goal: state.adminGoals.savingGoal,
});

export default connect(mapStateToProps, { requestAdminGoalDetail, requestAdminUserPurchases })(
  GoalDetails,
);

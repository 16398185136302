import { useField, useFormikContext } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';
import cx from 'classnames'
import { TextOne } from 'src/screens/v2/admin/components';
import styles from './_input-datepicker-two.module.scss'
import globalStyles from '../_styles.module.scss';

function InputDatepickerTwo({ label, ...props }) {
  const {setFieldValue} = useFormikContext()
  const [field, meta] = useField(props);

  return (
    <>
      <div className={globalStyles.inputLabelContainer}>
        <label htmlFor={props.id || props.name} className={globalStyles.inputLabel}>
          <TextOne>{label}</TextOne>
        </label>
      </div>
      <div className={styles.textInputContainer}>
        <DatePicker 
          {...field} 
          className={styles.input}
          selected={(field.value && new Date(field.value)) || null}
          onChange={val => {
            setFieldValue(field.name, val);
          }}
          {...props} 
          autoComplete="off"
        />
        {meta.touched && meta.error ? <div className={cx(globalStyles.error, styles.error)}>{meta.error}</div> : null}
      </div>
    </>
  );
}

export default InputDatepickerTwo;

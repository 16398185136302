import { Form, Formik, FieldArray } from 'formik';
import React, { Component } from 'react';
import { Card, Col, Container, Row, Modal, Tabs, Tab } from 'react-bootstrap';
import * as yup from 'yup';
import EventBus from 'eventing-bus';
import { InputSelectOne } from 'src/components/form-inputs';
import { Loader } from 'src/components';
import { ACTION_TYPES } from 'src/constants';
import { Heading, Label, AsyncSelectInput, ButtonMain } from 'src/components/theme';
import styles from './_email-templates.module.scss';
import './_modal.scss';

class EmailTemplates extends Component {
  codeInput = null;

  state = {        
    loading: false,
    templates: [],    
    offers: [],
    activeRow: 0,
    isOfferFetching: false,
    showGenerateCodeModal: false,
    code: '',
    codeCopied: false
  };

  _validationSchema = yup.object().shape({
    template: yup.string().required('Please select a template type.'),
    offer_list: yup.array().of(
      yup.object().shape({
        productUuid: yup.string().required('Please select a Product'),
        offerUuid: yup.string().required('Please select an Offer'),
      }),
    ),
  });

  componentDidMount() {
    this.fetchEmailTemplateRequestSubscription = EventBus.on(ACTION_TYPES.FETCH_EMAIL_TEMPLATE_REQUEST, () => 
      this.setState({ loading: true })
    );
    this.fetchEmailTemplateSuccessSubscription = EventBus.on(ACTION_TYPES.FETCH_EMAIL_TEMPLATE_SUCCESS, 
      this._onfetchEmailTemplateSuccess
    );
    this.fetchEmailTemplateFailureSubscription = EventBus.on(ACTION_TYPES.FETCH_EMAIL_TEMPLATE_FAILED, () => 
      this.setState({ loading: false })
    );
    
    this.requestAdminProductList = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_PRODUCT_LIST, () =>
      this.setState({ loading: true }),
    );
    this.adminProductListSuccess = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_SUCCESS, () =>
      this.setState({ loading: false }),
    );
    this.adminProductListFailure = EventBus.on(ACTION_TYPES.ADMIN_PRODUCT_LIST_FAILED, () =>
      this.setState({ loading: false }),
    );

    this.requestAdminOffersListByProductId = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_OFFERS_LIST_BY_PRODUCT_ID, () => 
      this.setState({ isOfferFetching: true }),
    );
    this.adminOffersListByProductIdSuccess = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_BY_PRODUCT_ID_SUCCESS,
      this._onOfferListSuccess
    );
    this.adminOffersListByProductIdFailure = EventBus.on(ACTION_TYPES.ADMIN_OFFERS_LIST_BY_PRODUCT_ID_FAILED,() => 
      this.setState({ isOfferFetching: false }),
    );   

    this.generateEmailCodeRequestSubscription = EventBus.on(ACTION_TYPES.GENERATE_EMAIL_CODE_REQUEST, () => 
      this.setState({ loading: true })
    );
    this.generateEmailCodeSuccessSubscription = EventBus.on(ACTION_TYPES.GENERATE_EMAIL_CODE_SUCCESS, 
      this._onGenerateEmailCodeSuccess
    );
    this.generateEmailCodeFailureSubscription = EventBus.on(ACTION_TYPES.GENERATE_EMAIL_CODE_FAILED, () => 
      this.setState({ loading: false })
    );
    
    this.props.fetchEmailTemplatesRequest();    
  }

  componentWillUnmount() {
    this.fetchEmailTemplateRequestSubscription();
    this.fetchEmailTemplateSuccessSubscription();
    this.fetchEmailTemplateFailureSubscription();

    this.requestAdminProductList();
    this.adminProductListSuccess();
    this.adminProductListFailure();

    this.requestAdminOffersListByProductId();
    this.adminOffersListByProductIdSuccess();
    this.adminOffersListByProductIdFailure();

    this.generateEmailCodeRequestSubscription();
    this.generateEmailCodeSuccessSubscription();
    this.generateEmailCodeFailureSubscription();
  }

  _onfetchEmailTemplateSuccess = ({ templates }) => {
    this.setState({ loading: false, templates });
  }

  _onOfferListSuccess = ({ productOffers }) => {
    const { offers, activeRow } = this.state;    
    offers[activeRow] = productOffers;    
    this.setState({ isOfferFetching: false, offers });
  }

  _onGenerateEmailCode = (values, { setSubmitting }) => {   
    const {template, offer_list = []} = values;
    const offerUuids = offer_list.map(offer => offer.offerUuid);
    console.log(offerUuids);

    this.props.generateEmailCodeRequest({
      type: template,
      product_offer_uuid: offer_list[0].offerUuid
    });

    setSubmitting(false);
  };

  _onGenerateEmailCodeSuccess = (code) => {
    this.setState({ loading: false, showGenerateCodeModal: true, code });    
  }

  loadOptions = (inputValue, callback) => {
    this.props.requestAdminProductListByName({ name: inputValue, callback });
  };

  _decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  _onCloseGenerateCodeModal = () => {
    this.setState({ showGenerateCodeModal: false });
  }

  _copyCodeToClipboard = (e) => {
    this.codeInput.select();
    this.codeInput.setSelectionRange(0, 99999);

    document.execCommand('copy');
    this.setState({ codeCopied: true });
    setTimeout(() => {
      this.setState({ codeCopied: false });
    }, 2000);
    e.preventDefault();
  }

  render() {
    const { products = [] } = this.props;
    const { templates, offers, showGenerateCodeModal, code } = this.state;
    const { isOfferFetching, activeRow } = this.state;    
    let productsData = [];
    if (products && products.length > 0) {
      productsData = products.map((data) => ({
        ...data,
        value: data?.productName,
        label: data?.productName,
      }));
    }
    
    return (
      <Container className={styles.container}>
        <Heading variant="section">Email Templates</Heading>
        <Formik
          initialValues={{
            template: '',
            offer_list: [{ productUuid: '', offerUuid: '' }]
          }}
          validationSchema={this._validationSchema}
          onSubmit={this._onGenerateEmailCode}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <Card className={styles.card}>
                <Card.Header className={styles.cardHeader}>
                  <Heading variant="sub-heading">Choose Template</Heading>
                </Card.Header>
                <Card.Body className={styles.cardBody}>
                  <Row className={styles.cardSection} style={{ padding: '0 1rem' }}>
                    <Col>                      
                      <InputSelectOne
                        className={styles['select-p-offer']}
                        label="Select Template"
                        name="template"
                        id="template"
                        onChange={(e) => {
                          setFieldValue('template', e.target.value);
                          this.props.requestAdminProductList({ page: 1 });     
                        }}
                        labelsize="large"
                      >    
                        {
                          templates && templates.length === 0 && <option>Loading...</option>
                        }        
                        <option value='0'>Select</option>            
                        { templates &&
                          templates.length > 0 &&
                          templates.map((template) => (
                            <option key={template.type} value={template.type}>
                              {template.title}
                            </option>
                          ))}
                      </InputSelectOne>                        
                    </Col>
                  </Row>
                  <div className={styles['remaining-payment']}>
                      Choose Offers
                  </div>
                  <FieldArray
                    name="offer_list"
                    render={(arrayHelpers) => ( 
                      <>   
                      {values?.offer_list &&
                        values?.offer_list.length > 0 &&
                        values?.offer_list.map((offerList, index) => (                    
                        <Row className={styles.cardSection} style={{ padding: '0 1rem' }} key={`row-${index}`}>                          
                          <Col md={6}>
                            <Label style={{ marginBottom: '0.2rem', fontFamily: 'Avenir-Regular' }}>Select Product</Label>
                            <AsyncSelectInput
                                cacheOptions
                                loadOptions={this.loadOptions}
                                defaultOptions={productsData}
                                labelsize="large"
                                name={`offer_list[${index}].productUuid`}
                                id={`offer_list[${index}].productUuid`}
                                onChange={(e) => {   
                                  this.setState({ activeRow: index});
                                  this.props.requestAdminOffersListByProductId({ product_id: e?.uuid });
                                  setFieldValue(`offer_list[${index}].productUuid`, e?.uuid);
                                }}
                              />                                                                                                                                                
                              {errors?.offer_list && errors?.offer_list.length > 0 && errors?.offer_list[index]?.productUuid && (
                                <div className={styles['error-box']}>{errors?.offer_list[index].productUuid}</div>
                              )}
                          </Col>
                          <Col md={5}>
                            <InputSelectOne
                              className={styles['select-p-offer']}
                              label="Select Offer"
                              name={`offer_list[${index}].offerUuid`}
                              id={`offer_list[${index}].offerUuid`}
                              labelsize="large"
                              onChange={(e) => {                                                                                              
                                setFieldValue(`offer_list[${index}].offerUuid`, e.target.value);
                              }}                              
                            >
                              <option value=''>
                                {isOfferFetching && activeRow === index ? 'Fetching...' : 'Choose a Product Offer'}
                              </option>
                              {offers && offers[index] &&
                                offers[index].length > 0 &&
                                offers[index].map((offer) => (
                                  <option key={offer.uuid} value={offer.uuid}>
                                    {offer.productOfferName}
                                  </option>
                                ))}
                            </InputSelectOne>                          
                          </Col>
                          <Col md={1}>
                            {index > 0 && (
                              <div
                                className={styles['delete']}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove
                              </div>
                            )}
                          </Col>
                        </Row>                         
                      ))}
                      {/* <Row>
                        <Col>
                          <div className={styles['add-row']}>
                            <span onClick={() => arrayHelpers.push({ productUuid: '', offerUuid: '' })}>Add Offer</span>  
                          </div>
                        </Col>
                      </Row>  */}
                    </>
                  )}/>                                  
                </Card.Body>
              </Card>
              <ButtonMain type="submit" align="center">
                Generate Email Code
              </ButtonMain>
            </Form>
          )}
        </Formik>
        <Modal show={showGenerateCodeModal} size="lg" className="email-code-modal">
          <div className="modal-header">
            <div className="header-left">
              Generated Email
            </div>
            <div className="header-right">
              <span onClick={this._onCloseGenerateCodeModal}>
                <img src="/img/cross-icon.svg" alt="cross-icon" />
              </span>
            </div>
          </div>          
          <div className="modal-body"> 
            {this.state.codeCopied && (
              <div className="code-copied">Copied to clipboard</div>
            )}           
            <Tabs defaultActiveKey="html">
              <Tab eventKey="html" title="HTML">
                <div dangerouslySetInnerHTML={{ __html: this._decodeHtml(code) }} />
              </Tab>
              <Tab eventKey="code" title="CODE">                
                <textarea value={this._decodeHtml(code)} ref={(ref) => (this.codeInput = ref)}/>                
                <ButtonMain align="center" onClick={this._copyCodeToClipboard}>
                  Copy to Clipboard
                </ButtonMain>
              </Tab>
            </Tabs>
          </div>
        </Modal>
        <Loader loading={this.state.loading} />
      </Container>
    );
  }
}

export default EmailTemplates;

import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from 'src/components/data-table';
import { Badge } from 'src/components/theme';
import styles from '../_styles.module.scss';
import './_productsTable.scss';

const ProductsTable = (props) => {
  let history = useHistory();

  /**
   *
   * Handles click to uuid to take user to product detail screen
   *
   */
  const _handleDetailLink = (e, uuid) => {
    e.preventDefault();
    history.push(`/admin/product/${uuid}`);
  };

  const { productsTotal } = props.data;

  const pageSize = 50;

  const paginationConfig = {
    totalItems: productsTotal,
    pageSize,
    totalPages: Math.ceil(productsTotal / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'productName', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => (
          <div className={styles.multilineColumn}>
            <span className={styles.childOne}>{value}</span>
            <a href="#" onClick={(e) => _handleDetailLink(e, original?.uuid)}>
              <span className={styles.childTwo}>{original?.uuid}</span>
            </a>
          </div>
        ),
      },
      {
        Header: 'Offers Available',
        accessor: 'productOffers',
        Cell: ({ value }) => <span style={{ textAlign: 'center' }}>{value && value.length}</span>,
        styles: {
          textAlign: 'center',
          verticalAlign: 'middle',
        },
      },
      {
        Header: 'Status',
        // accessor: 'status',
        Cell: () => <Badge>Active</Badge>,
      },
    ],
    [],
  );
  return (
    <div className="products-data-table">
      <DataTable
        columns={columns}
        data={props.data.products}
        paginationConfig={paginationConfig}
        handlePaginationChange={props.handlePaginationChange}
      />
    </div>
  );
};

export default ProductsTable;

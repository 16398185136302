import React, { Component } from 'react';
import cx from 'classnames';
// import { faCopy } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { ButtonOne } from '../../../../../../components';

import styles from './_product-offer-link-modal.module.scss';

class ProductOfferLinkModal extends Component {
  redirectToTemplates = () => {
    const { history } = this.props;
    history.push('/admin/marketing/email-templates');
  };

  redirectToProducts = () => {
    const { history } = this.props;
    history.push('/admin/marketing/products');
  };

  render() {
    const { copyCodeToClipboard, code, decodeHtml, codeInput, codeCopied, closeModal } = this.props;
    return (
      <div className={styles['product-create-modal']}>
        <div className={styles['product-box']}>
          <div>
            <div onClick={closeModal} className={styles['close-btn']}>
              <img src="/img/cross-icon.svg" alt="cross-icon" />
            </div>
          </div>
          <div className={styles['product-img']}>
            <img src="/img/ok-3.png" alt="ok" />
          </div>
          <div className={styles['title']}>Please copy the link below to share</div>

          {codeCopied && <div className={styles['code-copied']}>Copied to clipboard</div>}
          <div className={styles['link']}>
            {code && (
              <div onClick={copyCodeToClipboard} className={styles['copy-icon']}>
                <img src="/img/copy.svg" alt="copy" />
              </div>
            )}
            <input
              className={cx(styles['confirmation'])}
              value={decodeHtml(code)}
              ref={(ref) => codeInput(ref)}
            />
          </div>
          <div className={styles['custom-btns-group']}>
            <ButtonOne text="Done" onClick={closeModal} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProductOfferLinkModal);

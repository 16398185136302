import { connect } from 'react-redux';
import { requestAdminLogout } from 'src/redux/actions';
import AdminLogout from './admin-logout';

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}

export default connect(mapStateToProps, { requestAdminLogout })(AdminLogout);

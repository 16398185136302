import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function AdminProtectedRoute({ component: Component, ...restOfProps }) {
  const { partnerAccessKey, userIdentityToken } = restOfProps;
  const isAuthenticated = partnerAccessKey && userIdentityToken;
  const redirectRoute = '/admin/sign-in';

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={redirectRoute} />
      }
    />
  );
}

export default AdminProtectedRoute;

import { ACTION_TYPES } from 'src/constants';
import { INITIAL_STATE } from '../store';

export default function AdminReducer(state = INITIAL_STATE.admin, action) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_LOGIN_SUCCESS:
      return {
        ...action.payload.user,
      };

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.admin;

    default:
      return state;
  }
}

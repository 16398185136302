import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Convert } from 'src/helpers';
// import { Tab, Nav } from 'react-bootstrap';
import styles from './offer-box.module.scss';

const PAYMENT_METHODS = {
  book_save_buy: 'Book. Save. Buy',
  pay_in_instalments: 'Pay in Installments',
};

class OfferBox extends Component {
  state = {
    progress: 0,
    isSaveExpanded: false,
    isOfferModalOpen: false,
  };

  componentDidMount() {
    this.setState({ progress: 30 });
  }

  toogleSaveExpand = () => {
    const { isSaveExpanded } = this.state;
    this.setState({ isSaveExpanded: !isSaveExpanded });
  };

  openOfferModal = () => {
    this.setState({ isOfferModalOpen: true });
  };

  closeOfferModal = () => {
    this.setState({ isOfferModalOpen: false });
  };

  _onChangePaymentMethod = (paymentMethod) => {
    const { savingPlan } = this.props;
    this.props.updateSavingPlan({
      ...savingPlan,
      paymentMethod,
    });
  };

  render() {
    const { progress, isSaveExpanded, isOfferModalOpen } = this.state;
    const { partner, product, user, isPayScreen, savingPlan } = this.props;
    const { productOffers } = product || {};
    const offer = productOffers && productOffers.length > 0 ? productOffers[0] : {};
    const { paymentMethodsAvailable = [], amount, paymentCalendar } = offer || {};
    const productAmount = Number(amount);
    const discountPercentage = Number(offer?.discountPercentage || 0);
    const discountAmount = (productAmount / 100) * discountPercentage;
    const sortedPaymentCalendar = Object.keys(paymentCalendar || {}).sort(
      (a, b) => moment(a, 'DDMMYYYY').valueOf() - moment(b, 'DDMMYYYY').valueOf(),
    );
    const installmentKey =
      sortedPaymentCalendar && sortedPaymentCalendar[1] ? sortedPaymentCalendar[1] : 0;
    const offerAmount = productAmount - discountAmount;
    const finalPrice = offerAmount - offer?.upfrontPaymentAmount;
    const returnAmount = (finalPrice / 100) * Number(offer?.savingReturn);
    return (
      <div className={styles['offer-box-outer']}>
        <div className={styles['offer-box']}>
          <div className={styles['header']}>
            <div className={styles['inner-header']}>
              <div className={styles['sub-inner-header']}>
                <div className={styles['logo']}>
                  <img src={partner?.logoImageLink} alt="Merchant Logo" />
                </div>
                <div className={styles['content']}>
                  <div className={styles['title']}>{offer.productOfferName}</div>
                  <div className={styles['identity']} />
                  {/* <div className={styles['price']}>Rs. 15,000</div> */}
                  <div className={styles['discount']}>
                    {Convert.currency(offer?.upfrontPaymentAmount || 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['progress-bar']}>
            <div className={styles['active-progress-bar']} style={{ width: `${progress}%` }} />
          </div>

          <div className={styles['mobile-view-discount']}>
            <h4 className={styles['mobile-pay-heading']}>Choose your buying method</h4>
            <div className={styles['mobile-options-container']}>
              {paymentMethodsAvailable &&
                paymentMethodsAvailable.length > 0 &&
                paymentMethodsAvailable.map((paymentMethod, index) => {
                  if (!PAYMENT_METHODS[paymentMethod]) return null;
                  return (
                    <button
                      onClick={() => this._onChangePaymentMethod(paymentMethod)}
                      className={cx(styles['option-button'], {
                        [styles['active']]: paymentMethod === savingPlan?.paymentMethod,
                      })}
                      key={`option-${index}`}
                    >
                      <img src={`/img/${paymentMethod}.svg`} alt={PAYMENT_METHODS[paymentMethod]} />
                      <h4>{PAYMENT_METHODS[paymentMethod]}</h4>
                    </button>
                  );
                })}
            </div>
            <div className={styles['mobile-view-discount-content']}>
              <div className={styles['mobile-view-sub-discount-content']}>
                <div>
                  <div className={styles['total-cost']}>
                    Total cost of offer: {Convert.currency(offer?.discountedAmount || 0)}
                  </div>
                  <div className={styles['view-discount']}>
                    <div className={styles['view-discount-title']}>
                      <img src="/img/lock.svg" alt="lock" /> You are saving{' '}
                      <span>{Convert.currency(discountAmount)}</span> on this offer
                    </div>
                    <div className={styles['discount-line']} />
                    <div className={styles['view-discount-btn']} onClick={this.openOfferModal}>
                      View discounts
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['progress-bar']} />
          </div>
          {savingPlan?.paymentMethod !== 'pay_in_instalments' && (
            <div>
              <div className={styles['save-title']}>BOOK, SAVE AND BUY</div>

              <div className={styles['schedule-title-returns']}>
                <span>
                  {' '}
                  Earn returns upto: {Convert.currency(Number(returnAmount).toFixed(2))}/-{' '}
                </span>
              </div>
            </div>
          )}
          {Number(discountAmount || 0) > 0 && (
            <div
              className={cx(styles['desktop-discount'], {
                [styles['desktop-discount-margin-top']]:
                  savingPlan?.paymentMethod === 'pay_in_instalments',
              })}
            >
              <div className={styles['disc-title']}>
                Initial cost of offer: {Convert.currency(offer?.taxedAmount || 0)}
              </div>
              <div className={cx(styles['disc-cost-list'])}>
                <div className={styles['disc-title']}>
                  Discount: {Convert.currency(discountAmount || 0)}
                </div>
              </div>
              <div className={styles['view-discount']}>
                <div className={styles['view-discount-title']}>
                  <img src="/img/lock.svg" alt="lock" /> You are saving{' '}
                  <span>{Convert.currency(discountAmount)}</span> on this offer
                </div>
              </div>
            </div>
          )}

          <div className={styles['main-schedule']}>
            <div className={styles['inner-main-schedule']}>
              <div className={styles['sub-inner-main-schedule']}>
                <div>
                  <div
                    className={`${styles['schedule-title']} ${
                      savingPlan?.paymentMethod !== 'pay_in_instalments'
                        ? styles['schedule-title-bsb']
                        : ''
                    }`}
                  >
                    {savingPlan?.paymentMethod === 'pay_in_instalments'
                      ? 'Pay in Installments Schedule'
                      : 'Book, Save and Buy Schedule'}
                  </div>
                  <div className={styles['scheduler']}>
                    <div className={styles['scheduler-progress-box']}>
                      <div className={styles['scheduler-progress-bar']}>
                        <div
                          className={cx(styles['scheduler-progress-line'], {
                            [styles['active-progress-line']]: isPayScreen,
                          })}
                        />
                        <div
                          className={cx(styles['progress-number-layer'], {
                            [styles['active-progress-number-layer']]: isPayScreen,
                          })}
                        >
                          <div
                            className={cx(styles['progress-number-border'], {
                              [styles['active-progress-number-border']]: isPayScreen,
                            })}
                          >
                            <div
                              className={cx(styles['scheduler-progress-number'], {
                                [styles['active-progress-number']]: isPayScreen,
                                [styles['success-bg']]: !isPayScreen,
                              })}
                            >
                              1
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles['scheduler-content']}>
                        <div className={styles['scheduler-book']}>
                          <div
                            className={cx(styles['scheduler-title'], {
                              [styles['disabled-scheduler-title']]: !isPayScreen,
                            })}
                          >
                            Book
                          </div>
                          <div
                            className={cx(styles['scheduler-desc'], {
                              [styles['disabled-scheduler-desc']]: !isPayScreen,
                            })}
                          >
                            today for {Convert.currency(offer?.upfrontPaymentAmount || 0)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles['scheduler-progress-box']}>
                      <div className={styles['scheduler-progress-bar']}>
                        <div className={styles['scheduler-progress-line']} />
                        <div
                          className={cx(styles['progress-number-layer'], {
                            [styles['active-progress-number-layer']]: !isPayScreen,
                          })}
                        >
                          <div
                            className={cx(styles['progress-number-border'], {
                              [styles['active-progress-number-border']]: !isPayScreen,
                            })}
                          >
                            <div
                              className={cx(styles['scheduler-progress-number'], {
                                [styles['active-progress-number']]: !isPayScreen,
                              })}
                            >
                              2
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles['scheduler-content']}>
                        <div className={styles['scheduler-book']}>
                          <div
                            className={cx(styles['scheduler-title'], {
                              [styles['disabled-scheduler-title']]: isPayScreen,
                            })}
                          >
                            {savingPlan?.paymentMethod === 'pay_in_instalments' ? 'Pay' : 'Save'}
                          </div>
                          <div className={cx(styles['scheduler-desc'])}>
                            <span
                              className={cx({
                                [styles['disabled-scheduler-desc']]: isPayScreen,
                              })}
                            >
                              for the rest in {sortedPaymentCalendar.length - 1}{' '}
                              {savingPlan?.paymentMethod === 'pay_in_instalments'
                                ? 'payments'
                                : 'installments'}
                              <br /> of{' '}
                              {Convert.currency(
                                offer?.paymentCalendar && offer?.paymentCalendar[installmentKey],
                              )}
                              /month{' '}
                            </span>
                            &nbsp;
                            <span onClick={this.toogleSaveExpand}>
                              <img
                                src="/img/down-arrow-2.svg"
                                alt="img"
                                className={cx(styles['scheduler-down-icon'], {
                                  [styles['scheduler-up-icon']]: isSaveExpanded,
                                })}
                              />
                            </span>
                            {isSaveExpanded && (
                              <div
                                className={cx(styles['scheduler-ul'], {
                                  [styles['disabled-scheduler-desc']]: isPayScreen,
                                })}
                              >
                                {/* <div
                                  className={cx(
                                    styles['scheduler-list'],
                                    styles['scheduler-list-first'],
                                  )}
                                >
                                  - First Save {Convert.currency(offer?.upfrontPaymentAmount || 0)}
                                </div> */}
                                {sortedPaymentCalendar.map((key, index) => {
                                  if (index === 0) {
                                    return null;
                                  }
                                  return (
                                    <div
                                      key={`installment-${index}`}
                                      className={styles['scheduler-list']}
                                    >
                                      -{' '}
                                      {savingPlan?.paymentMethod === 'pay_in_instalments'
                                        ? 'Pay'
                                        : 'Save'}{' '}
                                      {Convert.currency(offer?.paymentCalendar[key])} on{' '}
                                      {moment(key, 'DDMMYYYY').format('Do, MMM YYYY')}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles['scheduler-progress-box']}>
                      <div className={styles['scheduler-progress-bar']}>
                        <div
                          className={cx(styles['progress-number-layer'], {
                            [styles['active-progress-number-layer']]: false,
                          })}
                        >
                          <div
                            className={cx(styles['progress-number-border'], {
                              [styles['active-progress-number-border']]: false,
                            })}
                          >
                            <div
                              className={cx(styles['scheduler-progress-number'], {
                                [styles['active-progress-number']]: false,
                              })}
                            >
                              3
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles['scheduler-content']}>
                        <div className={styles['scheduler-book']}>
                          <div
                            className={cx(styles['scheduler-title'], {
                              [styles['disabled-scheduler-title']]: true,
                            })}
                          >
                            Buy
                          </div>
                          <div
                            className={cx(styles['scheduler-desc'], {
                              [styles['disabled-scheduler-desc']]: true,
                            })}
                          >
                            your product with money saved
                          </div>
                          <div
                            className={cx(styles['schedule-date'], {
                              [styles['disabled-schedule-date']]: true,
                            })}
                          >
                            <div className={styles['schedule-date-title']}> Date of buy: </div>
                            {moment(offer?.finalDate).format('DD MMMM YYYY')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['progress-bar']} />
          <div className={styles['your-info']}>
            <div className={styles['inner-your-info']}>
              <div className={styles['sub-inner-your-info']}>
                {user?.phoneNumber?.length > 9 ? (
                  <div>
                    <div className={styles['info-title']}>your information</div>
                    <div className={styles['info-box']}>
                      {user?.phoneNumber && user?.phoneNumber !== '+91' && (
                        <div className={styles['info-phone']}>{user?.phoneNumber}</div>
                      )}
                      {user?.email && (
                        <>
                          {user?.phoneNumber && user?.phoneNumber !== '+91' && (
                            <div className={styles['info-line']} />
                          )}
                          <div className={styles['info-email']}>{user?.email}</div>
                        </>
                      )}
                    </div>
                    <div className={styles['your-save']} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {false && (
            <div className={styles['saving-method']}>
              <div className={styles['info-title']}>Saving method</div>
              <div className={styles['info-desc']}>
                Your money will be safely invested in
                <br /> <a href="#">ICICI Prudential’s Liquid Mutual Fund</a>
              </div>
              <div className={styles['your-save']}>
                This transaction is secured by 256-bit data encryption
              </div>
            </div>
          )}
          {offer?.partner?.paymentTermsLink && (
            <div className={styles['payment-terms']}>
              <a href={offer?.partner?.paymentTermsLink}>Payment Terms</a>
            </div>
          )}
          {isPayScreen && (
            <div
              className={cx(styles['your-save'], styles['desktop-your-save'], {
                [styles['your-save-bsb']]: savingPlan?.paymentMethod !== 'pay_in_instalments',
              })}
            >
              <img src="/img/lock.svg" alt="lock" />
              This transaction is secured by 256-bit data encryption
            </div>
          )}

          <>
            <div className={cx('mt-2 mb-3', styles['progress-bar'])} />
            {savingPlan?.paymentMethod !== 'pay_in_instalments' ? (
              <div className={styles['encrypt']}>
                <div className={styles['encrypt-inner']}>
                  <div className={styles['encrypt-text']}>
                    Your money will be saved in{' '}
                    <a
                      href="https://www.icicipruamc.com/mutual-fund/debt-funds/icici-prudential-liquid-fund"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ICICI Prudential Liquid Mutual Fund
                    </a>
                    <br /> Your data is encrypted by 256-bit security
                  </div>
                  <div className={styles['encrypt-images']}>
                    <img src="/img/icici-amfi2.svg" alt="img" />
                  </div>
                </div>
              </div>
            ) : null}
          </>
          {savingPlan?.paymentMethod === 'pay_in_instalments' && (
            <div className={styles['empty-box']} />
          )}
          {isPayScreen && (
            <button
              disabled={!savingPlan?.paymentMethod}
              className={styles['proceed-btn']}
              onClick={this.props?.onProceedPayment}
            >
              PROCEED
            </button>
          )}
          {isOfferModalOpen && (
            <div className={styles['mobile-discount-applied']}>
              <div className={styles['mobile-discount-applied-inner']}>
                <div className={styles['mobile-discount-applied-header']}>
                  <div className={styles['applied-title']}>Discounts Applied</div>
                  <div className={styles['close-applied']} onClick={this.closeOfferModal}>
                    <img src="/img/close-discounts.svg" alt="close" />
                  </div>
                </div>
                <div className={styles['below-content']}>
                  <div className={styles['cost-list']}>
                    <div className={styles['cost-title']}>Initial cost of offer</div>
                    <div className={styles['cost-value']}>
                      {Convert.currency(offer?.taxedAmount || 0)}
                    </div>
                  </div>
                  <div className={styles['cost-line']} />

                  <div className={cx('pt-3', styles['cost-list'])}>
                    <div className={styles['cost-title']}>Discount</div>
                    <div className={styles['cost-value']}>
                      {Convert.currency(discountAmount || 0)}
                    </div>
                  </div>
                  <div className={styles['cost-line']} />
                  <div className={styles['cost-list']}>
                    <div className={styles['cost-title']}>
                      <strong>Cost after discount</strong>
                    </div>
                    <div className={styles['cost-value']}>
                      <strong>{Convert.currency(offer?.discountedAmount || 0)}</strong>
                    </div>
                  </div>
                  <div className={styles['cost-line']} />

                  <div className={styles['total-amount-saved']}>
                    Total Amount Saved <span>{Convert.currency(discountAmount || 0)}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OfferBox;

import React from 'react'
import { InputFileThree } from 'src/components/form-inputs';
import cx from 'classnames';
import styles from './_review-address-proof.module.scss';

export default function ReviewAddressProof(props) {
  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <span className={styles.heading}>
          {props?.heading}
        </span>
      </div>
      <div className={styles.inputContainer}>
        <InputFileThree
          name="address-proof"
          id="address-proof"
          ctaTitle="Re-upload"
          src={props.addressProof?.image_url}
          onClick={() => props.onClick(2)}
        />
      </div>      
      <div className={styles.contentContainer}>
        <div>
          <label>Proof Type:</label>
          <span>{props.addressProof?.address_proof_type}</span>
        </div>
        <div>
          <label>Full name:</label>
          <span>{props.addressProof?.name}</span>
        </div>
        <div>
          <label>ID Number:</label>
          <span>{props.addressProof?.aadhaar_uid || props.addressProof?.license_number || props.addressProof?.passport_number || props.addressProof?.voter_id_number}</span>
        </div>
        <div>
          <label>Address:</label>
          <span>{props.addressProof?.address}</span>
        </div>
        <div>
          <label>State:</label>
          <span>{props.addressProof?.state}</span>
        </div>
        <div>
          <label>District:</label>
          <span>{props.addressProof?.district}</span>
        </div>
        <div>
          <label>City:</label>
          <span>{props.addressProof?.city}</span>
        </div>
        {
          props.addressProof?.issue_date
          ?
          <div>
            <label>Issue Date:</label>
            <span>{props.addressProof?.issue_date}</span>
          </div>
          :
          null
        }
        {
          props.addressProof?.expiry_date
          ?
          <div>
            <label>Expiry Date:</label>
            <span>{props.addressProof?.expiry_date}</span>
          </div>
          :
          null
        }        
      </div>      
    </div>
  )
}

import { useField } from 'formik';
import React from 'react';
import { Label } from 'src/components/theme';
import styles from './_inputswitch.module.scss';
import { TextOne } from '../../text-one';
import './_inputswitch.scss';

function InputSwitch({ label, val, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      {label && (
        <div className={styles.inputLabelContainer}>
          <Label>
            <TextOne>{label}</TextOne>
          </Label>
        </div>
      )}
      <div className={styles.container} style={{ ...props.containerstyles }}>
        {props.labelPosition !== 'right' ? (
          <label className={styles.inputLabel} htmlFor={props.id || props.name}>
            <TextOne>{val}</TextOne>
          </label>
        ) : null}
        <div className={`${styles.input} form-check form-switch`}>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            {...field}
            {...props}
          />
        </div>
        {props.labelPosition === 'right' ? (
          <label className={styles.inputLabel} htmlFor={props.id || props.name}>
            {val}
          </label>
        ) : null}
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>
    </>
  );
}

export default InputSwitch;

import { takeEvery, put, call } from 'redux-saga/effects';
import { ACTION_TYPES } from 'src/constants';
import { fetchEmailTemplates, generateEmailCode } from 'src/redux/api';
import { fetchEmailTemplatesSuccess, fetchEmailTemplatesFailure, generateEmailCodeSuccess, generateEmailCodeFailure } from 'src/redux/actions';

export function* watchMarketingRequests() {
  yield takeEvery(ACTION_TYPES.FETCH_EMAIL_TEMPLATE_REQUEST, requestEmailTemplates);
  yield takeEvery(ACTION_TYPES.GENERATE_EMAIL_CODE_REQUEST, requestEmailCode);
}

function* requestEmailTemplates(action) {
  try {
    const templates = yield call(fetchEmailTemplates, action.payload);
    yield put(fetchEmailTemplatesSuccess(templates));
  } catch (e) {
    yield put(fetchEmailTemplatesFailure(e));
  }
}

function* requestEmailCode(action) {
  try {
    const emailCode = yield call(generateEmailCode, action.payload);
    yield put(generateEmailCodeSuccess(emailCode));
  } catch (e) {
    yield put(generateEmailCodeFailure(e));
  }
}

import { connect } from 'react-redux';
import { requestCreateAdminCollections } from 'src/redux/actions';
import CollectMoneyRequest from './collect-money-request';

const mapStateToProps = (state) => ({
  goal: state.adminGoals.savingGoal,
  user: state.user,
});

export default connect(mapStateToProps, { requestCreateAdminCollections })(CollectMoneyRequest);

import React from 'react'
import { InputFileThree } from 'src/components/form-inputs';
import cx from 'classnames';
import styles from './_review-selfie.module.scss';

export default function ReviewSelfie(props) {
  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <span className={styles.heading}>
          {props?.heading}
        </span>
      </div>
      <div className={styles.inputContainer}>
        <InputFileThree
          name="selfie"
          id="selfie"
          ctaTitle="Re-take"
          src={props.selfie?.image_url}
          onClick={() => props.onClick(5)}
        />
      </div>                 
    </div>
  )
}

import React from 'react'
import cx from 'classnames'
import {TiLightbulb} from 'react-icons/ti'
import styles from './_info-box.module.scss'

const Icon = ({type, ...props}) => {
  switch (type) {
    case 'info':
      return <TiLightbulb {...props}/>
  
    default:
      return <TiLightbulb {...props}/>
  }
}

export default function InfoBox(props) {
  return (
    <div className={cx(styles.container, props?.className)}>
      <Icon type={props?.type || ''} className={styles.icon}/>
      {props?.children}
    </div>
  )
}

import React from 'react'
import { InputFileThree } from 'src/components/form-inputs';
import cx from 'classnames';
import styles from './_review-pan-card.module.scss';

export default function ReviewPanCard(props) {
  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.header}>
        <span className={styles.heading}>
          {props?.heading}
        </span>
      </div>
      <div className={styles.inputContainer}>
        <InputFileThree
          name="pan"
          id="pan"
          ctaTitle="Re-upload"
          src={props.panCard?.image_url}
          onClick={() => props.onClick(1)}
        />
      </div>      
      <div className={styles.contentContainer}>
        <div>
          <label>Full name:</label>
          <span>{props.panCard?.name}</span>
        </div>
        <div>
          <label>Pan Number:</label>
          <span>{props.panCard?.pan_number}</span>
        </div>
        <div>
          <label>Date of Birth:</label>
          <span>{props.panCard?.date_of_birth || 'N/A'}</span>
        </div>
        <div>
          <label>Father&#39;s Name:</label>
          <span>{props.panCard?.fathers_name || 'N/A'}</span>
        </div>
      </div>      
    </div>
  )
}

import { ApiRequest } from 'src/helpers';
import { API_END_POINTS } from 'src/constants';

export function adminPartnerList() {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_PARTNERS}/0`,
  }).then((response) => response.data);
}

export function adminProfile() {
  return ApiRequest.fetchAdmin({
    method: 'get',
    url: `${API_END_POINTS.ADMIN_PARTNERS}/0`,
  }).then((response) => response.data);
}

export function adminProfileUpdate(data) {
  return ApiRequest.fetchAdmin({
    method: 'put',
    url: `${API_END_POINTS.ADMIN_PARTNERS}/0`,
    data
  }).then((response) => response.data);
}
